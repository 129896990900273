import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { getRequestThunk } from '../Util/get-request-thunk';
import { getAllItemsFullfilled, defaultRejectedEffect } from '../lib/default-get-all-items';

const apiHOST = apiURL();
const initialState = {
  raw: [],
  formated: [],
  display: [],
  filterSearchValue: '',
  order: false,
  orderBy: null,
  orderByOptions: [
    '',
    'id',
    'razonSocial',
    'fechaFactura',
    'fechaVencimiento',
    'diasRestantes',
  ],
  sortAscending: false,
  error: false,
  loading: false,
  success: null,
};

export const getCuentasPorCobrar = getRequestThunk(
  'producto/getCuentasPorCobrar',
  `${apiHOST}views/cuentas-por-cobrar`,
);

const cuentasPorCobrarSlice = createSlice({
  name: 'getCuentasPorCobrar',
  initialState: initialState,
  reducers: {
    resetFilter(state, action) {
      state.filterSearchValue = null;
    },
    filterBy(state, action) {
      const value = String(action.payload.value).toUpperCase();
      state.filterSearchValue = value;

      try {
        const filtro = action.payload.raw.filter(
          (item) =>
            item.razonSocial.includes(value) ||
            item.factura.includes(value) ||
            item.fechaFactura.includes(value) ||
            item.fechaVencimiento.includes(value)||
            String(item.id).includes(value)
        );
        filtro.length > 0
          ? (state.display = filtro)
          : (state.display = action.payload.raw);
      } catch {
        state.display = action.payload.raw;
      }
    },
    orderBy(state, action) {
      state.orderBy = action.payload;
      let payloadValue = action.payload;
      let sortFunction = (a, b) => {
        if (state.sortAscending) {
          if (a[payloadValue] > b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] < b[payloadValue]) {
            return -1;
          }
        }
        if (!state.sortAscending) {
          if (a[payloadValue] < b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] > b[payloadValue]) {
            return -1;
          }
        }
        return 0;
      };
      if (payloadValue === 'costoAdquisicionActual' || payloadValue === 'precioVentaActual') {
        sortFunction = (a, b) => {
          if (state.sortAscending) {
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return -1;
            }
          }
          if (!state.sortAscending) {
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return -1;
            }
          }
          return 0;
        };
      }

      state.display.sort(sortFunction);
    },
    sortAscending(state, action) {
      state.sortAscending = !action.payload;
      const sortFunction = () => {
        if (state.sortAscending === action.payload) {
          return 1;
        }
        if (state.sortAscending !== action.payload) {
          return -1;
        }
      };
      state.display.sort(sortFunction);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCuentasPorCobrar.fulfilled, getAllItemsFullfilled);
    builder.addCase(getCuentasPorCobrar.rejected, defaultRejectedEffect);
  },
});

export default cuentasPorCobrarSlice;

export const cuentasPorCobrarActions = cuentasPorCobrarSlice.actions;
