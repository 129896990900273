import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
const apiHOST = apiURL();
const divisaState = {
  nombre: null,
  loading: false,
  error: false,
};

export const insertDivisa = createAsyncThunk(
  'divisa/insertDivisa',
  async (nombre) => {
    try {
      const request = { nombre };
      console.log('Request---->');
      console.log(request);

      const response = axios
        .post(`${apiHOST}divisas/`, request)
        .then((res) => {
          console.log('Submit RES');
          console.log(res);
        })
        .catch((err) => console.log(err));

      return response;
    } catch (err) {
      return err;
    }
  }
);

const divisaSlice = createSlice({
  name: 'sf',
  initialState: divisaState,
  reducers: {
    change(state, action) {
      state.nombre = action.payload;
    },
  },
  extraReducers: {
    [insertDivisa.fulfilled]: (state, action) => {
      state.nombre = null;
      state.loading = false;
      state.error = false;
    },
    [insertDivisa.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [insertDivisa.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default divisaSlice;

export const divisaActions = divisaSlice.actions;
