import React from 'react';
import IconButton from '../TailwindUI/IconButton';
const RelationInsertValue = (props) => (
  <div>
    <label
      htmlFor="simbolo"
      className="block text-sm font-medium text-white text-left "
    >
      {props.label}
    </label>

    <div className="">
      <input
        value={props.value}
        type="text"
        disabled
        className="mt-1 w-full text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      />
      <div className="mt-3 flex items-center justify-center">
        {props.value && (
          <IconButton
            onClick={props.handlePostRelation}
            color="green"
            icon="check"
          />
        )}
        <IconButton onClick={props.handleCancel} color="red" icon="x" />
      </div>
    </div>
  </div>
);

export default RelationInsertValue;
