import React, { useState, useEffect, useContext, Fragment } from 'react';
import { distitoMateraAPI, apiURL } from '../../Util/apiURL';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import axios from 'axios';

import { timeStampToDDMMYY } from '../../Util/get-date';

import {
  PencilAltIcon,
  TrashIcon,
  LogoutIcon,
  TruckIcon,
} from '@heroicons/react/solid';

import {
  getSalida,
  putSalida,
  editSalidaActions,
  getRelation,
  postRelationProducto,
  deleteSalida,
  deleteRelationProducto,
  cerrarSalida,
  moverAInventarioClientes,
  cerrarProductoOcc,
} from '../../store/editSalida-slice';
import { CardButton } from '../SharedUi/UpdateCardUI';
import ProductosSalida from './ProductosSalida';
import AsignProductoOCCtoSalida from '../Salida/AsignProductoOCCtoSalida';

import DotsDropdown from '../DropdownMenu/DotsDropdown';
import DropdownItemButton from '../DropdownMenu/DropdownItemButton';
import InfoMely, { datosMelyMx, datosMelyUsa } from '../InfoMely/InfoMely';
import SalidaPDF from '../DocsToPrint/SalidaPDF';
import DocsCard from '../SharedUi/DocsCard';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';

import {
  DocsCardLayoutLogo,
  DocsCardLayoutDatosDoc,
  DocsCardLayoutMenu,
  DocsCardLayoutTwoCols,
  DocsCardDatosFiscales,
  DocsCardLayoutInfoMely,
} from '../SharedUi/DocsCardLayout';
import DocsCardDatosDoc from '../SharedUi/DocsCardDatosDoc';
import LogoMely from '../SharedUi/LogoMely';
import IconButton from '../TailwindUI/IconButton';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import DeleteModal from '../UI/Modals/DeleteModal';
import ConfirmModal from '../UI/Modals/ConfirmModal';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { AddProductToSalida } from './AddProductToSalida';

const EditSalida = () => {
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [confirmModalIsVisibleCerrar, setConfirmModalIsVisibleCerrar] = useState(
    false);
  const [confirmModalIsVisibleMover, setConfirmModalIsVisibleMover] = useState(
    false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [useEffectVariable, setUseEffectVariable] = useState(0);

  useEffect(() => {
    fetchSalida();
    getRelationHandler(relationProductosSalida_URL, 'productosSalida');
  }, []);

  useEffect(() => {
    dispatch(editSalidaActions.resetProductToSearch());
    dispatch(editSalidaActions.resetSeleccionInventario());
    setFirstLoad(false);
    if (!firstLoad) {
      // getRelationHandler(relationProducto_URL, 'productosOrdenCompra');
      // getRelationHandler(existenciasInventario_URL, 'existenciasInventario');
      dispatch(editSalidaActions.resetExistenciasInventario());
    }
  }, [useEffectVariable]);

  const sliceState = useSelector((state) => state.editSalida);

  const dispatch = useDispatch();
  const apiHOST = apiURL();
  const { paramsById } = distitoMateraAPI;
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  const urlParam = location.pathname.split('/')[2];
  const requestUrl = 'ordenes-compra-cliente';
  const deleteUrl = `salidas/${sliceState?.salida?.id}`;
  const existenciasInventario_URL = `views/inventario/existencia-by-producto-by-cliente/?clienteId=${sliceState.cliente?.id}`;
  const relationProducto_URL = `views/salidas/productos-occ/for-salida/?productoId=${sliceState?.productToSearch?.id}&clienteId=${sliceState.cliente?.id}`;
  const relationProductosSalida_URL = `views/productos-salida/${urlParam}`;
  const putRequestObj = {
    numero_occ: sliceState?.occ?.numeroOcc,
    cliente_id: sliceState?.occ?.clienteId,
    iva: sliceState?.occ?.iva,
    usuario_id: sliceState?.occ?.usuarioId,
    divisa_id: sliceState?.occ?.divisaId,
    inventario_id: sliceState?.occ?.inventarioId,
    en_calidad_de_id: sliceState?.occ?.enCalidadDeId,
    estatus: sliceState?.occ?.estatus,
    cantidad_letra: sliceState?.occ?.cantidadLetra,
  };

  const filterArrByObjPropertyName = (externalArray, propertyname) => {
    const arr = [];
    externalArray?.forEach((i, index) => {
      let filter = arr.filter((e) => e === Number(i[propertyname]));
      console.log('filter-------');
      console.log(filter);
      console.log(filter.length);
      if (filter.length === 0) {
        arr.push(i[propertyname]);
      }
    });
    return arr;
  };

  const fetchSalida = () => {
    dispatch(getSalida({ urlParam, authCtx }));
  };

  const handleResetProductosOrdenescompra = () => {
    dispatch(editSalidaActions.resetProductosOrdenCompra());
  };

  const handlePutOrdenCompraProveedor = () => {
    dispatch(
      putSalida({
        requestObj: putRequestObj,
        requestUrl,
        reduxStateTree: sliceState,
        history,
        authCtx,
      }),
    );
  };
const resetOnCloseModal=()=>{
  dispatch(editSalidaActions.resetExistenciasInventario());
  dispatch(editSalidaActions.resetSeleccionInventario());
  dispatch(editSalidaActions.resetProductToSearch());

}
  const handleDelete = () => {
    console.log({
      requestUrl: deleteUrl,
      sliceState: sliceState,
      history,
    });
    dispatch(
      deleteSalida({
        requestUrl: deleteUrl,
        sliceState: sliceState,
        history,
        authCtx,
      }),
    );
  };
  //Relations
  ////Redux Actions
  const handleAsingProducto = (e) => {
    dispatch(editSalidaActions.asign({ ...e }));
  };
  const handleCancelAsingProducto = (e) => {
    dispatch(editSalidaActions.cancelAsign({ ...e }));
  };
  //Relations AsyncTHunks

  const getRelationHandler = (requestUrl, reduxStateTree) => {
    dispatch(
      getRelation({
        requestUrl,
        reduxStateTree,
        authCtx,
      }),
    );
  };
  const handleCerraProductoOcc = (e) => {
    dispatch(
      cerrarProductoOcc({
        primarykey: e.valueToUnasign.id,
        authCtx,
      }),
    );
    setUseEffectVariable(useEffectVariable + 1);
  };
  const handleEraseRelation = (e) => {
    console.log('errase relation!!!-------------<<<<<<<<<<<<<<<<<');
    console.log(e);
    console.log('errase relation!!!-------------<<<<<<<<<<<<<<<<<');
    const requestObj = {
      id: e.valueToUnasign.id,
    };
    const { reduxStateTree, asignedListString, asignedListSelector } = e;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    console.log('event');
    console.log(e);

    console.log('requestObj----->>>>>>>');
    console.log(requestObj);

    dispatch(
      deleteRelationProducto({
        requestObj,
        reduxStateTree,
        asignedListString,
        asignedListSelector,
        authCtx,
      }),
    );
    setUseEffectVariable(useEffectVariable + 1);
  };

  const handleSelect = (reduxStateTree, value, selectValues) => {
    handleResetProductosOrdenescompra();
    return editSalidaActions.select({
      field: reduxStateTree,
      selectedValue: value,
      values: selectValues,
    });
  };

  const handlePostRelation = (e) => {
    dispatch(editSalidaActions.resetProductosOrdenCompra());
    let requestObj = {};
    const { reduxStateTree, selectedValue, asignedListString } = e;
    console.log('reduxStateTree----------');
    console.log(reduxStateTree);
    if (reduxStateTree === 'productToAsign') {
      requestObj = { ...selectedValue };
    }
    dispatch(
      postRelationProducto({
        requestObj,
        reduxStateTree,
        selectedValue,
        asignedListString,
        authCtx,
      }),
    );
    handleResetProductosOrdenescompra();
    dispatch(editSalidaActions.resetProductToSearch());
    dispatch(editSalidaActions.resetSeleccionInventario());
    setUseEffectVariable(useEffectVariable + 1);
  };

  const handleCreateCuentaPorPagar = async (request) => {
    console.log('Create cuenta por pagar');
    console.log(request);

    try {
      const response = await axios.post(`${apiHOST}views/cuentas-por-pagar`,
        request);
      console.log('response Create cuenta por Pagar');
      console.log(response);
      return history.push(`/cuentas-por-pagar/${response.data.id}`);
    } catch (err) {
      return err;
    }
  };
  console.log('TTTESSST');
  // console.log(
  //   filterArrByObjPropertyName(sliceState?.productosSalida, 'numeroOcc')
  // );

  //FIXME estas fuera del 10% permitido

  return (
    <>


      <React.Fragment>
        {sliceState.error || sliceState.loading ? (
          <LifeCycleFeedback sliceState={sliceState} />
        ) : (
          <div className='flex-1 flex flex-col overflow-hidden'>
            {/* Main content */}

            <FlexContainer className='flex-1 items-stretch  overflow-hidden'>
              <main className='flex-1 overflow-y-auto'>
                {sliceState?.salida?.vigente && (
                  <div className='mt-4'>
                    {!sliceState?.existenciasInventario && (
                      <IconButton
                        onClick={() =>
                          getRelationHandler(existenciasInventario_URL,
                            'existenciasInventario')
                        }
                        color='orangeMely'
                        icon='+'
                      />
                    )}
                    <div style={{ position: 'relative' }}>
                      {sliceState?.existenciasInventario && (
                        <AddProductToSalida
                          resetExistenciasInventario={resetOnCloseModal}
                          sliceState={sliceState}
                          relationProducto_URL={relationProducto_URL}
                          getRelationHandler={getRelationHandler}
                          handleResetProductosOrdenescompra={handleResetProductosOrdenescompra}
                          dispatch={dispatch}
                          AsignProductoOCCtoSalida={
                            <AsignProductoOCCtoSalida
                              //UI
                              cardTitle={
                                sliceState?.productToSearch?.simbolo
                                  ? `Ordenes de Compra que contienen ${sliceState?.productToSearch?.simbolo}`
                                  : ''
                              }
                              inputLabel={'Añade Productos'}
                              //Redux
                              asignTree={'productToAsign'}
                              cantidadDisponible={sliceState?.productToSearch?.cantidad}
                              valueToAsignSelector={sliceState?.productToAsign}
                              asignedListSelector={sliceState?.productosOrdenCompra}
                              asignedListString={'productosSalida'}
                              salidaId={urlParam}
                              cliente={sliceState?.cliente}
                              enCalidadDe={sliceState?.salida?.enCalidadDe}
                              productToSearch={sliceState?.productToSearch}
                              //Function Handlers

                              handleSelect={handleAsingProducto}
                              handleCancel={handleCancelAsingProducto}
                              handlePostRelation={handlePostRelation}
                              handleCerrar={handleCerraProductoOcc}
                              handleUpdate={handlePutOrdenCompraProveedor}
                            />}

                        />
                      )}
                    </div>
                  </div>
                )}

                <FlexContainer className='flex-col'>
                  <div>
                    <DocsCard>
                      <DocsCardLayoutMenu>
                        <DotsDropdown vigente={sliceState?.salida?.vigente}>
                          {sliceState?.salida?.vigente ? (
                            <React.Fragment>
                              {sliceState?.productosSalida?.length === 0 && (
                                <DropdownItemButton
                                  onClick={() => setDeleteModalIsVisible(true)}>
                                  <TrashIcon
                                    className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                    aria-hidden='true'
                                  />
                                  Borrar Salida
                                </DropdownItemButton>
                              )}
                            </React.Fragment>
                          ) : (
                            <DropdownItemButton>
                              <PencilAltIcon
                                className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                aria-hidden='true'
                              />

                              <PDFDownloadLink
                                document={
                                  <SalidaPDF
                                    mely={
                                      sliceState?.productosSalida[0]?.inventario ===
                                      'MELYUSA'
                                        ? { ...datosMelyUsa }
                                        : { ...datosMelyMx }
                                    }
                                    info={{
                                      titulo: 'NOTA DE SALIDA',
                                      producto: ' CAJAS',
                                      numero: sliceState?.salida?.id,
                                      tipo: 'OCC',
                                      fecha: sliceState?.salida?.createdAt
                                        ? timeStampToDDMMYY(
                                          sliceState?.salida?.createdAt)
                                        : '',
                                      enCalidadDe: sliceState?.salida?.enCalidadDe,
                                    }}
                                    numeroOC={filterArrByObjPropertyName(
                                      sliceState?.productosSalida,
                                      'numeroOcc',
                                    )}
                                    list={sliceState?.productosSalida}
                                    cliente={{
                                      razonSocial: sliceState?.cliente?.razonSocial,
                                      direccionFiscal: {
                                        calle: sliceState?.cliente?.direccionFiscal?.calle,
                                        numero: sliceState?.cliente?.direccionFiscal?.numero,
                                        ciudad: sliceState?.cliente?.direccionFiscal?.ciudad,
                                        estado: sliceState?.cliente?.direccionFiscal?.estado,
                                        cp: sliceState?.cliente?.direccionFiscal?.cp,
                                        pais: sliceState?.cliente?.direccionFiscal?.pais,
                                      },
                                    }}
                                  />
                                }
                                fileName={`Salida_${sliceState?.salida?.id}.pdf`}
                              >
                                {({ blob, url, loading, error }) =>
                                  loading
                                    ? 'Preparando Documento...'
                                    : 'Descargar'
                                }
                              </PDFDownloadLink>
                            </DropdownItemButton>
                          )}
                          {sliceState?.salida?.vigente &&
                            sliceState?.productosSalida?.length > 0 && (
                              <div>
                                {sliceState?.salida?.seEmbarca ? (
                                  <DropdownItemButton
                                    onClick={() => setConfirmModalIsVisibleCerrar(
                                      true)}
                                  >
                                    <TruckIcon
                                      className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                      aria-hidden='true'
                                    />
                                    Embarcar Salida
                                  </DropdownItemButton>
                                ) : (
                                  <DropdownItemButton
                                    onClick={() => setConfirmModalIsVisibleMover(
                                      true)}
                                  >
                                    <LogoutIcon
                                      className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                      aria-hidden='true'
                                    />
                                    Mover a Inventario Clientes
                                  </DropdownItemButton>
                                )}
                              </div>
                            )}
                        </DotsDropdown>
                      </DocsCardLayoutMenu>

                      {confirmModalIsVisibleCerrar && (
                        <ConfirmModal
                          title={'Embarcar Salida'}
                          message={
                            'La salida ya no se podrá modificar y los productos saldrán del inventario.'
                          }
                          onConfirm={() => {
                            dispatch(
                              cerrarSalida({
                                salida: sliceState.salida,
                                productosSalida: sliceState.productosSalida,
                                authCtx,
                              }),
                            );
                          }}
                          onCancel={() => setConfirmModalIsVisibleCerrar(false)}
                        />
                      )}
                      {confirmModalIsVisibleMover && (
                        <ConfirmModal
                          title={'Transferir a inventario del cliente'}
                          message={
                            'Los productos ingresáran al inventario del cliente, esta acción no puede deshacerse. '
                          }
                          onConfirm={() => {
                            dispatch(
                              moverAInventarioClientes({
                                salida: sliceState.salida,
                                productosSalida: sliceState.productosSalida,
                              }),
                            );
                          }}
                          onCancel={() => setConfirmModalIsVisibleMover(false)}
                        />
                      )}
                      {deleteModalIsVisible && (
                        <DeleteModal
                          title={'Borrar Salida'}
                          onConfirm={(sliceState) => {
                            console.log('click');

                            handleDelete(sliceState.id);

                            setDeleteModalIsVisible(false);
                          }}
                          onCancel={() => setDeleteModalIsVisible(false)}
                        >
                          Esta Acción no puede desacerse
                        </DeleteModal>
                      )}

                      <DocsCardLayoutLogo>
                        <LogoMely className='h-16 w-auto' />
                      </DocsCardLayoutLogo>
                      <DocsCardLayoutDatosDoc>
                        <DocsCardDatosDoc
                          title={`SALIDA ${sliceState?.productosSalida[0]?.inventario}`}
                          number={sliceState?.salida?.id}
                          date={
                            sliceState?.salida?.createdAt
                              ? timeStampToDDMMYY(sliceState?.salida?.createdAt)
                              : ''
                          }
                        />
                      </DocsCardLayoutDatosDoc>
                      <DocsCardLayoutInfoMely>
                        <InfoMely
                          inventario={sliceState?.inventario?.nombre}
                          datosMely={
                            sliceState?.productosSalida[0]?.inventario ===
                            'MELYUSA'
                              ? datosMelyUsa
                              : datosMelyMx
                          }
                        />
                      </DocsCardLayoutInfoMely>
                      <DocsCardLayoutTwoCols>
                        <DocsCardDatosFiscales
                          sliceState={sliceState?.cliente} />

                        <div
                          className='col-span-1 mt-12 text-sm text-right ml-auto '>
                          <div>
                            <span className='mr-2'>EN CALIDAD DE:</span>
                            <span className='font-medium'>
                              {` ${sliceState?.salida?.enCalidadDe}`}
                            </span>
                          </div>
                          <div>
                            <span className='mr-2'>SE EMBARCA:</span>
                            <span className='font-medium'>
                              {` ${sliceState?.salida?.seEmbarca
                                ? 'SI'
                                : 'NO'}`}
                            </span>
                          </div>
                          <div>
                            <span className='mr-2'>FECHA:</span>
                            <span className='font-medium'>
                              {sliceState?.salida?.createdAt
                                ? timeStampToDDMMYY(
                                  sliceState?.salida?.createdAt)
                                : ''}
                            </span>
                          </div>
                        </div>
                      </DocsCardLayoutTwoCols>

                      <div className='col-span-6'>
                        <>
                          <ProductosSalida
                            //UI
                            cardTitle={'Productos Salida'}
                            //Redux
                            vigente={sliceState?.salida?.vigente}
                            asignTree={'productToAsign'}
                            removeTree={'productToRemoveAsign'}
                            valueToAsignSelector={sliceState?.productToAsign}
                            asignedListSelector={sliceState?.productosSalida}
                            asignedListString={'productosSalida'}
                            //Table
                            cantidadPropertyName={'cantidadSalida'}
                            //Function Handlers

                            handleSelect={handleAsingProducto}
                            handleCancel={handleCancelAsingProducto}
                            handlePostRelation={handlePostRelation}
                            handleErrase={handleEraseRelation}
                            handleUpdate={handlePutOrdenCompraProveedor}
                            handleCreateCuentaPorPagar={handleCreateCuentaPorPagar}
                            handleUpdateProductoSalida={() => {
                              getRelationHandler(relationProductosSalida_URL,
                                'productosSalida');
                            }}
                          />
                        </>
                      </div>
                      {
                        //#endregion
                      }
                    </DocsCard>
                  </div>
                </FlexContainer>
              </main>
            </FlexContainer>
          </div>
        )}
      </React.Fragment>
    </>
  );
};
export default EditSalida;
