import React, { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import { CardButton, CardContainer, CardTitle } from './UpdateCardUI';

const JoinedTable = (props) => {
  const authCtx = useContext(AuthContext);

  const dispatch = useDispatch();
  const history = useHistory();
  const { object, title, url, prefixSelector, stateTree } = props;
  const putForeignEntity = (requestObj, requestUrl, stateTree) => {
    dispatch(props.putJoinedTableThunk({ requestObj, requestUrl, stateTree, history, authCtx }));
  };

  let reset = false;

  return (
    <CardContainer>
      <CardTitle>{title}</CardTitle>
      {object && (
        <React.Fragment>
          {Object.entries(object)
            .sort()
            .map((item) => {
              // console.log('prefixSelector')
              // console.log(prefixSelector)
              return (
                <props.modelInputsAndSelectors
                  key={`${prefixSelector}${item[0]}`}
                  label={item[0]}
                  selectorName={`${prefixSelector}${item[0]}`}
                  defaultValue={item[1]}
                  reset={reset}
                />
              );
            })}
        </React.Fragment>
      )}
      {!props?.disableSave&&<CardButton onClick={() => putForeignEntity(object, url + '/' + object.id, stateTree)}>
        Guardar
      </CardButton>}
    </CardContainer>
  );
};

export default JoinedTable;
