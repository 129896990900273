import React, { useEffect, useContext } from 'react';
import { editEntityActions, putEntitiy } from './editEntity-slice';
import { useSelector, useDispatch } from 'react-redux';
import AuthContext from '../../../../store/auth-context';

import { InputCornerHint } from '../../../SharedUi/Inputs';
import { CardButton } from '../../../SharedUi/UpdateCardUI';
import { BooleanHelper } from '../../../BooleanHelper';
import { useAuth } from '../../../../context/AuthProvider';

export const EditEntity = ({
  getRequest = false,
  name = '',
  entity = [],
  editFields = [],
  readOnlyFields = [],
  url,
  callback,
}) => {
  const sliceState = useSelector((state) => state.editEntity);
  const dispatch = useDispatch();
  const { roleId ,token} = useAuth();


  useEffect(() => {
    dispatch(editEntityActions.reset());
    dispatch(editEntityActions.setState({ entity, editFields, readOnlyFields, url }));
  }, [dispatch, entity, editFields, readOnlyFields, url]);

  return (
    <div className="flex max-w-screen-sm">
      <div className="grid-cols-6 ">
        <h2 className=" mt-8 uppercase text-xl font-semibold text-center">{name}</h2>
        <div className={'my-2'} />

        {sliceState.readOnlyFields.map((item) => {
          return (
            <InputCornerHint
              name={item.label}
              label={item.label}
              value={sliceState.entity[item.path]}
              borderColor={'border-gray-100'}
              readOnly={true}
            />
          );
        })}

        {sliceState.editFields.map((item) => {

          return (
            <>
              {item?.type === 'boolean' ? (
                BooleanHelper({
                  label: item.label,
                  value: sliceState.entity[item?.path],
                  onChange: () => {
                    dispatch(
                      editEntityActions.boolean({
                        value: sliceState.entity[item?.path],
                        key: item.key,
                      }),
                    );
                  },
                })
              ) : (
                <InputCornerHint
                  name={item.label}
                  label={item.label}
                  value={sliceState.entity[item.path]}
                  onChange={(e) => {
                    dispatch(editEntityActions.change({ value: e.target.value, key: item.key }));
                  }}
                  borderColor={'border-orange-300'}
                />
              )}
            </>
          );
        })}

        <div className={'text-center'}>
          {!sliceState.succes &&
          (roleId === 1 || roleId === 2 || roleId === 4) ? (
            <CardButton
              onClick={() => {
                dispatch(
                  putEntitiy({
                    requestObj: sliceState.entity,
                    requestUrl: url,
                    reduxStateTree: sliceState.entity,
                    authCtx:{
                      token
                    },
                  }),
                );
              }}
              bgColor="orange"
            >
              Guardar
            </CardButton>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
