import react from 'react';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/solid';

const OrderTableButton = (props) => {
  let icon;
  props.sortAscending
    ? (icon = <ArrowDownIcon className="h-3 w-3" aria-hidden="true" />)
    : (icon = <ArrowUpIcon className="h-3 w-3" aria-hidden="true" />);

  const defaultClass =
    'ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 bg-darkBlue';
  let color = ` bg-${props.color}-600 hover:bg-${props.color}-700 focus:ring-${props.color}-500`;
  if (props.color === 'orangeMely') color = ` bg-${props.color}`;
  if (props.color === 'darkBlue') color = ` bg-${props.color}`;

  return (
    <button onClick={props.handleSortBy} className={defaultClass + color} type="button">
      {icon}
    </button>
  );
};
export default OrderTableButton;
