import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiURL } from '../Util/apiURL';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL();
const selectFetchState = {
  value: { id: 0, nombre: '' },
  showEditMode: false,
  seleccion: { id: 0, nombre: '' },
  options: [],
  loading: false,
};

export const fetchSelectOptions = createAsyncThunk(
  'selectFetch/fetchOptions',
  async (payload, { rejectWithValue }) => {
    const { authCtx, url } = payload;
    let response;
    try {
      console.log(`fetchSelectOptions---------->>>>`);
      console.log(`${apiHOST}${url}`);
      console.log(`authCtx---------->>>>`);
      console.log(authCtx);
      response = await axios.get(`${apiHOST}${url}`, {
        headers: {
          ...authorizationHeader()        },
      });
      console.log(response);
      return response.data;
    } catch (err) {
      console.log('err----->>>>');
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const insertValueOnSelect = createAsyncThunk(
  'selectFetch/insertValue',
  async ({ url, request }) => {
    try {
      console.log('Request select fetch ---->');
      console.log(request);
      console.log('URL select fetch ---->');
      console.log(url);

      // const response = axios
      //   .post(`${apiHOST}divisas/`, request)
      //   .then((res) => {
      //     console.log('Submit RES');
      //     console.log(res);
      //   })
      //   .catch((err) => console.log(err));

      // return response;
    } catch (err) {
      return err;
    }
  },
);

export const saveSeletion = createAsyncThunk();

const selectFetchSlice = createSlice({
  name: 'sf',
  initialState: selectFetchState,
  reducers: {},
  extraReducers: {
    [fetchSelectOptions.fulfilled]: (state, action) => {
      state.options = action.payload;
      state.loading = false;
    },
    [fetchSelectOptions.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchSelectOptions.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default selectFetchSlice;

export const selectFetchActions = selectFetchSlice.actions;
