export const asingDispatcher = ({ dispatch, sliceActions, event }) =>
  dispatch(sliceActions.asignReducer({ ...event }));

export const resetDispatcher = ({ dispatch, sliceActions }) =>
  changeDispatcher({ dispatch, sliceActions, field: 'reset' });

export const changeDispatcher = ({ dispatch, sliceActions, value, field }) =>
  dispatch(sliceActions.change({ value, field }));

export const ivaDispatcher = ({ dispatch, sliceActions, sliceState }) =>
  changeDispatcher({ dispatch, sliceActions, value: !sliceState.iva, field: 'iva' });

export const seEmbarcaDispatcher = ({ dispatch, sliceActions, sliceState }) =>
  changeDispatcher({ dispatch, sliceActions, value: !sliceState.seEmbarca, field: 'seEmbarca' });

export const fechaEntregaDispatcher = ({ dispatch, sliceActions, value }) =>
  changeDispatcher({ dispatch, sliceActions, value, field: 'fechaEntrega' });

export const fechaVencimientoDispatcher = ({ dispatch, sliceActions, value }) =>
  changeDispatcher({ dispatch, sliceActions, value, field: 'fechaVencimiento' });

export const fechaFacturaDispatcher = ({ dispatch, sliceActions, value }) =>
  changeDispatcher({ dispatch, sliceActions, value, field: 'fechaFactura' });

export const facturaDispatcher = ({ dispatch, sliceActions, value }) =>
  changeDispatcher({ dispatch, sliceActions, value, field: 'factura' });
