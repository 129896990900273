//TODO
// 1. Create a separate conmponent that displays entrada Rechazo, this component should handle update and deletion
// 2. This component should use a hook for storing the state, such as cantidad
// 3. EditRechazoClieste would be a "Parent Component", it wil use the hook use Cantidad and Insert it in the CreateSalidaComponent etc

import React, { useState, useEffect, useContext, Fragment } from 'react';
import { apiURL } from '../../Util/apiURL';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import EntradaPDF from '../DocsToPrint/EntradaPDF';
import axios from 'axios';
import { PencilAltIcon, TrashIcon, CheckIcon } from '@heroicons/react/solid';
import { InputHelper } from '../InputHelpers';

import {
  editEntradaInventarioRechazosActions as sliceActions,
  deleteEntrada
  , getEntradaAndUpdateMax,
} from '../../store/editEntradaInventarioRechazos-slice';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import DotsDropdown from '../DropdownMenu/DotsDropdown';
import DropdownItemButton from '../DropdownMenu/DropdownItemButton';
import InfoMely, { datosMelyMx, datosMelyUsa } from '../InfoMely/InfoMely';
import DocsCard from '../SharedUi/DocsCard';

import {
  DocsCardLayoutLogo,
  DocsCardLayoutDatosDoc,
  DocsCardLayoutMenu,
  DocsCardLayoutTwoCols,
  DocsCardDatosFiscales,
  DocsCardLayoutInfoMely,
} from '../SharedUi/DocsCardLayout';
import DocsCardDatosDoc from '../SharedUi/DocsCardDatosDoc';
import LogoMely from '../SharedUi/LogoMely';
import IconButton from '../TailwindUI/IconButton';
import DeleteModal from '../UI/Modals/DeleteModal';
import ConfirmModal from '../UI/Modals/ConfirmModal';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';
import { InputCornerHint } from '../SharedUi/Inputs';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { setDateStringDDMMYYYY } from '../../Util/get-date';
import { GenericModal } from '../UI/Generics';
import { CreateEntity } from '../UI/Generics/CreateEntity/CreateEntity';
import UseNumberInputWithMinMax from '../../hooks/UseNumberInputWithMinMax';
import { ProductosEntradaRechazos } from './ProductosEntradaRechazos';

import { CreateSalidaInventarioRechazo } from './CreateSalidaInventarioRechazo';
import { EditSalidaInventarioRechazo } from './EditSalidaInventarioRechazo';

const EditRechazoCliente = () => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [editEntity, setEditEntity] = useState(null);

  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);

  const [addProductActive, setAddProductActive] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);
  const [useEffectVariable, setUseEffectVariable] = useState(0);

  const fetchEntrada = () => {
    dispatch(getEntradaAndUpdateMax({ urlParam, authCtx }));
  };

  const {
    cantidad,
    handleInputChange,
    minValue,
    maxValue,
    setMaxValue,
    setInputValueToMaxValue,
    setInputValueToMinValue,
    inputError,
  } = UseNumberInputWithMinMax();

  useEffect(() => {
    setFirstLoad(false);
    if (!firstLoad) {
      fetchEntrada();

    }
  }, [useEffectVariable]);

  useEffect(() => {
    fetchEntrada();

    // getRelationHandler(relationProductosEntrada_URL, 'productosEntrada');
  }, []);

  const sliceState = useSelector(
    (state) => state.editEntradaInventarioRechazos);

  const apiHOST = apiURL();
  const urlParam = location.pathname.split('/')[2];
  const updateUrlSalidas = `salidas-inventario-rechazos`;
  const deleteUrl = `entrada-rechazo/${sliceState?.entradaRechazo?.id}`;

  const filterArrByObjPropertyName = (externalArray, propertyname) => {
    const arr = [];
    externalArray.forEach((i, index) => {
      let filter = arr.filter((e) => e === Number(i[propertyname]));
      console.log('filter-------');
      console.log(filter);
      console.log(filter.length);
      if (filter.length === 0) {
        arr.push(i[propertyname]);
      }
    });
    return arr;
  };

  const handleResetProductosOrdenescompra = () => {
    dispatch(sliceActions.resetProductosOrdenCompra());
  };
  const handleResetPrpductosToSearch = () => {
    dispatch(sliceActions.resetProductToSearch());
  };
  const handleResetAddProduct = () => {
    handleResetPrpductosToSearch();
    handleResetProductosOrdenescompra();
    setAddProductActive(false);
  };

  const handlePutSalida = () => {
    // const requestObj = {
    //   cliente_id: sliceState?.clienteId,
    //   entrada_ir_id: sliceState?.id,
    //   info: {
    //     cantidadDevoluciones: sliceState?.cantidadDevoluciones,
    //     observacionDevoluciones: sliceState?.observacionDevoluciones,
    //     cantidadRetenidas: sliceState?.cantidadRetenidas,
    //     observacionRetenidas: sliceState?.observacionRetenidas,
    //     cantidadMerma: sliceState?.cantidadMerma,
    //     observacionesMerma: sliceState?.observacionesMerma,
    //     cantidadRetrabajo: sliceState?.cantidadRetrabajo,
    //     observacionesRetrabajo: sliceState?.observacionesRetrabajo,
    //   },
    // };
    // console.log('requestObj----->>>>>>>');
    // console.log(requestObj);
    // dispatch(
    //   putEntrada({
    //     requestObj,
    //     requestUrl: updateUrlSalidas,
    //     authCtx,
    //     reduxStateTree: { ...sliceState },
    //   }),
    // );
  };
  const handlePutEntrada = () => {
    // const requestObj = {
    //   cliente_id: sliceState?.clienteId,
    //   entrada_ir_id: sliceState?.id,
    // };
    // console.log('requestObj----->>>>>>>');
    // console.log(requestObj);
    // dispatch(
    //   putEntrada({
    //     requestObj,
    //     requestUrl: updateUrlSalidas,
    //     authCtx,
    //     reduxStateTree: { ...sliceState },
    //   }),
    // );
  };

  const handleDelete = () => {
    console.log({
      requestUrl: deleteUrl,
      sliceState,
      history,
      authCtx,
    });
    dispatch(
      deleteEntrada({
        requestUrl: deleteUrl,
        sliceState,
        history,
        authCtx,
      }),
    );
  };





  return (
    <>
      {/* <div style={{ minWidth: '30vw', height: '100vh' }}>

      <PDFViewer width={600} height={600}>
        <EntradaPDF
          mely={
            sliceState?.inventario?.nombre === 'MELYUSA'
              ? { ...datosMelyUsa }
              : { ...datosMelyMx }
          }
          info={{
            titulo: 'NOTA DE ENTRADA',
            producto: ' CAJAS',
            numero: sliceState?.entrada?.id,
            tipo: 'OCP',
            fecha: sliceState?.entrada?.createdAt?.split('T')[0],
            enCalidadDe: sliceState?.entrada?.enCalidadDe?.toUpperCase(),
          }}
          numeroOC={filterArrByObjPropertyName(sliceState.productosEntrada, 'numeroOcp')}
          list={sliceState?.productosEntrada}
          cliente={{
            razonSocial: sliceState?.proveedor?.razonSocial,
            direccionFiscal: {
              calle: sliceState?.proveedor?.direccionFiscal?.calle,
              numero: sliceState?.proveedor?.direccionFiscal?.numero,
              ciudad: sliceState?.proveedor?.direccionFiscal?.ciudad,
              estado: sliceState?.proveedor?.direccionFiscal?.estado,
              cp: sliceState?.proveedor?.direccionFiscal?.cp,
              pais: sliceState?.proveedor?.direccionFiscal?.pais,
            },
          }}
        />
      </PDFViewer>


    </div> */}
      <React.Fragment>
        {sliceState.error || sliceState.loading ? (
          <LifeCycleFeedback sliceState={sliceState} />
        ) : (
          <div className='flex-1 flex flex-col overflow-hidden'>
            {/* Main content */}

            <FlexContainer className='flex-1 items-stretch  overflow-hidden'>
              <main className='flex-1 overflow-y-auto'>
                {/*TAB CODE */}
                {/*TAB CODE*/}
                {/* Primary column */}

                {/* <div>{JSON.stringify(sliceState)}</div> */}
                <FlexContainer className='flex-col'>
                  <div className=''>
                    <DocsCard>
                      <DocsCardLayoutMenu>
                        <DotsDropdown vigente={true}>
                          <DropdownItemButton
                            onClick={() => setConfirmModalIsVisible(true)}>
                            <PencilAltIcon
                              className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                              aria-hidden='true'
                            />
                            Editar
                          </DropdownItemButton>
                          <DropdownItemButton
                            onClick={() => setDeleteModalIsVisible(true)}>
                            <PencilAltIcon
                              className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                              aria-hidden='true'
                            />
                            Borrar
                          </DropdownItemButton>
                        </DotsDropdown>
                      </DocsCardLayoutMenu>

                      {/* {confirmModalIsVisible && (
                        <ConfirmModal
                          title={'Confirmar Entrada'}
                          message={
                            'Podrás imprimir la nota de entrada pero ya no podrás editar el documento.'
                          }
                          onConfirm={() => {
                            dispatch(
                              cerrarEntrada({
                                entrada: sliceState.entrada,
                                productosEntrada: sliceState.productosEntrada,
                                authCtx,
                              }),
                            );
                          }}
                          onCancel={() => setConfirmModalIsVisible(false)}
                        />
                      )}
                      */}
                      {deleteModalIsVisible && (
                        <DeleteModal
                          title={'Borrar Entrada Rechazo'}
                          item={sliceState?.entradaRechazo?.id}
                          message={""}
                          onConfirm={(sliceState) => {
                            handleDelete(sliceState?.entradaRechazo?.id);
                            setDeleteModalIsVisible(false);
                          }}
                          onCancel={() => setDeleteModalIsVisible(false)}
                        >
                          Esta Acción no puede desacerse
                        </DeleteModal>
                      )}

                      <DocsCardLayoutLogo>
                        <LogoMely className='h-16 w-auto' />
                      </DocsCardLayoutLogo>

                      <DocsCardLayoutDatosDoc className='text-xs'>
                        <DocsCardDatosDoc
                          title={'RECHAZO'}
                          number={sliceState.entradaRechazo.id}
                          date={setDateStringDDMMYYYY(
                            sliceState.entradaRechazo.createdAt)}
                        />
                      </DocsCardLayoutDatosDoc>

                      <div className=' mt-4' />

                      <DocsCardLayoutInfoMely>
                        <h2
                          className=' mb-4 text-xl bold text-left'>ENTRADA</h2>
                        <div className='col-span-6  md:col-span-2 '>
                          <InputCornerHint label='Cliente' readOnly
                                           value={sliceState.entradaRechazo.cliente.razonSocial} />
                        </div>

                        <div className='col-span-6 mt-4'>
                          {sliceState.entradaRechazo.productosEntradaRechazo.map(
                            (productosEntradaRechazo) =>
                              <ProductosEntradaRechazos
                                data={productosEntradaRechazo}
                              />,
                          )
                          }
                        </div>

                      </DocsCardLayoutInfoMely>


                    </DocsCard>

                    <div className='col-span-6  mt-4' />
                    {sliceState.cantidadMaximaProductoSalidaRechazo>0&&(
                    <DocsCardLayoutInfoMely>
                      <div className='col-span-6 mr-auto'>
                        <CardButton
                          bgColor='orange'
                          onClick={() => {
                            dispatch(
                              sliceActions.showCreateSalidaRechazoModal(
                                true));
                            dispatch(
                              sliceActions.asignCantidadMaximaProductoSalidaRechazo(
                                sliceState));
                          }}
                        >
                          Crear Salida Iventario Rechazo
                        </CardButton>
                      </div>
                      <IconButton

                      />
                    </DocsCardLayoutInfoMely>
                    )}

                    {sliceState.showCreateSalidaRechazoModal &&
                      <CreateSalidaInventarioRechazo sliceActions={sliceActions} sliceState={sliceState} />}


                    {sliceState.salidasRechazo.length > 0 &&
                      sliceState.salidasRechazo?.map(
                        salidaRechazo => <EditSalidaInventarioRechazo
                          key={JSON.stringify(salidaRechazo)}
                          sliceState={sliceState} sliceActions={sliceActions}
                          salidaRechazo={salidaRechazo} />)}

                    <div className='mb-4' />

                  </div>
                </FlexContainer>
              </main>
            </FlexContainer>

            {
              //#region Productos Entrada
            }

            {
              //#endregion
            }
          </div>
        )}
      </React.Fragment>
    </>
  );
};
export default EditRechazoCliente;
