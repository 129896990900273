import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../../Util/authorizationHeader';

const filter = (arr, key, filterBy, urlParam) =>
  arr.filter((item) => Number(item[key][filterBy]) === Number(urlParam));

export const getViewRequestThunk = (name, route, responseKey, key, filterBy) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { urlParam, authCtx } = payload;

    try {
      const response = await axios.get(`${route}`, {
        headers: {
           ...authorizationHeader()        },
      });
      let responseUpdated = filter(response.data[responseKey], key, filterBy, urlParam)[0];

      return { ...responseUpdated };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });

export const getDefaultViewRequestThunk = (name, route, responseKey) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { urlParam, authCtx } = payload;

    try {
      const response = await axios.get(`${route}/${urlParam}`, {
        headers: {
           ...authorizationHeader()        },
      });

      return response.data[responseKey][0];
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });

export const getDefaultRequestThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { urlParam, authCtx } = payload;

    try {
      const response = await axios.get(`${route}/${urlParam}`, {
        headers: {
           ...authorizationHeader()        },
      });

      console.log('response----->>>>>>>');
      console.log(response.data);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });
