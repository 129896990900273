import React from 'react';
import IconButton from '../TailwindUI/IconButton';

import { CardTitle } from '../SharedUi/UpdateCardUI';

import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';

import TableBodyCell from '../TailwindUI/TableBodyCell';
import { prettifyNumber } from '../../Util/prettifyNumber';

const ProductosSalidaIC = (props) => {
  //Function Handlers
  const handleErrase = (e) => {
    props.handleErrase({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };
  const handleUpdate = () => {
    props.handleUpdate();
  };

  return (
    <React.Fragment>
      <div>
        <CardTitle>{props.cardTitle}</CardTitle>

        {props.asignedListSelector?.length > 0 && (
          <div style={{ width: '100%', margin: 'auto' }}>
            <Table>
              <TableHeader>
                <TableRowHeader>
                  <TableHeaderCell>CANTIDAD</TableHeaderCell>
                  <TableHeaderCell>UNIDAD</TableHeaderCell>
                  <TableHeaderCell>DESCRIPCIÓN</TableHeaderCell>
                </TableRowHeader>
              </TableHeader>
              <TableBody>
                {props.asignedListSelector?.map((producto, personIdx) => {
                  console.log('producto');
                  console.log(producto);
                  return (
                    <React.Fragment>
                      <TableRow
                        key={producto.id}
                        className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                      >
                        <TableBodyCell>
                          {prettifyNumber(String(producto[props.cantidadPropertyName]))}
                        </TableBodyCell>
                        <TableBodyCell>{producto.unidad}</TableBodyCell>
                        <TableBodyCell>{producto.simbolo}</TableBodyCell>

                        <TableBodyCell>
                          {props.vigente && (
                            <IconButton
                              onClick={() => handleErrase(producto)}
                              color="gray"
                              icon="trash"
                              iconHeight={2.5}
                              iconWidth={2.5}
                            />
                          )}
                        </TableBodyCell>

                        {/* <IconButton
                        onClick={() =>
                          props.handleCreateCuentaPorCobrar(producto)
                        }
                        color="orange"
                        icon="dollar"
                        iconHeight={2.5}
                        iconWidth={2.5}
                      /> */}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default ProductosSalidaIC;
//test
