import React from 'react';
import {
  MobileNavigationItemNoChild,
} from '../NavBarMobile/MobileNavigationItemNoChild';
import {
  MobileNavigationItemParent,
} from '../NavBarMobile/MobileNavigationItemParent';
import { useSideBar } from '../../../hooks/useSideBar';
import { classNames } from '../../../Util/classNames';

const renderMobileNavigationItem = (item, closeSideBar) => {
  return !item.children
    ? (<MobileNavigationItemNoChild  key={JSON.stringify(item)} item={item}
                                    onClick={() => closeSideBar()} />)
    : (<MobileNavigationItemParent classNames={classNames}
                                   key={JSON.stringify(item)}
                                   item={item}
                                   onClick={() => closeSideBar()} />);
};
const renderNavBarSections = (navigation, elementIndex, closeSideBar) => (
  <>
    <div className='px-2 space-y-1'>
      {navigation.map(
        (item) => renderMobileNavigationItem(item, closeSideBar))}
    </div>
    {elementIndex > 1 &&
      <hr className='border-t border-gray-200 my-5' aria-hidden='true' />}

  </>
);
export const NavBar = ({ navBarSections }) => {
  const {
    closeSideBar,
  } = useSideBar();
  return (
    <nav aria-label='Sidebar' className='mt-5'>
      {navBarSections?.map((navBarsection, index) => renderNavBarSections(navBarsection,
        (navBarSections.length - index), closeSideBar))}
    </nav>
  );
};