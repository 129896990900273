import React from 'react';
import ReactDOM from 'react-dom';
import Card from './Card';
import Button from './Button';
import classes from './PagoModal.module.css';

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onConfirm} />;
};
const ModalOverlay = (props) => {
  return (
    <Card className={classes.cardModal}>
      <div className={classes.modal}>
        <header className={classes.header}>
          <h2 className=" font-bold text-gray-600">{props.title}</h2>
        </header>
        <div className={classes.content}>
          <p>{props.message}</p>
          <div>{props.children}</div>
        </div>
        <footer className={classes.actions}>
          {/* <Button onClick={props.onConfirm}>Okay</Button> */}
        </footer>
      </div>
    </Card>
  );
};
const PagoModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onConfirm={props.onConfirm} />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={props.title}
          message={props.message}
          onConfirm={props.onConfirm}
        >
          <div style={{maxWidth:'90vw' , overflow:'auto', margin:'auto'}}>
            {props.children}

          </div>
        </ModalOverlay>,
        document.getElementById('overlay-root')
      )}
    </React.Fragment>
  );
};

export default PagoModal;
