import Documentos from './Documentos';
// import CreateCliente from './CreateCliente';
// import EditCliente from './EditCliente';

export const documentosRoutes = [
  {
    name: 'Documentos',
    href: '/documentos',
    component: <Documentos />,
    exact: true,
  },
  // {
  //   name: 'Create Cliente',
  //   href: '/clientes/crear/',
  //   component: <CreateCliente />,
  //   exact: true,
  // },
  // {
  //   name: 'Edit Clientes',
  //   href: '/clientes/:id',
  //   component: <EditCliente />,
  //   exact: true,
  // },
];
