import React from 'react';
const DocsCardDatosDoc = (props) => {
  return (
    <React.Fragment>
      <div>{props.title}</div>
      <div className="text-red-600">No.{props?.number}</div>

      <div>FECHA</div>
      <div>{props.date}</div>
    </React.Fragment>
  );
};
export default DocsCardDatosDoc;
