import React from 'react';
import { DefatultItemsList } from '../SharedUi/DefaultItemsList';
import {
  getEntradasInventarioRechazos,
  entradasInventarioRechazosActions as sliceActions,
} from '../../store/entradas-inventario-rechazos-slice';
import { sortObjKeysFromArray } from '../../Util/sort-utils';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { setDateStringDDMMYYYY } from '../../Util/get-date';

const sortingArray = ['id', 'cantidad', 'simbolo', 'razonSocialClientes'];

const tableHeaderMapFn = (list, idx) => {
  let newObj = { ...list };
  newObj.cantidad = prettifyNumber(list.cantidad);
  newObj.createdAt = setDateStringDDMMYYYY(list.createdAt);
  delete newObj['unidadId'];
  delete newObj['createdBy'];
  delete newObj['productoId'];
  delete newObj['clienteId'];
  return sortObjKeysFromArray(newObj, sortingArray);
};

const EntradasInventarioRechazos = () => {
  return DefatultItemsList({
    sliceState: 'entradasInventarioRechazos',
    sliceActions,
    getItems: getEntradasInventarioRechazos,
    tableBodyMapFn: tableHeaderMapFn,
    tableHeaderMapFn,
  });
};

export default EntradasInventarioRechazos;
