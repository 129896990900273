import React, { useState, useEffect, useContext } from 'react';
import { distitoMateraAPI, apiURL } from '../../Util/apiURL';
import AuthContext from '../../store/auth-context';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCellAuto from '../TailwindUI/TableHeaderCellAuto';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useSelector, useDispatch } from 'react-redux';
import { getInventario, inventarioActions } from '../../store/inventario-slice';
import {
  getTotalByFecha,
  getInventarioClientesEntradas,
  getInventarioCliestesSalidas,
  getStock, getInventarioClientesTotales,
} from '../../store/inventarioClientesEntradasSalidas-slice';
import { useLocation } from 'react-router-dom';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { setTime } from '../../Util/get-date';
import { DateFormater } from '../../Util/DateFormatter';

// llamar a /views/inventario/?nombreInventario=MELLYMX&simbolo=producto3  para obtener el total, el nombre del inventario y el simbolo
// llamar a /views/inventario/entradas/?nombreInventario=MELLYMX&simbolo=producto2  para obtener los entradas de ese simbolo en ese inventario
// llamar a /views/inventario/salidas/?nombreInventario=MELLYMX&simbolo=producto1  para obtener los salidas de ese simbolo en ese inventario

const EntradasYSalidasClientes = () => {
  const apiHOST = apiURL();
  const location = useLocation();
  console.log('location.search');
  const queryInfo = location.search;
  console.log(location.search);
  console.log('location.search');
  const queryParams = new URLSearchParams(location.search);
  const nombreInventario = queryParams.get('nombreInventario');
  const simbolo = queryParams.get('simbolo');
  console.log(nombreInventario);
  console.log(simbolo);
  const tableMapper = (arr) =>
    arr?.map((obj) => {
      const formatedFecha = DateFormater.apply(obj.fecha);
      const newObj = { ...obj };
      newObj.fecha = formatedFecha['DD-MM-YYYY'] + ' ' +
        setTime(obj.fecha);
      delete newObj['enCalidadDe'];
      return newObj;
    });

  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const inventarioEntradasSalidas = useSelector(
    (state) => state.inventarioClientesEntradasSalidas);
  ;

  useEffect(() => {
    dispatch(inventarioActions.resetFilter());
    dispatch(getInventarioClientesEntradas({ urlParam: queryInfo, authCtx }));
    dispatch(getInventarioClientesTotales({ urlParam: queryInfo, authCtx }));
    dispatch(getInventarioCliestesSalidas({ urlParam: queryInfo, authCtx }));
    // dispatch(getStock({ urlParam: queryInfo, authCtx }));
  }, []);

  console.log('inventarioEntradasSalidas----->>>>>>>');
  console.log(inventarioEntradasSalidas);
  return (
    <>
      <div className='flex-1 flex flex-col overflow-hidden'>


        {/* Main content */}
        <FlexContainer className='flex-1 items-stretch  overflow-hidden '>
          <main className='flex-1 overflow-y-auto  '>
            {/* Primary column */}

            <FlexContainer className='flex-col   bg-gray-100 py-2'>
              {/* {JSON.stringify(inventarioEntradasSalidas.stock)} */}
              <div
                className='shadow w-auto border-b border-gray-200  rounded-t rounded-b-lg bg-white  m-2 pb-3  overflow-x-auto  '>
                {/* {JSON.stringify(inventarioEntradasSalidas.entradas)} */}
                <div className='flex'>
                  <div
                    className=' flex-initial bg-orangeMely  uppercase text-white text-left text-xl p-3 w-1/3'>
                    {inventarioEntradasSalidas?.stock?.map(
                      (i) => i.razonSocial)}
                  </div>
                </div>
                <div className='flex'>
                  <div
                    className=' flex-initial bg-darkBlueLight text-white text-left text-sm p-3 w-1/6'>
                    {inventarioEntradasSalidas?.stock?.map(
                      (i) => i.simbolo)}
                  </div>
                  <div
                    className=' flex-initial bg-darkBlueLight text-white text-left text-sm p-3 w-1/6'>
                    {inventarioEntradasSalidas?.stock?.map((i) =>
                      prettifyNumber(i.cantidad.toString()),
                    )}
                  </div>
                </div>


                <div className=' grid grid-cols-2'>
                  <div className='border-r-8 border-white'>
                    <div className='font-bold p-4 text-darkBlueLight'>ENTRADA
                    </div>
                    <Table>
                      <TableHeader>
                        <TableRowHeader>
                          <TableHeaderCellAuto
                            list={tableMapper(
                              inventarioEntradasSalidas?.entradas)}
                          />
                        </TableRowHeader>
                      </TableHeader>

                      <TableBody>
                        {tableMapper(inventarioEntradasSalidas?.entradas).map(
                          (producto, personIdx) => {
                            const productoClone = { ...producto };
                            productoClone.cantidad = prettifyNumber(
                              String(producto.cantidad));

                            return (
                              <React.Fragment>
                                <TableRow
                                  key={producto + personIdx + producto.simbolo +
                                    producto.cantidad}
                                  className={personIdx % 2 === 0
                                    ? 'bg-white'
                                    : 'bg-gray-50'}
                                >
                                  <TableBodyCellAuto linkOff={true}
                                                     list={productoClone} />

                                </TableRow>
                              </React.Fragment>
                            );
                          },
                        )}
                        <TableRow>
                          <TableBodyCell colspan={'5'}
                                         className='text-right'></TableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <div className='border-r-8 border-white'>
                    <div className='font-bold p-4 text-darkBlueLight'>SALIDA
                    </div>
                    <Table>
                      <TableHeader>
                        <TableRowHeader>
                          <TableHeaderCellAuto
                            list={tableMapper(
                              inventarioEntradasSalidas?.salidas)}
                          />
                        </TableRowHeader>
                      </TableHeader>
                      <TableBody>
                        {tableMapper(inventarioEntradasSalidas.salidas).map(
                          (producto, personIdx) => {
                            const productoClone = { ...producto };
                            productoClone.cantidad = prettifyNumber(
                              String(producto.cantidad));

                            return (
                              <React.Fragment>
                                <TableRow
                                  key={producto + personIdx + producto.simbolo +
                                    producto.cantidad}
                                  className={personIdx % 2 === 0
                                    ? 'bg-white'
                                    : 'bg-gray-50'}
                                >
                                  <TableBodyCellAuto
                                    url={'/salidas-inventario-clientes'}
                                    list={productoClone}
                                  />

                                </TableRow>
                              </React.Fragment>
                            );
                          },
                        )}
                        <TableRow>
                          <TableBodyCell colspan={'5'}
                                         className='text-right'></TableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            </FlexContainer>
          </main>
        </FlexContainer>
      </div>
    </>
  );
};
export default EntradasYSalidasClientes;
