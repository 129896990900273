export const TableRowHeader = ({ hideOnMobile, children, className }) => (
  <th
    scope='col'
    className={`${hideOnMobile
        ? 'hidden '
        : ''}` +
      `${className ? className : ''}`}
  >
    {children}
  </th>
);