import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';

import { hidrateArrayFromObjectKeys } from '../Util/hidrate-array-from-obj-keys';
import { authorizationHeader } from '../Util/authorizationHeader';

const filterSeleccion = (values, selectedValueId) => {
  const filterdValue = values.filter((item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const apiHOST = apiURL();
const initialState = {
  displayAll: false,
  displayLote: false,
  inventario: [],
  display: [],
  displayFiltered: [],
};

export const getInventario2 = createAsyncThunk('producto/getInventario2', async (payload) => {
  const { authCtx, url = 'views/inventarios' } = payload;
  try {
    const response = await axios.get(`${apiHOST}${url}`, {
      headers: {
        ...authorizationHeader()      },
    });
    console.log('response Data get inventario ----slice');
    console.log(response);

    return response.data;
  } catch (err) {
    return err;
  }
});

const handleFilteredItems = (arr) => {
  if (arr.length > 0) {
    const obj = arr.reduce((prev, curr) => {
      const [key, value] = curr;
      prev[key] = value;
      return prev;
    }, {});

    return hidrateArrayFromObjectKeys(obj);
  }
  return [];
};
const inventarioSlice2 = createSlice({
  name: 'getInventario2',
  initialState: initialState,
  reducers: {
    shouldDisplayAll(state, action) {
      console.log('action----->>>>>>>');
      console.log(action);
      state.displayAll = action.payload;
    },
    shouldDisplayLote(state, action) {
      console.log('action----->>>>>>>');
      console.log(action);
      state.displayLote = action.payload;
    },
    resetFilter(state, action) {
      state.filterSearchValue = null;
    },
    resetDisplayFiltered(state, action) {
      state.displayFiltered = [];
    },
    filterBy(state, action) {
      const value = String(action.payload.value).toUpperCase();
      state.filterSearchValue = value;
      // console.log('action.payload' + JSON.stringify(action.payload));
      console.log('pepe++++' + value);
      console.log('action.payload.valueeeee----->>>>>>>' + action.payload.value);
      console.log('action.payload.valueeeee----->>>>>>>' + action.payload.raw.inventario);

      if (value !== '') {
        console.log(value === '');
        try {
          const inventario = action.payload.raw.inventario;

          console.log('inventario search----->>>>>>>');
          console.log(inventario);
          const inventarioFiltered = Object.entries(inventario).filter(([key, objValue]) => {
            console.log('filtering item----->>>>>>>');

            return key.toUpperCase().includes(value);
          });
          console.log('inventarioFiltered----->>>>>>>');
          console.log(inventarioFiltered);
          console.log(' handleFilteredItems  inventarioFiltered----->>>>>>>');
          console.log(handleFilteredItems(inventarioFiltered));
          console.log(' handleFilteredItems  inventarioFiltered----->>>>>>>');

          state.displayFiltered = handleFilteredItems(inventarioFiltered);
        } catch (err) {}
      } else if (value === '') {
        console.log('value  === ');

        state.displayFiltered = [];
      }
    },
    orderBy(state, action) {
      state.orderBy = action.payload;
      let payloadValue = action.payload;
      let sortFunction = (a, b) => {
        if (state.sortAscending) {
          if (a[payloadValue] > b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] < b[payloadValue]) {
            return -1;
          }
        }
        if (!state.sortAscending) {
          if (a[payloadValue] < b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] > b[payloadValue]) {
            return -1;
          }
        }
        return 0;
      };
      if (payloadValue === 'cp') {
        sortFunction = (a, b) => {
          if (state.sortAscending) {
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return -1;
            }
          }
          if (!state.sortAscending) {
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return -1;
            }
          }
          return 0;
        };
      }

      state.display.sort(sortFunction);
    },
    sortAscending(state, action) {
      state.sortAscending = !action.payload;
      const sortFunction = () => {
        if (state.sortAscending === action.payload) {
          return 1;
        }
        if (state.sortAscending !== action.payload) {
          return -1;
        }
      };
      state.display.sort(sortFunction);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInventario2.fulfilled, (state, action) => {
      const inventario = action.payload;
      console.log('inventario----->>>>>>>');
      console.log(inventario);
      console.log('hidrateArrayFromObjectKeys(inventario);----->>>>>>>');
      console.log(hidrateArrayFromObjectKeys(inventario));
      state.inventario = inventario;
      state.display = hidrateArrayFromObjectKeys(inventario);

      state.filterSearchValue = '';
    });
  },
});

export default inventarioSlice2;

export const inventarioActions2 = inventarioSlice2.actions;
