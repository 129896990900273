import React from 'react';
import Table from '../TailwindUI/Table';
import { prettifyNumber } from '../../Util/prettifyNumber';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { Link } from 'react-router-dom';
const InventarioTabla = (props) => {
  console.log('props----->>>>>>>');
  console.log(props);
  const reducer = (previousValue, currentValue) => Number(previousValue) + Number(currentValue);
  const total = [];
  props.lista?.map((i) => total.push(i.total));

  return (
    <div className="mb-12" style={{ maxWidth: '300px' }}>
      <h1>{props.nombre}</h1>
      <Table>
        <TableHeader>
          <TableRowHeader>
            <TableHeaderCell>Símbolo</TableHeaderCell>
            <TableHeaderCell>Stock</TableHeaderCell>
          </TableRowHeader>
        </TableHeader>
        <TableBody>
          {props.lista?.map((inventario, personIdx) => (
            <React.Fragment>
              <TableRow
                key={inventario.simbolo + inventario.inventario + inventario.total}
                className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
              >
                <TableBodyCell>
                  <Link
                    to={`/inventario/busqueda?nombreInventario=${inventario.inventario}&simbolo=${inventario.simbolo}`}
                  >
                    {inventario.simbolo}
                  </Link>
                </TableBodyCell>
                <TableBodyCell>{prettifyNumber(String(inventario.total))}</TableBodyCell>
              </TableRow>
            </React.Fragment>
          ))}
          <TableRow>
            <TableBodyCell>TOTAL</TableBodyCell>
            <TableBodyCell>
              {total.length > 0 && (
                <h2 className="text-black">{prettifyNumber(String(total.reduce(reducer)))}</h2>
              )}
            </TableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
export default InventarioTabla;
