import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { InnerApp } from './InnerApp';
import { DesktopStaticSidebar } from './DesktopStaticSidebar';
import { useSideBarContext } from '../../../context/SideBarProvider';

export function LeftSideBarApp ({
  children,
  sidebar,
  mobileNavbar,
}) {
  const {
    isSidebarOpen,
    openSidebar,
  } = useSideBarContext();

  return (
    <div className='h-screen flex overflow-hidden bg-white '>
      {/* Mobile Menu  */}
      <Transition.Root show={isSidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed inset-0 flex z-40 lg:hidden'
          open={isSidebarOpen}
          onClose={openSidebar}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay
              className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            {/* Navbar Mobile */}
            <div
              className='relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  {mobileNavbar.closeButton}
                </div>
              </Transition.Child>
              {mobileNavbar.navBar}
            </div>

          </Transition.Child>
          <div className='flex-shrink-0 w-14' aria-hidden='true'>
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>


      {/* Static sidebar for desktop */}
      <DesktopStaticSidebar>
        <div
          className='flex flex-col h-0 flex-1 border-r border-gray-200 bg-white-100'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          {sidebar}
        </div>
      </DesktopStaticSidebar>


      {/* #region Inner APP */}
      <InnerApp
        mobileNavbarHeader={mobileNavbar.header}>
        {children}
      </InnerApp>

      {/* #endregion  */}
    </div>
  );
}
