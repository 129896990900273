import React from 'react';
import {
  createClienteActions as sliceActions,
  insertCliente as insertThunk,
} from '../../store/crearCliente-slice';
import { useSelector, useDispatch } from 'react-redux';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import { RenderInputUI } from '../SharedUi/RenderInputUI';
import InsertTemplate from '../SharedUi/InsertTemplate';

const CreateCliente = () => {
  const sliceState = useSelector((state) => state.createCliente);
  const dispatch = useDispatch();
  let editingSelectValue;

  const form_datosFiscales = [
    {
      parentName: null,
      property: 'razonSocial',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.razonSocial,
      selectorName: 'razonSocial',
    },
    {
      parentName: null,
      property: 'correoElectronico',
      type: 'email',
      foreignKey: false,
      selector: sliceState?.correoElectronico,
      selectorName: 'correoElectronico',
    },
    {
      parentName: null,
      property: 'rfc',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.rfc,
      selectorName: 'rfc',
    },
    {
      parentName: null,
      property: 'diasCredito',
      type: 'number',
      foreignKey: false,
      selector: sliceState?.diasCredito,
      selectorName: 'diasCredito',
    },
  ];
  const form_direccionFiscal = [
    {
      property: 'direccionFiscal.numero',
      type: 'text',
      label: 'numero',
      foreignKey: false,
      selector: sliceState?.direccionFiscal.numero,
      selectorName: 'direccionFiscal.numero',
    },
    {
      property: 'direccionFiscal.calle',
      type: 'text',
      label: 'calle',
      foreignKey: false,
      selector: sliceState?.direccionFiscal.calle,
      selectorName: 'direccionFiscal.calle',
    },
    {
      property: 'direccionFiscal.colonia',
      type: 'text',
      label: 'colonia',
      foreignKey: false,
      selector: sliceState?.direccionFiscal.colonia,
      selectorName: 'direccionFiscal.colonia',
    },
    {
      property: 'direccionFiscal.ciudad',
      type: 'text',
      label: 'ciudad',
      foreignKey: false,
      selector: sliceState?.direccionFiscal.ciudad,
      selectorName: 'direccionFiscal.ciudad',
    },
    {
      property: 'direccionFiscal.estado',
      type: 'text',
      label: 'estado',

      foreignKey: false,
      selector: sliceState?.direccionFiscal.estado,
      selectorName: 'direccionFiscal.estado',
    },
    {
      property: 'direccionFiscal.cp',
      label: 'código postal',

      type: 'number',
      foreignKey: false,
      selector: sliceState?.direccionFiscal.cp,
      selectorName: 'direccionFiscal.cp',
    },
    {
      property: 'direccionFiscal.pais',
      type: 'text',
      label: 'país',

      foreignKey: false,
      selector: sliceState?.direccionFiscal.pais,
      selectorName: 'direccionFiscal.pais',
    },
  ];
  const form_direccionEntrega = [
    {
      property: 'Numero',
      type: 'text',
      label: 'numero',

      foreignKey: false,
      selector: sliceState?.direccionEntrega.numero,
      selectorName: 'direccionEntrega.numero',
    },
    {
      property: 'Calle',
      type: 'text',
      label: 'calle',
      foreignKey: false,
      selector: sliceState?.direccionEntrega.calle,
      selectorName: 'direccionEntrega.calle',
    },
    {
      property: 'Colonia',
      type: 'text',
      label: 'colonia',

      foreignKey: false,
      selector: sliceState?.direccionEntrega.colonia,
      selectorName: 'direccionEntrega.colonia',
    },
    {
      property: 'Ciudad',
      type: 'text',
      label: 'ciudad',

      foreignKey: false,
      selector: sliceState?.direccionEntrega.ciudad,
      selectorName: 'direccionEntrega.ciudad',
    },
    {
      property: 'Estado',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.direccionEntrega.estado,
      label: 'estado',

      selectorName: 'direccionEntrega.estado',
    },
    {
      property: 'Cp',
      type: 'number',
      foreignKey: false,
      label: 'código postal',

      selector: sliceState?.direccionEntrega.cp,
      selectorName: 'direccionEntrega.cp',
    },
    {
      property: 'País',
      type: 'text',
      label: 'país',

      foreignKey: false,
      selector: sliceState?.direccionEntrega.pais,
      selectorName: 'direccionEntrega.pais',
    },
  ];

  const request = {
    razonSocial: sliceState.razonSocial,
    rfc: sliceState.rfc,
    correoElectronico:sliceState.correoElectronico,
    direccionEntrega: sliceState.direccionEntrega,
    direccionFiscal: sliceState.direccionFiscal,
    diasCredito: sliceState.diasCredito,
  };

  const condition =
    sliceState?.razonSocial &&
    sliceState?.rfc &&
    sliceState?.direccionFiscal.numero &&
    sliceState?.direccionFiscal.calle &&
    sliceState?.direccionFiscal.ciudad &&
    sliceState?.direccionFiscal.colonia &&
    sliceState?.direccionFiscal.estado &&
    sliceState?.direccionFiscal.cp &&
    sliceState?.direccionFiscal.pais &&
    sliceState?.direccionEntrega.numero &&
    sliceState?.direccionEntrega.colonia &&
    sliceState?.direccionEntrega.calle &&
    sliceState?.direccionEntrega.ciudad &&
    sliceState?.direccionEntrega.estado &&
    sliceState?.direccionEntrega.cp &&
    sliceState?.direccionEntrega.pais &&
    sliceState?.diasCredito &&
    !editingSelectValue;

  let components = [
    <CardContainer>
      <CardTitle>Datos Fiscales</CardTitle>

      <RenderInputUI list={form_datosFiscales} sliceActions={sliceActions} />
    </CardContainer>,
    <CardContainer>
      <CardTitle>Dirección Fiscal</CardTitle>

      <RenderInputUI list={form_direccionFiscal} sliceActions={sliceActions} />
    </CardContainer>,
    <CardContainer>
      <CardTitle>Datos de Entrega</CardTitle>

      <RenderInputUI list={form_direccionEntrega} sliceActions={sliceActions} />

      <CardButton
        onClick={() => {
          dispatch(
            sliceActions.pasteDatosEntrega({
              value: { ...sliceState.direccionFiscal },
            }),
          );
        }}
      >
        Copiar de Dirección fiscal
      </CardButton>
    </CardContainer>,
  ];

  return (
    <InsertTemplate
      sliceState={sliceState}
      insertRequest={request}
      insertThunk={insertThunk}
      components={components}
      condition={condition}
    />
  );
};
export default CreateCliente;
