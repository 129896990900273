import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../../Util/authorizationHeader';

export const postRelationProductoThunk = ({
  name,
  postRoute,
  getRoute,
  getParam,
  getParamKey,
}) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const {
      requestObj,
      reduxStateTree,
      selectedValue,
      asignedListString,
      authCtx,
    } = payload;

    const setParams = ({ getParam, getParamKey, requestObj, response }) => {
      if (getParam === 'requestObj') {
        return requestObj[getParamKey];
      }
      if (getParam === 'response') {
        return response.data[getParamKey];
      }
    };

    console.log(postRoute);
    console.log(requestObj);
    try {
      const response = await axios.post(postRoute, requestObj, {
        headers: {
          ...authorizationHeader(),
        },
      });

      const viewResponse = await axios.get(
        `${getRoute}/${setParams(
          { getParam, getParamKey, requestObj, response })}`,
        {
          headers: {
            ...authorizationHeader(),
          },
        },
      );

      return {
        data: viewResponse.data,
        reduxStateTree,
        selectedValue,
        asignedListString,
        requestObj,
      };
    } catch (err) {
      console.log('err=====??>>');
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });

export const postDocumentThunk = ({
  name,
  postRoute,

}) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const {
      requestObj,
    } = payload;

    console.log(postRoute);
    console.log('reqObj->',requestObj);
    try {
      const response = await axios.post(postRoute, requestObj, {
        headers: {
          ...authorizationHeader(),
        },
      });
      console.log(response);
      return response;
    } catch (err) {
      console.log('err=>');
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });