import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '../TailwindUI/IconButton';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import RelationInsertValue from '../SharedUi/RelationInsertValue';
import { XCircleIcon } from '@heroicons/react/outline';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { useHistory, useLocation } from 'react-router';
import { InputCornerHint, NumberInputWithLimits } from '../SharedUi/Inputs';
import { AlertWithDescription } from '../SharedUi/Alerts';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';

const AsignProductoEtoCPP = (props) => {
  const { iva } = props;
  const history = useHistory();
  //Internal State
  const [editMode, setEditMode] = useState(false);
  const [errorCantidad, setErrorCantidad] = useState(null);

  const [factura, setFactura] = useState('');
  const [remision, setRemision] = useState('');

  const [cantidad, setCantidad] = useState(null);

  const [limiteCantidadSuperior, setLimiteCantidadSuperior] = useState(null);
  const [limiteCantidadInferior, setLimiteCantidadInferior] = useState(null);

  const [productoPorDarEntrada, setProductoPorDarEntrada] = useState(null);
  const [sumaEntradas, setSumaEntradas] = useState(null);
  const [unidad, setUnidad] = useState(null);

  //Redux State

  //Create Relation
  const createRelation = useSelector((state) => state.createRelation);
  const loading = useSelector((state) => state.createRelation.loading);

  //Function Handlers

  const handleCancel = () => {
    props.handleCancel({
      stateTree: props.asignTree,
    });
    setEditMode(false);
  };

  const handlePostRelation = (producto) => {
    let selectedValue = {
      cuenta_por_pagar_id: props.cuentaPorPagar?.id,
      numero_ocp: productoPorDarEntrada.numeroOcp,
      remision_proveedor: remision,
      factura: props.factura,
      producto_id: productoPorDarEntrada.productoId,
      cantidad,
      precio_id: productoPorDarEntrada.precioCompraId,
      producto_entrada_id: productoPorDarEntrada.id,
    };

    console.log('producto');
    console.log(selectedValue);

    props.handlePostRelation({
      selectedValue,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
    });
    setEditMode(false);
  };
  const handleCerrar = (e) => {
    // props.handleErrase({
    //   valueToUnasign: e,
    //   reduxStateTree: props.asignTree,
    //   asignedListString: props.asignedListString,
    //   asignedListSelector: props.asignedListSelector,
    // });
  };

  //<-----------------SELECT OPTIONS----------------->
  const dispatch = useDispatch();

  const handleCantidad = (e) => {
    setCantidad(e.target.value);
    setErrorCantidad(null);

    if (e.target.value < limiteCantidadInferior) {
      setErrorCantidad('Estas fuera del 10% permitido');
    }
    if (e.target.value > limiteCantidadSuperior) {
      setErrorCantidad('Estas fuera del 10% permitido');
    }
  };
  const handleCrearEntrada = (eduxStateTree, value, selectValues) => {
    console.log('handle divisa---> Events');
    console.log('eduxStateTree');
    console.log(eduxStateTree);

    // props.handleSelect({
    //   reduxStateTree: props.asignTree,
    //   valuesToFilter: createRelation.valuesToAsign,
    //   selectedValue: productoPorDarEntrada,
    // });
  };

  const handleAddToCpp = (producto) => {
    console.log('producto');
    console.log(producto);
    console.log(producto.cantidad);
    setCantidad(producto.cantidad);
    setProductoPorDarEntrada(producto);

    setRemision(producto.notaDeRemision);
    setEditMode(true);
  };

  const handleSetlimitAsCantidad = () => {
    setCantidad(limiteCantidadSuperior);
  };

  return (
    <React.Fragment>
      {loading && <h1>Loading</h1>}
      {!editMode && (
        <React.Fragment>
          {props.asignedListSelector
            ? props.asignedListSelector.length > 0 && (
                <div style={{ width: 'max-content', marginRight: 'auto' }}>
                  <Table>
                    <TableHeader>
                      <TableRowHeader className="bg-gray-300 ">
                        <TableHeaderCell textColor="text-gray-700">FACTURA</TableHeaderCell>
                        <TableHeaderCell textColor="text-gray-700">REMISION</TableHeaderCell>
                        <TableHeaderCell textColor="text-gray-700">OCP</TableHeaderCell>
                        <TableHeaderCell textColor="text-gray-700">CANTIDAD</TableHeaderCell>
                        <TableHeaderCell textColor="text-gray-700">Símbolo</TableHeaderCell>

                        <TableHeaderCell textColor="text-gray-700">P.U</TableHeaderCell>
                        <TableHeaderCell textColor="text-gray-700">AÑADIR</TableHeaderCell>
                        {/* <TableHeaderCell textColor="text-gray-700">
                        Cerrar
                      </TableHeaderCell> */}
                      </TableRowHeader>
                    </TableHeader>
                    <TableBody>
                      {props.asignedListSelector
                        ? props.asignedListSelector.map((producto, personIdx) => (
                            <React.Fragment>
                              <TableRow
                                key={producto.id}
                                className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                              >
                                <TableBodyCell textColor="text-gray-500">
                                  {producto.factura}
                                </TableBodyCell>
                                <TableBodyCell textColor="text-gray-500">
                                  {producto.notaDeRemision}
                                </TableBodyCell>
                                <TableBodyCell textColor="text-gray-500">
                                  {producto.numeroOcp}
                                </TableBodyCell>
                                <TableBodyCell textColor="text-gray-500">
                                  {producto.cantidad}
                                </TableBodyCell>

                                <TableBodyCell textColor="text-gray-500">
                                  {producto.simbolo}
                                </TableBodyCell>

                                <TableBodyCell textColor="text-gray-500">
                                  {producto.precioUnitario}
                                </TableBodyCell>

                                <TableBodyCell textColor="text-gray-500">
                                  <IconButton
                                    onClick={() => handleAddToCpp(producto)}
                                    color="orange"
                                    icon="+"
                                    iconHeight={3}
                                    iconWidth={3}
                                  />
                                </TableBodyCell>
                                {/* <TableBodyCell textColor="text-gray-500">
                            <IconButton
                              onClick={() => handleCerrar(producto)}
                              color="red"
                              icon="x"
                              iconHeight={3}
                              iconWidth={3}
                            />
                          </TableBodyCell> */}
                              </TableRow>
                            </React.Fragment>
                          ))
                        : ''}
                    </TableBody>
                  </Table>
                </div>
              )
            : ''}
        </React.Fragment>
      )}
      {!loading && (
        <React.Fragment>
          {editMode && (
            <div>
              <div className="grid-cols-6">
                <InputCornerHint
                  name="simboloProducto"
                  label={'Símbolo'}
                  value={productoPorDarEntrada?.simbolo?.toUpperCase()}
                  disabled
                  borderColor={'border-gray-400'}
                />
                <div className={'my-2'} />

                <InputCornerHint
                  textColor="text-gray-400"
                  name="unidad"
                  label={'Cantidad'}
                  value={cantidad}
                  onChange={handleCantidad}
                  disabled
                  type="Number"
                />
                <div className={'my-4'} />

                <div className={'my-2'} />
                <InputCornerHint
                  textColor="text-gray-400"
                  name="unidad"
                  label={'Unidad'}
                  value={productoPorDarEntrada?.unidad}
                  disabled
                />

                <div className={'my-2'} />
                <InputCornerHint
                  name="numeroRemision"
                  label={'#Remision'}
                  value={remision}
                  onChange={(e) => setRemision(e.target.value)}
                  borderColor={'border-orange-300'}
                ></InputCornerHint>
                <div className={'my-2'} />

                {errorCantidad && (
                  <AlertWithDescription
                    title={errorCantidad}
                    icon={<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                  />
                )}

                {!errorCantidad && remision && (
                  <CardButton onClick={handlePostRelation} bgColor="orange">
                    Añadir a Cuenta Por Pagar
                  </CardButton>
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default AsignProductoEtoCPP;
