import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../../Util/authorizationHeader';

export const deleteRelationProductoThunk = ({ name, route, method, findKey = null }) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestObj, stateTree, asignedListString, asignedListSelector, authCtx } = payload;
    const createRequest = async () => {
      let response;
      try {
        if (method === 'POST') {
          response = await axios.post(` ${route}`, asignedListSelector[0], {
            headers: {
               ...authorizationHeader()
            },
          });
          return response;
        }
        if (method === 'POST_V2') {
          console.log('POST_V2----->>>>>>>');
          console.log(asignedListSelector);
          console.log(findKey);
          console.log(
            asignedListSelector.find((i) => (!findKey ? i.id : i[findKey]) === requestObj.id),
          );
          response = await axios.post(
            ` ${route}`,
            asignedListSelector.find((i) => (!findKey ? i.id : i[findKey]) === requestObj.id),
            {
              headers: {
                 ...authorizationHeader()
              },
            },
          );
          return response;
        }
        if (method === 'DELETE') {
          console.log("deleting???-->>")
          const url=`${route}/${requestObj.id}`
          console.log("url-->",url)
          response = await axios.delete(url, {
            headers: {
               ...authorizationHeader()
            },
          });
          console.log("response->",response)
          return response;
        }
      } catch (err) {
        console.log('errIF----->>>>>>>');
        console.log('errIF----->>>>>>>');
        console.log(err);
        console.log('errIF----->>>>>>>');
      }
    };
    try {
      console.log('createRequest()----->>>>>>>');
      console.log('createRequest()----->>>>>>>');
      const response = await createRequest();
      console.log('response----->>>>>>>');
      console.log('response----->>>>>>>');
      console.log(response);
      console.log('response----->>>>>>>');

      return {
        data: response.data,
        stateTree,
        asignedListString,
        simbolo: requestObj.simbolo,
        asignedListSelector,
      };
    } catch (err) {
      console.log('err----->>>>>>>');
      console.log('err----->>>>>>>');
      console.log(err);
      console.log('err----->>>>>>>');
      return rejectWithValue(err.response.data);
    }
  });
