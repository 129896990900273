import { createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../../Util/apiURL';
import { asignReducer, selectReducer } from '../../lib/shared-reducers';
import * as util from '../../lib/edit';
import { getRequestWparamsThunk } from '../../Util/get-request-thunk';
const apiHOST = apiURL();
const apiHOST_nest = apiURL_Nest();
const initialState = {
  producto: {
    id: '',
    deshabilitado:'',
    simbolo: '',
    categoriaId: '',
    divisaId: '',
    especificacionProductoId: '',
    dimensionLaminaId: '',
    medidasInternasCajaId: '',
    costoAdquisicionId: '',
    precioVentaId: '',
  },
  categoria: { id: '', nombre: '' },
  divisa: { id: '', nombre: '' },
  especificacionesProducto: {
    id: '',
    ect: '',
    flauta: '',
    papeles: '',
    tintas: '',
    tratamientos: '',
  },
  dimensionesLamina: {
    id: '',
    largo: '',
    ancho: '',
  },
  medidasInternasCaja: {
    id: '',
    largo: '',
    ancho: '',
    alto: '',
  },
  costoAdquisicion: { id: '', costoAdquisicionActual: '' },
  costos: [],
  precioVenta: { id: '', precioVentaActual: '' },
  precios: [],
  error: false,
  loading: false,
  success: null,
};

//--------OK REQUESTS------------------------------------------------------------

export const getProducto = getRequestWparamsThunk(
  'producto/getProducto',
  `${apiHOST_nest}productos`,
);

export const putProducto = util.patchRequestThunk('producto/putProducto', apiHOST_nest);

export const deleteProducto = util.deleteRequestThunk(
  'producto/deleteProducto',
  apiHOST,
  'productos',
);

//Relations

export const getRelation = util.getRequestRelationThunk('producto/getRelation', apiHOST);

export const putJoinedTable = util.putRequestJoinedTableThunk('producto/putForeignEntity', apiHOST);

//--------OK REQUESTS------------------------------------------------------------

const editProductoSlice = createSlice({
  name: 'editProducto',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'simbolo':
          state.producto.simbolo = action.payload.value.toUpperCase();
          break;
        case 'deshabilitado':
          state.producto.deshabilitado = action.payload.value;
          break;
        case 'e_ect':
          state.especificacionesProducto.ect = action.payload.value.toUpperCase();
          break;
        case 'e_flauta':
          state.especificacionesProducto.flauta = action.payload.value.toUpperCase();
          break;
        case 'e_papeles':
          state.especificacionesProducto.papeles = action.payload.value.toUpperCase();
          break;
        case 'e_tratamientos':
          state.especificacionesProducto.tratamientos = action.payload.value.toUpperCase();
          break;
        case 'e_tintas':
          state.especificacionesProducto.tintas = action.payload.value.toUpperCase();
          break;
        case 'dl_largo':
          state.dimensionesLamina.largo = action.payload.value;
          break;
        case 'dl_ancho':
          state.dimensionesLamina.ancho = action.payload.value;
          break;
        case 'mic_largo':
          state.medidasInternasCaja.largo = action.payload.value;
          break;
        case 'mic_ancho':
          state.medidasInternasCaja.ancho = action.payload.value;
          break;
        case 'mic_alto':
          state.medidasInternasCaja.alto = action.payload.value;
          break;
        case 'costoAdquisicionActual':
          state.costoAdquisicion = action.payload.value;
          break;
        case 'precioVentaActual':
          state.precioVenta = action.payload.value;
          break;
        default:
      }
    },
    resetSucces(state) {
      state.success = null;
    },
    resetError(state) {
      state.error = false;
    },
    selectReducer,
    asignReducer,
  },
  extraReducers: builder => {
    builder.addCase(getProducto.fulfilled, (state, action) => {
      const {
        producto,
        categoria,
        divisa,
        especificacionProducto,
        dimensionLamina,
        medidasInternasCaja,
        costoAdquisicionActual,
        precioVentaActual,
      } = action.payload;
      state.producto = producto;
      state.categoria = categoria;
      state.divisa = divisa;
      state.especificacionesProducto = especificacionProducto
      state.dimensionesLamina = dimensionLamina;
      state.medidasInternasCaja = medidasInternasCaja;
      state.costoAdquisicion = costoAdquisicionActual?.costoAdquisicion;
      state.precioVenta = precioVentaActual?.precioVenta;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(getProducto.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(getProducto.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteProducto.fulfilled, (state, action) => {
      state.producto = {
        id: null,
        simbolo: null,
        categoriaId: null,
        divisaId: null,
        especificacionProductoId: null,
        dimensionLaminaId: null,
        medidasInternasCajaId: null,
        costoAdquisicionId: null,
        precioVentaId: null,
      };
      state.categoria = { id: null, nombre: null };
      state.divisa = { id: null, nombre: null };
      state.especificacionesProducto = {
        id: null,
        ect: null,
        flauta: null,
        papeles: null,
        tintas: null,
        tratamientos: null,
      };
      state.dimensionesLamina = {
        id: null,
        largo: null,
        ancho: null,
      };
      state.medidasInternasCaja = {
        id: null,
        largo: null,
        ancho: null,
        alto: null,
      };
      state.costoAdquisicion = { id: null, costoAdquisicionActual: null };
      state.precioVenta = { id: null, precioVentaActual: null };
    });
    builder.addCase(deleteProducto.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    //Put Producto
    builder.addCase(putProducto.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
      state.loading = false;
      state.error = false;
      state.success = true;
    });
    builder.addCase(putProducto.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(putProducto.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    builder.addCase(putJoinedTable.fulfilled, (state, action) => {
      // console.log('Put joined Table =====>');
      // console.log(action.payload.data);
      // console.log('State Tree=====>');
      // console.log(action.payload.stateTree);
      state.success = true;
      state[action.payload.stateTree] = action.payload.data;
    });
    builder.addCase(putJoinedTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(getRelation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
  },
});

export default editProductoSlice;

export const editProductoActions = editProductoSlice.actions;
