import {  createSlice } from '@reduxjs/toolkit';

import { apiURL_Nest } from '../../Util/apiURL';
import {
  getRequestFromClientPathThunk,
  getRequestFromClientPathThunkV2,
} from '../../Util/get-request-thunk';
import {
  defaultPendingEffect,
  defaultRejectedEffect,
} from '../../lib/default-get-all-items';
const apiHOST =apiURL_Nest();
const initialState = {
  raw: [],
  formated: [],
  displayMx: {},
  displayUsd: {},
  totalesMx:{},
  totalesUsd:{},
  filterSearchValue: '',
  order: false,
  orderBy: null,
  orderByOptions: ['', 'salida', 'razonSocial', 'cp'],
  sortAscending: false,
  divisa: 'mx',
  displayAll: false,
  error: false,
  loading: false,
};


export const getEdoCuentas = getRequestFromClientPathThunkV2(
  'estadoDeCuentas/getEdoCuentas',
  `${apiHOST}`,
);


const estadosDeCuentaSlice = createSlice({
  name: 'estadosDeCuenta',
  initialState: initialState,
  reducers: {
    resetFilter(state, action) {
      state.filterSearchValue = null;
    },
    filterBy(state, action) {
      state.filterSearchValue = action.payload.value;
    },
    shouldDisplayAll(state, action) {
      state.displayAll = action.payload;
    },
    erraseError (state, action) {
      state.error=false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEdoCuentas.fulfilled, (state, action) => {
      const[totales,cuentasByEntidadComercial]=action.payload
      console.log(totales['MXN'])
      console.log(cuentasByEntidadComercial['MXN'])
      state.displayMx = cuentasByEntidadComercial['MXN']
      state.displayUsd = cuentasByEntidadComercial['USD']
      state.totalesMx=totales['MXN']
      state.totalesUsd=totales['USD']
      state.loading = false;
    });
    builder.addCase(getEdoCuentas.pending, defaultPendingEffect)
    builder.addCase(getEdoCuentas.rejected, defaultRejectedEffect)
  },
});

export default estadosDeCuentaSlice;

export const estadosDeCuentaActions = estadosDeCuentaSlice.actions;
