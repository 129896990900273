import React, { useState } from 'react';

import IconButton from '../TailwindUI/IconButton';
import { prettifyNumber } from '../../Util/prettifyNumber';
import numberToText from '../../Util/cantidadEnLetra';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { Link } from 'react-router-dom';
import DeleteModal from '../UI/Modals/DeleteModal';
import { GenericModal } from '../UI/Generics';
import { EditEntity } from '../UI/Generics/EditEntity/EditEntity';
import { prettyFinancial } from '../../Util/prettyFinancial';

const AsignedProductosTable = (props) => {
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [genericModalIsVisible, setGenericModalIsVisible] = useState(false);
  const [editEntity, setEditEntity] = useState(null);
  const [deleteEntity, setDeleteEntity] = useState(null);

  const { iva } = props;
  //Function Handlers
  const handleErrase = (e) => {
    console.log('handle errase Asigned p_ocp  !!!!!');
    console.log('value tu unasign', e);
    props.handleErrase({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };

  return (
    <React.Fragment>
      {props.sliceState?.productosOcc?.length > 0 && (
        <div style={{ width: '100%', margin: 'auto' }}>
          <Table>
            <TableHeader>
              <TableRowHeader>
                <TableHeaderCell>CANTIDAD</TableHeaderCell>
                <TableHeaderCell>UNIDAD</TableHeaderCell>
                <TableHeaderCell>DESCRIPCIÓN</TableHeaderCell>
                <TableHeaderCell>P.UNITARIO</TableHeaderCell>
                <TableHeaderCell>IMPORTE</TableHeaderCell>
                <TableHeaderCell>RESTANTES</TableHeaderCell>
              </TableRowHeader>
            </TableHeader>
            <TableBody>
              {props.sliceState.productosOcc?.map((producto, personIdx) => {
                return (
                  <React.Fragment>
                    <TableRow
                      key={producto.id}
                      className={producto.vigente ? 'bg-white' : 'bg-green-50'}
                    >
                      <TableBodyCell>{prettifyNumber(
                        producto.cantidad)}</TableBodyCell>
                      <TableBodyCell>{producto.unidad}</TableBodyCell>
                      <TableBodyCell>
                        <Link
                          to={`/productos/${producto.descripcion}`}>{producto.descripcion}</Link>
                      </TableBodyCell>
                      <TableBodyCell>
                        $
                        {producto.pUnitario}
                      </TableBodyCell>
                      <TableBodyCell>
                        $
                        {prettifyNumber(producto.importe.toFixed(2)
                          ? producto.importe.toFixed(2)
                          : producto.importe)}
                      </TableBodyCell>
                      {deleteModalIsVisible && (
                        <DeleteModal
                          title={'Borrar producto de ocp'}
                          item={prettifyNumber(deleteEntity?.cantidad) + ' ' +
                            deleteEntity?.descripcion}
                          onConfirm={() => {
                            handleErrase(deleteEntity);
                            setDeleteModalIsVisible(false);
                          }}
                          onCancel={() => setDeleteModalIsVisible(false)}
                        >
                          Esta Acción no puede desacerse
                        </DeleteModal>
                      )}
                      <TableBodyCell>
                        {prettifyNumber(producto.restantes)}
                      </TableBodyCell>
                      <TableBodyCell>
                        {producto.vigente ? (
                          <React.Fragment>
                            {props.vigente && (
                              <IconButton
                                onClick={() => {

                                  console.log("producto",producto)
                                  setDeleteEntity(producto);
                                  setDeleteModalIsVisible(true);
                                }}
                                color='red'
                                icon='trash'
                                iconHeight={2.5}
                                iconWidth={2.5}
                              />
                            )}
                            <IconButton
                              onClick={() => {
                                setEditEntity(producto);
                                setGenericModalIsVisible(true);
                              }}
                              color='red'
                              icon='x'
                              iconHeight={2.5}
                              iconWidth={2.5}
                            />
                          </React.Fragment>
                        ) : (
                          <IconButton
                            onClick={() => {
                              //  props.handleVigencia(producto)
                            }}
                            color='green'
                            icon='check'
                            iconHeight={2.5}
                            iconWidth={2.5}
                          />
                        )}
                      </TableBodyCell>
                      {genericModalIsVisible && (
                        <GenericModal
                          title={'Generic Modal'}
                          onConfirm={(sliceState) => {
                            setGenericModalIsVisible(false);
                          }}
                          onCancel={() => {
                            props.handleUpdateProductoOc();
                            setGenericModalIsVisible(false);
                          }}
                        >
                          <EditEntity
                            name='Producto Ocp'
                            // callback={}
                            entity={editEntity}
                            readOnlyFields={[
                              {
                                label: 'cantidad',
                                path: 'cantidad',
                                key: 'cantidad',
                              },
                              {
                                label: 'simbolo',
                                path: 'simbolo',
                                key: 'simbolo',
                              },
                            ]}
                            editFields={[
                              {
                                label: 'Cerrar',
                                path: 'pOcpVigente',
                                key: 'pOcpVigente',
                                type: 'boolean',
                              },
                            ]}
                            url={`views/productos-${props.type}/cerrar`}
                          />
                        </GenericModal>
                      )}
                    </TableRow>
                  </React.Fragment>
                );
              })}

              <TableRow className='py-1'>
                <TableBodyCell className='py-2' colspan={3}></TableBodyCell>

                <TableBodyCell
                  className='pt-10 px-1 whitespace-nowrap text-right'>
                  <span className='font-medium text-gray-600'>IMPORTE:</span>
                </TableBodyCell>
                <TableBodyCell
                  className='pt-10 px-1 whitespace-nowrap text-left'>
                  {prettyFinancial(props?.sliceState?.subtotalOk)}
                </TableBodyCell>
              </TableRow>

              {iva && (
                <TableRow className='py-1'>
                  <TableBodyCell colspan={3} className='py-2'></TableBodyCell>

                  <TableBodyCell
                    className='py-2 px-1 whitespace-nowrap text-right'>
                    <span className='font-medium text-gray-600'>IVA:</span>
                  </TableBodyCell>

                  <TableBodyCell
                    className='py-2 px-1 whitespace-nowrap text-left'>

                    {prettyFinancial(props.sliceState.iva)}
                  </TableBodyCell>
                </TableRow>
              )}

              <TableRow className='py-1'>
                <td
                  style={{
                    whiteSpace: 'nowrap',
                    wordBreak: 'break-word',
                  }}
                  colspan={3}
                >
                  {props.sliceState.totalTexto}
                </td>
                <TableBodyCell
                  className=' align-top py-2 px-1 whitespace-nowrap text-right'>
                  <span className='font-medium text-gray-600'>TOTAL:</span>
                </TableBodyCell>
                <TableBodyCell
                  className='align-top py-2  px-1 whitespace-nowrap text-left'>
                  {prettyFinancial(props.sliceState.total)}
                </TableBodyCell>

              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
};
export default AsignedProductosTable;
