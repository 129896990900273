import { tableHeaderMap } from '../../mappings/table-header-mappings';
const TableHeaderCellAuto = (props) => {
  if (props?.list) {
    let keys;
    props.list.length > 0 ? (keys = Object.keys(props.list[0])) : (keys = []);
    const AutoCells = keys.map((item) => {
      return (
        <th
          key={JSON.stringify(item) + Math.random(165).toString()}
          className={
            props.className
              ? props.className
              : 'px-1 pt-2 pb-1 text-left max-w-prose text-xs font-medium text-white uppercase tracking-wider'
          }
          scope="col"
        >
          {tableHeaderMap.get(String(item).toLowerCase()) ?? item}
        </th>
      );
    });
    return AutoCells;
  }
  return <th>No Records Found</th>;
};
export default TableHeaderCellAuto;
