import react from 'react';
const FlexContainer = (props) => {
  let classes = 'flex';
  if (props.className) {
    classes = classes + ' ' + props.className;
  }

  return (
    <div className={classes} style={{ background: '#fcfcfc' }}>
      {props.children}
    </div>
  );
};
export default FlexContainer;
