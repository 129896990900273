/*
El componente 
Recibe el objeto a modificar
Utiliza el hook Use effect,  const [obj,newObj]=useState({})
Utilizamos  el hook useEffect para que se actualize el estado en base al objeto recibido
Utilizamos let entries= Object.entries(obj)  en el objeto para recibir  sus entradas
Creamos una <form></form>, La Forma tiene un State el cual se modifa cuando un input value cambia (su estado y es aceptado)... Cuando el cliente a terminado de editar Puede Apretar el Boton Guardar Cambios  y esto genera un http request .

Utilizamos  entries.map((item)=><Componente defaultValue={item[1]} label={item[0]} handleEdit={} />) para crear un Componente modificable 
 el componente es  recibe el Valor del Key para su Label y su valor predeterminado del input, tiene tres estados Sin Editar, Aceptar cambios, Cancelar Cambios
 Si los cabios no han sido aceptados  el valor del input field lo recibe de el valor defaultvalue (Que viene del objeto)
Si los cambios fueron aceptados se guarda el valor del estado Interno actual de la forma y se envía un mensaje a la función update state de Forma Entera  -*/

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editProveedorActions } from './editarProveedor-slice';
import FlexContainer from '../TailwindUI/FlexContainer';
import IconButton from '../TailwindUI/IconButton';

const ModelInputsAndSelectors = props => {
  //Internal State
  const [editMode, setEditMode] = useState(false);
  const [backupValue, setBackupValue] = useState(false);
  //Hooks
  const dispatch = useDispatch();
  //Props
  const { selectorName } = props;
  //Redux Sekectors
  const razonSocial = useSelector(state => state.editProveedor.proveedor?.razonSocial);
  const diasCredito = useSelector(state => state.editProveedor.proveedor?.diasCredito);
  const rfc = useSelector(state => state.editProveedor.proveedor?.rfc);
  const correoElectronico = useSelector(state => state.editProveedor.proveedor?.correoElectronico);
  const numero = useSelector(state => state.editProveedor.direccion?.numero);
  const calle = useSelector(state => state.editProveedor.direccion?.calle);
  const colonia = useSelector(state => state.editProveedor.direccion?.colonia);
  const ciudad = useSelector(state => state.editProveedor.direccion?.ciudad);
  const estado = useSelector(state => state.editProveedor.direccion?.estado);
  const cp = useSelector(state => state.editProveedor.direccion?.cp);
  const pais = useSelector(state => state.editProveedor.direccion?.pais);

  //Function Handlers
  const handleChange = e => {
    setEditMode(true);
    dispatch(
      editProveedorActions.change({
        field: selectorName,
        value: e.target.value,
      }),
    );
  };
  const handleEditar = () => {
    setBackupValue(newValue);
    setEditMode(true);
  };
  const handleGuardar = () => {
    setEditMode(false);
  };
  const handleCancelar = () => {
    dispatch(editProveedorActions.change({ field: selectorName, value: backupValue }));
    setEditMode(false);
  };

  let newValue = '';
  let type = 'text';
  switch (selectorName) {
    case 'razonSocial':
      newValue = razonSocial;
      break;
    case 'correoElectronico':
      newValue = correoElectronico;
      break;
    case 'diasCredito':
      newValue = diasCredito;
      break;
    case 'rfc':
      newValue = rfc;
      break;
    case 'numero':
      newValue = numero;
      break;
    case 'calle':
      newValue = calle;
      break;
    case 'colonia':
      newValue = colonia;
      break;
    case 'ciudad':
      newValue = ciudad;
      break;
    case 'estado':
      newValue = estado;
      break;
    case 'cp':
      newValue = cp;
      break;
    case 'pais':
      newValue = pais;
      break;
    default:
  }

  return (
    <FlexContainer className={'flex-col max-w-fit m-auto  '}>
      {props.label !== 'id' && !props.label.includes('Id') && (
        <React.Fragment>
          <label className="block text-sm font-medium text-gray-400 self-start mt-5">
            {props.label ? props.label : 'Sin Etiqueta'}
          </label>
          <div className="mt-1 flex rounded shadow-sm py-1 ">
            {props.label !== 'id' && (
              <input
                type={type}
                onChange={handleChange}
                onFocus={handleEditar}
                value={newValue}
                onBlur={() => setEditMode(false)}
                readOnly={!editMode}
                className="  text-sm focus:ring-orange-500 focus:border-orange-500  block   border-white rounded-md  "
              />
            )}

            <div className="inline-flex items-center ">
              {!editMode && (
                <React.Fragment>
                  <div style={{ height: '3px', width: '30px' }}></div>
                  <IconButton
                    onClick={handleEditar}
                    color="darkBlue"
                    icon="edit"
                    iconHeight={3}
                    iconWidth={3}
                  />
                </React.Fragment>
              )}
              {/* 
              {editMode && (
                <IconButton
                  onClick={handleGuardar}
                  color="green"
                  icon="check"
                  iconHeight={3}
                  iconWidth={3}
                />
              )}
              {editMode && (
                <IconButton
                  onClick={handleCancelar}
                  color="red"
                  icon="x"
                  iconHeight={3}
                  iconWidth={3}
                />
              )} */}
            </div>
          </div>
        </React.Fragment>
      )}
    </FlexContainer>
  );
};
export default ModelInputsAndSelectors;
