import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '../TailwindUI/IconButton';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import RelationInsertValue from '../SharedUi/RelationInsertValue';
import { XCircleIcon } from '@heroicons/react/outline';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { useHistory, useLocation } from 'react-router';
import { InputCornerHint, NumberInputWithLimits } from '../SharedUi/Inputs';
import { AlertWithDescription } from '../SharedUi/Alerts';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import DocsCard from '../SharedUi/DocsCard';
import { Link } from 'react-router-dom';

const AsignProductoOCPtoEntrada = (props) => {
  const { iva } = props;
  const history = useHistory();
  //Internal State
  const [editMode, setEditMode] = useState(false);
  const [errorCantidad, setErrorCantidad] = useState(null);

  const [factura, setFactura] = useState('');
  const [remision, setRemision] = useState('');
  const [lote, setLote] = useState('');

  const [cantidad, setCantidad] = useState(null);

  const [limiteCantidadSuperior, setLimiteCantidadSuperior] = useState(null);
  const [limiteCantidadInferior, setLimiteCantidadInferior] = useState(null);

  const [productoPorProcesar, setProductoPorDarEntrada] = useState(null);
  const [sumaRegistros, setSumaRegistros] = useState(null);
  const [unidad, setUnidad] = useState(null);

  //Redux State

  //Create Relation
  const createRelation = useSelector((state) => state.createRelation);
  const loading = useSelector((state) => state.createRelation.loading);

  //Function Handlers

  const handleCancel = () => {
    props.handleCancel({
      stateTree: props.asignTree,
    });
    setEditMode(false);
  };

  const handlePostRelation = () => {
    let selectedValue;
    if (productoPorProcesar.numeroOcp) {
      selectedValue = {
        entrada_id: props?.entradaId,
        cantidad,
        unidad_id: productoPorProcesar.unidadId,
        producto_id: productoPorProcesar.productoId,
        nota_de_remision: remision,
        inventario_id: productoPorProcesar.inventarioId,
        factura,
        numero_ocp: productoPorProcesar.numeroOcp,
        producto_ocp_id: productoPorProcesar.id,
        proveedor_id: props.proveedor?.id,
        usuario_id: '1',
        en_calidad_de_id: props.enCalidadDe,
        lote,
        sumaRegistros,
      };
    }

    if (productoPorProcesar.numeroOcc) {
      selectedValue = {
        salida_id: props?.salidaId,
        cantidad,
        unidad_id: productoPorProcesar.unidadId,
        producto_id: productoPorProcesar.productoId,
        nota_de_remision: remision,
        inventario_id: productoPorProcesar.inventarioId,
        factura,
        numero_occ: productoPorProcesar.numeroOcc,
        producto_occ_id: productoPorProcesar.id,
        proveedor_id: props.proveedor?.id,
        usuario_id: '1',
        en_calidad_de_id: props.enCalidadDe,
        lote,
        sumaRegistros,
      };
    }
    console.log('Entrada');
    console.log(selectedValue);

    props.handlePostRelation({
      selectedValue,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
    });
    setEditMode(false);
  };
  const handleCerrar = (e) => {
    console.log('Handle Cerrare');
    props.handleCerrar({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };

  //<-----------------SELECT OPTIONS----------------->
  const dispatch = useDispatch();

  const handleCantidad = (e) => {
    setCantidad(e.target.value);
    setErrorCantidad(null);

    if (e.target.value < limiteCantidadInferior) {
      setErrorCantidad('No se permiten entradas con 0 Unidades');
    }
    if (e.target.value > limiteCantidadSuperior) {
      setErrorCantidad('Estas fuera del 10% permitido');
    }
  };
  const handleCrearEntrada = (eduxStateTree, value, selectValues) => {
    console.log('handle divisa---> Events');
    console.log('eduxStateTree');
    console.log(eduxStateTree);

    // props.handleSelect({
    //   reduxStateTree: props.asignTree,
    //   valuesToFilter: createRelation.valuesToAsign,
    //   selectedValue: productoPorProcesar,
    // });
  };

  const handleAddToEntrada = (producto) => {
    console.log('producto');
    console.log(producto);
    console.log(producto.cantidad);
    // IF OCP
    if (producto.cantidadOcp) {
      setCantidad(
        Number(producto.cantidad) - Number(producto.sumaEntradasByPOcp));
      setProductoPorDarEntrada(producto);
      setLimiteCantidadInferior(Math.floor(1));
      setLimiteCantidadSuperior(
        Math.floor(Number(producto.cantidad * 1.1) -
          Number(producto.sumaEntradasByPOcp)),
      );
      setSumaRegistros(producto.sumaEntradasByPOcp);
    }
    // IF OCC
    if (producto.cantidadOcc) {
      setCantidad(
        Number(producto.cantidad) - Number(producto.sumaSalidasByPOcp));
      setProductoPorDarEntrada(producto);
      setLimiteCantidadInferior(Math.floor(1));
      setLimiteCantidadSuperior(
        Math.floor(
          Number(producto.cantidad * 1.1) - Number(producto.sumaSalidasByPOcp)),
      );
      setSumaRegistros(producto.sumaSalidasByPOcp);
    }

    setEditMode(true);
  };

  const handleSetlimitAsCantidad = () => {
    setCantidad(limiteCantidadSuperior);
  };

  return (
    <React.Fragment>

      {loading && <h1>Loading</h1>}
      {!editMode && (

        <React.Fragment>
          {props.cardTitleProduct && (

            <CardTitle><h2
              className='mt-2 text-center font-bold text-base '>     {props.cardTitleProduct}</h2>
            </CardTitle>

          )}
          {props.asignedListSelector ? (
            <>
              {props.asignedListSelector.length > 0 && (
                <div>
                  <p
                    className='py-2 justify-center text-center text-xs text-gray-500'>
                    En la siguiente tabla puedes encontar las ordenes de compra
                    que
                    contienen este producto
                  </p>

                  <div className='overflow-auto py-4'>
                    <Table>
                      <TableHeader>
                        <TableRowHeader className='bg-gray-300 '>
                          <TableHeaderCell
                            textColor='text-gray-700'>PEDIDAS</TableHeaderCell>
                          <TableHeaderCell
                            textColor='text-gray-700'>ENTREGADAS</TableHeaderCell>
                          <TableHeaderCell
                            textColor='text-gray-700'>RESTANTES</TableHeaderCell>
                          <TableHeaderCell
                            textColor='text-gray-700'>Símbolo</TableHeaderCell>
                          <TableHeaderCell
                            textColor='text-gray-700'>OCP</TableHeaderCell>
                          <TableHeaderCell
                            textColor='text-gray-700'>P_OCP</TableHeaderCell>
                          <TableHeaderCell
                            textColor='text-gray-700'>AÑADIR</TableHeaderCell>
                          <TableHeaderCell
                            textColor='text-gray-700'>Cerrar</TableHeaderCell>
                        </TableRowHeader>
                      </TableHeader>
                      <TableBody>
                        {props.asignedListSelector?.map(
                          (producto, personIdx) => (
                            <React.Fragment key={producto.id}>
                              <TableRow key={producto.id}
                                        className='bg-gray-50'>
                                {producto.cantidadOcp && (
                                  <>
                                    <TableBodyCell textColor='text-gray-500'>
                                      {producto.cantidadOcp}
                                    </TableBodyCell>
                                    <TableBodyCell textColor='text-gray-500'>
                                      {producto.sumaEntradasByPOcp}
                                    </TableBodyCell>
                                    <TableBodyCell textColor='text-gray-500'>
                                      {Number(producto.cantidadOcp) -
                                        Number(producto.sumaEntradasByPOcp)}
                                    </TableBodyCell>
                                  </>
                                )}
                                {producto.cantidadOcc && (
                                  <>
                                    <TableBodyCell textColor='text-gray-500'>
                                      {producto.cantidadOcc}
                                    </TableBodyCell>
                                    <TableBodyCell textColor='text-gray-500'>
                                      {producto.sumaSalidasByPOcp}
                                    </TableBodyCell>
                                    <TableBodyCell textColor='text-gray-500'>
                                      {Number(producto.cantidadOcc) -
                                        Number(producto.sumaSalidasByPOcp)}
                                    </TableBodyCell>
                                  </>
                                )}
                                <TableBodyCell textColor='text-gray-500'>
                                  {producto.simbolo}
                                </TableBodyCell>
                                <TableBodyCell>{producto.numeroOcp}</TableBodyCell>
                                <TableBodyCell
                                  textColor='text-gray-500'>{producto.id}</TableBodyCell>

                                <TableBodyCell textColor='text-gray-500'>
                                  <IconButton
                                    onClick={() => handleAddToEntrada(producto)}
                                    color='orange'
                                    icon='+'
                                    iconHeight={3}
                                    iconWidth={3}
                                  />
                                </TableBodyCell>
                                <TableBodyCell textColor='text-gray-500'>
                                  <IconButton
                                    onClick={() => handleCerrar(producto)}
                                    color='red'
                                    icon='x'
                                    iconHeight={3}
                                    iconWidth={3}
                                  />
                                </TableBodyCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              )}
            </>
          ) : (
            ''
          )}
        </React.Fragment>
      )}

      {!loading && (
        <React.Fragment>
          {editMode && (
            <div className='col-span-6'>
              <CardContainer color={ 'gray-50'}>
                <div className='flex'>
                  {!props.valueToAsignSelector && (
                    <div className='grid-cols-6 '>
                      <InputCornerHint
                        name='simboloProducto'
                        label={'Símbolo'}
                        value={productoPorProcesar?.simbolo?.toUpperCase()}
                        disabled
                        borderColor={'border-gray-400'}
                      />
                      <div className={'my-2'} />
                      <NumberInputWithLimits
                        number={cantidad}
                        onChange={handleCantidad}
                        title={'Cantidad'}
                        leftLimit={Math.floor(limiteCantidadInferior)}
                        rightLimit={Math.floor(limiteCantidadSuperior)}
                        setCantidad={handleSetlimitAsCantidad}
                        leftLabel='-10%'
                        rightLabel='+10%'
                        error={errorCantidad}
                        borderColor={'border-orange-300'}
                      />
                      <div className={'my-4'} />

                      <div className={'my-2'} />
                      <InputCornerHint
                        textColor='text-gray-400'
                        name='unidad'
                        label={'Unidad'}
                        value={productoPorProcesar?.unidad}
                        disabled
                      />
                      <div className={'my-2'} />
                      <InputCornerHint
                        name='lote'
                        label={'Lote'}
                        value={lote}
                        onChange={(e) => setLote(e.target.value)}
                        borderColor={'border-orange-300'}
                      />
                      <div className={'my-2'} />
                      <InputCornerHint
                        name='numeroFactura'
                        label={'#Factura'}
                        value={factura}
                        onChange={(e) => setFactura(e.target.value)}
                        borderColor={'border-orange-300'}
                      />
                      <div className={'my-2'} />
                      <InputCornerHint
                        name='numeroRemision'
                        label={'#Remision'}
                        value={remision}
                        onChange={(e) => setRemision(e.target.value)}
                        borderColor={'border-orange-300'}
                      >
                        <button
                          className='text-xs'
                          onClick={() => {
                            setRemision(factura);
                          }}
                        >
                          Copiar #Factura
                        </button>
                      </InputCornerHint>
                      <div className={'my-2'} />

                      {errorCantidad && (
                        <AlertWithDescription
                          title={errorCantidad}
                          icon={
                            <XCircleIcon className='h-5 w-5 text-red-400'
                                         aria-hidden='true' />
                          }
                        />
                      )}

                      {!errorCantidad && factura && remision && lote && (
                        <>
                          {cantidad > 0 && (
                            <CardButton onClick={handlePostRelation}
                                        bgColor='orange'>
                              Registrar Entrada
                            </CardButton>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {props.valueToAsignSelector && (
                    <React.Fragment>
                      <RelationInsertValue
                        label={props.inputLabel}
                        value={props.valueToAsignSelector[props.nameOfPropertyToAsign]}
                        handlePostRelation={handlePostRelation}
                        handleCancel={handleCancel}
                      />
                    </React.Fragment>
                  )}
                </div>
              </CardContainer>
            </div>
          )}
        </React.Fragment>
      )}

    </React.Fragment>
  );
};
export default AsignProductoOCPtoEntrada;
