export const MatchPreviousValue = () => ({
  isSameValue: false,
  previousValue: '',
  execute(value, defaultValue, fallbackValue) {
    this.defaultValue = defaultValue;
    this.fallbackValue = fallbackValue;
    if (this.previousValue === value) {
      return this.isSameValue ? defaultValue : fallbackValue;
    }
    this.previousValue = value;
    this.isSameValue = !this.isSameValue;
    return this.isSameValue ? defaultValue : fallbackValue;
  },
});
