import React, { useState, useEffect, useContext } from 'react';
import { distitoMateraAPI, apiURL } from '../../Util/apiURL';
import AuthContext from '../../store/auth-context';
import { useHistory, useLocation } from 'react-router';

import {
  createNotaCreditoClienteActions,
  insertNotaCreditoCliente,
} from '../../store/crearNotaCreditoCliente-slice';
import { useSelector, useDispatch } from 'react-redux';

import { CardButton } from '../SharedUi/UpdateCardUI';
import SelectHelper from '../TailwindUI/SelectHelper';

import SelectDate from '../SharedUi/SelectDate';
import { InputCornerHint } from '../SharedUi/Inputs';
import SuccesAlert from '../SuccesAlert/SuccesAlert';

const CreateNotaCreditoFromCpc = props => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    let firstTime = 0;
    if (firstTime === 0 && props.divisa.id === 1) {
      let payload = {
        reduxStateTree: 'divisa',
        valuesToFilter: [props?.divisa],
        selectedValue: props?.divisa?.id,
      };

      dispatch(createNotaCreditoClienteActions.asign(payload));
    }
    firstTime++;
  }, []);

  //#region Redux Selectors--------------------------------->

  //   const monto = useSelector((state) => state.createNotaCreditoCliente.monto);
  const fecha = useSelector(state => state.createNotaCreditoCliente.fecha);

  const createNotaCreditoCliente = useSelector(state => state.createNotaCreditoCliente);

  const observaciones = useSelector(state => state.createNotaCreditoCliente.observaciones);

  //#endregion  Redux Selectors <---------------------------------
  //#region  DiableSumbit Button

  let disableSubmit = true;

  if (createNotaCreditoCliente?.monto && fecha && createNotaCreditoCliente?.divisa?.id) {
    disableSubmit = false;
  }

  //#endregion

  //#region Input Groups--------------------------------->
  const form_datos = [
    {
      parentName: null,
      property: 'observaciones',
      type: 'text',
      foreignKey: false,
      selector: observaciones,
      selectorName: 'observaciones',
    },
  ];

  //#endregion Input Groups <---------------------------------

  //#region Function Handlers--------------------------------->

  const handleAsingDivisa = e => {
    dispatch(createNotaCreditoClienteActions.asign({ ...e }));
    dispatch(createNotaCreditoClienteActions.resetMonto());
  };

  const handleCreateNC = () => {
    console.log('create  pago proveedor ');
    let request = {
      cuenta_por_cobrar_id: props.cpc?.id,
      divisa_id: createNotaCreditoCliente.divisa?.id,
      folio: createNotaCreditoCliente.folio,
      monto: createNotaCreditoCliente.monto.toFixed(2),
      observaciones: createNotaCreditoCliente.observaciones,
      fecha: createNotaCreditoCliente.fecha,
      tipo_de_cambio: createNotaCreditoCliente?.tipoDeCambio?.toFixed(4),
      cliente_id: props.cpc?.clienteId,
    };
    console.log(request);
    dispatch(
      insertNotaCreditoCliente({
        request,
      }),
    );
    // props.onConfirm();
  };

  const handleDate = e => {
    dispatch(
      createNotaCreditoClienteActions.change({
        field: 'fecha',
        value: e.target.value,
      }),
    );
  };

  const handleMonto = e => {
    const divisaPeso = props.divisa?.id === 1;
    const divisaDolar = props.divisa?.id === 2;
    const montoValue = Number(e.target.value);
    const montoYTipoDeCambioValue = Number(e.target.value) / createNotaCreditoCliente?.tipoDeCambio;
    const sumaPagosYnotaYMonto = montoValue + props.totalPagosYNotas;
    const sumaPagosYnotaYMontoYtipoDeCambio = montoYTipoDeCambioValue + props.totalPagosYNotas;
    const condition1 = props.totalPagosYNotas === 0 && montoValue <= props.totalCpc;
    const condition2 = sumaPagosYnotaYMonto <= props.totalCpc;
    const condition1TipoDeCambio =
      props.totalPagosYNotas === 0 && montoYTipoDeCambioValue <= props.totalCpc;

    console.log('props.totalCpc->>>' + props.totalCpc);
    console.log('sumaPagosYnotaYMontoYtipoDeCambio->>>' + sumaPagosYnotaYMontoYtipoDeCambio);

    console.log('Restante->>>' + (props.totalCpc - sumaPagosYnotaYMontoYtipoDeCambio));
    const condition2TipoDeCambio = sumaPagosYnotaYMontoYtipoDeCambio <= props.totalCpc;
    const dispatchMonto = () => {
      dispatch(
        createNotaCreditoClienteActions.change({
          field: 'monto',
          value: e.target.value,
        }),
      );
      return;
    };

    if (e.target.value > 0) {
      console.log('etarget +1');
      if (divisaPeso || (divisaDolar && createNotaCreditoCliente.divisa.id === 2)) {
        console.log('divisaPeso || if (divisaPeso ||createNotaCreditoCliente.divisa.id === 2) ');
        if (condition1 || condition2) {
          console.log('(condition1 || condition2) ');
          dispatchMonto();
          return;
        }
        return;
      }
      if (divisaDolar && createNotaCreditoCliente.divisa.id === 1) {
        console.log('divisaPeso || if (divisaPeso ||createNotaCreditoCliente.divisa.id === 1) ');
        if (condition1TipoDeCambio || condition2TipoDeCambio) {
          console.log('(condition1TipoDeCambio || condition2TipoDeCambio) ');
          dispatchMonto();

          return;
        }
        return;
      }
      return;
    }
  };
  //#endregion Function Handler <---------------------------------

  return (
    <div className="flex flex-col">
      {/* {JSON.stringify(createNotaCreditoCliente)} */}

      <>
        <div className="mt-4" />
        <SelectDate reduxStateTree={'fecha'} action={handleDate}>
          <InputCornerHint
            value={createNotaCreditoCliente?.fechaDisplay}
            disabled
            label={'Fecha'}
          />
        </SelectDate>
        <div className="mt-1" />
        <InputCornerHint
          label={'Folio'}
          type={'text'}
          value={createNotaCreditoCliente.folio}
          onChange={e => {
            dispatch(
              createNotaCreditoClienteActions.change({
                value: e.target.value,
                field: 'folio',
              }),
            );
          }}
        />
        <div className="mt-1" />
        {/* <InputCornerHint
          label={'Motivo'}
          type={'text'}
          value={createNotaCreditoCliente.motivo}
          onChange={(e) => {
            dispatch(
              createNotaCreditoClienteActions.change({
                value: e.target.value,
                field: 'motivo',
              })
            );
          }}
        /> */}

        {props?.divisa?.id === 2 ? (
          <SelectHelper
            //UI
            label={'Divisa'}
            inputLabel={'SeleccionarDivisa'}
            //URL
            fetchUrl={'divisas'}
            //Redux
            asignTree={'divisa'}
            removeTree={'divisa'}
            valueToAsignSelector={createNotaCreditoCliente.divisa}
            // asignedListString={'relationsProductos'}

            //Select Options
            nameOfPropertyToAsign={'nombre'}
            //Function Handlers
            handleSelect={handleAsingDivisa}
          />
        ) : (
          <InputCornerHint
            label={'Divisa'}
            type={'text'}
            value={createNotaCreditoCliente?.divisa?.nombre}
            disbaled
          />
        )}

        <div className="mt-1" />
        {props?.divisa?.id === 2 && createNotaCreditoCliente?.divisa?.nombre === 'MXN' && (
          <InputCornerHint
            label={'Tipo de Cambio'}
            type={'number'}
            value={createNotaCreditoCliente.tipoDeCambio}
            onChange={e => {
              dispatch(
                createNotaCreditoClienteActions.change({
                  value: e.target.value,
                  field: 'tipoDeCambio',
                }),
              );
            }}
          />
        )}

        <div className="mt-6" />
        {createNotaCreditoCliente.divisa?.id && (
          <InputCornerHint
            label={'Monto'}
            type={'number'}
            value={createNotaCreditoCliente?.monto}
            onChange={handleMonto}
          >
            <button
              onClick={() => {
                console.log('dispatch saldo pendiente');
                const divisaDolar = props?.divisa?.id === 2;
                const divisaPeso = props?.divisa?.id === 1;

                const restante = Number(props.totalCpc) - Number(props.totalPagosYNotas);
                const restanteContipoDeCambio = restante * createNotaCreditoCliente?.tipoDeCambio;

                if (divisaDolar) {
                  dispatch(
                    createNotaCreditoClienteActions.change({
                      field: 'monto',
                      value:
                        props?.divisa?.id === 2 &&
                        createNotaCreditoCliente?.divisa?.nombre === 'MXN'
                          ? restanteContipoDeCambio.toFixed(2)
                          : restante.toFixed(2),
                    }),
                  );
                }
                if (divisaPeso) {
                  dispatch(
                    createNotaCreditoClienteActions.change({
                      field: 'monto',
                      value: restante.toFixed(2),
                    }),
                  );
                }
              }}
            >
              <span className="px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                Saldo Pendiente
              </span>
            </button>
          </InputCornerHint>
        )}

        <div className="mt-2" />
        <InputCornerHint
          label={'Observaciones'}
          type={'text'}
          value={createNotaCreditoCliente?.observaciones}
          onChange={e => {
            dispatch(
              createNotaCreditoClienteActions.change({
                value: e.target.value,
                field: 'observaciones',
              }),
            );
          }}
        />
      </>

      {createNotaCreditoCliente.closeModal ? (
        <div className="mt-4">
          <SuccesAlert
            message={'Tu Pago se registro con éxito'}
            onClick={() => {
              props.onConfirm();
              dispatch(createNotaCreditoClienteActions.resetCloseModal());
            }}
          />
        </div>
      ) : (
        <CardButton disabled={disableSubmit} onClick={handleCreateNC}>
          Registrar Nota de Credito
        </CardButton>
      )}
    </div>
  );
};
export default CreateNotaCreditoFromCpc;
