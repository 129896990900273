import react from 'react';
import { Link, useLocation } from 'react-router-dom';

const TableBodyCellAuto = (props) => {
  const location = useLocation();
  // console.log('location');
  // console.log(location);

  const buildPath = (id) => {
    if (typeof id === 'number') {
      return id;
    }
    if (!id?.replace) {
      return id
    }
    let replaced = id.replace(' ', '-');
    return replaced;
  };

  if (props?.list) {
    const values = Object.values(props.list);
    const AutoCells = values.map((item, index) => {
      let id = item;
      if (typeof item === 'string') {
        id = item.split(' ').join();
      }
      let render = (
        <td
          key={index + Math.random(202)}
          className={
            props.className
              ? props.className
              : 'px-2 py-1 whitespace-nowrap text-left text-gray-800 font-medium'
          }
          scope='col'
        >
          {item}
        </td>
      );
      if (index === 0 && !props.linkOff)
        render = (
          <td
            key={index + Math.random(202)}
            className={
              props.className
                ? props.className
                : 'px-2 py-1 whitespace-nowrap text-left text-orange-600 font-medium tracking-wide text decoration '
            }
            scope='col'
          >
            <Link to={`${!props.url ? location.pathname : props.url}/${buildPath(id)}`}>
              {item}
            </Link>
          </td>
        );
      else if (props?.indexChildren?.length > 0) {
        const found = props?.indexChildren?.find((child) => child[0] === index);

        if (found) {
          render = found[1](item);
        }
      } else if (item === 'POR GENERAR')
        render = (
          <td
            key={item + index + Math.random(202)}
            className={
              props.className
                ? props.className
                : ' px-2 py-1 text-left text-white bg-red-400 text-s tracking-wide text decoration '
            }
            scope='col'
          >
            {item}
          </td>
        );

      return render;
    });
    return AutoCells;
  }
  return <th>No Records Found</th>;
};
export default TableBodyCellAuto;
