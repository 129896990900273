import React from 'react';
import Spinner from '../Spinner/Spinner';
import Alert from '../Alert/Alert';
import { RightBottomFixedMessage } from '../SharedUi/Alerts';
import { ErrorAlert, ErrorAlertFromApi } from '../Alert/ErrorAlert';
import { useHistory, useLocation } from 'react-router-dom';

export const LifeCycleFeedback = ({
  sliceState,
  erraseError,
  erraseSucces,
}) => {
  const handleErraseSucces = () => erraseSucces();
  const handleErraseError = () => erraseError();

  return (
    <RightBottomFixedMessage>
      {sliceState?.loading && <Spinner />}
      {sliceState?.error && <Alert message={JSON.stringify(sliceState?.error)}
                                   onClick={handleErraseError} />}
      {sliceState?.success && (
        <Alert message={'Operación Exitosa'} onClick={handleErraseSucces}
               type='ok' />
      )}
    </RightBottomFixedMessage>
  );
};

export const LifeCycleFeedbackV2 = ({
  sliceState,
  erraseError,
  erraseSucces,
}) => {
  const handleErraseSucces = () => erraseSucces();
  const handleErraseError = () => erraseError();
  console.log('sliceState');
  console.log(sliceState ?? 'no slice state');

  const keyValuesFromObj = (arr) => Object.entries(arr)?.map(([key, value]) => {
    return {
      key, value,
    };
  });

  return (
    <RightBottomFixedMessage>
      {sliceState?.loading && <Spinner />}
      {sliceState?.error && <ErrorAlert error={
        <div>
          <div>{sliceState.error}</div>
          <div>{sliceState.errorMessage}</div>
        </div>
      }
                                        message={sliceState?.error}
                                        onClick={handleErraseError} />}
      {sliceState?.success && (
        <Alert message={
          <div>

            <div>
              'Operación Exitosa'
            </div>
            <div className='text-left'>
              {keyValuesFromObj(sliceState.success)?.map(item =>
                <div>
                  <span>{item.key} </span><span
                  className='font-bold'>{item.value}</span>
                </div>,
              )}
            </div>
          </div>

        } onClick={handleErraseSucces} type='ok' />
      )}
    </RightBottomFixedMessage>
  );
};

export const LifeCycleFeedbackV3 = ({
  sliceState,
  erraseError,
  erraseSucces,
}) => {
  const handleErraseSucces = () => erraseSucces();
  const handleErraseError = () => erraseError();
  console.log('sliceState');
  console.log(sliceState ?? 'no slice state');
  const history = useHistory();


  const keyValuesFromObj = (arr) => Object.entries(arr)?.map(([key, value]) => {
    return {
      key, value,
    };
  });

  return (
    <RightBottomFixedMessage>
      {sliceState?.loading && <Spinner />}
      {sliceState?.error && <ErrorAlertFromApi error={
        sliceState.error}

                                               onClick={handleErraseError} />}
      {sliceState?.success && (
        <Alert message={
          <div>

            <div>
              'Operación Exitosa'
            </div>
            <div className='text-left'>
              {keyValuesFromObj(sliceState.success)?.map(item =>
                <div>
                  <span>{item.key} </span><span
                  className='font-bold'>{item.value}</span>
                </div>,
              )}
            </div>

          </div>

        } onClick={handleErraseSucces} type='ok' />
      )}
      {sliceState?.context?.redirectRoutePath && <button
        type='button'
        className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
        onClick={() => history.replace(sliceState?.context?.redirectRoutePath)}
      >
        Regresar
      </button>}
    </RightBottomFixedMessage>
  );
};