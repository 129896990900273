import React, { createContext, useContext } from 'react';
import { useSideBar } from '../hooks/useSideBar';

const SideBarContext = createContext();

export const useSideBarContext = () => useContext(SideBarContext);

export const SidebarProvider = ({ children }) => {
  const { isSidebarOpen, closeSideBar, openSidebar } = useSideBar();

  return <SideBarContext.Provider
    value={{ isSidebarOpen, closeSideBar, openSidebar }}>
    {children}
  </SideBarContext.Provider>;

};

