import React, { useState } from 'react';

import {
  CheckIcon,
  XIcon,
  PencilIcon,
  PlusSmIcon,
  ArrowDownIcon,
  TrashIcon,
  CurrencyDollarIcon,
  ClockIcon,
  ShoppingCartIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';
import axios from 'axios';
import { apiURL_Nest } from '../../Util/apiURL';
import { authorizationHeader } from '../../Util/authorizationHeader';
import { CardButton } from '../SharedUi/UpdateCardUI';
import Spinner from '../Spinner/Spinner';

export const FileInput = ({
  changeHandler,
  submitHandler,
  preview,
  setPreview
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    return changeHandler(event);

  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   console.log('setSelectedFile', selectedFile);
  //
  //   if (selectedFile) {
  //
  //     const fileName = selectedFile.name;
  //     const fileType = selectedFile.type;
  //     const formData = new FormData();
  //     formData.append('file', selectedFile);
  //     console.log('formData->', formData);
  //     console.log('formData->', formData);
  //
  //     const encodedBucket = encodeURIComponent(s3Bucket);
  //     const s3Key=`${category}/${fileName}`
  //     const encodedKey = encodeURIComponent(s3Key);
  //     const url = `${apiHOST}documents/signed-url?key=${encodedKey}&bucket=${encodedBucket}`;
  //
  //     console.log('url-->', url);
  //
  //     const presignedUrl = await axios.get(url,
  //       {
  //         headers: {
  //           ...authorizationHeader(),
  //         },
  //       },
  //     );
  //
  //     console.log('presignedUrl->>>', presignedUrl);
  //     console.log('presignedUrl->>>', presignedUrl);
  //     console.log('presignedUrl->>>', presignedUrl);
  //
  //     const signedUrl = presignedUrl.data;
  //
  //     console.log('signedUrl->', signedUrl);
  //     console.log('selectedFile->', selectedFile);
  //     console.log('fileType->', fileType);
  //     let s3Object;
  //     try {
  //       s3Object = await axios.put(signedUrl, selectedFile, {
  //         headers: {
  //           'Content-Type': fileType,
  //         },
  //       });
  //       console.log('s3Object-->', s3Object);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //
  //     if (s3Object.status === 200) {
  //       const uploadArgs = {
  //         name:s3Key,
  //         url: `https://s3.amazonaws.com/${s3Bucket}/${s3Key}`,
  //         bucket:s3Bucket,
  //         category: category,
  //         categoryId: categoryId,
  //       };
  //
  //       console.log('uploadArgs', uploadArgs);
  //
  //       const uploadUrl = await axios.post(
  //         `${apiHOST}uploads/`,
  //         uploadArgs,
  //
  //         {
  //           headers: {
  //             ...authorizationHeader(),
  //           },
  //         },
  //       );
  //
  //       console.log('uploadUrl');
  //       console.log(uploadUrl);
  //       if (uploadUrl.data) {
  //         console.log('dispat cgett mi cuenta otra ves');
  //         // dispatch(getMicuentaCliente(authCtx));
  //         return;
  //       }
  //
  //     }
  //
  //   }
  // };

  return (
    <>
      {!isLoading?(
        <form onSubmit={async (e) => {
          setIsLoading(true);
          await submitHandler(e);

          setIsLoading(false);
          setPreview(false)

        }

        }>

          {preview && (
            <div>
              <div style={{ width: '200px', height: 'auto' }}  className={"shadow-lg rounded overflow-hidden"}>


              <img src={preview} alt='Preview'
                   style={{ width: '200px', height: 'auto' }} />
              </div>
            </div>
          )}
          {!preview &&(
          <label
            htmlFor='file-upload'
            className=' cursor-pointer bg-white '
          >

        <span
          className=' inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 bg-orangeMely hover:bg-orange-600  focus-within:ring-gray-600'>
         <PlusSmIcon className={'h-5 w-5'} aria-hidden='true' />

        </span>
            <input type='file' accept='image/*' id='file-upload'
                   name='file-upload' className='sr-only'
                   onChange={handleFileChange} />

          </label>)}


          <div>
            <CardButton type={'submit'}>
              Guardar

            </CardButton>

          </div>
        </form>):<Spinner/>}
    </>
  );
};
