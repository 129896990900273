import CategoriasProductos from './CategoriasProductos';
import NewCategoriaProducto from './NewCategoriaProducto';
import ShowCategoriaProducto from './ShowCategoriaProducto';
// errase
export const categoriasProductosRoutes = [
  {
    name: 'CategoriasProductos',
    href: '/categorias-productos',
    component: <CategoriasProductos />,
    exact: true,
  },
  {
    name: 'NewCategoriasProducto',
    href: '/categorias-productos/crear/',
    component: <NewCategoriaProducto />,
    exact: true,
  },
  {
    name: 'ShowCategoriasProducto',
    href: '/categorias-productos/:id',
    component: <ShowCategoriaProducto />,
    exact: true,
  },
];
