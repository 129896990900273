import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { decodeJWT } from './decodeJWT';
import { authorizationHeader } from './authorizationHeader';

export const getRequestThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { authCtx } = payload;
    try {
      console.log('route--->' + route);
      const response = await axios.get(route, {
        headers: {
          ...authorizationHeader(),
        },
      });
      console.log('response.data--->>');
      console.log(response.data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

export const getRequestWparamsThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { urlParam } = payload;
    console.log(`${route}/${urlParam}`);
    console.log(`${route}/${urlParam}------>>>>`);

    try {
      const response = await axios.get(`${route}/${urlParam}`, {
        headers: {
          ...authorizationHeader(),
        },
      });
      console.log('response.data--->>');
      console.log(response.data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

export const getRequestFromClientPathThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { routePath, authCtx } = payload;
    console.log(`${route}${routePath}`);

    try {
      const response = await axios.get(`${route}${routePath}`, {
        headers: {
          ...authorizationHeader(),
        },
      });
      console.log('response.data--->>');
      console.log(response.data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

export const getRequestFromClientPathThunkV2 = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    console.log(`getRequestFromClientPathThunkV2 ----->`);
    const { routePath } = payload;

    console.log(`---->>>ROUTE--->>>${route}${routePath}`);

    const response = await axios.get(`${route}${routePath}`, {
      headers: {
        ...authorizationHeader(),
      },
    });
    console.log(`response data->>`,response.data);

    return response.data;

  });

//TODO deprecate
export const getRequestFromClientPathThunkV3ToDeprecate = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    console.log(`getRequestFromClientPathThunkV3ToDeprecate ->`);
    const { routePath, responseDataKey } = payload;

    console.log(`responseDataKey---->>${responseDataKey} `);

    console.log(`${route}${routePath}`);

    const response = await axios.get(`${route}${routePath}`, {
      headers: {
        ...authorizationHeader(),
      },
    });

    if (!responseDataKey) return;
    console.log(response.data[responseDataKey]);
    return  response.data[responseDataKey];


  });

export const getRequestWithUrl = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { url, authCtx } = payload;
    console.log('getRequestWithUrl--->');
    console.log(url);
    try {
      const response = await axios.get(`${route}${url}`, {
        headers: {
          ...authorizationHeader(),
        },
      });
      console.log('response.data--->>');
      console.log(response.data);

      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });
