import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { postItemThunk } from '../lib/default-post-item';
import { asignReducer } from '../lib/shared-reducers';
import { addDays } from '../Util/get-date';

const apiHOST = apiURL();
const initialState = {
  factura: '',
  fechaFactura: '',
  fechaVencimiento: '',
  cliente: '',
  iva: false,
};

export const insertCuentaPorCobrar = postItemThunk(
  'crearCuentaPorCobrar/insertCuentaPorCobrar',
  `${apiHOST}cuentas-por-cobrar/`,
  'cuentas-por-cobrar',
);

const createCuentaPorCobrarSlice = createSlice({
  name: 'createCuentaPorCobrar',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'factura':
          state.factura = action.payload.value;
          break;
        case 'fechaFactura':
          state.fechaFactura = action.payload.value;
          break;
        case 'fechaVencimiento':
          const { fechaFactura, sliceState } = action.payload.value;
          state.fechaVencimiento = addDays(fechaFactura, sliceState.cliente.diasCredito);
          break;
        case 'vigente':
          state.enCalidadDe = action.payload.value;
          break;

        case 'reset':
          state.fechaFactura = '';
          state.fechaVencimiento = '';
          break;

        case 'iva':
          state.iva = action.payload.value;
          break;

        default:
      }
    },
    asignReducer,
  },
  extraReducers: (builder) => {},
});

export default createCuentaPorCobrarSlice;

export const createCuentaPorCobrarActions = createCuentaPorCobrarSlice.actions;
