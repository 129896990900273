import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../../Util/authorizationHeader';

export const getRequestRelationThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestUrl, reduxStateTree, authCtx } = payload;

    try {
      const response = await axios.get(`${route}${requestUrl}`, {
        headers: {
          ...authorizationHeader()        },
      });
      return { data: response.data, reduxStateTree };
    } catch (err) {
      console.log(err);
      console.log('err-,<<<<<>>>>>>>>>>>>>>>>>>>');

      return rejectWithValue(err.response.data);
    }
  });
