import React from 'react';
import { useNavigation } from '../../hooks/useNavigation';
import { NavBar } from '../UI/NavBars/NavBar';

function classNames (...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navigation = ({setSidebarOpen,navBarSections}) => {




  return (

  <NavBar setSidebarOpen={setSidebarOpen}
          navBarSections={navBarSections}
          classnames={classNames} />
  );
};
export default Navigation;
