import Alert from '../Alert/Alert';
export const AlertWithDescription = (props) => (
  <div className="rounded-md bg-red-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">{props.icon}</div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">{props.title}</h3>
        <div className="mt-2 text-sm text-red-700">
          {props.list && (
            <ul className="list-disc pl-5 space-y-1">
              {props.list.map((i) => (
                <li>{i}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  </div>
);

export const RightBottomFixedMessage = (props) => (
  <div
    style={{ position: 'sticky', bottom: '10px', right: '10px' }}
    id="error-occ"
    className=" drop-shadow-lg rounded text-white text-center px-4 py-2"
  >
    {props.children}
  </div>
);
