import React from 'react';
import {
  getDocs,
  fetchAllActions as sliceActions, handleFilter,
} from '../../store/fetch-all-slice';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const filterCondition = (state, value) => {
  let condition = (
    String(state?.razonSocial).toUpperCase().includes(value) ||
    String(state?.rfc).toUpperCase().includes(value)
  );
  return condition;
};

const NotificationTemplates = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    getItems: getDocs,
    handleFilter,
    routePath: 'notificaciones/channels',
    // filterCondition,

    orderByOptions: [

    ],
  });
};

export default NotificationTemplates;
