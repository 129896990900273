import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { authorizationHeader } from '../Util/authorizationHeader';

const filterSeleccion = (values, selectedValueId) => {
  const filterdValue = values.filter((item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const apiHOST = apiURL();
const initialState = {
  displayAll: false,
  inventario: [],
  inventarioMelyMx: [],
  inventarioMelyUsa: [],
  inventarioClientes: [],
  inventarioRechazos: [],
  devoluciones: [],
  display: {
    inventario: [],
    inventarioMelyMx: [],
    inventarioMelyUsa: [],
    inventarioClientes: [],
    inventarioRechazos: [],
  },
};

export const getInventario = createAsyncThunk('producto/getInventario', async (payload) => {
  const { authCtx } = payload;
  try {
    const response = await axios.get(`${apiHOST}views/inventario`, {
      headers: {
        ...authorizationHeader()      },
    });
    console.log('response Data get inventario ----slice');
    console.log(response);

    return response.data;
  } catch (err) {
    return err;
  }
});

const inventarioSlice = createSlice({
  name: 'getInventario',
  initialState: initialState,
  reducers: {
    shouldDisplayAll(state, action) {
      console.log('action----->>>>>>>');
      console.log(action);
      state.displayAll = action.payload;
    },
    resetFilter(state, action) {
      state.filterSearchValue = null;
    },
    filterBy(state, action) {
      const value = String(action.payload.value).toUpperCase();
      state.filterSearchValue = value;
      console.log('action.payload.valueeeee----->>>>>>>' + action.payload.value);
      console.log(typeof action.payload.value);
      if (value !== '') {
        console.log(value === '');
        try {
          console.log(action.payload.raw);
          console.log('inventarioFilter----->>>>>>>');
          const inventarioFilter = action.payload.raw.inventario?.filter((item) =>
            item?.simbolo?.includes(value),
          );
          console.log(inventarioFilter);
          const inventarioMelyMxFilter = action.payload.raw.inventarioMelyMx?.filter((item) =>
            item?.simbolo?.includes(value),
          );
          console.log('inventarioMelyMxFilter----->>>>>>>');
          const inventarioMelyUsaFilter = action.payload.raw.inventarioMelyUsa?.filter((item) =>
            item?.simbolo?.includes(value),
          );
          console.log(inventarioMelyMxFilter);
          const melyClientesFilter = action.payload.raw.inventarioClientes?.filter((item) =>
            item?.simbolo?.includes(value),
          );
          const inventarioRechazosFilter = action.payload.raw.inventarioRechazos?.filter((item) =>
            item?.simbolo?.includes(value),
          );

          const filtro = {
            inventario: inventarioFilter,
            inventarioMelyMx: inventarioMelyMxFilter,
            inventarioMelyUsa: inventarioMelyUsaFilter,
            inventarioClientes: melyClientesFilter,
            inventarioRechazos: inventarioRechazosFilter,
          };
          state.display = filtro;
        } catch (err) {
          console.log('ERROR----->>>>>>>' + err);
          state.display = action.payload.raw.display;
        }
      } else {
        console.log('action.payload.value ====  ----->>>>>>>');
        console.log(action.payload.raw.display);
        state.display = {
          inventario: action.payload.raw.inventario,
          inventarioMelyMx: action.payload.raw.inventarioMelyMx,
          inventarioMelyUsa: action.payload.raw.inventarioMelyUsa,
          inventarioClientes: action.payload.raw.inventarioClientes,
          inventarioRechazos: action.payload.raw.inventarioRechazos,
        };
      }
    },
    orderBy(state, action) {
      state.orderBy = action.payload;
      let payloadValue = action.payload;
      let sortFunction = (a, b) => {
        if (state.sortAscending) {
          if (a[payloadValue] > b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] < b[payloadValue]) {
            return -1;
          }
        }
        if (!state.sortAscending) {
          if (a[payloadValue] < b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] > b[payloadValue]) {
            return -1;
          }
        }
        return 0;
      };
      if (payloadValue === 'cp') {
        sortFunction = (a, b) => {
          if (state.sortAscending) {
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return -1;
            }
          }
          if (!state.sortAscending) {
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return -1;
            }
          }
          return 0;
        };
      }

      state.display.sort(sortFunction);
    },
    sortAscending(state, action) {
      state.sortAscending = !action.payload;
      const sortFunction = () => {
        if (state.sortAscending === action.payload) {
          return 1;
        }
        if (state.sortAscending !== action.payload) {
          return -1;
        }
      };
      state.display.sort(sortFunction);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInventario.fulfilled, (state, action) => {
      const {
        inventario,
        inventarioMelyMx,
        inventarioMelyUsa,
        inventarioClientes,
        inventarioRechazos,
      } = action.payload;
      state.inventario = inventario;
      state.inventarioMelyMx = inventarioMelyMx;
      state.inventarioMelyUsa = inventarioMelyUsa;
      state.inventarioClientes = inventarioClientes;
      state.inventarioRechazos = inventarioRechazos;
      state.display.inventario = inventario;
      state.display.inventarioMelyMx = inventarioMelyMx;
      state.display.inventarioMelyUsa = inventarioMelyUsa;
      state.display.inventarioClientes = inventarioClientes;
      state.display.inventarioRechazos = inventarioRechazos;

      state.filterSearchValue = '';
    });
  },
});

export default inventarioSlice;

export const inventarioActions = inventarioSlice.actions;
