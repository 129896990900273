import React, { useState, useEffect, useContext, Fragment } from 'react';
import { distitoMateraAPI, apiURL } from '../../Util/apiURL';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PencilAltIcon, TrashIcon, LogoutIcon } from '@heroicons/react/solid';

import {
  getSalidaIC,
  putSalidaIC,
  deleteSalidaIC,
  editSalidaICActions,
  getRelation,
  postRelationProducto,
  deleteRelationProducto,
  cerrarSalida,
} from '../../store/editSalidaIC-slice';

import ProductosSalidaIC from './ProductosSalidaIC';
import AsignProductoSalidaIC from './AsignProductoSalidaIC';
import DotsDropdown from '../DropdownMenu/DotsDropdown';
import DropdownItemButton from '../DropdownMenu/DropdownItemButton';
import InfoMely, { datosMelyMx, datosMelyUsa } from '../InfoMely/InfoMely';
import SalidaICPDF from '../DocsToPrint/SalidaICPDF';
import DocsCard from '../SharedUi/DocsCard';

import {
  DocsCardLayoutLogo,
  DocsCardLayoutDatosDoc,
  DocsCardLayoutMenu,
  DocsCardLayoutTwoCols,
  DocsCardDatosFiscales,
  DocsCardLayoutInfoMely,
} from '../SharedUi/DocsCardLayout';
import DocsCardDatosDoc from '../SharedUi/DocsCardDatosDoc';
import LogoMely from '../SharedUi/LogoMely';
import IconButton from '../TailwindUI/IconButton';

const EditSalidaIC = () => {
  const editSalidaICState = useSelector((state) => state.editSalidaIC);

  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const urlParam = location.pathname.split('/')[2];
  const requestUrl = 'ordenes-compra-cliente';
  const deleteUrl = `salidas-inventario-clientes/${editSalidaICState?.salida?.id}`;
  const existenciasInventario_URL = `views/salidas-inventario-clientes/inventario-clientes/by-cliente/${editSalidaICState.cliente?.id}`;
  const relationProductosSalida_URL = `views/productos-salida-inventario-clientes/${urlParam}`;
  const putRequestObj = {
    numero_occ: editSalidaICState?.occ?.numeroOcc,
    cliente_id: editSalidaICState?.occ?.clienteId,
    iva: editSalidaICState?.occ?.iva,
    usuario_id: editSalidaICState?.occ?.usuarioId,
    divisa_id: editSalidaICState?.occ?.divisaId,
    inventario_id: editSalidaICState?.occ?.inventarioId,
    en_calidad_de_id: editSalidaICState?.occ?.enCalidadDeId,
    estatus: editSalidaICState?.occ?.estatus,
    cantidad_letra: editSalidaICState?.occ?.cantidadLetra,
  };

  const [firstLoad, setFirstLoad] = useState(true);
  const [useEffectVariable, setUseEffectVariable] = useState(0);
  const fetchSalida = () => {
    dispatch(getSalidaIC({ urlParam, authCtx }));
  };
  useEffect(() => {
    fetchSalida();
    getRelationHandler(relationProductosSalida_URL, 'productosSalida');
  }, []);

  useEffect(() => {
    dispatch(editSalidaICActions.resetProductToSearch());
    dispatch(editSalidaICActions.resetSeleccionInventario());
    setFirstLoad(false);
    if (!firstLoad) {
      // getRelationHandler(relationProducto_URL, 'productosOrdenCompra');
      // getRelationHandler(existenciasInventario_URL, 'existenciasInventario');
      dispatch(editSalidaICActions.resetExistenciasInventario());
    }
  }, [useEffectVariable]);

  const handleResetProductosOrdenescompra = () => {
    dispatch(editSalidaICActions.resetProductosOrdenCompra());
  };

  const handlePutOrdenCompraProveedor = () => {
    dispatch(
      putSalidaIC({
        requestObj: putRequestObj,
        requestUrl,
        reduxStateTree: editSalidaICState,
        history,
        authCtx,
      }),
    );
  };

  const handleDelete = () => {
    console.log({
      requestUrl: deleteUrl,
      reduxStateTree: editSalidaICState,
      history,
    });
    dispatch(
      deleteSalidaIC({
        requestUrl: deleteUrl,
        reduxStateTree: editSalidaICState,
        history,
        authCtx,
      }),
    );
  };
  //Relations
  ////Redux Actions
  const handleAsingProducto = (e) => {
    dispatch(editSalidaICActions.asign({ ...e }));
  };
  const handleCancelAsingProducto = (e) => {
    dispatch(editSalidaICActions.cancelAsign({ ...e }));
  };
  //Relations AsyncTHunks

  const getRelationHandler = (requestUrl, reduxStateTree) => {
    dispatch(
      getRelation({
        requestUrl,
        reduxStateTree,
        authCtx,
      }),
    );
  };

  const handleEraseRelation = (e) => {
    console.log('errase relation!!!-------------<<<<<<<<<<<<<<<<<');
    console.log(e);
    console.log('errase relation!!!-------------<<<<<<<<<<<<<<<<<');
    const requestObj = {
      id: e.valueToUnasign.id,
    };
    const { reduxStateTree, asignedListString, asignedListSelector } = e;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    console.log('event');
    console.log(e);

    dispatch(
      deleteRelationProducto({
        requestObj,
        reduxStateTree,
        asignedListString,
        asignedListSelector,
        authCtx,
      }),
    );
    setUseEffectVariable(useEffectVariable + 1);
  };

  const handlePostRelation = (e) => {
    dispatch(editSalidaICActions.resetProductosOrdenCompra());
    let requestObj = {};
    const { reduxStateTree, selectedValue, asignedListString } = e;
    console.log('reduxStateTree----------');
    console.log(reduxStateTree);
    if (reduxStateTree === 'productToAsign') {
      requestObj = { ...selectedValue };
    }
    dispatch(
      postRelationProducto({
        requestObj,
        reduxStateTree,
        selectedValue,
        asignedListString,
        authCtx,
      }),
    );
    handleResetProductosOrdenescompra();
    dispatch(editSalidaICActions.resetProductToSearch());
    dispatch(editSalidaICActions.resetSeleccionInventario());
    setUseEffectVariable(useEffectVariable + 1);
  };

  return (
    <>
      {/* <div style={{ minWidth: '30vw', height: '100vh' }}>

      <PDFViewer width={600} height={600}>
        <EntradaPDF
          mely={
            editSalidaICState?.inventario?.nombre === 'MELYUSA'
              ? { ...datosMelyUsa }
              : { ...datosMelyMx }
          }
          info={{
            titulo: 'NOTA DE ENTRADA',
            producto: ' CAJAS',
            numero: editSalidaICState?.salida?.id,
            tipo: 'OCP',
            fecha: editSalidaICState?.salida?.createdAt?.split('T')[0],
            enCalidadDe: editSalidaICState?.salida?.enCalidadDe?.toUpperCase(),
          }}
          numeroOC={filterArrByObjPropertyName(editSalidaICState.productosSalida, 'numeroOcc')}
          list={editSalidaICState?.productosSalida}
          cliente={{
            razonSocial: editSalidaICState?.cliente?.razonSocial,
            direccionFiscal: {
              calle: editSalidaICState?.cliente?.direccionFiscal?.calle,
              numero: editSalidaICState?.cliente?.direccionFiscal?.numero,
              ciudad: editSalidaICState?.cliente?.direccionFiscal?.ciudad,
              estado: editSalidaICState?.cliente?.direccionFiscal?.estado,
              cp: editSalidaICState?.cliente?.direccionFiscal?.cp,
              pais: editSalidaICState?.cliente?.direccionFiscal?.pais,
            },
          }}
        />
      </PDFViewer>


    </div> */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Main content */}

        <FlexContainer className="flex-1 items-stretch  overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            {editSalidaICState?.salida?.vigente && (
              <div className="mt-4">
                {!editSalidaICState?.existenciasInventario && (
                  <IconButton
                    onClick={() => {
                      getRelationHandler(existenciasInventario_URL, 'productosOrdenCompra');
                    }}
                    color="orangeMely"
                    icon="+"
                  />
                )}
                {editSalidaICState.productosOrdenCompra && (
                  <div style={{ position: 'relative' }}>
                    <div
                      className="flex-col justify-center bg-orangeMely p-12 mb-4"
                      style={{
                        position: 'absolute',
                        top: '0',
                        height: '100vh',
                        width: '100%',
                        zIndex: '2',
                      }}
                    >
                      <div className="bg-gray-100  mt-10 pb-10 pt-10  text-center">
                        {editSalidaICState.productosOrdenCompra.length === 0 && (
                          <div>No se encontraron odenes de compra vigentes para ese producto</div>
                        )}
                        {editSalidaICState.productosOrdenCompra.length > 0 && (
                          <AsignProductoSalidaIC
                            //UI
                            cardTitle={
                              editSalidaICState?.productToSearch?.simbolo
                                ? `Ordenes de Compra que contienen ${editSalidaICState?.productToSearch?.simbolo}`
                                : ''
                            }
                            inputLabel={'Añade Productos'}
                            //Redux
                            asignTree={'productToAsign'}
                            cantidadDisponible={editSalidaICState?.productToSearch?.cantidad}
                            valueToAsignSelector={editSalidaICState?.productToAsign}
                            asignedListSelector={editSalidaICState?.productosOrdenCompra}
                            asignedListString={'productosSalida'}
                            salidaICId={urlParam}
                            cliente={editSalidaICState?.cliente}
                            enCalidadDe={editSalidaICState?.salida?.enCalidadDe}
                            //Function Handlers

                            handleSelect={handleAsingProducto}
                            handleCancel={handleCancelAsingProducto}
                            handlePostRelation={handlePostRelation}
                            handleUpdate={handlePutOrdenCompraProveedor}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* <div>{JSON.stringify(editSalidaICState)}</div> */}

            <FlexContainer className="flex-col">
              <div>
                <DocsCard>
                  <DocsCardLayoutMenu>
                    <DotsDropdown vigente={editSalidaICState?.salida?.vigente}>
                      {editSalidaICState?.salida?.vigente ? (
                        <React.Fragment>
                          {editSalidaICState?.productosSalida?.length === 0 && (
                            <DropdownItemButton onClick={() => handleDelete()}>
                              <TrashIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              Borrar Salida
                            </DropdownItemButton>
                          )}
                        </React.Fragment>
                      ) : (
                        <DropdownItemButton>
                          <PencilAltIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />

                          <PDFDownloadLink
                            document={
                              <SalidaICPDF
                                mely={
                                  editSalidaICState?.inventario?.nombre === 'MELYUSA'
                                    ? { ...datosMelyUsa }
                                    : { ...datosMelyMx }
                                }
                                info={{
                                  titulo: 'NOTA DE SALIDA IC',
                                  producto: ' CAJAS',
                                  numero: editSalidaICState?.salida?.id,
                                  tipo: 'OCC',
                                  fecha: editSalidaICState?.salida?.createdAt?.split('T')[0],
                                  enCalidadDe: 'Embarque',
                                }}
                                numeroOC={['']}
                                list={editSalidaICState?.productosSalida}
                                cliente={{
                                  razonSocial: editSalidaICState?.cliente?.razonSocial,
                                  direccionFiscal: {
                                    calle: editSalidaICState?.cliente?.direccionFiscal?.calle,
                                    numero: editSalidaICState?.cliente?.direccionFiscal?.numero,
                                    ciudad: editSalidaICState?.cliente?.direccionFiscal?.ciudad,
                                    estado: editSalidaICState?.cliente?.direccionFiscal?.estado,
                                    cp: editSalidaICState?.cliente?.direccionFiscal?.cp,
                                    pais: editSalidaICState?.cliente?.direccionFiscal?.pais,
                                  },
                                }}
                              />
                            }
                            fileName={`Salida_IC_${editSalidaICState?.salida?.id}.pdf`}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? 'Preparando Documento...' : 'Descargar'
                            }
                          </PDFDownloadLink>
                        </DropdownItemButton>
                      )}
                      {editSalidaICState?.salida?.vigente &&
                        editSalidaICState?.productosSalida?.length > 0 && (
                          <div>
                            {editSalidaICState?.salida?.vigente && (
                              <DropdownItemButton
                                onClick={() =>
                                  dispatch(
                                    cerrarSalida({
                                      salida: editSalidaICState.salida,
                                      productosSalida: editSalidaICState.productosSalida,
                                      authCtx,
                                    }),
                                  )
                                }
                              >
                                <LogoutIcon
                                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                Cerrar Salida
                              </DropdownItemButton>
                            )}
                          </div>
                        )}
                    </DotsDropdown>
                  </DocsCardLayoutMenu>
                  <DocsCardLayoutLogo>
                    <LogoMely className="h-16 w-auto" />
                  </DocsCardLayoutLogo>
                  <DocsCardLayoutDatosDoc>
                    <DocsCardDatosDoc
                      title={'SALIDA IC'}
                      number={editSalidaICState?.salida?.id}
                      date={editSalidaICState?.salida?.createdAt?.split('T')[0]}
                    />
                  </DocsCardLayoutDatosDoc>
                  <DocsCardLayoutInfoMely>
                    <InfoMely
                      inventario={editSalidaICState?.inventario?.nombre}
                      datosMely={
                        editSalidaICState?.inventario?.nombre === 'MELYUSA'
                          ? datosMelyUsa
                          : datosMelyMx
                      }
                    />
                  </DocsCardLayoutInfoMely>
                  <DocsCardLayoutTwoCols>
                    <DocsCardDatosFiscales sliceState={editSalidaICState?.cliente} />

                    <div className="col-span-1 mt-12 text-sm text-right ml-auto ">
                      <div>
                        <span className="mr-2">FECHA:</span>
                        <span className="font-medium">
                          {` ${editSalidaICState?.salida?.createdAt?.split('T')[0]}`}
                        </span>
                      </div>
                    </div>
                  </DocsCardLayoutTwoCols>

                  <div className="col-span-6">
                    <>
                      <ProductosSalidaIC
                        //UI
                        cardTitle={'Productos Salida IC'}
                        //Redux
                        vigente={editSalidaICState?.salida?.vigente}
                        asignTree={'productToAsign'}
                        removeTree={'productToRemoveAsign'}
                        valueToAsignSelector={editSalidaICState?.productToAsign}
                        asignedListSelector={editSalidaICState?.productosSalida}
                        asignedListString={'productosSalida'}
                        //Table
                        cantidadPropertyName={'cantidadSalida'}
                        //Function Handlers

                        handleSelect={handleAsingProducto}
                        handleCancel={handleCancelAsingProducto}
                        handlePostRelation={handlePostRelation}
                        handleErrase={handleEraseRelation}
                        handleUpdate={handlePutOrdenCompraProveedor}
                      />
                    </>
                  </div>
                  {
                    //#endregion
                  }
                </DocsCard>
              </div>
            </FlexContainer>
          </main>
        </FlexContainer>
      </div>
    </>
  );
};
export default EditSalidaIC;
