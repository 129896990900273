import {
  BookOpenIcon,
  CashIcon,
  CogIcon,
  CollectionIcon, CubeIcon, LoginIcon,
  LogoutIcon, UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/outline';

export const fullRoleSettings = [
  {
    name: 'Configuración',
    icon: CogIcon,
    href: '/configuracion',
    children: [
      {
        icon: UsersIcon,
        name: 'Usuarios',
        href: '/configuracion/usuarios',
        current: false,
      },
      {
        icon: CollectionIcon,
        name: 'Dashboard',
        href: '/configuracion/dashboard',
        current: false,
      },
    ],
  },
];
export const fullRole = [
  {
    name: 'Inventario',
    icon: CollectionIcon,
    href: '/inventario',
    current: false,
    children: [
      {
        name: 'Inventarios',
        href: '/inventario',
        current: false,
      },
      {
        name: 'Inventarios2',
        href: '/test-inventario',
        current: false,
      },
      {
        name: 'Entradas',
        href: '/entradas',

        current: false,
      },
      {
        name: 'Rechazos Cliente',
        href: '/rechazos-cliente',

        current: false,
      },
      {
        name: 'Salidas',
        href: '/salidas',
        current: false,
      },
      {
        name: 'Salidas Inventario Clientes',
        href: '/salidas-inventario-clientes',
        current: false,
      },
    ],
  },

  {
    name: 'Clientes',
    href: '/clientes',
    icon: UsersIcon,
    current: false,
    children: [
      {
        name: 'Ordenes de compra',
        href: '/ordenes-de-compra-cliente',
        icon: LogoutIcon,
        current: false,
      },
      {
        name: 'Edo de cuenta',
        href: '/estado-de-cuenta/clientes',
        icon: CashIcon,
        current: false,
      },

      {
        name: 'Pagos',
        href: '/pagos-clientes',
        icon: CashIcon,
        current: false,
      },
      {
        name: 'Cuentas Por Cobrar',
        href: '/cuentas-por-cobrar',
        icon: CashIcon,
        current: false,
      },
    ],
  },

  {
    name: 'Proveedores',

    icon: BookOpenIcon,
    current: false,
    children: [
      {
        name: 'Ordenes de Compra',
        href: '/ordenes-de-compra-proveedor',
        icon: LoginIcon,
        current: false,
      },
      {
        name: 'Edo de cuenta',
        href: '/estado-de-cuenta/proveedores',
        icon: CashIcon,
        current: false,
      },

      {
        name: 'Pagos',
        href: '/pagos-proveedores',
        icon: CashIcon,
        current: false,
      },
      {
        name: 'Cuentas Por Pagar',
        href: '/cuentas-por-pagar',
        icon: CashIcon,
        current: false,
      },
    ],
  },
  {
    name: 'Notificaciones',

    icon: BookOpenIcon,
    current: false,
    children: [
      {
        name: 'Notificaciones',
        href: '/notificaciones',
        icon: LoginIcon,
        current: false,
      },

      // {
      //   name: 'Emails',
      //   href: '/emails',
      //   icon: CashIcon,
      //   current: false,
      // },
    ],
  },

  {
    name: 'Biblioteca',
    href: '/productos',
    icon: CubeIcon,
    current: false,
    children: [
      {
        name: 'Documentos',
        href: '/documentos',
        icon: CubeIcon,
        current: false,
      },
      {
        name: 'Productos',
        href: '/productos',
        icon: CubeIcon,
        current: false,
      },
      {
        name: 'Clientes',
        href: '/clientes',
        icon: UsersIcon,
        current: false,
      },
      {
        name: 'Proveedores',
        href: '/proveedores',
        icon: BookOpenIcon,
        current: false,
      },
      {
        name: 'Categorias P',
        href: '/categorias-productos',
        icon: BookOpenIcon,
        current: false,
      },
      {
        name: 'N. Tipos',
        href: '/notificaciones-tipos',
        icon: LoginIcon,
        current: false,
      },
      {
        name: 'N. Settings',
        href: '/notificaciones-settings',
        icon: LoginIcon,
        current: false,
      },
      {
        name: 'N. Templates',
        href: '/notificaciones-templates',
        icon: LoginIcon,
        current: false,
      },
      {
        name: 'N. Channels',
        href: '/notificaciones-channels',
        icon: LoginIcon,
        current: false,
      },
      // {
      //   name: 'Contactos',
      //   href: '/contactos',
      //   icon: UserGroupIcon,
      //   current: false,
      // },
    ],
  },
];

export const adminRole = [
  {
    name: 'Clientes',
    href: '/clientes',
    icon: UsersIcon,
    current: false,
    children: [
      {
        name: 'Ordenes de compra',
        href: '/ordenes-de-compra-cliente',
        icon: LogoutIcon,
        current: false,
      },
    ],
  },

  {
    name: 'Proveedores',

    icon: BookOpenIcon,
    current: false,
    children: [
      {
        name: 'Ordenes de Compra',
        href: '/ordenes-de-compra-proveedor',
        icon: LoginIcon,
        current: false,
      },
      {
        name: 'Edo de cuenta',
        href: '/estado-de-cuenta/proveedores',
        icon: CashIcon,
        current: false,
      },

      {
        name: 'Pagos',
        href: '/pagos-poveedores',
        icon: CashIcon,
        current: false,
      },
      {
        name: 'Cuentas Por Pagar',
        href: '/cuentas-por-pagar',
        icon: CashIcon,
        current: false,
      },
    ],
  },

  {
    name: 'Biblioteca',
    href: '/productos',
    icon: CubeIcon,
    current: false,
    children: [
      {
        name: 'Productos',
        href: '/productos',
        icon: CubeIcon,
        current: false,
      },
      {
        name: 'Clientes',
        href: '/clientes',
        icon: UsersIcon,
        current: false,
      },
      {
        name: 'Proveedores',
        href: '/proveedores',
        icon: BookOpenIcon,
        current: false,
      },
      {
        name: 'Contactos',
        href: '/contactos',
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: 'Categorias P',
        href: '/categorias-productos',
        icon: BookOpenIcon,
        current: false,
      },
    ],
  },
];

export const inventarioRole = [
  {
    name: 'Inventario',
    icon: CollectionIcon,
    href: '/inventario',
    current: false,
    children: [
      {
        name: 'Inventarios',
        href: '/inventario',
        current: false,
      },
      {
        name: 'Entradas',
        href: '/entradas',

        current: false,
      },
      {
        name: 'Entradas Rechazos',
        href: '/rechazos-cliente',

        current: false,
      },

      {
        name: 'Salidas',
        href: '/salidas',
        current: false,
      },
      {
        name: 'Salidas Inventario Clientes',
        href: '/salidas-inventario-clientes',
        current: false,
      },
    ],
  },
  {
    name: 'Clientes',
    href: '/clientes',
    icon: UsersIcon,
    current: false,
    children: [
      {
        name: 'Ordenes de compra',
        href: '/ordenes-de-compra-cliente',
        icon: LogoutIcon,
        current: false,
      },
    ],
  },
  {
    name: 'Proveedores',

    icon: BookOpenIcon,
    current: false,
    children: [
      {
        name: 'Ordenes de Compra',
        href: '/ordenes-de-compra-proveedor',
        icon: LoginIcon,
        current: false,
      },
    ],
  },
];
export const ventasRole = [
  {
    name: 'Inventario',
    icon: CollectionIcon,
    href: '/inventario',
    current: false,
    children: [
      {
        name: 'Inventarios',
        href: '/inventario',
        current: false,
      },
    ],
  },
  {
    name: 'Clientes',
    href: '/clientes',
    icon: UsersIcon,
    current: false,
    children: [
      {
        name: 'Ordenes de compra',
        href: '/ordenes-de-compra-cliente',
        icon: LogoutIcon,
        current: false,
      },
    ],
  },
  {
    name: 'Proveedores',

    icon: BookOpenIcon,
    current: false,
    children: [
      {
        name: 'Ordenes de Compra',
        href: '/ordenes-de-compra-proveedor',
        icon: LoginIcon,
        current: false,
      },
    ],
  },
  {
    name: 'Biblioteca',
    href: '/productos',
    icon: CubeIcon,
    current: false,
    children: [
      {
        name: 'Productos',
        href: '/productos',
        icon: CubeIcon,
        current: false,
      },
    ],
  },
];

export const cuentasRole = [
  {
    name: 'Inventario',
    icon: CollectionIcon,
    href: '/inventario',
    current: false,
    children: [
      {
        name: 'Entradas',
        href: '/entradas',

        current: false,
      },

      {
        name: 'Salidas',
        href: '/salidas',
        current: false,
      },
    ],
  },
  {
    name: 'Clientes',
    href: '/clientes',
    icon: UsersIcon,
    current: false,
    children: [
      {
        name: 'Edo de cuenta',
        href: '/estado-de-cuenta/clientes',
        icon: CashIcon,
        current: false,
      },
      {
        name: 'Cuentas Por Cobrar',
        href: '/cuentas-por-cobrar',
        icon: CashIcon,
        current: false,
      },
      {
        name: 'Pagos',
        href: '/pagos-clientes',
        icon: CashIcon,
        current: false,
      },
    ],
  },

  {
    name: 'Proveedores',

    icon: BookOpenIcon,
    current: false,
    children: [
      {
        name: 'Edo de cuenta',
        href: '/estado-de-cuenta/proveedores',
        icon: CashIcon,
        current: false,
      },
      {
        name: 'Cuentas Por Pagar',
        href: '/cuentas-por-pagar',
        icon: CashIcon,
        current: false,
      },
      {
        name: 'Pagos',
        href: '/pagos-proveedores',
        icon: CashIcon,
        current: false,
      },
    ],
  },
];
