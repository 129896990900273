import { clientesRoutes } from '../components/Clientes/clientesRoutes';
import { productosRoutes } from '../components/Productos/productosRoutes';
import { proveedoresRoutes } from '../components/Proveedores/proveedoresRoutes';
import { occRoutes } from '../components/OCC/occRoutes';
import { ocpRoutes } from '../components/OCP/ocpRoutes';
import { cppRoutes } from '../components/CuentasPorPagar/cppRoutes';
import { cpcRoutes } from '../components/CuentasPorCobrar/cpcRoutes';
import { pagosCuentasRoutes } from '../components/PagosCuentas/pagosCuentasRoutes';
import {estadosDeCuentaRoutes} from '../components/EstadosDeCuenta/estadosDeCuentaRoutes';
import { categoriasProductosRoutes } from '../components/CategoriasProducto/categoriasProductosRoutes';
import { documentosRoutes } from '../components/Documentos/DocumentosRoutes';
import {
  notificacionesRoutes
} from '../components/Notificaciones/NotificacionesRoutes';

export const adminRoutes = [
  ...documentosRoutes,
  ...notificacionesRoutes,
  ...categoriasProductosRoutes,
  ...clientesRoutes,
  ...productosRoutes,
  ...proveedoresRoutes,
  ...occRoutes,
  ...ocpRoutes,
  ...cppRoutes,
  ...cpcRoutes,
  ...pagosCuentasRoutes,
  ...estadosDeCuentaRoutes
];
