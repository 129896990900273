import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '../TailwindUI/IconButton';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import RelationInsertValue from '../SharedUi/RelationInsertValue';
import { XCircleIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router';
import { InputCornerHint, NumberInputWithLimits } from '../SharedUi/Inputs';
import { AlertWithDescription } from '../SharedUi/Alerts';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { prettifyNumber } from '../../Util/prettifyNumber';

const AsignProductoOCCtoSalida = (props) => {
  const { iva } = props;
  const history = useHistory();
  //Internal State
  const [editMode, setEditMode] = useState(false);
  const [errorCantidad, setErrorCantidad] = useState(null);

  const [factura, setFactura] = useState('');
  const [lote, setLote] = useState('');

  const [remision, setRemision] = useState('');

  const [cantidad, setCantidad] = useState(null);

  const [limiteCantidadSuperior, setLimiteCantidadSuperior] = useState(null);
  const [limiteCantidadInferior, setLimiteCantidadInferior] = useState(null);

  const [productoPorProcesar, setProductoPorDarEntrada] = useState(null);
  const [sumaRegistros, setSumaRegistros] = useState(null);
  const [unidad, setUnidad] = useState(null);

  //Redux State

  //Create Relation
  const createRelation = useSelector((state) => state.createRelation);
  const loading = useSelector((state) => state.createRelation.loading);

  //Function Handlers

  const handleCancel = () => {
    props.handleCancel({
      stateTree: props.asignTree,
    });
    setEditMode(false);
  };

  const handlePostRelation = () => {
    let selectedValue;
    let cantidadUpdated = cantidad;
    if (cantidad >= Number(props.productToSearch.cantidad)) {
      cantidadUpdated = Number(props.productToSearch.cantidad);
    }
    selectedValue = {
      salida_id: props?.salidaId,
      cantidad: cantidadUpdated,
      pedidas: productoPorProcesar.cantidadOcc,
      unidad_id: productoPorProcesar.unidadId,
      producto_id: productoPorProcesar.productoId,
      nota_de_remision: props?.salidaId,
      inventario_id: productoPorProcesar.inventarioId,
      factura,
      numero_occ: productoPorProcesar.numeroOcc,
      producto_occ_id: productoPorProcesar.id,
      proveedor_id: props.proveedor?.id,
      usuario_id: '1',
      en_calidad_de_id: props.enCalidadDe,
      lote,
      sumaRegistros,
    };

    console.log('selectedValue');
    console.log(selectedValue);

    props.handlePostRelation({
      selectedValue,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
    });
    setEditMode(false);
  };
  const handleCerrar = (e) => {
    console.log('Handle Cerrare');
    props.handleCerrar({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };

  //<-----------------SELECT OPTIONS----------------->
  const dispatch = useDispatch();

  const handleCantidad = (e) => {
    setCantidad(e.target.value);
    setErrorCantidad(null);
    console.log('handleCanatidad----->>>>>>>' + e.target.value);
    console.log('limiteCantidadSuperior----->>>>>>>' + limiteCantidadSuperior);

    if (e.target.value < Number(limiteCantidadInferior)) {
      setErrorCantidad('No se permiten entradas con 0 Unidades');
    }
    if (e.target.value > Number(limiteCantidadSuperior)) {
      setErrorCantidad('Estas fuera del 10% permitido');
    }
  };

  const handleAddToEntrada = (producto) => {
    console.log('producto');
    console.log(producto);
    console.log(producto.cantidad);

    // IF OCC
    setLimiteCantidadInferior(Math.floor(1));

    //revisar

    //Si la cantidad pedida es  menor a la cantidad disponible
    if (producto.cantidadOcc < props.cantidadDisponible) {
      setCantidad(producto.cantidadOcc);
      setProductoPorDarEntrada(producto);
      setLimiteCantidadSuperior(props.cantidadDisponible);
      if (Number(producto.cantidadOcc) * 1.1 < props.cantidadDisponible) {
        setLimiteCantidadSuperior(Math.floor(Number(producto.cantidadOcc) * 1.1));
      }
      //Si es menor el restante que la cantidad
      if (Number(producto.cantidadOcc) - Number(producto.sumaSalidasByPOcc) <= producto.cantidad) {
        setCantidad(Number(producto.cantidadOcc) - Number(producto.sumaSalidasByPOcc));
        setLimiteCantidadSuperior(
          Math.floor(Number(producto.cantidadOcc * 1.1) - Number(producto.sumaSalidasByPOcc)),
        );
      }
      if (Number(producto.cantidadOcc) - Number(producto.sumaSalidasByPOcc) === 0) {
        setCantidad(0);
        setLimiteCantidadSuperior(
          Math.floor(
            (Number(producto.cantidadOcc * 1.1) - Number(producto.sumaSalidasByPOcc)) * 1.1,
          ),
        );
      }
    }

    //Si la cantidad pedida es mayour o igual a la cantidad disponible
    if (producto.cantidadOcc >= props.cantidadDisponible) {
      setCantidad(props.cantidadDisponible);
      setProductoPorDarEntrada(producto);
      setLimiteCantidadSuperior(props.cantidadDisponible);
    }
    setSumaRegistros(producto.sumaSalidasByPOcc);
    setEditMode(true);
  };

  const handleSetlimitAsCantidad = () => {
    setCantidad(limiteCantidadSuperior);
  };

  return (
    <React.Fragment>
      <div>
        <CardTitle>{props.cardTitle}</CardTitle>

        {loading && <h1>Loading</h1>}
        {!editMode && props.asignedListSelector && (
          <React.Fragment>
            {props.asignedListSelector?.length > 0 && (
              <div className="overflow-auto">
                <Table>
                  <TableHeader>
                    <TableRowHeader className="bg-gray-300 ">
                      <TableHeaderCell textColor="text-gray-700">PEDIDAS</TableHeaderCell>
                      <TableHeaderCell textColor="text-gray-700">ENTREGADAS</TableHeaderCell>
                      <TableHeaderCell textColor="text-gray-700">RESTANTES</TableHeaderCell>
                      <TableHeaderCell textColor="text-gray-700">Símbolo</TableHeaderCell>
                      <TableHeaderCell textColor="text-gray-700">OCC</TableHeaderCell>
                      <TableHeaderCell textColor="text-gray-700">P_OCC</TableHeaderCell>
                      <TableHeaderCell textColor="text-gray-700">AÑADIR</TableHeaderCell>
                      <TableHeaderCell textColor="text-gray-700">Cerrar</TableHeaderCell>
                    </TableRowHeader>
                  </TableHeader>
                  <TableBody>
                    {props.asignedListSelector?.map((producto, personIdx) => {
                      console.log('TESTTT');
                      console.log(producto);
                      return (
                        <React.Fragment>
                          <TableRow
                            key={producto.id}
                            className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                          >
                            <TableBodyCell textColor="text-gray-500">
                              {prettifyNumber(producto.cantidadOcc)}
                            </TableBodyCell>
                            <TableBodyCell textColor="text-gray-500">
                              {prettifyNumber(producto.sumaSalidasByPOcc)}
                            </TableBodyCell>
                            <TableBodyCell textColor="text-gray-500">
                              {prettifyNumber(
                                Number(producto.cantidadOcc) - Number(producto.sumaSalidasByPOcc),
                              )}
                            </TableBodyCell>

                            <TableBodyCell textColor="text-gray-500">
                              {producto.simbolo}
                            </TableBodyCell>
                            <TableBodyCell textColor="text-gray-500">
                              {producto.numeroOcc}
                            </TableBodyCell>
                            <TableBodyCell textColor="text-gray-500">{producto.id}</TableBodyCell>

                            <TableBodyCell textColor="text-gray-500">
                              <IconButton
                                onClick={() => handleAddToEntrada(producto)}
                                color="orange"
                                icon="+"
                                iconHeight={3}
                                iconWidth={3}
                              />
                            </TableBodyCell>
                            <TableBodyCell textColor="text-gray-500">
                              <IconButton
                                onClick={() => handleCerrar(producto)}
                                color="red"
                                icon="x"
                                iconHeight={3}
                                iconWidth={3}
                              />
                            </TableBodyCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
          </React.Fragment>
        )}
        {!loading && (
          <React.Fragment>
            {/* {JSON.stringify(productoPorProcesar)} */}
            {editMode && (
              <CardContainer>
                <div className="flex">
                  {!props.valueToAsignSelector && (
                    <div className="grid-cols-6 ">
                      <InputCornerHint
                        name="simboloProducto"
                        label={'Símbolo'}
                        value={productoPorProcesar?.simbolo?.toUpperCase()}
                        disabled
                        borderColor={'border-gray-400'}
                      />
                      <div className={'my-2'} />
                      <NumberInputWithLimits
                        number={cantidad}
                        onChange={handleCantidad}
                        title={'Cantidad'}
                        leftLimit={Math.floor(limiteCantidadInferior)}
                        rightLimit={prettifyNumber(Math.floor(limiteCantidadSuperior))}
                        setCantidad={handleSetlimitAsCantidad}
                        leftLabel="-10%"
                        rightLabel="+10%"
                        error={errorCantidad}
                        borderColor={'border-orange-300'}
                      />
                      <div className={'my-4'} />

                      <div className={'my-2'} />
                      <InputCornerHint
                        textColor="text-gray-400"
                        name="unidad"
                        label={'Unidad'}
                        value={productoPorProcesar?.unidad}
                        disabled
                      />
                      <div className={'my-2'} />
                      <InputCornerHint
                        name="numeroFactura"
                        label={'#Factura'}
                        value={factura}
                        onChange={(e) => setFactura(e.target.value)}
                        borderColor={'border-orange-300'}
                      >
                        {' '}
                        <button
                          className="text-xs"
                          onClick={() => {
                            setFactura('POR GENERAR');
                            setRemision('POR GENERAR');
                          }}
                        >
                          Por Generar
                        </button>
                      </InputCornerHint>
                      <div className={'my-2'} />
                      <InputCornerHint
                        name="lote"
                        label={'Lote'}
                        value={lote}
                        onChange={(e) => setLote(e.target.value)}
                        borderColor={'border-orange-300'}
                      />
                      <div className={'my-2'} />

                      {errorCantidad && (
                        <AlertWithDescription
                          title={errorCantidad}
                          icon={<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                        />
                      )}

                      {!errorCantidad && factura && lote && (
                        <>
                          {cantidad > 0 && (
                            <CardButton onClick={handlePostRelation} bgColor="orange">
                              Registrar Salida
                            </CardButton>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {props.valueToAsignSelector && (
                    <React.Fragment>
                      <RelationInsertValue
                        label={props.inputLabel}
                        value={props.valueToAsignSelector[props.nameOfPropertyToAsign]}
                        handlePostRelation={handlePostRelation}
                        handleCancel={handleCancel}
                      />
                    </React.Fragment>
                  )}
                </div>
              </CardContainer>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
export default AsignProductoOCCtoSalida;
