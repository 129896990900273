import { cppRoutes } from '../components/CuentasPorPagar/cppRoutes';
import { cpcRoutes } from '../components/CuentasPorCobrar/cpcRoutes';
import { verEntradasSalidasRoutes } from './inventarioRoutes';
import { pagosCuentasRoutes } from '../components/PagosCuentas/pagosCuentasRoutes';
import {
  estadosDeCuentaRoutes
} from '../components/EstadosDeCuenta/estadosDeCuentaRoutes';

export const cuentasRoutes = [
  ...cppRoutes,
  ...cpcRoutes,
  ...estadosDeCuentaRoutes,
  ...verEntradasSalidasRoutes,
  ...pagosCuentasRoutes

];
