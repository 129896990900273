import { useEffect, useState } from 'react';
import { CheckIcon, ArrowDownIcon } from '@heroicons/react/solid';

import { Combobox } from '@headlessui/react';

// const data = [
//   { id: 1, name: 'Leslie Alexander' },
//   // More users...
// ]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MyCombobox ({
  data,
  handleSelect,
  selectedValue,
  label,
  dataKey,
}) {
  const [query, setQuery] = useState('');
  // const [selectedValue, handleSelect] = useState(null)

  console.log('myComboBox--.>>');
  let filteredData =
    query === ''
      ? data
      : data.filter((item) => {
        console.log(query.toLowerCase());
        console.log('query--.>>');

        return item?.[dataKey]?.toLowerCase().includes(query.toLowerCase());
      });

  console.log('filteredData--->>');
  console.log(filteredData);
  console.log(filteredData?.length);
  console.log('filteredData--->>');
  return (
    <Combobox as='div' value={selectedValue} onChange={handleSelect}>
      <Combobox.Label
        className='block text-sm font-medium leading-6 text-gray-900'>{label}</Combobox.Label>
      <div className='relative mt-2'>
        <Combobox.Input
          className='relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(producto) => producto?.[dataKey]}
        />
        <Combobox.Button
          className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <ArrowDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </Combobox.Button>
        {/*// We ar rendering this item to force filteredData to be avaluated again}*/}
        <span className={"hidden"}>{filteredData?.length}</span>
        {filteredData?.length > 0 && (
          <Combobox.Options
            className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {filteredData.map((item, idx  ) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-orange-600 text-white' : 'text-gray-900',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate',
                      selected && 'font-semibold')}>{item?.[dataKey]}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-orange-600',
                        )}
                      >
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}