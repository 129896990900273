import React from 'react';
const DocsCard = (props) => {
  return (
    <div
      className="  border-solid border border-gray-100  grid grid-cols-6 gap-2 p-12   shadow drop-shadow-2xl rounded-lg bg-white"
      style={{
        width: '90%',
        maxWidth: '1200px',
        margin: 'auto',
        marginTop: '1rem',
        overflow: 'auto',
      }}
    >
      {props.children}
    </div>
  );
};

export default DocsCard;
