import React from 'react';
import PropTypes from 'prop-types';
import {
  PencilAltIcon,
  DocumentAddIcon,
  DocumentDownloadIcon,
  PaperAirplaneIcon,
  TrashIcon,
  MailIcon,
} from '@heroicons/react/solid';
import DropdownItemButton from './DropdownItemButton';

// Common icon classes
const iconClasses = 'mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500';

// Function to return the appropriate icon component
const getIconComponent = (iconKey) => {
  const icons = {
    mail: MailIcon,
    pencil: PencilAltIcon,
    documentAdd: DocumentDownloadIcon,
    paperIcon: PaperAirplaneIcon,
    trash: TrashIcon,
  };
  const IconComponent = icons[iconKey] || icons['pencil'];
  return <IconComponent className={iconClasses} aria-hidden='true' />;
};

const DropdownButtonWithIcon = ({ text, icon = 'pencil', onClick }) => (
  <DropdownItemButton onClick={onClick}>
    {getIconComponent(icon)}
    <span className=''>{text}</span>
  </DropdownItemButton>
);

DropdownButtonWithIcon.propTypes = {

  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(['pencil', 'documentAdd', 'paperIcon', 'trash']),
  onClick: PropTypes.func.isRequired,
};

DropdownButtonWithIcon.defaultProps = {
  icon: 'pencil',
  onClick: () => {},
};

export default DropdownButtonWithIcon;
