import { createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Docs, apiURL_Nest } from '../Util/apiURL';
import {
  getRequestFromClientPathThunkV2,
  getRequestFromClientPathThunkV3ToDeprecate,
} from '../Util/get-request-thunk';
import {
  getAllItemsRejected,
  getAllItemsFullfilledRest,
  defaultPendingEffect,
} from '../lib/default-get-all-items';


const apiHOST = apiURL_Nest();
const apiHOSTLegacy = apiURL()
const apiHOSTDocs=apiURL_Docs();

const initialState = {
  data: [],//the Raw data
  display: [],// the data to display after applying filters/sorts
  filterSearchValue: '',
  order: false,
  orderBy: '',
  sortAscending: false,
  error: false,
  loading: false,
  success: null,
  orderByOptions: [],
  displayAll:false
};
export const handleFilter = (args) => {
  return async (dispatch, getState) => {
    const { value, filterCondition } = args;
    dispatch(fetchAllActions.setFilterSearchValue(String(value).toUpperCase()));
    const updatedState = getState().fetchAll;
    let filtro = [];
    filtro = updatedState.data.filter(
      (state) => filterCondition(state, updatedState.filterSearchValue));

    let results = filtro;

    if (filtro?.length < 1 || value === '') {
      results = updatedState.data;
    }
    dispatch(fetchAllActions.displayFilterResults(results));

  };
};
export const getItems = getRequestFromClientPathThunkV2('fetchAll/getAll',
  `${apiHOST}`);

export const getDocs = getRequestFromClientPathThunkV2('fetchAll/getAll',
  `${apiHOSTDocs}`);

export const getItemsLegacy = getRequestFromClientPathThunkV3ToDeprecate('fetchAll/getAllLegacy',
  `${apiHOSTLegacy}`);

const fetchAllSlice = createSlice({
  name: 'fetchAll',
  initialState,
  reducers: {
    resetFilter (state, action) {
      state.filterSearchValue = null;
      state.display = state.data;
    },
    resetState (state, action) {
      state.data = [];
      state.display = [];
      state.filterSearchValue = '';
      state.order = false;
      state.orderBy = null;
      state.sortAscending = false;
      state.error = false;
      state.loading = false;
      state.success = null;
    },

    setFilterSearchValue (state, action) {
      state.filterSearchValue = action.payload;
    },

    shouldDisplayAll (state, action) {
      state.displayAll = action.payload;
    },

    displayFilterResults (state, action) {
      state.display = action.payload;

    },
    // orderBy (state, action) {
    //   const { sortFunction } = action.payload;
    //   state.display.sort(sortFunction);
    // },
    orderBy (state, action) {
      state.orderBy = action.payload;
      let payloadValue = action.payload;
      let sortFunction = (a, b) => {
        if (state.sortAscending) {
          if (a[payloadValue] > b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] < b[payloadValue]) {
            return -1;
          }
        }
        if (!state.sortAscending) {
          if (a[payloadValue] < b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] > b[payloadValue]) {
            return -1;
          }
        }
        return 0;
      };

      state.display.sort(sortFunction);
    },

    sortData (state, action) {
      state.sortAscending = !action.payload;
      const sortFunction = () => {
        if (state.sortAscending === action.payload) {
          return 1;
        }
        if (state.sortAscending !== action.payload) {
          return -1;
        }
      };
      state.display.sort(sortFunction);
    },

    setOrderByOptions (state, action) {
      state.orderByOptions = [];
      state.orderByOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItems.fulfilled, getAllItemsFullfilledRest);
    builder.addCase(getItems.pending, defaultPendingEffect);
    builder.addCase(getItems.rejected, getAllItemsRejected);
    builder.addCase(getItemsLegacy.fulfilled, getAllItemsFullfilledRest);
    builder.addCase(getItemsLegacy.pending, defaultPendingEffect);
    builder.addCase(getItemsLegacy.rejected, getAllItemsRejected);
  },
});
export default fetchAllSlice;
export const fetchAllActions = fetchAllSlice.actions;

