import React from 'react';
import './App.css';

import { useSelector } from 'react-redux';


import { AuthProvider } from './context/AuthProvider';
import AuthenticatedApp from './components/AuthenticatedApp/AuthenticatedApp';
import { useAppSelector } from './hooks/useAppSelector';


function App() {
  const reduxMessage = useSelector((state) => state.messages.message);
  const showModal = useAppSelector(state => state.modal.showModal);

  return (
    <div className="App">
      {showModal&& <div>showing modal</div>}
      <AuthProvider>
        {reduxMessage && <div className="bg-black text-white text-center p-4">{reduxMessage}</div>}
         <AuthenticatedApp/>
      </AuthProvider>
    </div>
  );
}

export default App;
