import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BooleanHelper } from '../BooleanHelper';
import { buildFetchUrlPath } from './utils/build-fetch-url-path';
import AuthContext from '../../store/auth-context';
import { EstadosDeCuentaUi } from './ui/EstadosDeCuentaUi';
import {
  getEdoCuentas,
  estadosDeCuentaActions as sliceActions,
} from '../EstadosDeCuenta/estadosDeCuenta-slice';

export const EstadosDeCuenta = ({
  fetchModel,
  linkTo,
}) => {
  const sliceState = useSelector((state) => state.estadosDeCuenta);
  const displayAll = useSelector((state) => state.estadosDeCuenta.displayAll);
  const dispatch = useDispatch();
  const routePath = buildFetchUrlPath(fetchModel,sliceState);
  const authCtx = useContext(AuthContext);
  const handleDisplayAll = () => {
    dispatch(sliceActions.shouldDisplayAll(!displayAll));
  };

  useEffect(() => {
    dispatch(getEdoCuentas({ authCtx, routePath }));
    dispatch(sliceActions.resetFilter());

  }, [displayAll]);
  const displayAllComponent = BooleanHelper({
    label: 'Mostrar Todos',
    value: sliceState.displayAll,
    onChange: handleDisplayAll,
  });
  const totales = {
    mxn: sliceState.totalesMx,
    usd: sliceState.totalesUsd,
  };
  const balances = {
    mxn: sliceState.displayMx,
    usd: sliceState.displayUsd,
  };

  return <EstadosDeCuentaUi
    sliceState={sliceState}
    linkTo={linkTo}
    displayAllComponent={displayAllComponent}
    totales={totales}
    balances={balances}
  />;
};

