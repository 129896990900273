import React, { useState } from 'react';
import IconButton from '../TailwindUI/IconButton';
import { prettifyNumber } from '../../Util/prettifyNumber';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { Link } from 'react-router-dom';
import numberToText from '../../Util/cantidadEnLetra';
import DeleteModal from '../UI/Modals/DeleteModal';
const ProductosCuentaPorPagar = (props) => {
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const { iva } = props;
  //Internal State
  const [editMode, setEditMode] = useState(false);
  const [cantidad, setCantidad] = useState(null);
  const [deleteProducto,setDeleteProducto]=useState({})
  //Redux State

  //Function Handlers
  const handleErrase = (e) => {
    console.log('handle ERASE !');
    console.log(e);
    props.handleErrase({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };
  const handleUpdate = () => {
    props.handleUpdate();
  };

  return (
    <React.Fragment>
      <div>
        {props.asignedListSelector?.length > 0 && (
          <div style={{ width: '100%', margin: 'auto' }}>
            <Table>
              <TableHeader>
                <TableRowHeader>
                  <TableHeaderCell>OCP</TableHeaderCell>
                  <TableHeaderCell>ENTRADA</TableHeaderCell>
                  <TableHeaderCell>REMISION</TableHeaderCell>
                  <TableHeaderCell>DESCRIPCIÓN</TableHeaderCell>
                  <TableHeaderCell>CANTIDAD</TableHeaderCell>
                  <TableHeaderCell>PU</TableHeaderCell>
                  <TableHeaderCell>MONTO</TableHeaderCell>
                </TableRowHeader>
              </TableHeader>
              <TableBody>
                {props.asignedListSelector?.map((producto, personIdx) => (
                  <React.Fragment>
                    <TableRow
                      key={producto.id}
                      className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
                    >
                      <TableBodyCell>
                        <Link
                          className="text-orange-600 text-bold"
                          to={`/ordenes-de-compra-proveedor/${producto.numeroOcp}`}
                        >
                          {producto.numeroOcp}
                        </Link>
                      </TableBodyCell>
                      <TableBodyCell>
                        <Link
                          className="text-orange-600 text-bold"
                          to={`/entradas/${producto.entradaId}`}
                        >
                          {producto.entradaId}
                        </Link>
                      </TableBodyCell>
                      <TableBodyCell>{producto.remisionProveedor}</TableBodyCell>
                      <TableBodyCell>{producto.simbolo}</TableBodyCell>
                      <TableBodyCell>{prettifyNumber(producto.cantidad)}</TableBodyCell>
                      <TableBodyCell>${producto.monto}</TableBodyCell>
                      <TableBodyCell>
                        $
                        {prettifyNumber(
                          (Number(producto.monto) * Number(producto.cantidad)).toFixed(2),
                        )}
                      </TableBodyCell>

                      <IconButton
                        onClick={() => {
                          setDeleteProducto(producto)
                          setDeleteModalIsVisible(true)}}
                        color="red"
                        icon="trash"
                        iconHeight={2.5}
                        iconWidth={2.5}
                      />
                    </TableRow>
                    {deleteModalIsVisible && (
                      <DeleteModal
                        title={'Eliminar producto de cuenta por pagar'}
                        message={JSON.stringify({
                          cantidad: deleteProducto?.cantidad,
                          simbolo: deleteProducto?.simbolo,
                        })}
                        onConfirm={() => {
                          console.log('click');
                          handleErrase(deleteProducto);
                          setDeleteModalIsVisible(false);
                        }}
                        onCancel={() => setDeleteModalIsVisible(false)}
                      />
                    )}
                  </React.Fragment>
                ))}

                <TableRow className="py-1">
                  <TableBodyCell className="py-2" colspan={5}></TableBodyCell>

                  <TableBodyCell className="pt-10 px-1 whitespace-nowrap text-right">
                    <span className="font-medium text-gray-600">IMPORTE:</span>
                  </TableBodyCell>
                  <TableBodyCell className="pt-10 px-1 whitespace-nowrap text-left">
                    ${prettifyNumber(props.subtotal ?? Number(props.subtotal)?.toFixed(2))}
                  </TableBodyCell>
                </TableRow>

                {iva && (
                  <TableRow className="py-1">
                    <TableBodyCell colspan={5} className="py-2"></TableBodyCell>

                    <TableBodyCell className="py-2 px-1 whitespace-nowrap text-right">
                      <span className="font-medium text-gray-600">IVA:</span>
                    </TableBodyCell>

                    <TableBodyCell className="py-2 px-1 whitespace-nowrap text-left">
                      $
                      {prettifyNumber(
                        props.subtotal ? Number(props.subtotal * 0.16)?.toFixed(2) : '',
                      )}
                    </TableBodyCell>
                  </TableRow>
                )}

                <TableRow className="py-1">
                  <td
                    style={{
                      whiteSpace: 'nowrap',
                      wordBreak: 'break-word',
                      textAlign: 'left',
                    }}
                    colspan={5}
                  >
                    <>{String(numberToText(props.total)).toUpperCase()}</>
                  </td>
                  <TableBodyCell className=" align-top py-2 px-1 whitespace-nowrap text-right">
                    <span className="font-medium text-gray-600">TOTAL:</span>
                  </TableBodyCell>
                  {iva && (
                    <TableBodyCell className="align-top py-2  px-1 whitespace-nowrap text-left">
                      ${prettifyNumber(Number(props.total).toFixed(2))}
                    </TableBodyCell>
                  )}

                  {!iva && (
                    <TableBodyCell className="align-top py-2  px-1 whitespace-nowrap text-left">
                      ${prettifyNumber(props.subtotal ? props.subtotal : 0)}
                    </TableBodyCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default ProductosCuentaPorPagar;
//test
