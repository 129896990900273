import { createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../..//Util/apiURL';
import * as util from '../../lib/edit';
import {
  asignReducer,
  cancelAsignReducer,
  productToRemoveAsignReducer,
} from '../../lib/shared-reducers';
import { putCliente } from '../../store/editarCliente-slice';

const apiHOST = apiURL();
const apiNest = apiURL_Nest();
const initialState = {
  proveedor: {
    id: null,
    razonSocial: null,
    rfc: null,
    direccionId: null,
    diasCredito: null,
    correoElectronico: '',
  },
  direccion: {
    id: null,
    numero: null,
    calle: null,
    ciudad: null,
    estado: null,
    cp: null,
    pais: null,
  },
  asignedProducts: [],
  productToAsign: null,
  productToRemoveAsign: null,
  loading: false,
  success: null,
};

export const getProveedor = util.getDefaultRequestThunk(
  'proveedor/getProveedor',
  `${apiNest}proveedores`,
);

export const putProveedor = util.patchRequestThunk('proveedor/putProveedor',
  apiNest);

export const deleteProveedor = util.deleteRequestThunk(
  'proveedor/deleteProveedor', apiNest, 'proveedores');

export const putJoinedTable = util.putRequestJoinedTableThunk(
  'proveedor/putForeignEntity',
  apiHOST,
);

export const getRelation = util.getRequestRelationThunk('proveedor/getRelation',
  apiHOST);

export const postRelationProducto = util.postRelationTableThunk(
  'proveedor/postRelationProducto',
  `${apiHOST}proveedores-producto`,
);

export const deleteRelationProducto = util.deleteRelationTableThunk(
  'proveedor/deleteRelationProducto',
  `${apiHOST}views/proveedores-producto/delete`,
);

const editProveedorSlice = createSlice({
  name: 'editProveedor',
  initialState: initialState,
  reducers: {
    change (state, action) {
      switch (action.payload.field) {
        case 'simbolo':
          break;
        case 'razonSocial':
          state.proveedor.razonSocial = action.payload.value;
          break;
        case 'correoElectronico':
          state.proveedor.correoElectronico = action.payload.value;
          break;
        case 'rfc':
          state.proveedor.rfc = action.payload.value;
          break;
        case 'diasCredito':
          state.proveedor.diasCredito = action.payload.value;
          break;
        case 'numero':
          state.direccion.numero = action.payload.value;
          break;
        case 'calle':
          state.direccion.calle = action.payload.value;
          break;
        case 'colonia':
          state.direccion.colonia = action.payload.value;
          break;
        case 'ciudad':
          state.direccion.ciudad = action.payload.value;
          break;
        case 'estado':
          state.direccion.estado = action.payload.value;
          break;
        case 'cp':
          state.direccion.cp = action.payload.value;
          break;
        case 'pais':
          state.direccion.pais = action.payload.value;
          break;
        default:
      }
    },
    asignReducer,
    cancelAsignReducer,
    productToRemoveAsignReducer,
    resetSucces(state) {
      state.success = null;
    },
    resetError(state) {
      state.error = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getProveedor.fulfilled, (state, action) => {
      const {
        id,
        correoElectronico,
        razonSocial,
        rfc,
        diasCredito,
        direccion,
      } = action.payload;
      console.log('proveedor  rwsponse!!!!!!!!!!!!!!');
      state.proveedor = {
        id,
        correoElectronico,
        razonSocial,
        rfc,
        diasCredito,
      };
      state.direccion = direccion;
    });
    builder.addCase(deleteProveedor.fulfilled, (state, action) => {
      state.proveedor = {
        id: null,
        razonSocial: null,
        rfc: null,
        direccionId: null,
        diasCredito: null,
      };

      state.direccion = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
    });
    builder.addCase(putProveedor.fulfilled, (state, action) => {
      console.log('Console.log Action sss');
      console.log(action);
      state.loading = false
      state.error= false
      state.success = true;
      state[action.payload.stateTree] = action.payload.data;
    });

    builder.addCase(putProveedor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(putProveedor.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(putJoinedTable.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });
    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(postRelationProducto.fulfilled, (state, action) => {
      // console.log('DDDDDDDD?????????   extra reducer -------- ?');
      // console.log(action.payload);
      // console.log('state tree');
      // console.log(action.payload.stateTree);
      // console.log('data');
      // console.log(action.payload.data);
      // console.log('action.payload.relationList');
      // console.log(action.payload.relationList);

      state[action.payload.asignedListString]?.push(
        action.payload.selectedValue);

      //Set State to Nullstate the product that has been posted.
      state[action.payload.reduxStateTree] = null;
    });
    builder.addCase(deleteRelationProducto.fulfilled, (state, action) => {
      console.log('DDDDDDDD?????????   extra reducer -------- ?');
      console.log(action.payload);
      console.log('state tree');
      console.log(action.payload.stateTree);
      console.log('data');
      console.log(action.payload.data);
      console.log('action.payload.asignedListSelector');
      console.log(action.payload.asignedListString);
      console.log(action.payload.actualRelations);
      console.log('action.payload.simbolo');
      console.log(action.payload.simbolo);
      console.log('FILRERETE');

      const filterdValue = action.payload.asignedListSelector.filter(
        item => item.simbolo !== action.payload.simbolo,
      );

      console.log(filterdValue);
      console.log('FILRERETE');
      state[action.payload.asignedListString] = filterdValue;

      // state[action.payload.stateTree] = null;
    });
  },
});

export default editProveedorSlice;

export const editProveedorActions = editProveedorSlice.actions;
