import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getValuesToAsign } from '../../store/createRelation-slice';
import IconButton from '../TailwindUI/IconButton';
import RelationInsertValue from '../SharedUi/RelationInsertValue';
import { Label, InputCornerHint, SelectCornerHint } from '../SharedUi/Inputs';
import { CardTitle } from '../SharedUi/UpdateCardUI';
import Spinner from '../Spinner/Spinner';
import AuthContext from '../../store/auth-context';
import { GenericModal } from '../UI/Generics';

import ComboBoxHelper from '../TailwindUI/ComboBoxHelper';
import MyCombobox from '../UI/Generics/Combobox/Combobox';

const AsignProductoToOC = (props) => {
  const authCtx = useContext(AuthContext);

  //Internal State
  const [editMode, setEditMode] = useState(false);
  const [cantidad, setCantidad] = useState(null);
  const [unidad, setUnidad] = useState(null);
  //Redux State

  //Create Relation
  const createRelation = useSelector((state) => state.createRelation);
  const loading = useSelector((state) => state.createRelation.loading);

  //Function Handlers

  const handleCombobox = (e) => {
    props.handleSelect({
      reduxStateTree: props.asignTree,
      valuesToFilter: createRelation.valuesToAsign,
      selectedValue: e,
    });
  };

  const handleCancel = () => {
    props.handleCancel({
      stateTree: props.asignTree,
    });
    setEditMode(false);
    setCantidad(null);
    setUnidad(null);
  };

  const handlePostRelation = () => {
    props.handlePostRelation({
      selectedValue: props.valueToAsignSelector,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      cantidad,
      unidad,
    });
    setEditMode(false);
    setCantidad(null);
    setUnidad(null);
  };

  //<-----------------SELECT OPTIONS----------------->
  const dispatch = useDispatch();
  const handleGetSelectValues = () => {
    let url = `${props.fetchUrl}/?${props.filterWhere}=${props.filterWhereId}&divisaId=${props.divisaId}`;
    console.log(`!!!!!!!!REVISAR URL!!!!!!`);
    console.log(
      `${props.fetchUrl}/?${props.filterWhere}=${props.filterWhereId}&divisaId=${props.divisaId}`,
    );
    dispatch(getValuesToAsign({ url, authCtx }));
    setEditMode(true);
  };

  let selectValues = createRelation.valuesToAsign;

  const selectOptions = selectValues?.map((item) => {
    let nameOfPropertyToAsign;
    props.nameOfPropertyToAsign
      ? (nameOfPropertyToAsign = props.nameOfPropertyToAsign)
      : (nameOfPropertyToAsign = 'id');

    return (
      <option key={item.id} value={item.id}>
        {item[nameOfPropertyToAsign]}
      </option>
    );
  });

  const handleCantidad = (e) => {
    if (e.target?.value > 0) setCantidad(e.target.value);
  };
  const handleUnidades = (value, selectValues) => {
    const found = selectValues.find((i) => i.id === Number(value));
    setUnidad(found);
  };
  //TODO REVISAR flow para agregar productos, esta dando problemas cuando sale el botton rojo
  return (
    <React.Fragment>
      {props.divisaId && props.filterWhereId && (
        <div>
          <CardTitle>{props.cardTitle}</CardTitle>
          {!editMode && (
            <div className='my-4'>
              <IconButton onClick={handleGetSelectValues} color='orangeMely'
                          icon='+' />
            </div>
          )}
          {loading && <Spinner />}
          {!loading && (
            <React.Fragment>
              {editMode && (
                <GenericModal
                  overlayColor={'bg-orange-400'}
                  title={'Generic Modal'}
                  meesage={'En la siguiente lista puedes encontrar los productos que exísten dentro de una orden de compra de este proveedor'}
                  onConfirm={(sliceState) => {

                  }}
                  onCancel={() => {
                    setEditMode(false);

                  }}
                >
                  <div style={{ 'width': '90vw', 'height': '80vh' }}
                       className={'my-5  py-6 overflow-auto w-full'}>
                    <InputCornerHint
                      type='number'
                      value={cantidad}
                      onChange={handleCantidad}
                      name={'cantidad'}
                      label={'Cantidad'}
                    ></InputCornerHint>

                    <div className='mb-6' />
                    {cantidad && (
                      <ComboBoxHelper
                        transformer={(data) => data}
                        routePath={'unidades'}
                        dataKey={'nombre'}
                        modelName={'unidades'}
                        onChange={(e) => {
                          const [selectedValue, selectValues] = e;
                          handleUnidades(selectedValue?.id, selectValues);
                        }}
                        label={'unidades'}
                        selectedValue={unidad}
                      />

                    )}
                    <div className='mb-2' />
                    <div className='mb-2' />
                    {!props.valueToAsignSelector && (
                      <>
                        {cantidad && unidad && (
                          <MyCombobox
                            data={selectValues}
                            handleSelect={(e) => handleCombobox(e?.id)}
                            label={'Producto'}
                            dataKey={'simbolo'}
                          />
                        )}
                      </>
                    )}

                    <div className='mb-12' />
                    <div className='mb-12' />

                    {props.valueToAsignSelector && (
                      <React.Fragment>
                        <RelationInsertValue
                          label={props.inputLabel}
                          value={props.valueToAsignSelector[props.nameOfPropertyToAsign]}
                          handlePostRelation={handlePostRelation}
                          handleCancel={handleCancel}
                        />
                      </React.Fragment>
                    )}

                  </div>
                </GenericModal>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default AsignProductoToOC;
