import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { postItemThunk } from '../lib/default-post-item';
import { asignReducer } from '../lib/shared-reducers';

const apiHOST = apiURL();
const initialState = {
  cliente: null,
  usuario: null,
  error: false,
  loading: false,
};

export const insertSalidaIC = postItemThunk(
  'crearSalida/insertSalida',
  `${apiHOST}salidas-inventario-clientes/`,
  'salidas-inventario-clientes',
);

const createSalidaICSlice = createSlice({
  name: 'createSalidaIC',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'cliente':
          state.cliente = action.payload.value;
          break;

        case 'enCalidadDe':
          state.enCalidadDe = action.payload.value;
          break;
        case 'seEmbarca':
          state.seEmbarca = action.payload.value;
          break;

        default:
      }
    },
    asignReducer,
  },
  extraReducers: builder => {
    builder.addCase(insertSalidaIC.rejected, (state, action) => {
      console.log('action --------------> insertSalida Rejected');
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(insertSalidaIC.fulfilled, (state, action) => {
      console.log('action --------------> insertSalida Rejected');
      state.enCalidadDe = '';
      state.cliente = '';
      state.error = '';
      state.loading = false;
    });
    builder.addCase(insertSalidaIC.pending, (state, action) => {
      console.log('action --------------> insertSalida pending');
      state.loading = true;
    });
  },
});

export default createSalidaICSlice;

export const createSalidaICActions = createSalidaICSlice.actions;
