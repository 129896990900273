import React from 'react';

export const Form=({children, handleSubmit})=>{
 const handleOnSubmit=(event)=>{
  event.preventDefault()
   handleSubmit()
  }
  return (
    <React.Fragment>



      <form onSubmit={handleOnSubmit}>
        {children}
      </form>

    </React.Fragment>
  );
}