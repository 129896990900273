/*
El componente 
Recibe el objeto a modificar
Utiliza el hook Use effect,  const [obj,newObj]=useState({})
Utilizamos  el hook useEffect para que se actualize el estado en base al objeto recibido
Utilizamos let entries= Object.entries(obj)  en el objeto para recibir  sus entradas
Creamos una <form></form>, La Forma tiene un State el cual se modifa cuando un input value cambia (su estado y es aceptado)... Cuando el cliente a terminado de editar Puede Apretar el Boton Guardar Cambios  y esto genera un http request .

Utilizamos  entries.map((item)=><Componente defaultValue={item[1]} label={item[0]} handleEdit={} />) para crear un Componente modificable 
 el componente es  recibe el Valor del Key para su Label y su valor predeterminado del input, tiene tres estados Sin Editar, Aceptar cambios, Cancelar Cambios
 Si los cabios no han sido aceptados  el valor del input field lo recibe de el valor defaultvalue (Que viene del objeto)
Si los cambios fueron aceptados se guarda el valor del estado Interno actual de la forma y se envía un mensaje a la función update state de Forma Entera  -*/

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editProductoActions } from './editarProducto-slice';
import FlexContainer from '../TailwindUI/FlexContainer';
import IconButton from '../TailwindUI/IconButton';

const ModelInputsAndSelectors = props => {
  //Internal State
  const [editMode, setEditMode] = useState(false);
  const [backupValue, setBackupValue] = useState(false);
  //Hooks
  const dispatch = useDispatch();
  //Props
  const { selectorName } = props;
  //Redux Sekectors
  const simbolo = useSelector(state => state.editProducto.producto?.simbolo);
  const e_ect = useSelector(state => state.editProducto.especificacionesProducto?.ect);
  const e_flauta = useSelector(state => state.editProducto.especificacionesProducto?.flauta);
  const e_papeles = useSelector(state => state.editProducto.especificacionesProducto?.papeles);
  const e_tratamientos = useSelector(
    state => state.editProducto.especificacionesProducto?.tratamientos,
  );
  const e_tintas = useSelector(state => state.editProducto.especificacionesProducto?.tintas);
  const dl_largo = useSelector(state => state.editProducto.dimensionesLamina?.largo);
  const dl_ancho = useSelector(state => state.editProducto.dimensionesLamina?.ancho);
  const mic_largo = useSelector(state => state.editProducto.medidasInternasCaja?.largo);
  const mic_ancho = useSelector(state => state.editProducto.medidasInternasCaja?.ancho);
  const mic_alto = useSelector(state => state.editProducto.medidasInternasCaja?.alto);
  const costoAdquisicionActual = useSelector(
    state => state.editProducto?.costoAdquisicion?.monto,
  );
  const precioVentaActual = useSelector(
    state => state.editProducto?.precioVenta?.monto,
  );

  //Function Handlers
  const handleChange = e => {
    setEditMode(true);
    dispatch(editProductoActions.change({ field: selectorName, value: e.target.value }));
  };
  const handleEditar = () => {
    setBackupValue(newValue);
    setEditMode(true);
  };
  const handleGuardar = () => {
    setEditMode(false);
  };
  const handleCancelar = () => {
    dispatch(editProductoActions.change({ field: selectorName, value: backupValue }));
    setEditMode(false);
  };

  let newValue = '';
  let type = 'text';

  switch (selectorName) {
    case 'simbolo':
      newValue = simbolo;

      break;
    case 'e_ect':
      newValue = e_ect;
      break;
    case 'e_flauta':
      newValue = e_flauta;
      break;
    case 'e_papeles':
      newValue = e_papeles;
      break;
    case 'e_tratamientos':
      newValue = e_tratamientos;
      break;
    case 'e_tintas':
      newValue = e_tintas;
      break;
    case 'dl_largo':
      newValue = dl_largo;
      type = 'number';
      break;
    case 'dl_ancho':
      newValue = dl_ancho;
      type = 'number';
      break;
    case 'mic_largo':
      newValue = mic_largo;
      type = 'number';
      break;
    case 'mic_ancho':
      newValue = mic_ancho;
      type = 'number';
      break;
    case 'mic_alto':
      newValue = mic_alto;
      type = 'number';
      break;
    case 'costoAdquisicionActual':
      newValue = costoAdquisicionActual;
      type = 'number';
      break;
    case 'precioVentaActual':
      newValue = precioVentaActual;
      type = 'number';
      break;

    default:
  }

  return (
    <FlexContainer className={'flex-col max-w-fit m-auto  '}>
      {props.label !== 'id' && !props.label.includes('Id') && (
        <React.Fragment>
          <label className="block text-sm font-medium text-gray-400 self-start mt-5">
            {props.label ? props.label : 'Sin Etiqueta'}
          </label>
          <div className="mt-1 flex rounded shadow-sm py-1 ">
            {props.label !== 'id' && (
              <input
                type={type}
                onChange={handleChange}
                onBlur={() => setEditMode(false)}
                onFocus={handleEditar}
                value={newValue}
                readOnly={!editMode}
                className="  text-sm focus:ring-orange-500 focus:border-orange-500  block   border-white rounded-md  "
              />
            )}

            <div className="inline-flex items-center ">
              {!editMode && (
                <React.Fragment>
                  <div style={{ height: '3px', width: '30px' }}></div>
                  <IconButton
                    onClick={handleEditar}
                    color="darkBlue"
                    icon="edit"
                    iconHeight={3}
                    iconWidth={3}
                  />
                </React.Fragment>
              )}

              {/* {editMode && (
                <IconButton
                  onClick={handleGuardar}
                  color="green"
                  icon="check"
                  iconHeight={3}
                  iconWidth={3}
                />
              )}
              {editMode && (
                <IconButton
                  onClick={handleCancelar}
                  color="red"
                  icon="x"
                  iconHeight={3}
                  iconWidth={3}
                />
              )} */}
            </div>
          </div>
        </React.Fragment>
      )}
    </FlexContainer>
  );
};
export default ModelInputsAndSelectors;
