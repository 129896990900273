import { Factory } from 'fishery';
// import { PartialDocument } from '../types/document.type';
//
type DocumentTags = {
  entidadComercial: string;
  documentType: string;
  entidadComercialId: string;
};

export const documentUrlQueryFactory = Factory.define<
  { url: string },
  DocumentTags
>(({ transientParams }) => {
  const base = documentTagsFactory.build();
  return {
    url: `documents?limit=2&tags=entidadComercial:${transientParams.entidadComercial || base.entidadComercial},entidadComercialId:${transientParams.entidadComercialId || base.entidadComercialId},documentType:${transientParams.documentType || base.documentType}`,
  };
});
export const documentTagsFactory = Factory.define<DocumentTags>(() => ({
  entidadComercial: '',
  documentType: '',
  entidadComercialId: '',
}));

// export const documentFactory = Factory.define<PartialDocument>(
//   ({ sequence }) => ({
//     // id: `2d5c9072-6249-4443-9d54-ccca72dfeee${sequence}`,
//     tags: {},
//     name: `document_name`,
//     md5: `document_md5`,
//     downloads: 1,
//   }),
// );
// export const documentFactorySeq = Factory.define<PartialDocument>(
//   ({ sequence }) => ({
//     // id: `2d5c9072-6249-4443-9d54-ccca72dfeee${sequence}`,
//     tags: {},
//     name: `document_name_${sequence}`,
//     md5: `document_md5_${sequence}`,
//     downloads: 1,
//   }),
// );
//
// let document = documentFactory.build({
//   tags: documentTagsFactory.build({
//     entidadComercial: 'my new entidad comerial',
//   }),
// });
// console.log('document->', document);
//
// console.log(
//   'urlFactory',
//   documentUrlQueryFactory.build(
//     {},
//     {
//       transient: {
//         entidadComercialId: '3333',
//       },
//     },
//   ),
// );
