import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { formatDateFromDateInput, formatDateFromtoLocaleDateString } from '../Util/get-date';

const filterById = (values, selectedValueId) => {
  const filterdValue = values.filter((item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const date = new Date();

const apiHOST = apiURL();
const initialState = {
  cpp: {},
  monto: null,
  fecha: date.toString(),
  fechaDisplay: formatDateFromtoLocaleDateString(String(date.toLocaleDateString())),
  divisa: {},
  tipoDeCambio: 1,
  observaciones: null,
  closeModal: false,
};

export const insertPagoCliente = createAsyncThunk('producto/insertPagoCliente', async (payload) => {
  const { request, history } = payload;

  try {
    console.log('Reques INSERT PAGO PROVEEDORt---->');
    console.log(request);
    const updatedRequest = { ...request };
    updatedRequest.fecha = new Date(request.fecha);
    console.log(updatedRequest);

    const response = await axios.post(`${apiHOST}pagos-clientes`, updatedRequest);

    return;
  } catch (err) {
    // return err;
  }
});

const createPagoClienteSlice = createSlice({
  name: 'createPagoCliente',
  initialState: initialState,
  reducers: {
    change(state, action) {
      console.log('Change !!!!!!!!!!!');
      switch (action.payload.field) {
        case 'monto':
          console.log('monto');
          state.monto = Number(action.payload.value);

          break;
        case 'observaciones':
          state.observaciones = action.payload.value;

          break;
        case 'fecha':
          state.fecha = new Date(action.payload.value);
          state.fechaDisplay = formatDateFromDateInput(action.payload.value);
          break;

        case 'tipoDeCambio':
          if (action.payload.value > 0) {
            state.tipoDeCambio = Number(action.payload.value);
            state.monto = '';
          }
          break;
        default:
      }
    },
    asign(state, action) {
      console.log('Asign Dispatched');
      console.log(action.payload);
      state[action.payload.reduxStateTree] = filterById(
        action.payload.valuesToFilter,
        action.payload.selectedValue,
      );
    },
    setCpc(state, action) {
      console.log(' setCpp     action.payload');
      console.log(action.payload);
      state.cpp = action.payload;
    },

    resetMonto(state) {
      state.monto = '';
    },
    resetCloseModal(state) {
      state.closeModal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(insertPagoCliente.fulfilled, (state, action) => {
      state.monto = '';
      state.folio = '';
      state.closeModal = true;
    });
  },
});

export default createPagoClienteSlice;

export const createPagoClienteActions = createPagoClienteSlice.actions;
