import { InputCornerHint } from '../SharedUi/Inputs';
import React from 'react';

const Row = ({ className,children }) => {
  const twClass = `grid grid-cols-1  gap-2 md:grid-cols-2 ${!className
    ? ''
    : className}`
 return (
    <div className={twClass}>
      {children}
    </div>);
};
export const ProductosEntradaRechazos = ({ data }) => {
  const
    {
      cantidad,
      lote,
      producto,
      descripcion,
      responsable,
      motivo,
      observaciones,
    } = data;
  return (<div>

    <Row className='mt-2'>
      <Row>
        <InputCornerHint
          label='Cantidad'
          readOnly
          value={cantidad}
        />
        <InputCornerHint label='Lote' readOnly
                         value={lote} />
      </Row>

      <InputCornerHint
        label='Producto'
        readOnly
        value={producto?.simbolo}
      />
    </Row>

    <Row className='mt-4'>
      <InputCornerHint
        label='Descripcion'
        readOnly
        value={descripcion}
      />
      <InputCornerHint
        label='Responsable'
        readOnly
        value={responsable}
      />
    </Row>

    <Row className='mt-2'>
      <InputCornerHint
        label='Motivo'
        readOnly
        value={motivo}
      />
      <InputCornerHint
        label='Observaciones'
        readOnly
        value={observaciones}
      />
    </Row>
  </div>);

};