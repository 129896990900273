import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getEdoCuentaProveedor,
  edoCuentaProveedorActions as sliceActions
} from '../../store/edoCuentaProveedor-slice';
import AuthContext from '../../store/auth-context';
import { EstadoDeCuenta } from '../EstadoDeCuenta/EstadoDeCuenta';
import { getDivisa } from '../EstadoDeCuenta/utils/get-divisa';

const EdoCuentaProveedor = () => {
  const [divisa,setDivisa]=useState('')
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const sliceState = useSelector((state) => state.edoCuentaProveedor);
  const displayAll = useSelector(
    (state) => state.edoCuentaProveedor.displayAll);

  const location = useLocation();
    const urlParam = location.pathname.split('/')[3];


  useEffect(() => {
    setDivisa(getDivisa(location))
    const buildRoutePath = () => `${urlParam}/estados-de-cuenta/${getDivisa(location)}?showAll=${sliceState.displayAll}`;
    console.log('buildRoutePath()-.--->>')
    console.log(buildRoutePath())
    dispatch(getEdoCuentaProveedor(
      { urlParam, authCtx, routePath: `/${buildRoutePath()}` }));

  }, [displayAll]);

  return  <EstadoDeCuenta divisa={divisa} sliceState={sliceState} displayAll={displayAll} sliceActions={sliceActions} entidadComercial={"proveedor"}/>
};
export default EdoCuentaProveedor;
