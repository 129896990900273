import React from 'react';
import SelectHelper from '../TailwindUI/SelectHelper';
import { InputCornerHint } from './Inputs';
import { useDispatch } from 'react-redux';
export const RenderInputUI = ({ list, sliceActions }) => {
  const dispatch = useDispatch();
  return list.map((item) => {
    return !item.foreignKey ? (
      <InputCornerHint
        label={item.label ? item.label : item.property}
        type={item.type}
        value={item.selector}
        onChange={(e) => {
          dispatch(
            sliceActions.change({
              field: item.selectorName,
              value: e.target.value,
            }),
          );
        }}
      />
    ) : (
      <SelectHelper
        //UI
        label={item.property}
        //URL
        fetchUrl={item.url}
        //Redux
        asignTree={item.selectorName}
        removeTree={item.selectorName}
        valueToAsignSelector={item.selector}
        //Select Options
        nameOfPropertyToAsign={item.nameOfPropertyToAsign}
        //Function Handlers
        handleSelect={(e) => {
          dispatch(sliceActions.asignReducer({ ...e }));
        }}
      />
    );
  });
};
