import React from 'react';
import {
  getDocs,
  fetchAllActions as sliceActions, handleFilter,
} from '../../store/fetch-all-slice';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const filterCondition = (state, value) => {
  let condition = (
    String(state?.notificationName).toUpperCase().includes(value)
  );
  return condition;
};

const Notificaciones = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    getItems: getDocs,
    handleFilter,
    routePath: 'notificaciones',
    filterCondition,

    orderByOptions: [
      // '',
      // 'razonSocial',
      // 'rfc',
    ],
  });
};

export default Notificaciones;
