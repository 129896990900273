import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../../Util/authorizationHeader';

export const putRequestThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestObj, requestUrl, reduxStateTree, authCtx } = payload;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    try {
      const response = await axios.put(`${route}${requestUrl}/${reduxStateTree.id}`, requestObj, {
        headers: {
          ...authorizationHeader()        },
      });
      return { data: response.data, reduxStateTree };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });



export const patchRequestThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestObj, requestUrl, reduxStateTree, authCtx } = payload;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    const url=`${route}${requestUrl}/${reduxStateTree.id}`
    console.log('url=>',url)
    console.log('request Object=>',requestObj)
    try {
      const response = await axios.patch(url, requestObj, {
        headers: {
          ...authorizationHeader()        },
      });

      return { data: response.data, reduxStateTree };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });

export const putRequestThunkPrimaryKey = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { primarykey, authCtx } = payload;

    try {
      const response = await axios.put(
        `${route}/${primarykey}`,

        {},
        {
          headers: {
            ...authorizationHeader()          },
        },
      );
      console.log('response----->>>>>>>');
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });
