import { createObj } from './create-obj';
import { getKey } from './get-key';
import { getValue } from './get-value';

export const reduceGroup = (arr) => {
  if (Array.isArray(arr)) {
    const reduceValues = arr.reduce((prev, curr) => {
      const previousKeyFound = prev.find((i) => getKey(i) === getKey(curr));
      if (!previousKeyFound) {
        prev.push(curr);

        return prev;
      } else if (previousKeyFound) {
        let previousValue = getValue(previousKeyFound);
        let currentValue = getValue(curr);

        const group = [];
        if (Array.isArray(previousValue)) {
          previousValue.push(currentValue);

          group.push(...previousValue);
        } else {
          group.push(currentValue);
        }

        group.flat();

        const newGroup = createObj(getKey(previousKeyFound), group);

        return prev.map((i) => {
          if (getKey(i) === getKey(previousKeyFound)) {
            return newGroup;
          } else return i;
        });
      }
    }, []);

    return reduceValues;
  }
};
