import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../../../../Util/apiURL';
import * as util from '../../../../lib/edit';

const apiHOST = apiURL();
const initialState = {
  entity: {},
  editFields: [],
  readOnlyFields: [],
  hiddenFields:[],
  request: {},
  succes: false,
  loading: false,
  error: false,
};

export const putEntitiy = util.putRequestThunk('editEntity/putEntity', apiHOST);

const editEntitySlice = createSlice({
  name: 'editEntity',
  initialState: initialState,
  reducers: {
    change(state, action) {
      // console.log('change----->>>>>>>');
      // console.log(action?.payload);
      // console.log('change----->>>>>>>');
      state.entity[action?.payload?.key] = action.payload.value;
    },
    setState(state, action) {
      // console.log('setState----->>>>>>>');
      // console.log(action?.payload);

      state.entity = action?.payload?.entity;
      state.editFields = action?.payload?.editFields;
      state.readOnlyFields = action?.payload?.readOnlyFields;
      state.hiddenFields = action?.payload?.hiddenFields;
    },
    reset(state) {
      state.entity = {};
      state.succes = false;
      state.loading = false;
      state.error = false;
    },
    boolean(state, action) {
      const { key, value } = action.payload;
      // console.log('key----->>>>>>>');
      // console.log(key);
      // console.log('value----->>>>>>>');
      // console.log(value);

      // console.log('state.entity[key]----->>>>>>>');
      // console.log(state.entity[key]);

      state.entity[key] = !value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(putEntitiy.fulfilled, (state, action) => {
      console.log('Console.log Action sss');
      console.log(action);
      state[action.payload.reduxStateTree] = action.payload.data;
      state.loading = false;
      state.error = false;
      state.succes = true;
    });
    builder.addCase(putEntitiy.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
  },
});

export default editEntitySlice;

export const editEntityActions = editEntitySlice.actions;
