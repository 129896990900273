import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getValuesToAsign,
  getValuesToAsignNest,
} from '../../store/createRelation-slice';
import IconButton from './IconButton';
import { CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';

import RelationInsertValue from '../SharedUi/RelationInsertValue';
import AuthContext from '../../store/auth-context';
import Combobox from '../UI/Generics/Combobox/Combobox';

const AsignProductoToCliente = (props) => {
  const authCtx = useContext(AuthContext);

  //Internal State
  const [editMode, setEditMode] = useState(false);

  //Redux State

  //Create Relation
  const createRelation = useSelector((state) => state.createRelation);
  const loading = useSelector((state) => state.createRelation.loading);

  //Function Handlers

  const handleSelect = (e) => {
    props.handleSelect({
      reduxStateTree: props.asignTree,
      valuesToFilter: createRelation.valuesToAsign,
      selectedValue: e.target.value,
    });
  };

  const handleSelectCombobox = (e) => {
    console.log(e)
    props.handleSelect({
      reduxStateTree: props.asignTree,
      valuesToFilter: createRelation.valuesToAsign,
      selectedValue: e.id,
    });
  };


  const handleCancel = () => {
    props.handleCancel({
      stateTree: props.asignTree,
    });
    setEditMode(false);
  };

  const handlePostRelation = () => {
    props.handlePostRelation({
      selectedValue: props.valueToAsignSelector,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
    });
    setEditMode(false);
  };
  const handleErrase = (e) => {
    props.handleErrase({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };

  //<-----------------SELECT OPTIONS----------------->
  const dispatch = useDispatch();
  const handleGetSelectValues = () => {
    if(props.api==='nest'){
      dispatch(getValuesToAsignNest({ url: props.fetchUrl ? props.fetchUrl : '', authCtx }));
    }
    else {
      dispatch(getValuesToAsign({ url: props.fetchUrl ? props.fetchUrl : '', authCtx}));

    }
    setEditMode(true);
  };

  let selectValues = createRelation.valuesToAsign;
  // if (props.asignedListSelector.length === 0) {
  //   selectValues = createRelation.valuesToAsign;
  // }
  // if (props.asignedListSelector.length > 0) {
  //   // console.log('props.asignedListSelector.length +++++++1');
  //   props.asignedListSelector.map((item) => {
  //     // console.log('actual relations map--------------------------');
  //     // console.log(item);
  //     createRelation.valuesToAsign.map((value) => {
  //       // console.log('--------------------------select valuues  mapp');
  //       // console.log(value);
  //       // console.log(
  //       //   `If ${item.simbolo} !== ${item.simbolo}  &&  item id=${value.id}`
  //       // );
  //       if (item.simbolo !== value.simbolo) {
  //         selectValues.push(value);
  //         return '';
  //       } else return null;
  //     });
  //   });
  //   if (
  //     props.asignedListSelector.length ===
  //     createRelation.valuesToAsign.length - 1
  //   ) {
  //     // console.log('*******selectValues RESET+++++');
  //     selectValues = [{}];
  //   }
  //   // console.log('selectValues 2+++++');
  //   // console.log(selectValues);
  // }

  const selectOptions = selectValues?.map((item) => {
    console.log(item)
    console.log("select options item---->")
    let nameOfPropertyToAsign;
    props.nameOfPropertyToAsign
      ? (nameOfPropertyToAsign = props.nameOfPropertyToAsign)
      : (nameOfPropertyToAsign = 'id');

    return (
      <option key={item.id} value={item.id}>
        {item[nameOfPropertyToAsign]}
      </option>
    );
  });

  // console.log('Values to asign');
  // console.log(selectValues);

  return (
    <CardContainer color={'orange-200'}>
      <CardTitle>{props.cardTitle}</CardTitle>
      {loading && <h1>Loading</h1>}
      {!loading && (
        <React.Fragment>
          {!editMode && (
            <div className="mt-4">
              <IconButton onClick={handleGetSelectValues} color="orangeMely" icon="+" />
            </div>
          )}
          {editMode && (
            <CardContainer>
              <div className="flex">
                {!props.valueToAsignSelector && (
                  <>
                    <div>
                      <Combobox data={selectValues} handleSelect={handleSelectCombobox} selectedValue={props.valueToAsignSelector} dataKey={"simbolo"}/>
                    </div>
                    {/*<select*/}
                    {/*  id={props.asignedListString}*/}
                    {/*  name={props.asignedListString}*/}
                    {/*  className="mt-1 w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"*/}
                    {/*  onBlur={handleSelect}*/}
                    {/*  onChange={handleSelect}*/}
                    {/*>*/}
                    {/*  {selectOptions}*/}
                    {/*</select>*/}
                    <div>

                    </div>
                  </>
                )}
                {props.valueToAsignSelector && (
                  <RelationInsertValue
                    label={props.inputLabel}
                    value={props.valueToAsignSelector[props.nameOfPropertyToAsign]}
                    handlePostRelation={handlePostRelation}
                    handleCancel={handleCancel}
                  />
                )}
              </div>
            </CardContainer>
          )}
        </React.Fragment>
      )}
      {props.asignedListSelector.length > 0 && (
        <CardContainer>
          {props.asignedListSelector?.map((producto) => (
            <div className="flex items-center p-2 justify-between">
              <div>{producto?.simbolo} </div>
              <div className="">
                <IconButton
                  onClick={() => handleErrase(producto)}
                  iconWidth={3}
                  iconHeight={3}
                  icon="trash"
                  color="gray"
                />
              </div>
            </div>
          ))}
        </CardContainer>
      )}
    </CardContainer>
  );
};
export default AsignProductoToCliente;
