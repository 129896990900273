
import React from 'react';

export const InnerApp=({children,mobileNavbarHeader})=>(
  <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
    {/* Open sideBar */}
    {mobileNavbarHeader}
    <div className="flex-1 relative z-0 justify-center  flex overflow-auto">
      {children}
    </div>
  </div>
)