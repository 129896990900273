import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../../Util/authorizationHeader';

export const deleteRequestThunk = (name, route, redirectRoute) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestUrl, reduxStateTree, history,} = payload;
  console.log('requestUrl->>>',requestUrl)

    console.log('reduxStateTree->>>',reduxStateTree)
    try {
      const response = await axios.delete(`${route}${requestUrl}/${reduxStateTree.id}`, {
        headers: {
           ...authorizationHeader()        },
      });
      history.push(`/${redirectRoute}`);
      return { data: response.data, reduxStateTree };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });


export const deleteRequestThunkV2 = (name, route, redirectRoute) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestUrl, reduxStateTree, history,} = payload;
    console.log('requestUrl->>>',requestUrl)
    console.log("reduc")
    try {
      const response = await axios.delete(`${route}${requestUrl}`, {
        headers: {
          ...authorizationHeader()        },
      });
      history.push(`/${redirectRoute}`);
      return { data: response.data, reduxStateTree };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });

export const deleteRequestFromComponentThunk = (name, route, redirectRoute) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestUrl, reduxStateTree, history, authCtx } = payload;

    console.log('requestUrl');
    console.log(requestUrl);
    console.log('route delete@@@@@!!!!');
    console.log(route);

    try {
      const response = await axios.delete(`${route}${requestUrl}`, {
        headers: {
           ...authorizationHeader()        },
      });
      history.push(`/${redirectRoute}`);
      return { data: response.data, reduxStateTree };
    } catch (err) {
      console.log('err====>>>');
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });
