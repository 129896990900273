import { createSlice } from '@reduxjs/toolkit';
import { apiURL_Nest } from '../Util/apiURL';
import {
  getRequestFromClientPathThunkV2,
} from '../Util/get-request-thunk';
import {
  getAllItemsRejected, getAllItemsFullfilledRest, defaultPendingEffect,
} from '../lib/default-get-all-items';


const apiHOST = apiURL_Nest();
const initialState = {
  raw: [],
  formated: [],
  display: [],
  filterSearchValue: '',
  order: false,
  orderBy: null,
  orderByOptions: ['', 'salida', 'createdAt', 'razonsocial', 'simbolo'],
  sortAscending: false,
  error: false,
  loading: false,
  success: null,
};

export const getSalidas = getRequestFromClientPathThunkV2('producto/getSalidas',
  `${apiHOST}`);

const salidasSlice = createSlice({
  name: 'getSalidas',
  initialState: initialState,
  reducers: {
    resetFilter (state, action) {
      state.filterSearchValue = null;
    },
    filterBy (state, action) {
      const value = String(action.payload.value).toUpperCase();
      state.filterSearchValue = value;
      try {
        const filtro = action.payload.raw.filter(
          (item) =>
            item.razonSocial.includes(value) ||
            item.simbolo.includes(value) ||
            item.inventario.includes(value) ||
            item.lote.includes(value) ||
            item.factura.includes(value) ||
            item.remision.includes(value) ||
            item.createdAt.includes(value),
        );
        filtro.length > 0
          ? (state.display = filtro)
          : (state.display = action.payload.raw);
      } catch {
        state.display = action.payload.raw;
      }
    },
    orderBy (state, action) {
      state.orderBy = action.payload;

      let payloadValue = action.payload;
      let sortFunction = (a, b) => {
        if (state.sortAscending) {
          if (a[payloadValue] > b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] < b[payloadValue]) {
            return -1;
          }
        }
        if (!state.sortAscending) {
          if (a[payloadValue] < b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] > b[payloadValue]) {
            return -1;
          }
        }
        return 0;
      };
      if (payloadValue === 'cp') {
        sortFunction = (a, b) => {
          if (state.sortAscending) {
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return -1;
            }
          }
          if (!state.sortAscending) {
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return -1;
            }
          }
          return 0;
        };
      }

      state.display.sort(sortFunction);
    },
    sortAscending (state, action) {
      state.sortAscending = !action.payload;
      const sortFunction = () => {
        if (state.sortAscending === action.payload) {
          return 1;
        }
        if (state.sortAscending !== action.payload) {
          return -1;
        }
      };
      state.display.sort(sortFunction);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalidas.fulfilled, getAllItemsFullfilledRest);
    builder.addCase(getSalidas.pending, defaultPendingEffect);
    builder.addCase(getSalidas.rejected, getAllItemsRejected);
  },
});

export default salidasSlice;

export const salidasActions = salidasSlice.actions;
