import React, { useContext, useState } from 'react';

import {
  deleteProductoEntradaAndUpdateMax,
  deleteProductoRechazo,

} from '../../store/editEntradaInventarioRechazos-slice';
import IconButton from '../TailwindUI/IconButton';
import { useDispatch } from 'react-redux';
import AuthContext from '../../store/auth-context';
import { prettifyNumber } from '../../Util/prettifyNumber';

export const ProductosSalidaRechazoTable = (props) => {
  const dispatch = useDispatch();
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [genericModalIsVisible, setGenericModalIsVisible] = useState(false);
  const [editEntity, setEditEntity] = useState(null);
  const authCtx = useContext(AuthContext);
  const { iva } = props;
  //Function Handlers
  const handleErrase = (e) => {
    console.log('handle errase Asigned p_ocp  !!!!!');
    props.handleErrase({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };
  const handleVigecia = (e) => {
    props.handleErrase({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };

  return (
    <React.Fragment>


      <div className='px-4 sm:px-6 lg:px-8'>
        <div className='sm:flex sm:items-center'>

          <div className='mt-4 m-auto sm:mt-0 sm:flex-none'>
            {/*<button*/}
            {/*  type='button'*/}
            {/*  className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'*/}
            {/*>*/}
            {/*  Agregar Registro*/}
            {/*</button>*/}


          </div>
        </div>
        <div className='mt-8 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div
              className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <div
                className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-darkBlueLight text-white'>
                  <tr>


                    <th scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6'>
                      CANTIDAD
                    </th>
                    <th scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-white'>
                      LOTE
                    </th>
                    <th scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-white'>
                      PRODUCTO
                    </th>
                    <th scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-white'>
                      OBSERVACIONES
                    </th>
                    <th scope='col'
                        className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                  {props.productosSalidaRechazo.length > 0 &&
                    props.productosSalidaRechazo?.map((producto, idx) => (
                      <tr className='text-left' key={producto.lote + idx}>
                        <td
                          className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                          {producto.cantidad}
                        </td>
                        <td
                          className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {producto.lote}
                        </td>
                        <td
                          className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {producto.producto.simbolo}
                        </td>
                        <td
                          className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {producto.observaciones}
                        </td>
                        <td
                          className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>

                          {/*<IconButton*/}
                          {/*  onClick={() => {*/}
                          {/*    setEditEntity(producto);*/}
                          {/*    setGenericModalIsVisible(true);*/}
                          {/*    //   dispatch(*/}
                          {/*    //     sliceActions.showUpdateProductoSalidaRechazoModal(*/}
                          {/*    //       true));*/}
                          {/*    // }*/}

                          {/*  }}*/}
                          {/*  color='blue'*/}
                          {/*  icon='edit'*/}
                          {/*  iconHeight={2.5}*/}
                          {/*  iconWidth={2.5}*/}
                          {/*/>*/}
                          <IconButton
                            onClick={async () => {
                              // setDeleteModalIsVisible(true)
                              await dispatch(deleteProductoEntradaAndUpdateMax(
                                { item:producto, authCtx }));
                            }}
                            color='red'
                            icon='trash'
                            iconHeight={2.5}
                            iconWidth={2.5}
                          />

                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td
                      className='whitespace-nowrap  py-4 pl-4 pr-3 text-sm  text-left font-medium text-gray-900 sm:pl-6'>
                      {prettifyNumber(props.productosSalidaRechazo?.reduce((prev, producto) =>prev+producto.cantidad,0))}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


    </React.Fragment>
  );
};

