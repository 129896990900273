import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthContext from '../../store/auth-context';
import { createItem, updateItem } from '../../store/create-item-slice';
import { LifeCycleFeedbackV3 } from '../LifeCycleFeedback/LifeCycleFeedback';

import * as Yup from 'yup';
import { objectToOption } from '../../Util/object-to-option';
import { renderForm } from './renderForm';

const CreateNotificacionSetting = () => {
    const sliceState = useSelector((state) => state.createItem);
    const dispatch = useDispatch();
    const routePath = 'notificaciones/settings';
    const handleSubmit = (values) => {
      const req = {
        request: values, routePath, authCtx,
      };
      dispatch(createItem(req));
      console.log(JSON.stringify(req, null, 2));
    };

    const authCtx = useContext(AuthContext);

    const formInitialValue = {
      name: '',
      description: '',
      proveedorId: '',
    };
    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    });

    const formElements = [
      {
        type: 'text',
        name: 'name',
        value: 'name',
        label: 'nombre',
        component: 'input',
      },
      {
        type: 'text',
        name: 'settings',
        value: 'settings',
        label: 'settings',
        component: 'input',
      },

      {
        type: 'select',
        name: 'notificationChannelId',
        value: 'notificationChannelId',
        label: 'notification-channel',
        component: 'selectHelperFormik',

        props: {
          routePath: 'notificaciones/channels',
          modelName: 'notificaciones-channel',
          api: 'nest',
          transformer: (data) => {
            return data?.map(item => {
              return objectToOption({
                obj: item,
                valueKey: 'notificatonChannelId',
                textKey: 'name',
              });
            });
          },
        },
      },
      {
        type: 'select',
        name: 'notificationTemplateId',
        value: 'notificationTemplateId',
        label: 'notification-template',
        component: 'selectHelperFormik',

        props: {
          routePath: 'notificaciones/templates',
          modelName: 'notificaciones-templates',
          api: 'nest',
          transformer: (data) => {
            return data?.map(item => {
              return objectToOption({
                obj: item,
                valueKey: 'notificationTemplateId',
                textKey: 'uri',
              });
            });
          },
        },
      },
    ];

// const initialFormValues=

    let component = renderForm({formInitialValue, handleSubmit,formElements,validationSchema});

    return <>
      <div>
        {sliceState.success !== true ? component : ''}

        <div>

          <LifeCycleFeedbackV3 sliceState={sliceState} />
        </div>
      </div>

    </>;

  }
;

export { CreateNotificacionSetting };