import React, { useState, useEffect, useContext } from 'react';
import InnerHeader from '../InnerHeader/InnerHeader';
import { distitoMateraAPI, apiURL } from '../../Util/apiURL';
import AuthContext from '../../store/auth-context';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableHeaderCellAuto from '../TailwindUI/TableHeaderCellAuto';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useSelector, useDispatch } from 'react-redux';
import { getInventario, inventarioActions } from '../../store/inventario-slice';
import {
  getTotalByFecha,
  getInventarioEntradas,
  getInventarioSalidas,
  getStock,
  inventarioEntradasSalidasActions,
} from '../../store/inventarioEntradasSalidas-slice';
import { useLocation } from 'react-router-dom';
import InventarioTabla from './InventarioTable';
import { prettifyNumber } from '../../Util/prettifyNumber';
import IconButton from '../TailwindUI/IconButton';
import { CardButton } from '../SharedUi/UpdateCardUI';
import { setDateStringDDMMYYYY_MONTH, setTime } from '../../Util/get-date';

// llamar a /views/inventario/?nombreInventario=MELLYMX&simbolo=producto3  para obtener el total, el nombre del inventario y el simbolo
// llamar a /views/inventario/entradas/?nombreInventario=MELLYMX&simbolo=producto2  para obtener los entradas de ese simbolo en ese inventario
// llamar a /views/inventario/salidas/?nombreInventario=MELLYMX&simbolo=producto1  para obtener los salidas de ese simbolo en ese inventario

const EntradasYSalidas = () => {
  const apiHOST = apiURL();
  const location = useLocation();
  console.log('location.search');
  const queryInfo = location.search;
  console.log(location.search);
  console.log('location.search');
  const queryParams = new URLSearchParams(location.search);
  const nombreInventario = queryParams.get('nombreInventario');
  const simbolo = queryParams.get('simbolo');
  const lote = queryParams.get('lote');
  console.log(nombreInventario);
  console.log(simbolo);

  const tableMapper = (arr) =>
    arr?.map((obj) => {
      const newObj = { ...obj };
      newObj.fecha = setDateStringDDMMYYYY_MONTH(obj.fecha) + ' ' + setTime(obj.fecha);
      // newObj['EN CALIDAD DE'] = obj.enCalidadDe;
      delete newObj['enCalidadDe'];
      return newObj;
    });

  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const inventario = useSelector((state) => state.inventario);
  const inventarioEntradasSalidas = useSelector((state) => state.inventarioEntradasSalidas);

  const sortAscending = useSelector((state) => state.inventario.sortAscending);

  const productFilterSearchValue = useSelector((state) => state.inventario.filterSearchValue);

  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [inventarioTotalEnFechasSeleccionadas, setInventarioTotalEnFechasSeleccionadas] =
    useState(null);

  useEffect(() => {
    dispatch(inventarioActions.resetFilter());
    dispatch(getInventarioEntradas({ urlParam: queryInfo, authCtx }));
    dispatch(getInventarioSalidas({ urlParam: queryInfo, authCtx }));
    dispatch(getStock({ urlParam: queryInfo, authCtx }));
  }, []);

  const handleNameFilter = (e) => {
    dispatch(inventarioActions.filterBy({ raw: inventario, value: e.target.value }));
  };
  const handleResetSearchFilter = (e) => {
    dispatch(inventarioActions.resetFilter());
  };

  const handleOrderby = (e) => {
    dispatch(inventarioActions.orderBy(e));
  };
  const handleSortBy = (e) => {
    dispatch(inventarioActions.sortAscending(sortAscending));
  };
  const handleCalcularTotal = () => {
    let requestObj = {
      fechaInicial,
      fechaFinal,
      simbolo,
    };

    dispatch(getTotalByFecha({ requestObj, authCtx }));
  };

  const handleEntradaYSalidaSum = (entradasYSalidas) => {
    const entradas = entradasYSalidas?.entradas;
    const salidas = entradasYSalidas?.salidas;
    let sumaEntradas = 0;
    let sumaSalidas = 0;
    if (entradas.length) {
      sumaEntradas = entradas.reduce((prev, curr) => prev + curr.cantidad, 0);
    }
    if (salidas.length) {
      sumaSalidas = salidas.reduce((prev, curr) => prev + curr.cantidad, 0);
    }
    return sumaEntradas - sumaSalidas;
  };
  console.log('inventarioEntradasSalidas----->>>>>>>');
  console.log(handleEntradaYSalidaSum(inventarioEntradasSalidas));
  console.log(inventarioEntradasSalidas);
  return (
    <>
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* <InnerHeader
          placeholder={'Buscar EntradasYSalidas'}
          handleSearchFilter={handleNameFilter}
          searchFilter={productFilterSearchValue}
          resetSearchFilter={handleResetSearchFilter}
          hideCreateButton={true}
          handleOrderby={handleOrderby}
          orderByOptions={orderByOptionsState}
          handleSortBy={handleSortBy}
          sortAscending={sortAscending}
        /> */}

        {/* Main content */}
        <FlexContainer className="flex-1 items-stretch  overflow-hidden ">
          <main className="flex-1 overflow-y-auto  ">
            {/* Primary column */}

            <FlexContainer className="flex-col   bg-gray-100 py-2">
              {/* {JSON.stringify(inventarioEntradasSalidas.stock)} */}
              <div className="shadow w-auto border-b border-gray-200  rounded-t rounded-b-lg bg-white  m-2 pb-3  overflow-x-auto  ">
                {/* {JSON.stringify(inventarioEntradasSalidas.entradas)} */}
                <div className="flex">
                  <div className=" flex-initial bg-orangeMely  uppercase text-white text-left text-xl p-3 w-1/3">
                    {inventarioEntradasSalidas?.stock?.map((i) => i.inventario?.toUpperCase())}
                  </div>
                </div>
                <div className="flex">
                  <div className=" flex-initial bg-darkBlueLight text-white text-left text-sm p-3 w-1/6">
                    {inventarioEntradasSalidas?.stock?.map((i) => i.simbolo?.toUpperCase())}
                  </div>
                  <div className=" flex-initial bg-darkBlueLight text-white text-left text-sm p-3 w-1/6">
                    {inventarioEntradasSalidas?.stock?.map((i) =>
                      prettifyNumber(i.total.toString()),
                    )}
                  </div>
                </div>
                {lote && (
                  <div className="flex">
                    <div className=" flex-initial bg-gray-200 text-gray-600 text-left text-sm p-3 w-1/6">
                      {lote.toUpperCase()}
                    </div>
                    <div className=" flex-initial bg-gray-200 text-gray-600  text-left text-sm p-3 w-1/6">
                      {prettifyNumber(String(handleEntradaYSalidaSum(inventarioEntradasSalidas)))}
                    </div>
                  </div>
                )}
                {/* <div>Fecha Inicio : {fechaInicial}</div>
                <div>
                  Fecha Fin
                  {fechaFinal}
                </div>
                {fechaFinal &&
                  fechaInicial &&
                  !inventarioEntradasSalidas?.totalByFecha && (
                    <CardButton onClick={handleCalcularTotal}>
                      Calcular
                    </CardButton>
                  )}
                <div>
                  Total Inventario En Fechas Seleccionadas :
                  <span className=" mt-2 p-2 text-xl">
                    {inventarioEntradasSalidas?.totalByFecha}
                  </span>
                  {inventarioEntradasSalidas?.totalByFecha ? (
                    <div>
                      Borar Filtro
                      <IconButton
                        onClick={() => {
                          setFechaInicial(null);
                          setFechaFinal(null);
                          dispatch(
                            inventarioEntradasSalidasActions.resetTotalByFecha()
                          );
                        }}
                        color="gray"
                        icon="trash"
                        iconHeight={3}
                        iconWidth={3}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div> */}

                <div className=" grid grid-cols-2">
                  <div className="border-r-8 border-white">
                    <div className="font-bold p-4 text-darkBlueLight">ENTRADA</div>
                    <Table>
                      <TableHeader>
                        <TableRowHeader>
                          <TableHeaderCellAuto
                            list={tableMapper(inventarioEntradasSalidas?.entradas)}
                          />
                        </TableRowHeader>
                      </TableHeader>

                      <TableBody>
                        {tableMapper(inventarioEntradasSalidas?.entradas).map(
                          (producto, personIdx) => {
                            const productoClone = { ...producto };
                            productoClone.cantidad = prettifyNumber(String(producto.cantidad));

                            return (
                              <React.Fragment>
                                <TableRow
                                  key={producto + personIdx + producto.simbolo + producto.cantidad}
                                  className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                >
                                  <TableBodyCellAuto url={'/entradas'} list={productoClone} />
                                  {/* {fechaFinal && fechaFinal ? (
                                  ''
                                ) : (
                                  <TableBodyCell>
                                    {!fechaInicial && (
                                      <IconButton
                                        onClick={() => {
                                          setFechaInicial(producto.fecha);
                                        }}
                                        color="green"
                                        icon="+"
                                        iconHeight={3}
                                        iconWidth={3}
                                      />
                                    )}
                                    {fechaInicial &&
                                      new Date(producto.fecha) >
                                      new Date(fechaInicial) && (
                                        <IconButton
                                          onClick={() => {
                                            setFechaFinal(producto.fecha);
                                          }}
                                          color="red"
                                          icon="+"
                                          iconHeight={3}
                                          iconWidth={3}
                                        />
                                      )}
                                  </TableBodyCell>
                                )} */}
                                </TableRow>
                              </React.Fragment>
                            );
                          },
                        )}
                        <TableRow>
                          <TableBodyCell colspan={'5'} className="text-right"></TableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <div className="border-r-8 border-white">
                    <div className="font-bold p-4 text-darkBlueLight">SALIDA</div>
                    <Table>
                      <TableHeader>
                        <TableRowHeader>
                          <TableHeaderCellAuto
                            list={tableMapper(inventarioEntradasSalidas?.salidas)}
                          />
                        </TableRowHeader>
                      </TableHeader>
                      <TableBody>
                        {tableMapper(inventarioEntradasSalidas.salidas).map(
                          (producto, personIdx) => {
                            const productoClone = { ...producto };
                            productoClone.cantidad = prettifyNumber(String(producto.cantidad));

                            return (
                              <React.Fragment>
                                <TableRow
                                  key={producto + personIdx + producto.simbolo + producto.cantidad}
                                  className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                >
                                  <TableBodyCellAuto url={'/salidas'} list={productoClone} />
                                  {/* {fechaFinal && fechaFinal ? (
                                  ''
                                ) : (
                                  <TableBodyCell>
                                    {!fechaInicial && (
                                      <IconButton
                                        onClick={() => {
                                          setFechaInicial(producto.fecha);
                                        }}
                                        color="green"
                                        icon="+"
                                        iconHeight={3}
                                        iconWidth={3}
                                      />
                                    )}
                                    {fechaInicial &&
                                      new Date(producto.fecha) >
                                      new Date(fechaInicial) && (
                                        <IconButton
                                          onClick={() => {
                                            setFechaFinal(producto.fecha);
                                          }}
                                          color="red"
                                          icon="+"
                                          iconHeight={3}
                                          iconWidth={3}
                                        />
                                      )}
                                  </TableBodyCell>
                                )} */}
                                </TableRow>
                              </React.Fragment>
                            );
                          },
                        )}
                        <TableRow>
                          <TableBodyCell colspan={'5'} className="text-right"></TableBodyCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            </FlexContainer>
          </main>
        </FlexContainer>
      </div>
    </>
  );
};
export default EntradasYSalidas;
