import React, { useState } from 'react';

import IconButton from '../TailwindUI/IconButton';
import { CardTitle } from '../SharedUi/UpdateCardUI';

import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { Link } from 'react-router-dom';
import DeleteModal from '../UI/Modals/DeleteModal';
import { GenericModal } from '../UI/Generics';
import { EditEntity } from '../UI/Generics/EditEntity/EditEntity';

const ProductosEntrada = (props) => {
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [genericModalIsVisible, setGenericModalIsVisible] = useState(false);
  const [editEntity, setEditEntity] = useState(null);

  //Function Handlers
  const handleErrase = (e) => {
    props.handleErrase({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };
  const handleUpdate = () => {
    props.handleUpdate();
  };

  return (
    <React.Fragment>
      <div>
        <CardTitle>{props.cardTitle}</CardTitle>

        {props.asignedListSelector?.length > 0 && (
          <div style={{ width: '100%', margin: 'auto' }}>
            <Table>
              <TableHeader>
                <TableRowHeader>
                  <TableHeaderCell>OCP</TableHeaderCell>
                  <TableHeaderCell>CANTIDAD</TableHeaderCell>
                  <TableHeaderCell>UNIDAD</TableHeaderCell>
                  <TableHeaderCell>DESCRIPCIÓN</TableHeaderCell>
                  <TableHeaderCell>FACTURA</TableHeaderCell>
                  <TableHeaderCell>REMISION</TableHeaderCell>
                  <TableHeaderCell>LOTE</TableHeaderCell>
                </TableRowHeader>
              </TableHeader>
              <TableBody>
                {props.asignedListSelector?.map((producto, personIdx) => {
                  console.log('producto');
                  console.log(producto);
                  return (
                    <React.Fragment key={producto.id}>
                      <TableRow
                        key={producto.id}
                        className={!producto.cuentaPorPagarId ? 'bg-white' : 'bg-green-50'}
                      >
                        <TableBodyCell>
                          <span className="text-orange-600 font-medium ">
                            <Link to={`/ordenes-de-compra-proveedor/${producto.numeroOcp}`}>
                              {producto.numeroOcp}
                            </Link>
                          </span>
                        </TableBodyCell>
                        <TableBodyCell>{producto[props.cantidadPropertyName]}</TableBodyCell>
                        <TableBodyCell>{producto.unidad}</TableBodyCell>
                        <TableBodyCell>{producto.simbolo}</TableBodyCell>
                        <TableBodyCell>{producto.factura}</TableBodyCell>
                        <TableBodyCell>{producto.notaDeRemision}</TableBodyCell>
                        <TableBodyCell>{producto?.lote}</TableBodyCell>
                        <TableBodyCell>
                          {producto.cuentaPorPagarId ? (
                            <IconButton
                              onClick={() => {}}
                              color="green"
                              icon="dollar"
                              iconHeight={2.5}
                              iconWidth={2.5}
                            />
                          ) : props.vigente ? (
                            <IconButton
                              onClick={() => setDeleteModalIsVisible(true)}
                              color="red"
                              icon="trash"
                              iconHeight={2.5}
                              iconWidth={2.5}
                            />
                          ) : (
                            ''
                          )}
                        </TableBodyCell>
                        <TableBodyCell>
                          <IconButton
                            onClick={() => {
                              setEditEntity(producto);
                              setGenericModalIsVisible(true);
                            }}
                            color="gray"
                            icon="edit"
                            iconHeight={2.5}
                            iconWidth={2.5}
                          />
                        </TableBodyCell>

                        {/* <IconButton
                        onClick={() =>
                          props.handleCreateCuentaPorPagar(producto)
                        }
                        color="orange"
                        icon="dollar"
                        iconHeight={2.5}
                        iconWidth={2.5}
                      /> */}
                      </TableRow>
                      {deleteModalIsVisible && (
                        <DeleteModal
                          title={'Eliminar producto de entrada'}
                          onConfirm={() => {
                            handleErrase(producto);
                            setDeleteModalIsVisible(false);
                          }}
                          onCancel={() => setDeleteModalIsVisible(false)}
                        />
                      )}

                      {genericModalIsVisible && (
                        <GenericModal
                          title={'Generic Modal'}
                          onConfirm={(sliceState) => {
                            setGenericModalIsVisible(false);
                          }}
                          onCancel={() => {
                            props.handleUpdateProductoEntrada();
                            setGenericModalIsVisible(false);
                          }}
                        >
                          <EditEntity
                            name="Producto Entrada"
                            // callback={}
                            entity={editEntity}
                            readOnlyFields={[
                              {
                                label: 'cantidad',
                                path: 'cantidadEntrada',
                                key: 'cantidadEntrada',
                              },
                              {
                                label: 'simbolo',
                                path: 'simbolo',
                                key: 'simbolo',
                              },
                            ]}
                            editFields={[
                              {
                                label: 'factura',
                                path: 'factura',
                                key: 'factura',

                                state: 'factura',
                              },
                              {
                                label: 'remision',
                                path: 'notaDeRemision',
                                key: 'notaDeRemision',
                              },
                              {
                                label: 'lote',
                                path: 'lote',
                                key: 'lote',
                              },
                            ]}
                            url="productos-entrada/remision-factura"
                          />
                        </GenericModal>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default ProductosEntrada;
//test
