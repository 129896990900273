import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../../Util/authorizationHeader';

export const deleteRelationTableThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestObj, stateTree, asignedListString, asignedListSelector, authCtx } = payload;
    try {
      console.log('route');
      console.log(route);

      const response = await axios.post(route, requestObj, {
        headers: {
          ...authorizationHeader()
        },
      });
      return {
        data: response.data,
        stateTree,
        asignedListString,
        simbolo: requestObj.simbolo,
        asignedListSelector,
      };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  });
