import {
  modalActions,
  deleteEntityFromModal,
  deleteEntity,
} from '../../store/modal-slice';
import DeleteModal from '../UI/Modals/DeleteModal';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import { GenericModal } from '../UI/Generics';
import { EditEntity } from '../UI/Generics/EditEntity/EditEntity';
import { CreateForm } from '../UI/Generics/Form/CreateForm';
import { objectToOption } from '../../Util/object-to-option';
import { createItemActions } from '../../store/create-item-slice';

export const UpdateUsuario = () => {
  const sliceState = useSelector((state) => state.modal);
  const context = useSelector((state) => state.modal?.context);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const redirectRoutePath = '/configuracion/usuarios';
  const usuario=context?.entity
  useEffect(() => {
    dispatch(modalActions.resetModal());

      if (context === null) {
        history.push(redirectRoutePath);
      }
    }, [],
  );

  const model = [
    {
      key: 'id',
      label: 'id',
      value:usuario?.id,
      options:{
        disabled:true,
        display:false
      }

    },
    {
      key: 'nombre',
      label: 'nombre',
      value:usuario?.nombre,
    },
    {
      key: 'password',
      type: 'password',
      label: 'contraseña',
    },


  ];
  return (<>
      <GenericModal
        title={'Generic Modal'}
        onConfirm={async (e) => {
          if (!e.current) return
          console.log('onConfirm-->>>');
          await dispatch(deleteEntityFromModal({
            redirectRoutePath,
            entity: context?.entity,
            deleteId: context?.entity?.id,
            apiRoutePath: 'usuarios',
            authCtx,
          }));

        }}
        onCancel={
          (e) => {
            if (!e.current) return;
            console.log('onCancel-->>>');

            history.push(redirectRoutePath);
            dispatch(modalActions.resetModal());
            dispatch(createItemActions.reset());

          }
        }
        sliceState={  context}
      >

        <h2>Editar Usuario</h2>

        <CreateForm routePath={`usuarios/${context?.entity?.id}`}
                    model={model}
                    requestBuilder={(state) => ({
                      nombre: state.nombre,
                      email: state.email,
                      password: state.password,
                      role: state.role,
                    })}
                    buttonName={"Actualizar"}
                    method={"PATCH"}
        />

      </GenericModal>
  </>);
};