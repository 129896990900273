import { createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../../Util/apiURL';
import { postItemThunk } from '../../lib/default-post-item';
import {
  insertItemFullfilled,
  insertItemPending,
  insertItemRejected,
} from '../../lib/default-post-item';
const apiHOST = apiURL_Nest()
const initialState = {
  razonSocial: '',
  correoElectronico:'',
  rfc: '',
  direccionTipo:'fiscal_proveedor',
  numero: '',
  calle: '',
  ciudad: '',
  estado: '',
  colonia: '',
  cp: '',
  pais: '',
  diasCredito: '',
  error: false,
  loading: false,
  success: null,
};

export const insertProveedor = postItemThunk(
  'proveedor/insertProveedor',
  `${apiHOST}proveedores`,
  'proveedores',
);

const createProveedorSlice = createSlice({
  name: 'createProveedor',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'razonSocial':
          state.razonSocial = action.payload.value.toString().toUpperCase();
          break;
        case 'correoElectronico':
          state.correoElectronico = action.payload.value.toString().toUpperCase();
          break;
        case 'rfc':
          state.rfc = action.payload.value.toString().toUpperCase();
          break;
        case 'diasCredito':
          state.diasCredito = action.payload.value;
          break;
        case 'numero':
          state.numero = action.payload.value;
          break;
        case 'calle':
          state.calle = action.payload.value.toString().toUpperCase();
          break;
        case 'ciudad':
          state.ciudad = action.payload.value.toString().toUpperCase();
          break;
        case 'estado':
          state.estado = action.payload.value.toString().toUpperCase();
          break;
        case 'colonia':
          state.colonia = action.payload.value.toString().toUpperCase();
          break;
        case 'cp':
          state.cp = action.payload.value.toString().toUpperCase();
          break;
        case 'pais':
          state.pais = action.payload.value.toString().toUpperCase();
          break;

        default:
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(insertProveedor.fulfilled, insertItemFullfilled(initialState));
    builder.addCase(insertProveedor.pending, insertItemPending);
    builder.addCase(insertProveedor.rejected, insertItemRejected);
  },
});

export default createProveedorSlice;

export const createProveedorActions = createProveedorSlice.actions;
