import React from 'react';
import {
  getItems,
  fetchAllActions as sliceActions, handleFilter,
} from '../../store/fetch-all-slice';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const filterCondition = (state, value) => {
  let condition = (
    String(state?.razonSocial).toUpperCase().includes(value) ||
    String(state?.rfc).toUpperCase().includes(value)
  );
  return condition;
};


const Clientes = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    getItems,
    handleFilter,
    routePath: 'clientes',
    filterCondition,

    orderByOptions: [
      '',
      'razonSocial',
      'rfc',
    ],
  });
};

export default Clientes;
