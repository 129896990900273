import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import { getRequestWparamsThunk } from '../Util/get-request-thunk';

import * as util from '../lib/edit';
import { postItemThunkV2 } from '../lib/default-post-item-v2';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL_Nest();

const filterSeleccion = (valuesToFilter, selectedValueId) => {
  const filterdValue = valuesToFilter.filter(
    (item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const initialState = {
  entradaRechazo: {
    id: '',
    vigente: '',
    createdAt: '',
    createdBy: '',
    cliente: {
      id: '',
      razonSocial: '',
    },
    productosEntradaRechazo: [
      {
        cantidad: '',
        producto: {
          simbolo: '',
        },
        descripcion: '',
        motivo: '',
        lote: '',
        observaciones: '',
        responsable: '',
      }],
  },

  salidaRechazo: {
    id: '',
    vigente: '',
    createdBy: '',
    createdAt: '',
    entradaIrId: '',
    productosSalidaRechazo: [],
  },

  salidasRechazo: [],

  createSalidaRechazo: {
    entradaIrId: '',
    productosSalidaRechazo: [],
  },
  cantidadMaximaProductoSalidaRechazo: 0,

  showUpdateProductoSalidaRechazoModal: false,
  showCreateSalidaRechazoModal: false,
  showUpdateEntradaRechazoModal: false,
};

//Fetch Entrada to Edit

export const getEntrada = getRequestWparamsThunk(
  'editEntrada/getEntrada',
  `${apiHOST}entrada-rechazo`,
);

export const getSalidasRechazo = getRequestWparamsThunk(
  'editEntrada/getSalidasRechazo',
  `${apiHOST}salida-inventario-rechazo/entrada-rechazo`,
);

export const createSalidaInventarioRechazo = postItemThunkV2(
  'crearSalidaInventarioRechazo/insertSalida',
  `${apiHOST}salida-inventario-rechazo`,
  'entradas-inventario-rechazos',
  'entradaIrId',
);

export const updateSalidaInventarioRechazo = postItemThunkV2(
  'crearSalidaInventarioRechazo/insertSalida',
  `${apiHOST}salida-inventario-rechazo`,
  'entradas-inventario-rechazos',
  'entradaIrId',
);

export const getEntradaAndUpdateMax = (args) => {
  return async (dispatch, getState) => {
    await dispatch(getEntrada(args));
    await dispatch(getSalidasRechazo(args));

    const editEnradaInventarioRechazosState = getState().editEntradaInventarioRechazos;

    dispatch(
      editEntradaInventarioRechazosSlice.actions.asignCantidadMaximaProductoSalidaRechazo(
        editEnradaInventarioRechazosState));
  };
};


//Put Edited Entrada
export const putEntrada = createAsyncThunk('editEntrada/putEntrada',
  async (payload) => {
    const { requestObj, requestUrl, reduxStateTree, authCtx } = payload;
    console.log('authCtx----->>>>>>>');
    console.log(authCtx);
    try {
      const response = await axios.put(
        `${apiHOST}${requestUrl}/${reduxStateTree.id}`, requestObj, {
          headers: {
            ...authorizationHeader()          },
        });
      return { data: response.data, reduxStateTree };
    } catch (err) {
      return err;
    }
  });
//Delete  Entrada

export const deleteEntrada = util.deleteRequestFromComponentThunk(
  'editEntrada/deleteEntrada',
  apiHOST,
  'rechazos-cliente',
);

export const deleteProductoRechazo = createAsyncThunk(
  'editEntrada/deleteProductoRechazo',
  async (payload, { rejectWithValue }) => {
    console.log('payload-->>');
    console.log(payload);

    const response = await axios.delete(
      `${apiHOST}salida-inventario-rechazo/producto-salida/${payload.item.id}`,
      {
        headers: {
            ...authorizationHeader()        },
      },
    );
    return response.data;
  },
);
export const deleteProductoEntradaAndUpdateMax = (args) => {
  return async (dispatch, getState) => {
    await dispatch(deleteProductoRechazo(args));

    const editEnradaInventarioRechazosState = getState().editEntradaInventarioRechazos;

    dispatch(
      editEntradaInventarioRechazosSlice.actions.asignCantidadMaximaProductoSalidaRechazo(
        editEnradaInventarioRechazosState));
  };
};


export const deleteSalidaRechazo = createAsyncThunk(
  'editEntrada/deleteSalidaRechazo',
  async (payload, { rejectWithValue }) => {
    console.log('payload-->>');
    console.log(payload);

    const response = await axios.delete(
      `${apiHOST}salida-inventario-rechazo/${payload.item.id}`,
      {
        headers: {
            ...authorizationHeader()        },
      },
    );
    return response.data;

  },
);

export const cerrarSalidaRechazo = createAsyncThunk(
  'editEntrada/cerrarSalidaRechazo',
  async (payload, { rejectWithValue }) => {
    console.log('payload------->>>>>');
    console.log(payload);

    // const response = await axios.delete(
    //   `${apiHOST}salida-inventario-rechazo/producto-salida/${payload.item.id}`,
    //   {
    //     headers: {
    //         ...authorizationHeader()    //     },
    //   },
    // );
    // return response.data;
    return payload.item
  },
);




export const addProductoRechazoToEntradaRechazoAndUpdateMax = (args) => {
  return async (dispatch, getState) => {
    await dispatch(addProductoRechazoToEntradaRechazo(args));

    const editEnradaInventarioRechazosState = getState().editEntradaInventarioRechazos;

    dispatch(
      editEntradaInventarioRechazosSlice.actions.asignCantidadMaximaProductoSalidaRechazo(
        editEnradaInventarioRechazosState));
  };
};


export const addProductoRechazoToEntradaRechazo = createAsyncThunk(
  'editEntrada/addProductoRechazoToEntradaRechazo',
  async (payload, { rejectWithValue }) => {
    console.log('payload-->>');
    console.log(payload);

    const id = payload.request.salidaRechazoId;
    const request = payload.request.productoSalidaRechazo;
    console.log(request);
    const response = await axios.patch(
      `${apiHOST}salida-inventario-rechazo/${id}/producto-salida/`,
      request,
      {
        headers: {
            ...authorizationHeader()        },
      },
    );

    return response.data;
  },
);

export const editEntradaInventarioRechazosSlice = createSlice({
  name: 'editEntradaInventarioRechazos',
  initialState: initialState,
  reducers: {
    change (state, action) {

      switch (action.payload.field) {
        case 'productosSalidaRechazo':
          state.productosSalidaRechazo = action.payload.value;
          break;

        default:
      }
    },
    updateSalidaRechazo (state, action) {
      state.createSalidaRechazo.entradaIrId = action.payload.entradaIrId;
      state.createSalidaRechazo.productosSalidaRechazo.push(
        action.payload.productoSalidaRechazo);
    },
    createSalidaRechazo (state, action) {
      state.createSalidaRechazo.entradaIrId = action.payload.entradaIrId;
      state.createSalidaRechazo.productosSalidaRechazo.push(
        action.payload.productoSalidaRechazo);
    },

    setCantidadMax (state, action) {
      console.log('reducer updateSalidaRechazo ');
      state.shouldUpdateSalidaRechazo = true;
    },

    resetShouldUpdateSalidaRechazo (state, action) {
      console.log('reducer resetShouldUpdateSalidaRechazo ');
      state.shouldUpdateSalidaRechazo = false;
    },
    select (state, action) {
      state[action.payload.field] = filterSeleccion(
        action.payload.values,
        action.payload.selectedValue,
      );
      state.editsNotSaved = true;
    },

    asignCantidadMaximaProductoSalidaRechazo (state, action) {

      const sumaCantidades = (total, producto) => total +
        (+producto?.cantidad || 0);

      const sumCantidadProductosSalidaRechazo = action.payload?.salidasRechazo?.reduce(
        (prev, currentValue) => {
          let current = 0;
          if (currentValue.productosSalidaRechazo.length > 0) {
            current = currentValue.productosSalidaRechazo?.reduce(
              sumaCantidades, 0);
          }
          return prev + current;

        }, 0);
      const sumCantidadCrearProductosSalidaRechazo = action.payload?.createSalidaRechazo?.productosSalidaRechazo?.reduce(
        sumaCantidades, 0);
      const sumCantidadProductosEntradaRechazo = action.payload?.entradaRechazo?.productosEntradaRechazo?.reduce(
        sumaCantidades, 0);

      const maxVal = sumCantidadProductosEntradaRechazo -
        (sumCantidadProductosSalidaRechazo -
          sumCantidadCrearProductosSalidaRechazo);
      console.log('maxVal====>' + maxVal);
      state.cantidadMaximaProductoSalidaRechazo = maxVal;
    },
    showCreateSalidaRechazoModal (state, action) {
      state.showCreateSalidaRechazoModal = action.payload || false;
    },
    showUpdateProductoSalidaRechazoModal (state, action) {
      state.showUpdateProductoSalidaRechazoModal = action.payload || false;
    },
    showUpdateSalidaRechazoModal (state, action) {
      console.log('show--->>>');
      state.showUpdateSalidaRechazoModal = action.payload || false;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getEntrada.fulfilled, (state, action) => {

      console.log(action.payload);
      state.entradaRechazo = {
        ...action.payload,
      };
      state.error = false;
    });

    builder.addCase(getSalidasRechazo.fulfilled, (state, action) => {
      if (action.payload) {
        state.salidasRechazo = action.payload;
      }

      state.error = false;
    });

    builder.addCase(getEntrada.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    builder.addCase(createSalidaInventarioRechazo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(getSalidasRechazo.rejected, (state, action) => {
      state.loading = false;
      console.log(action.payload);
      // state.error = action.payload?.message;
    });

    builder.addCase(addProductoRechazoToEntradaRechazo.fulfilled,
      (state, action) => {

        console.log('fullfilled addProductoRechazoToEntradaRechazo');


        let updatedSalidaRechazo = state.salidasRechazo.find(
          updatedSalidaRechazo => updatedSalidaRechazo.id ===
            action.payload.id);
        if (!updatedSalidaRechazo) return;

        console.log('found!'+action.payload.id);
        console.log(updatedSalidaRechazo);
        console.log(action.payload);
        console.log('---------------action.payload');
        updatedSalidaRechazo=action.payload
        const updatedSalidaRechazoList = state.salidasRechazo.map (
          salidaRechazo => {
            if (salidaRechazo.id === updatedSalidaRechazo.id) {
              return updatedSalidaRechazo;
            }
            return salidaRechazo;
          });


        state.salidasRechazo = updatedSalidaRechazoList;


      });
    //
    builder.addCase(deleteSalidaRechazo.fulfilled, (state, action) => {

      const { id } = action.payload;
      const updatedSalidasRechazo = state.salidasRechazo.filter(updatedSalidaRechazo => updatedSalidaRechazo.id !== id);


      state.salidasRechazo = updatedSalidasRechazo

    });
    builder.addCase(cerrarSalidaRechazo.fulfilled, (state, action) => {
      const { id } = action.payload;


      const updatedSalidasRechazo = state.salidasRechazo.map(salidaRechazo => {

        if (salidaRechazo.id === id) {
          console.log( 'returning update')
          console.log(action.payload);
          return action.payload

        }
        return salidaRechazo;

      });


      state.salidasRechazo = updatedSalidasRechazo

    });



    builder.addCase(deleteProductoRechazo.fulfilled, (state, action) => {

      const { notaSalidaRechazo, id } = action.payload;
      const updatedSalidaRechazo = state.salidasRechazo.find(
        updatedSalidaRechazo => updatedSalidaRechazo.id ===
          notaSalidaRechazo.id);
      if (!updatedSalidaRechazo) return;

      const productosSalidaRechazo = updatedSalidaRechazo.productosSalidaRechazo?.filter(
        item => item.id !== id);

      updatedSalidaRechazo.productosSalidaRechazo = productosSalidaRechazo;

      const updatedSalidaRechazoList = state.salidasRechazo.map(
        salidaRechazo => {
          if (salidaRechazo.id === updatedSalidaRechazo.id) {
            return updatedSalidaRechazo;
          }
          return salidaRechazo;
        });

      state.salidasRechazo = updatedSalidaRechazoList;

    });
    builder.addCase(deleteProductoRechazo.pending, (state, action) => {
      console.log('pending deleteProductoRechazo');
    });

    builder.addCase(deleteProductoRechazo.rejected, (state, action) => {
      console.log('rejected deleteProductoRechazo');
      console.log(action);

    });
    builder.addCase(addProductoRechazoToEntradaRechazo.rejected,
      (state, action) => {
        console.log('rejected deleteProductoRechazo');
        console.log(action);

      });
  },
});

export default editEntradaInventarioRechazosSlice;

export const editEntradaInventarioRechazosActions = editEntradaInventarioRechazosSlice.actions;
