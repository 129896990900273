import React from 'react';
import SelectHelper from '../TailwindUI/SelectHelper';

const divisa = {
  label: 'Divisa',
  fetchUrl: 'divisas',
  asignTree: 'divisa',
  removeTree: 'divisa',
  nameOfPropertyToAsign: 'nombre',
};
const productosCliente = {
  label: 'Productos Cliente',
  fetchUrl: 'views/clientes-producto',
  asignTree: 'productosCliente',
  removeTree: 'productosCliente',
  nameOfPropertyToAsign: 'simbolo',
};
const inventario = {
  label: 'Inventario',
  fetchUrl: 'nombres-inventario',
  asignTree: 'inventario',
  removeTree: 'inventario',
  nameOfPropertyToAsign: 'nombre',
};
const proveedor = {
  label: 'Proveedor',
  fetchUrl: 'proveedores',
  asignTree: 'proveedor',
  removeTree: 'proveedor',
  nameOfPropertyToAsign: 'razonSocial',
};
const cliente = {
  label: 'Cliente',
  fetchUrl: 'clientes',
  asignTree: 'cliente',
  removeTree: 'cliente',
  nameOfPropertyToAsign: 'razonSocial',
};
const tiposEnCalidaDe = {
  label: 'En Calidad De',
  fetchUrl: 'tipos-en-calidad-de',
  asignTree: 'enCalidadDe',
  removeTree: 'enCalidadDe',
  nameOfPropertyToAsign: 'nombre',
};
const cuentasporPagar = {
  label: 'Cuenta Por Pagar',
  fetchUrl: 'cuentas-por-pagar',
  asignTree: 'enCalidadDe',
  removeTree: 'enCalidadDe',
  nameOfPropertyToAsign: 'nombre',
};

export const SelectHelpers = ({ name, valueToAsignSelector, handleSelect, fetchParam }) => {
  console.log('fetchParam----->>>>>>>');
  console.log(fetchParam);
  console.log('fetchParam----->>>>>>>');
  const createSelectHelper = ({
    label,
    fetchUrl,
    asignTree,
    removeTree,
    nameOfPropertyToAsign,
  }) => (
    <SelectHelper
      label={label}
      fetchUrl={`${fetchUrl}${fetchParam ?? ''}`}
      asignTree={asignTree}
      removeTree={removeTree}
      nameOfPropertyToAsign={nameOfPropertyToAsign}
      valueToAsignSelector={valueToAsignSelector}
      handleSelect={handleSelect}
    />
  );

  let selectHelper;
  switch (name) {
    case 'divisa':
      selectHelper = createSelectHelper(divisa);
      break;
    case 'inventario':
      selectHelper = createSelectHelper(inventario);
      break;
    case 'proveedor':
      selectHelper = createSelectHelper(proveedor);
      break;
    case 'cliente':
      selectHelper = createSelectHelper(cliente);
      break;
    case 'tiposEnCalidaDe':
      selectHelper = createSelectHelper(tiposEnCalidaDe);
      break;
    case 'cuentasporPagar':
      selectHelper = createSelectHelper(cuentasporPagar);
      break;
    case 'productosCliente':
      selectHelper = createSelectHelper(productosCliente);
      break;

    default:
      selectHelper = (
        <SelectHelper valueToAsignSelector={valueToAsignSelector} handleSelect={handleSelect} />
      );
  }

  return selectHelper;
};
