import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../../Util/authorizationHeader';

export const postRelationTableThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestObj, reduxStateTree, selectedValue, asignedListString ,authCtx} = payload;
    try {
      console.log('route');
      console.log(route);
      
      const response = await axios.post(route,
      requestObj, {
        headers: {
          ...authorizationHeader()        },
      });
      return {
        data: response.data,
        reduxStateTree,
        selectedValue,
        asignedListString,
      };
    } catch (err) {
      console.log(err)
      return rejectWithValue(err.response.data);
    }
  });
