import React from 'react';
import {
  newCategoriaProductoActions as sliceActions,
  insertCategoriaProducto as insertThunk,
} from './newCategoriaProducto-slice';
import { useSelector } from 'react-redux';
import { CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import { RenderInputUI } from '../SharedUi/RenderInputUI';
import InsertTemplate from '../SharedUi/InsertTemplate';

const NewCategoriaProducto = () => {
  const sliceState = useSelector((state) => state.newCategoriaProducto);

  const form_datos = [
    {
      parentName: null,
      property: 'nombre',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.nombre,
      selectorName: 'nombre',
    },
  ];

  const request = {
    nombre: sliceState.nombre,
  };

  let condition = true;

  let components = [
    <CardContainer>
      <CardTitle>Categoria Producto</CardTitle>
      <RenderInputUI list={form_datos} sliceActions={sliceActions} />
    </CardContainer>,
  ];

  return (
    <InsertTemplate
      sliceState={sliceState}
      insertRequest={request}
      insertThunk={insertThunk}
      components={components}
      condition={condition}
    />
  );
};
export default NewCategoriaProducto;
