export const sortWithArray = (arr, sortingArray) => {
  let sortedArr = [];
  let othersArr = [];
  arr.forEach((element) => {
    //If element is not found ond sorting array it will indexOf will return -1
    sortingArray.indexOf(element) !== -1 ? sortedArr.push(element) : othersArr.push(element);
  });
  return [...sortedArr, ...othersArr];
};

//TODO Create a lib with this FN
//const sortingArray = ['salida', 'occ', 'cpc', 'razonSocial', 'cantidad', 'simbolo'];
// const mappedObj = sortObjKeysFromArray(newObj, sortingArray);
export const sortObjKeysFromArray = (obj, arr) =>
  sortWithArray(Object.keys(obj), arr).reduce((accumulator, key) => {
    accumulator[key] = obj[key];
    return accumulator;
  }, {});
