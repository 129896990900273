import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProveedor,
  putProveedor,
  deleteProveedor,
  editProveedorActions,
  getRelation,
  putJoinedTable,
  postRelationProducto,
  deleteRelationProducto,
} from './editarProveedor-slice';
import JoinedTable from '../SharedUi/JoinedTable';
import ModelInputsAndSelectors from './ModelInputsAndSelectors';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import DeleteModal from '../UI/Modals/DeleteModal';
import AsignProductoToCliente from '../TailwindUI/AsignProductoToCliente';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';

const EditProveedor = () => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

  const sliceState = useSelector(state => state.editProveedor);
  const asignedProducts = useSelector(state => state.editProveedor.asignedProducts);

  const urlParam = location.pathname.split('/')[2];
  const requestUrl = 'proveedores';
  const proveedorStateTree = sliceState.proveedor;
  const relationProducto_URL = `views/proveedores/producto/${urlParam}`;

  useEffect(() => {
    dispatch(getProveedor({ urlParam, authCtx }));

    // getRelationHandler(relationProducto_URL, 'relationsProductos');
    getRelationHandler(relationProducto_URL, 'asignedProducts');
  }, []);

  const handlePutProveedor = () => {
    const requestObj = {
      id: sliceState.proveedor.id,
      razonSocial: sliceState.proveedor.razonSocial,
      rfc: sliceState.proveedor.rfc,
      correoElectronico:sliceState.proveedor.correoElectronico,
      diasCredito: sliceState.proveedor.diasCredito,
     direccion:sliceState.direccion,
    };

    dispatch(
      putProveedor({
        requestObj,
        requestUrl,
        reduxStateTree: proveedorStateTree,
        authCtx,
      }),
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteProveedor({
        requestUrl,
        reduxStateTree: proveedorStateTree,
        history,
      }),
    );
  };

  const handleAsingProducto = e => {
    dispatch(editProveedorActions.asignReducer({ ...e }));
  };
  const handleCancelAsingProducto = e => {
    dispatch(editProveedorActions.cancelAsignReducer({ ...e }));
  };
  //Relations AsyncTHunks
  const handlePostRelation = e => {
    let requestObj = {};
    const { reduxStateTree, selectedValue, asignedListString } = e;
    console.log('reduxStateTree----------');
    console.log(reduxStateTree);
    if (reduxStateTree === 'productToAsign') {
      requestObj = {
        proveedor_id: sliceState.proveedor.id,
        producto_id: sliceState.productToAsign.id,
      };
    }
    dispatch(
      postRelationProducto({
        requestObj,
        reduxStateTree,
        selectedValue,
        asignedListString,
        authCtx,
      }),
    );
  };
  const handleEraseRelation = e => {
    let requestObj = {};
    const { reduxStateTree, asignedListString, asignedListSelector } = e;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    if (reduxStateTree === 'productToAsign') {
      requestObj = {
        proveedor_id: sliceState.proveedor.id,
        producto_id: e.valueToUnasign.productoId,
        simbolo: e.valueToUnasign.simbolo,
      };
    }

    dispatch(
      deleteRelationProducto({
        requestObj,
        reduxStateTree,
        asignedListString,
        asignedListSelector,
        authCtx,
      }),
    );
  };

  const getRelationHandler = (requestUrl, reduxStateTree) => {
    dispatch(
      getRelation({
        requestUrl,
        reduxStateTree,
        authCtx,
      }),
    );
  };

  return (
    <>
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Main content */}
        <FlexContainer className="flex-1 items-stretch  overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            {/* Primary column */}

            {/* <div>{JSON.stringify(sliceState)}</div> */}
            <FlexContainer className="flex-col">
              <div className="flex flex-wrap ">
                <CardContainer>
                  <CardTitle>Proveedor</CardTitle>
                  <ModelInputsAndSelectors selectorName={'razonSocial'} label={'razonSocial'} />
                  <ModelInputsAndSelectors selectorName={'diasCredito'} label={'Dias Credito'} />
                  <ModelInputsAndSelectors selectorName={'correoElectronico'} label={'correo'} />

                  <ModelInputsAndSelectors selectorName={'rfc'} label={'rfc'} />
                  <CardButton onClick={handlePutProveedor}>Guardar</CardButton>
                </CardContainer>

                <JoinedTable
                  disableSave={true}
                  putJoinedTableThunk={putJoinedTable}
                  modelInputsAndSelectors={ModelInputsAndSelectors}
                  object={sliceState.direccion}
                  id={sliceState.id}
                  title={'Dirección fiscal'}
                  url={'direcciones'}
                  prefixSelector={''}
                  stateTree={'direccion'}
                />
              </div>

              <AsignProductoToCliente
                //UI
                api={'nest'}
                authCtx={authCtx}
                cardTitle={'Productos'}
                inputLabel={'Asignar producto'}
                //URL
                fetchUrl={'productos'}
                //Redux
                asignTree={'productToAsign'}
                removeTree={'productToRemoveAsign'}
                valueToAsignSelector={sliceState.productToAsign}
                asignedListSelector={asignedProducts}
                asignedListString={'asignedProducts'}
                // asignedListString={'relationsProductos'}

                //Select Options
                nameOfPropertyToAsign={'simbolo'}
                //Function Handlers
                handleSelect={handleAsingProducto}
                handleCancel={handleCancelAsingProducto}
                handlePostRelation={handlePostRelation}
                handleErrase={handleEraseRelation}
              />

              {deleteModalIsVisible && (
                <DeleteModal
                  title={'Borrar Proveedor'}
                  message={sliceState?.proveedor?.id}
                  item={`${sliceState?.proveedor?.razonSocial}`}
                  onConfirm={() => {
                    console.log('click');

                    handleDelete();

                    setDeleteModalIsVisible(false);
                  }}
                  onCancel={() => setDeleteModalIsVisible(false)}
                >
                  Esta Acción no puede desacerse
                </DeleteModal>
              )}
              <CardContainer>
                <CardButton bgColor="red" onClick={() => setDeleteModalIsVisible(true)}>
                  Borrar Producto
                </CardButton>
              </CardContainer>
              <LifeCycleFeedback
                erraseSucces={() => {
                  dispatch(editProveedorActions.resetSucces());
                }}
                erraseError={() => {
                  dispatch(editProveedorActions.resetError());
                }}
                sliceState={sliceState}
              />
            </FlexContainer>
          </main>
        </FlexContainer>
      </div>
    </>
  );
};
export default EditProveedor;
