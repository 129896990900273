export const occSendEmailFactory =(correoElectronico,documentName,type) => (
  
  {
  eventBus: null,
  eventSource: 'sistema_mely_frontend',
  detailType: 'notification-email-aws_ses_raw',
  eventDetail: {
    data: {
      email: {
        toAddresses: [correoElectronico],
        content: [
          {
            data: {
              file: {
                url: `https://s3.amazonaws.com/public/${type}/${documentName}.pdf`,
                source: 's3',
                key: `${type}/${documentName}.pdf`,
                bucket: 'melypackaging.public',
              },
            },
          },
        ],
      },
    },
    metadata: {
      notificationSettingId: 'd72f68dc-6cc9-460e-9b02-3214040c4d06',
      notificationName:`send_email_with_document_${type}_${documentName}`
    },
  },
})

