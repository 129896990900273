import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../Util/apiURL';
import { getRequestFromClientPathThunkV2 } from '../Util/get-request-thunk';
import {
  getAllItemsRejected,
  defaultPendingEffect,
} from '../lib/default-get-all-items';
import axios from 'axios';
import { createItem } from './create-item-slice';
import { authorizationHeader } from '../Util/authorizationHeader';
import fetchAllOptionsSlice, {
  fetchAllOptionsActions,
  getItems,
} from './fetch-all-options-slice';

const apiHOST = apiURL();
const apiHOST_Nest = apiURL_Nest();

const renderDataInitialState={
  isLoading: false,
  showModal: false,
  hasError: false,
}

const initialState = {
  data: [],//the Raw data
  // displayedData:[],
  displayedData:[],
    isLoading: false,
    showModal: false,
    hasError: false,
};

export const fetchAndSetData = ({ key="data",url, }) => {

  return async (dispatch, getState) => {

    dispatch(displayDataActions.reset());
    console.log("url->>>",url)
    await dispatch(getData({url}));
    console.log("getState->>",getState)
    console.log("getState->>",getState)
    console.log("getState->>",getState())
    const { data, error } = getState().displayData
    console.log("data-> from state->",data)
    if (data.length === 0 && error) return error;
    dispatch(
      displayDataActions.setStateKeyAndValue({ key:"displayedData",value:data}));
  };

};

export const getData = createAsyncThunk(
  'fetchAllOptions/getItems',
  async (payload, { rejectWithValue }) => {
    const { url } = payload;
    console.info("fetching url->", url)
    const response = await axios.get(url, {
      headers: {
        ...authorizationHeader(),
      },
    });


    return response.data;

  });




const displayDataSlice = createSlice({
  name: 'fetchAllOptions',
  initialState,
  reducers: {
    setStateKeyAndValue (state, action) {
      const {key, value } = action.payload;
      state[key] = value;
      console.log('setting Key-> ', key);
      console.log('data-->>' + value);
    },

    reset (state, action) {
      state['data'] = []
      state.renderDataState=renderDataInitialState
    },
    applyDataTransform(state,action){
      const {dataTransformer, value } = action.payload;
      try{
        state.isLoading=true;
        const data=dataTransformer(value);
        state.displayedData=data
        state.isLoading=false
        state.showModal=false

      }catch(err){
        state.error=err
      }
    },


  },
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
      state.showModal=false
    });
    builder.addCase(getData.pending, (state,action)=>{
      state.loading = true;
      state.error = false
      state.showModal=false
    });
    builder.addCase(getData.rejected, (state, action) => {
      console.log(action);
      console.log('action--->>error');
      state.loading = false;
      // todo handle errors in Reducers??
      state.error = action?.error;
    });
  },
});
export default displayDataSlice;
export const displayDataActions = displayDataSlice.actions;

