import React from 'react';

export const DesktopStaticSidebar = ({ children }) => (
  <div className='hidden lg:flex lg:flex-shrink-0'>
    <div className='flex flex-col w-64'>
      {children}
    </div>
  </div>

);


