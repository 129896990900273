// Entradas
import Entradas from '../components/Entrada/Entradas';
import CreateEntrada from '../components/Entrada/CreateEntrada';
import EditEntrada from '../components/Entrada/EditEntrada';
//Salidas
import Salidas from '../components/Salida/Salidas';
import CreateSalida from '../components/Salida/CreateSalida';
import EditSalida from '../components/Salida/EditSalida';

//Inventarios
import Inventario from '../components/Inventario/Inventario';
import Inventario2 from '../components/Inventario/Inventario2';
import EntradasYSalidas from '../components/Inventario/EntradasYSalidas';

//Documentos
import CreateSalidaIC from '../components/SalidaInventarioClientes/CreateSalidaIC';
import EditSalidaIC from '../components/SalidaInventarioClientes/EditSalidaIC';
import SalidasIC from '../components/SalidaInventarioClientes/SalidasIC';
import EntradasYSalidasClientes from '../components/Inventario/EntradasYSalidasClientes';

//Rechazos
import CreateEntradaInventarioRechazos from '../components/EntradaInventarioRechazos/CreateEntradaInventarioRechazos';
import EntradasInventarioRechazos from '../components/EntradaInventarioRechazos/EntradasInventarioRechazos';
import EditEntradaInventarioRechazos from '../components/EntradaInventarioRechazos/EditEntradaInventarioRechazos';
import RechazosCliente from '../components/RechazosCliente/RechazosCliente';
import EditRechazoCliente
  from '../components/RechazosCliente/EditRechazoCliente';
import CreateEntradaRechazoCliente
  from '../components/RechazosCliente/CreateEntradaRechazoCliente';
export const inventarioRoutes = [
  {
    name: 'Entradas',
    href: '/entradas',
    component: <Entradas />,
    exact: true,
  },
  {
    name: 'Crear Entrada',
    href: '/entradas/crear/',
    component: <CreateEntrada />,
    exact: true,
  },
  {
    name: 'Editar Entrada',
    href: '/entradas/:id',
    component: <EditEntrada />,
    exact: true,
  },
  {
    name: 'Salidas',
    href: '/salidas',
    component: <Salidas />,
    exact: true,
  },
  {
    name: 'Crear Salida',
    href: '/salidas/crear/',
    component: <CreateSalida />,
    exact: true,
  },

  {
    name: 'Editar Salida',
    href: '/salidas/:id',
    component: <EditSalida />,
    exact: true,
  },
  {
    name: 'Salidas IC',
    href: '/salidas-inventario-clientes',
    component: <SalidasIC />,
    exact: true,
  },
  {
    name: 'Crear Salida IC',
    href: '/salidas-inventario-clientes/crear/',
    component: <CreateSalidaIC />,
    exact: true,
  },
  {
    name: 'Editar Salida IC',
    href: '/salidas-inventario-clientes/:id',
    component: <EditSalidaIC />,
    exact: true,
  },
  //-----------Rechazos-------------------------------------------------------------------------------------------------------------
  {
    name: 'Entradas inventario rechazos',
    href: '/entradas-inventario-rechazos',
    component: <EntradasInventarioRechazos />,
    exact: true,
  },
  {
    name: 'Edit entradas inventario rechazos',
    href: '/entradas-inventario-rechazos/:id',
    component: <EditEntradaInventarioRechazos />,
    exact: true,
  },
  {
    name: 'Crear Entrada Rechazos',
    href: '/entradas-inventario-rechazos/crear/',
    component: <CreateEntradaInventarioRechazos />,
    exact: true,
  },


  //-----------RechazosCliente-------------------------------------------------------------------------------------------------------------
  {
    name: 'RechazosCliente',
    href: '/rechazos-cliente',
    component: <RechazosCliente />,
    exact: true,
  },
  {
    name: 'Edit entradas inventario rechazos',
    href: '/rechazos-cliente/:id',
    component: <EditRechazoCliente/>,
    exact: true,
  },

  {
    name: 'Crear Entrada Rechazos',
    href: '/rechazos-cliente/crear/',
    component: <CreateEntradaRechazoCliente />,
    exact: true,
  },

  //-----------Inventarios-------------------------------------------------------------------------------------------------------------
  {
    name: 'Inventarios2',
    href: '/test-inventario',
    component: <Inventario2 />,
    exact: true,
  },
  {
    name: 'Inventarios',
    href: '/inventario',
    component: <Inventario />,
    exact: true,
  },

  {
    name: 'Busqueda Inventario',
    href: '/inventario/busqueda',
    component: <EntradasYSalidas />,
  },
  {
    name: 'Busqueda Inventario',
    href: '/inventario-clientes/busqueda',
    component: <EntradasYSalidasClientes />,
  },

];

export const verEntradasSalidasRoutes=[
   {
    name: 'Entradas',
    href: '/entradas',
    component: <Entradas />,
    exact: true,
  },
   {
    name: 'Salidas',
    href: '/salidas',
    component: <Salidas />,
    exact: true,
  },
]