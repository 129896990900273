import { createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../Util/apiURL';
import { getRequestWithUrl } from '../Util/get-request-thunk';

const apiHOST = apiURL();
const apiHOST_Nest = apiURL_Nest();

const createRelationState = {
  asignedValues: [],
  valuesToAsign: [],
  error: false,
  loading: false,
  success: null,
};

export const getValuesToAsign = getRequestWithUrl('createRelation/getValuesToAsign', apiHOST);
export const getValuesToAsignNest = getRequestWithUrl('createRelation/getValuesToAsign', apiHOST_Nest);

const createRelationSlice = createSlice({
  name: 'createRelation',
  initialState: createRelationState,
  reducers: {
    reset(state) {
      state.valuesToAsign = [];
      state.asignedValues = [];
      state.error = false;
      state.loading = false;
      state.success = null;
    },
  },

  extraReducers: {
    [getValuesToAsign.fulfilled]: (state, action) => {
      state.valuesToAsign = action.payload;
      state.loading = false;
      state.error = false;
    },
    [getValuesToAsign.pending]: (state, action) => {
      state.valuesToAsign = [];
      state.asignedValues = [];
      state.loading = true;
      state.error = false;
    },
    [getValuesToAsign.rejected]: (state, action) => {
      state.valuesToAsign = [];
      state.asignedValues = [];
      state.loading = false;
      state.error = action.error?.message;
    },
  },
});

export default createRelationSlice;

export const createRelationActions = createRelationSlice.actions;
