import React, { useEffect, useContext } from 'react';
import InnerHeader from '../InnerHeader/InnerHeader';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useSelector, useDispatch } from 'react-redux';
import { getInventario2, inventarioActions2 } from '../../store/inventario2-slice';

import InventarioGeneric from './InventarioGeneric';
import { InventarioFiltered } from './InventarioFiltered';
import InventarioTableByKeys from './InventarioTableByKeys';
import { objectEntry } from '../../Util/object-entry';
import { BooleanHelper } from '../BooleanHelper';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Inventario2 = () => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const inventario = useSelector((state) => state.inventario2);
  const display = useSelector((state) => state.inventario2.display);
  const displayAll = useSelector((state) => state.inventario2.displayAll);
  const displayLote = useSelector((state) => state.inventario2.displayLote);
  const displayFiltered = useSelector((state) => state.inventario2.displayFiltered);
  const sortAscending = useSelector((state) => state.inventario2.sortAscending);

  const productFilterSearchValue = useSelector((state) => state.inventario2.filterSearchValue);

  useEffect(() => {
    dispatch(inventarioActions2.resetFilter());

    dispatch(getInventario2({ authCtx }));
  }, []);

  useEffect(() => {
    if (displayAll && !displayLote) {
      dispatch(getInventario2({ authCtx, url: 'views/inventarios?showAllEntries=true' }));
    } else if (displayLote && !displayAll) {
      dispatch(getInventario2({ authCtx, url: 'views/inventarios?showLote=true' }));
    } else if (displayLote && displayAll) {
      dispatch(
        getInventario2({ authCtx, url: 'views/inventarios?showAllEntries=true&showLote=true' }),
      );
    } else if (!displayLote && !displayAll) {
      dispatch(getInventario2({ authCtx, url: 'views/inventarios?' }));
    }
  }, [displayAll, displayLote]);

  const handleNameFilter = (e) => {
    dispatch(inventarioActions2.filterBy({ raw: inventario, value: e.target.value }));
  };
  const handleResetSearchFilter = (e) => {
    dispatch(inventarioActions2.resetFilter());
  };

  const handleOrderby = (e) => {
    dispatch(inventarioActions2.orderBy(e));
  };
  const handleSortBy = (e) => {
    dispatch(inventarioActions2.sortAscending(sortAscending));
  };

  const divideArray = (array, chunk = 1) => {
    return array?.reduce((all, one, i) => {
      const ch = Math.floor(i / chunk);
      all[ch] = [].concat(all[ch] || [], one);

      return all;
    }, []);
  };
  const handleDisplayAll = () => {
    dispatch(inventarioActions2.shouldDisplayAll(!displayAll));
  };
  const handleDisplayLote = () => {
    dispatch(inventarioActions2.shouldDisplayLote(!displayLote));
  };

  const RenderInventarioTable = (arr) => {
    return arr.map((item, index) => {
      const [inventario, values] = objectEntry(item);
      return (
        <div
          className={classNames(
            index % 1 === 0 ? 'bg-gray-200' : 'bg-blue-100',
            'flex w-full flex-wrap justify-around py-4',
          )}
        >
          {divideArray(values, 10).map((i) => (
            <InventarioTableByKeys lista={i} nombre={inventario} inventario={inventario} />
          ))}
        </div>
      );
    });
  };

  return (
    <>
      <div className="flex-1 flex flex-col overflow-hidden">
        <InnerHeader
          placeholder={'Buscar Inventario'}
          handleSearchFilter={handleNameFilter}
          searchFilter={productFilterSearchValue}
          resetSearchFilter={handleResetSearchFilter}
          hideCreateButton={false}
          hideOrdenarPor={true}
          handleOrderby={handleOrderby}
          handleSortBy={handleSortBy}
          sortAscending={sortAscending}
          handleDisplayAll={handleDisplayAll}
          state={inventario}
        />

        {/* Main content */}
        <FlexContainer className="flex-1 items-stretch  overflow-hidden ">
          <main className="flex-1 overflow-y-auto  ">
            {/* Primary column */}
            <div>
              {BooleanHelper({
                label: 'Mostrar Lotes',
                value: displayLote,
                onChange: handleDisplayLote,
              })}
            </div>

            <FlexContainer className="flex-col   bg-gray-100 py-2">
              {displayFiltered?.length > 0
                ? RenderInventarioTable(displayFiltered)
                : RenderInventarioTable(display)}
            </FlexContainer>
          </main>
        </FlexContainer>
      </div>
    </>
  );
};
export default Inventario2;
