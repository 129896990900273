import React from 'react';
import { objectToOption } from '../../Util/object-to-option';
import { CreateForm } from '../UI/Generics/Form/CreateForm';

const CreateProducto = () => {
  let editingSelectValue;
  const model = [
    {
      key: 'simbolo',
      label: 'simbolo',
    },
    {
      key: 'categoriaId',
      label: 'categoria',
      type: 'select',
      options: {
        routePath: 'categorias-productos',
        modelName: 'categoriasProductos',
        transformer: (data) => {
          return data?.map(item => {
            return objectToOption({
              obj: item,
              valueKey: 'id',
            });
          });
        },
      },
    },
    {
      key: 'divisaId',
      label: 'divisa',
      type: 'select',
      options: {
        routePath: 'divisas',
        modelName: 'divisa',
        transformer: (data) => {
          return data?.map(item => {
            return objectToOption({
              obj: item,
              valueKey: 'id',
            });
          });
        },
      },
    },

    {
      key: 'ect',
      options: {
        groupName: 'especificacionesProducto',
      },
    },
    {
      key: 'flauta',
    },
    {
      key: 'papeles',
    },
    {
      parentKey: 'especificacionesProducto',
      key: 'tintas',
    },
    {
      parentKey: 'especificacionesProducto',
      key: 'tratamientos',
    },

    {
      key: 'dimensionLaminaLargo',
      type: 'number',
    },
    {
      key: 'dimensionLaminaAncho',
      type: 'number',
    },
    {
      key: 'medidasInternasCajaAlto',
      type: 'number',
    },
    {
      key: 'medidasInternasCajaLargo',
      type: 'number',
    },
    {
      key: 'medidasInternasCajaAncho',
      type: 'number',
    },

    {
      key: 'costoAdquisicion',
      type: 'number',
      options: {
        min: '0.1',
        step: '0.0001',
      },
    },
    {
      key: 'precioVenta',
      type: 'number',
      options: {
        min: '0.1',
        step: '0.0001',
      },

    },
  ];

  return <CreateForm routePath={'productos'}
                     model={model}
                     submitGuard={(state) => {
                       let guard = (state.simbolo && state.categoriaId &&
                         state.divisaId && state.ect && state.flauta &&
                         state.papeles && state.tratamientos && state.tintas &&
                         state.dimensionLaminaLargo &&
                         state.dimensionLaminaAncho &&
                         state.medidasInternasCajaLargo &&
                         state.medidasInternasCajaAncho &&
                         state.medidasInternasCajaAlto &&
                         state.costoAdquisicion && state.precioVenta);
                       return Boolean(guard);

                     }}
                     requestBuilder={(state) => ({
                       simbolo: state.simbolo,
                       categoriaId: +state.categoriaId,
                       divisaId: +state.divisaId ,
                       especificacionProducto: {
                         ect: state.ect,
                         flauta: state.flauta,
                         papeles: state.papeles,
                         tintas: state.tintas,
                         tratamientos: state.tratamientos,
                       },
                       dimensionLamina: {
                         largo: state.dimensionLaminaLargo,
                         ancho: state.dimensionLaminaAncho,
                       },
                       medidasInternasCaja: {
                         largo: state.medidasInternasCajaLargo,
                         ancho: state.medidasInternasCajaAncho,
                         alto: state.medidasInternasCajaAlto,
                       },
                       costoAdquisicion: Number(state.costoAdquisicion),
                       precioVenta: Number(state.precioVenta),

                     })} />

};
export default CreateProducto;
