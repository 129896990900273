import React, { useState, useEffect, useContext } from 'react';
import { distitoMateraAPI, apiURL } from '../../Util/apiURL';
import AuthContext from '../../store/auth-context';
import {
  createPagoProveedorActions,
  insertPagoProveedor,
} from '../../store/crearPagoProveedor-slice';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from '../SharedUi/Grid';
import { CardButton } from '../SharedUi/UpdateCardUI';
import SelectHelper from '../TailwindUI/SelectHelper';
import { TextInput } from '../Clientes/CreateCliente';
import SelectDate from '../SharedUi/SelectDate';
import { InputCornerHint } from '../SharedUi/Inputs';
import IconButton from '../TailwindUI/IconButton';
import SuccesAlert from '../SuccesAlert/SuccesAlert';

const CreatePagoFromCpp = props => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    let firstTime = 0;
    if (firstTime === 0 && props.divisa.id === 1) {
      let payload = {
        reduxStateTree: 'divisa',
        valuesToFilter: [props?.divisa],
        selectedValue: props?.divisa?.id,
      };

      dispatch(createPagoProveedorActions.asign(payload));
    }
    firstTime++;
  }, []);

  //#region Redux Selectors--------------------------------->
  const createPagoProveedor = useSelector(state => state.createPagoProveedor);
  const fecha = useSelector(state => state.createPagoProveedor.fecha);
  const observaciones = useSelector(state => state.createPagoProveedor.observaciones);

  //#endregion  Redux Selectors <---------------------------------
  //#region  DiableSumbit Button

  let disableSubmit = true;
  if (createPagoProveedor?.monto && fecha && createPagoProveedor?.divisa?.id) {
    disableSubmit = false;
  }
  //#endregion

  //#region Function Handlers--------------------------------->

  const handleAsingDivisa = e => {
    dispatch(createPagoProveedorActions.asign({ ...e }));
    dispatch(createPagoProveedorActions.resetMonto());
  };

  const handleCreatePago = () => {
    console.log('create  pago proveedor ');
    let request = {
      cuenta_por_pagar_id: createPagoProveedor.cpp.id,
      monto: createPagoProveedor.monto.toFixed(2),
      observaciones: createPagoProveedor.observaciones,
      fecha: createPagoProveedor.fecha,
      divisa_id: createPagoProveedor.divisa?.id,
      tipo_de_cambio: createPagoProveedor?.tipoDeCambio.toFixed(4),
    };
    console.log(request);
    dispatch(
      insertPagoProveedor({
        request,
      }),
    );
    // props.onConfirm();
  };

  const handleDate = e => {
    dispatch(
      createPagoProveedorActions.change({
        field: 'fecha',
        value: e.target.value,
      }),
    );
  };

  const handleMonto = e => {
    const divisaPeso = props.divisa?.id === 1;
    const divisaDolar = props.divisa?.id === 2;
    const montoValue = Number(e.target.value);
    const montoYTipoDeCambioValue = Number(e.target.value) / createPagoProveedor?.tipoDeCambio;
    const sumaPagosYnotaYMonto = montoValue + props.totalPagosYNotas;
    const sumaPagosYnotaYMontoYtipoDeCambio = montoYTipoDeCambioValue + props.totalPagosYNotas;
    const condition1 = props.totalPagosYNotas === 0 && montoValue <= props.totalCpp;
    const condition2 = sumaPagosYnotaYMonto <= props.totalCpp;
    const condition1TipoDeCambio =
      props.totalPagosYNotas === 0 && montoYTipoDeCambioValue <= props.totalCpp;

    const condition2TipoDeCambio = sumaPagosYnotaYMontoYtipoDeCambio <= props.totalCpp;
    const dispatchMonto = () => {
      dispatch(
        createPagoProveedorActions.change({
          field: 'monto',
          value: e.target.value,
        }),
      );
      return;
    };

    if (e.target.value > 0) {
      if (divisaPeso || (divisaDolar && createPagoProveedor.divisa.id === 2)) {
        if (condition1 || condition2) {
          dispatchMonto();
          return;
        }
        return;
      }
      if (divisaDolar && createPagoProveedor.divisa.id === 1) {
        if (condition1TipoDeCambio || condition2TipoDeCambio) {
          dispatchMonto();

          return;
        }
        return;
      }
      return;
    }
  };
  //#endregion Function Handler <---------------------------------

  return (
    <div className="flex flex-col">
      {/* {JSON.stringify(createPagoProveedor)} */}

      <>
        {props?.divisa?.id === 2 ? (
          <SelectHelper
            //UI
            label={'Divisa'}
            inputLabel={'SeleccionarDivisa'}
            //URL
            fetchUrl={'divisas'}
            //Redux
            asignTree={'divisa'}
            removeTree={'divisa'}
            valueToAsignSelector={createPagoProveedor.divisa}
            // asignedListString={'relationsProductos'}
            //Select Options
            nameOfPropertyToAsign={'nombre'}
            //Function Handlers
            handleSelect={handleAsingDivisa}
          />
        ) : (
          <InputCornerHint
            label={'Divisa'}
            type={'text'}
            value={createPagoProveedor?.divisa?.nombre}
            disbaled
          />
        )}

        <div className="mt-1" />

        {props?.divisa?.id === 2 && createPagoProveedor?.divisa?.nombre === 'MXN' && (
          <InputCornerHint
            label={'Tipo de Cambio'}
            type={'number'}
            value={createPagoProveedor.tipoDeCambio}
            onChange={e => {
              dispatch(
                createPagoProveedorActions.change({
                  value: e.target.value,
                  field: 'tipoDeCambio',
                }),
              );
            }}
          />
        )}

        <div className="mt-6" />
        {createPagoProveedor.divisa?.id && (
          <InputCornerHint
            label={'Monto'}
            type={'number'}
            value={createPagoProveedor.monto}
            onChange={handleMonto}
          >
            <button
              onClick={() => {
                console.log('dispatch saldo pendiente');
                const divisaDolar = props?.divisa?.id === 2;
                const divisaPeso = props?.divisa?.id === 1;

                const restante = Number(props.totalCpp) - Number(props.totalPagosYNotas);
                const restanteContipoDeCambio = restante * createPagoProveedor?.tipoDeCambio;

                if (divisaDolar) {
                  dispatch(
                    createPagoProveedorActions.change({
                      field: 'monto',
                      value:
                        props?.divisa?.id === 2 && createPagoProveedor?.divisa?.nombre === 'MXN'
                          ? restanteContipoDeCambio.toFixed(2)
                          : restante.toFixed(2),
                    }),
                  );
                }
                if (divisaPeso) {
                  dispatch(
                    createPagoProveedorActions.change({
                      field: 'monto',
                      value: restante.toFixed(2),
                    }),
                  );
                }
              }}
            >
              <span className="px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                Saldo Pendiente
              </span>
            </button>
          </InputCornerHint>
        )}
        <div className="mt-1" />
        <SelectDate reduxStateTree={'fecha'} action={handleDate}>
          <InputCornerHint
            value={createPagoProveedor?.fechaDisplay}
            disabled
            label={'Fecha del Pago'}
          />
        </SelectDate>

        <div className="mt-2" />
        <InputCornerHint
          label={'Observaciones'}
          type={'text'}
          value={createPagoProveedor?.observaciones}
          onChange={e => {
            dispatch(
              createPagoProveedorActions.change({
                value: e.target.value,
                field: 'observaciones',
              }),
            );
          }}
        />
      </>
      {createPagoProveedor.closeModal ? (
        <div className="mt-4">
          <SuccesAlert
            message={'Tu Pago se registro con éxito'}
            onClick={() => {
              props.onConfirm();
              dispatch(createPagoProveedorActions.resetCloseModal());
            }}
          />
        </div>
      ) : (
        <CardButton disabled={disableSubmit} onClick={handleCreatePago}>
          Registrar Pago
        </CardButton>
      )}
    </div>
  );
};
export default CreatePagoFromCpp;
