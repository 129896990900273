import { SocioComercial } from './socio-comercial-table';
import { CardButton, CardContainer } from '../../SharedUi/UpdateCardUI';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { EdoCuentaPDF } from '../EdoCuentaPdf';
import React, { useEffect, useState } from 'react';
import { prettyFinancial } from '../../../Util/prettyFinancial';
import { postDocumentCommand } from '../../../domains/post-document-command';
import { postDocument } from '../../../store/editarOrdenCompraCliente-slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  edoCuentaDocumentRequestObj,
} from '../../../domains/edo-cuenta/edo-cuenta-document-request-obj';
import Spinner from '../../Spinner/Spinner';
import UploadsComponent from '../../UploadComponent/UploadsComponent';
import { asingDispatcher } from '../../../lib/sharedDispatchers';
import {
  createOCCActions as sliceActions,
} from '../../../store/crearOCC-slice';
import {
  documentTagsFactory,
  documentUrlQueryFactory,
} from '../../../factory/document.factory';
import { TestingPePe } from '../../UploadComponent/DisplayListWithActions';
import { displayDataActions } from '../../../store/display-data-slice';

export const EdoCuentasHeader = ({
  sliceState,
  divisa,
  displayAll,
  entidadComercial,
}) => {
  const handleAsingDownload = (event) => asingDispatcher(
    { dispatch, sliceActions, event });
  const fetchAllOptions = useSelector((state) => state.fetchAllOptions);
  const [archivoADescargar, setArchivoADescargar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [createDocumentHasError, setCreateDocumentHasError] = useState(false);
  const dispatch = useDispatch();
  const razonSocial = sliceState?.total?.razonSocial;
  const edoCuentaPdfFilename = `${new Date().toLocaleDateString()}_${razonSocial?.trim()
    .replaceAll(' ', '-')}_EDO-CUENTA.pdf`;

  const id = sliceState?.total?.id;

  return (
    <div className='flex'>
      <SocioComercial
        razonSocial={razonSocial}
        divisa={divisa}
        total={sliceState?.[divisa]?.total}
        vigente={sliceState?.[divisa]?.vigente}
        vencido={sliceState?.[divisa]?.vencido}
      />


      <div className='ml-10'>
        {displayAll}
      </div>

      <div className='ml-10'>
        <div>
          {/*<CardButton>*/}
          {/*  <PDFDownloadLink*/}
          {/*    document={*/}
          {/*      <EdoCuentaPDF*/}
          {/*        sliceState={sliceState}*/}
          {/*        divisa={divisa}*/}
          {/*      />*/}
          {/*    }*/}
          {/*    fileName={edoCuentaPdfFilename}*/}
          {/*  >*/}
          {/*    {({ blob, url, loading, error }) =>*/}
          {/*      loading ? 'Preparando Documento...' : 'Descargar'*/}
          {/*    }*/}
          {/*  </PDFDownloadLink>*/}
          {/*</CardButton>*/}

          <div>
            {createDocumentHasError && <div>Se produjo un Error </div>}
            {isLoading && <Spinner />}
            {!isLoading && !createDocumentHasError &&
              <CardButton onClick={async () => {

                const today = new Date().toLocaleDateString();
                console.log(sliceState);
                const data = {
                  productos: sliceState?.cuentasP.map(item => {
                    const cuentaPor = item?.productoCuenta?.cuentaPorCobrar ||
                      item?.productoCuenta?.cuentaPorPagar;
                    const monto = item?.cuenta.total;

                    return ({
                      factura: cuentaPor?.factura,
                      concepto: item?.productoCuenta?.producto?.simbolo,
                      fechaFactura: cuentaPor?.fechaFactura?.split(
                        'T')[0],
                      fechaVencimiento: cuentaPor?.fechaVencimiento?.split(
                        'T')[0],
                      monto: prettyFinancial(monto),
                      estatus: item?.cuenta?.estatus,
                      diasRestantes: item?.cuenta?.diasRestantes,
                      melyLlc: item?.cuenta?.nombreInventario === 'MELYUSA'
                        ? 'SI'
                        : 'NO',
                      saldo: prettyFinancial(item?.cuenta?.saldo),
                      pago: prettyFinancial(item?.cuenta?.totalPagos),
                      notaCredito: prettyFinancial(
                        item?.cuenta?.totalNotasDeCredito),

                      razonSocial: item.cuenta?.razonSocial,

                    });

                  }),
                  entidadComercial: {
                    categoria: String(entidadComercial).toUpperCase(),
                    razonSocial: sliceState?.total?.razonSocial,
                    // taxId:',
                    // calle:'',
                    // ciudad:'',
                    // estado:'',
                    // zip:'',
                    // pais:'',
                  },
                  vigente: prettyFinancial(sliceState.total?.vigente),
                  total: prettyFinancial(sliceState.total?.total),
                  vencido: prettyFinancial(sliceState.total?.vencido),
                  divisa: divisa,
                  today: today,

                };

                const today2 = new Date();
                const formattedDate = today2.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
                const formattedTime = today2.toTimeString()
                  .split(' ')[0].replace(
                  /:/g, '-'); // Format time as HH-MM-SS
                const fileName = String(sliceState?.total?.razonSocial)
                  .replace(/ /g, '-');
                const name = `${fileName}_${formattedDate}_T-${formattedTime}`;
                setIsLoading(true);

                const tags = documentTagsFactory.build({
                  entidadComercial,
                  entidadComercialId: id,
                  documentType: 'estado_de_cuenta',
                });
                const request = {
                  requestObj: edoCuentaDocumentRequestObj(data, name, tags),
                };
                try {
                  console.log('posting-->', request);
                  const document = await postDocumentCommand(dispatch,
                    postDocument, request);
                  console.log('document-->', document);
                  if (document?.name) {
                    setIsLoading(false);
                    window.location.reload()
                  } else {
                    throw  new Error('create document didn´t returned a name');
                  }
                } catch (err) {
                  console.log('catch-->', err);
                  setCreateDocumentHasError(true);
                }

              }}>
                Crear Documento
              </CardButton>}
          </div>
        </div>
      </div>
      <div>
      </div>
      <div className='ml-10'>


        {id && <TestingPePe id={id}
                            correoElectronico={sliceState?.total?.correoElectronico}
                            entidadComercial={entidadComercial}
        />}

      </div>
    </div>

  );
};