import {  createSlice } from '@reduxjs/toolkit';
import {
  getRequestFromClientPathThunk,
  getRequestFromClientPathThunkV2,
} from '../Util/get-request-thunk';
import { apiURL_Nest } from '../Util/apiURL';
import {
  defaultPendingEffect,
  defaultRejectedEffect,
} from '../lib/default-get-all-items';

const apiHOST = apiURL_Nest();
const initialState = {
  raw: [],
  formated: [],
  display: [],
  filterSearchValue: '',
  order: false,
  orderBy: null,
  orderByOptions: ['', 'salida', 'razonSocial', 'cp'],
  sortAscending: false,
  displayAll: false,
    cuentasP:[],
  error: false,
  loading: false,
};

export const getEdoCuentaCliente = getRequestFromClientPathThunkV2(
  'edoCuentaCliente/getEdoCuentas',
  `${apiHOST}clientes`,
);



const edoCuentaClienteSlice = createSlice({
  name: 'getSalidas',
  initialState: initialState,
  reducers: {
    resetFilter (state, action) {
      state.filterSearchValue = null;
    },
    shouldDisplayAll (state, action) {
      state.displayAll = action.payload;
    },
    filterBy (state, action) {
      state.filterSearchValue = action.payload.value;
    },
    erraseError (state, action) {
      state.error=false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEdoCuentaCliente.fulfilled, (state, action) => {
      state.display = action.payload;
      state.total = action.payload?.total;
      state.mxn = action.payload?.mxn;
      state.usd = action.payload?.usd;
      state.cuentasP = action.payload?.cuentasP?.flat()
      state.loading=false

    });

    builder.addCase(getEdoCuentaCliente.pending, defaultPendingEffect)
    builder.addCase(getEdoCuentaCliente.rejected, defaultRejectedEffect);

  },
});

export default edoCuentaClienteSlice;

export const edoCuentaClienteActions = edoCuentaClienteSlice.actions;
