import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BooleanHelper } from '../BooleanHelper';
import { SelectHelpers } from '../SelectHelpers';
import {
  createSalidaActions as sliceActions,
  insertSalida as insertThunk,
} from '../../store/crearSalida-slice';
import InsertTemplate from '../SharedUi/InsertTemplate';
import { asingDispatcher, seEmbarcaDispatcher } from '../../lib/sharedDispatchers';
const CreateSalida = () => {
  const sliceState = useSelector((state) => state.createSalida);
  const dispatch = useDispatch();

  const handleAsingProducto = (event) => asingDispatcher({ dispatch, sliceActions, event });

  let request = {
    cliente_id: sliceState?.cliente?.id,
    en_calidad_de_id: sliceState?.enCalidadDe?.id,
    se_embarca: sliceState?.seEmbarca,
  };
  const handleEmbarque = () => seEmbarcaDispatcher({ dispatch, sliceActions, sliceState });

  let components = [
    SelectHelpers({
      name: 'cliente',
      valueToAsignSelector: sliceState.cliente,
      handleSelect: handleAsingProducto,
    }),

    BooleanHelper({
      label: '¿Se embarca?',
      value: sliceState?.seEmbarca,
      onChange: handleEmbarque,
    }),
  ];
  let condition = sliceState.enCalidadDe && sliceState.cliente;

  return (
    <InsertTemplate
      sliceState={sliceState}
      insertRequest={request}
      insertThunk={insertThunk}
      components={components}
      condition={condition}
    />
  );
};
export default CreateSalida;
