import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOptions } from '../../store/fetch-all-options-slice';
import { SelectCornerHint } from '../SharedUi/Inputs';
import AuthContext from '../../store/auth-context';
import { objectToOption } from '../../Util/object-to-option';
import Spinner from '../Spinner/Spinner';
import Alert from '../Alert/Alert';
import MyCombobox from '../UI/Generics/Combobox/Combobox';

const ComboBoxHelper = ({
  routePath,
  modelName,
  transformer,
  onChange,
  label,
  selectedValue,
  dataKey
}) => {
  const authCtx = useContext(AuthContext);
  const sliceState = useSelector((state) => state.fetchAllOptions[modelName]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAll = async () => {
    setIsLoading(true);

    const data = await dispatch(
      fetchOptions({ routePath, authCtx, modelName }));

    console.log('testin->data response');
    console.log(data);
    if (data) {
      setError(data.message);
    }

    setIsLoading(false);
  };

  console.log(sliceState);
  console.log('sliceState');
  useEffect(() => {
    fetchAll();
  }, []);

  const fallbackTransformer = (data) => data?.map(
    item => objectToOption({ obj: item }));
  const data = transformer ? transformer(sliceState) : fallbackTransformer(
    sliceState);

  return (
    <div className='max-w-full max-h-full py-2 my-2'>
      {isLoading && <Spinner />}
      {error && <Alert message={
        <div className='text-left'>
          Error Fetching <span className='font-bold'>{modelName}</span>
          <div>
            Error Message <span className='font-bold'> {error}</span>
          </div>
        </div>

      } />}

      {(!isLoading && !error) &&
        <MyCombobox
          data={data} handleSelect={(e) => onChange([e, sliceState])}
          selectedValue={selectedValue} label={label} dataKey={dataKey}

        />}


    </div>
  );
};
export default ComboBoxHelper;
