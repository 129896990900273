import Notificaciones from './Notificaciones';
import { CreateNotificacion } from './CreateNotificacion';
import NotificationTypes from './NotificacionTypes';
import NotificationSettings from './NotificationSettings';
import { CreateNotificacionSetting } from './CreateNotificacionSetting';
import NotificationChannels from './NotificationChannels';
import NotificationTemplates from './NotificationTemplates';
import { CreateNotificacionChannel } from './CreateNotificacionChannel';
import { CreateNotificacionTemplate } from './CreateNotificacionTemplate';

export const notificacionesRoutes = [
  {
    name: 'Notificaciones',
    href: '/Notificaciones',
    component: <Notificaciones />,
    exact: true,
  },

  {
    name: 'Create Notificacion',
    href: '/notificaciones/crear/',
    component: <CreateNotificacion type='email' />,
    exact: true,
  },
//tipos
  {
    name: 'Tipos de Notificaciones',
    href: '/notificaciones-tipos',
    component: <NotificationTypes />,
    exact: true,
  },
  {
    name: 'Create Notificacion',
    href: '/notificaciones-tipos/crear/',
    component: <CreateNotificacion type='email' />,
    exact: true,
  },
//settings
  {
    name: 'Tipos de Notificaciones',
    href: '/notificaciones-settings',
    component: <NotificationSettings />,
    exact: true,
  },
  {
    name: 'Create Notificacion',
    href: '/notificaciones-settings/crear/',
    component: <CreateNotificacionSetting />,
    exact: true,
  },

  //channels
  {
    name: 'Notification Channels',
    href: '/notificaciones-channels',
    component: <NotificationChannels />,
    exact: true,
  },
  {
    name: 'Create Channel',
    href: '/notificaciones-channels/crear/',
    component: <CreateNotificacionChannel />,
    exact: true,
  },

  {
    name: 'Notification Templates',
    href: '/notificaciones-templates',
    component: <NotificationTemplates />,
    exact: true,
  },
  {
    name: 'Create Templates',
    href: '/notificaciones-templates/crear/',
    component: <CreateNotificacionTemplate />,
    exact: true,
  },



  // {
  //   name: 'Edit Clientes',
  //   href: '/clientes/:id',
  //   component: <EditCliente />,
  //   exact: true,
  // },
];
