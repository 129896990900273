import React from 'react';
import {
  getDocs,
  fetchAllActions as sliceActions, handleFilter,
} from '../../store/fetch-all-slice';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';
import { sortObjKeysFromArray } from '../../Util/sort-utils';
import TableRow from '../TailwindUI/TableRow';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import DropdownButtonWithIcon from '../DropdownMenu/DropdownButtonWithIcon';
import { downloadPDF } from '../../domains/post-document-command';
import IconButton from '../TailwindUI/IconButton';

const filterCondition = (state, value) => {
  let condition = (
    String(state?.name).toUpperCase().includes(value)
  );
  return condition;
};

const tableHeaderMapFn = (obj, idx) => {
  const newObj = {};
  newObj.name = obj.name;
  newObj.download = 'download';
  return newObj;
};
const customDisplayMapFn = (documento, idx) => {

  return (
    <React.Fragment key={documento?.id}>
      <TableRow
        key={documento?.id}
        className={
          idx % 2 === 0 ? 'bg-white' : 'bg-gray-100'
        }
      >
        <TableBodyCell>


          <div> {documento?.name} </div>


          {/*<DropdownButtonWithIcon text={'Descargar Documento'} icon={'documentAdd'}  onClick={*/}
          {/*  async () => {*/}

          {/*             // console.log('event->>',event)*/}
          {/* */}

          {/*    // try{*/}
          {/*    //   setIsLoading(true)*/}
          {/*    //   const document=await downloadPDF(documento.id,documento.name)*/}
          {/*    //   setIsLoading(false)*/}
          {/*    //*/}
          {/*    // }catch (err){*/}
          {/*    //   setCommandButtonError(true)*/}
          {/*    // }*/}
          {/*    //*/}


          {/*  }}/>*/}
        </TableBodyCell>
        <TableBodyCell>
          <IconButton icon={'download'} color={'darkBlue'} iconHeight={2}
                      iconWidth={2}
                      onClick={async () => {
                        console.log('download->>> action' + documento?.id);
                        await downloadPDF(documento?.id, documento?.name);
                        // setIsLoading(true);
                        // await download(name);
                        // setIsLoading(false);
                      }}
          />
        </TableBodyCell>
      </TableRow>
    </React.Fragment>
  );
};

const Documentos = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    getItems: getDocs,
    handleFilter,
    routePath: 'documents',
    customDisplayMapFn,
    tableHeaderMapFn,
    filterCondition,
    orderByOptions: [
      // '',
      // 'razonSocial',
      // 'rfc',
    ],
  });
};

export default Documentos;
