import React, { useState } from 'react';
import { greaterThan, lessThan } from '../primitives/operators';
const UseNumberInputWithMinMax = (title = 'cantidad', leftLabel = 'min', rightLabel = 'max') => {
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(1);
  const [cantidad, setCantidad] = useState(minValue);
  const [inputError, setInputError] = useState('');
  const minValueExeptionMessage = `No se permiten asignar cantidades menores a ${minValue} Productos`;
  const maxValueExeptionMessage = `Solo puedes agregar ${minValue} Productos`;

  const setInputValueToMaxValue = () => {
  console.log('setInputValueToMaxValue')
  console.log(maxValue)
    setCantidad(maxValue)
  }
  const setInputValueToMinValue = () => setCantidad(minValue);
  const isValueOnRange = (value, minValue, maxValue) => {
    handleRangeError(value, minValue, maxValue);
    if (lessThan(value, minValue) || greaterThan(value, maxValue)) return false;
    return true;
  };
  const handleRangeError = (value, minValue, maxValue) => {
    setInputError(null);
    if (lessThan(value, minValue)) setInputError(minValueExeptionMessage);
    else if (greaterThan(value, maxValue)) setInputError(maxValueExeptionMessage);
  };

  const handleInputChange = (e) => {
    console.log('handle input change??')
    const value = +e.target.value;
    console.log(value)

    console.log(!isValueOnRange(value, minValue, maxValue))

    if (!isValueOnRange(value, minValue, maxValue)) return;
    setCantidad(value);

  };

  return {
    cantidad,
    handleInputChange,
    minValue,
    setMinValue,
    maxValue,
    setMaxValue,
    setInputValueToMaxValue,
    setInputValueToMinValue,
    inputError,
  };
};

export default UseNumberInputWithMinMax;
