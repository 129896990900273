import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import OccPDF from '../DocsToPrint/OccPDF';
import { PencilAltIcon, TrashIcon, CheckIcon } from '@heroicons/react/solid';

import {
  getOrdenCompraCliente,
  putOrdenCompraCliente,
  deleteOrdenCompraCliente,
  cerrarOrdenCompraCliente,
  editOrdenCompraClienteActions,
  getRelation,
  postDocument,
  postRelationProducto,
  deleteRelationProducto,
  downloadDocument,
} from '../../store/editarOrdenCompraCliente-slice';
import AsignProductoToOC from '../AsignProductoToOc/AsignProductoToOC';

import SelectDate from '../SharedUi/SelectDate';
import DotsDropdown from '../DropdownMenu/DotsDropdown';
import DropdownItemButton from '../DropdownMenu/DropdownItemButton';
import { InputCornerHint } from '../SharedUi/Inputs';
import DocsCard from '../SharedUi/DocsCard';
import InfoMely, { datosMelyMx, datosMelyUsa } from '../InfoMely/InfoMely';

import {
  DocsCardLayoutLogo,
  DocsCardLayoutDatosDoc,
  DocsCardLayoutMenu,
  DocsCardLayoutTwoCols,
  DocsCardDatosFiscales,
  DocsCardLayoutInfoMely,
} from '../SharedUi/DocsCardLayout';
import DocsCardDatosDoc from '../SharedUi/DocsCardDatosDoc';
import LogoMely from '../SharedUi/LogoMely';
import AsignedProductosTable
  from '../AsignedProductosTable/AsignedProductosTable';
import DeleteModal from '../UI/Modals/DeleteModal';
import ConfirmModal from '../UI/Modals/ConfirmModal';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';
import OccProductosTable from './OccProductosTable';

import { OCDocumentFactory } from '../../domains/oc/OCDocumentFactory';
import { documentRequestObj } from '../../domains/oc/document-request-obj';
import DropdownButtonWithIcon from '../DropdownMenu/DropdownButtonWithIcon';
import {
  downloadPDF,
  postDocumentCommand,
} from '../../domains/post-document-command';

import { postEmail } from '../../store/editarOrdenCompraProveedor-slice';
import Spinner from '../Spinner/Spinner';
import {
  occSendEmailFactory,
} from '../../domains/notificaciones/occ-send-email.factory';

const EditOCC = () => {
  const [useEffectVariable, setUseEffectVariable] = useState(0);
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [commandButtonError, setCommandButtonError] = useState(false);

  useEffect(() => {
    fetchOrdenCompraCliente();
    getRelationHandler(relationProducto_URL, 'productosOrdenCompra');
    dispatch(editOrdenCompraClienteActions.resetProductToAssign());

  }, []);

  useEffect(() => {
    fetchOrdenCompraCliente();
    getRelationHandler(subtotalOCP_URL, 'subtotal');
  }, [useEffectVariable]);

  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const sliceState = useSelector((state) => state.editOrdenCompraCliente);

  const urlParam = location.pathname.split('/')[2];
  const requestUrl = 'ordenes-compra-cliente';
  const deleteUrl = `ordenes-compra-cliente/${sliceState?.occ?.numeroOcc}`;
  const updateUrl = `ordenes-compra-cliente/${sliceState?.occ?.numeroOcc}`;
  const relationProducto_URL = `views/productos-occ/${urlParam}`;
  const subtotalOCP_URL = `views/productos-occ/subtotal/${urlParam}`;

  const fetchOrdenCompraCliente = () => {
    dispatch(getOrdenCompraCliente({ urlParam, authCtx }));
  };

  const handlePutOrdenCompraCliente = ({ fechaEntrega }) => {
    const requestObj = {
      numero_occ: sliceState?.occ?.numeroOcc,
      cliente_id: sliceState?.occ?.clienteId,
      iva: sliceState?.occ?.iva,
      usuario_id: sliceState?.occ?.usuarioId,
      divisa_id: sliceState?.occ?.divisaId,
      inventario_id: sliceState?.occ?.inventarioId,
      estatus: sliceState?.occ?.estatus,
      fecha_entrega: fechaEntrega
        ? fechaEntrega
        : sliceState?.ocp?.fechaEntrega,
    };
    dispatch(
      putOrdenCompraCliente({
        requestObj,
        requestUrl: updateUrl,
        authCtx,
      }),
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteOrdenCompraCliente({
        requestUrl: deleteUrl,
        sliceState,
        history,
        authCtx,
      }),
    );
  };

  const handleCerrarOcc = () => {
    dispatch(
      cerrarOrdenCompraCliente({
        primarykey: sliceState?.occ?.numeroOcc,
        authCtx,
      }),
    );
  };

  // +--------------------------------------------------------------------------+

  ////Redux Actions
  const handleAsingProducto = (e) => {
    dispatch(editOrdenCompraClienteActions.asign({ ...e }));
  };
  const handleCancelAsingProducto = (e) => {
    dispatch(editOrdenCompraClienteActions.cancelAsign({ ...e }));
  };

  //Relations AsyncTHunks
  const getRelationHandler = (requestUrl, reduxStateTree) => {
    dispatch(
      getRelation({
        requestUrl,
        reduxStateTree,
        authCtx,
      }),
    );
  };

  const handleEraseRelation = (e) => {
    const requestObj = {
      id: e.valueToUnasign.id,
    };
    const { reduxStateTree, asignedListString, asignedListSelector } = e;
    dispatch(
      deleteRelationProducto({
        requestObj,
        reduxStateTree,
        asignedListString,
        asignedListSelector,
        authCtx,
      }),
    );
    setUseEffectVariable(useEffectVariable + 1);
  };

  const handlePostRelation = (e) => {
    let requestObj = {};
    const {
      reduxStateTree,
      selectedValue,
      asignedListString,
      cantidad,
      unidad,
    } = e;

    if (reduxStateTree === 'productToAsign') {
      requestObj = {
        numero_occ: sliceState?.occ?.numeroOcc,
        cantidad,
        unidad_id: unidad.id,
        producto_id: sliceState.productToAsign.id,
        precio_venta_id: sliceState.productToAsign.precioVentaId,
        divisa_id: sliceState?.occ?.divisa?.id,
        vigente: true,
      };
    }
    dispatch(
      postRelationProducto({
        requestObj,
        reduxStateTree,
        selectedValue,
        asignedListString,
        authCtx,
      }),
    );

    setUseEffectVariable(useEffectVariable + 1);
  };

  const handleDate = (e) => {
    dispatch(
      editOrdenCompraClienteActions.change({
        field: 'fechaEntrega',
        value: e.target.value,
      }),
    );
    handlePutOrdenCompraCliente({ fechaEntrega: e.target.value });
  };

  return (
    <React.Fragment>
      {sliceState.error || sliceState.loading ? (
        <LifeCycleFeedback sliceState={sliceState} />
      ) : (
        <div className='flex-1 flex flex-col overflow-hidden'>
          {/* Main content */}
          <FlexContainer className='flex-1 items-stretch  overflow-hidden '>
            <main className='flex-1 overflow-y-auto'>
              {/*{JSON.stringify(sliceState)}*/}
              {console.log(sliceState)}

              <FlexContainer className='flex-col'>
                <div className=' '>
                  {sliceState?.occ?.vigente ? (
                    <AsignProductoToOC
                      //UI
                      cardTitle={'Productos'}
                      inputLabel={'Añade Productos'}
                      //URL
                      fetchUrl={'views/ordenes-compra-cliente/productos-for-occ'}
                      //Redux
                      asignTree={'productToAsign'}
                      removeTree={'productToRemoveAsign'}
                      filterWhereId={sliceState?.occ?.cliente.id}
                      filterWhere={'clienteId'}
                      divisaId={sliceState?.occ?.divisa?.id}
                      valueToAsignSelector={sliceState?.productToAsign}
                      asignedListSelector={sliceState?.productosOrdenCompra}
                      subtotal={sliceState?.subtotal}
                      iva={sliceState?.occ?.iva}
                      asignedListString={'productosOrdenCompra'}
                      //Select Options
                      nameOfPropertyToAsign={'simbolo'}
                      //Function Handlers

                      handleSelect={handleAsingProducto}
                      handleCancel={handleCancelAsingProducto}
                      handlePostRelation={handlePostRelation}
                      handleErrase={handleEraseRelation}
                      handleUpdate={handlePutOrdenCompraCliente}
                    />
                  ) : (
                    ''
                  )}
                  <DocsCard>
                    {
                      //#region Dropdown
                    }


                    <DocsCardLayoutMenu>
                      <DotsDropdown vigente={sliceState?.occ?.vigente}>
                        <React.Fragment>

                          {sliceState?.occ?.vigente &&
                            sliceState.productosOrdenCompra.length > 0 && (
                              <DropdownItemButton
                                onClick={() => setConfirmModalIsVisible(true)}>
                                <CheckIcon
                                  className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                  aria-hidden='true'
                                />
                                Confirmar OCC
                              </DropdownItemButton>
                            )}

                          {
                            !sliceState?.occ?.vigente && (
                              <>

                                {/*{sliceState.productosOrdenCompra.length > 0 && (*/}
                                {/*  <React.Fragment>*/}
                                {/*    <DropdownItemButton>*/}
                                {/*      <PencilAltIcon*/}
                                {/*        className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'*/}
                                {/*        aria-hidden='true'*/}
                                {/*      />*/}
                                {/*      <PDFDownloadLink*/}
                                {/*        document={*/}
                                {/*          <OccPDF*/}
                                {/*            mely={*/}
                                {/*              sliceState?.inventario?.nombre ===*/}
                                {/*              'MELYUSA'*/}
                                {/*                ? { ...datosMelyUsa }*/}
                                {/*                : { ...datosMelyMx }*/}
                                {/*            }*/}
                                {/*            info={{*/}
                                {/*              titulo: 'ORDEN DE COMPRA',*/}
                                {/*              producto: 'CLIENTES',*/}
                                {/*              numero: sliceState?.entrada?.id,*/}
                                {/*              tipo: 'OCC',*/}
                                {/*              fechaEntrega: sliceState?.occ?.fechaEntrega*/}
                                {/*                ? sliceState?.occ?.fechaEntrega?.split(*/}
                                {/*                  'T')[0]*/}
                                {/*                : '',*/}
                                {/*              fecha: sliceState?.occ?.createdAt?.split(*/}
                                {/*                'T')[0],*/}
                                {/*              enCalidadDe: sliceState?.enCalidadDe?.nombre?.toUpperCase(),*/}
                                {/*            }}*/}
                                {/*            numeroOC={sliceState?.occ?.numeroOcc}*/}
                                {/*            list={sliceState?.productosOcc}*/}
                                {/*            totalTexto={sliceState?.totalTexto}*/}
                                {/*            subtotal={sliceState?.subtotal}*/}
                                {/*            total={sliceState?.total}*/}
                                {/*            divisa={sliceState?.divisa?.nombre}*/}
                                {/*            iva={sliceState?.iva}*/}
                                {/*            cliente={{*/}
                                {/*              razonSocial: sliceState?.cliente?.razonSocial,*/}
                                {/*              rfc: sliceState?.cliente?.rfc,*/}
                                {/*              direccionFiscal: {*/}
                                {/*                calle: sliceState?.cliente?.direccionFiscal?.calle,*/}
                                {/*                numero: sliceState?.cliente?.direccionFiscal?.numero,*/}
                                {/*                colonia: sliceState?.cliente?.direccionFiscal?.colonia,*/}
                                {/*                ciudad: sliceState?.cliente?.direccionFiscal?.ciudad,*/}
                                {/*                estado: sliceState?.cliente?.direccionFiscal?.estado,*/}
                                {/*                cp: sliceState?.cliente?.direccionFiscal?.cp,*/}
                                {/*                pais: sliceState?.cliente?.direccionFiscal?.pais,*/}
                                {/*              },*/}
                                {/*            }}*/}
                                {/*          />*/}
                                {/*        }*/}
                                {/*        fileName={`OCC_${sliceState?.occ?.numeroOcc}.pdf`}*/}
                                {/*      >*/}
                                {/*        {({ blob, url, loading, error }) =>*/}
                                {/*          loading*/}
                                {/*            ? 'Preparando Documento...'*/}
                                {/*            : 'Descargar'*/}
                                {/*        }*/}
                                {/*      </PDFDownloadLink>*/}
                                {/*    </DropdownItemButton>*/}
                                {/*  </React.Fragment>*/}
                                {/*)}*/}
                                {isLoading && <Spinner />}
                                {commandButtonError && (
                                  <button
                                    className={'bg-red-500  color-white'}
                                    onClick={() => {
                                      window?.location?.reload()
                                    }}
                                  >

                                    Se produjo un Error {`${commandButtonError}`}
                                  </button>)}


                                {!sliceState?.document?.id &&
                                  sliceState.productosOrdenCompra?.length > 0 &&
                                  !isLoading && !commandButtonError && (
                                    <DropdownButtonWithIcon
                                      text={'Crear documento'}
                                      icon={'documentAdd'}
                                      onClick={
                                        async () => {
                                          console.log(
                                            'create document---click--->>>');
                                          const regenerate = { date: new Date() };
                                          const request = {
                                            requestObj: documentRequestObj(
                                              { ...regenerate, ...sliceState }),
                                          };
                                          console.log('request', request);
                                          setIsLoading(true);
                                          const document = await postDocumentCommand(
                                            dispatch, postDocument, request);
                                          if (document?.name) {
                                            setIsLoading(false);
                                          } else {
                                            setCommandButtonError(
                                              'al crear el documento');
                                          }
                                        }
                                      }
                                    />
                                  )}

                                {sliceState?.document?.id && !isLoading &&
                                  !commandButtonError &&
                                  <DropdownButtonWithIcon
                                    text={'Descargar'}
                                    icon={'documentAdd'}
                                    onClick={async () => {

                                      const documentName = sliceState.document?.name;
                                      const documenId = sliceState.document?.id;

                                      // console.log('event->>',event)

                                      try {
                                        setIsLoading(true);
                                        const document = await downloadPDF(
                                          documenId,
                                          documentName);
                                        setIsLoading(false);

                                      } catch (err) {
                                        setCommandButtonError(
                                          'al descargar el documento');
                                      }

                                    }} />}

                                {sliceState?.document?.id && !isLoading &&
                                  !commandButtonError && (
                                    <DropdownButtonWithIcon
                                      text={'Enviar por correo'}
                                      icon={'paperIcon'}
                                      onClick={
                                        async () => {

                                          const correo = sliceState?.occ?.cliente?.correoElectronico;
                                          if (!correo) {
                                            setCommandButtonError(
                                              ', No esta dado de alta el correo receptor');
                                            return;
                                          }
                                          const documentName = sliceState.document.name;
                                          const event = occSendEmailFactory(
                                            correo,
                                            documentName, 'OCC');
                                          setIsLoading(true);
                                          dispatch(postEmail(
                                            { requestObj: event }))
                                            .unwrap()
                                            .then(res => {
                                              const response = res;
                                              console.log(
                                                'res->.', res);
                                              if (response.status ===
                                                201) {
                                                setIsLoading(
                                                  false);
                                              } else {

                                                setCommandButtonError(
                                                  ' al enviar el documento');
                                              }
                                              console.log(
                                                'sent email-->>>',
                                                res);
                                              console.log(
                                                'sent email-->>>',
                                                res);
                                              console.log(
                                                'sent email-->>>',
                                                res);
                                            });
                                        }} />
                                  )}

                                {/*TODO: delete document*/}
                                {/*{sliceState?.document?.id &&  !isLoading && !commandButtonError && (<DropdownButtonWithIcon text={'Borrar Documento'} icon={'trash'} disabled={true}  onClick={*/}
                                {/*  async () => {*/}
                                {/*    const correo=sliceState?.occ.cliente.correoElectronico|| "josearmentab@gmail.com"*/}
                                {/*    const documentName=sliceState.document.name*/}
                                {/*    // TODO BORRAR DOCUMENT :dispatch(postEmail({requestObj:event}))*/}
                                {/*  }}>*/}
                                {/*    Borrar Documento*/}
                                {/*  </DropdownButtonWithIcon>*/}

                                {/*)}*/}


                                {sliceState.productosOrdenCompra.length === 0 && (
                                  <DropdownItemButton
                                    onClick={() => setDeleteModalIsVisible(true)}>
                                    <TrashIcon
                                      className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                      aria-hidden='true'
                                    />
                                    Borrar OCC
                                  </DropdownItemButton>
                                )}
                              </>
                            )
                          }
                        </React.Fragment>
                      </DotsDropdown>
                    </DocsCardLayoutMenu>

                    {confirmModalIsVisible && (
                      <ConfirmModal
                        title={'Confirmar Orden de compra cliente'}
                        message={
                          'Los productos se podrán ingresar al inventario pero ya no podrás editar el documento, '
                        }
                        onConfirm={() => {
                          handleCerrarOcc();
                        }}
                        onCancel={() => setConfirmModalIsVisible(false)}
                      />
                    )}
                    {deleteModalIsVisible && (
                      <DeleteModal
                        title={'Borrar OCC'}
                        onConfirm={(sliceState) => {
                          console.log('click');

                          handleDelete(sliceState.id);

                          setDeleteModalIsVisible(false);
                        }}
                        onCancel={() => setDeleteModalIsVisible(false)}
                      >
                        Esta Acción no puede desacerse
                      </DeleteModal>
                    )}

                    <DocsCardLayoutLogo>
                      <LogoMely className='h-16 w-auto' />
                    </DocsCardLayoutLogo>

                    <DocsCardLayoutDatosDoc>
                      <DocsCardDatosDoc
                        title={'OC CLIENTE'}
                        number={sliceState?.occ?.numeroOcc}
                        date={sliceState?.occ?.createdAt?.split('T')[0]}
                      />
                    </DocsCardLayoutDatosDoc>

                    <DocsCardLayoutInfoMely>
                      <InfoMely
                        inventario={sliceState?.inventario?.nombre}
                        datosMely={
                          sliceState?.inventario?.nombre === 'MELYUSA'
                            ? datosMelyUsa
                            : datosMelyMx
                        }
                      />
                    </DocsCardLayoutInfoMely>

                    <DocsCardLayoutTwoCols>
                      <DocsCardDatosFiscales sliceState={sliceState?.cliente} />

                      <div className='col-span-1  pr-10'>
                        <div className='text-right'>
                          <span className='font-light mr-2'>Divisa:</span>
                          {sliceState?.divisa?.nombre}
                        </div>
                        <div className='text-right'>
                          <span className='font-light mr-2'>Inventario:</span>
                          {sliceState?.inventario?.nombre}
                        </div>

                        <div className='ml-auto' style={{ maxWidth: '170px' }}>
                          <SelectDate
                            reduxStateTree={'unidades'}
                            action={handleDate}
                            url={'unidades'}
                            entityPropertyToSelect={'nombre'}
                          >
                            <InputCornerHint
                              label={'Fecha de entrega'}
                              value={
                                sliceState?.occ?.fechaEntrega
                                  ? sliceState?.occ?.fechaEntrega?.split('T')[0]
                                  : ''
                              }
                            />
                          </SelectDate>
                        </div>
                      </div>
                    </DocsCardLayoutTwoCols>
                    <div className='col-span-6 '>
                      <OccProductosTable
                        //Redux
                        sliceState={sliceState}
                        asignedListSelector={sliceState?.productosOcc}
                        vigente={sliceState?.occ?.vigente}
                        asignTree={'productToAsign'}
                        asignedListString={'productosOrdenCompra'}
                        subtotal={sliceState?.subtotal}
                        iva={sliceState?.occ?.iva}
                        //Function Handlers
                        handleErrase={handleEraseRelation}
                        handleUpdateProductoOc={() => {
                          getRelationHandler(relationProducto_URL,
                            'productosOrdenCompra');
                        }}
                        type='occ'
                      />
                    </div>
                  </DocsCard>
                </div>
              </FlexContainer>
            </main>
          </FlexContainer>

          {/* <PDFViewer height={500} width={500}>
          <OccPDF
            mely={
              sliceState?.inventario?.nombre === 'MELYUSA'
                ? { ...datosMelyUsa }
                : { ...datosMelyMx }
            }
            info={{
              titulo: 'ORDEN DE COMPRA',
              producto: 'CLIENTES',
              numero: sliceState?.entrada?.id,
              tipo: 'OCC',
              fecha: sliceState?.occ?.fechaEntrega,
              enCalidadDe:
                sliceState?.enCalidadDe?.nombre?.toUpperCase(),
            }}
            numeroOC={sliceState?.occ?.numeroOcc}
            list={sliceState?.productosOrdenCompra}
            cantidadLetra={sliceState.occ?.cantidadLetra}
            subtotal={sliceState?.subtotal}
            iva={sliceState?.occ?.iva}
            cliente={{
              razonSocial: sliceState?.cliente?.razonSocial,
              direccionFiscal: {
                calle:
                  sliceState?.cliente?.direccionFiscal?.calle,
                numero:
                  sliceState?.cliente?.direccionFiscal?.numero,
                ciudad:
                  sliceState?.cliente?.direccionFiscal?.ciudad,
                estado:
                  sliceState?.cliente?.direccionFiscal?.estado,
                cp: sliceState?.cliente?.direccionFiscal?.cp,
                pais: sliceState?.cliente?.direccionFiscal
                  ?.pais,
              },
            }}
          />
        </PDFViewer> */}
        </div>
      )}
    </React.Fragment>
  );
};
export default EditOCC;
