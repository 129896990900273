import React from 'react';
import { CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import ToogleWithIcon from '../TailwindUI/ToogleWithIcon';

export const BooleanHelper = ({ label, onChange = null, value = null }) => (
  <CardContainer>
    <CardTitle>{label}</CardTitle>
    <div className="text-center">
      <ToogleWithIcon enabled={value} onChange={onChange} />
    </div>
  </CardContainer>
);
