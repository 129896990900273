import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiURL_Nest } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import { authorizationHeader } from '../Util/authorizationHeader';

const initialState = {
  success: null,
  loading: false,
  error: null,
  response: null,
  context:null
};
const apiHOST = apiURL_Nest();

export const deleteEntityFromModal = (args) => {
  console.log('deleteEntityFromModal');
  console.log(args);
  return async (dispatch, getState) => {

    console.log('her----->>>se????');
    const { redirectRoutePath, entity } = args;
    console.log(redirectRoutePath);
    console.log(entity);
    dispatch(
      modalSlice.actions.setModalContext({ redirectRoutePath, entity }));
    await dispatch(deleteEntity(args));

  };
};
export const deleteEntity = createAsyncThunk(
  'modal/deleteEntity',
  async (
    payload,
    { rejectWithValue }) => {
    const { apiRoutePath, redirectRoutePath, deleteId, authCtx } = payload;

    console.log('Request to->>' + `${apiHOST}${apiRoutePath}/${deleteId}`);

    try {
      const response = await axios.delete(
        `${apiHOST}${apiRoutePath}/${deleteId}`, {
          headers: {
            ...authorizationHeader()          },
        });
      console.log('response-->>');
      console.log(response);
      return response.data;
    } catch (err) {
      console.log('err====>>>');
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  },
);

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal (state, action) {
      const { modalName } = action.payload;
      state[modalName] = true;
    },
    resetModal (state, action) {
      state.success = null;
      state.loading = false;
      state.error = null;
      state.response = null;
    },
    hideModal (state, action) {
      const { modalName } = action.payload;
      state[modalName] = false;
    },
    switchModal (state, action) {
      const { modalName } = action.payload;
      state[modalName] = !state[modalName];
    },
    setModalContext (state, action) {
      state.contex = null;
      state.context = action.payload;
      console.log('modal context!!!!!!???????');
      console.log(action.payload);
    },
  },
  extraReducers (builder) {
    builder.addCase(deleteEntity.fulfilled, (state, action) => {
      state.success = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteEntity.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(deleteEntity.rejected, (state, action) => {
      console.log(action);
      console.log('action--->>error');
      state.loading = false;
      state.error = action.payload;

      state.success = null;
    });

  },
});
export default modalSlice;
export const modalActions = modalSlice.actions;

