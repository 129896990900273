import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import * as util from '../lib/edit';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL();

const filterSeleccion = (valuesToFilter, selectedValueId) => {
  const filterdValue = valuesToFilter.filter((item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const initialState = {
  editsNotSaved: null,
  cpc: null,
  proveedor: null,
  divisa: null,
  inventario: null,
  productToAsign: [],
  productosCuentaPorCobrar: [],
  productosSalida: null,
};

//Fetch CuentaPorCobrar to Edit

export const getCuentaPorCobrar = createAsyncThunk(
  'editCuentaPorCobrar/getCuentaPorCobrar',
  async (payload) => {
    const { urlParam, authCtx } = payload;
    console.log('editCuentaPorCobrar/getCuentaPorCobrar');
    console.log('11111!!!!!!!!!!!PAYLOAD!!!!!');
    console.log(payload);
    try {
      const response = await axios.get(`${apiHOST}views/cuentas-por-cobrar/${urlParam}`, {
        headers: {
          ...authorizationHeader()        },
      });
      if (response.data.length === 0) {
        const datosEntrada = await axios.get(`${apiHOST}cuentas-por-cobrar/${urlParam}`, {});
        console.log('datosEntrada.data view cuentas por pagar--');
        console.log(datosEntrada.data);
        return datosEntrada.data;
      }
      console.log('response.data view cuentas por pagar--');
      console.log(response.data);
      return response.data;
    } catch (err) {
      return err;
    }
  },
);

export const deleteCuentaPorCobrar = util.deleteRequestFromComponentThunk(
  'editCuentaPorCobrar/deleteCuentaPorCobrar',
  apiHOST,
  'cuentas-por-cobrar',
);

export const getRelation = util.getRequestRelationThunk('editCuentaPorCobrar/getRelation', apiHOST);

export const postRelationProducto = util.postRelationProductoThunk({
  name: 'editCuentaPorCobrar/postRelationProducto',
  postRoute: `${apiHOST}views/create/productos-cuenta-por-cobrar`,
  getRoute: `${apiHOST}views/productos-cuentas-por-cobrar`,
  getParam: 'requestObj',
  getParamKey: 'cuenta_por_cobrar_id',
});

export const deleteRelationProducto = util.deleteRelationProductoThunk({
  name: 'editCuentaPorCobrar/deleteRelationProducto',
  route: `${apiHOST}views/productos-cuenta-por-cobrar/delete`,
  method: 'POST_V2',
  findKey: 'pcpcId',
});

export const deleteRelationPago = util.deleteRelationProductoThunk({
  name: 'editCuentaPorCobrar/deleteRelationPago',
  route: `${apiHOST}views/pagos-cliente/delete`,
  method: 'POST_V2',
});

export const deleteRelationNotaDeCredito = util.deleteRelationProductoThunk({
  name: 'editCuentaPorCobrar/deleteRelationNotaDeCredito',
  route: `${apiHOST}views/notas-de-credito-cliente/delete`,
  method: 'POST_V2',
});

const editCuentaPorCobrarSlice = createSlice({
  name: 'editCuentaPorCobrar',
  initialState: initialState,
  reducers: {
    change(state, action) {
      // console.log('Reducer----->');
      // console.log(action.payload.field);
      // console.log(action.payload.value);
      switch (action.payload.field) {
        case 'cantidadLetra':
          state.ocp.cantidadLetra = action.payload.value;
          break;
        case 'fechaEntrega':
          state.ocp.fechaEntrega = action.payload.value;
          break;
        default:
      }
    },
    select(state, action) {
      console.log(' Edit actions  SELECT action.payload.field--------->>>>');
      console.log(action.payload.field);
      console.log(action.payload.selectedValue);
      console.log(action.payload.values);
      state[action.payload.field] = filterSeleccion(
        action.payload.values,
        action.payload.selectedValue,
      );
      state.editsNotSaved = true;
    },

    asign(state, action) {
      state[action.payload.reduxStateTree] = filterSeleccion(
        action.payload.valuesToFilter,
        action.payload.selectedValue,
      );
    },
    cancelAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    productToRemoveAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    resetProductosSalida(state, action) {
      state.productosSalida = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCuentaPorCobrar.fulfilled, (state, action) => {
      console.log('action.payload---------->');
      console.log(action.payload);
      console.log('CCP--------');
      state.cpc = action.payload;
    });
    builder.addCase(deleteCuentaPorCobrar.fulfilled, (state, action) => {
      state.editCuentaPorCobrar = {
        id: null,
        rfc: null,
        razonSocial: null,
        direccionId: null,
      };

      state.direccionFiscal = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.direccionEntrega = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.relationsProductos = [];
      state.asignedProducts = [];
      state.productToAsign = null;
      state.productToRemoveAsign = null;
    });

    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(postRelationProducto.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = null;
      console.log('action.payload.data-------?');
      console.log(action.payload.data);

      console.log('action.payload.data-------?');
      console.log(action.payload.asignedListString);

      state[action?.payload?.asignedListString] = [];
      if (Array.isArray(action.payload.data)) {
        action.payload.data.map((i) => state[action?.payload?.asignedListString]?.push(i));
      }
      // else{

      //   state[action?.payload?.asignedListString]=action.payload.data

      // }

      //Set State to Nullstate the product that has been posted.
    });
    builder.addCase(deleteRelationProducto.fulfilled, (state, action) => {
      console.log(' DELETE    RELATION   FULLFILLED');
      console.log(' action.payload.asignedListSelector');
      console.log(action.payload.asignedListSelector);
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.pcpcId !== action.payload.data.id,
      );

      console.log('FILTERED VALUE');
      console.log(filterdValue);
      state[action.payload.asignedListString] = filterdValue;
    });
    builder.addCase(deleteRelationProducto.rejected, (state, action) => {
      console.log(' aERROR ERRROt REOERORO');
      console.log(action);
    });

    builder.addCase(deleteRelationNotaDeCredito.fulfilled, (state, action) => {
      console.log(' action.payload.asignedListSelector');
      console.log(action.payload.asignedListSelector);
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.id !== action.payload.data.id,
      );

      console.log('FILTERED VALUE');
      console.log(filterdValue);
      state[action.payload.asignedListString] = filterdValue;
    });

    builder.addCase(deleteRelationPago.fulfilled, (state, action) => {
      console.log(
        '!!!!!!!!!!!!!!!!!!!!!!!!!!!-------????>>>>>>>>>>>>>>>>>>>>>>>> action.payload.asignedListSelector',
      );
      console.log(' action.payload.data');
      console.log(action.payload.data);
      console.log(' action.payload.asignedListSelector');
      console.log(action.payload.asignedListSelector);
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.id !== action.payload.data.id,
      );

      console.log('FILTERED VALUE');
      console.log(filterdValue);
      state[action.payload.asignedListString] = filterdValue;
    });

    builder.addCase(getValuesToAsign.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });
  },
});

export default editCuentaPorCobrarSlice;

export const editCuentaPorCobrarActions = editCuentaPorCobrarSlice.actions;
