import { createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import {
  asignReducer,
  cancelAsignReducer,
  productToRemoveAsignReducer,
} from '../lib/shared-reducers';
import * as util from '../lib/edit';
import { getDefaultRequestThunk } from '../lib/edit';

const apiHOST = apiURL();
const apiNest = apiURL_Nest();
const initialState = {
  cliente: {
    id: '',
    razonSocial: '',
    rfc: '',
    direccionFiscalId: '',
    direccionEntregaId: '',
    diasCredito: '',
    correoElectronico: ''
  },
  direccionFiscal: {
    id: '',
    numero: '',
    calle: '',
    colonia: '',
    ciudad: '',
    estado: '',
    cp: '',
    pais: '',
  },
  direccionEntrega: {
    id: '',
    numero: '',
    calle: '',
    colonia: '',
    ciudad: '',
    estado: '',
    cp: '',
    pais: '',
  },

  asignedProducts: [],
  productToAsign: null,
  productToRemoveAsign: null,
  error: false,
  loading: false,
  success: null,
};

//--------OK REQUESTS------------------------------------------------------------
export const getCliente = util.getDefaultRequestThunk(
  'cliente/getCliente',
  `${apiNest}clientes`,
);
export const putCliente = util.patchRequestThunk('cliente/putCliente', apiURL_Nest());

export const deleteCliente = util.deleteRequestThunk('cliente/deleteCliente', apiNest, 'clientes');

export const putJoinedTable = util.putRequestJoinedTableThunk('cliente/putForeignEntity', apiHOST);

export const getRelation = util.getRequestRelationThunk('cliente/getRelation', apiHOST);

export const postRelationProducto = util.postRelationTableThunk(
  'cliente/postRelationProducto',
  `${apiHOST}clientes-producto`,
);

export const deleteRelationProducto = util.deleteRelationTableThunk(
  'cliente/deleteRelationProducto',
  `${apiHOST}views/clientes-producto/delete`,
);

//--------OK REQUESTS------------------------------------------------------------
const editClienteSlice = createSlice({
  name: 'editCliente',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'razonSocial':
          state.cliente.razonSocial = action.payload.value;
          break;
        case 'rfc':
          state.cliente.rfc = action.payload.value;
          break;

        case 'correoElectronico':
          state.cliente.correoElectronico = action.payload.value;
          break;
        case 'diasCredito':
          state.cliente.diasCredito = action.payload.value;
          break;
        case 'direccionFiscal.numero':
          state.direccionFiscal.numero = action.payload.value;
          break;
        case 'direccionFiscal.calle':
          state.direccionFiscal.calle = action.payload.value;
          break;
        case 'direccionFiscal.colonia':
          state.direccionFiscal.colonia = action.payload.value;
          break;
        case 'direccionFiscal.ciudad':
          state.direccionFiscal.ciudad = action.payload.value;
          break;
        case 'direccionFiscal.estado':
          state.direccionFiscal.estado = action.payload.value;
          break;
        case 'direccionFiscal.cp':
          state.direccionFiscal.cp = action.payload.value;
          break;
        case 'direccionFiscal.pais':
          state.direccionFiscal.pais = action.payload.value;
          break;
        case 'direccionEntrega.numero':
          state.direccionEntrega.numero = action.payload.value;
          break;
        case 'direccionEntrega.calle':
          state.direccionEntrega.calle = action.payload.value;
          break;
        case 'direccionEntrega.colonia':
          state.direccionEntrega.colonia = action.payload.value;
          break;
        case 'direccionEntrega.ciudad':
          state.direccionEntrega.ciudad = action.payload.value;
          break;
        case 'direccionEntrega.estado':
          state.direccionEntrega.estado = action.payload.value;
          break;
        case 'direccionEntrega.cp':
          state.direccionEntrega.cp = action.payload.value;
          break;
        case 'direccionEntrega.pais':
          state.direccionEntrega.pais = action.payload.value;
          break;

        default:
      }
    },
    asignReducer,
    cancelAsignReducer,
    productToRemoveAsignReducer,
    resetSucces(state) {
      state.success = null;
    },
    resetError(state) {
      state.error = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getCliente.fulfilled, (state, action) => {
      const { correoElectronico,diasCredito,razonSocial,rfc,id, direccionFiscal, direccionEntrega } = action.payload;
      state.cliente = {
        correoElectronico,
        razonSocial,
        diasCredito,
        rfc,
        id,
      }
      state.direccionFiscal = direccionFiscal;
      state.direccionEntrega = direccionEntrega;
    });
    builder.addCase(deleteCliente.fulfilled, (state, action) => {
      state.cliente = {
        id: '',
        rfc: '',
        razonSocial: '',
        direccionId: '',
        diasCredito: '',
      };

      state.direccionFiscal = {
        id: '',
        numero: '',
        calle: '',
        colonia: '',
        ciudad: '',
        estado: '',
        cp: '',
        pais: '',
      };
      state.direccionEntrega = {
        id: '',
        numero: '',
        calle: '',
        colonia: '',
        ciudad: '',
        estado: '',
        cp: '',
        pais: '',
      };
      state.relationsProductos = [];
      state.asignedProducts = [];
      state.productToAsign = null;
      state.productToRemoveAsign = null;
    });
    builder.addCase(putCliente.fulfilled, (state, action) => {
      console.log('Console.log Action sss');
      console.log(action);
      state[action.payload.reduxStateTree] = action.payload.data;
      state.loading = false;
      state.error = false;
      state.success = true;
    });
    builder.addCase(putCliente.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(putCliente.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(putJoinedTable.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });
    builder.addCase(putJoinedTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(getValuesToAsign.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });
    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(getRelation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(postRelationProducto.fulfilled, (state, action) => {
      state[action.payload.asignedListString]?.push(action.payload.selectedValue);
      //Set State to Nullstate the product that has been posted.
      state[action.payload.reduxStateTree] = null;
    });
    builder.addCase(postRelationProducto.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(deleteRelationProducto.fulfilled, (state, action) => {
      const filterdValue = action.payload.asignedListSelector.filter(
        item => item.simbolo !== action.payload.simbolo,
      );
      state[action.payload.asignedListString] = filterdValue;

      // state[action.payload.stateTree] = null;
    });
    builder.addCase(deleteRelationProducto.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
      // state[action.payload.stateTree] = null;
    });
  },
});

export default editClienteSlice;

export const editClienteActions = editClienteSlice.actions;
