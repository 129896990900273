import React, { useState } from 'react';
import { LeftSideBarApp } from '../Layouts/LeftSideBarApp';
import { Sidebar1 } from '../Layouts/SideBar1';
import logo from '../../../images/logo-mely.svg';
import Navigation from '../../TailwindUI/navigation';
import useAuthentication from '../../../hooks/useAhthentication';
import { MobileNavBarHeader } from '../NavBarMobile/MobileNavBarHeader';
import { useNavigation } from '../../../hooks/useNavigation';
import { NavBarMobile } from '../NavBarMobile/NavBarMobile';
import { NavBarMobileClose } from '../NavBarMobile/NavBarMobileClose';
import { useSideBar } from '../../../hooks/useSideBar';
import { useMobileNavBar } from '../../../hooks/useMobileNavBar';
import { SidebarProvider } from '../../../context/SideBarProvider';

export const AppContainer = ({ layout, children }) => {
  const { logout, isAuthenticated } = useAuthentication();
  const navBarSections = useNavigation();
  let container;

  switch (layout) {
    default:
      container = '';
  }

  const mobileSideBar = useMobileNavBar({ navBarSections });

  let sideBar = <Sidebar1 logoSrc={logo} isAuthenticated={isAuthenticated}
                          logout={logout}
                          navigation={<Navigation
                            navBarSections={navBarSections}
                          />} />;
  return <SidebarProvider>
    <LeftSideBarApp
      navBarSections={navBarSections}
      mobileNavbar={mobileSideBar}
      sidebar={sideBar}
    >{children}
    </LeftSideBarApp>;
  </SidebarProvider>;

};