
import React, { PropsWithChildren } from 'react';
type RenderDataArgs<DATA> = {
  data: DATA[];
  Components: RenderDataComponentsType<DATA>;
  state: RenderDataState;
};
// Component Types
export type RenderDataComponentsType<DATA> = {
  Item: React.FC<DATA>;
  ItemsContainer: React.ComponentType<PropsWithChildren<{}>>;
  Header: React.ComponentType<PropsWithChildren<{}>>;
  Container: React.ComponentType<PropsWithChildren<{}>>;
  Loader: React.ComponentType<PropsWithChildren<{}>>;
  ErrorComponent: React.ComponentType<PropsWithChildren<{}>>;
  Modal: React.ComponentType<PropsWithChildren<{}>>;
};

export type RenderDataState = {
  isLoading: boolean;
  showModal: boolean;
  hasError: boolean;
};

export function buildRenderDataComponents<T>(args: RenderDataComponentsType<T>): RenderDataComponentsType<T> {
  return args;
}

export function RenderData<DATA>({ data, state, Components }: RenderDataArgs<DATA>) {
  const { hasError, isLoading, showModal } = state;

  if (hasError) {
    return <Components.ErrorComponent />;
  }

  if (isLoading) {
    return <Components.Loader />;
  }

  return (
    <Components.Container>
      <Components.Header />
      <Components.ItemsContainer>
        {data.map((item) => (
          <Components.Item key={(item as any).id} {...item} />
        ))}
      </Components.ItemsContainer>
      {showModal && <Components.Modal />}
    </Components.Container>
  );
}