import React from 'react';
import { objectToOption } from '../../Util/object-to-option';
import { CreateForm } from '../UI/Generics/Form/CreateForm';

const CreateUsuario = () => {
  const model = [
    {
      key: 'nombre',
      label: 'nombre',
    },
    {
      key: 'email',
      type: 'email',
      label: 'correo',
    },

    {
      key: 'password',
      type: 'password',
      label: 'contraseña',

    },
    {
      key: 'roleId',
      label: 'rol',
      type: 'select',
      options: {
        routePath: 'roles',
        modelName: 'roles',
        transformer: (data) => {
          return data?.map(item => {
            return objectToOption({
              obj: item,
              valueKey: 'id',
            });
          });
        },
      },
    },

  ];

  return  <CreateForm routePath={'authentication/sign-up'}
              model={model}
              requestBuilder={(state) => ({
                nombre: state.nombre,
                email: state.email,
                password: state.password,
                roleId: +state.roleId
              })} />;

};

export default CreateUsuario;