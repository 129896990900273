import Clientes from './Clientes';
import CreateCliente from './CreateCliente';
import EditCliente from './EditCliente';

export const clientesRoutes = [
  {
    name: 'Clientes',
    href: '/clientes',
    component: <Clientes />,
    exact: true,
  },
  {
    name: 'Create Cliente',
    href: '/clientes/crear/',
    component: <CreateCliente />,
    exact: true,
  },
  {
    name: 'Edit Clientes',
    href: '/clientes/:id',
    component: <EditCliente />,
    exact: true,
  },
];
