import { base64UrlToBase64 } from './base64UrlToBase64';

export const decodeJWT=(jwt)=> {
  const parts = jwt.split('.');

  if (parts.length !== 3) {
    throw new Error('Invalid JWT token.');
  }

  const header = JSON.parse(atob(base64UrlToBase64(parts[0])));
  const payload = JSON.parse(atob(base64UrlToBase64(parts[1])));

  return {
    header,
    payload
  };
}