import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { postItemThunk } from '../lib/default-post-item';
import { asignReducer } from '../lib/shared-reducers';

const apiHOST = apiURL();
const initialState = {
  producto: null,
  proveedor: null,
  ocp: null,
  enCalidadDe: { id: 1, nombre: 'COMPRA', createdBy: 1 },
  usuario: null,
  error: false,
  loading: false,
};

export const insertEntrada = postItemThunk(
  'crearEntrada/insertEntrada',
  `${apiHOST}entradas/`,
  'entradas',
);

const createEntradaSlice = createSlice({
  name: 'createEntrada',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'proveedor':
          state.proveedor = action.payload.value;

          break;

        case 'inventario':
          state.inventario = action.payload.value;
          break;

        case 'divisa':
          state.divisa = action.payload.value;

          break;

        case 'enCalidadDe':
          state.enCalidadDe = action.payload.value;
          break;

        case 'iva':
          console.log('iva siwthc');
          state.iva = action.payload.value;
          break;

        default:
      }
    },
    asignReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(insertEntrada.rejected, (state, action) => {
      console.log('action --------------> insertEntrada Rejected');
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(insertEntrada.fulfilled, (state, action) => {
      console.log('action --------------> insertEntrada Rejected');
      state.enCalidadDe = '';
      state.proveedor = '';
      state.error = '';
      state.loading = false;
    });
    builder.addCase(insertEntrada.pending, (state, action) => {
      console.log('action --------------> insertEntrada pending');
      state.loading = true;
    });
  },
});

export default createEntradaSlice;

export const createEntradaActions = createEntradaSlice.actions;
