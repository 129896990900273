import react from 'react';
const TableHeaderCell = (props) => {
  let textColor = 'text-white';
  let bgColor = '';
  let border = '';
  let textAlign = '';
  props.textColor ? (textColor = props.textColor) : (textColor = 'text-white');
  props.bgColor ? (bgColor = props.bgColor) : (bgColor = '');
  props.border ? (border = 'border') : (border = '');
  props.textAlign ? (textAlign = props.textAlign) : (textAlign = 'text-left');
  return (
    <th
      className={
        props.className
          ? props.className
          : `px-1 pt-2 pb-1 ${textAlign} max-w-prose text-xs font-medium  uppercase tracking-wider ${textColor} ${bgColor} ${border}`
      }
      scope="col"
    >
      {props.children}
    </th>
  );
};
export default TableHeaderCell;
