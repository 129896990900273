
import {
  addProductoRechazoToEntradaRechazo,
  addProductoRechazoToEntradaRechazoAndUpdateMax,

  editEntradaInventarioRechazosActions as sliceActions,
} from '../../store/editEntradaInventarioRechazos-slice';
import React, { useContext, useEffect, useState } from 'react';
import { CreateProductoSalidaRechazo } from './CreateProductoSalidaRechazo';
import { useDispatch } from 'react-redux';
import { GenericModal } from '../UI/Generics';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router-dom';
import IconButton from '../TailwindUI/IconButton';

export const UpdateSalidaInventarioRechazo = ({
  salidaRechazo,
  sliceState,
}) => {
  const [enCalidadDeId, setEnCalidadDeId] = useState(4);
  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const updateSalidaRechazo = (productoSalidaRechazo) => {

    const request = {
      salidaRechazoId: +salidaRechazo.id,
      productoSalidaRechazo,
    };
    console.log('request');
    console.log(request);
    console.log(sliceState);

    //
    dispatch(addProductoRechazoToEntradaRechazoAndUpdateMax({ request, authCtx }));

  };

  const handleProductoSalidaRechazoState = (e) => {
    setShowUpdateForm(false);

    updateSalidaRechazo(e);
  };

  return (
    <React.Fragment>
      {sliceState.cantidadMaximaProductoSalidaRechazo>0&&(
      <IconButton
        onClick={
          () => {
            setShowUpdateForm(true);
            dispatch(
              sliceActions.asignCantidadMaximaProductoSalidaRechazo(
                sliceState));
          }
        }

        color='orangeMely'
        icon='+'
      />
      )}

      {showUpdateForm && (
        <React.Fragment>
          <GenericModal
            title={'Generic Modal'}
            onConfirm={(sliceState) => {
              setShowUpdateForm(false);

            }}
            onCancel={() => {
              setShowUpdateForm(false);

            }}
          >
            <div className='mt-6 mb-2'>
              <h2 className='font-bold uppercase mb-4'>Salida Rechazo</h2>

            </div>
            <CreateProductoSalidaRechazo
              sliceState={sliceState}
              sliceActions={sliceActions}
              dispatchState={handleProductoSalidaRechazoState} />

          </GenericModal>
        </React.Fragment>
      )}

    </React.Fragment>
  );
};

