import React from 'react';
import {
  MobileNavBarHeader
} from '../components/UI/NavBarMobile/MobileNavBarHeader';
import {
  NavBarMobileClose
} from '../components/UI/NavBarMobile/NavBarMobileClose';
import { NavBarMobile } from '../components/UI/NavBarMobile/NavBarMobile';


export const useMobileNavBar = ({navBarSections}) => {
  return {
    header: <MobileNavBarHeader/>,
    closeButton: <NavBarMobileClose />,
    navBar: <NavBarMobile navBarSections={navBarSections} />,
  };

};