import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import * as util from '../lib/edit';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL();

const filterSeleccion = (valuesToFilter, selectedValueId) => {
  const filterdValue = valuesToFilter.filter((item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const initialState = {
  salida: null,
  producto: null,
  cliente: null,
  productosOrdenCompra: null,
  productosSalida: [],
  productToAsign: null,
  productToSearch: null,
  seleccionInventario: [],
  existenciasInventario: null,
};

export const getSalidaIC = util.getDefaultViewRequestThunk(
  'editSalidaIC/getSalidaIC',
  `${apiHOST}views/salidas-inventario-clientes`,
  'formated',
);

//Put Edited Salida
export const putSalidaIC = createAsyncThunk('editSalidaIC/putSalida', async (payload) => {
  const { requestObj, requestUrl, reduxStateTree } = payload;
  try {
    const response = await axios.put(
      `${apiHOST}${requestUrl}/${reduxStateTree.id}`,
      requestObj,
      {},
    );
    return { data: response.data, reduxStateTree };
  } catch (err) {
    return err;
  }
});

export const deleteSalidaIC = util.deleteRequestFromComponentThunk(
  'editSalidaIC/editSalidaIC',
  apiHOST,
  'salidas-inventario-clientes',
);

//Relations

export const getRelation = util.getRequestRelationThunk('editSalidaIC/getRelation', apiHOST);

export const cerrarSalida = createAsyncThunk(
  'editSalidaIC/cerrarSalida',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiHOST}views/salidas-inventario-clientes/cerrar/${payload.salida.id}`,
        payload,
        {
          headers: {
              ...authorizationHeader()          },
        },
      );
      console.log('Cerrar Salida????');
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const postRelationProducto = createAsyncThunk(
  'editSalidaIC/postRelationProducto',
  async (payload) => {
    console.log('editSalidaIC/postRelationProducto');
    console.log(payload);

    const { requestObj, reduxStateTree, selectedValue, asignedListString } = payload;
    console.log(`${apiHOST}productos-salida-inventario-clientes`);
    console.log(JSON.stringify(requestObj));
    console.log(asignedListString);

    try {
      const response = await axios.post(
        `${apiHOST}productos-salida-inventario-clientes`,
        requestObj,
        {
          headers: {
              ...authorizationHeader()          },
        },
      );
      console.log(
        ' ordenesCompraProveedor-producto Insert response.data!!!!!!!!!!!!!!!!!!!!!!!----',
      );
      console.log(response.data);

      console.log('------------------viewResponse !!!----------------');
      console.log(
        `${apiHOST}views/productos-salida-inventario-clientes/${response.data.salidaIcId}`,
      );

      const viewResponse = await axios.get(
        `${apiHOST}views/productos-salida-inventario-clientes/${response.data.salidaIcId}`,
        {
          headers: {
              ...authorizationHeader()          },
        },
      );

      console.log('------------------viewResponse 22222----------------');
      console.log(viewResponse.data);
      return {
        data: viewResponse.data,
        reduxStateTree,
        selectedValue,
        asignedListString,
        requestObj,
      };
    } catch (err) {
      return err;
    }
  },
);
export const deleteRelationProducto = createAsyncThunk(
  'editSalidaIC/deleteRelationProducto',
  async (payload) => {
    console.log('editSalidaIC/deleteRelationProducto>>>>>>');
    console.log(payload);

    const { requestObj, stateTree, asignedListString, asignedListSelector } = payload;
    console.log('requestObj msg!!!!');
    console.log(requestObj);

    try {
      const response = await axios.delete(
        `${apiHOST}productos-salida-inventario-clientes/${requestObj.id}`,

        {
          headers: {
              ...authorizationHeader()          },
        },
      );

      return {
        data: response.data,
        stateTree,
        asignedListString,
        simbolo: requestObj.simbolo,
        asignedListSelector,
      };
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

const editSalidaICSlice = createSlice({
  name: 'editSalidaIC',
  initialState: initialState,
  reducers: {
    change(state, action) {
      // console.log('Reducer----->');
      // console.log(action.payload.field);
      // console.log(action.payload.value);
      switch (action.payload.field) {
        case 'cantidadLetra':
          break;
        case 'fechaEntrega':
          break;
        default:
      }
    },
    select(state, action) {
      console.log(' Edit actions  SELECT action.payload.field--------->>>>');
      console.log(action.payload.field);
      console.log(action.payload.selectedValue);
      console.log(action.payload.values);
      state[action.payload.field] = filterSeleccion(
        action.payload.values,
        action.payload.selectedValue,
      );
      state.editsNotSaved = true;
    },

    asign(state, action) {
      console.log('action.payload');
      console.log(action.payload);
      state[action.payload.reduxStateTree] = filterSeleccion(
        action.payload.valuesToFilter,
        action.payload.selectedValue,
      );
    },
    cancelAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    productToRemoveAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    resetProductosOrdenCompra(state, action) {
      state.productosOrdenCompra = null;
    },
    seleccionInventario(state, action) {
      state.seleccionInventario = action.payload;
    },
    setProductToSearch(state, action) {
      state.productToSearch = action.payload;
    },
    resetProductToSearch(state, action) {
      state.productToSearch = null;
    },
    resetSeleccionInventario(state, action) {
      state.seleccionInventario = null;
    },
    resetExistenciasInventario(state, action) {
      state.existenciasInventario = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalidaIC.fulfilled, (state, action) => {
      const { salida, producto, cliente, pedidoPor } = action?.payload;
      console.log('action.payload---------->');
      console.log(action?.payload);
      console.log('ENTRADA--------');

      state.salida = salida;
      state.producto = producto;
      state.cliente = cliente;
      state.pedidoPor = pedidoPor;
    });
    builder.addCase(deleteSalidaIC.fulfilled, (state, action) => {
      state.editSalidaIC = {
        id: null,
        rfc: null,
        razonSocial: null,
        direccionId: null,
      };

      state.direccionFiscal = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.direccionEntrega = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.relationsProductos = [];
      state.asignedProducts = [];
      state.productToAsign = null;
      state.productToRemoveAsign = null;
    });
    builder.addCase(putSalidaIC.fulfilled, (state, action) => {
      console.log('Console.log Action sss');
      console.log(action);
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });

    builder.addCase(cerrarSalida.fulfilled, (state, action) => {
      state.salida.vigente = action.payload?.vigente;
    });

    builder.addCase(postRelationProducto.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = null;
      console.log('action.payload.data-------?');
      console.log(action.payload.data);

      console.log('action.payload.data-------?');
      console.log(action.payload.asignedListString);

      state[action?.payload?.asignedListString] = [];
      if (Array.isArray(action.payload.data)) {
        action.payload.data.map((i) => state[action?.payload?.asignedListString]?.push(i));
      }
      // else{

      //   state[action?.payload?.asignedListString]=action.payload.data

      // }

      //Set State to Nullstate the product that has been posted.
    });
    builder.addCase(deleteRelationProducto.fulfilled, (state, action) => {
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.id !== action.payload.data.id,
      );
      state[action.payload.asignedListString] = filterdValue;
    });
    builder.addCase(getValuesToAsign.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });
  },
});

export default editSalidaICSlice;

export const editSalidaICActions = editSalidaICSlice.actions;
