import React from 'react';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import { GenericModal } from '../UI/Generics';
import { SelectFetchCombobox } from '../TailwindUI/SelectFetchCombobox';
import { editSalidaActions } from '../../store/editSalida-slice';
import { TableRowHeader } from '../UI/Generics/Table/TableRowHeader';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { Table } from '../UI/Generics/Table/Table';
import { prettifyNumber } from '../../Util/prettifyNumber';
import AsignProductoOCCtoSalida from './AsignProductoOCCtoSalida';
import { classNames } from '../../Util/classNames';

export const AddProductToSalida = ({
  sliceState,
  relationProducto_URL,
  getRelationHandler,
  handleResetProductosOrdenescompra,
  dispatch,
  AsignProductoOCCtoSalida,
  resetExistenciasInventario,

}) => {
  return (<GenericModal
      overlayColor={'bg-orange-400'}
      title={'Generic Modal'}
      meesage={'En la siguiente lista puedes encontrar los productos que exísten dentro de una orden de compra de este proveedor'}
      onConfirm={(sliceState) => {

      }}
      onCancel={() => {
        resetExistenciasInventario();
        handleResetProductosOrdenescompra();

      }}
    >

      <div className={'text-center'}>
        {/* Sí hay existencias en  los dos inventarios */}
        {sliceState?.existenciasInventario?.mx?.length > 0 && (
          <CardButton
            bgColor={"gray"}
            onClick={() => {
              dispatch(
                editSalidaActions.seleccionInventario(
                  sliceState?.existenciasInventario?.mx,
                ),
              );
              dispatch(editSalidaActions.resetProductToSearch());
              handleResetProductosOrdenescompra();
            }}
          >
            Inventario MX
          </CardButton>
        )}
        {sliceState?.existenciasInventario?.usa?.length > 0 && (
          <CardButton
            bgColor={"gray"}
            onClick={() => {
              dispatch(
                editSalidaActions.seleccionInventario(
                  sliceState?.existenciasInventario?.usa,
                ),
              );
              dispatch(editSalidaActions.resetProductToSearch());
              handleResetProductosOrdenescompra();
            }}
          >
            Inventario USA
          </CardButton>
        )}

        {sliceState?.seleccionInventario?.length > 0 && (
          <div className='flex flex-col  items-center justify-center mt-6  p-2'>
            <div className='font-bold mb-2'>SELCCIONA UN PRODUCTO</div>
            <div
              className='-mx-2 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg'>
              <table className={'min-w-full divide-y divide-gray-300 '}>
                <thead className={'bg-darkBlueLight text-white'}>
                <tr>
                  <TableRowHeader className={"px-3 py-3.5 font-semibold lg:table-cell text-center"}>
                    PRODUCTO
                  </TableRowHeader>
                  <TableRowHeader  className={"px-3 py-3.5 font-semibold lg:table-cell text-center"}>
                    INVENTARIO
                  </TableRowHeader>


                </tr>
                </thead>
                <tbody>
                {sliceState?.seleccionInventario.map((i, idx) => (
                  <tr>
                    {/* {i.productoId} */}
                    <td
                      className={classNames(
                        idx === 0 ? '' : 'border-t border-gray-200',
                        'relative py-4 pl-4 pr-3 text-sm sm:pl-6',
                      )}
                    >
                      <button
                        className='text-orangeMely font-medium hover:font-bold'
                        onClick={() => {
                          dispatch(
                            editSalidaActions.setProductToSearch({
                              id: i.productoId,
                              simbolo: i.simbolo,
                              cantidad: i.total,
                            }),
                          );
                        }}
                      >
                        {i.simbolo}{' '}
                      </button>
                    </td>

                    <td
                      className={classNames(
                        idx === 0 ? '' : 'border-t border-gray-200',
                        'px-3 py-3.5 text-sm  font-medium text-gray-600',
                      )}
                    >
                      {prettifyNumber(String(i.total))}
                    </td>

                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* {sliceState?.existenciasInventario?.mx?.length > 0
            ? sliceState?.existenciasInventario?.mx.map((i) => (
                <div>{i.entradaSimbolo + i.cantidadTotal}</div>
              ))
            : 'No Hay Registros MX'} */}


        {!sliceState.productosOrdenCompra && sliceState?.productToSearch && (
          <CardButton
            onClick={() =>
              getRelationHandler(relationProducto_URL, 'productosOrdenCompra')
            }
          >
            {`Ver Ordenes de Compra que contienen 
                            ${sliceState?.productToSearch?.simbolo}`}
          </CardButton>
        )}

        {sliceState.productosOrdenCompra && (
          <div className='bg-gray-100  mt-10 pb-10 pt-10  text-center'>
            {sliceState.productosOrdenCompra.length === 0 && (
              <div>
                No se encontraron odenes de compra vigentes para ese producto
              </div>
            )}

            {sliceState.productosOrdenCompra.length > 0 &&
              AsignProductoOCCtoSalida}
          </div>
        )}
      </div>
    </GenericModal>
  );

};