import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../../Util/apiURL';
import { postItemThunk } from '../../lib/default-post-item';
import {
  insertItemFullfilled,
  insertItemPending,
  insertItemRejected,
} from '../../lib/default-post-item';

import { selectReducer, asignReducer } from '../../lib/shared-reducers';

const apiHOST = apiURL();
const initialState = {
  simbolo: null,
  categoria: null,
  divisa: null,
  especificacionesProducto: {
    ect: null,
    flauta: null,
    papeles: null,
    tratamientos: null,
    tintas: null,
  },
  dimensionesLamina: {
    largo: null,
    ancho: null,
  },
  medidasInternasCaja: {
    largo: null,
    ancho: null,
    alto: null,
  },
  costoAdquisicion: null,
  precioVenta: null,
  error: false,
  loading: false,
  success: null,
};

export const insertProducto = postItemThunk(
  'producto/insertProducto',
  `${apiHOST}views/productos/crear/`,
  'productos',
);

const createProductoSlice = createSlice({
  name: 'createProducto',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'simbolo':
          state.simbolo = action.payload.value.toUpperCase();
          break;
        case 'especificacionesProducto.ect':
          state.especificacionesProducto.ect = action.payload.value.toUpperCase();
          break;
        case 'especificacionesProducto.flauta':
          state.especificacionesProducto.flauta = action.payload.value.toUpperCase();
          break;
        case 'especificacionesProducto.papeles':
          state.especificacionesProducto.papeles = action.payload.value.toUpperCase();
          break;
        case 'especificacionesProducto.tratamientos':
          state.especificacionesProducto.tratamientos = action.payload.value.toUpperCase();
          break;
        case 'especificacionesProducto.tintas':
          state.especificacionesProducto.tintas = action.payload.value.toUpperCase();
          break;
        case 'dimensionesLamina.largo':
          state.dimensionesLamina.largo = action.payload.value;
          break;
        case 'dimensionesLamina.ancho':
          state.dimensionesLamina.ancho = action.payload.value;
          break;
        case 'medidasInternasCaja.largo':
          state.medidasInternasCaja.largo = action.payload.value;
          break;
        case 'medidasInternasCaja.ancho':
          state.medidasInternasCaja.ancho = action.payload.value;
          break;
        case 'medidasInternasCaja.alto':
          state.medidasInternasCaja.alto = action.payload.value;
          break;
        case 'costoAdquisicion':
          state.costoAdquisicion = action.payload.value;
          break;
        case 'precioVenta':
          state.precioVenta = action.payload.value;
          break;
        default:
      }
    },
    selectReducer,
    asignReducer,
  },
  extraReducers: builder => {
    builder.addCase(insertProducto.fulfilled, insertItemFullfilled(initialState));
    builder.addCase(insertProducto.pending, insertItemPending);
    builder.addCase(insertProducto.rejected, insertItemRejected);
  },
});

export default createProductoSlice;

export const createProductoActions = createProductoSlice.actions;
