import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiURL } from '../Util/apiURL';
import { getCliente } from './editarCliente-slice';

const apiHOST = apiURL();
const initialState = {
  message: null,
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {},
  extraReducers: {
    // [getCliente.fulfilled]: (state, action) => {
    //   state.message = null;
    // },
    // [getCliente.pending]: (state, action) => {
    //   state.message = 'loading';
    // },
  },
});

export default messagesSlice;

export const messagesActions = messagesSlice.actions;
