import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProducto,
  putJoinedTable,
  putProducto,
  deleteProducto,
  getRelation,
  editProductoActions,
} from './editarProducto-slice';
import JoinedTable from '../SharedUi/JoinedTable';
import ModelInputsAndSelectors from './ModelInputsAndSelectors';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import { InputCornerHint } from '../SharedUi/Inputs';
import DeleteModal from '../UI/Modals/DeleteModal';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';
import { BooleanHelper } from '../BooleanHelper';
import { FileInput } from '../TailwindUI/FileInput';
import SelectHelperV2 from '../TailwindUI/SelectHelperV2';
import { objectToOption } from '../../Util/object-to-option';
import { authorizationHeader } from '../../Util/authorizationHeader';
import { apiURL_Nest } from '../../Util/apiURL';
import { asingDispatcher } from '../../lib/sharedDispatchers';
import { createOCCActions as sliceActions } from '../../store/crearOCC-slice';
import { UploadComponent } from '../UploadComponent/UploadComponent';
import { useUpload } from '../UploadComponent/useUpload';
import { useUploadPreview } from '../UploadComponent/useUploadPreview';
import UploadsComponent from '../UploadComponent/UploadsComponent';

const EditProducto = () => {
  const fetchAllOptions = useSelector((state) => state.fetchAllOptions);
  const handleAsingProducto = (event) => asingDispatcher(
    { dispatch, sliceActions, event });
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const sliceState = useSelector((state) => state.editProducto);
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const editProductState = useSelector((state) => state.editProducto);
  const urlParam = location.pathname.split('/')[2];

  const [archivoADescargar, setArchivoADescargar] = useState(null);
  const requestUrl = 'productos';
  const productoStateTree = editProductState?.producto;
  const productosPrecios_URL = `views/productos/precios/${editProductState?.producto?.id}`;
  const productosCostos_URL = `views/productos/costos/${editProductState?.producto?.id}`;

  const s3Bucket = 'melypackaging.public';
  const keyPrefix = `producto/${urlParam}`;

  const upload = useUpload(s3Bucket, keyPrefix);
  const uploadPreview = useUploadPreview();

  useEffect(() => {
    dispatch(getProducto({ urlParam, authCtx }));
  }, []);

  const getRelationHandler = (requestUrl, reduxStateTree) => {
    dispatch(
      getRelation({
        requestUrl,
        reduxStateTree,
        authCtx,
      }),
    );
  };

  const handlePutProducto = () => {
    const requestObj = {
      id: editProductState?.producto.id,
      simbolo: editProductState?.producto.simbolo,
      isDisabled: editProductState?.producto.deshabilitado,
    };

    dispatch(
      putProducto({
        requestObj,
        requestUrl,
        reduxStateTree: productoStateTree,
        history,
        authCtx,
      }),
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteProducto({
        requestUrl,
        reduxStateTree: productoStateTree,
        history,
        authCtx,
      }),
    );
  };

  const saveEdit = (editObject, value) => {
    console.log('PUT to REST------------?');
    console.log(editObject);
    console.log(value);
    const request = editObject;

    console.log(request);
  };

  const handleUpdatePrice = (requestObj, url, stateTree) => {
    let requestUrl = `${url}/${productoStateTree.id}`;
    console.log(requestUrl);
    console.log(requestObj);
    console.log(stateTree);
    dispatch(putJoinedTable({ requestObj, requestUrl, stateTree, authCtx }));
  };

  const renderActual = (object, id, title, url, prefixSelector, stateTree) => {
    let reset = false;

    if (object) {
      return (
        <CardContainer>
          <CardTitle>{title}</CardTitle>
          <React.Fragment>
            {Object.entries(object).map((item) => {
              // console.log('prefixSelector')
              // console.log(prefixSelector)
              return (
                <ModelInputsAndSelectors
                  label={item[0]}
                  selectorName={`${prefixSelector}${item[0]}`}
                  defaultValue={item[1]}
                  handleEdit={saveEdit}
                  reset={reset}
                />
              );
            })}
          </React.Fragment>
          <CardButton
            onClick={() => {
              handleUpdatePrice(object, url, stateTree);
            }}
          >
            Guardar
          </CardButton>
        </CardContainer>
      );
    }
  };

  return (
    <>
      <div className='flex-1 flex flex-col overflow-hidden'>
        {/* Main content */}
        <FlexContainer className='flex-1 items-stretch  overflow-hidden'>
          <main className='flex-1 overflow-y-auto'>
            {/* Primary column */}

            {/*<div>{JSON.stringify(sliceState)}</div>*/}
            <FlexContainer className='flex-col'>
              <div className='flex flex-wrap ' style={{ padding: '1% 10%' }}>
                <CardContainer>
                  <CardTitle>Producto</CardTitle>
                  <ModelInputsAndSelectors selectorName={'simbolo'}
                                           label={'simbolo'} />


                  <InputCornerHint
                    label={'Divisa'}
                    value={editProductState?.divisa?.nombre}
                    disabled
                  />
                  <BooleanHelper label={'deshabilitar'}
                                 onChange={(e) => {
                                   dispatch(editProductoActions.change({
                                     field: 'deshabilitado',
                                     value: e,
                                   }));
                                 }}
                                 value={editProductState?.producto?.deshabilitado} />

                  <CardButton onClick={handlePutProducto}>Guardar</CardButton>
                </CardContainer>

                <JoinedTable
                  modelInputsAndSelectors={ModelInputsAndSelectors}
                  putJoinedTableThunk={putJoinedTable}
                  object={sliceState.especificacionesProducto}
                  id={sliceState.id}
                  title={'Especificaciones'}
                  url={'especificaciones-producto'}
                  prefixSelector={'e_'}
                  stateTree={'especificacionesProducto'}
                />
                <JoinedTable
                  modelInputsAndSelectors={ModelInputsAndSelectors}
                  putJoinedTableThunk={putJoinedTable}
                  object={sliceState.dimensionesLamina}
                  id={sliceState.id}
                  title={'Dimensiones Lamina'}
                  url={'dimensiones-laminas'}
                  prefixSelector={'dl_'}
                  stateTree={'dimensionesLamina'}
                />
                <JoinedTable
                  putJoinedTableThunk={putJoinedTable}
                  modelInputsAndSelectors={ModelInputsAndSelectors}
                  object={sliceState.medidasInternasCaja}
                  id={sliceState.id}
                  title={'Medidas Internas Cajas'}
                  url={'medidas-internas-cajas'}
                  prefixSelector={'mic_'}
                  stateTree={'medidasInternasCaja'}
                />


                {renderActual(
                  {
                    costoAdquisicionActual: sliceState?.costoAdquisicion,
                  },
                  sliceState.id,
                  'Costos Adquisición Actual',
                  'views/productos/nuevo-costo-adquisicion',
                  '',
                  'costoAdquisicion',
                )}

                <CardContainer>
                  <CardTitle>Costos De Adquisición</CardTitle>
                  <CardButton
                    onClick={() => getRelationHandler(productosCostos_URL,
                      'costos')}>
                    Obtener Costos
                  </CardButton>
                  {editProductState?.costos.map((i) => (
                    <div className='mt-4'>
                      <span>Creado en : {i?.creadoEn?.split('T')[0]}</span>
                      <span className='text-bold ml-2'>${i?.monto}</span>
                    </div>
                  ))}
                </CardContainer>

                {renderActual(
                  {
                    precioVentaActual: editProductState?.precioVenta,
                  },
                  sliceState.id,
                  'Precio Venta Actual',
                  'views/productos/nuevo-precio-venta',
                  '',
                  'precioVenta',
                )}

                {/*<UploadComponent / >*/}


                <CardContainer>
                  <CardTitle>Precios De Venta</CardTitle>
                  <CardButton
                    onClick={() => getRelationHandler(productosPrecios_URL,
                      'precios')}>
                    Obtener Precios
                  </CardButton>
                  {editProductState?.precios.map((i) => (
                    <div className='mt-4'>
                      <span>Creado en : {i?.creadoEn?.split('T')[0]}</span>
                      <span className='text-bold ml-2'>${i?.monto}</span>
                    </div>
                  ))}
                </CardContainer>

                <CardContainer>
                  <CardTitle>Añadir Archivo</CardTitle>

                  <FileInput

                    preview={uploadPreview.preview}
                    setPreview={uploadPreview.setPreview}
                    changeHandler={(event) => {
                      const file = event.target.files[0];
                      uploadPreview.usePreviewHandler(file);
                      const params = upload.changeHandler({
                          file: file,
                          bucket: s3Bucket,
                          prefix: keyPrefix,
                          tags: {
                            keyPrefix,
                          },
                        },
                      );
                      console.log("params-->",params)
                      upload.setUploadParams(params);
                    }
                    }
                    submitHandler={async (e) => {
                      e.preventDefault();
                      await upload.submitHandler( );
                      window.location.reload()
                    }

                    }


                  />

                </CardContainer>

                <CardContainer>
                  <UploadsComponent
                    routePath={`uploads?tags=keyPrefix:${keyPrefix}`}
                    modelName={`print_card_categoryId=${urlParam}`}
                    label='Archivos'
                    api={'nest'}
                    formkikValue={archivoADescargar}

                    transformer={data => data}

                    onChange={(e) => {
                      console.log(e.target.value);
                      setArchivoADescargar(e.target.value);

                      handleAsingProducto({
                        reduxStateTree: `print_card_categoryId=${urlParam}`,
                        valuesToFilter: fetchAllOptions[`print_card_categoryId=${urlParam}`],
                        selectedValue: e.target.value,
                        authCtx,
                      });

                    }}
                  />

                </CardContainer>


                {deleteModalIsVisible && (
                  <DeleteModal
                    title={'Borrar Producto'}
                    item={`${editProductState?.producto?.simbolo}`}
                    onConfirm={() => {
                      console.log('click');

                      handleDelete();

                      setDeleteModalIsVisible(false);
                    }}
                    onCancel={() => setDeleteModalIsVisible(false)}
                  >
                    Esta Acción no puede desacerse
                  </DeleteModal>
                )}
                <CardContainer>
                  <CardButton bgColor='red'
                              onClick={() => setDeleteModalIsVisible(true)}>
                    Borrar Producto
                  </CardButton>

                </CardContainer>
              </div>
              <LifeCycleFeedback
                erraseSucces={() => {
                  dispatch(editProductoActions.resetSucces());
                }}
                erraseError={() => {
                  dispatch(editProductoActions.resetError());
                }}
                sliceState={sliceState}
              />
            </FlexContainer>
          </main>
        </FlexContainer>
      </div>
    </>
  );
};
export default EditProducto;
