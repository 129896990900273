import { useState } from 'react';
import axios from 'axios';
import { authorizationHeader } from '../../Util/authorizationHeader';
import { apiURL_Nest } from '../../Util/apiURL';
import { object } from 'yup';

export const useUploadPreview = () => {
  const [preview, setPreview] = useState(null);

  const usePreviewHandler = (file) => {
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);

  };

  return {
   usePreviewHandler,
    preview,
    setPreview
  };
};

