const unDigito = (number) => {
  let obj = {
    0: '',
    1: 'un',
    2: 'dos',
    3: 'tres',
    4: 'cuatro',
    5: 'cinco',
    6: 'seis',
    7: 'siete',
    8: 'ocho',
    9: 'nueve',
  };
  return obj[number];
};
const dosDigitos = (number) => {
  let cardinales = {
    1: 'un',
    2: 'dos',
    3: 'tres',
    4: 'cuatro',
    5: 'cinco',
    6: 'seis',
    7: 'siete',
    8: 'ocho',
    9: 'nueve',
    10: 'diez',
    11: 'once',
    12: 'doce',
    13: 'trece',
    14: 'catorce',
    15: 'quince',
    16: 'dieciséis',
    17: 'diecisiete',
    18: 'dieciocho',
    19: 'diecinueve',
    20: 'veinte',
    21: 'veintiuno',
    22: 'veintidós',
    23: 'veintitrés',
    24: 'veinticuatro',
    25: 'veinticinco',
    26: 'veintiséis',
    27: 'veintisiete',
    28: 'veintiocho',
    29: 'veintinueve',
  };
  let decenas = {
    3: 'treinta',
    4: 'cuarenta',
    5: 'cincuenta',
    6: 'sesenta',
    7: 'setenta',
    8: 'ochenta',
    9: 'noventa',
  };
  if (number < 30) {
    if (number.toString()[0] !== '0') {
      return cardinales[number];
    }
    return cardinales[number.toString()[1]];
  } else if (number >= 30) {
    // console.log('Number is mayor or equal to 30');
    // console.log(number.toString()[1]);
    let primerDigito;
    let segundoDigito;
    if (number.toString()[1] === '0') {
      return decenas[number.toString()[0]];
    }
    return `${decenas[number.toString()[0]]} y ${unDigito(
      number.toString()[1]
    )}`;
  }
};
const tresDigitos = (number, ciento = true) => {
  let centenas = {
    0: '',
    1: 'cien',
    2: 'doscientos',
    3: 'trescientos',
    4: 'cuartrocientos',
    5: 'quienientos',
    6: 'seiscientos',
    7: 'setecientos',
    8: 'ochocientos',
    9: 'novecientos',
  };

  if (number.toString()[1] === '0' && number.toString()[2] === '0') {
    return `${centenas[number.toString()[0]]}`;
  }
  if (number.toString()[0] === '1') {
    // console.log('!!!TEST');
    // console.log(number.toString()[1] + number.toString()[2]);
    let tests = '01';
    // console.log(dosDigitos(tests));
    return `${centenas[number.toString()[0]]}${ciento ? 'to' : ''} ${dosDigitos(
      number.toString()[1] + number.toString()[2]
    )}`;
  }

  return `${centenas[number.toString()[0]]} ${dosDigitos(
    number.toString()[1] + number.toString()[2]
  )}`;
};

const cuatroDigitos = (number) => {
  let textNum = number.toString();
  if (number.toString()[0] === '1') {
    return `mil ${numberToText(textNum[1] + textNum[2] + textNum[3])} `;
  }
  return ` ${unDigito(textNum[0])}mil ${numberToText(
    textNum[1] + textNum[2] + textNum[3]
  )} `;
};
const cincoDigitos = (number) => {
  let textNum = number.toString();

  return `${dosDigitos(textNum[0] + textNum[1])}mil ${numberToText(
    textNum[2] + textNum[3] + textNum[4]
  )}`;
};
const seisDigitos = (number) => {
  let textNum = number.toString();
  if (textNum[0] === '1') {
    return `${tresDigitos(
      textNum[0] + textNum[1] + textNum[2]
    )} mil ${numberToText(textNum[3] + textNum[4] + textNum[5])}`;
  }

  return `${tresDigitos(
    textNum[0] + textNum[1] + textNum[2],
    false
  )} mil ${numberToText(textNum[3] + textNum[4] + textNum[5])}`;
};

const sieteDigitos = (number) => {
  let textNum = number.toString();
  let sixDigits = [...textNum];
  // console.log('textNum[0]---?');
  // console.log(textNum[0]);
  sixDigits.shift();
  // console.log('sieteDigitos');
  // console.log('textNum');
  // console.log(textNum);
  // console.log('sixDigits');
  // console.log(sixDigits);
  // console.log(sixDigits.join(''));
  let millones = 'millones';

  // console.log('textNum[0]---?');
  // console.log(textNum[0]);

  if (textNum[0] === '1') {
    // console.log('textNum[0]!!!!!!');
    millones = 'millon';
  }

  if (
    textNum[1] === '0' &&
    textNum[2] === '0' &&
    textNum[3] === '0' &&
    textNum[4] === '0' &&
    textNum[5] === '0' &&
    textNum[6] === '0'
  ) {
    if (textNum[0] === '1') {
      // console.log('textNum[0]!!!!!!');
      // millones = 'un millon';
      return `${millones}`;
    }

    return `${unDigito(textNum[0])} ${millones}`;
  }
  return `${unDigito(textNum[0])} ${millones} ${setMillones(
    sixDigits.join('')
  )}`;
};
const setMillones = (number) => {
  let textNum = number.toString();
  let millones = 'millones';

  if (
    textNum[0] === '0' &&
    textNum[1] === '0' &&
    textNum[2] === '0' &&
    textNum[3] === '0' &&
    textNum[4] === '0' &&
    textNum[5] === '0'
  ) {
    return '';
  }
  if (
    textNum[0] === '0' &&
    textNum[1] === '0' &&
    textNum[2] === '0' &&
    textNum[3] === '0' &&
    textNum[4] === '0'
  ) {
    return `${unDigito(textNum[0])} ${millones} ${
      textNum[5] === '1' ? 'un' : unDigito(textNum[5])
    }`;
  }
  if (
    textNum[0] === '0' &&
    textNum[1] === '0' &&
    textNum[2] === '0' &&
    textNum[3] === '0'
  ) {
    return `${numberToText(textNum[4] + textNum[5])}`;
  }
  if (textNum[0] === '0' && textNum[1] === '0' && textNum[2] === '0') {
    return `${numberToText(textNum[3] + textNum[4] + textNum[5])}`;
  }
  if (textNum[0] === '0' && textNum[1] === '0') {
    return `${numberToText(textNum[2] + textNum[3] + textNum[4] + textNum[5])}`;
  }
  if (textNum[0] === '0') {
    return `${numberToText(
      textNum[1] + textNum[2] + textNum[3] + textNum[4] + textNum[5]
    )}`;
  }

  return `${numberToText(number)}`;
};

const ochoDigitos = (number) => {
  // console.log('OCHO DIGIYOS');
  // console.log(number);
  let textNum = number.toString();
  let sixDigits = [...textNum];
  let millones = 'millones';
  let vartest = sixDigits.splice(2, 6).join('');

  // console.log('Number(textNum[0] + textNum[1])');
  // console.log(Number(textNum[0] + textNum[1]));
  // console.log('vartest!!!!!!');
  // console.log(vartest);
  return `${dosDigitos(textNum[0] + textNum[1])} ${millones} ${setMillones(
    vartest
  )}`;
};
const nueveDigitos = (number) => {
  // console.log('Nueve DIGIYOS');
  // console.log(number);
  let textNum = number.toString();
  let sixDigits = [...textNum];
  let millones = 'millones';
  let vartest = sixDigits.splice(3, 6).join('');
  // console.log('vartest!!!!!!');
  // console.log(vartest);

  return `${tresDigitos(
    textNum[0] + textNum[1] + textNum[2]
  )} ${millones} ${setMillones(vartest)}`;
};
const numberToText = (number) => {
  // console.log('number to text');
  // console.log(number);
  if (number === 0) {
    return 'cero';
  }
  let roundNumber = Math.round(number);
  // console.log('roundNumber');
  // console.log(roundNumber);
  let textNum = roundNumber.toString();

  switch (textNum.length) {
    case 1:
      return unDigito(roundNumber);
    case 2:
      return dosDigitos(roundNumber);
    case 3:
      return tresDigitos(roundNumber);
    case 4:
      return cuatroDigitos(roundNumber);
    case 5:
      return cincoDigitos(roundNumber);
    case 6:
      return seisDigitos(roundNumber);
    case 7:
      return sieteDigitos(roundNumber);
    case 8:
      return ochoDigitos(roundNumber);
    case 9:
      return nueveDigitos(roundNumber);
    default:
  }
};

export default numberToText;
