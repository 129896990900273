import { createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../Util/apiURL';
import { postItemThunk } from '../lib/default-post-item';
import {
  insertItemFullfilled,
  insertItemRejected,
  insertItemPending,
} from '../lib/default-post-item';

const apiHOST = apiURL_Nest()
const initialState = {
  razonSocial: '',
  rfc: '',
  correoElectronico:'',
  direccionFiscal: {
    numero: '',
    calle: '',
    ciudad: '',
    colonia: '',
    estado: '',
    cp: '',
    pais: '',
    tipo:'fiscal_cliente'
  },
  direccionEntrega: {
    numero: '',
    calle: '',
    ciudad: '',
    colonia: '',
    estado: '',
    cp: '',
    pais: '',
    tipo:'entrega_cliente'
  },
  diasCredito: '',
  error: false,
  loading: false,
  success: null,
};

export const insertCliente = postItemThunk(
  'cliente/insertCliente',
  `${apiHOST}clientes/`,
  'clientes',
);

const createClienteSlice = createSlice({
  name: 'createCliente',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'razonSocial':
          state.razonSocial = action.payload.value.toString().toUpperCase();
          break;
        case 'correoElectronico':
          state.correoElectronico = action.payload.value.toString().toUpperCase();
          break;
        case 'diasCredito':
          state.diasCredito = +action.payload.value;
          break;
        case 'rfc':
          state.rfc = action.payload.value.toString().toUpperCase();
          break;
        case 'direccionFiscal.numero':
          state.direccionFiscal.numero = action.payload.value;
          break;

        case 'direccionFiscal.calle':
          state.direccionFiscal.calle = action.payload.value.toString().toUpperCase();
          break;

        case 'direccionFiscal.ciudad':
          state.direccionFiscal.ciudad = action.payload.value.toString().toUpperCase();
          break;
        case 'direccionFiscal.estado':
          state.direccionFiscal.estado = action.payload.value.toString().toUpperCase();
          break;
        case 'direccionFiscal.colonia':
          state.direccionFiscal.colonia = action.payload.value.toString().toUpperCase();
          break;
        case 'direccionFiscal.cp':
          state.direccionFiscal.cp = +action.payload.value;
          break;
        case 'direccionFiscal.pais':
          state.direccionFiscal.pais = action.payload.value.toString().toUpperCase();
          break;
        case 'direccionEntrega.numero':
          state.direccionEntrega.numero = action.payload.value;
          break;

        case 'direccionEntrega.calle':
          state.direccionEntrega.calle = action.payload.value.toString().toUpperCase();
          break;

        case 'direccionEntrega.ciudad':
          state.direccionEntrega.ciudad = action.payload.value.toString().toUpperCase();
          break;
        case 'direccionEntrega.colonia':
          state.direccionEntrega.colonia = action.payload.value.toString().toUpperCase();
          break;
        case 'direccionEntrega.estado':
          state.direccionEntrega.estado = action.payload.value.toString().toUpperCase();
          break;
        case 'direccionEntrega.cp':
          state.direccionEntrega.cp = +action.payload.value;
          break;
        case 'direccionEntrega.pais':
          state.direccionEntrega.pais = action.payload.value.toString().toUpperCase();
          break;

        default:
      }
    },
    pasteDatosEntrega(state, action) {
      const { numero, calle, ciudad, colonia, estado, cp, pais } = action.payload.value;

      state.direccionEntrega.numero = numero;
      state.direccionEntrega.calle = calle;
      state.direccionEntrega.ciudad = ciudad;
      state.direccionEntrega.colonia = colonia;
      state.direccionEntrega.estado = estado;
      state.direccionEntrega.cp = cp;
      state.direccionEntrega.pais = pais;
    },
  },
  extraReducers: builder => {
    builder.addCase(insertCliente.fulfilled, insertItemFullfilled(initialState));
    builder.addCase(insertCliente.pending, insertItemPending);
    builder.addCase(insertCliente.rejected, insertItemRejected);
  },
});

export default createClienteSlice;

export const createClienteActions = createClienteSlice.actions;
