import Signup from '../SignUp/SignUp';
import CreateUsuario from '../Usuarios/CreateUsuario';
import Usuarios from '../Usuarios/Usuarios';
import { DeleteUsuario } from '../Usuarios/DeleteUsuario';
import { UpdateUsuario } from '../Usuarios/UpdateUsuario';
import { Dashboard } from '../Dashboard/Dashboard';

export const configuracionRoutes = [

  {
    name: 'usuarios',
    href: '/configuracion/usuarios',
    component: <Usuarios />,
    exact: true,
  },
  {
    name: 'usuarios',
    href: '/configuracion/usuarios/crear/',
    component: <CreateUsuario />,
    exact: true,
  },
  {
    name: 'usuarios',
    href: '/configuracion/usuarios/:id/borrar',
    component: <DeleteUsuario />,
    exact: true,
  },
  {
    name: 'usuarios',
    href: '/configuracion/usuarios/:id/editar',
    component: <UpdateUsuario />,
    exact: true,
  },

  {
    name: 'usuarios',
    href: '/configuracion/dashboard',
    component: <Dashboard />,
    exact: true,
  },


];
