import React, { useEffect, useContext } from 'react';
import InnerHeader from '../InnerHeader/InnerHeader';
import AuthContext from '../../store/auth-context';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCellAuto from '../TailwindUI/TableHeaderCellAuto';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useSelector, useDispatch } from 'react-redux';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';

export const DefatultItemsList = ({
  sliceState,
  sliceActions,
  getItems,
  customDisplayMapFn,
  disableFilter,
  tableHeaderMapFn,
  tableBodyMapFn,
  defaultFilterCondition,
  routePath
}) => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const state = useSelector((state) => state[sliceState]);
  const itemsRaw = state?.raw;
  const itemsDisplay = state?.display;
  const orderByOptionsState = state?.orderByOptions;
  const sortAscending = state?.sortAscending;
  const productFilterSearchValue = state?.filterSearchValue;

  let itemsDisplayMapFn;
  !customDisplayMapFn
    ? (itemsDisplayMapFn = (item, personIdx) => {
        const mappedItem = tableBodyMapFn ? tableBodyMapFn(item) : item;
        return (
          <React.Fragment  key={JSON.stringify(item)+personIdx}>
            <TableRow
              key={mappedItem.id}
              className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
            >
              <TableBodyCellAuto list={mappedItem} />
            </TableRow>
          </React.Fragment>
        );
      })
    : (itemsDisplayMapFn = customDisplayMapFn);

  useEffect(() => {
    dispatch(sliceActions.resetFilter());
    dispatch(getItems({ routePath,authCtx }));
  }, []);

  const handleNameFilter = (e) => {
    dispatch(sliceActions.filterBy({ raw: itemsRaw, value: e.target.value }));
  };
  const handleResetSearchFilter = (e) => {
    dispatch(sliceActions.resetFilter());
  };

  const handleOrderby = (e) => {
    dispatch(sliceActions.orderBy(e));
  };
  const handleSortBy = (e) => {
    dispatch(sliceActions.sortAscending(sortAscending));
  };

  const handleDisplayAll = () => {
    if (sliceActions.shouldDisplayAll) {
      dispatch(sliceActions.shouldDisplayAll(!state.displayAll));
    }
    return;
  };

  const defaultFilter = (arr) => {

    if (!defaultFilterCondition) return arr;
    if (state.displayAll) return arr;

    const { key, value } = defaultFilterCondition;
    //Todo -> create a Strategy class to be able to configure this conditions

    return arr.filter((i) => i[key] === value);
  };
  return (
    <>

      <div className="flex-1 flex flex-col overflow-hidden">
        <div className={`bg-yellow-500 max-w-full py-2`}></div>
        {/* {JSON.stringify(itemsRaw)} */}
        <InnerHeader
          disableFilter={disableFilter}
          placeholder={'Buscar Productos'}
          handleSearchFilter={handleNameFilter}
          searchFilter={productFilterSearchValue}
          resetSearchFilter={handleResetSearchFilter}
          hideCreateButton={true}
          handleOrderby={handleOrderby}
          orderByOptions={orderByOptionsState}
          handleSortBy={handleSortBy}
          sortAscending={sortAscending}
          state={state}
          handleDisplayAll={handleDisplayAll}
        />

        {/* Main content */}
        <FlexContainer className="flex-1 items-stretch  overflow-hidden ">
          <main className="flex-1 overflow-y-auto  ">
            {/* Primary column */}

            <FlexContainer className="flex-col   bg-gray-100 py-2">
              <div className="shadow w-auto border-b border-gray-200  rounded-t rounded-b-lg bg-white  m-2 pb-3  overflow-x-auto  ">
                <Table>
                  <TableHeader>
                    <TableRowHeader>
                      <TableHeaderCellAuto
                        list={
                          !tableHeaderMapFn
                            ? defaultFilter(itemsDisplay)
                            : defaultFilter(itemsDisplay)?.map(tableHeaderMapFn)
                        }
                      />
                    </TableRowHeader>
                  </TableHeader>
                  <TableBody>
                    {console.log(itemsDisplay)}
                    {defaultFilter(itemsDisplay)?.map(itemsDisplayMapFn)}

                    <TableRow>
                      <TableBodyCell colspan={'5'} className="text-right"></TableBodyCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </FlexContainer>
          </main>

          <LifeCycleFeedback sliceState={state} />
        </FlexContainer>
      </div>
    </>
  );
};
