import React, { useEffect, useContext } from 'react';
import InnerHeader from '../InnerHeader/InnerHeader';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useSelector, useDispatch } from 'react-redux';
import { getInventario, inventarioActions } from '../../store/inventario-slice';
import InventarioTabla from './InventarioTable';
import InventarioClientesTable from './InventarioClientesTable';
import InventarioRechazosTable from './InventarioRechazosTable';
import { BooleanHelper } from '../BooleanHelper';

const Inventario = () => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const inventario = useSelector((state) => state.inventario);
  const displayAll = useSelector((state) => state.inventario.displayAll);
  const inventarioMelyMx = useSelector((state) => state.inventario.display.inventarioMelyMx);
  const inventarioMelyUsa = useSelector((state) => state.inventario.display.inventarioMelyUsa);
  const inventarioClientes = useSelector((state) => state.inventario.display.inventarioClientes);
  const inventarioRechazos = useSelector((state) => state.inventario.display.inventarioRechazos);
  const orderByOptionsState = useSelector((state) => state.inventario?.orderByOptions);
  const sortAscending = useSelector((state) => state.inventario.sortAscending);

  const productFilterSearchValue = useSelector((state) => state.inventario.filterSearchValue);

  useEffect(() => {
    dispatch(inventarioActions.resetFilter());
    dispatch(getInventario({ authCtx }));
  }, []);

  const handleNameFilter = (e) => {
    dispatch(inventarioActions.filterBy({ raw: inventario, value: e.target.value }));
  };
  const handleResetSearchFilter = (e) => {
    dispatch(inventarioActions.resetFilter());
  };

  const handleOrderby = (e) => {
    dispatch(inventarioActions.orderBy(e));
  };
  const handleSortBy = (e) => {
    dispatch(inventarioActions.sortAscending(sortAscending));
  };

  const divideArray = (array, chunk = 1) => {
    return array?.reduce((all, one, i) => {
      const ch = Math.floor(i / chunk);
      all[ch] = [].concat(all[ch] || [], one);
      return all;
    }, []);
  };
  const handleDisplayAll = () => {
    dispatch(inventarioActions.shouldDisplayAll(!displayAll));
  };

  return (
    <>

      <div className="flex-1 flex flex-col overflow-hidden">
        {/* <InnerHeader
          placeholder={'Buscar Inventario'}
          handleSearchFilter={handleNameFilter}
          searchFilter={productFilterSearchValue}
          resetSearchFilter={handleResetSearchFilter}
          hideCreateButton={true}
          handleOrderby={handleOrderby}
          orderByOptions={orderByOptionsState}
          handleSortBy={handleSortBy}
          sortAscending={sortAscending}
        /> */}

        {/* Main content */}
        <FlexContainer className="flex-1 items-stretch  overflow-hidden ">
          <main className="flex-1 overflow-y-auto  ">
            {/* Primary column */}

            <FlexContainer className="flex-col   bg-gray-100 py-2">
              <div>
                {BooleanHelper({
                  label: 'Mostrar Todos',
                  value: displayAll,
                  onChange: handleDisplayAll,
                })}
              </div>
              <div className="shadow w-auto border-b border-gray-200  rounded-t rounded-b-lg bg-white  m-2 pb-3  overflow-x-auto  ">
                <div className="flex w-full flex-wrap  bg-gray-200 justify-around py-4">
                  {divideArray(
                    displayAll
                      ? inventarioMelyMx
                      : inventarioMelyMx?.filter((i) => i.total !== '0'),
                    10,
                  ).map((i) => (
                    <InventarioTabla lista={i} nombre="MELY MX" />
                  ))}
                </div>

                <div className="flex w-full flex-wrap bg-blue-100 justify-around py-4">
                  {divideArray(
                    displayAll
                      ? inventarioMelyUsa
                      : inventarioMelyUsa?.filter((i) => i.total !== '0'),
                    10,
                  ).map((i) => (
                    <InventarioTabla lista={i} nombre="MELY USA" />
                  ))}
                </div>
                <div className="flex w-full flex-wrap justify-around py-4">
                  <InventarioClientesTable lista={inventarioClientes} nombre="Clientes" />
                </div>
                {/* TODO INVENTARIO RECHASOS */}
                <div className="flex w-full flex-wrap justify-around py-4">
                  <InventarioRechazosTable lista={inventarioRechazos} nombre="Rechazos" />
                </div>
              </div>
            </FlexContainer>
          </main>
        </FlexContainer>
      </div>
    </>
  );
};
export default Inventario;
