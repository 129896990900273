import React from 'react';
import { DefatultItemsList } from '../SharedUi/DefaultItemsList';
import TableRow from '../TailwindUI/TableRow';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import { getCuentasPorCobrar, cuentasPorCobrarActions } from '../../store/cuentasPorCobrar-slice';
import { prettifyNumber } from '../../Util/prettifyNumber';

const customDisplayMapFn = (CuentaPorCobrar, personIdx) => {
  let cuentaPorCobrarClone = { ...CuentaPorCobrar };
  cuentaPorCobrarClone.iva = cuentaPorCobrarClone.iva ? 'SI' : 'NO';
  cuentaPorCobrarClone.subtotal = `$${prettifyNumber(CuentaPorCobrar.subtotal)}`;
  cuentaPorCobrarClone.total = `$${prettifyNumber(Number(CuentaPorCobrar.total)?.toFixed(2))}`;

  return (
    <React.Fragment>
      <TableRow
        key={CuentaPorCobrar?.id}
        className={
          !CuentaPorCobrar.razonSocial
            ? 'bg-yellow-100 border'
            : personIdx % 2 === 0
            ? 'bg-white'
            : 'bg-gray-100'
        }
      >
        <TableBodyCellAuto list={cuentaPorCobrarClone} />
      </TableRow>
    </React.Fragment>
  );
};

const CuentasPorCobrar = () => {
  return DefatultItemsList({
    sliceState: 'cuentasPorCobrar',
    sliceActions: cuentasPorCobrarActions,
    getItems: getCuentasPorCobrar,
    customDisplayMapFn,
  });
};

export default CuentasPorCobrar;
