import { useReducer } from 'react';

export function useObjectReducer(initialState) {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_FIELD':
        return { ...state, [action.field]: action.payload };
      case 'RESET_STATE':
        return initialState;
      default:
        throw new Error('Unsupported action type!');
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateField= (field, value) => {
    console.log({ type: 'UPDATE_FIELD', field, payload: value })
    dispatch({ type: 'UPDATE_FIELD', field, payload: value });

  };

  const resetState = () => {
    dispatch({ type: 'RESET_STATE' });
  };


  return { state, updateField, resetState };
}