import { prettifyNumber } from './prettifyNumber';
import { financial } from './financial';

export function prettyFinancial(value){
 console.log("value->", value)
 // Attempt to convert value to a number
 const numericValue = parseFloat(value);

 // Check if the conversion result is not a number
 if (isNaN(numericValue)) return '';

 // Proceed if numericValue is a valid number
 const prettyValue = prettifyNumber(financial(numericValue));
 return `$${prettyValue}`;
}