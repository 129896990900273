import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '../TailwindUI/IconButton';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import RelationInsertValue from '../SharedUi/RelationInsertValue';
import { XCircleIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router';
import { InputCornerHint, NumberInputWithLimits } from '../SharedUi/Inputs';
import { AlertWithDescription } from '../SharedUi/Alerts';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { prettifyNumber } from '../../Util/prettifyNumber';

const AsignProductoSalidaIC = (props) => {
  const { iva } = props;
  const history = useHistory();
  //Internal State
  const [editMode, setEditMode] = useState(false);
  const [errorCantidad, setErrorCantidad] = useState(null);

  const [factura, setFactura] = useState('');
  const [remision, setRemision] = useState('');

  const [cantidad, setCantidad] = useState(null);

  const [limiteCantidadSuperior, setLimiteCantidadSuperior] = useState(null);
  const [limiteCantidadInferior, setLimiteCantidadInferior] = useState(null);

  const [productoPorProcesar, setProductoPorDarEntrada] = useState(null);
  const [sumaRegistros, setSumaRegistros] = useState(null);
  const [unidad, setUnidad] = useState(null);

  //Redux State

  //Create Relation
  const createRelation = useSelector((state) => state.createRelation);
  const loading = useSelector((state) => state.createRelation.loading);

  //Function Handlers

  const handleCancel = () => {
    props.handleCancel({
      stateTree: props.asignTree,
    });
    setEditMode(false);
  };

  const handlePostRelation = () => {
    let selectedValue;

    console.log('productoPorProcesar!!!!');
    console.log(productoPorProcesar);
    selectedValue = {
      salida_ic_id: props?.salidaICId,
      cliente_id: productoPorProcesar.clienteId,
      cantidad,
      unidad_id: 1,
      producto_id: productoPorProcesar.productoId,
    };

    console.log('Salida');
    console.log(selectedValue);

    props.handlePostRelation({
      selectedValue,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
    });
    setEditMode(false);
  };

  //<-----------------SELECT OPTIONS----------------->
  const dispatch = useDispatch();

  const handleCantidad = (e) => {
    setCantidad(e.target.value);
    setErrorCantidad(null);

    if (e.target.value < limiteCantidadInferior) {
      setErrorCantidad('No se permiten salidas con 0 Unidades');
    }
    if (e.target.value > limiteCantidadSuperior) {
      setErrorCantidad('No tenemos en existencia ese número de unidades');
    }
  };
  const handleCrearEntrada = (eduxStateTree, value, selectValues) => {
    console.log('handle divisa---> Events');
    console.log('eduxStateTree');
    console.log(eduxStateTree);

    // props.handleSelect({
    //   reduxStateTree: props.asignTree,
    //   valuesToFilter: createRelation.valuesToAsign,
    //   selectedValue: productoPorProcesar,
    // });
  };

  const handleAddToEntrada = (producto) => {
    console.log('producto');
    console.log(producto);
    console.log(producto.cantidad);

    // IF OCC
    setLimiteCantidadInferior(Math.floor(1));
    setCantidad(1);
    setProductoPorDarEntrada(producto);
    setLimiteCantidadSuperior(Number(producto.sumaEntradas) - Number(producto.sumaSalidas));

    setEditMode(true);
  };

  const handleSetlimitAsCantidad = () => {
    setCantidad(limiteCantidadSuperior);
  };

  return (
    <React.Fragment>
      <div>
        <CardTitle>{props.cardTitle}</CardTitle>

        {loading && <h1>Loading</h1>}
        {!editMode && props.asignedListSelector && (
          <React.Fragment>
            {props.asignedListSelector?.length > 0 && (
              <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <Table>
                  <TableHeader>
                    <TableRowHeader className="bg-gray-300 ">
                      <TableHeaderCell textColor="text-gray-700">Símbolo</TableHeaderCell>

                      <TableHeaderCell textColor="text-gray-700">TOTAL</TableHeaderCell>
                      <TableHeaderCell textColor="text-gray-700">AÑADIR</TableHeaderCell>
                    </TableRowHeader>
                  </TableHeader>
                  <TableBody>
                    {props.asignedListSelector?.map((producto, personIdx) => {
                      console.log('TESTTT');
                      console.log(producto);
                      if (Number(producto.sumaEntradas) - Number(producto.sumaSalidas) > 0) {
                        return (
                          <React.Fragment>
                            <TableRow
                              key={producto.id}
                              className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                            >
                              <TableBodyCell textColor="text-gray-500">
                                {producto.entradaSimbolo}
                              </TableBodyCell>
                              <TableBodyCell textColor="text-gray-500">
                                {prettifyNumber(
                                  String(
                                    Number(producto.sumaEntradas) - Number(producto.sumaSalidas),
                                  ),
                                )}
                              </TableBodyCell>

                              <TableBodyCell textColor="text-gray-500">
                                <IconButton
                                  onClick={() => handleAddToEntrada(producto)}
                                  color="orange"
                                  icon="+"
                                  iconHeight={3}
                                  iconWidth={3}
                                />
                              </TableBodyCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
          </React.Fragment>
        )}
        {!loading && (
          <React.Fragment>
            {editMode && (
              <CardContainer>
                <div className="flex">
                  {/* {JSON.stringify(productoPorProcesar)} */}

                  {!props.valueToAsignSelector && (
                    <div className="grid-cols-6 ">
                      <InputCornerHint
                        name="simboloProducto"
                        label={'Símbolo'}
                        value={productoPorProcesar?.entradaSimbolo?.toUpperCase()}
                        disabled
                        borderColor={'border-gray-400'}
                      />
                      <div className={'my-2'} />
                      <NumberInputWithLimits
                        number={cantidad}
                        onChange={handleCantidad}
                        title={'Cantidad'}
                        leftLimit={Math.floor(limiteCantidadInferior)}
                        rightLimit={Math.floor(limiteCantidadSuperior)}
                        setCantidad={handleSetlimitAsCantidad}
                        leftLabel=""
                        rightLabel=""
                        error={errorCantidad}
                        borderColor={'border-orange-300'}
                      />
                      <div className={'my-4'} />

                      <div className={'my-2'} />

                      <div className={'my-2'} />

                      {errorCantidad && (
                        <AlertWithDescription
                          title={errorCantidad}
                          icon={<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                        />
                      )}

                      {!errorCantidad && (
                        <>
                          {cantidad > 0 && (
                            <CardButton onClick={handlePostRelation} bgColor="orange">
                              Registrar Salida IC
                            </CardButton>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {props.valueToAsignSelector && (
                    <React.Fragment>
                      <RelationInsertValue
                        label={props.inputLabel}
                        value={props.valueToAsignSelector[props.nameOfPropertyToAsign]}
                        handlePostRelation={handlePostRelation}
                        handleCancel={handleCancel}
                      />
                    </React.Fragment>
                  )}
                </div>
              </CardContainer>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
export default AsignProductoSalidaIC;
