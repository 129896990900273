import React, { useState } from 'react';
import IconButton from '../TailwindUI/IconButton';
import { prettifyNumber } from '../../Util/prettifyNumber';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { Link } from 'react-router-dom';
import numberToText from '../../Util/cantidadEnLetra';
import DeleteModal from '../UI/Modals/DeleteModal';
import { timeStampToDDMMYY } from '../../Util/get-date';

const PagosTable = (props) => {
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(false);
  const { iva } = props;
  //Internal State
  const [verObservaciones, setVerObservaciones] = useState(false);
  const [cantidad, setCantidad] = useState(null);

  //Redux State
  const confirmDelete = (pago) => {
    handleErrase(pago);
    setDeleteModalIsVisible(false);
  };
  //Function Handlers
  const handleErrase = (e) => {
    props.handleErrase({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };
  const handleUpdate = () => {
    props.handleUpdate();
  };

  return (
    <React.Fragment>
      <div>
        {props.asignedListSelector?.length > 0 && (
          <div style={{ width: '100%', margin: 'auto' }}>
            <Table>
              <TableHeader>
                <TableRowHeader>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>FECHA</TableHeaderCell>
                  <TableHeaderCell>DIVISA</TableHeaderCell>
                  <TableHeaderCell>MONTO</TableHeaderCell>
                  <TableHeaderCell>TOTAL</TableHeaderCell>
                  {verObservaciones && (
                    <>
                      <TableHeaderCell>FOLIO</TableHeaderCell>
                      <TableHeaderCell>OBSERVACIONES</TableHeaderCell>
                    </>
                  )}
                </TableRowHeader>
              </TableHeader>
              <TableBody>
                <TableRow></TableRow>
                {props.asignedListSelector?.map((pago, personIdx) => (
                  <React.Fragment>
                    <TableRow
                      key={pago.id}
                      className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
                    >
                      <TableBodyCell>{pago.id}</TableBodyCell>
                      <TableBodyCell>
                        {timeStampToDDMMYY(pago.fecha)}
                      </TableBodyCell>

                      <TableBodyCell>
                        {props.divisa?.id === 1
                          ? 'MXN'
                          : pago.divisaId === 2
                          ? 'USD'
                          : `MXN TC(${pago.tipoDeCambio})`}
                      </TableBodyCell>
                      <TableBodyCell>${prettifyNumber(pago.monto)}</TableBodyCell>
                      <TableBodyCell>
                        $
                        {prettifyNumber(
                          (Number(pago.monto) / Number(pago.tipoDeCambio ? pago.tipoDeCambio : 1))
                            ?.toFixed(2)
                            ?.toString(),
                        )}
                      </TableBodyCell>
                      {verObservaciones && (
                        <>
                          <TableBodyCell>{pago.folio}</TableBodyCell>
                          <TableBodyCell>{pago.observaciones}</TableBodyCell>
                        </>
                      )}
                      <TableBodyCell>
                        {deleteModalIsVisible && (
                          <>
                            <DeleteModal
                              title={props.modalTitle}
                              item={`Id:${itemToDelete?.id} Monto:$${prettifyNumber(
                                itemToDelete?.monto,
                              )} Divisa:${
                                props.divisa?.id === 1
                                  ? 'MXN'
                                  : pago.divisaId === 2
                                  ? 'USD'
                                  : `MXN TC(${pago.tipoDeCambio})`
                              }`}
                              onConfirm={() => {
                                console.log('ON CONFIRM =>>>>>ITem to delete >>>>>>>>>>>>>>>>>>>');
                                console.log(itemToDelete);
                                confirmDelete(itemToDelete);
                              }}
                              onCancel={() => setDeleteModalIsVisible(false)}
                            >
                              Esta Acción no puede desacerse
                            </DeleteModal>
                          </>
                        )}
                        <IconButton
                          onClick={() => {
                            setItemToDelete(pago);
                            setDeleteModalIsVisible(true);
                          }}
                          color="red"
                          icon="trash"
                          iconHeight={2.5}
                          iconWidth={2.5}
                        />
                        {pago.observaciones ? (
                          <IconButton
                            onClick={() => setVerObservaciones((e) => !e)}
                            color={verObservaciones ? 'gray' : 'darkBlue'}
                            icon="info"
                            iconHeight={2.5}
                            iconWidth={2.5}
                          />
                        ) : (
                          <span style={{ visibility: 'hidden' }}>
                            <IconButton
                              onClick={() => ''}
                              color={verObservaciones ? 'gray' : 'darkBlue'}
                              icon="info"
                              iconHeight={2.5}
                              iconWidth={2.5}
                            />
                          </span>
                        )}
                      </TableBodyCell>
                    </TableRow>
                  </React.Fragment>
                ))}

                <TableRow className="py-1">
                  <td
                    style={{
                      whiteSpace: 'nowrap',
                      wordBreak: 'break-word',
                      textAlign: 'right',
                    }}
                    colspan={3}
                  >
                    {/* <>{numberToText(props.total)?.toString()?.toUpperCase()}</> */}
                  </td>
                  <TableBodyCell className=" align-top py-4 px-1 whitespace-nowrap text-right">
                    <span className="font-medium text-gray-600">TOTAL:</span>
                  </TableBodyCell>
                  <TableBodyCell className="align-top py-4  text-gray-600 px-1 whitespace-nowrap text-left">
                    ${prettifyNumber(props.total ? Number(props.total).toFixed(2) : 0)}
                  </TableBodyCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default PagosTable;
//test
