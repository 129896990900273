import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createOCCActions as sliceActions,
  insertOCC as insertThunk,
} from '../../store/crearOCC-slice';
import SelectDate from '../SharedUi/SelectDate';

import { InputCornerHint, Label } from '../SharedUi/Inputs';
import {
  asingDispatcher,
  ivaDispatcher,
  fechaEntregaDispatcher,
} from '../../lib/sharedDispatchers';
import InsertTemplate from '../SharedUi/InsertTemplate';
import { BooleanHelper } from '../BooleanHelper';
import SelectHelperV2 from '../TailwindUI/SelectHelperV2';
import { objectToOption } from '../../Util/object-to-option';
import AuthContext from '../../store/auth-context';
import { daysFromToday } from '../../Util/daysFromToday';
import DatePicker from 'react-datepicker';


const CreateOCC = () => {
  const [startDate, setStartDate] = useState(new Date());

  const authCtx = useContext(AuthContext);
  const fetchAllOptions = useSelector((state) => state.fetchAllOptions);
  const sliceState = useSelector((state) => state.createOcc);
  const dispatch = useDispatch();
  //Dispatchers------------------------------------------------------------------------
  const handleAsingProducto = (event) => asingDispatcher({ dispatch, sliceActions, event });
  const handleIva = () => ivaDispatcher({ dispatch, sliceActions, sliceState });
  const handleFechaDeEntrega = (event) =>
    fechaEntregaDispatcher({ dispatch, sliceActions, value: event.target.value });

  const handleFechaDeEntrega2 = (event) =>
    fechaEntregaDispatcher(
      { dispatch, sliceActions, value: event });
  const request = {
    cliente_id: sliceState?.cliente?.id,
    iva: sliceState?.iva,
    usuario_id: 1,
    divisa_id: sliceState?.divisa?.id,
    inventario_id: sliceState?.inventario?.id,
    estatus: 'Creada',
    fecha_entrega: sliceState?.fechaEntrega,
  };
  let components = [
    <div className={"text-left"}>
      <Label htmlFor={'fechaDeEntrega'}>Fecha de Entrega</Label>
      <div></div>
      <DatePicker selected={startDate} onChange={(date) => {
        handleFechaDeEntrega2(date);
        setStartDate(date);
      }
      } />
    </div>,
    // <React.Fragment>
    //   <SelectDate reduxStateTree={'unidades'} action={handleFechaDeEntrega}>
    //     <InputCornerHint
    //       value={sliceState?.fechaEntregaDisplay}
    //       type="text"
    //       label={'Fecha de Entrega'}
    //     />
    //   </SelectDate>
    // </React.Fragment>,

    <SelectHelperV2
      routePath={'clientes'}
      modelName={'cliente'}
      api={"nest"}
      label='cliente'
      transformer={(data) => {
        return data?.map(item => {
          return objectToOption({
            obj: item,
            valueKey: 'id',
            textKey:'razonSocial'
          });
        });
      }}
      onChange={(e) => {
        handleAsingProducto({
          reduxStateTree: "cliente",
          valuesToFilter: fetchAllOptions.cliente,
          selectedValue: e.target.value,
          authCtx,
        })
      }}
    />,

    <SelectHelperV2
      routePath={'divisas'}
      modelName={'divisa'}
      label='divisa'
      transformer={(data) => {
        return data?.map(item => {
          return objectToOption({
            obj: item,
            valueKey: 'id',
            textKey:'nombre'
          });
        });
      }}
      onChange={(e) => {
        handleAsingProducto({
          reduxStateTree: "divisa",
          valuesToFilter: fetchAllOptions.divisa,
          selectedValue: e.target.value,
          authCtx,
        })
      }}
    />,


    <SelectHelperV2
      routePath={'nombres-inventario'}
      modelName={'inventario'}
      label='inventario'

      transformer={(data) => {
        return data?.map(item => {
          return objectToOption({
            obj: item,
            valueKey: 'id',
            textKey:'nombre'
          });
        });
      }}
      onChange={(e) => {
        handleAsingProducto({
          reduxStateTree: "inventario",
          valuesToFilter: fetchAllOptions.inventario,
          selectedValue: e.target.value,
          authCtx,
        })
      }}
    />,



    BooleanHelper({
      label: 'IVA',
      value: sliceState.iva,
      onChange: handleIva,
    }),
  ];

  let condition = sliceState.inventario && sliceState.cliente && sliceState.divisa;

  return (
    <InsertTemplate
      sliceState={sliceState}
      insertRequest={request}
      insertThunk={insertThunk}
      components={components}
      condition={condition}
    />
  );
};
export default CreateOCC;
