import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import * as util from '../lib/edit';
import { getRequestWparamsThunk, getRequestThunk } from '../Util/get-request-thunk';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL();
const initialState = {
  simbolo: null,
  stock: null,
  entradas: [],
  salidas: [],
  totalByFecha: null,
};
export const getStockInventarioSimbolo = getRequestThunk(
  'inventarioEntradasSalidas/getStockInventarioSimbolo',
  `${apiHOST}views/inventario`,
);

export const getStock = getRequestWparamsThunk(
  'inventarioEntradasSalidas/getStock',
  `${apiHOST}views/inventario`,
);

export const getInventarioEntradas = getRequestWparamsThunk(
  'inventarioEntradasSalidas/getInventarioEntradas',
  `${apiHOST}views/inventario/entradas`,
);

export const getInventarioSalidas = getRequestWparamsThunk(
  'inventarioEntradasSalidas/getInventarioSalidas',
  `${apiHOST}views/inventario/salidas`,
);

export const getTotalByFecha = createAsyncThunk(
  'inventarioEntradasSalidas/getTotalByFecha',
  async (payload) => {
    const { requestObj, authCtx } = payload;
    console.log('query----------');
    console.log(`${apiHOST}views/inventario/total-by-fechas`);
    console.log(requestObj);
    try {
      const response = await axios.post(`${apiHOST}views/inventario/total-by-fechas`, requestObj, {
        headers: {
          ...authorizationHeader()        },
      });
      console.log('response Data get inventario ----slice');
      console.log(response);

      return response.data;
    } catch (err) {
      return err;
    }
  },
);

const inventarioEntradasSalidasSlice = createSlice({
  name: 'getInventarioEntradasSalidas',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInventarioEntradas.fulfilled, (state, action) => {
      state.entradas = action.payload;
      state.filterSearchValue = '';
    });
    builder.addCase(getTotalByFecha.fulfilled, (state, action) => {
      const { totalesInventario } = action.payload;
      console.log('totalesInventario-----');
      console.log(action.payload);
      console.log(totalesInventario);
      state.totalByFecha = totalesInventario;
    });
    builder.addCase(getInventarioSalidas.fulfilled, (state, action) => {
      state.salidas = action.payload;
      state.filterSearchValue = '';
    });
    builder.addCase(getStock.fulfilled, (state, action) => {
      state.stock = action.payload;
      console.log(action.payload);
      state.filterSearchValue = '';
    });
  },
});

export default inventarioEntradasSalidasSlice;

export const inventarioEntradasSalidasActions = inventarioEntradasSalidasSlice.actions;
