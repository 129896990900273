export const Label = (props) => {
  let color;
  props.color ? (color = props.color) : (color = `text-gray-700`);
  let className = '';
  props.className
    ? (className = props.className)
    : (className = `"block text-sm font-medium ${color}`);
  return (
    <label htmlFor={props.htmlFor} className={className}>
      {props.children}
    </label>
  );
};
export const InputCornerHint = (props) => {
  let textColor = '';
  props.textColor ? (textColor = props.textColor) : (textColor = '');
  let borderColor = '';
  props.borderColor
    ? (borderColor = props.borderColor)
    : (borderColor = 'border-gray-300');
  const focusColor = props.error ? 'red-600' : 'blue-800';
  return (
    <div>
      <div className='flex justify-between'>
        <Label htmlFor={props.name}>{props.label}</Label>

        <span className='text-sm text-gray-500' id='email-optional'>
          {props.children}
        </span>
      </div>
      <div className='mt-1'>
        <input
          readOnly={props.readOnly ? true : false}
          disabled={props.disabled ? true : false}
          type={props.type ? props.type : 'text'}
          name={props.name}
          id={props.name}
          min={1}
          onBlur={props.onBlur}
          value={props.value}
          onChange={props.onChange}
          className={`shadow-sm focus:ring-${focusColor} focus:border-${focusColor} block w-full sm:text-sm ${borderColor} rounded-md ${textColor}`}
        />
      </div>
    </div>
  );
};

export const NumberInputCornerHint = (props) => {
  let textColor = '';
  props.textColor ? (textColor = props.textColor) : (textColor = '');
  let borderColor = '';
  props.borderColor
    ? (borderColor = props.borderColor)
    : (borderColor = 'border-gray-300');
  return (
    <div>
      <div className='flex justify-between'>
        <Label htmlFor={props.name}>{props.label}</Label>

        <span className='text-sm text-gray-500' id='email-optional'>
          {props.children}
        </span>
      </div>
      <div className='mt-1'>
        <input
          readOnly={props.readOnly ? true : false}
          disabled={props.disabled ? true : false}
          type={'number'}
          name={props.name}
          id={props.name}
          min={props.min}
          max={props.max}
          step={props.step || 'any'}
          value={props.value}
          onChange={props.onChange}
          className={`shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm ${borderColor} rounded-md ${textColor}`}
        />
      </div>
    </div>
  );
};

export const SelectCornerHint = (props) => {
  let textColor = '';
  props.textColor ? (textColor = props.textColor) : (textColor = '');
  let borderColor = '';
  props.borderColor
    ? (borderColor = props.borderColor)
    : (borderColor = 'border-gray-300');
  return (
    <div>
      <div className='flex justify-between'>
        <Label htmlFor={props.name}>{props.label}</Label>

        <span className='text-sm text-gray-500' id='email-optional'></span>
      </div>
      <div className='mt-1'>
        <select
          readOnly={props.readOnly ? true : false}
          disabled={props.disabled ? true : false}
          name={props.name}
          id={props.name}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onClick={props.onClick}
          className={
            props.className
              ? props.className
              : ` shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm ${borderColor} rounded-md ${textColor}`
          }
        >
          <option value=''></option>
          {props.options}
        </select>
      </div>
    </div>
  );
};

export const InputWithLabel = (props) => (
  <div>
    <label className='block text-sm font-medium  text-left text-gray-700'
           htmlFor={props.label}>
      {props.label}
    </label>
    <div className='mt-1'>
      <input
        type={props.type || 'text'}
        name={props.name || props.label || ''}
        id={props.name || props.label || ''}
        className={
          props.className ||
          'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
        }
        placeholder={props.placeholder}
        ref={props.ref}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  </div>
);

export const NumberInputWithLimits = (props) => {
  let borderColor = '';
  props.borderColor
    ? (borderColor = props.borderColor)
    : (borderColor = 'border-gray-300');
  return (
    <div>
      <fieldset>
        <legend
          className='block text-sm font-medium text-gray800'>{props.title}</legend>
        <div className='mt-1 bg-white rounded-md shadow-sm -space-y-px'>
          <div className='flex -space-x-px'>
            <div className='w-1/2 flex-1 min-w-0'>
              <label
                htmlFor='card-expiration-date'
                className=' pl-1 block text-xs  text-left font-medium text-gray-600'
              >
                {props.leftLabel}
              </label>
              <input
                disabled
                value={props.leftLimit}
                type='text'
                name='card-expiration-date'
                id='card-expiration-date'
                className='text-center  text-gray-500 focus:ring-orange-500 focus:border-orange-500 relative block w-full rounded-none rounded-tl-md bg-transparent focus:z-10 sm:text-sm border-gray-300'
              />
            </div>
            <div className='flex-1 min-w-0'>
              <label
                htmlFor='card-cvc'
                className='  pr-1 block text-xs text-right font-medium text-gray-600'
              >
                {props.rightLabel}
              </label>
              <button onClick={props.setCantidad}>
                <input
                  disabled
                  value={props.rightLimit}
                  type='text'
                  name='card-cvc'
                  id='card-cvc'
                  className=' text-center cursor-pointer  text-gray-500 focus:ring-orange-500 focus:border-orange-500 relative block w-full rounded-none rounded-tr-md bg-transparent focus:z-10 sm:text-sm border-gray-300'
                />
              </button>
            </div>
          </div>
          <div>
            <label htmlFor='card-number' className='sr-only'>
              {props.label}
            </label>
            <input
              type='number'
              name='cantidad'
              id='cantidad'
              min='0'
              className={`text-center focus:ring-orange-500 focus:border-orange-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm  ${borderColor} ${
                props.error ? ' border-red-500 border-2  ' : 'border'
              }`}
              value={props.number}
              onChange={props.onChange}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export const NumberInputWithLimitsV2 = (props) => {
  let borderColor = '';
  props.borderColor
    ? (borderColor = props.borderColor)
    : (borderColor = 'border-gray-300');
  return (
    <div>
      <fieldset>
        <legend
          className='block text-sm font-medium text-gray800'>{props.title}</legend>
        <div className='mt-1 bg-white rounded-md shadow-sm -space-y-px'>
          <div className='flex -space-x-px'>
            <div className='w-1/2 flex-1 min-w-0'>
              <label
                htmlFor='card-expiration-date'
                className=' pl-1 block text-xs  text-left font-medium text-gray-600'
              >
                <button onClick={props.setLeftValue}>
                  {props.leftLabel}
                </button>

              </label>

              <input
                disabled
                value={props.leftLimit}
                type='text'
                name='card-expiration-date'
                id='card-expiration-date'
                className='text-center  text-gray-500 focus:ring-orange-500 focus:border-orange-500 relative block w-full rounded-none rounded-tl-md bg-transparent focus:z-10 sm:text-sm border-gray-300'
              />
            </div>
            <div className='flex-1 min-w-0'>
              <label
                htmlFor='card-cvc'
                className='  pr-1 block text-xs text-right font-medium text-gray-600'
              >
                <button onClick={props.setRightValue}>
                  {props.rightLabel}
                </button>

              </label>

              <input
                disabled
                value={props.rightLimit}
                type='text'
                name='card-cvc'
                id='card-cvc'
                className=' text-center text-gray-500 focus:ring-orange-500 focus:border-orange-500 relative block w-full rounded-none rounded-tr-md bg-transparent focus:z-10 sm:text-sm border-gray-300'
              />
            </div>
          </div>
          <div>
            <label htmlFor='card-number' className='sr-only'>
              {props.label}
            </label>
            <input
              type='number'
              name='cantidad'
              id='cantidad'
              min='0'
              className={`text-center focus:ring-orange-500 focus:border-orange-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm  ${borderColor} ${
                props.error ? ' border-red-500 border-2  ' : 'border'
              }`}
              value={props.number}
              onChange={props.onChange}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};
