import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL_Nest } from '../Util/apiURL';
import {
  getRequestWparamsThunk,
  getRequestThunk,
} from '../Util/get-request-thunk';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL_Nest();
const initialState = {
  simbolo: null,
  stock: null,
  entradas: [],
  salidas: [],
  totalByFecha: null,
};
export const getStockInventarioSimbolo = getRequestThunk(
  'inventarioEntradasSalidas/getStockInventarioSimbolo',
  `${apiHOST}/inventario-clientes`,
);

export const getStock = getRequestWparamsThunk(
  'inventarioEntradasSalidas/getStock',
  `${apiHOST}views/inventario`,
);

export const getInventarioClientesTotales = getRequestWparamsThunk(
  'inventarioEntradasSalidas/getTotales',
  `${apiHOST}inventario-clientes/totales`,
);

export const getInventarioClientesEntradas = getRequestWparamsThunk(
  'inventarioEntradasSalidas/getInventarioEntradas',
  `${apiHOST}inventario-clientes/productos-entrada`,
);

export const getInventarioCliestesSalidas = getRequestWparamsThunk(
  'inventarioEntradasSalidas/getInventarioSalidas',
  `${apiHOST}inventario-clientes/productos-salida`,
);

export const getTotalByFecha = createAsyncThunk(
  'inventarioEntradasSalidas/getTotalByFecha',
  async (payload) => {
    const { requestObj, authCtx } = payload;
    console.log('query----------');
    console.log(`${apiHOST}views/inventario/total-by-fechas`);
    console.log(requestObj);
    try {
      const response = await axios.post(
        `${apiHOST}views/inventario/total-by-fechas`, requestObj, {
          headers: {
            ...authorizationHeader(),
          },
        });
      console.log('response Data get inventario ----slice');
      console.log(response);

      return response.data;
    } catch (err) {
      return err;
    }
  },
);

const inventarioClientesEntradasSalidasSlice = createSlice({
  name: 'getInventarioEntradasSalidas',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInventarioClientesEntradas.fulfilled,
      (state, action) => {
        const result = action.payload;
        state.entradas = result;
        state.filterSearchValue = '';
      });
    builder.addCase(getTotalByFecha.fulfilled, (state, action) => {
      const { totalesInventario } = action.payload;
      console.log('totalesInventario-----');
      console.log(action.payload);
      console.log(totalesInventario);
      state.totalByFecha = totalesInventario;
    });
    builder.addCase(getInventarioCliestesSalidas.fulfilled, (state, action) => {
      state.salidas = action.payload;
      state.filterSearchValue = '';
    });
    builder.addCase(getStock.fulfilled, (state, action) => {
      state.stock = action.payload;
      console.log(action.payload);
      state.filterSearchValue = '';
    });

    builder.addCase(getInventarioClientesTotales.fulfilled,
      (state, action) => {
        console.log('gettotales ->', action.payload);
        console.log('gettotales ->', action.payload);
        console.log('gettotales ->', action.payload);
        state.stock =  action.payload;
        // state.simbolo = simbolo;
        state.filterSearchValue = '';
      });

  },
});

export default inventarioClientesEntradasSalidasSlice;

export const inventarioCliestesEntradasSalidasActions = inventarioClientesEntradasSalidasSlice.actions;
