import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createCuentaPorPagarActions as sliceActions,
  insertCuentaPorPagar as insertThunk,
} from '../../store/crearCuentaPorPagar-slice';
import { CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import ToogleWithIcon from '../TailwindUI/ToogleWithIcon';
import SelectDate from '../SharedUi/SelectDate';
import { InputCornerHint } from '../SharedUi/Inputs';
import InsertTemplate from '../SharedUi/InsertTemplate';
import {
  asingDispatcher,
  resetDispatcher,
  ivaDispatcher,
  fechaFacturaDispatcher,
  facturaDispatcher,
  fechaVencimientoDispatcher,
} from '../../lib/sharedDispatchers';

import { SelectHelpers } from '../SelectHelpers';
import { createDateFromYYYYMMDD_String, strinDateSpanish } from '../../Util/get-date';

const CreateCuentaPorPagar = () => {
  const sliceState = useSelector((state) => state.createCuentaPorPagar);
  const dispatch = useDispatch();
  //Dispatchers------------------------------------------------------------------------
  const handleAsingPrincipal = (event) => {
    resetDispatcher({ dispatch, sliceActions });
    asingDispatcher({ dispatch, sliceActions, event });
  };
  const handleIva = () => ivaDispatcher({ dispatch, sliceActions, sliceState });
  const handleFechaFactura = (event) => {
    fechaFacturaDispatcher({ dispatch, sliceActions, value: event.target.value });
    fechaVencimientoDispatcher({
      dispatch,
      sliceActions,
      value: { sliceState, fechaFactura: createDateFromYYYYMMDD_String(event.target.value) },
    });
  };
  const handleFactura = (event) =>
    facturaDispatcher({ dispatch, sliceActions, value: event.target.value });

  //#region Redux Selectors--------------------------------->

  let request = {
    proveedor_id: sliceState?.proveedor?.id,
    factura: sliceState?.factura,
    divisa_id: sliceState?.divisa?.id,
    fecha_factura: sliceState?.fechaFactura,
    fecha_vencimiento:
      sliceState?.fechaVencimiento !== '' ? sliceState.fechaVencimiento.toDateString() : new Date(),
    iva: sliceState.iva,
    observaciones: '',
  };
  //#endregion Function Handler <---------------------------------

  let components = [
    SelectHelpers({
      name: 'proveedor',
      valueToAsignSelector: sliceState.proveedor,
      handleSelect: handleAsingPrincipal,
    }),

    SelectHelpers({
      name: 'divisa',
      valueToAsignSelector: sliceState.divisa,
      handleSelect: handleAsingPrincipal,
    }),
    //TODO create  component for IVA
    <CardContainer>
      <CardTitle>IVA</CardTitle>
      <ToogleWithIcon enabled={sliceState.iva} onChange={handleIva} />
    </CardContainer>,
    //TODO create  component for Factura
    <CardContainer>
      <CardTitle>Factura</CardTitle>
      <InputCornerHint onChange={handleFactura} />
    </CardContainer>,
    //TODO create  component for Fecha Factura
    sliceState.proveedor ? (
      <CardContainer>
        <CardTitle>Fecha Factura</CardTitle>
        <SelectDate action={handleFechaFactura}>
          <input
            className="  text-sm focus:ring-orange-500 focus:border-orange-500  block   border-white rounded-md  "
            type="text"
            value={sliceState?.fechaFactura}
          />
        </SelectDate>
      </CardContainer>
    ) : (
      ''
    ),
    sliceState.proveedor ? (
      <CardContainer>
        <CardTitle>Días de Crédito</CardTitle>
        <InputCornerHint value={sliceState.proveedor?.diasCredito} />
      </CardContainer>
    ) : (
      ''
    ),
    sliceState.proveedor ? (
      <CardContainer>
        <CardTitle>Vencimiento Factura</CardTitle>
        <InputCornerHint
          value={sliceState?.fechaVencimiento ? strinDateSpanish(sliceState.fechaVencimiento) : ''}
        />
      </CardContainer>
    ) : (
      ''
    ),
  ];
  let condition =
    sliceState.factura &&
    sliceState.fechaFactura &&
    sliceState.divisa?.id &&
    sliceState.proveedor?.id;

  return (
    <InsertTemplate
      sliceState={sliceState}
      insertRequest={request}
      insertThunk={insertThunk}
      components={components}
      condition={condition}
    />
  );
};
export default CreateCuentaPorPagar;
