import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { postItemThunk } from '../lib/default-post-item';
import { asignReducer } from '../lib/shared-reducers';
import {
  insertItemFullfilled,
  insertItemPending,
  insertItemRejected,
} from '../lib/default-post-item';
const apiHOST = apiURL();

const dateOptions = {
  weekday: 'short',
  year: '2-digit',
  month: 'short',
  day: 'numeric',
};

const initialState = {
  proveedor: '',
  divisa: '',
  inventario: '',
  iva: false,
  fechaEntrega: '',
  fechaEntregaDisplay: '',
  productToAsign: null,
  productosOrdenCompra: [],
  error: false,
  loading: false,
  success: null,
};

export const insertOCP = postItemThunk(
  'createOCP/insertOCP',
  `${apiHOST}ordenes-compra-proveedor/`,
  'ordenes-de-compra-proveedor',
  'numeroOcp',
);

const createOCPSlice = createSlice({
  name: 'createOCP',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'proveedor':
          state.proveedor = action.payload.value;

          break;
        case 'fechaEntrega':
          state.fechaEntrega = action.payload.value;
          state.fechaEntregaDisplay = action.payload.value;

          break;

        case 'inventario':
          state.inventario = action.payload.value;
          break;

        case 'divisa':
          state.divisa = action.payload.value;

          break;

        case 'iva':
          state.iva = action.payload.value;
          break;

        default:
      }
    },
    asignReducer,
  },
  extraReducers: builder => {
    builder.addCase(insertOCP.fulfilled, insertItemFullfilled(initialState));
    builder.addCase(insertOCP.pending, insertItemPending);
    builder.addCase(insertOCP.rejected, insertItemRejected);
  },
});

export default createOCPSlice;

export const createOCPActions = createOCPSlice.actions;
