const setMonth = (date: Date) => date.getUTCMonth();
const setYear = (date: Date) => date.getUTCFullYear();
const setDay = (date: Date) => date.getUTCDate();
const normalizeTo2Digits = (number: number) => {
  return number < 10 ? `0${number}` : number;
};
export const setDateStringDDMYYYY = (date: Date) => {
  const dateObj = new Date(date);

  return String(
    `${normalizeTo2Digits(setDay(dateObj))}-${normalizeTo2Digits(
      setMonth(dateObj) + 1)}-${setYear(dateObj)}`,
  );
};

export class DateFormater {
  // public dateFormats: {
  //   'DD-MM-YYYY': String
  // };

  constructor(private readonly date: Date) {
    this.date = date;
  }

  static apply(date: Date) {
    return {
      'DD-MM-YYYY': setDateStringDDMYYYY(date),
    };
  }

}