import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoutes from '../../Routes/AppRoutes';
import Login from '../../components/Login/Login';
import { useAuth } from '../../context/AuthProvider';
import { AppContainer } from '../UI/AppContainer/AppContainer';

function AuthenticatedApp () {
  const {
    isAuthenticated,
    authenticationError,
  } = useAuth();

  return (
    <>

      {isAuthenticated ? (
      <AppContainer>
        <Switch>
          <AppRoutes />
        </Switch>
      </AppContainer>
      ) : (
      <Login error={authenticationError}
             resetIsError={() => {/* TODO: setError(null) */}} />
      )}
    </>
  );
}

export default AuthenticatedApp;