import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createOCPActions as sliceActions,
  insertOCP as insertThunk,
} from '../../store/crearOCP-slice';
import SelectDate from '../SharedUi/SelectDate';
import { InputCornerHint, Label } from '../SharedUi/Inputs';
import { SelectHelpers } from '../SelectHelpers';
import {
  asingDispatcher,
  fechaEntregaDispatcher,
  ivaDispatcher,
} from '../../lib/sharedDispatchers';
import { BooleanHelper } from '../BooleanHelper';

import InsertTemplate from '../SharedUi/InsertTemplate';
import SelectHelperV2 from '../TailwindUI/SelectHelperV2';
import { objectToOption } from '../../Util/object-to-option';
import { findBy } from '../../lib/shared-reducers';
import AuthContext from '../../store/auth-context';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';


const CreateOCP = () => {
  const [startDate, setStartDate] = useState(new Date());
  const authCtx = useContext(AuthContext);

  const sliceState = useSelector((state) => state.createOcp);
  const fetchAllOptions = useSelector((state) => state.fetchAllOptions);

  const dispatch = useDispatch();

  //Dispatchers------------------------------------------------------------------------
  const handleAsingProducto = (event) => asingDispatcher(
    { dispatch, sliceActions, event });
  const handleFechaDeEntrega = (event) =>
    fechaEntregaDispatcher(
      { dispatch, sliceActions, value: event.target.value });

  const handleFechaDeEntrega2 = (event) =>
    fechaEntregaDispatcher(
      { dispatch, sliceActions, value: event });
  const handleIva = (event) => ivaDispatcher(
    { dispatch, sliceActions, sliceState });

  //Insert Request------------------------------------------------------------------------
  const request = {
    proveedor_id: sliceState?.proveedor?.id,
    iva: sliceState?.iva,
    usuario_id: 1,
    divisa_id: sliceState?.divisa?.id,
    inventario_id: sliceState?.inventario?.id,
    vigente: true,
    fecha_entrega: sliceState?.fechaEntrega,
  };

  let components = [
    <div className={"text-left"}>
      <Label htmlFor={'fechaDeEntrega'}>Fecha de Entrega</Label>
      <div></div>
      <DatePicker selected={startDate} onChange={(date) => {
        handleFechaDeEntrega2(date);
        setStartDate(date);
      }
      } />
    </div>,
    // <SelectDate action={handleFechaDeEntrega}>
    //   <InputCornerHint
    //     value={sliceState?.fechaEntregaDisplay}
    //     type='text'
    //     label={'Fecha de Entrega'}
    //   />
    // </SelectDate>,

    <SelectHelperV2
      routePath={'proveedores'}
      modelName={'proveedor'}
      api={'nest'}
      label='proveedor'
      transformer={(data) => {
        return data?.map(item => {
          return objectToOption({
            obj: item,
            valueKey: 'id',
            textKey: 'razonSocial',
          });
        });
      }}
      onChange={(e) => {
        handleAsingProducto({
          reduxStateTree: 'proveedor',
          valuesToFilter: fetchAllOptions.proveedor,
          selectedValue: e.target.value,
          authCtx,
        });
      }}
    />,

    <SelectHelperV2
      routePath={'divisas'}
      modelName={'divisa'}
      label='divisa'
      transformer={(data) => {
        return data?.map(item => {
          return objectToOption({
            obj: item,
            valueKey: 'id',
            textKey: 'nombre',
          });
        });
      }}
      onChange={(e) => {
        handleAsingProducto({
          reduxStateTree: 'divisa',
          valuesToFilter: fetchAllOptions.divisa,
          selectedValue: e.target.value,
          authCtx,
        });
      }}
    />,

    <SelectHelperV2
      routePath={'nombres-inventario'}
      modelName={'inventario'}
      label='inventario'

      transformer={(data) => {
        return data?.map(item => {
          return objectToOption({
            obj: item,
            valueKey: 'id',
            textKey: 'nombre',
          });
        });
      }}
      onChange={(e) => {
        handleAsingProducto({
          reduxStateTree: 'inventario',
          valuesToFilter: fetchAllOptions.inventario,
          selectedValue: e.target.value,
          authCtx,
        });
      }}
    />,

    BooleanHelper({
      label: 'IVA',
      value: sliceState.iva,
      onChange: handleIva,
    }),
  ];

  let condition = sliceState.inventario && sliceState.proveedor &&
    sliceState.divisa;

  return (
    <InsertTemplate
      sliceState={sliceState}
      insertRequest={request}
      insertThunk={insertThunk}
      components={components}
      condition={condition}
    />
  );
};
export default CreateOCP;
