import React from 'react';
import TableRow from '../TailwindUI/TableRow';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import { DefatultItemsList } from '../SharedUi/DefaultItemsList';
import { getCuentasPorPagar, cuentasPorPagarActions } from '../../store/cuentasPorPagar-slice';
import { prettifyNumber } from '../../Util/prettifyNumber';

const customDisplayMapFn = (cuentaPorPagar, personIdx) => {
  let cuentaPorPagarClone = { ...cuentaPorPagar };
  cuentaPorPagarClone.iva = cuentaPorPagarClone.iva ? 'SI' : 'NO';
  cuentaPorPagarClone.subtotal = `$${prettifyNumber(cuentaPorPagar.subtotal)}`;
  cuentaPorPagarClone.total = `$${prettifyNumber(Number(cuentaPorPagar.total)?.toFixed(2))}`;
  delete cuentaPorPagarClone['ivaTexto'];

  return (
    <React.Fragment>
      <TableRow
        key={cuentaPorPagar?.id}
        className={
          !cuentaPorPagar.razonSocial
            ? 'bg-yellow-100 border'
            : personIdx % 2 === 0
            ? 'bg-white'
            : 'bg-gray-100'
        }
      >
        <TableBodyCellAuto list={cuentaPorPagarClone} />
      </TableRow>
    </React.Fragment>
  );
};

const tableHeaderMapFn = (cuentaPorPagar, idx) => {
  let cuentaPorPagarClone = { ...cuentaPorPagar };

  delete cuentaPorPagarClone['ivaTexto'];
  return cuentaPorPagarClone;
};
const CuentasPorPagar = () => {
  return DefatultItemsList({
    sliceState: 'cuentasPorPagar',
    sliceActions: cuentasPorPagarActions,
    getItems: getCuentasPorPagar,
    customDisplayMapFn,
    tableHeaderMapFn,
  });
};

export default CuentasPorPagar;
