import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import OcpPDF from '../DocsToPrint/OcpPDF';
import { PencilAltIcon, TrashIcon, CheckIcon } from '@heroicons/react/solid';
import DeleteModal from '../UI/Modals/DeleteModal';
import {
  getOrdenCompraProveedor,
  putOrdenCompraProveedor,
  deleteOrdenCompraProveedor,
  cerrarOrdenCompraProveedor,
  editOrdenCompraProveedorActions,
  getRelation,
  postRelationProducto,
  postDocument,
  deleteRelationProducto,
  postEmail,
  downloadDocument,
} from '../../store/editarOrdenCompraProveedor-slice';
import AsignProductoToOC from '../AsignProductoToOc/AsignProductoToOC';

import SelectDate from '../SharedUi/SelectDate';
import DotsDropdown from '../DropdownMenu/DotsDropdown';
import DropdownItemButton from '../DropdownMenu/DropdownItemButton';
import { InputCornerHint } from '../SharedUi/Inputs';
import DocsCard from '../SharedUi/DocsCard';
import InfoMely, { datosMelyMx, datosMelyUsa } from '../InfoMely/InfoMely';

import {
  DocsCardLayoutLogo,
  DocsCardLayoutDatosDoc,
  DocsCardLayoutMenu,
  DocsCardLayoutTwoCols,
  DocsCardDatosFiscales,
  DocsCardLayoutInfoMely,
} from '../SharedUi/DocsCardLayout';
import DocsCardDatosDoc from '../SharedUi/DocsCardDatosDoc';
import LogoMely from '../SharedUi/LogoMely';
import AsignedProductosTable
  from '../AsignedProductosTable/AsignedProductosTable';

import ConfirmModal from '../UI/Modals/ConfirmModal';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';
import { OCDocumentFactory } from '../../domains/oc/OCDocumentFactory';
import { documentRequestObj } from '../../domains/oc/document-request-obj';
import DropdownButtonWithIcon from '../DropdownMenu/DropdownButtonWithIcon';
import {
  ocpSendEmailFactory,
} from '../../domains/notificaciones/ocp-send-email.factory';
import {
  downloadPDF, postDocumentCommand,
} from '../../domains/post-document-command';
import Spinner from '../Spinner/Spinner';
import { pause } from '../../Util/pause';

const EditOCP = () => {
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
  const [useEffectVariable, setUseEffectVariable] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [commandButtonError, setCommandButtonError] = useState(false);
  useEffect(() => {
    fetchOrdenCompraProveedor();

    dispatch(editOrdenCompraProveedorActions.resetProductToAssign());
  }, []);

  useEffect(() => {
    fetchOrdenCompraProveedor();
    getRelationHandler(subtotalOCP_URL, 'subtotal');
  }, [useEffectVariable]);

  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const sliceState = useSelector((state) => state.editOrdenCompraProveedor);

  const urlParam = location.pathname.split('/')[2];
  const requestUrl = 'ordenes-compra-proveedor';
  const deleteUrl = `ordenes-compra-proveedor/${sliceState?.ocp?.numeroOcp}`;
  const updateUrl = `ordenes-compra-proveedor/${sliceState?.ocp?.numeroOcp}`;
  const relationProducto_URL = `views/productos-ocp/${urlParam}`;
  const subtotalOCP_URL = `views/productos-ocp/subtotal/${urlParam}`;

  const fetchOrdenCompraProveedor = () => {
    dispatch(getOrdenCompraProveedor({ authCtx, urlParam }));
  };

  const handlePutOrdenCompraProveedor = ({ fechaEntrega }) => {
    const requestObj = {
      numero_ocp: sliceState?.ocp?.numeroOcp,
      proveedor_id: sliceState?.ocp?.proveedorId,
      iva: sliceState?.ocp?.iva,
      usuario_id: sliceState?.ocp?.usuarioId,
      divisa_id: sliceState?.ocp?.divisaId,
      inventario_id: sliceState?.ocp?.inventarioId,
      estatus: sliceState?.ocp?.estatus,
      fecha_entrega: fechaEntrega
        ? fechaEntrega
        : sliceState?.ocp?.fechaEntrega,
    };
    dispatch(putOrdenCompraProveedor({
      requestObj, requestUrl: updateUrl, authCtx,
    }));
  };

  const handleDelete = () => {
    dispatch(deleteOrdenCompraProveedor({
      requestUrl: deleteUrl, sliceState, history, authCtx,
    }));
  };
  // +--------------------------------------------------------------------------+
  const handleCerrarOcp = () => {
    dispatch(cerrarOrdenCompraProveedor({
      primarykey: sliceState?.ocp?.numeroOcp, authCtx,
    }));
  };
  // +--------------------------------------------------------------------------+
  ////Redux Actions
  const handleAsingProducto = (e) => {
    dispatch(editOrdenCompraProveedorActions.asign({ ...e }));
  };
  const handleCancelAsingProducto = (e) => {
    dispatch(editOrdenCompraProveedorActions.cancelAsign({ ...e }));
  };

  //Relations AsyncTHunks
  const getRelationHandler = (requestUrl, reduxStateTree) => {
    dispatch(getRelation({
      requestUrl, reduxStateTree, authCtx,
    }));
  };
  const handleEraseRelation = async (e) => {
    const requestObj = {
      id: e.valueToUnasign.id,
    };
    const { reduxStateTree, asignedListString, asignedListSelector } = e;

    dispatch(deleteRelationProducto({
      requestObj,
      reduxStateTree,
      asignedListString,
      asignedListSelector,
      authCtx,
    }));
    setUseEffectVariable(useEffectVariable + 1);
    await pause(500)
    getRelationHandler(subtotalOCP_URL, 'subtotal');
    fetchOrdenCompraProveedor();
  };

  const handlePostRelation = async (e) => {
    let requestObj = {};
    const {
      reduxStateTree, selectedValue, asignedListString, cantidad, unidad,
    } = e;
    if (reduxStateTree === 'productToAsign') {
      requestObj = {
        numero_ocp: sliceState?.ocp?.numeroOcp,
        cantidad,
        unidad_id: unidad.id,
        producto_id: sliceState.productToAsign.id,
        precio_compra_id: sliceState.productToAsign.costoAdquisicionId,
        divisa_id: sliceState?.divisa?.id,
        vigente: true,
      };
    }
    dispatch(postRelationProducto({
      requestObj, reduxStateTree, selectedValue, asignedListString, authCtx,
    }));

    await pause(500)


    setUseEffectVariable(useEffectVariable + 1);
    getRelationHandler(subtotalOCP_URL, 'subtotal');
    fetchOrdenCompraProveedor();
  };

  const handleDate = (e) => {
    dispatch(editOrdenCompraProveedorActions.change({
      field: 'fechaEntrega', value: e.target.value,
    }));
    handlePutOrdenCompraProveedor({ fechaEntrega: e.target.value });
  };

  return (<React.Fragment>
    {sliceState.error || sliceState.loading ? (
      <LifeCycleFeedback sliceState={sliceState} />) : (
      <div className='flex-1 flex flex-col overflow-hidden'>
        {/* Main content */}
        <FlexContainer className='flex-1 items-stretch  overflow-hidden'>
          <main className='flex-1 overflow-y-auto'>
            {/* Primary column */}
            {/* {console.log('->sliceState')}*/}
            {/*{console.log(sliceState)}*/}
            <FlexContainer className='flex-col'>

              <div className=' '>
                {sliceState?.ocp?.vigente ? (<AsignProductoToOC
                  //UI
                  cardTitle={'Añade Productos'}
                  inputLabel={'Añade Productos'}
                  //URL
                  fetchUrl={'views/ordenes-compra-proveedor/productos-for-ocp'}
                  //Redux
                  asignTree={'productToAsign'}
                  removeTree={'productToRemoveAsign'}
                  filterWhereId={sliceState?.ocp?.proveedor.id}
                  filterWhere={'proveedorId'}
                  divisaId={sliceState?.ocp?.divisa?.id}
                  valueToAsignSelector={sliceState?.productToAsign}
                  asignedListSelector={sliceState?.productosOrdenCompra}

                  asignedListString={'productosOrdenCompra'}
                  //Select Options
                  nameOfPropertyToAsign={'simbolo'}
                  //Function Handlers

                  handleSelect={handleAsingProducto}
                  handleCancel={handleCancelAsingProducto}
                  handlePostRelation={handlePostRelation}
                  handleUpdate={handlePutOrdenCompraProveedor}
                />) : ('')}
                <DocsCard>
                  <DocsCardLayoutMenu>
                    <DotsDropdown vigente={sliceState?.ocp?.vigente}>
                      <React.Fragment>
                        {sliceState?.ocp?.vigente &&
                          sliceState.productosOrdenCompra?.length > 0 &&
                          (<DropdownItemButton
                            onClick={() => setConfirmModalIsVisible(true)}>
                            <CheckIcon
                              className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                              aria-hidden='true'
                            />
                            Confirmar OCP
                          </DropdownItemButton>)}
                        {!sliceState?.ocp?.vigente && (
                          <>
                            {/*{sliceState.productosOrdenCompra?.length > 0 &&*/}
                            {/*  (<React.Fragment>*/}
                            {/*    <DropdownItemButton>*/}
                            {/*      <PencilAltIcon*/}
                            {/*        className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'*/}
                            {/*        aria-hidden='true'*/}
                            {/*      />*/}
                            {/*      <PDFDownloadLink*/}
                            {/*        document={<OcpPDF*/}
                            {/*          estatus={sliceState?.ocp?.vigente*/}
                            {/*            ? 'POR CONFIRMAR'*/}
                            {/*            : 'CONFIRMADA'}*/}
                            {/*          mely={sliceState?.inventario?.nombre ===*/}
                            {/*          'MELYUSA'*/}
                            {/*            ? { ...datosMelyUsa }*/}
                            {/*            : { ...datosMelyMx }}*/}
                            {/*          info={{*/}
                            {/*            titulo: 'ORDEN DE COMPRA',*/}
                            {/*            producto: 'PROVEEDORES',*/}
                            {/*            numero: sliceState?.entrada?.id,*/}
                            {/*            tipo: 'OCP',*/}
                            {/*            fechaEntrega: sliceState?.ocp?.fechaEntrega*/}
                            {/*              ? sliceState?.ocp?.fechaEntrega?.split(*/}
                            {/*                'T')[0]*/}
                            {/*              : '',*/}
                            {/*            fecha: sliceState?.ocp?.createdAt?.split(*/}
                            {/*              'T')[0],*/}
                            {/*            enCalidadDe: sliceState?.enCalidadDe?.nombre?.toUpperCase(),*/}
                            {/*          }}*/}
                            {/*          numeroOC={sliceState?.ocp?.numeroOcp}*/}
                            {/*          list={sliceState?.productosOcp}*/}
                            {/*          totalTexto={sliceState?.totalTexto}*/}
                            {/*          subtotal={sliceState?.subtotal}*/}
                            {/*          total={sliceState?.total}*/}
                            {/*          divisa={sliceState?.divisa?.nombre}*/}
                            {/*          iva={sliceState?.iva}*/}
                            {/*          certificacion={*/}

                            {/*            {*/}
                            {/*              codigo: 'FOINO38',*/}
                            {/*              version: '00',*/}
                            {/*              fechaEmision: '01-NOV-23',*/}
                            {/*              fechaRevision: '01-NOV-23',*/}
                            {/*            }*/}

                            {/*          }*/}
                            {/*          proveedor={{*/}
                            {/*            razonSocial: sliceState?.proveedor?.razonSocial,*/}
                            {/*            rfc: sliceState?.proveedor?.rfc,*/}
                            {/*            direccionFiscal: {*/}
                            {/*              calle: sliceState?.proveedor?.direccion?.calle,*/}
                            {/*              numero: sliceState?.proveedor?.direccion?.numero ??*/}
                            {/*                '',*/}
                            {/*              colonia: sliceState?.proveedor?.direccion?.colonia ??*/}
                            {/*                '',*/}
                            {/*              ciudad: sliceState?.proveedor?.direccion?.ciudad ??*/}
                            {/*                '',*/}
                            {/*              estado: sliceState?.proveedor?.direccion?.estado,*/}
                            {/*              cp: sliceState?.proveedor?.direccion?.cp,*/}
                            {/*              pais: sliceState?.proveedor?.direccion?.pais,*/}
                            {/*            },*/}
                            {/*          }}*/}
                            {/*        />}*/}
                            {/*        fileName={`OCP_${sliceState?.ocp?.numeroOcp}.pdf`}*/}
                            {/*      >*/}
                            {/*        {({ blob, url, loading, error }) => loading*/}
                            {/*          ? 'Preparando Documento...'*/}
                            {/*          : 'Descargar'}*/}
                            {/*      </PDFDownloadLink>*/}
                            {/*    </DropdownItemButton>*/}
                            {/*  </React.Fragment>)}*/}
                            {isLoading && <Spinner />}
                            {commandButtonError && (<button
                              className={'bg-red-500  color-white'}
                              onClick={() => {
                                window?.location?.reload()
                              }}
                            >

                              Se produjo un Error {`${commandButtonError}`}
                            </button>)}


                            {!sliceState?.document?.id &&
                              sliceState.productosOrdenCompra?.length &&
                              !isLoading && !commandButtonError > 0 &&
                              <DropdownButtonWithIcon text={'Crear documento'}
                                                      icon={'documentDownload'}
                                                      onClick={async () => {
                                                        const regenerate = { date: new Date() };
                                                        console.log('SliceSTate',sliceState);
                                                        const request = {
                                                          requestObj: documentRequestObj(
                                                            { ...regenerate, ...sliceState }),
                                                        };
                                                        console.log("requestObj--->",request)
                                                        setIsLoading(true);
                                                        const document = await postDocumentCommand(
                                                          dispatch,
                                                          postDocument,
                                                          request);
                                                        if (document?.name) {
                                                          setIsLoading(false);
                                                        } else {
                                                          setCommandButtonError(
                                                            ' al crear el documento');
                                                        }
                                                      }}
                              />}


                            {sliceState?.document?.id && !isLoading &&
                              !commandButtonError &&
                              <DropdownButtonWithIcon
                                text={'Descargar'}
                                icon={'documentAdd'}
                                onClick={async () => {

                                  const documentName = sliceState.document?.name;
                                  const documenId = sliceState.document?.id;

                                  // console.log('event->>',event)

                                  try {
                                    setIsLoading(true);
                                    const document = await downloadPDF(
                                      documenId,
                                      documentName);
                                    setIsLoading(false);

                                  } catch (err) {
                                    setCommandButtonError(
                                      'al descargar el documento');
                                  }

                                }} />}


                            {sliceState?.document?.id && !isLoading &&
                              !commandButtonError &&
                              <DropdownButtonWithIcon text={'Enviar por correo'}
                                                      icon={'mail'}
                                                      onClick={async () => {
                                                        const correo = sliceState?.ocp?.proveedor?.correoElectronico;
                                                        if (!correo) {
                                                          setCommandButtonError(
                                                            ', No esta dado de alta el correo receptor');
                                                          return;
                                                        }
                                                        // console.log("correo",correo)
                                                        const documentName = sliceState.document.name;
                                                        // console.log('event----slicestate.ocp->>',documentName)
                                                        const event = ocpSendEmailFactory(
                                                          correo, documentName,
                                                          'ocp');
                                                        // console.log('event->>',event)
                                                        setIsLoading(true);
                                                        dispatch(postEmail(
                                                          { requestObj: event }))
                                                          .unwrap()
                                                          .then(res => {
                                                            const response = res;
                                                            console.log(
                                                              'res->.',
                                                              res);
                                                            if (response.data.status !==
                                                              'failed') {
                                                              setIsLoading(
                                                                false);
                                                            } else {
                                                              setCommandButtonError(
                                                                ' al enviar el documento');
                                                            }
                                                            console.log(
                                                              'sent email-->>>',
                                                              res);
                                                            console.log(
                                                              'sent email-->>>',
                                                              res);
                                                            console.log(
                                                              'sent email-->>>',
                                                              res);
                                                          });
                                                      }} />}


                            {/*TODO: borar documento*/}
                            {/*TODO: Crear un slice en redux que maneje todos lo relacionado a documentos y su lógica. */}
                            {/*{sliceState?.document?.id &&  !isLoading && !commandButtonError && (<DropdownButtonWithIcon text={'Borrar Documento'} icon={'trash'} disabled={true}  onClick={*/}
                            {/*    async () => {*/}
                            {/*      const correo=sliceState?.occ.cliente.correoElectronico*/}
                            {/*      const documentName=sliceState.document.name*/}
                            {/*      // TODO BORRAR DOCUMENT :dispatch(postEmail({requestObj:event}))*/}
                            {/*    }}>*/}
                            {/*    Borrar Documento*/}
                            {/*  </DropdownButtonWithIcon>*/}

                            {/*)}*/}


                            {!sliceState.productosOrdenCompra && !isLoading &&
                              !commandButtonError && (<DropdownItemButton
                                onClick={() => setDeleteModalIsVisible(true)}>
                                <TrashIcon
                                  className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                  aria-hidden='true'
                                />
                                Borrar OCP
                              </DropdownItemButton>)}
                          </>
                        )}
                      </React.Fragment>
                    </DotsDropdown>
                  </DocsCardLayoutMenu>

                  {confirmModalIsVisible && (<ConfirmModal
                    title={'Confirmar Orden de compra proveedor'}
                    message={'Los productos se podrán ingresar al inventario pero ya no podrás editar el documento, '}
                    onConfirm={() => {
                      handleCerrarOcp();
                    }}
                    onCancel={() => setConfirmModalIsVisible(false)}
                  />)}
                  {deleteModalIsVisible && (<DeleteModal
                    title={'Borrar OCP'}
                    message={'OCP ' + sliceState?.ocp?.numeroOcp}
                    onConfirm={(sliceState) => {
                      console.log('click');

                      handleDelete(sliceState.id);

                      setDeleteModalIsVisible(false);
                    }}
                    onCancel={() => setDeleteModalIsVisible(false)}
                  >
                    Esta Acción no puede desacerse
                  </DeleteModal>)}
                  <DocsCardLayoutLogo>
                    <LogoMely className='h-16 w-auto' />
                  </DocsCardLayoutLogo>

                  <DocsCardLayoutDatosDoc>
                    <DocsCardDatosDoc
                      title={'OC PROVEEDOR'}
                      number={sliceState?.ocp?.numeroOcp}
                      date={sliceState?.ocp?.createdAt?.split('T')[0]}
                    />
                  </DocsCardLayoutDatosDoc>
                  <DocsCardLayoutInfoMely>
                    <InfoMely
                      inventario={sliceState?.inventario?.nombre}
                      datosMely={sliceState?.inventario?.nombre === 'MELYUSA'
                        ? datosMelyUsa
                        : datosMelyMx}
                    />
                  </DocsCardLayoutInfoMely>

                  <DocsCardLayoutTwoCols>
                    <DocsCardDatosFiscales
                      sliceState={sliceState?.ocp?.proveedor} />

                    <div className='col-span-1  pr-10'>
                      <div className='text-right'>
                        <span className='font-light mr-2'>Divisa:</span>
                        {sliceState?.divisa?.nombre}
                      </div>
                      <div className='text-right'>
                        <span className='font-light mr-2'>Inventario:</span>
                        {sliceState?.inventario?.nombre}
                      </div>

                      <div className='ml-auto' style={{ maxWidth: '170px' }}>
                        <SelectDate
                          reduxStateTree={'unidades'}
                          action={handleDate}
                          url={'unidades'}
                          entityPropertyToSelect={'nombre'}
                          disabled={!sliceState?.ocp?.vigente}
                        >
                          <InputCornerHint
                            label={'Fecha de entrega'}
                            value={sliceState?.ocp?.fechaEntrega
                              ? sliceState?.ocp?.fechaEntrega?.split('T')[0]
                              : ''}
                          />
                        </SelectDate>
                      </div>
                    </div>
                  </DocsCardLayoutTwoCols>
                  {/*<div>{JSON.stringify(sliceState.test)}</div>*/}
                  <div className='col-span-6'>
                    <AsignedProductosTable
                      iva={sliceState.iva}
                      //Redux
                      vigente={sliceState?.ocp?.vigente}
                      asignedListSelector={sliceState?.productosOcp}
                      asignTree={'productToAsign'}
                      asignedListString={'productosOrdenCompra'}
                      //Function Handlers
                      handleErrase={handleEraseRelation}
                      sliceState={sliceState}
                      handleUpdateProductoOc={() => {
                        getRelationHandler(relationProducto_URL,
                          'productosOrdenCompra');
                      }}
                      type='ocp'
                    />
                  </div>
                </DocsCard>
              </div>
            </FlexContainer>
          </main>
        </FlexContainer>
        {/*
        <PDFViewer>
          <OcpPDF
            mely={
              sliceState?.inventario?.nombre === 'MELYUSA'
                ? { ...datosMelyUsa }
                : { ...datosMelyMx }
            }
            info={{
              titulo: 'ORDEN DE COMPRA',
              producto: 'PROVEEDORES',
              numero: sliceState?.entrada?.id,
              tipo: 'OCP',
              fecha: sliceState?.ocp?.fechaEntrega,
              enCalidadDe:
                sliceState?.enCalidadDe?.nombre?.toUpperCase(),
            }}
            numeroOC={sliceState?.ocp?.numeroOcp}
            list={sliceState?.productosOrdenCompra}
            cantidadLetra={sliceState.ocp?.cantidadLetra}
            subtotal={sliceState?.subtotal}
            iva={sliceState?.ocp?.iva}
            proveedor={{
              razonSocial:
                sliceState?.proveedor?.razonSocial,
              direccionFiscal: {
                calle:
                  sliceState?.proveedor?.direccionFiscal
                    ?.calle,
                numero:
                  sliceState?.proveedor?.direccionFiscal
                    ?.numero,
                ciudad:
                  sliceState?.proveedor?.direccionFiscal
                    ?.ciudad,
                estado:
                  sliceState?.proveedor?.direccionFiscal
                    ?.estado,
                cp: sliceState?.proveedor?.direccionFiscal
                  ?.cp,
                pais: sliceState?.proveedor?.direccionFiscal
                  ?.pais,
              },
            }}
          />
        </PDFViewer> */}
      </div>)}
  </React.Fragment>);
};
export default EditOCP;

//TODO anañir funcionalidad para filtrar  lista de productos  en base a texto ingresado
