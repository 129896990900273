export const iterateArrayWithFnCall = (arr, value, fn) => {
  const newValue = fn(arr, value);

  if (arr.length === 0) return newValue;

  //Create a new array without the last index
  const newArr = arr.slice(0, -1);
  if (newArr.length === 0) return newValue;

  return iterateArrayWithFnCall(newArr, newValue, fn);
};
