import React from 'react';
/* This example requires Tailwind CSS v2.0+ */
import { XCircleIcon} from '@heroicons/react/solid';

export const ErrorAlert = ({ error, onClick }) => (
  <div className={`rounded-md bg-red-50 p-4`}>
    <div className='flex'>
      <div className='flex-shrink-0'>
        <button onClick={onClick}>
          <XCircleIcon
            className={`h-5 w-5 text-red-400`}
            aria-hidden='true'
          /></button>
      </div>
      <div className='ml-3'>
        <h3 className={`text-sm font-medium text-red-800`}>
          {error.message}
        </h3>
        <div className='mt-2 text-sm text-red-700'>

          {error.stack}

        </div>

      </div>
    </div>
  </div>
);

export const ErrorAlertFromApi = ({ error, onClick }) => (
  <div className={`rounded-md bg-red-50 p-4`}>
    <div className='flex'>
      <div className='flex-shrink-0'>
        <button onClick={onClick}>
          <XCircleIcon
            className={`h-5 w-5 text-red-400`}
            aria-hidden='true'
          /></button>
      </div>
      <div className='ml-3'>
        <h3 className={`text-sm font-medium text-red-800`}>
          {error.statusCode}
        </h3>
        <div className='mt-2 text-sm text-red-700'>

          {error.message}

        </div>

      </div>
    </div>
  </div>
);
