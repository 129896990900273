import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AuthContext from '../../store/auth-context';
import { EstadoDeCuenta } from '../EstadoDeCuenta/EstadoDeCuenta';
import { getDivisa } from '../EstadoDeCuenta/utils/get-divisa';

import {
  getEdoCuentaCliente,
  edoCuentaClienteActions as sliceActions
} from '../../store/edoCuentaCliente-slice';
const EdoCuentaCliente = () => {
  const [divisa,setDivisa]=useState('')
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const sliceState = useSelector((state) => state.edoCuentaCliente);
  const displayAll = useSelector((state) => state.edoCuentaCliente.displayAll);

  const location = useLocation();
  const urlParam = location.pathname.split('/')[3];
  const buildRoutePath = () => `${urlParam}/estados-de-cuenta/${divisa}?showAll=${sliceState.displayAll}`;

  useEffect(() => {
    setDivisa(getDivisa(location))
    dispatch(getEdoCuentaCliente(
      { urlParam, authCtx, routePath: `/${buildRoutePath()}` }));

  }, [displayAll]);



  return <EstadoDeCuenta divisa={divisa} sliceState={sliceState} displayAll={displayAll} sliceActions={sliceActions} entidadComercial={"cliente"}/>
};
export default EdoCuentaCliente;
