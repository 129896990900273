import React from 'react';
import {
  DefaultItemsListClientRoute,
} from '../SharedUi/DefaultItemsListClientRoute';
import {
  getItems,
  handleFilter,
  fetchAllActions as sliceActions,
} from '../../store/fetch-all-slice';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import TableRow from '../TailwindUI/TableRow';
import { Link, useLocation } from 'react-router-dom';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const tableHeaderMapFn = (obj, idx) => {
  const newObj = { ...obj };
  delete newObj['tipo'];
  delete newObj['direccionId'];
  return newObj;
};
const tableBodyMapFn = (obj, idx) => {
  const newObj = { ...obj };
  delete newObj['tipo'];
  delete newObj['direccionId'];
  return newObj;
};

const buildRoute = (location) => {
  console.log('buildRoute-----');
  console.log(location.pathname);
  const urlParam = location.pathname.split('/')[2] ?? '';
  console.log(urlParam);
  const isCuentaPorCobrar = location.pathname.includes('proveedores');
  const routePathBase = isCuentaPorCobrar
    ? 'proveedores/view-cuentas-por-pagar-pagos'
    : 'clientes/view-cuentas-por-cobrar-pagos';
  console.log(isCuentaPorCobrar);
  console.log('routePathBase-----');
  console.log('routePathBase-----');
  console.log(routePathBase);

  return urlParam ? `${routePathBase}/${urlParam}` : routePathBase;
};

const customDisplayMapFn = (item, index) => {

  return (
    <React.Fragment>
      <TableRow key={item.id}
                className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
        <TableBodyCellAuto
          url={'cuentas-por-pagar'}
          list={item}
          // indexChildren={[[1, (value) => <Link to={'test'}>{value}</Link>]]}
        />
      </TableRow>
    </React.Fragment>
  );
};

const filterCondition = (state, value) => {
  console.log(state);
  let condition = (
    String(state?.cuentaPorPagarId).toUpperCase().includes(value) ||
    String(state?.razonSocial).toUpperCase().includes(value) ||
    String(state?.fechaFactura).toUpperCase().includes(value) ||
    String(state?.fechaVencimiento).toUpperCase().includes(value) ||
    String(state?.factura).toUpperCase().includes(value)
  );
  return condition;
};

const PagosProveedores = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    getItems,
    handleFilter,
    buildRouteFn: buildRoute,
    customDisplayMapFn,
    filterCondition,

    orderByOptions: [
      '',
      'cuentaPorPagarId',
      'fechaFactura',
      'fechaVencimiento',
      'factura',
    ],
  });

};
export default PagosProveedores;
