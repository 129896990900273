import React, { useState, useEffect, useContext, Fragment } from 'react';
import { apiURL } from '../../Util/apiURL';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import EntradaPDF from '../DocsToPrint/EntradaPDF';
import axios from 'axios';
import SelectFetch3 from '../TailwindUI/SelectFetch3';
import { PencilAltIcon, TrashIcon, CheckIcon } from '@heroicons/react/solid';
import {
  getEntrada,
  putEntrada,
  editEntradaActions,
  getRelation,
  postRelationProducto,
  deleteEntrada,
  deleteRelationProducto,
  cerrarProductoOcp,
  cerrarEntrada,
} from '../../store/editEntrada-slice';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import ProductosEntrada from './ProductosEntrada';
import AsignProductoOCPtoEntrada from './AsignProductoOCPtoEntrada';
import DotsDropdown from '../DropdownMenu/DotsDropdown';
import DropdownItemButton from '../DropdownMenu/DropdownItemButton';
import InfoMely, { datosMelyMx, datosMelyUsa } from '../InfoMely/InfoMely';
import DocsCard from '../SharedUi/DocsCard';

import {
  DocsCardLayoutLogo,
  DocsCardLayoutDatosDoc,
  DocsCardLayoutMenu,
  DocsCardLayoutTwoCols,
  DocsCardDatosFiscales,
  DocsCardLayoutInfoMely,
} from '../SharedUi/DocsCardLayout';
import DocsCardDatosDoc from '../SharedUi/DocsCardDatosDoc';
import LogoMely from '../SharedUi/LogoMely';
import IconButton from '../TailwindUI/IconButton';
import DeleteModal from '../UI/Modals/DeleteModal';
import ConfirmModal from '../UI/Modals/ConfirmModal';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';
import { timeStampToDDMMYY } from '../../Util/get-date';
import { AddProductToEntrada } from './AddProductToEntrada';

const EditEntrada = () => {
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
  const [addProductActive, setAddProductActive] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [useEffectVariable, setUseEffectVariable] = useState(0);
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    fetchEntrada();

    getRelationHandler(relationProductosEntrada_URL, 'productosEntrada');
  }, []);

  useEffect(() => {
    setFirstLoad(false);
    if (!firstLoad) {
      fetchEntrada();
    }
  }, [useEffectVariable]);

  const sliceState = useSelector((state) => state.editEntrada);
  const apiHOST = apiURL();

  const urlParam = location.pathname.split('/')[2];
  const requestUrl = 'entradas';
  const updateUrl = `entradas/${sliceState?.entrada?.id}`;
  const deleteUrl = `entradas/${sliceState?.entrada?.id}`;
  const relationProducto_URL = `views/entradas/productos-ocp/for-entrada/?productoId=${sliceState?.productToSearch?.id}&proveedorId=${sliceState.proveedor?.id}`;
  const relationProductosEntrada_URL = `views/productos-entrada/${urlParam}`;

  const filterArrByObjPropertyName = (externalArray, propertyname) => {
    const arr = [];
    externalArray.forEach((i, index) => {
      let filter = arr.filter((e) => e === Number(i[propertyname]));
      if (filter.length === 0) {
        arr.push(i[propertyname]);
      }
    });
    return arr;
  };

  const fetchEntrada = () => {
    dispatch(getEntrada({ urlParam, authCtx }));
  };

  const handleResetProductosOrdenescompra = () => {
    dispatch(editEntradaActions.resetProductosOrdenCompra());
  };
  const handleResetPrpductosToSearch = () => {
    dispatch(editEntradaActions.resetProductToSearch());
  };
  const handleResetAddProduct = () => {
    handleResetPrpductosToSearch();
    handleResetProductosOrdenescompra();
    setAddProductActive(false);
  };

  const handlePutOrdenCompraProveedor = () => {
    const requestObj = {};
    dispatch(
      putEntrada({
        requestObj,
        requestUrl: updateUrl,
        authCtx,
      }),
    );
  };

  const handleDelete = () => {
    console.log({
      requestUrl: deleteUrl,
      sliceState,
      history,
      authCtx,
    });
    dispatch(
      deleteEntrada({
        requestUrl: deleteUrl,
        sliceState,
        history,
        authCtx,
      }),
    );
  };
  //Relations
  ////Redux Actions
  const handleAsingProducto = (e) => {
    dispatch(editEntradaActions.asign({ ...e }));
  };
  const handleCancelAsingProducto = (e) => {
    dispatch(editEntradaActions.cancelAsign({ ...e }));
  };
  //Relations AsyncTHunks

  const getRelationHandler = (requestUrl, reduxStateTree) => {
    dispatch(
      getRelation({
        requestUrl,
        reduxStateTree,
        authCtx,
      }),
    );
  };
  const handleCerraProductoOcp = (e) => {
    dispatch(
      cerrarProductoOcp({
        primarykey: e.valueToUnasign.id,
        authCtx,
      }),
    );
    setUseEffectVariable(useEffectVariable + 1);
  };
  const handleEraseRelation = (e) => {
    console.log('errase relation!!!-------------<<<<<<<<<<<<<<<<<');
    console.log(e);
    console.log('errase relation!!!-------------<<<<<<<<<<<<<<<<<');
    const requestObj = {
      id: e.valueToUnasign.id,
    };
    const { reduxStateTree, asignedListString, asignedListSelector } = e;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    console.log('event');
    console.log(e);

    dispatch(
      deleteRelationProducto({
        requestObj,
        reduxStateTree,
        asignedListString,
        asignedListSelector,
        authCtx,
      }),
    );
    setUseEffectVariable(useEffectVariable + 1);
  };

  const handleSelect = (reduxStateTree, value, selectValues) => {
    handleResetProductosOrdenescompra();
    return editEntradaActions.select({
      field: reduxStateTree,
      selectedValue: value,
      values: selectValues,
    });
  };

  const handlePostRelation = (e) => {
    dispatch(editEntradaActions.resetProductosOrdenCompra());
    let requestObj = {};
    const { reduxStateTree, selectedValue, asignedListString } = e;
    console.log('reduxStateTree----------');
    console.log(reduxStateTree);
    if (reduxStateTree === 'productToAsign') {
      requestObj = { ...selectedValue };
    }
    dispatch(
      postRelationProducto({
        requestObj,
        reduxStateTree,
        selectedValue,
        asignedListString,
        authCtx,
      }),
    );
    setAddProductActive(false);
    handleResetProductosOrdenescompra();
    handleResetPrpductosToSearch();
    // setUseEffectVariable(useEffectVariable + 1);
  };

  const handleCreateCuentaPorPagar = async (request) => {
    console.log('Create cuenta por pagar');
    console.log(request);

    try {
      const response = await axios.post(`${apiHOST}views/cuentas-por-pagar`,
        request);
      console.log('response Create cuenta por Pagar');
      console.log(response);
      return history.push(`/cuentas-por-pagar/${response.data.id}`);
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      {/* <div style={{ minWidth: '30vw', height: '100vh' }}>

      <PDFViewer width={600} height={600}>
        <EntradaPDF
          mely={
            sliceState?.inventario?.nombre === 'MELYUSA'
              ? { ...datosMelyUsa }
              : { ...datosMelyMx }
          }
          info={{
            titulo: 'NOTA DE ENTRADA',
            producto: ' CAJAS',
            numero: sliceState?.entrada?.id,
            tipo: 'OCP',
            fecha: sliceState?.entrada?.createdAt?.split('T')[0],
            enCalidadDe: sliceState?.entrada?.enCalidadDe?.toUpperCase(),
          }}
          numeroOC={filterArrByObjPropertyName(sliceState.productosEntrada, 'numeroOcp')}
          list={sliceState?.productosEntrada}
          cliente={{
            razonSocial: sliceState?.proveedor?.razonSocial,
            direccionFiscal: {
              calle: sliceState?.proveedor?.direccionFiscal?.calle,
              numero: sliceState?.proveedor?.direccionFiscal?.numero,
              ciudad: sliceState?.proveedor?.direccionFiscal?.ciudad,
              estado: sliceState?.proveedor?.direccionFiscal?.estado,
              cp: sliceState?.proveedor?.direccionFiscal?.cp,
              pais: sliceState?.proveedor?.direccionFiscal?.pais,
            },
          }}
        />
      </PDFViewer>


    </div> */}
      <React.Fragment>
        {sliceState.error || sliceState.loading ? (
          <LifeCycleFeedback sliceState={sliceState} />
        ) : (
          <div className='flex-1 flex flex-col overflow-hidden'>
            {/* Main content */}

            <FlexContainer className='flex-1 items-stretch  overflow-hidden'>
              <main className='flex-1 overflow-y-auto'>
                {/*TAB CODE */}
                {/*TAB CODE*/}
                {/* Primary column */}

                {/* <div>{JSON.stringify(sliceState)}</div> */}
                <FlexContainer className='flex-col'>
                  {sliceState?.entrada?.vigente ? (
                    <div className=''>
                      {!addProductActive ? (
                        <div className='mt-4'>
                          <IconButton
                            onClick={() => setAddProductActive(true)}
                            color='orangeMely'
                            icon='+'
                          />
                        </div>
                      ) : <AddProductToEntrada

                        sliceState={sliceState}
                        handleResetAddProduct={handleResetAddProduct}
                        handleSelect={handleSelect}
                        handleResetPrpductosToSearch={handleResetPrpductosToSearch}
                        getRelationHandler={getRelationHandler}
                        relationProducto_URL={relationProducto_URL}
                        AsignProductoOCPtoEntrada={<AsignProductoOCPtoEntrada
                          //UI
                          cardTitle={
                            sliceState?.productToSearch?.simbolo
                              ? `Ordenes de Compra que contienen`
                              : ''
                          }
                          cardTitleProduct={sliceState?.productToSearch?.simbolo}
                          inputLabel={'Añade Productos'}
                          //Redux
                          asignTree={'productToAsign'}
                          valueToAsignSelector={sliceState?.productToAsign}
                          asignedListSelector={sliceState?.productosOrdenCompra}
                          asignedListString={'productosEntrada'}
                          entradaId={urlParam}
                          proveedor={sliceState?.proveedor}
                          enCalidadDe={sliceState?.entrada?.enCalidadDe}
                          //Function Handlers

                          handleSelect={handleAsingProducto}
                          handleCancel={handleCancelAsingProducto}
                          handlePostRelation={handlePostRelation}
                          handleCerrar={handleCerraProductoOcp}
                          handleUpdate={handlePutOrdenCompraProveedor}
                        />}

                      />
                      }
                    </div>
                  ) : (
                    ''
                  )}
                  {/* //FIXME Bug with vigente */}
                  <div>
                    <DocsCard>
                      <DocsCardLayoutMenu>
                        <DotsDropdown vigente={sliceState?.entrada?.vigente}>
                          {!sliceState?.entrada?.vigente && (
                            <DropdownItemButton>
                              <PencilAltIcon
                                className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                aria-hidden='true'
                              />

                              <PDFDownloadLink
                                document={
                                  <EntradaPDF
                                    mely={
                                      sliceState?.productosEntrada[0]?.inventario ===
                                      'MELYUSA'
                                        ? { ...datosMelyUsa }
                                        : { ...datosMelyMx }
                                    }
                                    info={{
                                      titulo: 'NOTA DE ENTRADA',
                                      producto: ' CAJAS',
                                      numero: sliceState?.entrada?.id,
                                      tipo: 'OCP',
                                      fecha: sliceState?.entrada?.createdAt
                                        ? timeStampToDDMMYY(
                                          sliceState?.entrada?.createdAt)
                                        : '',
                                      enCalidadDe: sliceState?.entrada?.enCalidadDe,
                                    }}
                                    numeroOC={filterArrByObjPropertyName(
                                      sliceState?.productosEntrada,
                                      'numeroOcp',
                                    )}
                                    list={sliceState?.productosEntrada}
                                    cliente={{
                                      razonSocial: sliceState?.proveedor?.razonSocial,
                                      direccionFiscal: {
                                        calle: sliceState?.proveedor?.direccionFiscal?.calle,
                                        numero: sliceState?.proveedor?.direccionFiscal?.numero,
                                        ciudad: sliceState?.proveedor?.direccionFiscal?.ciudad,
                                        estado: sliceState?.proveedor?.direccionFiscal?.estado,
                                        cp: sliceState?.proveedor?.direccionFiscal?.cp,
                                        pais: sliceState?.proveedor?.direccionFiscal?.pais,
                                      },
                                    }}
                                  />
                                }
                                fileName={`Entrada_${sliceState?.entrada?.id}.pdf`}
                              >
                                {({ blob, url, loading, error }) =>
                                  loading
                                    ? 'Preparando Documento...'
                                    : 'Descargar'
                                }
                              </PDFDownloadLink>
                            </DropdownItemButton>
                          )}

                          {sliceState?.entrada?.vigente &&
                            sliceState.productosEntrada.length > 0 && (
                              <DropdownItemButton
                                onClick={() => setConfirmModalIsVisible(true)}>
                                <CheckIcon
                                  className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                  aria-hidden='true'
                                />
                                Confirmar Entrada
                              </DropdownItemButton>
                            )}
                          {sliceState.productosEntrada.length === 0 && (
                            <DropdownItemButton
                              onClick={() => setDeleteModalIsVisible(true)}>
                              <TrashIcon
                                className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                aria-hidden='true'
                              />
                              Borrar Entrada
                            </DropdownItemButton>
                          )}
                        </DotsDropdown>
                      </DocsCardLayoutMenu>

                      {confirmModalIsVisible && (
                        <ConfirmModal
                          title={'Confirmar Entrada'}
                          message={
                            'Podrás imprimir la nota de entrada pero ya no podrás editar el documento.'
                          }
                          onConfirm={() => {
                            dispatch(
                              cerrarEntrada({
                                entrada: sliceState.entrada,
                                productosEntrada: sliceState.productosEntrada,
                                authCtx,
                              }),
                            );
                          }}
                          onCancel={() => setConfirmModalIsVisible(false)}
                        />
                      )}
                      {deleteModalIsVisible && (
                        <DeleteModal
                          title={'Borrar Entrada'}
                          onConfirm={(sliceState) => {
                            handleDelete(sliceState.id);
                            setDeleteModalIsVisible(false);
                          }}
                          onCancel={() => setDeleteModalIsVisible(false)}
                        >
                          Esta Acción no puede desacerse
                        </DeleteModal>
                      )}

                      <DocsCardLayoutLogo>
                        <LogoMely className='h-16 w-auto' />
                      </DocsCardLayoutLogo>

                      <DocsCardLayoutDatosDoc>
                        <DocsCardDatosDoc
                          title={'ENTRADA'}
                          number={sliceState?.entrada?.id}
                          date={
                            sliceState?.entrada?.createdAt
                              ? timeStampToDDMMYY(
                                sliceState?.entrada?.createdAt)
                              : ''
                          }
                        />
                      </DocsCardLayoutDatosDoc>
                      <DocsCardLayoutInfoMely>
                        <InfoMely
                          inventario={sliceState?.inventario?.nombre}
                          datosMely={
                            sliceState?.productosEntrada[0]?.inventario ===
                            'MELYUSA'
                              ? datosMelyUsa
                              : datosMelyMx
                          }
                        />
                      </DocsCardLayoutInfoMely>

                      <DocsCardLayoutTwoCols>
                        <DocsCardDatosFiscales
                          sliceState={sliceState?.proveedor} />

                        <div className='col-span-1  pr-10 text-right'>
                          <div>
                            <span className='mr-2'>EN CALIDAD DE:</span>
                            <span className='font-medium'>
                              {` ${sliceState?.entrada?.enCalidadDe}`}
                            </span>
                          </div>
                          <div>
                            <span className='mr-2'>FECHA:</span>
                            <span className='font-medium'>
                              {sliceState?.entrada?.createdAt
                                ? timeStampToDDMMYY(
                                  sliceState?.entrada?.createdAt)
                                : ''}
                            </span>
                          </div>
                        </div>
                      </DocsCardLayoutTwoCols>

                      <div className='col-span-6 '>
                        <>
                          <ProductosEntrada
                            //UI

                            //Redux
                            asignTree={'productToAsign'}
                            removeTree={'productToRemoveAsign'}
                            valueToAsignSelector={sliceState?.productToAsign}
                            asignedListSelector={sliceState?.productosEntrada}
                            asignedListString={'productosEntrada'}
                            //Table
                            cantidadPropertyName={'cantidadEntrada'}
                            //Function Handlers
                            vigente={sliceState?.entrada?.vigente}
                            handleSelect={handleAsingProducto}
                            handleCancel={handleCancelAsingProducto}
                            handlePostRelation={handlePostRelation}
                            handleErrase={handleEraseRelation}
                            handleUpdateProductoEntrada={() => {
                              getRelationHandler(relationProductosEntrada_URL,
                                'productosEntrada');
                            }}
                            handleCreateCuentaPorPagar={handleCreateCuentaPorPagar}
                          />
                        </>
                      </div>

                      {
                        //#endregion
                      }
                    </DocsCard>
                  </div>
                </FlexContainer>
              </main>
            </FlexContainer>

            {
              //#region Productos Entrada
            }

            {
              //#endregion
            }
          </div>
        )}
      </React.Fragment>
    </>
  );
};
export default EditEntrada;
