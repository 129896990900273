import React from 'react';
import { InputCornerHint } from '../SharedUi/Inputs';

export const InputHelper = ({
  label = '',
  type = 'text',
  value = '',
  onChange = null,
  onBlur = null,
}) => (
  <InputCornerHint label={label} type={type} value={value} onChange={onChange} onBlur={onBlur} />
);
