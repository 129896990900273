import React from 'react';
import {
  getItems,
  handleFilter,
  fetchAllActions as sliceActions,
} from '../../store/fetch-all-slice';
import TableRow from '../TailwindUI/TableRow';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import { Link } from 'react-router-dom';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';


const customDisplayMapFn = (entrada, idx) => {
  const wrapWithLink = (urlPath, content) => (
    <span className="text-orange-600 font-medium">
      <Link to={urlPath}>{content}</Link>
    </span>
  );

  let coloredRowBg;
  switch (entrada.cpp) {
    case 'SIN INFORMACION':
      coloredRowBg = 'bg-yellow-100';
      break;
    case 'POR GENERAR':
      coloredRowBg = 'bg-red-50';
      break;
    default:
      coloredRowBg = idx % 2 === 0 ? 'bg-white' : 'bg-gray-100';
      break;
  }


const updatedProducto={...entrada}
  updatedProducto.ocp =updatedProducto.ocp? wrapWithLink(`/ordenes-de-compra-proveedor/${entrada.ocp}`, entrada.ocp) : entrada.ocp
  updatedProducto.cpp=typeof updatedProducto.cpp === 'number'? wrapWithLink(`/cuentas-por-pagar/${entrada.cpp}`, entrada.cpp) : entrada.cpp

  return (
    <React.Fragment>
      <TableRow
        key={entrada.id}
        className={coloredRowBg ? coloredRowBg : `${idx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}
      >
        <TableBodyCellAuto list={updatedProducto} />
      </TableRow>
    </React.Fragment>
  );
};

const tableHeaderMapFn = (list, idx) => {
  let newObj = { ...list };

};


const filterCondition = (state, value) => {
  console.log(state)
  let condition = (
    String(state?.simbolo).toUpperCase().includes(value) ||
    String(state?.razonSocial).toUpperCase().includes(value)||
    String(state?.entrada).toUpperCase().includes(value)||
    String(state?.cpp).toUpperCase().includes(value)||
    String(state?.lote).toUpperCase().includes(value)
  );
  return condition;
};


const Entradas = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    routePath:'entradas',
    sliceActions,
    getItems,
    handleFilter,
    customDisplayMapFn,
    filterCondition,
    orderByOptions: [
      '',
      'entrada',
      'simbolo',
      'razonSocial',
      'cpp',
      'lote',
      'createdAt'
    ],
  });
};

export default Entradas;
