const filterSeleccion = (valuesToFilter, selectedValueId, filterBy) => {
  const filterdValue = valuesToFilter.filter(
    (item) => Number(item[filterBy ? filterBy : 'id']) === Number(selectedValueId),
  );
  return filterdValue[0];
};

export const findBy = (list, id, findBy) => {
  const value = list.find((item) => Number(item[findBy ? findBy : 'id']) === Number(id));
  return value;
};

export function selectReducer(state, action) {
  state[action.payload.field] = filterSeleccion(
    action.payload.values,
    action.payload.selectedValue,
  );
  state.editsNotSaved = true;
}

export function asignReducer(state, action) {
  state[action.payload.reduxStateTree] = findBy(
    action.payload.valuesToFilter,
    action.payload.selectedValue,
    action.payload.findBy,
  );
}

export function shouldDisplayAll(state, action) {
  state.displayAll = action.payload;
}

export function cancelAsignReducer(state, action) {
  state[action.payload.stateTree] = null;
}
export function productToRemoveAsignReducer(state, action) {
  state[action.payload.stateTree] = null;
}
