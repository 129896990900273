import React from 'react';

import { BottomBar } from '../SideBar/BottomBar';
export const Sidebar1 = ({logoSrc,navigation,logout,isAuthenticated}) => {


  return (
    <>

      {/* Static sidebar for desktop */}

        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-2">
            <img
              className="h-11 "
              style={{ height: '58px', paddingLeft: ' 6px' }}
              src={logoSrc}
              alt="Workflow"
            />
          </div>

          {navigation}
        </div>
        <BottomBar isAuthenticated={isAuthenticated} logout={logout}/>


    </>
  );
};

