import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCliente,
  putCliente,
  deleteCliente,
  editClienteActions,
  getRelation,
  putJoinedTable,
  postRelationProducto,
  deleteRelationProducto,
} from '../../store/editarCliente-slice';
import JoinedTable from '../SharedUi/JoinedTable';
import ModelInputsAndSelectors from './ModelInputsAndSelectors';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import DeleteModal from '../UI/Modals/DeleteModal';
import AsignProductoToCliente from '../TailwindUI/AsignProductoToCliente';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';


const EditCliente = () => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

  const sliceState = useSelector((state) => state.editCliente);
  const asignedProducts = useSelector((state) => state.editCliente.asignedProducts);

  const urlParam = location.pathname.split('/')[2];
  const requestUrl = 'clientes';
  const clienteStateTree = sliceState.cliente;
  const relationProducto_URL = `views/clientes/producto/${urlParam}`;

  useEffect(() => {
    dispatch(getCliente({ urlParam, authCtx }));
    getRelationHandler(relationProducto_URL, 'asignedProducts');
  }, []);

  const handlePutCliente = () => {
    const requestObj = {
      id: clienteStateTree?.id,
      razon_social: clienteStateTree?.razonSocial,
      rfc: clienteStateTree?.rfc,
      correoElectronico:clienteStateTree?.correoElectronico,
      direccionFiscal: sliceState.direccionFiscal,
      direccionEntrega:sliceState.direccionEntrega,
      diasCredito: clienteStateTree?.diasCredito,
    };

    dispatch(
      putCliente({
        requestObj,
        requestUrl,
        reduxStateTree: clienteStateTree,
        history,
      }),
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteCliente({
        requestUrl,
        reduxStateTree: clienteStateTree,
        history,
      }),
    );
  };

  const handleAsingProducto = (e) => {
    dispatch(editClienteActions.asignReducer({ ...e }));
  };
  const handleCancelAsingProducto = (e) => {
    dispatch(editClienteActions.cancelAsignReducer({ ...e }));
  };
  //Relations AsyncTHunks
  const handlePostRelation = (e) => {
    console.log('eeeeee');
    console.log(e);
    let requestObj = {};
    const { reduxStateTree, selectedValue, asignedListString } = e;
    console.log('reduxStateTree----------');
    console.log(reduxStateTree);
    if (reduxStateTree === 'productToAsign') {
      requestObj = {
        cliente_id: sliceState.cliente.id,
        producto_id: sliceState.productToAsign.id,
      };
    }
    dispatch(
      postRelationProducto({
        requestObj,
        reduxStateTree,
        selectedValue,
        asignedListString,
        authCtx,
      }),
    );
  };
  const handleEraseRelation = (e) => {
    let requestObj = {};
    const { reduxStateTree, asignedListString, asignedListSelector } = e;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    if (reduxStateTree === 'productToAsign') {
      requestObj = {
        cliente_id: sliceState.cliente.id,
        producto_id: e.valueToUnasign.productoId,
        simbolo: e.valueToUnasign.simbolo,
      };
    }

    dispatch(
      deleteRelationProducto({
        requestObj,
        reduxStateTree,
        asignedListString,
        asignedListSelector,
        authCtx,
      }),
    );
  };

  const getRelationHandler = (requestUrl, reduxStateTree) => {
    dispatch(
      getRelation({
        requestUrl,
        reduxStateTree,
        authCtx,
      }),
    );
  };

  return (
    <>
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Main content */}
        <FlexContainer className="flex-1 items-stretch  overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            {/*TAB CODE */}

            {/* Primary column */}
            {/* <div>{JSON.stringify(sliceState)}</div> */}
            <FlexContainer className="flex-col">
              <div className="flex flex-wrap ">
                <CardContainer>
                  <CardTitle>Cliente</CardTitle>
                  <ModelInputsAndSelectors selectorName={'razonSocial'} label={'Razon Social'} />
                  <ModelInputsAndSelectors selectorName={'rfc'} label={'RFC'} />
                  <ModelInputsAndSelectors selectorName={'correoElectronico'} label={'correo electronico'} type={'email'} />
                  <ModelInputsAndSelectors
                    selectorName={'diasCredito'}
                    label={'Días Crédito'}
                    type={'number'}
                  />
                  <CardButton onClick={handlePutCliente}>Guardar</CardButton>
                </CardContainer>

                <JoinedTable
                  disableSave={true}
                  putJoinedTableThunk={putJoinedTable}
                  modelInputsAndSelectors={ModelInputsAndSelectors}
                  object={sliceState.direccionFiscal}
                  id={sliceState.id}
                  title={'Direccion Fiscal'}
                  url={'direcciones'}
                  prefixSelector={'direccionFiscal.'}
                  stateTree={'direccionFiscal'}
                />
                <JoinedTable
                  disableSave={true}
                  putJoinedTableThunk={putJoinedTable}
                  modelInputsAndSelectors={ModelInputsAndSelectors}
                  object={sliceState.direccionEntrega}
                  id={sliceState.id}
                  title={'Direccion Entrega'}
                  url={'direcciones'}
                  prefixSelector={'direccionEntrega.'}
                  stateTree={'direccionEntrega'}
                />
              </div>

              <AsignProductoToCliente
                //UI
                authCtx={authCtx}
                cardTitle={'Productos'}
                inputLabel={'Asignar producto'}
                //URL
                fetchUrl={'productos'}
                //Redux
                asignTree={'productToAsign'}
                removeTree={'productToRemoveAsign'}
                valueToAsignSelector={sliceState.productToAsign}
                asignedListSelector={asignedProducts}
                asignedListString={'asignedProducts'}
                // asignedListString={'relationsProductos'}

                //Select Options
                nameOfPropertyToAsign={'simbolo'}
                //Function Handlers
                handleSelect={handleAsingProducto}
                handleCancel={handleCancelAsingProducto}
                handlePostRelation={handlePostRelation}
                handleErrase={handleEraseRelation}
              />

              {deleteModalIsVisible && (
                <DeleteModal
                  title={'Borrar Cliente'}
                  item={`${sliceState?.cliente?.razonSocial}`}
                  onConfirm={() => {
                    console.log('click');

                    handleDelete();

                    setDeleteModalIsVisible(false);
                  }}
                  onCancel={() => setDeleteModalIsVisible(false)}
                >
                  Esta Acción no puede desacerse
                </DeleteModal>
              )}
              <CardContainer>
                <CardButton bgColor="red" onClick={() => setDeleteModalIsVisible(true)}>
                  Borrar Cliente
                </CardButton>
              </CardContainer>

              <LifeCycleFeedback
                erraseSucces={() => {
                  dispatch(editClienteActions.resetSucces());
                }}
                erraseError={() => {
                  dispatch(editClienteActions.resetError());
                }}
                sliceState={sliceState}
              />
            </FlexContainer>
          </main>
        </FlexContainer>
      </div>
    </>
  );
};
export default EditCliente;
