import react from 'react';
const TableBodyCell = (props) => {
  let textColor = '';
  let textAlign = '';
  props.textColor ? (textColor = props.textColor) : (textColor = 'text-gray-800 font-medium');
  props.textAlign ? (textAlign = props.textAlign) : (textAlign = 'text-left');
  return (
    <td
      colSpan={props.colspan}
      className={
        props.className ? props.className : `px-2 py-1 whitespace-nowrap ${textAlign} ${textColor} `
      }
      scope="col"
    >
      {props.children}
    </td>
  );
};
export default TableBodyCell;
