import React from 'react';
import Table from '../TailwindUI/Table';

import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { Link, useHistory } from 'react-router-dom';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { MatchPreviousValue } from '../../Util/MatchPreviousValue';
import FlexContainer from '../TailwindUI/FlexContainer';

const EdoCuentaClienteTable = (props) => {
  const matchPreviousValue = MatchPreviousValue();
  return (
    <FlexContainer className='flex-1 items-stretch  overflow-hidden '>
      <main className='flex-1 overflow-y-auto  '>
        {/* Primary column */}

        <FlexContainer className='flex-col   bg-gray-100 py-2'>
          {/* {JSON.stringify(sliceState)} */}
          <div
            className='shadow w-auto border-b border-gray-200  rounded-t rounded-b-lg bg-white  m-2 pb-3  overflow-x-auto  '>
            <div className='mb-12'>
              <h1>{props.nombre}</h1>
              <Table className=' text-xs'>
                <thead>
                <tr>
                  <th colspan='10'
                      className='text-gray-400 font-semibold border-2'>
                    DATOS PROVEEDOR / FACTURA
                  </th>
                  <th colspan='9'
                      className='text-gray-400  font-semibold border-2'>
                    DATOS MELY
                  </th>
                </tr>
                </thead>

                {/* <TableHeader>
          {props?.data[0] && (
            <TableRowHeader>
              {Object.keys(props?.data[0])?.map((i) => (
                <TableHeaderCell
                  
                  className='bg-white text-gray630 border px-10' border={true}
                  
                >
                  {i}
                </TableHeaderCell>
              ))}
            </TableRowHeader>
          )}
        </TableHeader> */}

                <TableHeader>
                  <TableRowHeader>
                    {/* <TableHeaderCell
              className="bg-white text-gray-600 border px-1"
              border={true}
            >
              <div
                style={{
                  maxWidth: '15ch',
                  textAlign: 'center',
                  fontSize: '11px',
                }}
              >
                RAZON SOCIAL
              </div>
            </TableHeaderCell> */}
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        REMISION PROVEEDOR
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        OCC
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        NUMERO FACTURA
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        FECHA
                        <br /> FACTURA
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        FECHA VENCIEMIENTO
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        CONCEPTO
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        CANTIDAD
                        <br />
                        CAJAS
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        P.U.
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        IVA
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        TOTAL PRODUCTOS
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        ESTATUS
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        DIAS RESTANTES
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        NOTA ALMACEN MELY
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        FECHA
                      </div>
                    </TableHeaderCell>

                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        TOTAL CPC
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        TOTAL PAGOS
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        TOTAL NC
                      </div>
                    </TableHeaderCell>

                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        SALDO
                      </div>
                    </TableHeaderCell>
                    <TableHeaderCell
                      className='bg-white text-gray-600 border px-1'
                      border={true}>
                      <div
                        style={{
                          maxWidth: '15ch',
                          textAlign: 'center',
                          fontSize: '11px',
                        }}
                      >
                        CPC
                      </div>
                    </TableHeaderCell>
                    {/*<TableHeaderCell*/}
                    {/*  className='bg-white text-gray-600 border px-1'*/}
                    {/*  border={true}>*/}
                    {/*  <div*/}
                    {/*    style={{*/}
                    {/*      maxWidth: '15ch',*/}
                    {/*      textAlign: 'center',*/}
                    {/*      fontSize: '11px',*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    OBSERVACIONES*/}
                    {/*  </div>*/}
                    {/*</TableHeaderCell>*/}
                  </TableRowHeader>
                </TableHeader>

                <TableBody>
                  {props?.data?.map((i) => {

                    const estatus = i?.cuenta?.estatus;
                    const remision = i?.productoCuenta?.remisionProveedor;
                    const totalProducto = i?.cuenta?.total;
                    const notaAlmacen = i?.productoCuenta?.productoSalida?.notaDeRemision;
                    const fetchaNotaAlmacen = i?.productoCuenta?.productoSalida?.createdAt?.toString()
                      .split('T')[0];
                    const cuentaId = i?.cuenta?.id;
                    const productoCuentaId = i?.productoCuenta?.id;
                    const numeroOc = i?.productoCuenta?.occ?.numeroOcc;
                    const factura = i?.cuenta.factura;
                    const fechaFactura = i?.cuenta?.fechaFactura?.toString()
                      .split('T')[0];
                    const fechaVencimiento = i?.cuenta?.fechaVencimiento?.toString()
                      .split('T')[0];
                    const simbolo = i?.productoCuenta?.producto?.simbolo;
                    const totalCuenta = prettifyNumber(i?.cuenta?.total);
                    const totalPagos = prettifyNumber(
                      Number(i?.cuenta?.totalPagos)?.toFixed(2));
                    const totalNotasDeCredito = prettifyNumber(
                      Number(i?.cuenta?.totalNotasDeCredito)?.toFixed(2));
                    const saldo = prettifyNumber(i?.cuenta?.saldo);
                    const iva = i?.cuenta?.iva ? 'SI' : 'NO';
                    const cantidad = i?.productoCuenta?.cantidad;
                    const diasRestantes=i?.cuenta?.diasRestantes
                    const precio = prettifyNumber(
                      i?.productoCuenta?.precio?.monto);

                    let rowBg = matchPreviousValue.execute(cuentaId, 'bg-white',
                      'bg-gray-100');
                    if (estatus === 'VENCIDO')
                      rowBg = matchPreviousValue.execute(cuentaId, 'bg-red-50',
                        'bg-red-100');
                    if (estatus === 'PAGADO')
                      rowBg = matchPreviousValue.execute(cuentaId,
                        'bg-green-50',
                        'bg-green-100');
                    if (estatus === 'ERROR')
                      rowBg = matchPreviousValue.execute(cuentaId,
                        'bg-orange-50',
                        'bg-orange-100');

                    return (
                      <TableRow className={rowBg} key={productoCuentaId}>
                        <TableBodyCell
                          textAlign='text-center'>{remision}</TableBodyCell>
                        <TableBodyCell textAlign='text-center'>
                          <Link
                            to={`/ordenes-de-compra-cliente/${numeroOc}`}>
                    <span
                      className='font-semibold text-orangeMely'>{numeroOc}</span>
                          </Link>
                        </TableBodyCell>
                        <TableBodyCell
                          textAlign='text-center'>{factura}</TableBodyCell>
                        <TableBodyCell>{fechaFactura}</TableBodyCell>
                        <TableBodyCell>{fechaVencimiento}</TableBodyCell>
                        <TableBodyCell
                          textAlign='text-center'>{simbolo}</TableBodyCell>
                        <TableBodyCell
                          textAlign='text-center'>{cantidad}</TableBodyCell>
                        <TableBodyCell
                          textAlign='text-center'>{precio}</TableBodyCell>
                        <TableBodyCell
                          textAlign='text-center'>{iva}</TableBodyCell>
                        <TableBodyCell>$ {prettifyNumber(
                          totalProducto)}</TableBodyCell>
                        <TableBodyCell
                          textAlign='text-center'>{estatus}</TableBodyCell>
                        <TableBodyCell
                          textAlign='text-center'>{diasRestantes}</TableBodyCell>
                        <TableBodyCell textAlign='text-center'>
                          <Link to={`/salidas/${notaAlmacen}`}>
                    <span
                      className='font-semibold text-orangeMely'>{notaAlmacen}</span>
                          </Link>
                        </TableBodyCell>
                        <TableBodyCell>{fetchaNotaAlmacen}</TableBodyCell>
                        <TableBodyCell>${totalCuenta}</TableBodyCell>
                        <TableBodyCell
                          textAlign='text-center'>${totalPagos}</TableBodyCell>
                        <TableBodyCell
                          textAlign='text-center'>${totalNotasDeCredito}</TableBodyCell>
                        <TableBodyCell>${saldo}</TableBodyCell>
                        <TableBodyCell>
                          <Link to={`/cuentas-por-cobrar/${cuentaId}`}>
                    <span
                      className='font-semibold text-orangeMely'>{cuentaId}</span>
                          </Link>
                        </TableBodyCell>
                        {/*<TableBodyCell*/}
                        {/*  textAlign='text-center'>{i.observaciones}</TableBodyCell>*/}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>

          </div>
        </FlexContainer>
      </main>
    </FlexContainer>
  );
};
export default EdoCuentaClienteTable;
