import Productos from './Productos';
import CreateProducto from './CreateProducto';
import EditProducto from './EditProducto';

export const productosRoutes = [
  {
    name: 'Productos',
    href: '/productos',
    component: <Productos />,
    exact: true,
  },
  {
    name: 'Create Producto',
    href: '/productos/crear/',
    component: <CreateProducto />,
    exact: true,
  },
  {
    name: 'Edit Productos',
    href: '/productos/:id',
    component: <EditProducto />,
    exact: true,
  },
];
