import React, { Fragment } from 'react';
import { Menu } from '@headlessui/react';
import {
  PencilAltIcon,
  TrashIcon,
  DotsVerticalIcon,
} from '@heroicons/react/solid';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DropdownItemButton = (props) => {
  return (
    <div className="py-1">
      <Menu.Item>
        {({ active }) => (
          <Fragment>
            <button
              onClick={props.onClick}
              className="text-gray-700 group flex items-center px-4 py-2 text-sm w-full hover:bg-gray-100 hover:text-gray-900"
            >
              {props.children}
            </button>
          </Fragment>
        )}
      </Menu.Item>
    </div>
  );
};
export default DropdownItemButton;
