import {
  modalActions,
  deleteEntityFromModal,
  deleteEntity,
} from '../../store/modal-slice';
import DeleteModal from '../UI/Modals/DeleteModal';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

export const DeleteUsuario = () => {
  const sliceState = useSelector((state) => state.modal);
  const context = useSelector((state) => state.modal?.context);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const redirectRoutePath = '/configuracion/usuarios';
  useEffect(() => {
    dispatch(modalActions.resetModal());
      if (context === null) {
        history.push(redirectRoutePath);
      }
    }, [],
  );
  return (<>
    <DeleteModal
      sliceState={sliceState}
      title={'Borrar Usuario'}
      item={context?.entity?.nombre + ' ' + context?.entity?.email}
      message={''}

      onConfirm={async (e) => {

        if (!e.current) return
        console.log('onConfirm-->>>');
        await dispatch(deleteEntityFromModal({
            redirectRoutePath,
            entity: context?.entity,
            deleteId: context?.entity?.id,
            apiRoutePath: 'usuarios',
            authCtx,
          }));

      }}
      onCancel={
        (e) => {
          if (!e.current) return;
          console.log('onCancel-->>>');

          history.push(redirectRoutePath);
          dispatch(modalActions.resetModal());

        }
      }

    >
      Esta Acción no puede desacerse
    </DeleteModal>

  </>);
};