import React from 'react';
import Table from '../TailwindUI/Table';

import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableHeaderCellAuto from '../TailwindUI/TableHeaderCellAuto';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import { Link, useHistory } from 'react-router-dom';
import { prettifyNumber } from '../../Util/prettifyNumber';

const InventarioClientesTable = (props) => {
  const reducer = (previousValue, currentValue) => Number(previousValue) +
    Number(currentValue);

  const groupByParam = (param, list) => {
    const groupedArray = [];
    list?.forEach((element) => {
      if (groupedArray.length === 0) {
        groupedArray.push(element[param]);
      }
      const filterParamArr = groupedArray.filter(
        (item) => item === element[param]);

      if (filterParamArr.length === 0) {
        groupedArray.push(element[param]);
      }
    });
    return groupedArray;
  };

  let razonSocialGroup = groupByParam('razonSocial', props.lista);

  let mergeValuesByGroup = (arr, arrObj) => {
    const updatedArray = [];
    arr?.forEach((groupedArrayElement) => {
      console.log('foreach ----<<' + groupedArrayElement);
      const findValue = arrObj.filter(
        (item) => groupedArrayElement === item.razonSocial);
      // for (let i = 0; i < arrObj?.length; i++) {
      //   console.log('For loop------>');
      //   console.log('index' + i);
      //   console.log('arrObj[i].razonSocial ');
      //   console.log(arrObj[i].razonSocial);
      //   const findValue = arrObj.filter(
      //     (item) => arrObj[i].razonSocial === item.razonSocial
      //   );

      console.log('findValue ???????');
      console.log(findValue);
      if (findValue.length > 0) {
        updatedArray.push({ [groupedArrayElement]: findValue });
      }
      console.log('updatedArray');
      console.log(updatedArray);
      // }
    });

    return updatedArray;
  };

  // console.log(mergeValuesByGroup(razonSocialGroup, props.lista));
  const updatedList = mergeValuesByGroup(razonSocialGroup, props.lista);

  return (
    <div className='mb-12' style={{ maxWidth: '300px' }}>
      <h1>{props.nombre}</h1>
      <Table>
        <TableHeader>
          <TableRowHeader className='bg-orange-500'>
            <TableHeaderCell>Cliente</TableHeaderCell>
            <TableHeaderCell>
              <Table>
                <TableBody>
                  <TableRow className='text-white text-xs'>
                    <TableBodyCell>Símbolo</TableBodyCell>
                    <TableBodyCell>Stock</TableBodyCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableHeaderCell>
            {/* <TableHeaderCell>Símbolo</TableHeaderCell>
            <TableHeaderCell>Stock</TableHeaderCell> */}
          </TableRowHeader>
        </TableHeader>
        <TableBody>
          {updatedList?.map((inventario, personIdx) => {
            let razonSocial = Object.keys(inventario)[0];
            let razonSocialItems = Object.values(inventario);

            console.log('inventario clientessss----');
            console.log('inventario clientessss----');
            console.log(razonSocialItems);
            console.log('inventario clientessss----');
            const total = [];

            return (
              <React.Fragment>
                <TableRow
                  key={inventario.simbolo + inventario.inventario +
                    inventario.total}
                  className={personIdx % 2 === 0 ? 'bg-white' : 'bg-orange-50'}
                >
                  <TableBodyCell>{razonSocial}</TableBodyCell>

                  <TableBodyCell colspan='2'>
                    {razonSocialItems.map((productos) => {
                      console.log('producto---->');
                      console.log(productos);
                      return (
                        <>
                          {productos.map((producto, index) => {
                            const operation = Number(producto.sumaEntradas) -
                              Number(producto.sumaSalidas);
                            if (operation === 0) return;
                            total.push(
                              operation,
                            );
                            return (
                              <div className=' pt-1pb-2' key={producto.simbolo}>
                                <Table>
                                  <TableBody>
                                    <TableRow
                                      className={
                                        index % 2 === 0
                                          ? 'bg-white'
                                          : 'border-t border-gray-200'
                                      }
                                    >
                                      <TableBodyCell>
                                        <Link
                                          to={`/inventario-clientes/busqueda?nombreInventario=clientes&razonSocial=${razonSocial}&simbolo=${producto.entradaSimbolo}`}
                                        >
                                          {producto.entradaSimbolo}
                                        </Link>
                                      </TableBodyCell>
                                      <TableBodyCell>
                                        {prettifyNumber(
                                          String(
                                            Number(producto.sumaEntradas) -
                                            Number(producto.sumaSalidas),
                                          ),
                                        )}
                                      </TableBodyCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </div>
                            );
                          })}
                          <Table>
                            {total.length > 0 && (
                              <TableBody>
                                <TableRow>
                                  <TableBodyCell>TOTAL</TableBodyCell>
                                  <TableBodyCell>

                                    <h2 className='text-black text-right'>
                                      {prettifyNumber(
                                        String(total.reduce(reducer)))}
                                    </h2>

                                  </TableBodyCell>
                                </TableRow>
                              </TableBody>
                            )}
                          </Table>
                        </>
                      );
                    })}
                  </TableBodyCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
export default InventarioClientesTable;
