import { useState } from 'react';
import axios from 'axios';
import { authorizationHeader } from '../../Util/authorizationHeader';
import { apiURL_Nest } from '../../Util/apiURL';
import { object } from 'yup';
import { downloadBlob } from '../../domains/post-document-command';

export const useUpload = () => {
  const apiHOST = apiURL_Nest();
  const [file, setFile] = useState(null);
  const [uploadParams, setUploadParams] = useState(null);
  const changeHandler = ({ file, bucket, prefix, tags }) => {
    setFile(file);
    const { name } = file;

    const params = new UploadThroughSignedUrl({
      bucket, prefix, tags, fileName: name,
    });
    setUploadParams(params);

    return params;

  };
  const submitHandler = async () => {

    console.log('uploadParams->', uploadParams);
    const upload = await UploadThroughSignedUrl.submitHandler(
      { file, ...uploadParams });
    console.log('upload return->', upload);
  };

  return {
    changeHandler,
    submitHandler,
    setUploadParams,
  };
};

export class UploadThroughSignedUrl {
  constructor ({ bucket, prefix, fileName, tags }) {
    this.bucket = bucket;
    this.prefix = prefix;
    this.tags = tags;
    this.fileName = UploadThroughSignedUrl.removeWhiteSpace(fileName);
    this.key = this.resolveS3key(prefix,
      UploadThroughSignedUrl.removeWhiteSpace(fileName));
    this.encodedUrl = this.urlBuilder(bucket, this.key);
  }

  static removeWhiteSpace (str, updatedChar = '_') {

    return str.replace(/\s/g, updatedChar);

  }

  setTags (tags) {
    if (typeof tag === 'object') {
      throw new Error('tag must be an object');
    }
    this.tags = tags;
  }

  resolveS3key (prefix, fileName) {
    return prefix
      ? `${prefix}/${fileName}`
      : `${fileName}`;
  }

  urlBuilder (bucket, key) {
    const apiHOST = apiURL_Nest();
    const encodedBucket = encodeURIComponent(bucket);
    const encodedKey = encodeURIComponent(key);
    console.log('encoded Key->>>', encodedKey);
    const url = `${apiHOST}documents/signed-url?key=${encodedKey}&bucket=${encodedBucket}`;
    console.log('url->', url);
    return url;

  };

  static async getPresignedUrl (url) {
    console.log('presignedUrl->url request', url);
    return await axios.get(url,
      {
        headers: {
          ...authorizationHeader(),
        },
      },
    );

  }

  static async putObjectThroughSignedUrl (url, data, contentType) {
    console.log('contentType->', contentType);
    try {
      const putObjectCommand = await axios.put(url, data, {
        headers: {
          'Content-Type': contentType,
        },
      });
      console.log('putObjectCommand->', putObjectCommand);
      return putObjectCommand;
    } catch (e) {

      console.log('error->', e);

    }
  };

  static uploadFileArgsAssembler ({ fileName, bucket, key, tags }) {
    return {
      name: fileName,
      bucket,
      key,
      tags,
    };

  }

  static async submitHandler ({
    file,
    bucket,
    key,
    tags,
    fileName,
    prefix,
    encodedUrl,
  }) {
    if (!file) return;
    console.log('prefix->', prefix);
    console.log('fileName->', fileName);
    console.log('key->', key);
    console.log('tags->', tags);
    console.log('encodedURL->', encodedUrl);
    const { contentType } = file;
    console.log('contentType->', contentType);

    const apiHOST = apiURL_Nest();

    const signedUrl = await UploadThroughSignedUrl.getPresignedUrl(encodedUrl);
    console.log('signedUrl', signedUrl);

    if (!signedUrl.data) return;
    console.log('signedUrl.data', signedUrl.data);

    const { status } = await UploadThroughSignedUrl.putObjectThroughSignedUrl(
      signedUrl.data,
      file, contentType);

    if (status !== 200) return;

    const uploadArgs = UploadThroughSignedUrl.uploadFileArgsAssembler({
      fileName,
      bucket,
      key,
      tags,
    });

    console.log('uploadArgs-->', uploadArgs);
    console.log('uploadArgs-->', uploadArgs);
    console.log('uploadArgs-->', uploadArgs);

    const uploadFile = await axios.post(
      `${apiHOST}uploads/`,
      uploadArgs,
      {
        headers: {
          ...authorizationHeader(),
        },
      },
    );

    console.log('uploadfile', uploadFile);
    console.log(uploadFile);
    return uploadFile;

  }

}

export class DownloadUploadFromSignedUrl {
  static async download (name) {

    const downloadFromSignedUrl = new DownloadUploadFromSignedUrl(name);

    const signedUrl = await downloadFromSignedUrl.getSignedUrl();
    const blob = await downloadFromSignedUrl.getObjectAsBlob(signedUrl);
    return downloadFromSignedUrl.downloadFromBlob(blob);
  }


  constructor (name,url) {
    this.name = name;
    this.url = this.resolveUrl(name,url);
  }

  resolveUrl (name,url) {
    const apiHOST = apiURL_Nest();
    return `${apiHOST}uploads/${name}/download`;
  }

  downloadFromBlob (blob) {
    const url = window.URL.createObjectURL(blob); // Create a temporary URL for the Blob
    const a = document.createElement('a'); // Create a hidden anchor tag
    a.style.display = 'none';
    a.href = url; // Set the URL to the Blob
    a.download = this.name; // Set the download filename
    document.body.appendChild(a); // Append to the body
    a.click(); // Trigger the download
    document.body.removeChild(a); // Clean up
    window.URL.revokeObjectURL(url); // Release the Blob URL
  };

  async getSignedUrl () {
    const response = await axios.get(this.url, {
      headers: {
        'Content-Type': 'application/json',
        ...authorizationHeader(),
      },
    });

    if (!response.data) throw new Error(
      'no data was presignedUrl was returned');
    return response.data;
  }

  async getObjectAsBlob (url) {

    console.log('getObjectAsBlob,Url-->', url);
    const response = await axios.get(url,
      { responseType: 'blob' },
    );
    const blob = new Blob([response.data],
      { type: response.headers['content-type'] });
    return blob;
  }

}