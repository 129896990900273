import React from 'react';

const InfoMely = (props) => {
  return (
    <React.Fragment>
      <div>
        <div>
          <span>{props.datosMely?.razonSocial}</span>
        </div>
        <div>
          <span className="ml-4">{props.datosMely?.rfc}</span>
        </div>
      </div>

      <div>{props.datosMely?.correo}</div>
      <div>{props.datosMely?.paginaWeb}</div>
      <div className="text-sm">{props.datosMely?.direccion}</div>
    </React.Fragment>
  );
};
export default InfoMely;

export const datosMelyMx = {
  razonSocial: `MELY PACKAGING S.A. de C.V.`,
  rfc: 'R.F.C. TME140908T79',
  correo: 'facturacion@grupomely.com',
  paginaWeb: 'www.melypackaging.com',
  direccion: `PLAZA DE LAS FUENTES Nº 6273 INT. 49 COL. VILLAS DE IRAPUATO C.P.36670 TEL. 462 173 2966 IRAPUATO, GTO.`,
  telefono: `01 462 173 29 66`,
};

export const datosMelyUsa = {
  razonSocial: `MELY PACKAGING L.L.C`,
  rfc: 'ID 372029667',
  correo: 'facturacion@grupomely.com',
  paginaWeb: 'www.melypackaging.com',
  direccion: '8122 DATAPOINT SUITE 250, SAN ANTONIO, TX 78229',
  telefono: `01 462 173 29 66`,
};
