import Proveedores from './Proveedores';
import CreateProveedor from './CreateProveedor';
import EditProveedor from './EditProveedor';

export const proveedoresRoutes = [
  {
    name: 'Proveedores',
    href: '/proveedores',
    component: <Proveedores />,
    exact: true,
  },
  {
    name: 'Create Proveedor',
    href: '/proveedores/crear/',
    exact: true,

    component: <CreateProveedor />,
  },

  {
    name: 'Edit Proveedores',
    href: '/proveedores/:id',
    exact: true,
    component: <EditProveedor />,
  },
];
