import {
  InputCornerHint, NumberInputCornerHint,
  NumberInputWithLimitsV2,
} from '../../../SharedUi/Inputs';
import { prettifyNumber } from '../../../../Util/prettifyNumber';
import React from 'react';
import {
  useObjectReducer,
} from '../../../EntradaInventarioRechazos/useObjectReducer';
import {
  createInitialStateFromModel,
} from '../../../../Util/create-initial-state-from-model';
import SelectHelperV2 from '../../../TailwindUI/SelectHelperV2';
import { BooleanHelper } from '../../../BooleanHelper';

export const FormItemsBuilder = ({
  model,
  state,
  updateField,
}) => {
  return model.map((formItem, idx) => {
    if (formItem.diplay === false) return false;
    switch (formItem.type) {
      case 'select':
        return <SelectHelperV2
          transformer={formItem.options.transformer ?? undefined}
          routePath={formItem.options.routePath}
          modelName={formItem.options.modelName}
          onChange={(e) => updateField([formItem.key], e.target.value)}
          label={formItem.label}
        />;

      case 'boolean':
        return<BooleanHelper  label={formItem.label}
                       onChange={(e) => updateField([formItem.key], e)}
                       value={state[formItem.key]}
        />

      case'number':
        return (formItem?.options?.display===false?"":<NumberInputCornerHint
          name={formItem.key}
          label={formItem.label ?? formItem.key}
          type={formItem.type}
          value={state[formItem.key]}
          step={formItem?.options?.step}
          min={formItem?.options?.min||0}
          max={formItem?.options?.max}
          onChange={(e) => updateField([formItem.key], e.target.value)}
          borderColor={'border-orange-300'}
          disabled={formItem?.options?.disabled||false}
        />)
      default:
        return (formItem?.options?.display===false?"":<InputCornerHint
          name={formItem.key}
          label={formItem.label ?? formItem.key}
          type={formItem.type}
          value={state[formItem.key]}
          onChange={(e) => updateField([formItem.key], e.target.value)}
          borderColor={'border-orange-300'}
          disabled={formItem?.options?.disabled||false}
        />)
    }
  });
};
