import React from 'react';

import { Route, Switch } from 'react-router-dom';
// Inventario Routes
import { inventarioRoutes } from './inventarioRoutes';
import { adminRoutes } from './adminRoutes';
import { configRoutes } from './configRoutes';
import { ventasRoutes } from './ventasRoutes';
import { cuentasRoutes } from './cuentasRoutes';
import { useAuth } from '../context/AuthProvider';
import OCCs from '../components/OCC/OCCs';
import OCPs from '../components/OCP/OCPs';

export default function AppRoutes () {
  const { roleId } = useAuth();

  let list = [];
  switch (roleId) {
    //1 direccion
    //2 administracion
    //3 inventario
    //4 ventas
    //6 cuentas
    case 1:
      list = [...inventarioRoutes, ...adminRoutes, ...configRoutes];
      break;
    case 2:
      list = [...inventarioRoutes, ...adminRoutes];
      break;
    case 3:
      list = [
        ...inventarioRoutes, {
          name: 'OCCs',
          href: '/ordenes-de-compra-cliente',
          component: <OCCs />,
          exact: true,
        },{
          name: 'OCPs',
          href: '/ordenes-de-compra-proveedor',
          component: <OCPs />,
          exact: true,
        }];
      break;
    case 4:
      list = ventasRoutes;
      break;
    case 6:
      list = cuentasRoutes;
      break;
    default:
  }
  return (
    <React.Fragment>

      {list.length > 0
        ? list.map((i) => {
          return (
            <Switch key={Math.random(723409)}>
              <Route strict exact={i.exact ? i.exact : false} path={i.href}>
                {i.component}
              </Route>
            </Switch>
          );
        })
        : ''}
    </React.Fragment>
  );
}

// export const fullRole = [
//   {
//     name: 'Inventario',
//     icon: CollectionIcon,
//     href: '/inventario',
//     current: false,
//     children: [
//       {
//         name: 'Inventarios',
//         href: '/inventario',
//         current: false,
//       },
//       {
//         name: 'Entradas',
//         href: '/entradas',

//         current: false,
//       },
//       {
//         name: 'Salidas',
//         href: '/salidas',
//         current: false,
//       },
//     ],
//   },

//   {
//     name: 'Documentos',
//     href: '/clientes',
//     icon: UsersIcon,
//     current: false,
//     children: [
//       {
//         name: 'Ordenes de compra',
//         href: '/ordenes-de-compra-cliente',
//         icon: LogoutIcon,
//         current: false,
//       },
//     ],
//   },

//   {
//     name: 'Proveedores',

//     icon: BookOpenIcon,
//     current: false,
//     children: [
//       {
//         name: 'Ordenes de Compra',
//         href: '/ordenes-de-compra-proveedor',
//         icon: LoginIcon,
//         current: false,
//       },
//       {
//         name: 'Edo de cuenta',
//         href: '/estado-de-cuenta/proveedores',
//         icon: CashIcon,
//         current: false,
//       },

//       {
//         name: 'Pagos Proveedor',
//         href: '/pagos-poveedor',
//         icon: CashIcon,
//         current: false,
//       },
//       {
//         name: 'Cuentas Por Pagar',
//         href: '/cuentas-por-pagar',
//         icon: CashIcon,
//         current: false,
//       },
//     ],
//   },

//   {
//     name: 'Biblioteca',
//     href: '/productos',
//     icon: CubeIcon,
//     current: false,
//     children: [
//       {
//         name: 'Productos',
//         href: '/productos',
//         icon: CubeIcon,
//         current: false,
//       },
//       {
//         name: 'Documentos',
//         href: '/clientes',
//         icon: UsersIcon,
//         current: false,
//       },
//       {
//         name: 'Proveedores',
//         href: '/proveedores',
//         icon: BookOpenIcon,
//         current: false,
//       },
//       {
//         name: 'Contactos',
//         href: '/contactos',
//         icon: UserGroupIcon,
//         current: false,
//       },
//     ],
//   },
// ];
