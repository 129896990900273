import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOptions } from '../../store/fetch-all-options-slice';
import { SelectCornerHint } from '../SharedUi/Inputs';
import AuthContext from '../../store/auth-context';
import { objectToOption } from '../../Util/object-to-option';
import Spinner from '../Spinner/Spinner';
import Alert from '../Alert/Alert';
import { classNames } from '../../Util/classNames';
import { XIcon } from '@heroicons/react/solid';
import IconButton from '../TailwindUI/IconButton';
import { apiURL_Nest } from '../../Util/apiURL';
import { authorizationHeader } from '../../Util/authorizationHeader';
import axios from 'axios';
import { CardTitle } from '../SharedUi/UpdateCardUI';
import { DownloadUploadFromSignedUrl } from './useUpload';
import { useDownload } from './useDownload';

const UploadsComponent = ({
    routePath,
    modelName,
    transformer,
    onChange,
    label,
    api,
    name = '',
    formkikValue = '',
    downloadUrl,
  }) => {
    const authCtx = useContext(AuthContext);
    const sliceState = useSelector((state) => state.fetchAllOptions[modelName]);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [fileToDowload, setFileToDownload] = useState(null);
    const [download] = useDownload({ url: downloadUrl });

    const deleteFile = async (name) => {
      console.log('executing DeleteFile--->>>');
      const encodedName = encodeURIComponent(name);

      const url = `${apiURL_Nest()}uploads/${encodedName}`;
      console.log('url->', url);
      try {
        const deleteObjectCommand = await axios.delete(url, {
          headers: {
            ...authorizationHeader(),
          },
        });
        console.log('deleteObjectCommand->', deleteObjectCommand);

        return deleteObjectCommand;
      } catch (e) {

        console.log('error->', e);

      }

    };

    const fetchAll = async () => {
      console.log("fetching all-->>")
      setIsLoading(true);


      const data2 = await dispatch(
        fetchOptions({ routePath, authCtx, modelName, api }));

      const data = await dispatch(
        fetchOptions({ routePath, authCtx, modelName, api }));

      console.log('testin->data response');
      console.log(data);
      if (data) {
        setError(data.message);
      }

      setIsLoading(false);
    };

    useEffect(() => {
      fetchAll();
    }, []);

    const fallbackTransformer = (data) => data?.map(
      item => objectToOption({ obj: item }));
    const data = transformer ? transformer(sliceState) : fallbackTransformer(
      sliceState);
    const components = data?.map((item, idx) => {
      const { value, text } = item;
      return (
        <button onClick={() => console.log('value--->', value)}>{text}</button>
      );
    });
    const list = <ul role='list' className='divide-y divide-gray-100'>
      {data?.map((item) => {
          const { id, name, createdAt } = item;
          return (
            <li key={id} className='flex items-center justify-between gap-x-6 py-5'>
              <div className='min-w-0'>
                <div className='flex items-start gap-x-3'>
                  <p
                    className='text-sm font-semibold leading-6 text-gray-900'>{name}</p>
                  {/*<p*/}
                  {/*  className={classNames(*/}
                  {/*    `statuses[project.status]`,*/}
                  {/*    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'*/}
                  {/*  )}*/}
                  {/*>*/}
                  {/*  {`project.status`}*/}
                  {/*</p>*/}
                </div>
                <div
                  className='mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500'>
                  {createdAt && <p className='whitespace-nowrap'>
                    <time dateTime={createdAt}>{createdAt}</time>
                  </p>}
                  <svg viewBox='0 0 2 2' className='h-0.5 w-0.5 fill-current'>
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  {/*<p className="truncate">Created by {project.createdBy}</p>*/}
                </div>
              </div>
              <div className='flex flex-none items-center gap-x-4'>

                <IconButton icon={'download'} color={'gray'}
                            onClick={async () => {
                              setIsLoading(true);
                              await download(name);
                              setIsLoading(false);
                            }}
                />
                {!isLoading && <IconButton icon={'trash'} color={'red'}
                                           onClick={async () => {
                                             setIsLoading(true);
                                             await deleteFile(name);
                                             setIsLoading(false);
                                             await fetchAll();

                                           }} />}


                {/*<Menu as="div" className="relative flex-none">*/}
                {/*  <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">*/}
                {/*    <span className="sr-only">Open options</span>*/}
                {/*    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />*/}
                {/*  </MenuButton>*/}
                {/*  <Transition*/}
                {/*    enter="transition ease-out duration-100"*/}
                {/*    enterFrom="transform opacity-0 scale-95"*/}
                {/*    enterTo="transform opacity-100 scale-100"*/}
                {/*    leave="transition ease-in duration-75"*/}
                {/*    leaveFrom="transform opacity-100 scale-100"*/}
                {/*    leaveTo="transform opacity-0 scale-95"*/}
                {/*  >*/}
                {/*    <MenuItems className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">*/}
                {/*      <MenuItem>*/}
                {/*        {({ focus }) => (*/}
                {/*          <a*/}
                {/*            href="#"*/}
                {/*            className={classNames(*/}
                {/*              focus ? 'bg-gray-50' : '',*/}
                {/*              'block px-3 py-1 text-sm leading-6 text-gray-900'*/}
                {/*            )}*/}
                {/*          >*/}
                {/*            Edit<span className="sr-only">, {project.name}</span>*/}
                {/*          </a>*/}
                {/*        )}*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem>*/}
                {/*        {({ focus }) => (*/}
                {/*          <a*/}
                {/*            href="#"*/}
                {/*            className={classNames(*/}
                {/*              focus ? 'bg-gray-50' : '',*/}
                {/*              'block px-3 py-1 text-sm leading-6 text-gray-900'*/}
                {/*            )}*/}
                {/*          >*/}
                {/*            Move<span className="sr-only">, {project.name}</span>*/}
                {/*          </a>*/}
                {/*        )}*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem>*/}
                {/*        {({ focus }) => (*/}
                {/*          <a*/}
                {/*            href="#"*/}
                {/*            className={classNames(*/}
                {/*              focus ? 'bg-gray-50' : '',*/}
                {/*              'block px-3 py-1 text-sm leading-6 text-gray-900'*/}
                {/*            )}*/}
                {/*          >*/}
                {/*            Delete<span className="sr-only">, {project.name}</span>*/}
                {/*          </a>*/}
                {/*        )}*/}
                {/*      </MenuItem>*/}
                {/*    </MenuItems>*/}
                {/*  </Transition>*/}
                {/*</Menu>*/}
              </div>
            </li>);
        },
      )}
    </ul>;

    return (
      <div className='max-w-full max-h-full mb-6 mt-4'>
        <CardTitle>
          Archivos
        </CardTitle>
        {isLoading && <Spinner />}
        {error && <Alert message={
          <div className='text-left'>
            Error Fetching <span className='font-bold'>{modelName}</span>
            <div>
              Error Message <span className='font-bold'> {error}</span>
            </div>
          </div>

        } />}

        {(!isLoading && !error) && list}


      </div>
    );
  }
;
export default UploadsComponent;
