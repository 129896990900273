import React from 'react';
import {
  CheckIcon,
  XIcon,
  PencilIcon,
  PlusSmIcon,
  ArrowDownIcon,
  TrashIcon,
  CurrencyDollarIcon,
  ClockIcon,
  ShoppingCartIcon,
  InformationCircleIcon,
  DownloadIcon,
  MailIcon
} from '@heroicons/react/solid';

const IconButton = (props) => {
  let iconHeight;
  props.iconHeight ? (iconHeight = 3) : (iconHeight = 5);
  let iconWidth;
  props.iconWidth ? (iconWidth = props.iconWidth) : (iconWidth = 5);
  let icon;
  switch (props.icon) {
    case 'x':
      icon = <XIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
      case 'mail':
      icon = <MailIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
    case 'check':
      icon = <CheckIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
    case '+':
      icon = <PlusSmIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
    case 'edit':
      icon = <PencilIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
    case 'order':
      icon = <ArrowDownIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
    case 'trash':
      icon = <TrashIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
    case 'dollar':
      icon = <CurrencyDollarIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
    case 'cart':
      icon = <ShoppingCartIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
    case 'clock':
      icon = <ClockIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />;
      break;
    case 'info':
      icon = (
        <InformationCircleIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />
      );
    case 'download':
      icon = (
        <DownloadIcon className={`h-${iconHeight} w-${iconWidth}`} aria-hidden="true" />
      );
      break;
    default:
  }
  const defaultClass =
    'ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2';
  let color = ` bg-${props.color}-600 hover:bg-${props.color}-600 focus:ring-${props.color}-700`;
  if (props.color === 'orangeMely') color = ` bg-${props.color}`;
  if (props.color === 'darkBlue') color = ` bg-${props.color}`;

  return (
    <button onClick={props.onClick} className={defaultClass + color} type="button">
      {icon}
    </button>
  );
};

export default IconButton;




export const IconButton2 = ({ iconHeight,iconWidth,icon,color,onClick }) => {

  const height=iconHeight??3
  const width=iconWidth??3
  if (!icon) return
  switch (icon) {
    case 'x':
      icon = <XIcon className={`h-${height} w-${width}`} aria-hidden="true" />;
      break;
    case 'check':
      icon = <CheckIcon className={`h-${height} w-${width}`} aria-hidden="true" />;
      break;
    case '+':
      icon = <PlusSmIcon className={`h-${height} w-${width}`} aria-hidden="true" />;
      break;
    case 'edit':
      icon = <PencilIcon className={`h-${height} w-${width}`} aria-hidden="true" />;
      break;
    case 'order':
      icon = <ArrowDownIcon className={`h-${height} w-${width}`} aria-hidden="true" />;
      break;
    case 'trash':
      icon = <TrashIcon className={`h-${height} w-${width}`} aria-hidden="true" />;
      break;
    case 'dollar':
      icon = <CurrencyDollarIcon className={`h-${height} w-${width}`} aria-hidden="true" />;
      break;
    case 'cart':
      icon = <ShoppingCartIcon className={`h-${height} w-${width}`} aria-hidden="true" />;
      break;
    case 'clock':
      icon = <ClockIcon className={`h-${height} w-${width}`} aria-hidden="true" />;
      break;
    case 'info':
      icon = (
        <InformationCircleIcon className={`h-${height} w-${width}`} aria-hidden="true" />
      );
      break;
    default:
  }
  const defaultClass =
    'ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2';
  let buttonColor = ` bg-${color??'gray'}-600 hover:bg-${color??'gray'}-600 focus:ring-${color??'gray'}-700`;
  if (color === 'orangeMely') buttonColor = ` bg-${color}`;
  if (color === 'darkBlue') buttonColor = ` bg-${color}`;

  return (
    <button onClick={onClick} className={defaultClass + buttonColor} type="button">
      {icon}
    </button>
  );
};