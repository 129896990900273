import React from 'react';
import ReactDOM from 'react-dom';
import { ModalOverlay } from './ModalOverlay/ModalOverlay';

export const GenericModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          overlayColor={props.overlayColor}
          onCancel={props.onCancel}
          title={props.title}
          message={props.message}
          onConfirm={props.onConfirm}
        >
          {/*{JSON.stringify(props.sliceState)}*/}
          {props.children}
        </ModalOverlay>,
        document.getElementById('overlay-root'),
      )}
    </React.Fragment>
  );
};
