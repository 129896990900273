import { occRoutes } from '../components/OCC/occRoutes';
import { ocpRoutes } from '../components/OCP/ocpRoutes';
import Inventario from '../components/Inventario/Inventario';
import EntradasYSalidas from '../components/Inventario/EntradasYSalidas';
import { productosRoutes } from '../components/Productos/productosRoutes';
export const ventasRoutes = [
  ...productosRoutes,
  ...occRoutes,
  ...ocpRoutes,
  {
    name: 'Inventarios',
    href: '/inventario',
    component: <Inventario />,
    exact: true,
  },
  {
    name: 'Busqueda Inventario',
    href: '/inventario/busqueda',
    component: <EntradasYSalidas />,
  },
];
