import { InputCornerHint } from '../SharedUi/Inputs';
import SelectHelperFormik from '../TailwindUI/SelectHelperFormik';
import { Field } from 'formik';
import React from 'react';

export const resolveFormInputComponent = (
  element, values, handleChange, handleBlur) => {
  let component;

  if (element.component === 'input') {
    component = <InputCornerHint
      name={element.name}
      value={values[element.value]}
      type={element.type}
      onChange={(e) => handleChange(e)}
      onBlur={(e) => handleBlur(e)}
      label={element.label}
    />;

  }

  if (element.component === 'selectHelperFormik') {
    component = <SelectHelperFormik
      routePath={element.props.routePath}
      modelName={element.props.modelName}
      api={element.props.api}
      label={element.label}
      name={element.name}
      onChange={(e) => {
        console.log('handling select change ', e.target.value);
        handleChange(e);
      }}
      formkikValue={values[element.value]}
      transformer={element.props.transformer}
    />;
  }

  if (element.component === 'switch') {
    component = <>

      <label>
        <Field type='checkbox' name={element.name} />

      </label>


    </>;
  }

  return component;

};