import Table from '../../TailwindUI/Table';
import TableHeader from '../../TailwindUI/TableHeader';
import TableRowHeader from '../../TailwindUI/TableRowHeader';
import TableHeaderCell from '../../TailwindUI/TableHeaderCell';
import TableBody from '../../TailwindUI/TableBody';
import TableRow from '../../TailwindUI/TableRow';
import TableBodyCell from '../../TailwindUI/TableBodyCell';
import { Link } from 'react-router-dom';
import { prettifyNumber } from '../../../Util/prettifyNumber';
import React from 'react';

export const TablaBalance = ({ label, linkTo, balances, totales }) =>
  (
    <div className='col-1 mr-2 mb-12'>
      <div
        className='bg-orangeMely text-white  m-2 p-2 text-left'>{label}
      </div>
      <div
        className='shadow w-auto border-b border-gray-200  rounded-t rounded-b-lg bg-white  m-2 pb-3  overflow-x-auto'>
        <Table>
          <TableHeader>
            <TableRowHeader>
              <TableHeaderCell>Razon Social</TableHeaderCell>
              <TableHeaderCell>Total</TableHeaderCell>
              <TableHeaderCell>Vencido</TableHeaderCell>
              <TableHeaderCell> Vigente</TableHeaderCell>
            </TableRowHeader>
          </TableHeader>
            {/*TODO fix in a more proper way*/}
            <TableBody>
              {typeof balances === 'object' &&
                Object.values(balances).map((entidadComercial, index) => {
                  return (
                    <TableRow
                      key={entidadComercial.id + label}
                      className={index % 2 !== 0 ? 'bg-white' : 'bg-gray-50'}>
                      <TableBodyCell>
                        <Link
                          to={`${linkTo}/${entidadComercial.id}`}>
                                    <span
                                      className='text-orangeMely font-medium'>
                                      {entidadComercial.razonSocial}
                                    </span>
                        </Link>
                      </TableBodyCell>
                      <TableBodyCell>
                        ${prettifyNumber(entidadComercial.total)}
                      </TableBodyCell>
                      <TableBodyCell>
                        ${prettifyNumber(entidadComercial.vencido)}
                      </TableBodyCell>
                      <TableBodyCell>
                        ${prettifyNumber(entidadComercial.vigente)}
                      </TableBodyCell>
                    </TableRow>
                  );
                })}
              <TableRow className={'bg-gray-200 font-semibold'}>
                <TableBodyCell>SUMA</TableBodyCell>
                <TableBodyCell>
                  ${prettifyNumber(Number(totales?.total).toFixed(2))}
                </TableBodyCell>
                <TableBodyCell>
                  ${prettifyNumber(Number(totales?.vencido).toFixed(2))}
                </TableBodyCell>
                <TableBodyCell>
                  ${prettifyNumber(Number(totales?.vigente).toFixed(2))}
                </TableBodyCell>
              </TableRow>
            </TableBody>
            </Table>

            </div>
            </div>
            );
