import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../Util/apiURL';
import { postItemThunk } from '../lib/default-post-item';
import {
  insertItemFullfilled,
  insertItemPending,
  insertItemRejected,
} from '../lib/default-post-item';
import axios from 'axios';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL_Nest();

const initialState = {
  error: null,
  loading: false,
  success: null,
};

export const createItem = createAsyncThunk(
  'createItem/create', async (payload,{rejectWithValue}) => {
    console.log('testing Thunk----->>');
    const { request, routePath, authCtx} = payload;
    console.log('Route---->???');
    console.log(routePath);
    console.log('Request---->???');
    console.log(request);
    try {
      const response = await axios.post(`${apiHOST}${routePath}`, request, {
        headers: {
          ...authorizationHeader()        },
      });
    return response.data
    } catch (err) {
      return rejectWithValue(err.response.data);
    }

  },
);


export const updateItem = createAsyncThunk(
  'updateItem/update', async (payload,{rejectWithValue}) => {
    console.log('testing Thunk----->>');
    const { request, routePath, authCtx} = payload;
    console.log('Route---->???');
    console.log(routePath);
    console.log('Request---->???');
    console.log(request);
    try {
      const response = await axios.patch(`${apiHOST}${routePath}`, request, {
        headers: {
          ...authorizationHeader()        },
      });
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data);
    }

  },
);


const createItemSlice = createSlice({
  name: 'createItem',
  initialState: initialState,
  reducers: {
    reset (state, action) {
      state.error = null;
      state.isloading = false;
      state.success = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(createItem.fulfilled, (state, action) => {
      state.success = action.payload;
      state.loading = false;
      state.error = null;

    });
    builder.addCase(createItem.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(createItem.rejected, (state, action) => {
      console.log(action);
      console.log('action--->>error');
      state.loading = false;
      state.error = action.payload

      state.success = null;
    });
    builder.addCase(updateItem.fulfilled, (state, action) => {
      state.success = action.payload;
      state.loading = false;
      state.error = null;

    });
    builder.addCase(updateItem.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(updateItem.rejected, (state, action) => {
      console.log(action);
      console.log('action--->>error');
      state.loading = false;
      state.error = action.payload

      state.success = null;
    });
  },
});

export default createItemSlice;

export const createItemActions = createItemSlice.actions;
