import { authorizationHeader } from '../Util/authorizationHeader';
import { apiURL_Docs } from '../Util/apiURL';

export const downloadBlob=(blob,name)=>{
  const url = window.URL.createObjectURL(blob); // Create a temporary URL for the Blob
  const a = document.createElement('a'); // Create a hidden anchor tag
  a.style.display = 'none';
  a.href = url; // Set the URL to the Blob
  a.download = `${name}.pdf`; // Set the download filename
  document.body.appendChild(a); // Append to the body
  a.click(); // Trigger the download
  document.body.removeChild(a); // Clean up
  window.URL.revokeObjectURL(url); // Release the Blob URL
 return true
}

export function downloadPDF (id, name) {
  console.log("executing get request--->>>", authorizationHeader())
  fetch(`${apiURL_Docs()}documents/${id}/download`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authorizationHeader(),
      }
    }
  )
    .then((response) => {
      console.log("response-->", response)
      if (!response.ok) {

        throw new Error('Network response was not ok');
      }
      return response.blob(); // Get the response as a Blob
    })
    .then((blob) => {
      return downloadBlob(blob,name)
    })
}

export const  postDocumentCommand= async(dispatch,action,request) => {
    console.log("display PDFFF-->")
    console.log('request', request);
    const { id, name } = await dispatch(
      action(request))
      .unwrap().then((res) => {
        const id = res?.data?.id
        console.log("response-->", res)
        console.log("id-->", id)
        return { id: res.data.id, name: res.data.name }

      })
    console.log("id-->", id)
    console.log("id-->", id)
    console.log("id-->", id)

    function displayPDF (blob) {
      console.log("display PDFFF-->", id)
      const url = URL.createObjectURL(blob);
      window.open(url);
    }

    async function fetchPDF (id) {
      fetch(`http://localhost:3005/documents/${id}/download`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.arrayBuffer();
        })
        .then(arrayBuffer => {
          const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
          displayPDF(blob);
        })
        .catch(error => {
          console.error('Fetching PDF failed:', error);
        });
    }


    await downloadPDF(id, name)

    return { id, name }
}