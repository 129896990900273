import React, { PropsWithChildren, useEffect } from 'react';

import { buildRenderDataComponents, RenderData, RenderDataComponentsType, RenderDataState } from '../../hoc/RenderData';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useDispatch } from 'react-redux';
import { displayDataActions, fetchAndSetData } from '../../store/display-data-slice';
import { CardTitle } from '../SharedUi/UpdateCardUI';
import Alert from '../Alert/Alert';
import Spinner from '../Spinner/Spinner';
import IconButton from '../TailwindUI/IconButton';
import { postEmail } from '../../store/editarOrdenCompraProveedor-slice';
import { edoCuentaSendEmailFactory } from '../../domains/notificaciones/edo_cuenta-send-email.factory';
import { ApplicationState } from '../../store';
import { apiURL_Docs } from '../../Util/apiURL';
import { downloadPDF } from '../../domains/post-document-command';


/// Para este contenedor:
//1. Get Data
//resetData
//getData
//Get Data from State
// IF Data->
// useDataTransformer
//  setDisplayedData

// IF no Data->
// Show error and show click that can re-fetch again.

//2. Action on Data
/// setActionMessage(i.e loading)
/// useId
/// execute an action based on that id
//// Show Modal Asking for confirmation
/// If Succes->
// show succes
// show error
// Container component


// DisplayDataActions


interface displayWithActions {
  fetch: (url: string) => {};
  data: any[];
  dataTransformer: (url: string) => {};
  displayedData: () => {};
  actions: [];//injected buttons

  sliceState: string;
  dispatch: () => {};
  isLoading: boolean;
  error: string;

}


const Container: React.FC<PropsWithChildren<{}>> = ({ children }) => <div
  className='flex flex-col justify-start max-w-m p-6 px-8 border-solid  grid-rows-3 border-2 border-gray-50 m-4 rounded-xl mx-auto shadow-lg'>{children}</div>;

// ItemComponent Arguments Type
type ItemComponentArgs = {
  id: string | number;
  name: string;
  createdAt: string;
};

// ItemComponent

const ItemsContainer: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <ul role='list' className='divide-y divide-gray-100'>
    {children}
  </ul>
);

// HeaderContainer component
// Data Type
type DataType = {
  id: string | number;
  name: string;
  createdAt: string;
};


function identity<Type>(arg: Type): Type {
  return arg;
}

const errorMessage = 'This is the error message or COMPONENT';
const modalMessage = 'hello this is a MODAL';
const State = identity<RenderDataState>({
  isLoading: false,
  showModal: true,
  hasError: false,
});


function compose<T>(...fns: ((arg: T) => T)[]): (arg: T) => T {
  return (arg: T) => fns.reduce((acc, fn) => fn(acc), arg);
};

// http://localhost:80/documents?limit=2&tags=entidadComercial:cliente,entidadComercialId:3,documentType:estado_de_cuenta
// Usage of the function
export const TestingPePe = ({ correoElectronico, id, entidadComercial }: {
  correoElectronico: string;
  id: string;
  type: string
  entidadComercial: string
}) => {
  const dispatch = useDispatch();
  const data = useAppSelector(state => state.displayData.displayedData);
  const displayedDataState = useAppSelector(state => state.displayData);

  const fetchAll = async () => {
    console.log('id in fetch->>', id);
    const payload =
      {
        key: 'data',
        url: `${apiURL_Docs()}/documents?limit=2&tags=entidadComercial:${entidadComercial},entidadComercialId:${id},documentType:estado_de_cuenta`,
      };
    // @ts-ignore
    const data = await dispatch(fetchAndSetData(payload));

  };

  // const data = useSelector((state: RootState) => state.sliceState.data);
  // const dispatch = useDispatch();

  // const executeEveryMinute = useCallback(() => {
  //   const newItem = { id: Date.now(), name: `test ${new Date().toTimeString()}` };
  //   dispatch(addItem(newItem));
  //   console.log("data->", data);
  //   console.log("executing-->>", new Date().toDateString());
  // }, [dispatch, data]);
  //
  useEffect(() => {
    fetchAll();
  }, [id]);
  const HeaderContainer: React.FC = () => (
    <section className=''>
      <CardTitle>
        Documentos
      </CardTitle>
    </section>
  );

  const ItemComponent: React.FC<ItemComponentArgs> = ({ id, name, createdAt }) => {
    return (
      <li key={id} className='flex items-center justify-between gap-x-6 py-5'>
        <div className='min-w-0'>
          <div className='flex items-start gap-x-3'>
            <p
              className='text-sm font-semibold leading-6 text-gray-900'>{name}</p>
            {/*<p*/}
            {/*  className={classNames(*/}
            {/*    `statuses[project.status]`,*/}
            {/*    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'*/}
            {/*  )}*/}
            {/*>*/}
            {/*  {`project.status`}*/}
            {/*</p>*/}
          </div>
          {/*<div*/}
          {/*  className='mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500'>*/}
          {/*  {createdAt && <p className='whitespace-nowrap'>*/}
          {/*    <time dateTime={createdAt}>{createdAt}</time>*/}
          {/*  </p>}*/}
          {/*  <svg viewBox='0 0 2 2' className='h-0.5 w-0.5 fill-current'>*/}
          {/*    <circle cx={1} cy={1} r={1} />*/}
          {/*  </svg>*/}
          {/*  /!*<p className="truncate">Created by {project.createdBy}</p>*!/*/}
          {/*</div>*/}
        </div>
        <div className='flex flex-none items-center gap-x-4'>
          <IconButton icon={'mail'} color={'darkBlue'}
                      onClick={
                        async () => {
                          if (!correoElectronico) {

                            dispatch(displayDataActions.setStateKeyAndValue({
                              key: 'hasError',
                              value: 'No esta dado de alta el correo de Destino',
                            }));
                            return;
                          }


                          const event = edoCuentaSendEmailFactory({
                            correoElectronico,
                            type: `EDO_${String(entidadComercial).toUpperCase()}`,
                            documentName: name,
                          });
                          // setIsLoading(true);
                          console.log('event->>', event);
                          //@ts-ignore
                          dispatch(postEmail(
                            // @ts-ignore
                            { requestObj: event }))
                            .unwrap();

                        }}
            // onClick={async () => {
            //   console.log('send email->>> action' + id);
            //   // setIsLoading(true);
            //   // await download(name);
            //   // setIsLoading(false);
            // }}
          />

          <IconButton icon={'download'} color={'darkBlue'}
                      onClick={async () => {
                        console.log('download->>> action' + id);
                        await downloadPDF(id, name);
                      }}
          />
        </div>
      </li>);


  };// ItemsContainer component

  const ErrorComp = () => <Alert message={
    <div className='text-left'>
      Error Fetching <span className='font-bold'>{'Estado de Cuenta'}</span>
      <div>
        Error Message <span className='font-bold'> {displayedDataState.hasError}</span>
      </div>
    </div>

  } />;

  const Components = buildRenderDataComponents<DataType>({
    Container,
    Header: HeaderContainer,
    Item: ItemComponent,
    ItemsContainer,
    Loader: () => <Spinner />,
    ErrorComponent: () => <ErrorComp />,
    Modal: () => <Container>{modalMessage}</Container>,
  });

  // const data=[{name:'my-0name',id:'1'},{name:'test',id:'2344'}]
  return RenderData<DataType>({
    data,
    Components,
    state: {
      hasError: displayedDataState.hasError,
      isLoading: displayedDataState.isLoading,
      showModal: displayedDataState.showModal,
    },
  });
};


