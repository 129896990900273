import { configureStore } from '@reduxjs/toolkit';
import createProveedorSlice from '../components/Proveedores/crearProveedor-slice';
import editProveedorSlice from '../components/Proveedores/editarProveedor-slice';


import createProductoSlice from '../components/Productos/crearProducto-slice';
import editProductoSlice from '../components/Productos/editarProducto-slice';

import selectFetchSlice from './selecfetch-slice';
import insertOneValueSlice from './insertOneValue-slice';
import divisaSlice from './divisa-slice';

import createClienteSlice from './crearCliente-slice';

import editarClienteSlice from './editarCliente-slice';
import createRelationSlice from './createRelation-slice';
import messagesSlice from './messages-slice';
import createOCPSlice from './crearOCP-slice';
import editarOrdenCompraProveedor from './editarOrdenCompraProveedor-slice';
import editarOrdenCompraCliente from './editarOrdenCompraCliente-slice';

import createEntradaSlice from './crearEntrada-slice';
import entradasSlice from './entradas-slice';
import editEntradaSlice from './editEntrada-slice';
import editSalidaSlice from './editSalida-slice';
import createSalidaSlice from './crearSalida-slice';
import createSalidaICSlice from './crearSalidaIC-slice';

import inventarioSlice from './inventario-slice';
import inventarioSlice2 from './inventario2-slice';
import inventarioEntradasSalidasSlice from './inventarioEntradasSalidas-slice';
import cuentasPorPagarSlice from './cuentasPorPagar-slice';
import cuentasPorCobrarSlice from './cuentasPorCobrar-slice';
import createCuentaPorPagarSlice from './crearCuentaPorPagar-slice';
import createCuentaPorCobrarSlice from './crearCuentaPorCobrar-slice';
import pagosProveedoresSlice from './pagosProveedores-slice';
import editCuentaPorPagarSlice from './editarCuentaPorPagar-slice';
import editCuentaPorCobrarSlice from './editarCuentaPorCobrar-slice';
import createPagoProveedorSlice from './crearPagoProveedor-slice';
import createPagoClienteSlice from './crearPagoCliente-slice';
import createOCCSlice from './crearOCC-slice';
import salidasSlice from './salidas-slice';
import editSalidaICSlice from './editSalidaIC-slice';
import createNotaCreditoProveedorSlice from './crearNotaCreditoProveedor-slice';
import createNotaCreditoClienteSlice from './crearNotaCreditoCliente-slice';


import edoCuentaProveedorSlice from './edoCuentaProveedor-slice';
import edoCuentaClienteSlice from './edoCuentaCliente-slice';
import estadosDeCuentaSlice from '../components/EstadosDeCuenta/estadosDeCuenta-slice';



import crearEntradaInventarioRechazosSlice from './crearEntradaInventarioRechazos-slice';
import entradasInventarioRechazosSlice from './entradas-inventario-rechazos-slice';
import editEntradaInventarioRechazosSlice from './editEntradaInventarioRechazos-slice';

import editEntitySlice from '../components/UI/Generics/EditEntity/editEntity-slice';
import newCategoriaProductoSlice from '../components/CategoriasProducto/newCategoriaProducto-slice';
import showCategoriasProductoSlice from '../components/CategoriasProducto/showCategoriaProducto-slice';

import fetchAllSlice from './fetch-all-slice';
import fetchAllOptionsSlice from './fetch-all-options-slice';
import createItemSlice from './create-item-slice';
import modalSlice from './modal-slice';
import displayDataSlice from './display-data-slice';
import inventarioClientesEntradasSalidasSlice from './inventarioClientesEntradasSalidas-slice';
const store = configureStore({
  reducer: {
    //Utility
    displayData:displayDataSlice.reducer,
    fetchAll:fetchAllSlice.reducer,
    fetchAllOptions:fetchAllOptionsSlice.reducer,
    createItem:createItemSlice.reducer,
    modal:modalSlice.reducer,
    messages: messagesSlice.reducer,
    selectFetch: selectFetchSlice.reducer,
    createRelation: createRelationSlice.reducer,
    insertOneValue: insertOneValueSlice.reducer,
    // Por Ordenar
    divisa: divisaSlice.reducer,
    //Orden Compra Cliente
    createOcc: createOCCSlice.reducer,
    editOrdenCompraCliente: editarOrdenCompraCliente.reducer,
    //Orden Compra Proveedor

    createOcp: createOCPSlice.reducer,
    editOrdenCompraProveedor: editarOrdenCompraProveedor.reducer,

    //Cliente
    createCliente: createClienteSlice.reducer,
    editCliente: editarClienteSlice.reducer,

    //Producto
    createProducto: createProductoSlice.reducer,
    editProducto: editProductoSlice.reducer,

    //Proveedor
    createProveedor: createProveedorSlice.reducer,
    editProveedor: editProveedorSlice.reducer,

    //Entradas
    entradas: entradasSlice.reducer,
    createEntrada: createEntradaSlice.reducer,
    editEntrada: editEntradaSlice.reducer,

    //Salidas
    salidas: salidasSlice.reducer,
    createSalida: createSalidaSlice.reducer,
    editSalida: editSalidaSlice.reducer,

    //EntradasRechazos
    entradasInventarioRechazos: entradasInventarioRechazosSlice.reducer,
    createEntradaInventarioRechazos: crearEntradaInventarioRechazosSlice.reducer,
    // editEntrada: editEntradaSlice.reducer,
    editEntradaInventarioRechazos: editEntradaInventarioRechazosSlice.reducer,

    //InventarioClientes
    createSalidaIC: createSalidaICSlice.reducer,
    editSalidaIC: editSalidaICSlice.reducer,

    //Inventario
    inventario: inventarioSlice.reducer,
    inventario2: inventarioSlice2.reducer,
    inventarioEntradasSalidas: inventarioEntradasSalidasSlice.reducer,

    //InventarioClientes
    inventarioClientesEntradasSalidas:inventarioClientesEntradasSalidasSlice.reducer,

    //CuentasPorPagar
    editCuentaPorPagar: editCuentaPorPagarSlice.reducer,
    cuentasPorPagar: cuentasPorPagarSlice.reducer,
    createCuentaPorPagar: createCuentaPorPagarSlice.reducer,

    //CuentasPorCobrar
    editCuentaPorCobrar: editCuentaPorCobrarSlice.reducer,
    cuentasPorCobrar: cuentasPorCobrarSlice.reducer,
    createCuentaPorCobrar: createCuentaPorCobrarSlice.reducer,

    //Pagos Proveedores
    pagosProveedores: pagosProveedoresSlice.reducer,
    createPagoProveedor: createPagoProveedorSlice.reducer,

    //Pagos Documentos
    createPagoCliente: createPagoClienteSlice.reducer,

    //
    estadosDeCuenta:estadosDeCuentaSlice.reducer,
    edoCuentaProveedor: edoCuentaProveedorSlice.reducer,
    edoCuentaCliente: edoCuentaClienteSlice.reducer,

    //


    createNotaCreditoProveedor: createNotaCreditoProveedorSlice.reducer,
    //

    createNotaCreditoCliente: createNotaCreditoClienteSlice.reducer,



    //editEntity
    editEntity: editEntitySlice.reducer,

    //categoriasProductos
    newCategoriaProducto: newCategoriaProductoSlice.reducer,
    showCategoriasProducto: showCategoriasProductoSlice.reducer,
  },
});

export type ApplicationState= ReturnType<typeof store.getState>
export type  ApplicationDispatch= typeof store.dispatch
export default store;
