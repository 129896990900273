import { createSlice } from '@reduxjs/toolkit';
import {  apiURL_Nest } from '../Util/apiURL';
import { postItemThunk } from '../lib/default-post-item';
import { asignReducer } from '../lib/shared-reducers';
import { toUpperCase } from '../lib/transformers/to-uppercase';

const apiHOST = apiURL_Nest();
const initialState = {
  isOrigenCliente: true,
  error: false,
  cliente: '',
  loading: false,
  productosCliente: {},
  cantidad:'',
  lote: '',
  responsable:'',
  motivo:'',
  descripcion:'',
  observaciones: '',

};

export const insertEntrada = postItemThunk(
  'crearEntrada/insertEntrada',
  `${apiHOST}entrada-rechazo`,
  'entradas-inventario-rechazos',
);

const crearEntradaInventarioRechazosSlice = createSlice({
  name: 'crearEntradaInventarioRechazosSlice',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'isOrigenCliente':
          state.isOrigenCliente = action.payload.value;
          state.cliente = null;
          state.proveedor = null;

          break;
        case 'lote':
          state.lote = toUpperCase(action.payload.value);
          break;
        case 'observaciones':
          state.observaciones = toUpperCase(action.payload.value);
          break;
        case 'cantidad':
          state.cantidad = +action.payload.value;
          break;
        case 'responsable':
          state.responsable = toUpperCase(action.payload.value);
          break;
        case 'motivo':
          state.motivo = toUpperCase(action.payload.value);
          break;
        case 'descripcion':
          state.descripcion = toUpperCase(action.payload.value);
          break;

        default:
      }
    },
    asignReducer,
    resetProducto(state, action) {
      console.log('resetProdiuctop----->>>>>>>');
      console.log('resetProdiuctop----->>>>>>>');
      console.log('resetProdiuctop----->>>>>>>');
      console.log('resetProdiuctop----->>>>>>>');
      state.productosCliente = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(insertEntrada.rejected, (state, action) => {
      console.log('action --------------> insertEntrada Rejected');
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(insertEntrada.fulfilled, (state, action) => {
      console.log('action --------------> insertEntrada Rejected');
      state.enCalidadDe = '';
      state.proveedor = '';
      state.error = '';
      state.loading = false;
    });
    builder.addCase(insertEntrada.pending, (state, action) => {
      console.log('action --------------> insertEntrada pending');
      state.loading = true;
    });
  },
});

export default crearEntradaInventarioRechazosSlice;

export const crearEntradaInventarioRechazosActions = crearEntradaInventarioRechazosSlice.actions;
