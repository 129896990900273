import React, { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { CardButton } from '../SharedUi/UpdateCardUI';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';

const InsertTemplate = ({
  sliceState,
  insertRequest,
  insertThunk,
  components,
  condition,
  buttonName,
}) => {
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  let disableInsert = true;
  if (condition) disableInsert = false;

  //Insert Request------------------------------------------------------------------------
  const postRequest = {
    ...insertRequest,
    authCtx,
  };

  //SliceThunksDispatchers------------------------------------------------------------------------
  const handleInsert = (request) => {
    dispatch(
      insertThunk({
        request,
        history,
        authCtx,
      }),
    );
  };

  return (
    <div className="flex flex-col">
      {/* {JSON.stringify(sliceState)} */}

      <div className="mt-10">
        {components
          ? components.map((component, id) => <React.Fragment key={id}>{component}</React.Fragment>)
          : ''}
      </div>

      <CardButton disabled={disableInsert} onClick={() => handleInsert(postRequest)}>
        <span className="uppercase">{buttonName ?? 'CREAR'}</span>
      </CardButton>

      <LifeCycleFeedback sliceState={sliceState} />
    </div>
  );
};
export default InsertTemplate;
