import CuentasPorPagar from './CuentasPorPagar';
import CreateCuentaPorPagar from './CreateCuentaPorPagar';
import EditCuentaPorPagar from './EditCuentaPorPagar';

export const cppRoutes = [
  {
    name: 'Edit CPPs',
    href: '/cuentas-por-pagar',
    component: <CuentasPorPagar />,
    exact: true,
  },
  {
    name: 'Edit CPPs',
    href: '/cuentas-por-pagar/:id',
    component: <EditCuentaPorPagar />,
    exact: true,
  },
  {
    name: 'Create CPP',
    href: '/cuentas-por-pagar/crear/',
    component: <CreateCuentaPorPagar />,
    exact: true,
  },
];
