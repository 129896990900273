import React from 'react';
import MelyIcon from '../../images/logo-mely.svg';
const LogoMely = (props) => {
  let classes;
  props.className ? (classes = props.className) : (classes = '');

  return (
    <React.Fragment>
      <img className={classes} src={MelyIcon} alt="MelyLogo" />
    </React.Fragment>
  );
};

export default LogoMely;
