import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SelectHelpers } from '../SelectHelpers';
import InsertTemplate from '../SharedUi/InsertTemplate';
import {
  createSalidaICActions as sliceActions,
  insertSalidaIC as insertThunk,
} from '../../store/crearSalidaIC-slice';
import { asingDispatcher } from '../../lib/sharedDispatchers';
//TODO revisar vista de salidas IC , no aparecen las notas de salida sin productos
const CreateSalidaIC = () => {
  const sliceState = useSelector((state) => state.createSalidaIC);
  const dispatch = useDispatch();

  const handleAsingProducto = (event) => asingDispatcher({ dispatch, sliceActions, event });

  let request = {
    cliente_id: sliceState?.cliente?.id,
  };

  let components = [
    SelectHelpers({
      name: 'cliente',
      valueToAsignSelector: sliceState.cliente,
      handleSelect: handleAsingProducto,
    }),
  ];
  let condition = sliceState.cliente;
  return (
    <InsertTemplate
      sliceState={sliceState}
      insertRequest={request}
      insertThunk={insertThunk}
      components={components}
      condition={condition}
      buttonName={'Crear salida IC'}
    />
  );
};
export default CreateSalidaIC;
