import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  PencilAltIcon,
  TrashIcon,
  DotsVerticalIcon,
} from '@heroicons/react/solid';
const DotsDropdown = (props) => {
  let bg;

  !props.vigente
    ? (bg = `bg-green-500 hover:bg-green-400 `)
    : (bg = `bg-orange-400 hover:bg-orange-500 `);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={` ${bg} flex items-center text-white p-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 `}
        >
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          {props.children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default DotsDropdown;
