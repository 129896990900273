import Analytics from '../mosaic-react/pages/Analytics';
import Fintech from '../mosaic-react/pages/Fintech';

export const Dashboard=()=>{

  return(<>
  {/*<div>This is a dashboard</div>*/}
    <div>
      <Analytics/>
      <Fintech/>
    </div>


  </>)
}