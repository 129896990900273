import React from 'react';
import useAuthentication from '../../../hooks/useAhthentication';

export const BottomBar = () => {
  const { logout, isAuthenticated } = useAuthentication();
  const user={name:''}

  return (<>
      {!!isAuthenticated && <div
        className='flex-shrink-0 flex border-t border-gray-200 p-4 bg-darkBlue '>
        <div className='flex items-center'>
          <div>
            {/* <img className="inline-block h-9 w-9 rounded-full" src={user.imageUrl} alt="" /> */}
          </div>
          <div className='ml-3'>
            <p className='text-sm font-medium text-white'>{user?.name}</p>

            <button
              onClick={() => {
                logout();
              }}
              className='text-xs font-medium text-white hover:text-yellow-400'
            >
              Logout
            </button>
          </div>
        </div>
      </div>}
    </>
  );

};