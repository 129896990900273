import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  crearEntradaInventarioRechazosActions as sliceActions,
  insertEntrada as insertThunk,
} from '../../store/crearEntradaInventarioRechazos-slice';
import { SelectHelpers } from '../SelectHelpers';
import InsertTemplate from '../SharedUi/InsertTemplate';
import { asingDispatcher } from '../../lib/sharedDispatchers';
import { InputHelper } from '../InputHelpers';

const CreateEntradaRechazoCliente = () => {
  const sliceState = useSelector((state) => state.createEntradaInventarioRechazos);
  const dispatch = useDispatch();
  const handleAsing = (event) => asingDispatcher({ dispatch, sliceActions, event });
  const handleAsingCliente = (event) => {
    dispatch(sliceActions.resetProducto());
    asingDispatcher({ dispatch, sliceActions, event });
  };


  let request = {
    productosEntradaRechazos:[{
    productoId: sliceState?.productosCliente?.id,
      cantidad:sliceState?.cantidad,
      lote:sliceState?.lote,
      responsable:sliceState?.responsable,
      motivo:sliceState?.motivo,
      descripcion:sliceState?.descripcion,
      observaciones:sliceState?.observaciones,

    }],
    clienteId: sliceState?.cliente?.id,
  };

  let inputHelpers = [
    {
      label: 'cantidad',
      key: 'cantidad',
      type: 'number',
    },
    {
      label: 'descripcion',
      key: 'descripcion',
    },
    {
      label: 'lote',
      key: 'lote',
    },
    {
      label: 'observaciones',
      key: 'observaciones',
    },
    {
      label: 'motivo',
      key: 'motivo',
    },
    {
      label: 'responsable',
      key: 'responsable',
    },
  ];





  let components = [
    SelectHelpers({
      name: 'cliente',
      valueToAsignSelector: sliceState.cliente,
      handleSelect: handleAsingCliente,
    }),

    sliceState?.cliente?.id
      ? SelectHelpers({
          name: 'productosCliente',
          valueToAsignSelector: sliceState.productosCliente,
          handleSelect: handleAsing,
          fetchParam: `/${sliceState?.cliente?.id}`,
        })
      : '',

    ...inputHelpers.map((item) =>
      InputHelper({
        label: item.label,
        type: item.type,
        value: sliceState?.[item.key],
        onChange: (e) => {
          dispatch(
            sliceActions.change({
              field: item.key,
              value: e.target.value,
            }),
          );
        },
      }),
    ),
  ];
  let condition = sliceState.cliente && sliceState.productosCliente && sliceState.cantidad;
  return (
    <InsertTemplate
      sliceState={sliceState}
      insertRequest={request}
      insertThunk={insertThunk}
      components={components}
      condition={condition}
    />
  );
};
export default CreateEntradaRechazoCliente;
