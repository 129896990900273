import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCategoriasProducto,
  putCategoriasProducto,
  deleteCategoriasProducto,
  showCategoriasProductoActions,
} from './showCategoriaProducto-slice';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import DeleteModal from '../UI/Modals/DeleteModal';
import { RenderInputUI } from '../SharedUi/RenderInputUI';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';

export const ShowCategoriaProducto = () => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

  const sliceState = useSelector((state) => state.showCategoriasProducto);

  const urlParam = location.pathname.split('/')[2];
  const requestUrl = 'categorias-productos';

  useEffect(() => {
    dispatch(getCategoriasProducto({ urlParam, authCtx }));
  }, []);

  const handlePutCategoriasProducto = () => {
    const requestObj = {
      id: sliceState?.id,
      nombre: sliceState?.nombre,
    };
    dispatch(
      putCategoriasProducto({
        requestObj,
        requestUrl,
        reduxStateTree: sliceState,
        history,
        authCtx,
      }),
    );
  };

  const handleDelete = () => {
    console.log('handle dolete----->>>>>>>');
    dispatch(
      deleteCategoriasProducto({
        requestUrl,
        reduxStateTree: sliceState,
        history,
        authCtx,
      }),
    );
  };
  const inputs = [
    {
      parentName: null,
      property: 'nombre',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.nombre,
      selectorName: 'nombre',
    },
  ];

  return (
    <>
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Main content */}
        <FlexContainer className="flex-1 items-stretch  overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            {/*TAB CODE */}

            {/* Primary column */}
            {/* <div>{JSON.stringify(editCategoriasProductoState)}</div> */}
            <FlexContainer className="flex-col">
              <div className="flex flex-wrap ">
                <CardContainer>
                  <CardTitle>Categoria Producto</CardTitle>
                  <RenderInputUI list={inputs} sliceActions={showCategoriasProductoActions} />
                  <CardButton onClick={handlePutCategoriasProducto}>Guardar</CardButton>
                </CardContainer>
              </div>

              {deleteModalIsVisible && (
                <DeleteModal
                  title={'Borrar Categoria Producto'}
                  message=""
                  item={`${sliceState?.nombre}`}
                  onConfirm={() => {
                    console.log('click');

                    handleDelete();

                    setDeleteModalIsVisible(false);
                  }}
                  onCancel={() => setDeleteModalIsVisible(false)}
                >
                  Esta Acción no puede desacerse
                </DeleteModal>
              )}
              <CardContainer>
                <CardButton bgColor="red" onClick={() => setDeleteModalIsVisible(true)}>
                  Borrar Categoria Producto
                </CardButton>
              </CardContainer>
            </FlexContainer>
          </main>
          <LifeCycleFeedback
            erraseSucces={() => {
              dispatch(showCategoriasProductoActions.resetSucces());
            }}
            erraseError={() => {
              dispatch(showCategoriasProductoActions.resetError());
            }}
            sliceState={sliceState}
          />
        </FlexContainer>
      </div>
    </>
  );
};
export default ShowCategoriaProducto;
