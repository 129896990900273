import React, { useContext, useState } from 'react';
import {
  DocsCardLayoutDatosDoc, DocsCardLayoutInfoMely,
  DocsCardLayoutLogo, DocsCardLayoutMenu,
} from '../SharedUi/DocsCardLayout';
import LogoMely from '../SharedUi/LogoMely';
import DocsCardDatosDoc from '../SharedUi/DocsCardDatosDoc';
import { setDateStringDDMMYYYY } from '../../Util/get-date';
import DocsCard from '../SharedUi/DocsCard';
import { ProductosSalidaRechazoTable } from './ProductosSalidaRechazoTable';
import DotsDropdown from '../DropdownMenu/DotsDropdown';
import DropdownItemButton from '../DropdownMenu/DropdownItemButton';
import { PencilAltIcon } from '@heroicons/react/solid';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '../TailwindUI/IconButton';
import { UpdateSalidaInventarioRechazo } from './UpdateSalidaInventarioRechazo';
import {
  cerrarSalidaRechazo,
  deleteSalidaRechazo,
} from '../../store/editEntradaInventarioRechazos-slice';

import AuthContext from '../../store/auth-context';

export const EditSalidaInventarioRechazo = ({ salidaRechazo,sliceState,sliceActions }) => {
  const dispatch=useDispatch()
  const authCtx = useContext(AuthContext);
  //TODO fix vigente vs no vigente
  return (
    <DocsCard>
      <DocsCardLayoutMenu>

        <DotsDropdown vigente={true}>
          {/*<DropdownItemButton*/}
          {/*  onClick={() => dispatch(cerrarSalidaRechazo({item:{...salidaRechazo,vigente:true},authCtx }))}*/}
          {/*>*/}
          {/*  <PencilAltIcon*/}
          {/*    className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'*/}
          {/*    aria-hidden='true'*/}
          {/*  />*/}
          {/*  Editar*/}
          {/*</DropdownItemButton>*/}
          <DropdownItemButton
            onClick={() => dispatch(deleteSalidaRechazo({item:salidaRechazo,authCtx }))}

          >
            <PencilAltIcon
              className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
              aria-hidden='true'
            />
            Borrar
          </DropdownItemButton>
        </DotsDropdown>
      </DocsCardLayoutMenu>


      <DocsCardLayoutLogo>
        <LogoMely className='h-16 w-auto' />
      </DocsCardLayoutLogo>



      <DocsCardLayoutDatosDoc className='text-xs'>
        <DocsCardDatosDoc
          title={'RECHAZO'}
          number={salidaRechazo.id}
          date={setDateStringDDMMYYYY(
            salidaRechazo.createdAt)}
        />
      </DocsCardLayoutDatosDoc>

      <div className=' mt-4' />

      <DocsCardLayoutInfoMely>
        <h2
          className=' mb-4 text-xl bold text-left uppercase'>SALIDA {salidaRechazo.enCalidadDe.nombre}</h2>


        <div className='col-span-6 mt-4'>


          <div className='mb-16'>


           <UpdateSalidaInventarioRechazo  sliceActions={sliceActions} salidaRechazo={salidaRechazo} sliceState={sliceState} dispatchState={(e)=>console.log(e)}/>

            <ProductosSalidaRechazoTable
              productosSalidaRechazo={salidaRechazo.productosSalidaRechazo}
            />



          </div>

        </div>

      </DocsCardLayoutInfoMely>

    </DocsCard>
  );
};