import { createObj } from './create-obj';
import { reduceGroup } from './reduce-group';

export const groupByKey2 = (obj) => {
  const entries = Object.entries(obj);
  return entries.map((i) => {
    const [itemKeys, itemValues] = i;
    if (Array.isArray(itemValues)) {
      return createObj(itemKeys, reduceGroup(itemValues));
    } else {
      return createObj(itemKeys, i);
    }
  });
};
