import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getValuesToAsign, createRelationActions } from '../../store/createRelation-slice';
import IconButton from './IconButton';
import { InputCornerHint, SelectCornerHint } from '../SharedUi/Inputs';
import AuthContext from '../../store/auth-context';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';

const SelectHelper = (props) => {
  const authCtx = useContext(AuthContext);

  //Internal State
  const [editMode, setEditMode] = useState(false);

  //Redux State

  //Create Relation
  const sliceState = useSelector((state) => state.createRelation);
  const loading = useSelector((state) => state.createRelation.loading);

  //Function Handlers

  const handleSelect = (e) => {
    props.handleSelect({
      reduxStateTree: props.asignTree,
      valuesToFilter: sliceState.valuesToAsign,
      selectedValue: e.target.value,
      authCtx,
    });
    setEditMode(false);
  };
  const handleCancel = (e) => {
    dispatch(createRelationActions.reset());
    setEditMode(false);
  };

  //<-----------------SELECT OPTIONS----------------->
  const dispatch = useDispatch();
  const handleGetSelectValues = () => {
    console.log('fetchUrl');
    console.log(props.fetchUrl);

    dispatch(getValuesToAsign({ url: props.fetchUrl ? props.fetchUrl : '', authCtx }));
    setEditMode(true);
  };

  let selectValues = sliceState.valuesToAsign;

  const selectOptions = selectValues?.map((item) => {
    let nameOfPropertyToAsign;
    props.nameOfPropertyToAsign
      ? (nameOfPropertyToAsign = props.nameOfPropertyToAsign)
      : (nameOfPropertyToAsign = 'id');

    return (
      <option key={item.id} value={item.id}>
        {item[nameOfPropertyToAsign]}
      </option>
    );
  });

  return (
    <>
      {/* {loading && editMode && <Spinner />} */}

      <React.Fragment>
        {editMode && (
          <React.Fragment>
            <div className=" flex rounded  py-1 ">
              <div style={{ flexBasis: '100%' }}>
                <SelectCornerHint
                  label={props.label}
                  id={props.asignedListString}
                  name="scliente"
                  onBlur={handleSelect}
                  onChange={handleSelect}
                  options={selectOptions}
                />
              </div>
              <div className="flex items-center pt-5">
                <IconButton
                  onClick={handleCancel}
                  iconHeight={4}
                  iconWidth={4}
                  color="red"
                  icon="x"
                />
              </div>
            </div>
            <LifeCycleFeedback sliceState={sliceState} />
          </React.Fragment>
        )}
      </React.Fragment>

      {!editMode && (
        <>
          <div className="flex rounded  py-1 ">
            <div style={{ flexBasis: '100%' }}>
              <InputCornerHint
                label={props.label}
                disabled
                value={
                  props.valueToAsignSelector
                    ? props.valueToAsignSelector[props.nameOfPropertyToAsign]
                    : ''
                }
              />
            </div>

            <div className="flex items-center pt-5 ">
              <IconButton
                onClick={handleGetSelectValues}
                color="darkBlue"
                icon="edit"
                iconHeight={4}
                iconWidth={4}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default SelectHelper;
