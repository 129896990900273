import { prettifyNumber } from '../../../Util/prettifyNumber';
import React from 'react';

export const SocioComercial=({razonSocial,divisa,total,vencido,vigente})=>(
  <div style={{ maxWidth: '350px' }}>
    <div className='flex mt-4 '>
      <div
        className=' flex bg-darkBlueLight text-white text-left text-lg px-3 py-1 w-full'>
        <div>{razonSocial} </div>
        {' '}
        <div className='ml-auto uppercase'>{divisa}</div>
      </div>
    </div>
    <div className='flex'>
      <div
        className=' flex justify-between text-gray-400 text-left text-sm px-3 py-1 w-full  border'>
        <div>TOTAL</div>
        <div>${prettifyNumber(total)}</div>
      </div>
    </div>
    <div className='flex '>
      <div
        className=' flex justify-between text-gray-400 text-left text-sm px-3 py-1 w-full  border'>
        <div>VENCIDO</div>
        <div>${prettifyNumber(vencido)}</div>
      </div>
    </div>
    <div className='flex'>
      <div
        className=' flex justify-between text-gray-400 text-left text-sm px-3 py-1 w-full  border'>
        <div className=''>VIGENTE</div>

        <div className='font-bold'>
          <div>${prettifyNumber(vigente)}</div>

        </div>
      </div>
    </div>
  </div>
)