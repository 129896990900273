export const ocpSendEmailFactory =(correoElectronico,documentName,type) => (
  
  {
  eventBus: null,
  eventSource: 'sistema_mely_frontend',
  detailType: 'notification-email-aws_ses_raw',
  eventDetail: {
    data: {
      email: {
        toAddresses: [correoElectronico],
        content: [
          {
            data: {
              file: {
                url: `https://s3.amazonaws.com/public/${String(type).toUpperCase()}/${documentName}.pdf`,
                source: 's3',
                key: `${String(type).toUpperCase()}/${documentName}.pdf`,
                bucket: 'melypackaging.public',
              },
            },
          },
        ],
      },
    },
    metadata: {
      notificationSettingId: 'eb3d9a29-5a30-4280-ad2d-db83e2bd93bd',
      notificationName:`send_email_with_document_${type}_${documentName}`
    },
  },
})

