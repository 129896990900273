import React from 'react';

export const DocsCardLayoutMenu = (props) => {
  return <div
    className='col-span-6 text-right -mt-12 -mr-12'>{props.children}</div>;
};

export const DocsCardLayoutLogo = (props) => {
  return <div className='col-span-3'>{props.children}</div>;
};

export const DocsCardLayoutDatosDoc = (props) => {
  let className = props.className;
  let tailwindClass = `col-span-3 text-right ${className ?? ''}`;
  return <div className={tailwindClass}>{props.children}</div>;
};

export const DocsCardLayoutInfoMely = (props) => {
  return <div className='col-span-6 '>{props.children}</div>;
};

export const DocsCardLayoutTwoCols = (props) => {
  return (
    <div
      className='col-span-6  mt-12 grid grid-cols-2'
      style={{
        maxWidth: '1200px',
        width: '85%',
        margin: 'auto',
        marginTop: '50px',
      }}
    >
      {props.children}
    </div>
  );
};

export const DocsCardDatosFiscales = (props) => {
  return (
    <div
      className='col-span-1 row-span-3 border-2 rounded mr-auto text-left py-4 px-4'>
      <div>{`  ${props.sliceState?.razonSocial}  ${props.sliceState?.rfc}`}</div>
      <div className='mt-4 uppercase'>
        {/*TODO clean this mess*/}
        {`${(props.sliceState?.direccionFiscal?.calle ||
          props.sliceState?.direccion?.calle) ?? ''} 
           ${(props.sliceState?.direccionFiscal?.numero ||
          props.sliceState?.direccion?.numero) ?? ''}
           ${(props.sliceState?.direccionFiscal?.colonia ||
          props.sliceState?.direccion?.colonia) ?? ''}
           ${(props.sliceState?.direccionFiscal?.ciudad ||
          props.sliceState?.direccion?.ciudad) ?? ''}
           ${(props.sliceState?.direccionFiscal?.estado ||
          props.sliceState?.direccion?.estado) ?? ''}
           ${(props.sliceState?.direccionFiscal?.cp ||
          props.sliceState?.direccion?.cp) ?? ''}
           ${(props.sliceState?.direccionFiscal?.pais ||
          props.sliceState?.direccion?.pais) ?? ''}`}
      </div>
    </div>
  );
};
