import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../Util/authorizationHeader';
export const getRequestThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { authCtx } = payload;
    try {
      const response = await axios.get(route, {
        headers: {
          ...authorizationHeader()
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

export const getAllItemsFullfilled = (state, action) => {
  console.log('state----->>>>>>>');
  console.log('state----->>>>>>>');
  console.log(action);
  console.log('state----->>>>>>>');
  state.raw = action?.payload.raw;
  state.display = action?.payload.raw?.sort();
  state.formated = action?.payload?.formated;
  state.filterSearchValue = '';
  console.log('state-----???????????????????????????');
};
export const getAllItemsFullfilledRest = (state, action) => {
  console.log('getAllItemsFullfilledRest--->>>')
  console.log(action?.payload)
  state.raw = action?.payload;
  state.data=action.payload
  state.display = action?.payload
  state.formated = action?.payload?.formated;
  state.filterSearchValue = '';
  state.error = false;
  state.loading = false;
};

export const getAllItemsRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload?.message;
};
export const defaultRejectedEffect = (state, action) => {
  state.loading = false;
  console.log(action.error)
  state.error = action.error
};

export const defaultPendingEffect = (state, action) => {
  state.loading = true;
  state.error = false
};