import React, { useState } from 'react';

import IconButton from '../TailwindUI/IconButton';
import { CardTitle } from '../SharedUi/UpdateCardUI';

import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import { Link } from 'react-router-dom';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { GenericModal } from '../UI/Generics';
import { EditEntity } from '../UI/Generics/EditEntity/EditEntity';

const ProductosSalida = (props) => {
  const [genericModalIsVisible, setGenericModalIsVisible] = useState(false);
  const [editEntity, setEditEntity] = useState(null);

  //Function Handlers
  const handleErrase = (e) => {
    console.log('errase debug----->>>>>>>');
    console.log(e);
    props.handleErrase({
      valueToUnasign: e,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
      asignedListSelector: props.asignedListSelector,
    });
  };
  const handleUpdate = () => {
    props.handleUpdate();
  };

  return (
    <React.Fragment>
      <div>
        <CardTitle>{props.cardTitle}</CardTitle>

        {props.asignedListSelector?.length > 0 && (
          <div style={{ width: '100%', margin: 'auto' }}>
            <Table>
              <TableHeader>
                <TableRowHeader>
                  <TableHeaderCell>OCC</TableHeaderCell>
                  <TableHeaderCell>CANTIDAD</TableHeaderCell>
                  <TableHeaderCell>UNIDAD</TableHeaderCell>
                  <TableHeaderCell>DESCRIPCIÓN</TableHeaderCell>
                  <TableHeaderCell>FACTURA</TableHeaderCell>
                  <TableHeaderCell>LOTE</TableHeaderCell>
                  {/* <TableHeaderCell>REMISION</TableHeaderCell> */}
                </TableRowHeader>
              </TableHeader>
              <TableBody>
                {props.asignedListSelector?.map((producto, personIdx) => {
                  console.log('producto');
                  console.log(producto);
                  return (
                    <React.Fragment>
                      <TableRow
                        key={producto.id}
                        className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                      >
                        {console.log('---****---****producto')}
                        {console.log(producto)}
                        <TableBodyCell>
                          <span className="text-orange-600 font-medium ">
                            <Link to={`/ordenes-de-compra-cliente/${producto.numeroOcc}`}>
                              {producto.numeroOcc}
                            </Link>
                          </span>
                        </TableBodyCell>
                        <TableBodyCell>{producto[props.cantidadPropertyName]}</TableBodyCell>
                        <TableBodyCell>{producto.unidad}</TableBodyCell>
                        <TableBodyCell>{producto.simbolo}</TableBodyCell>
                        <TableBodyCell>{producto.factura}</TableBodyCell>
                        <TableBodyCell>{producto?.lote}</TableBodyCell>
                        {/* <TableBodyCell>{producto.notaDeRemision}</TableBodyCell> */}
                        <TableBodyCell>
                          {producto.cuentaPorCobrarId ? (
                            `CPC:${producto.cuentaPorCobrarId}`
                          ) : (
                            <React.Fragment>
                              {props.vigente ? (
                                <IconButton
                                  onClick={() => handleErrase(producto)}
                                  color="gray"
                                  icon="trash"
                                  iconHeight={2.5}
                                  iconWidth={2.5}
                                />
                              ) : (
                                `CPC:Sin Asignar`
                              )}
                            </React.Fragment>
                          )}
                        </TableBodyCell>
                        <TableBodyCell>
                          <IconButton
                            onClick={() => {
                              setEditEntity(producto);
                              setGenericModalIsVisible(true);
                            }}
                            color="gray"
                            icon="edit"
                            iconHeight={2.5}
                            iconWidth={2.5}
                          />
                        </TableBodyCell>

                        {/* <IconButton
                        onClick={() =>
                          props.handleCreateCuentaPorCobrar(producto)
                        }
                        color="orange"
                        icon="dollar"
                        iconHeight={2.5}
                        iconWidth={2.5}
                      /> */}
                      </TableRow>

                      {genericModalIsVisible && (
                        <GenericModal
                          title={'Generic Modal'}
                          onConfirm={(sliceState) => {
                            // handleDelete(sliceState.id);
                            setGenericModalIsVisible(false);
                          }}
                          onCancel={() => {
                            props.handleUpdateProductoSalida();
                            setGenericModalIsVisible(false);
                          }}
                        >
                          <EditEntity
                            name="Producto Salida"
                            // callback={}
                            entity={editEntity}
                            readOnlyFields={[
                              {
                                label: 'cantidad',
                                path: 'cantidadSalida',
                                key: 'cantidadSalida',
                              },
                              {
                                label: 'simbolo',
                                path: 'simbolo',
                                key: 'simbolo',
                              },
                            ]}
                            editFields={[
                              {
                                label: 'factura',
                                path: 'factura',
                                key: 'factura',
                                state: 'factura',
                              },
                              {
                                label: 'remision',
                                path: 'notaDeRemision',
                                key: 'notaDeRemision',
                                state: 'notaDeRemision',
                              },
                              {
                                label: 'lote',
                                path: 'lote',
                                key: 'lote',
                              },
                            ]}
                            url="productos-salida/remision-factura"
                          />
                        </GenericModal>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default ProductosSalida;
//test
