import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthContext from '../../store/auth-context';
import { createItem, updateItem } from '../../store/create-item-slice';
import { LifeCycleFeedbackV3 } from '../LifeCycleFeedback/LifeCycleFeedback';

import * as Yup from 'yup';
import { objectToOption } from '../../Util/object-to-option';
import { renderForm } from './renderForm';

const CreateNotificacionTemplate = () => {
  const sliceState = useSelector((state) => state.createItem);
  const dispatch = useDispatch();
  const routePath = 'notificaciones/templates';
  const handleSubmit = (values) => {
    const req = {
      request: values, routePath, authCtx,
    };
    dispatch(createItem(req));
    console.log(JSON.stringify(req, null, 2));
  };

  const authCtx = useContext(AuthContext);

  const formInitialValue = {
    name: '', description: '', proveedorId: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  const formElements = [
    {
      type: 'text',
      name: 'name',
      value: 'name',
      label: 'nombre',
      component: 'input',
    }, {
      type: 'text', name: 'uri', value: 'uri', label: 'uri', component: 'input',
    }, {
      type: 'text',
      name: 'settings',
      value: 'settings',
      label: 'settings',
      component: 'input',
    }];

// const initialFormValues=

  let component = renderForm(
    { formInitialValue, handleSubmit, formElements, validationSchema });

  return <>
    <div>
      {sliceState.success !== true ? component : ''}

      <div>

        <LifeCycleFeedbackV3 sliceState={sliceState} />
      </div>
    </div>

  </>;

};

export { CreateNotificacionTemplate };