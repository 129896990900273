import OCPs from './OCPs';
import CreateOCP from './CreateOCP';
import EditOCP from './EditOCP';

export const ocpRoutes = [
  {
    name: 'OCPs',
    href: '/ordenes-de-compra-proveedor',
    component: <OCPs />,
    exact: true,
  },
  {
    name: 'Edit OCPs',
    href: '/ordenes-de-compra-proveedor/:id',
    component: <EditOCP />,
    exact: true,
  },
  {
    name: 'Create OCP',
    href: '/ordenes-de-compra-proveedor/crear/',
    component: <CreateOCP />,
    exact: true,
  },
];
