import IconButton from '../TailwindUI/IconButton';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import AsignProductoOCPtoEntrada from './AsignProductoOCPtoEntrada';
import React, { useState } from 'react';
import { GenericModal } from '../UI/Generics';

import { SelectFetchCombobox } from '../TailwindUI/SelectFetchCombobox';

export const AddProductToEntrada = ({
  sliceState,
  handleResetAddProduct,
  handleSelect,
  handleResetPrpductosToSearch,
  getRelationHandler,
  relationProducto_URL,
  AsignProductoOCPtoEntrada,
  setGenericModalIsVisible,
}) => {
  return <GenericModal
    overlayColor={"bg-orange-400"}
    title={'Generic Modal'}
    meesage={'En la siguiente lista puedes encontrar los productos que exísten dentro de una orden de compra de este proveedor'}
    onConfirm={(sliceState) => {

    }}
    onCancel={() => {
      handleResetAddProduct();

    }}
  >
    <div className='' style={{"minHeight":"50vh"}}>
      {!sliceState?.productToSearch?.simbolo && (
        <>

          <CardContainer>
            <CardTitle><h2 className='text-center font-bold'>Selecciona un
              producto</h2></CardTitle>
            <p className='py-2 justify-center text-center text-xs'>

              En la siguiente lista puedes encontrar los productos que
              exísten dentro de una orden de compra de este proveedor
            </p>
            <div className='flex justify-center my-6 bg-white'>

              <SelectFetchCombobox

                spinnerColor={'white'}
                reduxStateTree={'productToSearch'}
                reduxAction={handleSelect}
                url={`views/entradas/productos-ocp/by-proveedor/?proveedorId=${sliceState?.proveedor?.id}`}
                entityPropertyToSelect={'simbolo'}
                sliceState={sliceState}
              />

            </div>
          </CardContainer>
        </>
      )}


      {AsignProductoOCPtoEntrada}


      {!sliceState.productosOrdenCompra && sliceState?.productToSearch &&
        (
          <div className={"text-center"}>

            <CardButton
              onClick={() =>
                getRelationHandler(relationProducto_URL,
                  'productosOrdenCompra')
              }
            >
              Ver Ordenes de Compra
            </CardButton>
          </div>
        )}

      {sliceState.productosOrdenCompra && (
        <>
          {sliceState.productosOrdenCompra.length === 0 &&
            sliceState?.productToSearch && (
              <CardContainer>
                <CardButton
                  bgColor={'red'}
                  onClick={() => {
                    handleResetPrpductosToSearch();
                  }}
                >
                  No se enontraron resultados, intenta de nuevo
                </CardButton>
              </CardContainer>

            )}
        </>
      )}

    </div>

  </GenericModal>;

};