import React from 'react';
import {
  insertProveedor as insertThunk,
  createProveedorActions,
} from './crearProveedor-slice';
import { useSelector } from 'react-redux';
import { CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import { RenderInputUI } from '../SharedUi/RenderInputUI';
import InsertTemplate from '../SharedUi/InsertTemplate';

const CreateProveedor = () => {
  const sliceState = useSelector(state => state.createProveedor);
  const form_clientes = [
    {
      parentName: null,
      property: 'razonSocial',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.razonSocial,
      selectorName: 'razonSocial',
      displayValue: 'razon social',
    },
    {
      parentName: null,
      property: 'rfc',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.rfc,
      selectorName: 'rfc',
    },
    {
      parentName: null,
      property: 'correoElectronico',
      type: 'email',
      foreignKey: false,
      selector: sliceState?.correoElectronico,
      selectorName: 'correoElectronico',
    },
    {
      parentName: null,
      property: 'diasCredito',
      type: 'number',
      foreignKey: false,
      selector: sliceState?.diasCredito,
      selectorName: 'diasCredito',
    },
  ];

  const form_direccionClientes = [
    {
      parentName: null,
      property: 'numero',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.numero,
      selectorName: 'numero',
    },
    {
      parentName: null,
      property: 'calle',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.calle,
      selectorName: 'calle',
    },
    {
      parentName: null,
      property: 'ciudad',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.ciudad,
      selectorName: 'ciudad',
    },
    {
      parentName: null,
      property: 'estado',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.estado,
      selectorName: 'estado',
    },
    {
      parentName: null,
      property: 'colonia',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.colonia,
      selectorName: 'colonia',
    },
    {
      parentName: null,
      property: 'cp',
      type: 'number',
      foreignKey: false,
      selector: sliceState?.cp,
      selectorName: 'cp',
    },
    {
      parentName: null,
      property: 'pais',
      type: 'text',
      foreignKey: false,
      selector: sliceState?.pais,
      selectorName: 'pais',
    },
  ];

  const request = {
    razonSocial: sliceState?.razonSocial,
    rfc: sliceState?.rfc,
    correoElectronico: sliceState.correoElectronico,
    diasCredito: sliceState?.diasCredito,
    direccion: {
      tipo: sliceState?.direccionTipo,
      numero: sliceState?.numero,
      calle: sliceState?.calle,
      ciudad: sliceState?.ciudad,
      colonia: sliceState?.colonia,
      estado: sliceState?.estado,
      cp: sliceState?.cp,
      pais: sliceState?.pais,
    },

  };
  const condition =
    sliceState.razonSocial &&
    sliceState.rfc &&
    sliceState.numero &&
    sliceState.calle &&
    sliceState.ciudad &&
    sliceState.estado &&
    sliceState.cp &&
    sliceState.pais &&
    sliceState.diasCredito &&
    sliceState.colonia;

  let components = [
    <CardContainer>
      <CardTitle>Proveedor</CardTitle>
      <RenderInputUI list={form_clientes}
                     sliceActions={createProveedorActions} />
    </CardContainer>,
    <div className='p-2' />,
    <CardContainer>
      <CardTitle>Dirección Fiscal</CardTitle>
      <RenderInputUI list={form_direccionClientes}
                     sliceActions={createProveedorActions} />
    </CardContainer>,
  ];

  return (
    <>
      <InsertTemplate
        sliceState={sliceState}
        insertRequest={request}
        insertThunk={insertThunk}
        components={components}
        condition={condition}
      />
    </>
  );
};
export default CreateProveedor;
