type SendEmailFactoryArgs={
  correoElectronico:string
  documentName:string
  type:string
}
export const edoCuentaSendEmailFactory =({correoElectronico,documentName,type} :SendEmailFactoryArgs) => (
  
  {
  eventBus: null,
  eventSource: 'sistema_mely_frontend',
  detailType: 'notification-email-aws_ses_raw',
  eventDetail: {
    data: {
      email: {
        toAddresses: [correoElectronico],
        content: [
          {
            data: {
              file: {
                url: `https://s3.amazonaws.com/public/${String(type).toUpperCase()}/${documentName}.pdf`,
                source: 's3',
                key: `${String(type).toUpperCase()}/${documentName}.pdf`,
                bucket: 'melypackaging.public',
              },
            },
          },
        ],
      },
    },
    metadata: {
      notificationSettingId: '29c8122d-ff4f-47a5-89b4-6acb6ed74fdc',
      notificationName:`send_email_with_document_${type}_${documentName}`
    },
  },
})

