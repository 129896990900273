import React from 'react';
import Table from '../TailwindUI/Table';
import { prettifyNumber } from '../../Util/prettifyNumber';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { Link } from 'react-router-dom';
import { getKey } from '../../Util/get-key';
import { objectEntry } from '../../Util/object-entry';
import { getValue } from '../../Util/get-value';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const InventarioTablaByKeys = (props) => {
  const reducer = (previousValue, currentValue) => Number(previousValue) + Number(currentValue);
  const total = [];

  props.lista?.forEach((i) => {
    const value = getValue(i);
    if (typeof value === 'number') {
      total.push(getValue(i));
    }
  });

  const GenerateLoteRow = (props, key, value, index) => (
    <TableRow
      key={value}
      className={classNames(index % 2 === 0 ? 'bg-white' : 'bg-gray-100', 'border ')}
    >
      <TableBodyCell>
        <Link
          to={`/inventario/busqueda?nombreInventario=${props.inventario}&simbolo=${key}${
            getKey(value) ? `&lote=${getKey(value)}` : ''
          }`}
        >
          <span className="font-light">{getKey(value) === '' ? 'Sin lote' : getKey(value)}</span>
        </Link>
      </TableBodyCell>

      <TableBodyCell>
        <span className="font-light">{prettifyNumber(getValue(value))}</span>
      </TableBodyCell>
    </TableRow>
  );

  const renderNumber = (value) => <TableBodyCell>{prettifyNumber(String(value))}</TableBodyCell>;

  const toArray = (value) => {
    if (!Array.isArray(value)) {
      return [value];
    }

    console.log('is  Array----->>>>>>>');
    console.log(value);

    return value;
  };
  return (
    <div className="mb-12" style={{ maxWidth: '300px' }}>
      <h1>{props.nombre}</h1>
      <Table>
        <TableHeader>
          <TableRowHeader>
            <TableHeaderCell>Símbolo</TableHeaderCell>
            <TableHeaderCell>Stock</TableHeaderCell>
          </TableRowHeader>
        </TableHeader>
        <TableBody>
          {props.lista?.map((inventario, personIdx) => {
            const [key, value] = objectEntry(inventario);
            return (
              <React.Fragment>
                <TableRow key={key} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                  <TableBodyCell>
                    <Link
                      to={`/inventario/busqueda?nombreInventario=${props.inventario}&simbolo=${key}`}
                    >
                      {key}
                    </Link>
                  </TableBodyCell>
                  {typeof value === 'number' ? (
                    renderNumber(value)
                  ) : (
                    <TableBodyCell></TableBodyCell>
                  )}
                </TableRow>
                {typeof value === 'object' && (
                  <>{toArray(value).map((item) => GenerateLoteRow(props, key, item, personIdx))}</>
                )}
              </React.Fragment>
            );
          })}
          {total.length > 0 && (
            <TableRow>
              <TableBodyCell>TOTAL</TableBodyCell>
              <TableBodyCell>
                <h2 className="text-black">{prettifyNumber(String(total.reduce(reducer)))}</h2>
              </TableBodyCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
export default InventarioTablaByKeys;
