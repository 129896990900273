import React, { useEffect, useContext } from 'react';
import InnerHeader from '../InnerHeader/InnerHeader';
import AuthContext from '../../store/auth-context';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCellAuto from '../TailwindUI/TableHeaderCellAuto';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useSelector, useDispatch } from 'react-redux';
import { LifeCycleFeedback } from '../LifeCycleFeedback/LifeCycleFeedback';
import { getItemsLegacy, handleFilter } from '../../store/fetch-all-slice';
import { useLocation } from 'react-router';
import { useAuth } from '../../context/AuthProvider';

export const DefaultFetchAllList = ({
  sliceState,
  sliceActions,
  getItems,
  getItemsLegacy,
  handleFilter,
  customDisplayMapFn,
  disableFilter,
  tableHeaderMapFn,
  tableBodyMapFn,
  defaultFilterCondition,
  routePath,
  filterCondition = () => true,
  orderByOptions,
  buildRouteFn,
  responseDataKey,
}) => {
  const {
    roleId,
  } = useAuth();
  const dispatch = useDispatch();
  const state = useSelector((state) => state[sliceState]);
  const modal = useSelector((state) => state.modal);

  const itemsRaw = state?.data;
  const itemsDisplay = state?.display;
  const orderByOptionsState = state?.orderByOptions;
  const sortAscending = state?.sortAscending;
  const productFilterSearchValue = state?.filterSearchValue;
  const location = useLocation();

  let itemsDisplayMapFn;
  !customDisplayMapFn
    ? (itemsDisplayMapFn = (item, personIdx) => {
      const mappedItem = tableBodyMapFn ? tableBodyMapFn(item) : item;
      return (
        <React.Fragment key={JSON.stringify(item) + personIdx}>
          <TableRow
            key={mappedItem.id}
            className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
          >
            <TableBodyCellAuto list={mappedItem} />
          </TableRow>
        </React.Fragment>
      );
    })
    : (itemsDisplayMapFn = customDisplayMapFn);

  const resolvePath = () => {
    if (routePath) {
      return routePath;
    } else if (buildRouteFn) {
      return buildRouteFn(location);
    }
  };

  const resolveCsvFileName = () => {
    const locationPaths = location.pathname.split('/');
    const path= locationPaths[locationPaths.length-1]
    const date=new Date(Date.now()).toLocaleDateString()
    const fileName=`${path.replace("-","_")}_${date}`
    return fileName
  };

  function convertToCSV (jsonData) {
    const headers = Object.keys(jsonData[0]);

    const csvContent = [
      headers.join(','),
      ...jsonData.map((row) => headers.map((header) => {
        const value = row[header];
        return typeof value === 'string' && value.includes(',')
          ? `"${value}"`
          : value;
      }).join(',')),
    ].join('\n');

    return csvContent;
  }

  function downloadCSV (csvContent) {
    const csvBlob = new Blob([csvContent], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = csvUrl;
    downloadLink.download = `${resolveCsvFileName()}.csv`;
    downloadLink.click();
  }

  useEffect(() => {
    console.log('reset--->State');
    dispatch(sliceActions.resetState());
    dispatch(sliceActions.setOrderByOptions(orderByOptions));
    dispatch(sliceActions.resetFilter());

    if (getItems) {
      dispatch(getItems({ routePath: resolvePath() }));
    }
    if (getItemsLegacy) {
      dispatch(getItemsLegacy({ responseDataKey, routePath: resolvePath() }));
    }
  }, [routePath, buildRouteFn]);

  const handleNameFilter = (e) => {
    dispatch(handleFilter({ filterCondition, value: e.target.value }));
  };
  const handleResetSearchFilter = (e) => {
    dispatch(sliceActions.resetFilter());
  };

  const handleOrderby = (e) => {
    dispatch(sliceActions.orderBy(e));
  };
  const handleSortBy = (e) => {
    dispatch(sliceActions.sortData(sortAscending));
  };

  const handleDisplayAll = () => {
    if (sliceActions.shouldDisplayAll) {
      dispatch(sliceActions.shouldDisplayAll(!state.displayAll));
    }
    return;
  };

  const defaultFilter = (arr) => {
    console.log('defaultFilter----->>');
    console.log('state.displayAll----->>' + state.displayAll);
    console.log(arr);
    console.log('arr---->>>');
    console.log('!defaultFilterCondition----->>' + defaultFilterCondition);
    console.log(JSON.stringify(defaultFilterCondition));

    if (!defaultFilterCondition) return arr;
    if (state.displayAll) return arr;

    const { key, value } = defaultFilterCondition;
    //Todo -> create a Strategy class to be able to configure this conditions

    console.log('key---->>>' + key);
    console.log('value---->>>' + value);
    console.log('filtering---->>>');
    console.log('filtering---->>>');

    const filtered = arr?.filter((i) => i[key] === value);
    console.log('filtered----->>');
    console.log(filtered);
    return filtered;
  };

  //TODO create a more sophisticated authorization handler
  const shouldCreateEntity = () => {
    if ((roleId === 3 && routePath === 'ordenes-compra/cliente')||(roleId === 3 && routePath === 'ordenes-compra/proveedor')) {
      return false;
    }
    return true;
  };
  const hideCreateButton = shouldCreateEntity();
  return (
    <>
      <div className='flex-1 flex flex-col overflow-hidden'>
        <div className={`bg-${!getItemsLegacy
          ? 'green'
          : 'yellow'}-500 max-w-full py-2`}></div>
        {/* {JSON.stringify(itemsRaw)} */}
        <InnerHeader
          disableFilter={disableFilter}
          placeholder={'Buscar Productos'}
          searchFilter={productFilterSearchValue}
          resetSearchFilter={handleResetSearchFilter}
          hideCreateButton={hideCreateButton}
          orderByOptions={orderByOptionsState}
          handleSortBy={handleSortBy}
          sortAscending={sortAscending}
          state={state}
          handleSearchFilter={handleNameFilter}
          handleOrderby={handleOrderby}
          handleDisplayAll={handleDisplayAll}
        />

        {/* Main content */}
        <FlexContainer className='flex-1 items-stretch  overflow-hidden '>
          <main className='flex-1 overflow-y-auto  '>
            {/* Primary column */}
            <div className={'text-right'}>
              <button
                className={` text-center m-2  mb-0 p-2 border border-transparent text-sm leading-4 font-medium rounded-lg shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkBlue disabled:opacity-25 disabled:cursor-not-allowed`}
                onClick={() => downloadCSV(
                  convertToCSV(defaultFilter(itemsDisplay)))}>Download CSV
              </button>
            </div>

            <FlexContainer className='flex-col   bg-gray-100 py-2'>
              <div
                className='shadow w-auto border-b border-gray-200  rounded-t rounded-b-lg bg-white  m-2 pb-3  overflow-x-auto  '>
                <Table>
                  <TableHeader>
                    <TableRowHeader>
                      <TableHeaderCellAuto
                        list={
                          !tableHeaderMapFn
                            ? defaultFilter(itemsDisplay)
                            : defaultFilter(itemsDisplay)?.map(tableHeaderMapFn)
                        }
                      />
                    </TableRowHeader>
                  </TableHeader>
                  <TableBody>
                    {console.log('itemsDispla----->>>')}
                    {console.log(itemsDisplay)}
                    {defaultFilter(itemsDisplay)?.map(itemsDisplayMapFn)}

                    <TableRow>
                      <TableBodyCell colspan={'5'}
                                     className='text-right'></TableBodyCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </FlexContainer>
          </main>

          <LifeCycleFeedback sliceState={state} />
        </FlexContainer>
      </div>
    </>
  );
};
