import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import * as util from '../lib/edit';
import { authorizationHeader } from '../Util/authorizationHeader';
const apiHOST = apiURL();

const filterSeleccion = (valuesToFilter, selectedValueId) => {
  const filterdValue = valuesToFilter.filter((item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const initialState = {
  editsNotSaved: null,
  cpp: null,
  proveedor: null,
  divisa: null,
  inventario: null,
  productToAsign: [],
  productosCuentaPorPagar: [],
  productosEntrada: null,
};

//Fetch CuentaPorPagar to Edit
export const getCuentaPorPagar = createAsyncThunk(
  'editCuentaPorPagar/getCuentaPorPagar',
  async (payload) => {
    const { urlParam, authCtx } = payload;
    try {
      const response = await axios.get(`${apiHOST}views/cuentas-por-pagar/${urlParam}`, {
        headers: {
          ...authorizationHeader()        },
      });
      if (response.data.length === 0) {
        const datosEntrada = await axios.get(`${apiHOST}cuentas-por-pagar/${urlParam}`, {});
        return datosEntrada.data;
      }
      return response.data;
    } catch (err) {
      return err;
    }
  },
);

export const deleteCuentaPorPagar = util.deleteRequestFromComponentThunk(
  'editCuentaPorPagar/deleteCuentaPorPagar',
  apiHOST,
  'cuentas-por-pagar',
);

//Relations
export const getRelation = util.getRequestRelationThunk('editCuentaPorPagar/getRelation', apiHOST);

export const postRelationProducto = util.postRelationProductoThunk({
  name: 'editCuentaPorPagar/postRelationProducto',
  postRoute: `${apiHOST}views/create/productos-cuenta-por-pagar`,
  getRoute: `${apiHOST}views/productos-cuentas-por-pagar`,
  getParam: 'requestObj',
  getParamKey: 'cuenta_por_pagar_id',
});

export const deleteRelationProducto = util.deleteRelationProductoThunk({
  name: 'editCuentaPorPagar/deleteRelationProducto',
  route: `${apiHOST}views/productos-cuenta-por-pagar/delete`,
  method: 'POST_V2',
  findKey: 'pcppId',
});

export const deleteRelationPago = util.deleteRelationProductoThunk({
  name: 'editCuentaPorPagar/deleteRelationPago',
  route: `${apiHOST}views/pagos-proveedor/delete`,
  method: 'POST_V2',
});

export const deleteRelationNotaDeCredito = util.deleteRelationProductoThunk({
  name: 'editCuentaPorPagar/deleteRelationNotaDeCredito',
  route: `${apiHOST}views/notas-de-credito-proveedor/delete`,
  method: 'POST_V2',
});

const editCuentaPorPagarSlice = createSlice({
  name: 'editCuentaPorPagar',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'cantidadLetra':
          state.ocp.cantidadLetra = action.payload.value;
          break;
        case 'fechaEntrega':
          state.ocp.fechaEntrega = action.payload.value;
          break;
        default:
      }
    },
    select(state, action) {
      state[action.payload.field] = filterSeleccion(
        action.payload.values,
        action.payload.selectedValue,
      );
      state.editsNotSaved = true;
    },

    asign(state, action) {
      state[action.payload.reduxStateTree] = filterSeleccion(
        action.payload.valuesToFilter,
        action.payload.selectedValue,
      );
    },
    cancelAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    productToRemoveAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    resetProductosEntrada(state, action) {
      state.productosEntrada = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCuentaPorPagar.fulfilled, (state, action) => {
      state.cpp = action.payload;
    });
    builder.addCase(deleteCuentaPorPagar.fulfilled, (state, action) => {
      state.editCuentaPorPagar = {
        id: null,
        rfc: null,
        razonSocial: null,
        direccionId: null,
      };

      state.direccionFiscal = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.direccionEntrega = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.relationsProductos = [];
      state.asignedProducts = [];
      state.productToAsign = null;
      state.productToRemoveAsign = null;
    });

    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(postRelationProducto.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = null;
      state[action?.payload?.asignedListString] = [];
      if (Array.isArray(action.payload.data)) {
        action.payload.data.map((i) => state[action?.payload?.asignedListString]?.push(i));
      }
      // else{

      //   state[action?.payload?.asignedListString]=action.payload.data

      // }

      //Set State to Nullstate the product that has been posted.
    });
    builder.addCase(deleteRelationProducto.fulfilled, (state, action) => {
      console.log(' action.payload.asignedListSelector');
      console.log(action.payload.asignedListSelector);
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.pcppId !== action.payload.data.id,
      );

      console.log('FILTERED VALUE');
      console.log(filterdValue);
      state[action.payload.asignedListString] = filterdValue;
    });

    builder.addCase(deleteRelationNotaDeCredito.fulfilled, (state, action) => {
      console.log(' action.payload.asignedListSelector');
      console.log(action.payload.asignedListSelector);
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.id !== action.payload.data.id,
      );

      console.log('FILTERED VALUE');
      console.log(filterdValue);
      state[action.payload.asignedListString] = filterdValue;
    });

    builder.addCase(deleteRelationPago.fulfilled, (state, action) => {
      console.log(
        '!!!!!!!!!!!!!!!!!!!!!!!!!!!-------????>>>>>>>>>>>>>>>>>>>>>>>> action.payload.asignedListSelector',
      );
      console.log(' action.payload.data');
      console.log(action.payload.data);
      console.log(' action.payload.asignedListSelector');
      console.log(action.payload.asignedListSelector);
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.id !== action.payload.data.id,
      );

      console.log('FILTERED VALUE');
      console.log(filterdValue);
      state[action.payload.asignedListString] = filterdValue;
    });

    builder.addCase(getValuesToAsign.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });
  },
});

export default editCuentaPorPagarSlice;

export const editCuentaPorPagarActions = editCuentaPorPagarSlice.actions;
