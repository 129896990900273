import {
  createSalidaInventarioRechazo,
  editEntradaInventarioRechazosActions as sliceActions,
} from '../../store/editEntradaInventarioRechazos-slice';
import React, { useContext, useState } from 'react';
import { CreateProductoSalidaRechazo } from './CreateProductoSalidaRechazo';
import { useDispatch } from 'react-redux';
import { GenericModal } from '../UI/Generics';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router-dom';
import { SelectCornerHint } from '../SharedUi/Inputs';

export const CreateSalidaInventarioRechazo = ({ sliceState, sliceActions }) => {
  const [enCalidadDeId, setEnCalidadDeId] = useState(4);
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const createSalidaRechazo = (productoSalidaRechazo) => {

    const request = {
      entradaIrId: sliceState.entradaRechazo.id,
      enCalidadDeId:+enCalidadDeId,
      productosSalidaRechazo: [productoSalidaRechazo],
    };
    console.log(request)
    console.log('request--------->>>')

    dispatch(sliceActions.createSalidaRechazo(request));
    dispatch(sliceActions.showCreateSalidaRechazoModal(false));
    dispatch(createSalidaInventarioRechazo({ request, authCtx, history }));

  };

  const handleProductoSalidaRechazoState = (e) => {
    createSalidaRechazo(e);
  };

  return (
    <React.Fragment>
      {sliceState.showCreateSalidaRechazoModal && (

        <GenericModal
          title={'Generic Modal'}
          onConfirm={(sliceState) => {
            dispatch(sliceActions.showCreateSalidaRechazoModal(false));
          }}
          onCancel={() => {
            dispatch(sliceActions.showCreateSalidaRechazoModal(false));
          }}
        >
          <div className='mt-6 mb-2'>
            <h2 className='font-bold uppercase mb-4'>Salida Rechazo</h2>
            <SelectCornerHint
              onChange={(e) => {

                setEnCalidadDeId(e.target.value);

              }}
              label='en calidad de'
              value={enCalidadDeId}
              options={
                <React.Fragment>
                  <option value='4'>DEFECTO PROVEEDOR</option>
                  <option value='5'>DEFECTO MELY</option>
                  <option value='6'>RETRABAJO</option>
                </React.Fragment>}
            />
          </div>
          <CreateProductoSalidaRechazo
            sliceState={sliceState}
            sliceActions={sliceActions}
            dispatchState={handleProductoSalidaRechazoState} />

        </GenericModal>
      )}

    </React.Fragment>
  );
};

