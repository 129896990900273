import React, { createContext, useContext } from 'react';
import useAuthentication from '../hooks/useAhthentication';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const {login, userId,roleId,logout, token, isAuthenticated,tokenExpiration,authenticationError} = useAuthentication();

  // const [userId, setUserId] = useState(null);
  // const [roleId, setRoleId] = useState(null);

  const handleLogin = async (credentials) => {
    const userData = await login(credentials);
    if (userData?.userId && userData?.roleId) {
      // setUserId(userData.userId);
      // setRoleId(userData.roleId);
    }

    // Assuming your server returns an object with userId and roleId upon successful login

  };

  const handleLogout = () => {
    logout();
    // setUserId(null);
    // setRoleId(null);
  };

  return (
    <AuthContext.Provider value={{
      userId,
      token,
      roleId,
      isAuthenticated,
      login: handleLogin,
      logout: handleLogout,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
