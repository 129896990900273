import React, { useState, useEffect, useContext, Fragment } from 'react';
import { distitoMateraAPI, apiURL } from '../../Util/apiURL';
import AuthContext from '../../store/auth-context';
import FlexContainer from '../TailwindUI/FlexContainer';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '../HeadlessUI/Modal';
import axios from 'axios';
import { putEntrada, deleteEntrada } from '../../store/editEntrada-slice';
import { prettifyNumber } from '../../Util/prettifyNumber.js';
import {
  getCuentaPorCobrar,
  getRelation,
  editCuentaPorCobrarActions,
  deleteRelationProducto,
  postRelationProducto,
  deleteRelationPago,
  deleteCuentaPorCobrar,
  deleteRelationNotaDeCredito,
} from '../../store/editarCuentaPorCobrar-slice';
import AsignProductoEtoCPC from './AsignProductoEtoCPC';
import DotsDropdown from '../DropdownMenu/DotsDropdown';
import DropdownItemButton from '../DropdownMenu/DropdownItemButton';
import ProductosCuentaPorCobrar from './ProductosCuentaPorCobrar';
import { TrashIcon, CurrencyDollarIcon } from '@heroicons/react/solid';
import ErrorModal from '../UI/Modals/ErrorModal';
import PagoModal from '../UI/Modals/PagoModal';
import { InputCornerHint, SelectCornerHint } from '../SharedUi/Inputs';
import { createPagoClienteActions } from '../../store/crearPagoCliente-slice';
import CreatePagoFromCpc from '../PagosCliente/CreatePagoFromCpc';
import CreateNotaCreditoFromCpc from '../NotaDeCreditoCliente/CreateNotaCreditoFromCpc';

import DocsCard from '../SharedUi/DocsCard';
import {
  DocsCardLayoutLogo,
  DocsCardLayoutDatosDoc,
  DocsCardLayoutMenu,
} from '../SharedUi/DocsCardLayout';

import IconButton from '../TailwindUI/IconButton';
import PagosTable from './PagosTable';
import DeleteModal from '../UI/Modals/DeleteModal';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import { prettyFinancial } from '../../Util/prettyFinancial';
//FIXME  boton observaciones no se ve
const EditCuentaPorCobrar = () => {
  const [isPagoModalOpen, setIsPagoModalOpen] = useState(false);
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
  const [isAddProductoModalOpen, setIsAddProductoModalOpen] = useState(false);
  const editEntradaState = useSelector((state) => state.editEntrada);
  const editCuentaPorCobrar = useSelector((state) => state.editCuentaPorCobrar);

  const relationsProductos = useSelector(
    (state) => state.editOrdenCompraCliente.relationsProductos,
  );
  const asignedProducts = useSelector((state) => state.editOrdenCompraCliente.asignedProducts);
  const dispatch = useDispatch();
  const apiHOST = apiURL();
  const { paramsById } = distitoMateraAPI;
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  console.log('EDIT ENTRADA Stateeee-----');
  console.log(editEntradaState);

  const urlParam = location.pathname.split('/')[2];
  const putUrl = location.pathname.split('/')[1];

  const requestUrl = 'ordenes-compra-cliente';
  const deleteUrl = `cuentas-por-cobrar/${editCuentaPorCobrar.cpc?.id}`;
  const relationProducto_URL = `views/cuentas-por-cobrar/productos-salida/for-cpc/?clienteId=${editCuentaPorCobrar.cpc?.clienteId}&divisaId=${editCuentaPorCobrar.cpc?.divisaId}&iva=${editCuentaPorCobrar.cpc?.iva}`;
  const relationProductosCuentasPorCobrar_URL = `views/productos-cuentas-por-cobrar/${urlParam}`;

  const relationPagos = `views/pagos-cliente/cpc/${urlParam}`;
  const relationNotasDeCredito = `views/notas-de-credito-cliente/cpc/${urlParam}`;
  const relationTotalPagos = `views/pagos-cliente/cpc/total/${urlParam}`;
  const relationTotalNotasDeCredito = `views/notas-de-credito-cliente/cpc/total/${urlParam}`;

  const ordenCompraClienteStateTree = editEntradaState;
  const putRequestObj = {
    numero_ocp: ordenCompraClienteStateTree.ocp?.numeroOcp,
    proveedor_id: ordenCompraClienteStateTree.ocp?.clienteId,
    iva: ordenCompraClienteStateTree.ocp?.iva,
    usuario_id: ordenCompraClienteStateTree.ocp?.usuarioId,
    divisa_id: ordenCompraClienteStateTree.ocp?.divisaId,
    inventario_id: ordenCompraClienteStateTree.ocp?.inventarioId,
    en_calidad_de_id: ordenCompraClienteStateTree.ocp?.enCalidadDeId,
    estatus: ordenCompraClienteStateTree.ocp?.estatus,
    cantidad_letra: ordenCompraClienteStateTree.ocp?.cantidadLetra,
  };

  const [useEffectVariable, setUseEffectVariable] = useState(0);
  const [tipoDeAbono, setTipoDeAbono] = useState('pago');
  const fetchCuentaPorCobrar = () => {
    dispatch(getCuentaPorCobrar({ urlParam, authCtx }));
  };
  useEffect(() => {
    fetchCuentaPorCobrar();
    getRelationHandler(relationProductosCuentasPorCobrar_URL, 'productosCuentaPorCobrar');
  }, []);

  useEffect(() => {
    getRelationHandler(relationPagos, 'pagosCliente');
    getRelationHandler(relationTotalPagos, 'totalPagosCliente');
    getRelationHandler(relationNotasDeCredito, 'notasDeCreditoCliente');
    getRelationHandler(relationTotalNotasDeCredito, 'totalNotasDeCreditoCliente');
    getRelationHandler(relationProductosCuentasPorCobrar_URL, 'productosCuentaPorCobrar');
  }, [useEffectVariable]);

  const handleResetProductosOrdenescompra = () => {
    dispatch(editCuentaPorCobrarActions.resetProductosOrdenCompra());
  };

  const handlePutOrdenCompraCliente = () => {
    dispatch(
      putEntrada({
        requestObj: putRequestObj,
        requestUrl,
        reduxStateTree: ordenCompraClienteStateTree,
        history,
        authCtx,
      }),
    );
  };

  const handleDelete = () => {
    console.log({
      requestUrl: deleteUrl,
      editCuentaPorCobrar,
      history,
    });
    dispatch(
      deleteCuentaPorCobrar({
        requestUrl: deleteUrl,
        editCuentaPorCobrar,
        history,
        authCtx,
      }),
    );
  };

  //Relations
  ////Redux Actions
  const handleAsingProducto = (e) => {
    dispatch(editCuentaPorCobrarActions.asign({ ...e }));
  };
  const handleCancelAsingProducto = (e) => {
    dispatch(editCuentaPorCobrarActions.cancelAsign({ ...e }));
  };
  //Relations AsyncTHunks

  const getRelationHandler = (requestUrl, reduxStateTree) => {
    dispatch(
      getRelation({
        requestUrl,
        reduxStateTree,
        authCtx,
      }),
    );
  };
  const handleEraseRelation = (e) => {
    console.log('errase producto_cpc');
    console.log(e);

    const requestObj = {
      id: e.valueToUnasign.pcpcId,
    };
    const { reduxStateTree, asignedListString, asignedListSelector } = e;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    console.log('requestObj');
    console.log(requestObj);

    dispatch(
      deleteRelationProducto({
        requestObj,
        reduxStateTree,
        asignedListString,
        asignedListSelector,
        authCtx,
      }),
    );
    setUseEffectVariable(useEffectVariable + 1);
  };
  const handleErrasePago = (e) => {
    console.log('errase PAGO');
    console.log(e);

    const requestObj = {
      id: e.valueToUnasign.id,
    };
    const { reduxStateTree, asignedListString, asignedListSelector } = e;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    console.log('event');
    console.log(e);

    dispatch(
      deleteRelationPago({
        requestObj,
        reduxStateTree,
        asignedListString,
        asignedListSelector,
        authCtx,
      }),
    );
    setUseEffectVariable(useEffectVariable + 1);
    getRelationHandler(relationPagos, 'pagosCliente');
    getRelationHandler(relationTotalPagos, 'totalPagosCliente');
  };

  const handleErraseNotaDeCredito = (e) => {
    const requestObj = {
      id: e.valueToUnasign.id,
    };
    const { reduxStateTree, asignedListString, asignedListSelector } = e;
    console.log('reduxStateTree');
    console.log(reduxStateTree);
    console.log('event');
    console.log(e);

    dispatch(
      deleteRelationNotaDeCredito({
        requestObj,
        reduxStateTree,
        asignedListString,
        asignedListSelector,
        authCtx,
      }),
    );
    setUseEffectVariable(useEffectVariable + 1);
    getRelationHandler(relationNotasDeCredito, 'notasDeCreditoCliente');
    getRelationHandler(relationTotalNotasDeCredito, 'totalNotasDeCreditoCliente');
  };

  const handleSelect = (reduxStateTree, value, selectValues) => {
    handleResetProductosOrdenescompra();
    return editCuentaPorCobrarActions.select({
      field: reduxStateTree,
      selectedValue: value,
      values: selectValues,
    });
  };

  const handlePostRelation = (e) => {
    dispatch(editCuentaPorCobrarActions.resetProductosSalida());
    let requestObj = {};
    const { reduxStateTree, selectedValue, asignedListString } = e;
    console.log('reduxStateTree----------');
    console.log(reduxStateTree);
    if (reduxStateTree === 'productToAsign') {
      requestObj = { ...selectedValue };
    }
    dispatch(
      postRelationProducto({
        requestObj,
        reduxStateTree,
        selectedValue,
        asignedListString,
        authCtx,
      }),
    );

    setUseEffectVariable(useEffectVariable + 1);
    setIsAddProductoModalOpen(false);
    getRelationHandler(relationProductosCuentasPorCobrar_URL, 'productosCuentaPorCobrar');
  };
  const handleAsignCpcToPagoCliente = (cpc) => {
    console.log('handleAsignCpcToPagoCliente!!!!!!!___++++++');
    console.log(cpc);
    dispatch(createPagoClienteActions.setCpc(cpc));
  };

  const handleCantidadLetra = (e) => {
    dispatch(
      editCuentaPorCobrarActions.change({
        field: 'cantidadLetra',
        value: e.target.value,
      }),
    );
  };
  const handleDate = (e) => {
    dispatch(
      editCuentaPorCobrarActions.change({
        field: 'fechaEntrega',
        value: e.target.value,
      }),
    );
  };
  const handleCreateCuentaPorCobrar = async (request) => {
    console.log('Create cuenta por cobrar');
    console.log(request);

    try {
      const response = await axios.post(`${apiHOST}views/cuentas-por-cobrar`, request);
      console.log('response Create cuenta por Pagar');
      console.log(response);
      return history.push(`/cuentas-por-cobrar/${response.data.id}`);
    } catch (err) {
      return err;
    }
  };
  const handleCloseModal = () => {
    setIsPagoModalOpen(false);
    setUseEffectVariable(useEffectVariable + 1);
  };
  const handleTipoDeAbono = (e) => {
    if (e.target.value === 'pago') {
      setTipoDeAbono('pago');
      return;
    }
    if (e.target.value === 'notaDeCredito') {
      setTipoDeAbono('notaDeCredito');
      return;
    }
  };

  const divisa = {
    id: editCuentaPorCobrar?.cpc?.divisaId,
    nombre: editCuentaPorCobrar?.cpc?.divisa,
  };
  const totalPagosCliente = editCuentaPorCobrar?.totalPagosCliente?.totalPagosClientesByCpc
    ? editCuentaPorCobrar?.totalPagosCliente?.totalPagosClientesByCpc
    : 0;
  const totalCpc = Number(editCuentaPorCobrar?.cpc?.total);
  const totalPagosYNotas =
    Number(
      editCuentaPorCobrar?.totalPagosCliente?.totalPagosClientesByCpc
        ? editCuentaPorCobrar?.totalPagosCliente?.totalPagosClientesByCpc
        : 0,
    ) +
    Number(
      editCuentaPorCobrar?.totalNotasDeCreditoCliente?.totalNotasDeCreditoClienteByCpc
        ? editCuentaPorCobrar?.totalNotasDeCreditoCliente?.totalNotasDeCreditoClienteByCpc
        : 0,
    );

  return (
    <>
      {deleteModalIsVisible && (
        <DeleteModal
          title={'Borrar CCP'}
          onConfirm={(editCuentaPorCobrar) => {
            console.log('click');

            handleDelete(editCuentaPorCobrar?.cpc?.id);

            setDeleteModalIsVisible(false);
          }}
          onCancel={() => setDeleteModalIsVisible(false)}
        >
          Esta Acción no puede desacerse
        </DeleteModal>
      )}

      {isPagoModalOpen && (
        <PagoModal
          title={`ABONO A CUENTA POR COBRAR --->  ${editCuentaPorCobrar?.cpc?.razonSocial?.toUpperCase()}`}
          onConfirm={() => {
            handleCloseModal();
          }}
        >
          <div>

          <div style={{maxWidth:'90vw' , overflow:'auto', margin:'auto'}}>
            <Table>
              <TableHeader>
                <TableRowHeader>
                  <TableHeaderCell textAlign="text-center">CPP</TableHeaderCell>
                  <TableHeaderCell textAlign="text-center">FACTURA</TableHeaderCell>

                  <TableHeaderCell textAlign="text-center">DIVISA</TableHeaderCell>
                  <TableHeaderCell textAlign="text-center">SUBTOTAL</TableHeaderCell>
                  {editCuentaPorCobrar?.cpc?.iva && (
                    <TableHeaderCell textAlign="text-center">IVA</TableHeaderCell>
                  )}
                  <TableHeaderCell textAlign="text-center">TOTAL</TableHeaderCell>
                  <TableHeaderCell textAlign="text-center">PENDIENTE</TableHeaderCell>
                </TableRowHeader>
              </TableHeader>
              <TableBody>
                <TableRow
                  className={
                    editCuentaPorCobrar?.cpc?.diasRestantes < 0 ? 'bg-red-200' : 'bg-gray-100'
                  }
                >
                  <TableBodyCell textAlign="text-center">
                    {editCuentaPorCobrar?.cpc?.id}
                  </TableBodyCell>

                  <TableBodyCell textAlign="text-center">
                    {editCuentaPorCobrar?.cpc?.factura}
                  </TableBodyCell>

                  <TableBodyCell textAlign="text-center">
                    <span className="uppercase font-bold text-lg">
                      {editCuentaPorCobrar?.cpc?.divisa}
                    </span>
                  </TableBodyCell>
                  <TableBodyCell textAlign="text-center">
                    ${prettifyNumber(editCuentaPorCobrar?.cpc?.subtotal?.toString())}
                  </TableBodyCell>
                  {editCuentaPorCobrar?.cpc?.iva && (
                    <TableBodyCell textAlign="text-center">
                      ${prettifyNumber((editCuentaPorCobrar?.cpc?.subtotal * 0.16).toString())}
                    </TableBodyCell>
                  )}
                  <TableBodyCell textAlign="text-center">
                    ${prettifyNumber(editCuentaPorCobrar?.cpc?.total?.toString())}
                  </TableBodyCell>
                  <TableBodyCell textAlign="text-center">
                    ${prettifyNumber((totalCpc - totalPagosYNotas).toFixed(2).toString())}
                  </TableBodyCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

            <div className=" my-4 p-4 bg-orange-100" style={{ position: 'sticky', top: '50px' }}>
              <SelectCornerHint
                value={tipoDeAbono}
                onChange={handleTipoDeAbono}
                onBlur={handleTipoDeAbono}
                label={'Tipo de Abono'}
                options={[
                  <option value={'pago'}>Pago</option>,
                  <option value={'notaDeCredito'}>Nota De Crédito</option>,
                ]}
              ></SelectCornerHint>
            </div>

            {tipoDeAbono === 'pago' && (
              <CreatePagoFromCpc
                onConfirm={() => {
                  handleCloseModal();
                }}
                divisa={divisa}
                totalPagosYNotas={totalPagosYNotas}
                totalCpc={totalCpc}
                totalPagosCliente={totalPagosCliente}
              />
            )}
            {tipoDeAbono === 'notaDeCredito' && (
              <CreateNotaCreditoFromCpc
                divisa={divisa}
                onConfirm={() => {
                  handleCloseModal();
                }}
                cpc={editCuentaPorCobrar?.cpc}
                totalCpc={totalCpc}
                totalPagosYNotas={totalPagosYNotas}
                totalPagosCliente={totalPagosCliente}
              />
            )}
          </div>
        </PagoModal>
      )}

      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Main content */}
        <FlexContainer className="flex-1 items-stretch  overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            {/*TAB CODE */}
            {/*TAB CODE*/}
            {/* Primary column */}
            {/* <div>{JSON.stringify(editCuentaPorCobrar)}</div> */}
            <FlexContainer className="flex-col">
              <div>
                <DocsCard>
                  <DocsCardLayoutMenu>
                    {editCuentaPorCobrar.productosCuentaPorCobrar.length === 0 ? (
                      <DotsDropdown vigente={true}>
                        <DropdownItemButton onClick={() => setDeleteModalIsVisible(true)}>
                          <TrashIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          Borrar CPP
                        </DropdownItemButton>
                      </DotsDropdown>
                    ) : (
                      ''
                    )}
                  </DocsCardLayoutMenu>
                  <DocsCardLayoutLogo>
                    <div className="text-sm text-left  ">
                      <div>
                        Razon Social{' '}
                        <span className="font-medium">
                          {editCuentaPorCobrar?.cpc?.razonSocial?.toUpperCase()}
                        </span>
                      </div>
                      <div className="mt-d2">
                        Divisa
                        <span className="bg-purple-100 text-purple-600 rounded-xl font-medium px-6 py-1">
                          {editCuentaPorCobrar?.cpc?.divisa?.toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </DocsCardLayoutLogo>
                  <DocsCardLayoutDatosDoc>
                    <div className="col-span-1 text-sm text-right ml-auto ">
                      <div>
                        <span className="mr-2">Cuenta por cobrar:</span>
                        <span className="font-medium">{editCuentaPorCobrar?.cpc?.id}</span>
                      </div>
                      <div>
                        <span className="mr-2">Factura:</span>
                        <span className="font-medium">{editCuentaPorCobrar?.cpc?.factura}</span>
                      </div>
                      <div>
                        <span className="mr-2">Días Restantes</span>
                        <span className="font-medium">
                          {editCuentaPorCobrar?.cpc?.diasRestantes}
                        </span>
                      </div>
                      <div>
                        <span className="mr-2">Fecha Factura:</span>
                        <span className="font-medium">
                          {editCuentaPorCobrar?.cpc?.fechaFactura}
                        </span>
                      </div>
                      <div>
                        <span className="mr-2">Fecha Vencimiento:</span>
                        <span className="font-medium">
                          {editCuentaPorCobrar?.cpc?.fechaVencimiento}
                        </span>
                      </div>
                    </div>
                  </DocsCardLayoutDatosDoc>
                </DocsCard>

                <DocsCard>
                  <div className="tex-center col-span-6">
                    <Modal
                      dispatchClose={() => {
                        setIsAddProductoModalOpen(false);
                      }}
                      isOpen={isAddProductoModalOpen}
                      openButton={
                        editCuentaPorCobrar.cpc?.clienteId && (
                          <IconButton
                            onClick={() => {
                              setIsAddProductoModalOpen(true);
                              getRelationHandler(relationProducto_URL, 'productosSalida');
                            }}
                            color="orangeMely"
                            icon="+"
                          />
                        )
                      }
                      title={'Productos en Cuenta por cobrar'}
                      body={
                        <>
                          <AsignProductoEtoCPC
                            //UI
                            cardTitle={
                              editEntradaState?.productToSearch?.simbolo
                                ? `Entradas Sin Asignar Cuenta Por Pagar`
                                : ''
                            }
                            inputLabel={'Añade Productos'}
                            //Redux
                            asignTree={'productToAsign'}
                            valueToAsignSelector={editCuentaPorCobrar?.productToAsign}
                            asignedListSelector={editCuentaPorCobrar?.productosSalida}
                            asignedListString={'productosCuentaPorCobrar'}
                            entradaId={urlParam}
                            factura={editCuentaPorCobrar?.cpc?.factura}
                            clienteId={editCuentaPorCobrar?.clienteId}
                            cuentaPorCobrar={editCuentaPorCobrar?.cpc}
                            //Function Handlers
                            handleCantidadLetra={handleCantidadLetra}
                            handleSelect={handleAsingProducto}
                            handleCancel={handleCancelAsingProducto}
                            handlePostRelation={handlePostRelation}
                            handleUpdate={handlePutOrdenCompraCliente}
                          />
                          {editCuentaPorCobrar?.productosSalida
                            ? editCuentaPorCobrar?.productosSalida?.length === 0 && (
                                <div className="text-red-400">
                                  No se encontraron productos por añadir
                                </div>
                              )
                            : ''}
                        </>
                      }
                    />
                  </div>

                  <div className="col-span-6 mt-4">
                    <>
                      <div className="text-left mb-2 text-gray-600">
                        PRODUCTOS EN CUENTA POR COBRAR
                      </div>

                      <ProductosCuentaPorCobrar
                        //Redux

                        asignedListSelector={editCuentaPorCobrar?.productosCuentaPorCobrar}
                        asignTree={'productToAsign'}
                        asignedListString={'productosCuentaPorCobrar'}
                        subtotal={editCuentaPorCobrar.cpc?.subtotal}
                        iva={editCuentaPorCobrar.cpc?.iva}
                        total={editCuentaPorCobrar.cpc?.total}
                        //Function Handlers
                        handleErrase={handleEraseRelation}
                      />
                    </>
                  </div>
                </DocsCard>

                <DocsCard>
                  <DocsCardLayoutMenu>
                    {Math.floor(totalCpc) - Math.floor(totalPagosYNotas) === 0 ||
                    Math.floor(totalCpc) - Math.floor(totalPagosYNotas) === 1 ||
                    Math.floor(totalCpc) - Math.floor(totalPagosYNotas) === -1 ? (
                      ''
                    ) : (
                      <DotsDropdown vigente={true}>
                        <DropdownItemButton
                          onClick={() => {
                            handleAsignCpcToPagoCliente(editCuentaPorCobrar.cpc);
                            setIsPagoModalOpen(true);
                          }}
                        >
                          <CurrencyDollarIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          CREAR ABONO
                        </DropdownItemButton>
                      </DotsDropdown>
                    )}
                  </DocsCardLayoutMenu>
                  <div className="col-span-6 mt-4">
                    <div className=" font-medium text-left mb-4 ">ABONOS A CUENTA POR COBRAR</div>
                    <div style={{ maxWidth: '300px' }}>
                      <div className=" text-left mb-12 text-gray-600">
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHeaderCell className="text-gray-600 px-1">
                                Descripción
                              </TableHeaderCell>
                              <TableHeaderCell className="text-gray-600 px-1">
                                Monto
                              </TableHeaderCell>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            <TableRow className="bg-white border">
                              <TableBodyCell className="text-gray-800 px-1">Total</TableBodyCell>
                              <TableBodyCell className="text-gray-800 px-1">
                                <div>{prettyFinancial(totalCpc)}</div>
                              </TableBodyCell>
                            </TableRow>
                            <TableRow className="bg-white border">
                              <TableBodyCell className="text-gray-800 px-1">Suma Abonos</TableBodyCell>
                              <TableBodyCell className="text-gray-800 px-1">
                                <div>${prettifyNumber(totalPagosYNotas.toFixed(2).toString())}</div>
                              </TableBodyCell>
                            </TableRow>
                            <TableRow className="bg-white border">
                              <TableBodyCell className="text-gray-800 px-1">Saldo Pendiente</TableBodyCell>
                              <TableBodyCell
                                className={
                                  Math.floor(totalCpc) - Math.floor(totalPagosYNotas) === 0 ||
                                  Math.floor(totalCpc) - Math.floor(totalPagosYNotas) === 1 ||
                                  Math.floor(totalCpc) - Math.floor(totalPagosYNotas) === -1
                                    ? '  bg-green-100 text-green-600 font-medium  px-1 py-1'
                                    : '  bg-yellow-100 text-gray-600 font-medium  px-1 py-1'
                                }
                              >
                                <div>
                                  $
                                  {prettifyNumber(
                                    (totalCpc - totalPagosYNotas).toFixed(2).toString(),
                                  )}
                                </div>
                              </TableBodyCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </div>

                    <div className={' my-4 text-left text-gray-600'}>
                      PAGOS
                      {/* {editCuentaPorCobrar.cpc?.clienteId && (
                        <IconButton
                          onClick={() => {
                            handleAsignCpcToPagoCliente(
                              editCuentaPorCobrar.cpc
                            );
                            setIsPagoModalOpen(true);
                          }}
                          color="orangeMely"
                          icon="+"
                          iconHeight={2.5}
                          iconWidth={2.5}
                        />
                      )} */}
                    </div>

                    <PagosTable
                      divisa={divisa}
                      modalTitle={'Borrar pago'}
                      //Redux
                      asignedListSelector={editCuentaPorCobrar?.pagosCliente}
                      asignTree={'productToAsign'}
                      asignedListString={'pagosCliente'}
                      total={editCuentaPorCobrar?.totalPagosCliente?.totalPagosClientesByCpc}
                      //Function Handlers

                      handleErrase={handleErrasePago}
                    />

                    <div className=" my-4 text-left text-gray-600">NOTAS DE CREDITO</div>

                    <PagosTable
                      divisa={divisa}
                      modalTitle={'Borrar nota de crédito'}
                      //Redux
                      asignedListSelector={editCuentaPorCobrar?.notasDeCreditoCliente}
                      asignTree={'productToAsign'}
                      asignedListString={'notasDeCreditoCliente'}
                      total={
                        editCuentaPorCobrar?.totalNotasDeCreditoCliente
                          ?.totalNotasDeCreditoClienteByCpc
                      }
                      //Function Handlers
                      handleErrase={handleErraseNotaDeCredito}
                    />
                  </div>
                </DocsCard>
              </div>
            </FlexContainer>
          </main>
        </FlexContainer>

        {
          //#region Productos Entrada
        }
      </div>
    </>
  );
};
export default EditCuentaPorCobrar;
