import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../Util/authorizationHeader';
export const asignPutRequestThunk = (name,host) =>
  createAsyncThunk(
    name,
    async (payload, { rejectWithValue }) => {
      const { requestObj, requestUrl, authCtx } = payload;
      try {
        console.log('assign put request thunk--->>>');
        console.log(authCtx);
        const response = await axios.put(
          `${host}${requestUrl}`,
          requestObj,
          {
            headers: {
              ...authorizationHeader()
            },
          }
        );


        return response.data;
      } catch (err) {
        console.log('assign put request thunk-ERROR-->>>');
        console.log(err)
        return rejectWithValue(err.response.data);
      }
    }
  );
