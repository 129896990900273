import React from 'react';
import {

  Page, Image, Text, View, Document, StyleSheet,
} from '@react-pdf/renderer';
import MelyLogo from '../../images/mely-logo.jpg';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { setDateStringDDMMYYYY_MONTH } from '../../Util/get-date';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: '5%',
    flexWrap: 'wrap',
  },
  section: {
    margin: 10, padding: 10, flexGrow: 1,
  },
  image: {
    width: 135,
  },
  header: {
    flexDirection: 'row', justifyContent: 'space-between', flexBasis: '100%',
  },

  headerDatosCliente: {
    marginTop: '32px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexBasis: '100%',
  },
  flex100: {
    flexBasis: '100%',

    paddingVertical: '3px',
  },
  tableContainer: {
    fontSize: '10px',
    border: '1px solid black',
    borderRadius: '1px',
    flexBasis: '100%',
  },
  tableContainer3: {
    color: 'gray',
    fontSize: '8px',
    border: '1px solid black',
    borderColor: 'gray',
    borderRadius: '1px',
    flexBasis: '100%',
  },
  tableContainer2: {
    fontSize: '10px',

    flexBasis: '100%',
  },
  tableCell: {
    flexDirection: 'row', textAlign: 'center',
  },
  tableCellColumn: {
    flexDirection: 'column', textAlign: 'center', flexBasis: '30px',
  },

  flex25: {
    flexBasis: '25%',

    paddingVertical: '3px',
  },
  flex25VerticalBorder: {
    flexBasis: '25%',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',

    paddingVertical: '3px',
  },
  flex50: { flexBasis: '50%', paddingVertical: '3px' },
  flex75: { flexBasis: '75%', paddingVertical: '3px' },
  flex80: { flexBasis: '80%', paddingVertical: '3px' },
  flex33: { flexBasis: '33.3333%', paddingVertical: '3px' },
  flex33VerticalBorder: {
    flexBasis: '33.3333%',
    paddingVertical: '3px',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  },

  flex45: {
    flexBasis: '50%',
  },

  m15: {
    margin: '15px',
  },

  mt5: {
    marginTop: '5px',
  },

  infoMelyView: {
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: '6px',
    marginTop: '24px',
  },
  infoMelyText: {
    marginBottom: '1px', fontSize: '10px',
  },
  borderBottom: {
    borderBottom: '1px solid black',
  },
  borderTop: {
    borderTop: '1px solid black',
  },

  tituloMely: {
    fontSize: '10px',
  },
  tituloMelyRojo: {
    fontSize: '12px', color: 'red',
  },
  direccionMely: {
    flexBasis: '100%',
    fontSize: '8px',
    paddingTop: '10px',
    textAlign: 'center',
    letterSpacing: '0.5px',
    marginTop: '2px',
  },

  viewOrderNumber: {
    alignSelf: 'flex-start',
  },
  infoDoc: {
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '12px',
    border: '1px solid black',
    borderRadius: '12px',
    paddingHorizontal: '16px',
    paddingVertical: '10px',
  },
  line: {
    width: '100%', border: '0.3px solid black',

    marginTop: '10px', marginBottom: '10px',
  },
  mrAuto: {
    marginRight: 'auto',
  },
  tablaDatos: {
    flexDirection: 'row', alignItems: 'flex-end', padding: '3px',
  },
});

// Create Document Component
const OcpPDF = (props) => (<Document>
    <Page wrap size='A4' style={styles.page}>
      <View style={styles.header}>
        <View>
          <Image style={styles.image} src={MelyLogo} />
        </View>

        <View style={styles.infoMelyView}>
          <Text style={styles.tituloMely}>{props.mely?.razonSocial}</Text>

          <Text style={styles.infoMelyText}>{props.mely?.rfc}</Text>
          <Text style={styles.infoMelyText}>{props.mely?.correo}</Text>
          <Text style={styles.infoMelyText}>{props.mely?.paginaWeb}</Text>
        </View>
        <View style={styles.viewOrderNumber}>
          <View style={styles.infoDoc}>
            <Text style={styles.tituloMely}>{props.info?.titulo}</Text>
            <Text style={styles.tituloMely}>{props.info?.producto}</Text>

            {props?.estatus && (<Text
                style={{
                  fontSize: '8px',
                  marginTop: '5px',
                  marginBottom: '-5px',
                  color: 'gray',
                }}
              >
                {props?.estatus}
              </Text>)}

            <Text style={styles.line}></Text>
            <Text style={styles.tituloMelyRojo}>{`OCP_${props.numeroOC}`}</Text>
          </View>
        </View>
      </View>
      <View style={styles.direccionMely}>
        <Text>{props.mely?.direccion}</Text>
      </View>

      <View style={styles.headerDatosCliente}>
        <View style={styles.flex45}>
          <View style={{ ...styles.infoDoc, fontSize: '10px' }}>
            <Text
              style={styles.mrAuto}>{`PROVEEDOR: ${props.proveedor?.razonSocial}`}</Text>
            <Text
              style={styles.mrAuto}>{`RFC: ${props.proveedor?.rfc?.toUpperCase()}`}</Text>
            <Text style={{ ...styles.mrAuto, marginTop: '3px' }}>
              {`${props.proveedor?.direccionFiscal?.calle} ${props.proveedor?.direccionFiscal?.numero}  ${props.proveedor?.direccionFiscal?.colonia} ${props.proveedor?.direccionFiscal?.ciudad} ${props.proveedor?.direccionFiscal?.estado} ${props.proveedor?.direccionFiscal?.cp} ${props.proveedor?.direccionFiscal?.pais}`}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.infoDoc, fontSize: '10px' }}>
          <Text>FECHA DE CREACIÓN</Text>
          <Text>{setDateStringDDMMYYYY_MONTH(props.info?.fecha)} </Text>
          <Text style={{ marginTop: '5px' }}>FECHA DE ENTREGA</Text>

          <Text>{setDateStringDDMMYYYY_MONTH(props.info?.fechaEntrega)} </Text>
        </View>
        <View style={{ ...styles.infoDoc, fontSize: '10px' }}>
          <Text>DIVISA </Text>
          <Text>{props?.divisa} </Text>
        </View>
      </View>

      <View style={styles.m15}></View>
      <View style={styles.tableContainer}>
        <View>
          <View style={styles.tableCell}>
            <Text style={styles.flex25}>CANTIDAD</Text>
            <Text style={styles.flex25VerticalBorder}>UNIDAD</Text>
            <Text style={styles.flex50}>DESCRIPCIÓN</Text>
            <Text
              style={{
                ...styles.flex25VerticalBorder,
                textAlign: 'right',
                padding: '2px',
              }}
            >
              P.UNITARIO
            </Text>
            <Text style={{
              ...styles.flex25, textAlign: 'right', padding: '2px',
            }}>IMPORTE</Text>
          </View>
        </View>

        {props.list?.map((item) => {
          return (<View style={styles.borderTop}>
              <View style={styles.tableCell}>
                <Text style={{ ...styles.flex25 }}>
                  {prettifyNumber(item.cantidad?.toString())}
                </Text>
                <Text style={styles.flex25VerticalBorder}>{item.unidad}</Text>
                <Text style={styles.flex50}>{item.descripcion}</Text>
                <Text
                  style={{
                    ...styles.flex25VerticalBorder,
                    textAlign: 'right',
                    padding: '2px',
                  }}
                >
                  ${prettifyNumber(item.pUnitario)}
                </Text>
                <Text
                  style={{
                    ...styles.flex25, textAlign: 'right', padding: '2px',
                  }}
                >
                  ${prettifyNumber(item.importe ? item.importe.toFixed(2) : '')}
                </Text>
              </View>
            </View>);
        })}
      </View>
      <View style={styles.m15}></View>
      <View style={styles.m15}></View>
      <View style={styles.tableContainer2}>
        <View style={{ flexDirection: 'row', flexBasis: '30px' }}>
          <View style={{ ...styles.flex80 }}>
            <Text
              style={{
                paddingLeft: '3px',
                paddingBottom: '10px',
                fontSize: '8px',
                borderBottom: '0.6px solid black',
              }}
            >
              CANTIDAD EN LETRA
            </Text>
            <Text style={{ paddingTop: '10px', paddingLeft: '3px' }}>
              {props?.totalTexto}
            </Text>
            <Text style={{
              paddingTop: '20px', paddingLeft: '3px',
            }}>{props?.divisa}</Text>
          </View>

          <View style={{ ...styles.flex25 }}></View>
          <View style={{ ...styles.flex25, border: '1px solid black' }}>
            <Text
              style={{
                ...styles.flex25, textAlign: 'right', padding: '2px',
              }}
            >
              IMPORTE
            </Text>
          </View>
          <View
            style={{
              ...styles.flex25, border: '1px solid black', borderLeft: 'none',
            }}
          >
            <Text
              style={{
                ...styles.flex25, textAlign: 'right', padding: '2px',
              }}
            >
              ${prettifyNumber(props?.subtotal)}
            </Text>
          </View>
        </View>
      </View>
      {props.iva &&
        <View style={{ ...styles.tableContainer2, borderTop: 'none' }}>
          <View style={{ flexDirection: 'row', flexBasis: '20px' }}>
            <View style={styles.flex80}></View>
            <View style={{ ...styles.flex25 }}></View>
            <View
              style={{
                ...styles.flex25, border: '1px solid black', borderTop: 'none',
              }}
            >
              <Text
                style={{
                  ...styles.flex25, textAlign: 'right', padding: '2px',
                }}
              >
                {props.iva ? 'IVA' : ''}
              </Text>
            </View>
            <View
              style={{
                ...styles.flex25,
                border: '1px solid black',
                borderTop: 'none',
                borderLeft: 'none',
              }}
            >
              <Text
                style={{
                  ...styles.flex25, textAlign: 'right', paddin: '2px',
                }}
              >
                ${prettifyNumber(props?.iva)}
              </Text>
            </View>
          </View>
        </View>}
      <View
        style={{
          ...styles.tableContainer2,
        }}
      >
        <View style={{ flexDirection: 'row', flexBasis: '20px' }}>
          <View style={styles.flex80}></View>

          <View style={{ ...styles.flex25 }}></View>
          <View
            style={{
              ...styles.flex25, border: '1px solid black', borderTop: 'none',
            }}
          >
            <Text
              style={{
                ...styles.flex25, textAlign: 'right', padding: '2px',
              }}
            >
              TOTAL
            </Text>
          </View>
          <View
            style={{
              ...styles.flex25,
              border: '1px solid black',
              borderTop: 'none',
              borderLeft: 'none',
            }}
          >
            <Text
              style={{
                ...styles.flex25, textAlign: 'right', padding: '2px',
              }}
            >
              ${prettifyNumber(props?.total)}
            </Text>

          </View>
        </View>
      </View>

      <View style={styles.m15}></View>
      {/*       
      <View style={styles.tableContainer}>
        <View style={styles.borderBottom}>
          <View style={styles.tablaDatos}>
            <Text>Cliente :</Text>
            <Text style={{ paddingLeft: '2px' }}>
              {props.proveedor?.razonSocial?.toUpperCase()}
            </Text>
          </View>
        </View>
        <View style={styles.borderBottom}>
          <View style={styles.tablaDatos}>
            <Text>Fecha :</Text>
            <Text style={{ paddingLeft: '2px' }}> {props.info?.fecha}</Text>
          </View>
        </View>
        <View>
          <View style={styles.tablaDatos}>
            <Text>En Calidad De :</Text>
            <Text style={{ margin: 'auto', fontWeight: 'bold' }}>
              {props.info?.enCalidadDe}
            </Text>
          </View>
        </View>
      </View> */}

      <View style={styles.tableContainer3}>
        <View style={styles.tableCell}>
          <Text style={styles.flex100}>ORDEN DE COMPRA PROVEEDOR</Text>
        </View>
      </View>

      <View style={{ marginBottom: '4px' }}></View>

      <View style={styles.tableContainer3}>
        <View style={styles.tableCell}>
          <Text style={styles.flex25}>CÓDIGO:FOINO38</Text>
          <Text style={styles.flex25}>VERSIÓN:00</Text>
          <Text style={styles.flex25}>FECHA EMISIÓN:01-NOV-23</Text>
          <Text style={styles.flex25}>FECHA REVISIÓN:01-NOV-23</Text>
        </View>
      </View>

    </Page>
  </Document>);

export default OcpPDF;
