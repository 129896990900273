import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSelectOptions } from '../../store/selecfetch-slice';
import IconButton from './IconButton';
import Spinner from '../Spinner/Spinner';
import AuthContext from '../../store/auth-context';
import Combobox from '../UI/Generics/Combobox/Combobox';

export const SelectFetchCombobox = (props) => {
  //Hooks
  const dispatch = useDispatch();
  //Local State
  const [editMode, setEditMode] = useState(false);
  const authCtx = useContext(AuthContext);

  //Redux Selectors
  const selectValues = useSelector((state) => state.selectFetch.options);
  const loading = useSelector((state) => state.selectFetch.loading);

  //Function Handlers

  const handleSelectCombobox = (e) => {
    dispatch(props.reduxAction(props.reduxStateTree, e.id, selectValues));
    setEditMode(false);
  };

  const handleEdit = () => {
    dispatch(fetchSelectOptions({ url: props.url ? props.url : '', authCtx }));
    setEditMode(true);
  };

  return (
    <React.Fragment>
      {loading && editMode && <Spinner color={props.spinnerColor} />}
      {!loading && (
        <React.Fragment>
          <div>
            {!editMode && (
              <>
                <div className='flex'>


                  <IconButton onClick={handleEdit} color='orange'
                              icon='+' />

                </div>
              </>
            )}

            {editMode && (
              <React.Fragment>
                <div className='flex'>
                  <div>
                    <Combobox label={props.label} data={selectValues}
                              handleSelect={handleSelectCombobox}
                              dataKey={"simbolo"}
                              selectedValue={props.sliceState?.productToSearch?.simbolo} />

                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

