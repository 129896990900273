import { useState } from 'react';

export const useSideBar=()=>{
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const closeSideBar = () => {
    console.log('close Sidebar use SideBar -->>');
    setIsSidebarOpen(false);
  };

  const openSidebar = () => {
    // setSidebarOpen(true);
    console.log('open Sidebar use SideBar -->>');
    setIsSidebarOpen(true);
  };

  return {isSidebarOpen,closeSideBar,openSidebar}
}