import { Formik } from 'formik';
import { resolveFormInputComponent } from './resolveFormInputComponent';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { CardButton } from '../SharedUi/UpdateCardUI';
import React from 'react';

export const renderForm = ({formInitialValue,handleSubmit,formElements,validationSchema}) => (
  <div>

    <Formik
      initialValues={formInitialValue}
      validationSchema={validationSchema}
      // validate={}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmit(values);

        setSubmitting(false);

      }}

    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>

          {formElements.map((element) => <>


              <div className={`p-2 ${errors[element.name] ? 'bg-red-50' : ''}`}>

                <div className='relative mt-2 rounded-md shadow-sm'>
                  {resolveFormInputComponent(element, values, handleChange,
                    handleBlur)}
                  {
                    errors[element.name] && touched[element.name] ? (<div
                      className='pointer-events-none absolute inset-y-0 right-0 flex items-end pr-3 pb-2'>
                      <ExclamationCircleIcon className='h-5 w-5 text-red-500'
                                             aria-hidden='true' />
                    </div>) : null}
                </div>
                {
                  errors[element.name] && touched[element.name] ? (
                    <p className='mt-2 text-sm text-red-600 text-left pl-1'
                       id='email-error'>
                      {errors[element.name]}
                    </p>) : null
                }
              </div>
            </>,
          )
          }


          <div>


            <CardButton type='submit' disabled={isSubmitting}>
              Submit
            </CardButton>
          </div>
        </form>
      )}
    </Formik>
  </div>
);