import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Nest } from '../Util/apiURL';
import { getRequestFromClientPathThunkV2 } from '../Util/get-request-thunk';
import {
  getAllItemsRejected,
  defaultPendingEffect,
} from '../lib/default-get-all-items';
import axios from 'axios';
import { createItem } from './create-item-slice';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL();
const apiHOST_Nest = apiURL_Nest();

const initialState = {
  data: [],//the Raw data
  fetchingModel: '',

};

export const fetchOptions = ({ routePath, authCtx, modelName,api }) => {

  return async (dispatch, getState) => {

    dispatch(fetchAllOptionsSlice.actions.reset());
    console.log("route-path->>>",routePath)

    await dispatch(getItems({ routePath, authCtx,api }));
    const { data, error } = getState().fetchAllOptions;
    if (data.length === 0 && error) return error;
    dispatch(
      fetchAllOptionsActions.setModelOptions({ modelName, data }));
    // await dispatch( fetchAllOptionsSlice.actions.resetData());

  };

};
export const getItems = createAsyncThunk(
  'fetchAllOptions/getItems',
  async (payload, { rejectWithValue }) => {
    const { routePath, api } = payload;
    console.log(`------- ->`);
    console.log(`------- API->`,api);
    console.log(`${api === 'nest' ? apiHOST_Nest : apiHOST}${routePath}`);
    let apihost=api === 'nest' ? apiHOST_Nest : apiHOST

    const response = await axios.get(`${apihost}${routePath}`, {
      headers: {
        ...authorizationHeader(),
      },
    });
    console.log(`------- ->`,response);
    console.log(response.data);


    return response.data;

  });

const fetchAllOptionsSlice = createSlice({
  name: 'fetchAllOptions',
  initialState,
  reducers: {
    setModelOptions (state, action) {
      const { modelName, data } = action.payload;
      state[modelName] = data;
      console.log('setting model name ' + modelName + ' values');


      console.log('data-->>' + data);

    },
    reset (state, action) {
      console.log('reseting data');
      state.data = [];
      state.fetchingModel = '';
    },

    filterBy (state, action) {
      const { value, filterFunction } = action.payload;
      state.filterSearchValue = String(value).toUpperCase();
      state.display = filterFunction(state.raw, state.filterSearchValue);
    },
    orderBy (state, action) {
      const { sortFunction } = action.payload;
      state.display.sort(sortFunction);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItems.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(getItems.pending, defaultPendingEffect);
    builder.addCase(getItems.rejected, (state, action) => {
      console.log(action);
      console.log('action--->>error');
      state.loading = false;
      state.error = action.error;
    });
  },
});
export default fetchAllOptionsSlice;
export const fetchAllOptionsActions = fetchAllOptionsSlice.actions;

