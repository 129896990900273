import { createSlice } from '@reduxjs/toolkit';
import { apiURL_Nest } from '../Util/apiURL';
import { getAllItemsFullfilledRest } from '../lib/default-get-all-items';
import { getRequestFromClientPathThunk } from '../Util/get-request-thunk';

const filterSeleccion = (values, selectedValueId) => {
  const filterdValue = values.filter(
    (item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const apiHOST = apiURL_Nest();
const initialState = {
  raw: [],
  display: [],
  formated: [],
  filterSearchValue: '',
  order: false,
  orderBy: 'fechaDePago',
  orderByOptions: ['', 'factura', 'fechaDePago', 'fechaFactura','fechaVencimiento','cuentaPorCobrarId','cuentaPorPagarId'],
  sortAscending: false,
  error: false,
  loading: false,
};

export const getPagosProveedores = getRequestFromClientPathThunk(
  'producto/getPagosProveedores',
  `${apiHOST}`,
);

const pagosProveedoresSlice = createSlice({
  name: 'getPagosProveedores',
  initialState: initialState,
  reducers: {
    resetState (state) {
      state.raw = [];
      state.display = [];
      state.formated = [];
      state.filterSearchValue = '';
    },
    resetFilter (state, action) {
      state.filterSearchValue = null;
    },
    filterBy (state, action) {
      const value = String(action.payload.value).toUpperCase();
      console.log(value)
      console.log('value---->')
      state.filterSearchValue = value;
      try {
        const filtro = action.payload.raw.filter(
          (item) =>
            item.razonSocial.includes(value) ||
            item.fechaDePago.includes(value) ||
            item.fechaFactura.includes(value) ||
            item.fechaVencimiento.includes(value) ||
           String(item?.cuentaPorCobrarId).includes(value) ||
           String(item?.cuentaPorPagarId).includes(value) ||
           String(item?.pago).includes(value) ||
            item.fechaVencimiento.includes(value) ||
            item.factura.includes(value),
        );
        filtro.length > 0
          ? (state.display = filtro)
          : (state.display = action.payload.raw);
      } catch {
        state.display = action.payload.raw;
      }
    },
    orderBy (state, action) {
      state.orderBy = action.payload;
      let payloadValue = action.payload;
      let sortFunction = (a, b) => {
        if (state.sortAscending) {
          if (a[payloadValue] > b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] < b[payloadValue]) {
            return -1;
          }
        }
        if (!state.sortAscending) {
          if (a[payloadValue] < b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] > b[payloadValue]) {
            return -1;
          }
        }
        return 0;
      };

      state.display.sort(sortFunction);
    },
    sortAscending (state, action) {
      state.sortAscending = !action.payload;
      const sortFunction = () => {
        if (state.sortAscending === action.payload) {
          return 1;
        }
        if (state.sortAscending !== action.payload) {
          return -1;
        }
      };
      state.display.sort(sortFunction);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPagosProveedores.fulfilled, getAllItemsFullfilledRest);
    builder.addCase(getPagosProveedores.pending, (state, action) => {
      state.error = false;
      state.loading = true;

    });
    builder.addCase(getPagosProveedores.rejected, (state,action)=>{
      state.error = true;
      state.loading = false;

    });
  },
});

export default pagosProveedoresSlice;

export const pagosProveedoresActions = pagosProveedoresSlice.actions;
