import React, { useContext, useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import SelectHelperV2 from '../TailwindUI/SelectHelperV2';

import { useDispatch, useSelector } from 'react-redux';
import AuthContext from '../../store/auth-context';
import { createItem, updateItem } from '../../store/create-item-slice';
import { InputCornerHint } from '../SharedUi/Inputs';
import { CardButton } from '../SharedUi/UpdateCardUI';
import { LifeCycleFeedbackV3 } from '../LifeCycleFeedback/LifeCycleFeedback';

import {
  ExclamationCircleIcon,
} from '@heroicons/react/outline';

import * as Yup from 'yup';
import { FileInput } from '../TailwindUI/FileInput';
import MultiFileUpload from '../TailwindUI/MultipleFileUpload';
import { renderForm } from './renderForm';

const CreateNotificacion = () => {
    const sliceState = useSelector((state) => state.createItem);
    const dispatch = useDispatch();
    const routePath = 'notificaciones/type';
    const handleSubmit = (values) => {
      const req = {
        request: values, routePath, authCtx,
      };
      dispatch(createItem(req));
      console.log(JSON.stringify(req, null, 2));
    };

    const authCtx = useContext(AuthContext);

    const formInitialValue = {
      name: '',
      description: '',
    };
    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    });

    const formElements = [
      {
        type: 'text',
        name: 'name',
        value: 'name',
        label: 'nombre',
        component: 'input',
      },
      {
        type: 'text',
        name: 'description',
        value: 'description',
        label: 'descripción',
        component: 'input',
      },
    ];

    let component = renderForm(
      { formInitialValue, handleSubmit, validationSchema, formElements });
    return <>
      <div>

        {sliceState.success !== true ? component : ''}


        <div>


          <LifeCycleFeedbackV3 sliceState={sliceState} />
        </div>
      </div>

    </>;

  }
;

export { CreateNotificacion };