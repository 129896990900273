import {
  InputCornerHint,
  NumberInputWithLimitsV2,
  SelectCornerHint,
} from '../SharedUi/Inputs';
import React, { useEffect } from 'react';
import { useObjectReducer } from './useObjectReducer';
import { prettifyNumber } from '../../Util/prettifyNumber';
import UseNumberInputWithMinMax from '../../hooks/UseNumberInputWithMinMax';
import { CardButton } from '../SharedUi/UpdateCardUI';
import { SelectHelpers } from '../SelectHelpers';
import {
  crearEntradaInventarioRechazosActions as sliceActions,
} from '../../store/crearEntradaInventarioRechazos-slice';
import { asingDispatcher } from '../../lib/sharedDispatchers';
import {
  createInitialStateFromModel
} from '../../Util/create-initial-state-from-model';

export const CreateProductoSalidaRechazo = ({ sliceState, dispatchState,sliceActions }) => {
  const {
    cantidad,
    handleInputChange,
    minValue,
    maxValue,
    setMaxValue,
    setInputValueToMaxValue,
    setInputValueToMinValue,
    inputError,
  } = UseNumberInputWithMinMax();

  // const model = [
  //   {
  //     key: 'productoId',
  //     diplay: false,
  //     type: 'number',
  //     value:sliceState.entradaRechazo?.productosEntradaRechazo[0]?.producto?.id
  //   },
  //   {
  //     key: 'cantidad',
  //     type: 'number_with_limits',
  //   },
  //   {
  //     key: 'lote',
  //   },
  //   {
  //     key: 'responsable',
  //   },
  //   {
  //     key: 'descripcion',
  //   },
  //   {
  //     key: 'observaciones',
  //   },
  //   {
  //     key: 'motivo',
  //   },
  // ];

  const model = [
    {
      key: 'productoId',
      diplay: false,
      type: 'number',
      value: sliceState.entradaRechazo?.productosEntradaRechazo[0]?.producto?.id,
    },
    {
      key: 'cantidad',
      type: 'number_with_limits',
    },
    {
      key: 'lote',
    },

    {
      key: 'observaciones',
    },

  ];

  const initialState =createInitialStateFromModel(model)

  const { state, updateField, resetState } = useObjectReducer(initialState);
  useEffect(() => {
    updateField('cantidad', cantidad);
  }, [cantidad]);

  useEffect(() => {
    setMaxValue(sliceState.cantidadMaximaProductoSalidaRechazo);

  }, [sliceState.cantidadMaximaProductoSalidaRechazo]);


  const components = model.map((item,idx) => {
    if (item.diplay === false) return;
    if (item.type === 'number_with_limits') {
      return (
        <NumberInputWithLimitsV2
          key={idx}
          number={cantidad}
          onChange={handleInputChange}
          title={'cantidad'}
          leftLimit={Math.floor(minValue)}
          rightLimit={prettifyNumber(Math.floor(maxValue))}
          setRightValue={setInputValueToMaxValue}
          setLeftValue={setInputValueToMinValue}
          leftLabel='min'
          rightLabel='max'
          error={inputError}
          borderColor={'border-orange-300'}
        />
      );
    }

    return (<InputCornerHint
      name={item.key}
      label={item.key}
      value={state[item.key]}
      onChange={(e) => updateField([item.key], e.target.value)}
      borderColor={'border-orange-300'}
    />);
  });

  return (
    <React.Fragment>
      {components}
      <CardButton
        onClick={() => dispatchState(state)}>
        Crear
      </CardButton>
    </React.Fragment>
  );

};