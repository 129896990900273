import React from 'react';
import Table from '../TailwindUI/Table';

import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableHeaderCellAuto from '../TailwindUI/TableHeaderCellAuto';
import TableBodyCell from '../TailwindUI/TableBodyCell';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import { Link, useHistory } from 'react-router-dom';
import { prettifyNumber } from '../../Util/prettifyNumber';
const InventarioRechazosTable = (props) => {
  const reducer = (previousValue, currentValue) => Number(previousValue) + Number(currentValue);

  return (
    <div className="mb-12" style={{ maxWidth: '300px' }}>
      <h1>{props.nombre}</h1>
      <Table>
        <TableHeader>
          <TableRowHeader className="bg-red-500">
            <TableHeaderCell>Cliente</TableHeaderCell>
            <TableHeaderCell>
              <Table>
                <TableBody>
                  <TableRow className="text-white text-xs">
                    <TableBodyCell>Símbolo</TableBodyCell>
                    <TableBodyCell>Stock</TableBodyCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableHeaderCell>

          </TableRowHeader>
        </TableHeader>
        <TableBody>
          {props.lista?.map((inventario, idx) => {

            return (
              <React.Fragment>
                <TableRow
                  key={inventario.simbolo + inventario.inventario + inventario.total}
                  className={idx % 2 === 0 ? 'bg-white' : 'bg-orange-50'}
                >
                  <TableBodyCell>{inventario.razonSocial}</TableBodyCell>

                  <TableBodyCell colspan="2">

                              <div className=" pt-1pb-2" key={inventario.simbolo}>
                                <Table>
                                  <TableBody>
                                    <TableRow
                                      className={
                                        idx % 2 === 0 ? 'bg-white' : 'border-t border-gray-200'
                                      }
                                    >
                                      <TableBodyCell>

                                          {inventario.simbolo}
                                      </TableBodyCell>
                                      <TableBodyCell textAlign='text-right'  >

                                        {prettifyNumber(String(Number(inventario.total)))}

                                      </TableBodyCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </div>



                  </TableBodyCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
        <TableRow>
          <TableBodyCell>TOTAL</TableBodyCell>
          <TableBodyCell>

            <h2 className="text-black text-right">
              {prettifyNumber(String(props.lista?.reduce((prev,next)=>+prev + +next.total,0)))}
            </h2>

          </TableBodyCell>
        </TableRow>

      </Table>
    </div>
  );
};
export default InventarioRechazosTable;
