
import {
  adminRole, cuentasRole,
  fullRole, inventarioRole, ventasRole,
} from '../services/navigation/navigation-settings-by-role';
import { useAuth } from '../context/AuthProvider';
import { fullRoleSettings } from '../lib/appNavigation';



const resolveMainNavigation=(roleId)=>{
  switch (roleId) {
    case 1:
      return fullRole;
      break;
    case 2:
      return adminRole;
      break;
    case 3:
      return inventarioRole;
      break;
    case 4:
      return ventasRole;
      break;
    case 6:
      return cuentasRole;
      break;
    default:
      return [];
  }

}
const resolveSecondaryNavigation=(userId)=>{
    switch (userId) {
      case 1:
        return fullRoleSettings;
        break;
      case 9:
        return fullRoleSettings;
        break;
      case 3:
        return [];
        break;
      default:
        return [];
    }
}


export const useNavigation =()=> {
  const { roleId,userId} = useAuth();
 return[resolveMainNavigation(roleId),resolveSecondaryNavigation(userId)]

};
