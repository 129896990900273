import React, { useRef } from 'react';
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { GenericTransition } from '../Transition/Transition';
export const ModalOverlay = ({ onCancel, children, onConfirm,overlayColor}) => {
  const [open, setOpen] = useState(true);

  const xButton=useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className={`fixed z-10 inset-0 overflow-y-auto ${overlayColor} h-full`}>
        <GenericTransition>
          <div className="relative inline-block align-bottom bg-white h-full rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle   sm:p-6">
            <div className=" sm:block absolute top-0 right-0 pt-4 pr-4 z-0">
              <button
                ref={xButton}
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={()=>onCancel(xButton)}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            {children}
          </div>
        </GenericTransition>
      </div>
    </Transition.Root>
  );
};
