import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { postItemThunk } from '../lib/default-post-item';
import { asignReducer } from '../lib/shared-reducers';

const apiHOST = apiURL();
const initialState = {
  cliente: null,
  enCalidadDe: { id: 2, nombre: 'VENTA', createdBy: 1 },
  seEmbarca: true,
  usuario: null,
  error: false,
  loading: false,
};

export const insertSalida = postItemThunk(
  'crearEntrada/insertEntrada',
  `${apiHOST}salidas/`,
  'salidas',
);

const createSalidaSlice = createSlice({
  name: 'createSalida',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'cliente':
          state.cliente = action.payload.value;
          break;

        case 'enCalidadDe':
          state.enCalidadDe = action.payload.value;
          break;
        case 'seEmbarca':
          state.seEmbarca = action.payload.value;
          break;

        default:
      }
    },
    asignReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(insertSalida.rejected, (state, action) => {
      console.log('action --------------> insertSalida Rejected');
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(insertSalida.fulfilled, (state, action) => {
      console.log('action --------------> insertSalida Rejected');
      state.enCalidadDe = '';
      state.cliente = '';
      state.error = '';
      state.loading = false;
    });
    builder.addCase(insertSalida.pending, (state, action) => {
      console.log('action --------------> insertSalida pending');
      state.loading = true;
    });
  },
});

export default createSalidaSlice;

export const createSalidaActions = createSalidaSlice.actions;
