import react from 'react';
const Table = (props) => {
  return (
    <table
      style={{ borderSpacing: 0 }}
      className={
        props.className
          ? props.className
          : ' min-w-full divide-y divide-gray-200 text-sm text-gray-400'
      }
    >
      {props.children}
    </table>
  );
};
export default Table;
