import React from 'react';
import TableRow from '../TailwindUI/TableRow';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import { Link } from 'react-router-dom';
import {
  getItems,
  handleFilter,
  fetchAllActions as sliceActions,
} from '../../store/fetch-all-slice';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const customDisplayMapFn = (salida, idx) => {
  const wrapWithLink = (urlPath, content) => (
    <span className="text-orange-600 font-medium">
      <Link to={urlPath}>{content}</Link>
    </span>
  );

  let newObj = {...salida};

  if (newObj.occ) {
    newObj.occ = wrapWithLink(`/ordenes-de-compra-cliente/${newObj.occ}`, newObj.occ);
  }

  if (typeof newObj.cpc === 'number') {
    newObj.cpc = wrapWithLink(`/cuentas-por-cobrar/${newObj.cpc}`, newObj.cpc);
  }

  let coloredRowBg = (newObj.razonSocial === null)
    ? 'bg-yellow-100'
    : (idx % 2 === 0 ? 'bg-white' : 'bg-gray-100');


  return (
    <TableRow key={salida.id} className={coloredRowBg}>
      <TableBodyCellAuto list={newObj} />
    </TableRow>
  );
};


const filterCondition = (state, value) => {
  console.log(state)
  let condition = (
    String(state?.simbolo).toUpperCase().includes(value) ||
    String(state?.razonSocial).toUpperCase().includes(value)||
    String(state?.salida).toUpperCase().includes(value)||
    String(state?.cpc).toUpperCase().includes(value)||
    String(state?.lote).toUpperCase().includes(value)
  );
  return condition;
};


const Salidas = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    routePath:'salidas',
    sliceActions,
    getItems,
    handleFilter,
    customDisplayMapFn,
    filterCondition,
    orderByOptions: [
      '',
      'salida',
      'simbolo',
      'razonSocial',
      'cpc',
      'lote',
      'createdAt'
    ],
  });
};

export default Salidas;
