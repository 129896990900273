
export function CardContainer(props) {
  let color;
  props.color ? (color = props.color) : (color = 'white');
  return (
    <div
      className={`flex flex-col justify-start max-w-sm p-6 px-8 border-solid  grid-rows-3 border-2 border-gray-50 m-4 rounded-xl mx-auto shadow-lg bg-${color}`}
    >
      {props.children}
    </div>
  );
}
export function CardTitle(props) {
  let color;
  props.color ? (color = props.color) : (color = 'gray-600 ');
  // IconGroup(props.color === 'white');
  return (
    <h3 className={`text-lg leading- font-medium text-${color} mb-3`}>
      {props.children}
    </h3>
  );
}

export function   CardButton(props) {
  let bgColor = 'gray';

  props.bgColor ? (bgColor = props.bgColor) : (bgColor = 'gray');
  return (
    <button
      disabled={props.disabled ? props.disabled : false}
      onClick={props.onClick}
      type={props.type || 'button'}
      className={`mt-8 text-center mt-2  px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-lg shadow-sm text-white bg-${bgColor}-600 hover:bg-${bgColor}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkBlue disabled:opacity-25 disabled:cursor-not-allowed`}
    >
      {props.children}
    </button>
  );
}
