import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../../Util/apiURL';
import { postItemThunk } from '../../lib/default-post-item';
import {
  insertItemFullfilled,
  insertItemPending,
  insertItemRejected,
} from '../../lib/default-post-item';

import { selectReducer, asignReducer } from '../../lib/shared-reducers';

const apiHOST = apiURL();
const initialState = {
  nombre: null,
};

export const insertCategoriaProducto = postItemThunk(
  'categoriaProducto/insertCategoriaProducto',
  `${apiHOST}categorias-productos/`,
  'categorias-productos',
);

const newCategoriaProductoSlice = createSlice({
  name: 'newCategoriaProducto',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'nombre':
          state.nombre = action.payload.value.toUpperCase();
          break;
        default:
      }
    },
    selectReducer,
    asignReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(insertCategoriaProducto.fulfilled, insertItemFullfilled(initialState));
    builder.addCase(insertCategoriaProducto.pending, insertItemPending);
    builder.addCase(insertCategoriaProducto.rejected, insertItemRejected);
  },
});

export default newCategoriaProductoSlice;

export const newCategoriaProductoActions = newCategoriaProductoSlice.actions;
