import { EdoCuentasHeader } from './ui/edo-cuentas-header';
import { BooleanHelper } from '../BooleanHelper';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  LifeCycleFeedbackV2,
} from '../LifeCycleFeedback/LifeCycleFeedback';
import EdoCuentaClienteTable from '../EdoCuentaCliente/EdoCuentaClienteTable';
import EdoCuentaProveedorTable
  from '../EdoCuentaProveedor/EdoCuentaProveedorTable';
import { displayDataActions } from '../../store/display-data-slice';

export const EstadoDeCuenta = ({
  divisa,
  sliceState,
  displayAll,
  sliceActions,
  entidadComercial,
}) => {
  const dispatch = useDispatch();

  const handleDisplayAll = () => {
    dispatch(sliceActions.shouldDisplayAll(!displayAll));
  };

  useEffect(() => {
    dispatch(displayDataActions.reset(''));
  }, []);

  return (
    <>
      {sliceState.loading || sliceState.error ? (
        <LifeCycleFeedbackV2 sliceState={sliceState} />
      ) : (
        <div className='flex-1 flex flex-col overflow-hidden'>
          <div className="bg-green-500 max-w-full py-2"></div>
          <EdoCuentasHeader
            entidadComercial={entidadComercial}
            sliceState={sliceState}
            divisa={divisa}
            displayAll={BooleanHelper({
              label: 'Mostrar Todos',
              value: displayAll,
              onChange: handleDisplayAll,
            })}
          />



          {/* Main content */}
          {entidadComercial === 'proveedor'&& <EdoCuentaProveedorTable data={sliceState?.cuentasP} />}
          {entidadComercial === 'cliente'&& <EdoCuentaClienteTable data={sliceState?.cuentasP} />}



        </div>
      )}
    </>
  );
};