import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SearchIcon } from '@heroicons/react/outline';
import IconButton from '../TailwindUI/IconButton';

import OrderTableButton from '../TailwindUI/OrderTableButton';
import ToogleWithIcon from '../TailwindUI/ToogleWithIcon';

const InnerHeader = (props) => {
  let inputValue = '';
  if (props.searchFilter) inputValue = props.searchFilter;
  const location = useLocation();
  const handleOrderby = (e) => {
    props.handleOrderby(e.target.value);
  };

  let orderByOptions = props.orderByOptions?.map((i) => <option>{i}</option>);
  return (
    <header className="w-full sticky ">
      {/* <div className="bg-red-600 py-2 text-white text-base font-semibold">
        hello
      </div> */}
      <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
        <div className="flex-1 flex justify-between ">
          <div className="flex-1 flex">
            <form
              className="w-full flex md:ml-0 p-1"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <label htmlFor="search_field" className="sr-only">
                Buscar
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600 p-1">
                <div className="pointer-events-none absolute inset-y-0 left-5 flex items-center">
                  <SearchIcon className="flex-shrink-0 h-4 w-4" aria-hidden="true" />
                </div>
                <input
                  name="search_field"
                  id="search_field"
                  className={`h-full w-full ${
                    props.disableFilter ? `bg-yellow-100` : ''
                  }  p-2 rounded border-gray-200 py-2 pl-8 pr-3  text-xs text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-blue-800  focus:border-2 focus:placeholder-gray-400`}
                  type="input"
                  disabled={props.disableFilter}
                  onChange={props.handleSearchFilter}
                  onClick={props.resetSearchFilter}
                  placeholder={props.placeholder}
                  value={inputValue}
                />
              </div>
            </form>
          </div>
          <div className=" flex flex-col  items-center justify-center p-4 bg-gray-100 ">
            <div
              className=" text-gray-600  tex-xs rounded text-left tracking-tight"
              style={{ fontSize: '10px' }}
            >
              <span className="mr-1">OFF</span>/<span className="ml-1">ON</span>
            </div>
            <ToogleWithIcon
              enabled={props?.state?.displayAll === false ? true : false}
              onChange={() => props?.handleDisplayAll()}
            />
          </div>
          {props.hideOrdenarPor !== true && (
            <div
              className="mr-6 text-sm flex items-center  p-3 bg-gray-100 "
              style={{ maxWidth: '30%' }}
            >
              <div>
                <div
                  className=" text-gray-600  tex-xs rounded text-left tracking-tight"
                  style={{ fontSize: '10px' }}
                >
                  Ordenar por
                </div>

                <select
                  onBlur={handleOrderby}
                  onChange={handleOrderby}
                  className="h-full  tracking-wide text-sm rounded border-gray-100 bg-gray-100 font-medium text-gray-600 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-blue-800  focus:border-2 focus:placeholder-gray-400"
                >
                  {orderByOptions}
                </select>
                <OrderTableButton
                  handleSortBy={props.handleSortBy}
                  sortAscending={props.sortAscending}
                />
              </div>
            </div>
          )}
          {!!props.hideCreateButton && (
            <div className="mr-6 flex items-center space-x-4 sm:ml- sm:space-x-6">
              {/* Profile dropdown */}
              <Link to={`${location.pathname}/crear/`}>
                <IconButton onClick={() => {}} color="orangeMely" icon="+" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
export default InnerHeader;
