import {
  createInitialStateFromModel,
} from '../../../../Util/create-initial-state-from-model';
import {
  useObjectReducer,
} from '../../../EntradaInventarioRechazos/useObjectReducer';
import { createItem, updateItem } from '../../../../store/create-item-slice';
import { FormItemsBuilder } from './FormItemsBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from './Form';
import { CardButton } from '../../../SharedUi/UpdateCardUI';
import React, { useContext } from 'react';
import AuthContext from '../../../../store/auth-context';
import {
  LifeCycleFeedbackV3,
} from '../../../LifeCycleFeedback/LifeCycleFeedback';

export const CreateForm = ({
  model,
  routePath,
  requestBuilder,
  buttonName,
  method,
  submitGuard,
}) => {
  const initialState = createInitialStateFromModel(model);
  console.log(initialState);
  console.log('initialState--->>');
  const { state, updateField, resetState } = useObjectReducer(initialState);
  const formItems = FormItemsBuilder({ model, state, updateField });
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const sliceState = useSelector((state) => state.createItem);
  const handleSumbit = () => {
    console.log('submiting');

    const history = 'test-history';
    const request = requestBuilder ? requestBuilder(state) : state;
    console.log(request);

    if (!method) {
      dispatch(createItem({ request, routePath, authCtx, history }));
    }
    if (method === 'PATCH') {
      console.log('UPDATING');
      dispatch(updateItem({ request, routePath, authCtx, history }));
    }
  };
  let isButtonDisabled=true
  if (submitGuard === undefined||submitGuard(state)===true) {
    isButtonDisabled=false
  }

  return (

    <div className=' mt-12 p-6 overflow-auto bg-white shadow sm:rounded-lg'>

      <Form handleSubmit={handleSumbit}>
        {formItems}
        <CardButton  disabled={isButtonDisabled}type={'submit'}>{buttonName ?? 'Crear'}</CardButton>
      </Form>
      <div className='mt-4' />
      <LifeCycleFeedbackV3 sliceState={sliceState} />
    </div>

  );

};