export const distitoMateraAPI = {
  hostingProvider: 'https://aws-melypackaging.link/test/',
  localServer: 'http://localhost:8080/test/',
  RootUrl: '',
  local: (local) => {
    if (local) {
      return distitoMateraAPI.localServer;
    } else {
      return distitoMateraAPI.hostingProvider;
    }
  },
  paramsById: '_id',
  parambsByUrl: 'pdfUrl',
};
export const nestAPI = {
  hostingProvider: 'https://server.aws-melypackaging.link/app1/',
  localServer: 'http://localhost:80/',
  RootUrl: '',
  local: (local) => {
    if (local) {
      return nestAPI.localServer;
    } else {
      return nestAPI.hostingProvider;
    }
  },
  paramsById: '_id',
  parambsByUrl: 'pdfUrl',
};
export const docsAPI = {
  hostingProvider: 'https://server.aws-melypackaging.link/app1/',
  localServer: 'http://localhost:80/',
  RootUrl: '',
  local: (local) => {
    if (local) {
      return docsAPI.localServer;
    } else {
      return docsAPI.hostingProvider;
    }
  },
  paramsById: '_id',
  parambsByUrl: 'pdfUrl',
};

export const apiURL = () => distitoMateraAPI.local(false);

export const apiURL_Nest = () => nestAPI.local(false);
export const apiURL_Docs = () => docsAPI.local(false);

// console.log(process.env.NODE_ENV);  For searching in which envirorment we are working

