import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createEntradaActions as sliceActions,
  insertEntrada as insertThunk,
} from '../../store/crearEntrada-slice';
import { SelectHelpers } from '../SelectHelpers';
import InsertTemplate from '../SharedUi/InsertTemplate';
import { asingDispatcher } from '../../lib/sharedDispatchers';

//TODO definir compra como en calidad de
const CreateEntrada = () => {
  const sliceState = useSelector((state) => state.createEntrada);
  const dispatch = useDispatch();

  const handleAsingProducto = (event) => asingDispatcher({ dispatch, sliceActions, event });

  let request = {
    proveedor_id: sliceState?.proveedor?.id,
    en_calidad_de_id: sliceState?.enCalidadDe?.id,
  };

  let components = [
    SelectHelpers({
      name: 'proveedor',
      valueToAsignSelector: sliceState.proveedor,
      handleSelect: handleAsingProducto,
    }),
  ];
  let condition = sliceState.enCalidadDe && sliceState.proveedor;
  return (
    <InsertTemplate
      sliceState={sliceState}
      insertRequest={request}
      insertThunk={insertThunk}
      components={components}
      condition={condition}
    />
  );
};
export default CreateEntrada;
