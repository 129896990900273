import { createSlice } from '@reduxjs/toolkit';
import { apiURL_Nest } from '../Util/apiURL';
import {
  getRequestFromClientPathThunkV2,
} from '../Util/get-request-thunk';
import {
  defaultPendingEffect,
  defaultRejectedEffect,
  getAllItemsFullfilledRest,
} from '../lib/default-get-all-items';

const apiHOST = apiURL_Nest();
const initialState = {
  raw: [],
  formated: [],
  display: [],
  filterSearchValue: '',
  order: false,
  orderBy: null,
  orderByOptions: [
    '',
    'entrada',
    'ocp',
    'razonsocial',
    'createdAt',
    'cpp',
    'cantidad',
    'factura',
    'remision',
    'simbolo',
  ],
  sortAscending: false,
  error: false,
  loading: false,
  success: null,
};

export const getEntradas =
  getRequestFromClientPathThunkV2('producto/getEntradas', `${apiHOST}`);

const entradasSlice = createSlice({
  name: 'getEntradas',
  initialState: initialState,
  reducers: {
    resetFilter(state, action) {
      state.filterSearchValue = null;
    },
    filterBy(state, action) {
      const value = String(action.payload.value).toUpperCase();
      state.filterSearchValue = value;
      try {
        const filtro = action.payload.raw.filter(
          (item) =>
            item.razonSocial.includes(value) ||
            item.simbolo.includes(value) ||
            item.inventario.includes(value) ||
            item.lote.includes(value) ||
            item.factura.includes(value) ||
            item.remision.includes(value) ||
            item.createdAt.includes(value),
        );
        filtro.length > 0 ? (state.display = filtro) : (state.display = action.payload.raw);
      } catch {
        state.display = action.payload.raw;
      }
    },
    orderBy(state, action) {
      state.orderBy = action.payload;
      let payloadValue = action.payload;
      let sortFunction = (a, b) => {
        if (state.sortAscending) {
          if (a[payloadValue] > b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] < b[payloadValue]) {
            return -1;
          }
        }
        if (!state.sortAscending) {
          if (a[payloadValue] < b[payloadValue]) {
            return 1;
          }
          if (a[payloadValue] > b[payloadValue]) {
            return -1;
          }
        }
        return 0;
      };
      if (payloadValue === 'cp') {
        sortFunction = (a, b) => {
          if (state.sortAscending) {
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return -1;
            }
          }
          if (!state.sortAscending) {
            if (Number(a[payloadValue]) < Number(b[payloadValue])) {
              return 1;
            }
            if (Number(a[payloadValue]) > Number(b[payloadValue])) {
              return -1;
            }
          }
          return 0;
        };
      }

      state.display.sort(sortFunction);
    },
    sortAscending(state, action) {
      state.sortAscending = !action.payload;
      const sortFunction = () => {
        if (state.sortAscending === action.payload) {
          return 1;
        }
        if (state.sortAscending !== action.payload) {
          return -1;
        }
      };
      state.display.sort(sortFunction);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEntradas.fulfilled, getAllItemsFullfilledRest);
    builder.addCase(getEntradas.pending, defaultPendingEffect);
    builder.addCase(getEntradas.rejected, defaultRejectedEffect);
  },
});

export default entradasSlice;

export const entradasActions = entradasSlice.actions;
