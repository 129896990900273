import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiURL } from '../Util/apiURL';

const apiHOST = apiURL();
const insertOneValueState = {
  value: '',
  showInsertOneValue: false,
  message: null,
  loading: false,
};

export const insertValueOnSelect = createAsyncThunk(
  'insertOneValue/valueChange',
  async ({ url, request }, { rejectWithValue }) => {
    try {
      console.log('RequestinsertOneValue---->');
      console.log(request);
      console.log('URL insertOneValue ---->');
      console.log(url);

      const response = await axios.post(`${apiHOST}${url}`, request);

      console.log('Response from inserting one value');
      console.log(response);
      console.log('response.status');
      console.log(response.status);

      return response.data;
    } catch (err) {
      rejectWithValue(err.response.data);
      return err;
    }
  },
);

const insertOneValueSlice = createSlice({
  name: 'insertOneValue',
  initialState: insertOneValueState,
  reducers: {
    change(state, action) {
      state.value = action.payload.toUpperCase();
    },
    show(state, action) {
      state.showInsertOneValue = action.payload;
    },
    message(state, action) {
      state.message = action.payload;
    },
    loading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [insertValueOnSelect.fulfilled]: (state, action) => {
      console.log('EXTRA REDUCERSSSSSSSSSSS-----fulfilled');
      console.log('action');
      console.log(action);
      state.value = null;
      state.message = 'success';
      state.loading = false;
      state.showInsertOneValue = false;
    },
    [insertValueOnSelect.pending]: (state, action) => {
      console.log('EXTRA REDUCERSSSSSSSSSSS-----pending');
      state.value = null;
      state.loading = true;
      state.message = 'en proceso';
      state.showInsertOneValue = true;
    },
    [insertValueOnSelect.rejected]: (state, action) => {
      console.log('EXTRA REDUCERSSSSSSSSSSS-----REJECTED');
      state.value = null;
      state.loading = false;
      state.message = 'error';
      state.showInsertOneValue = true;
    },
  },
});

export default insertOneValueSlice;

export const insertOneValueActions = insertOneValueSlice.actions;
