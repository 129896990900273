import { createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Docs, apiURL_Nest } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import { getRequestWparamsThunk } from '../Util/get-request-thunk';
import { asignPutRequestThunk } from '../lib/asing-put-request';
import * as util from '../lib/edit';
import { postDocumentThunk } from '../lib/edit';
const apiHOSTDocs=apiURL_Docs();
const apiHOST = apiURL();
const apiHOST_nest=apiURL_Nest()
const filterSeleccion = (valuesToFilter, selectedValueId) => {
  const filterdValue = valuesToFilter.filter((item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const initialState = {
  ocp: '',
  document:'',
  proveedor: '',
  divisa: '',
  inventario: '',
  pedidoPor: '',
  productToAsign: '',
  productosOrdenCompra: [],
  subtotal: '',
  error: false,
  loading: false,
  success: false,
};

export const getOrdenCompraProveedor = getRequestWparamsThunk(
  'ordenCompraProveedor/getOrdenCompraProveedor',
  `${apiHOST_nest}ordenes-compra/proveedor`,
);
export const putOrdenCompraProveedor = asignPutRequestThunk(
  'ordenCompraProveedor/putOrdenCompraProveedor',
  apiHOST,
);

export const deleteOrdenCompraProveedor = util.deleteRequestFromComponentThunk(
  'ordenCompraProveedor/deleteOrdenCompraProveedor',
  apiHOST,
  'ordenes-de-compra-proveedor',
);

export const getRelation = util.getRequestRelationThunk(
  'ordenCompraProveedor/getRelation',
  apiHOST,
);


export const postDocument= util.postDocumentThunk({
  name: 'ordenCompraProveedor/document',
  postRoute: `${apiHOSTDocs}documents`,
});



export const downloadDocument = getRequestWparamsThunk(
  'ordenCompraProveedor/getOrdenCompraProveedor',
  `${apiHOST_nest}documents`,
);



export const postEmail = util.postDocumentThunk({
  name: 'ordenCompraProveedor/notification/email',
  postRoute: `${apiHOSTDocs}notificaciones`,
});



export const postRelationProducto = util.postRelationProductoThunk({
  name: 'ordenCompraProveedor/postRelationProducto',
  postRoute: `${apiHOST}productos-ocp`,
  getRoute: `${apiHOST}views/productos-ocp`,
  getParam: 'requestObj',
  getParamKey: 'numero_ocp',
});

export const deleteRelationProducto = util.deleteRelationProductoThunk({
  name: 'ordenCompraProveedor/deleteRelationProducto',
  route: `${apiHOST}productos-ocp`,
  method: 'DELETE',
});

export const cerrarOrdenCompraProveedor = util.putRequestThunkPrimaryKey(
  'ordenCompraProveedor/cerrarOrdenCompraProveedor',
  `${apiHOST}views/ordenes-compra-proveedor/cerrar`,
);

const editOrdenCompraProveedorSlice = createSlice({
  name: 'editOrdenCompraProveedor',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'cantidadLetra':
          state.ocp.cantidadLetra = action.payload.value;
          break;
        case 'fechaEntrega':
          state.ocp.fechaEntrega = action.payload.value;
          break;
        default:
      }
    },
    select(state, action) {
      console.log(' Edit actions  SELECT action.payload.field--------->>>>');
      console.log(action.payload.field);
      console.log(action.payload.selectedValue);
      console.log(action.payload.values);
      state[action.payload.field] = filterSeleccion(
        action.payload.values,
        action.payload.selectedValue,
      );
      state.editsNotSaved = true;
    },

    asign(state, action) {
      state[action.payload.reduxStateTree] = filterSeleccion(
        action.payload.valuesToFilter,
        action.payload.selectedValue,
      );
    },
    cancelAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    productToRemoveAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    resetProductToAssign(state, action) {
      state.productToAsign= null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrdenCompraProveedor.fulfilled, (state, action) => {
      const { ocp,productosOcp,subtotal,iva,total,totalTexto,document } = action.payload;
      state.subtotal=subtotal;
      state.subtotalOk=subtotal;
      state.total=total;
      //TODO this is a patch.. Logic should be updated on Backend
      state.iva=ocp.iva?iva:null;
      state.totalTexto=totalTexto;
      state.ocp = ocp;
      state.document=document
      state.productosOcp = productosOcp;
      state.productosOrdenCompra = productosOcp;
      state.proveedor = ocp?.proveedor;
      state.divisa = ocp?.divisa;
      state.inventario = ocp?.inventario;
      state.error = false;
    });

    builder.addCase(getOrdenCompraProveedor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    builder.addCase(deleteOrdenCompraProveedor.fulfilled, (state, action) => {
      state.ordenCompraProveedor = {
        id: null,
        rfc: null,
        razonSocial: null,
        direccionId: null,
      };

      state.direccionFiscal = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.direccionEntrega = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.relationsProductos = [];
      state.asignedProducts = [];
      state.productToAsign = null;
      state.productToRemoveAsign = null;
    });
    builder.addCase(putOrdenCompraProveedor.fulfilled, (state, action) => {
      console.log('------>>>>>>>>>>>>>>>>>>');
      console.log('putOrdenCompraProveedor');
      console.log(action.payload);
      state.loading = false;
      state.error = false;
    });
    builder.addCase(putOrdenCompraProveedor.pending, (state, action) => {
      state.loading = 'testtt';
    });

    builder.addCase(putOrdenCompraProveedor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(postRelationProducto.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = null;
      console.log('action.payload.data-------?');
      console.log(action.payload.data);

      console.log('action.payload.data-------?');
      console.log(action.payload.asignedListString);

      state[action?.payload?.asignedListString] = [];
      if (Array.isArray(action.payload.data)) {
        action.payload.data.map((i) => state[action?.payload?.asignedListString]?.push(i));
      }
      // else{

      //   state[action?.payload?.asignedListString]=action.payload.data

      // }

      //Set State to Nullstate the product that has been posted.
    });
    builder.addCase(postRelationProducto.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loading = false;
    });
    builder.addCase(deleteRelationProducto.fulfilled, (state, action) => {

      console.log("delete relation producto fulldiled--->",action.payload)
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.id !== action.payload.data.id,
      );
      console.log("filtered--->", filterdValue)
      state[action.payload.asignedListString] = filterdValue;
      console.log("testing-->")
    });
    builder.addCase(getValuesToAsign.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });

    builder.addCase(cerrarOrdenCompraProveedor.fulfilled, (state, action) => {
      state.ocp.vigente = false;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(cerrarOrdenCompraProveedor.pending, (state, action) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(cerrarOrdenCompraProveedor.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default editOrdenCompraProveedorSlice;

export const editOrdenCompraProveedorActions = editOrdenCompraProveedorSlice.actions;
