import { useState, useEffect } from 'react';
import axios from 'axios';  // Assuming you're using axios for HTTP requests.
import { apiURL_Nest } from '../Util/apiURL';
import { decodeJWT } from '../Util/decodeJWT';
import { useHistory } from 'react-router-dom';

const useAuthentication = () => {
  const [token, setToken] = useState(localStorage.getItem('jwtToken') || null);
  const [tokenExpiration, setTokenExpiration] = useState(
    +localStorage.getItem('tokenExpiration') || null);
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [userId, setUserId] = useState(+localStorage.getItem('userId') || null);
  const [roleId, setRoleId] = useState(+localStorage.getItem('roleId') || null);
  const [authenticationError, setAthenticationError] = useState(null);
  const history = useHistory();
  //
  // useEffect(() => {
  //   const handleClick = () => {
  //     if (isTokenExpired()) {
  //       logout();
  //     }
  //   };
  //
  //   window.addEventListener('click', handleClick);
  //
  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener('click', handleClick);
  //   };
  // }, [token, tokenExpiration]); // Re-run the effect if token or its expiration changes

  useEffect(() => {

    const checkInterval = 5000; // e.g., check every 5 seconds
    const interval = setInterval(() => {
      console.log(`isTokenExpired ? ${isTokenExpired()}`);
      if (token && isTokenExpired()) {
        logout();
      }
    }, checkInterval);

    return () => clearInterval(interval); // Clear interval if component unmounts
  }, [token]);

  const logTokenExpiration = (currentTimestamp, tokenExpirationTimestamp) => {
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = tokenExpirationTimestamp -
      currentTimestamp;

// Convert the difference to hours and minutes
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

// You can use Math.floor() to round down to the nearest whole number if you want
    console.log(`Token Expiration Timestamp: ${tokenExpirationTimestamp}`);
    console.log(`Hours left: ${Math.floor(differenceInHours)}`);
    console.log(`Minutes left: ${Math.floor(differenceInMinutes)}`);
  };

  const isTokenExpired = () => {
    const currentTimestamp = new Date().getTime();
    const tokenExpirationDate = new Date(tokenExpiration);
    const tokenExpirationTimestamp = tokenExpirationDate.getTime();
    console.log(`Token Expiration Date : ${tokenExpirationDate}`);

    if (tokenExpirationTimestamp === 0) return;

    logTokenExpiration(currentTimestamp, tokenExpirationTimestamp);

    return currentTimestamp > tokenExpirationTimestamp;
  };

  // login method
  const login = async (credentials) => {
    const apiHOST = apiURL_Nest();
    console.log(`login()`);

    try {
      const response = await axios.post(`${apiHOST}authentication/sign-in`,
        credentials); // Replace with your API endpoint.
      const { token, roleId, tokenExpiration } = response.data;
      console.log('response.data--->');
      console.log(response.data);
      const { payload } = decodeJWT(token);
      console.log(payload);
      localStorage.setItem('jwtToken', token);  // Store token in localStorage.
      setToken(token);
      const tokenExp=+payload.exp* 1000
      localStorage.setItem('tokenExpiration', tokenExp);  // Store tokenExpiration in localStorage.
      setTokenExpiration(tokenExp);
      localStorage.setItem('userId', payload.userId);  // Store userId in localStorage.
      setUserId(+payload.userId);

      localStorage.setItem('roleId', roleId);  // Store roleId in localStorage.
      setRoleId(+roleId);
      setIsAuthenticated(true);
      return { userId, roleId, token };
    } catch (err) {
      console.log(err?.response);
      setAthenticationError(
        err?.response?.data?.message || 'Ocurrio un error, intenta nuevamente');
    }
  };

  // logout method
  const logout = () => {
    console.log(`logout()`);
    localStorage.clear();  // Remove token from localStorage.
    localStorage.removeItem('jwtToken');  // Remove token from localStorage.
    localStorage.removeItem('tokenExpiration');  // Remove tokenExpiration from localStorage.
    localStorage.removeItem('roleId');  // Remove tokenExpiration from localStorage.
    localStorage.removeItem('userId');  // Remove tokenExpiration from localStorage.
    setToken(null);
    setTokenExpiration(null);
    setIsAuthenticated(false);
    history.push('/');
    window?.location?.reload();
  };

  // useEffect to initialize authentication state on component mount.
  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [token]);

  return {
    login,
    logout,
    token,
    isAuthenticated,
    userId,
    roleId,
    tokenExpiration,
    authenticationError,
  };
};

export default useAuthentication;
