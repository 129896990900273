//TODO
// 1. Create a backend for retreiving the information regarding Inventario rechazos
import React from 'react';
import { DefatultItemsList } from '../SharedUi/DefaultItemsList';
import {
  getItems,
  handleFilter,
  fetchAllActions as sliceActions,
} from '../../store/fetch-all-slice';
import { sortObjKeysFromArray } from '../../Util/sort-utils';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { setDateStringDDMMYYYY } from '../../Util/get-date';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const sortingArray = ['id', 'cantidad', 'simbolo', 'razonSocialClientes'];



const RechazosCliente = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    routePath:'entrada-rechazo',
    sliceActions,
    getItems,
    handleFilter,
    orderByOptions: [
      '',
      'id',
      'simbolo',
      'creadaEn',
      'razonSocial',
      'cantidad'
    ],
  });
};

export default RechazosCliente;
