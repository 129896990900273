import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import Card from './Card';
import Button from './Button';
import classes from './DeleteModal.module.css';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { LifeCycleFeedbackV3 } from '../../LifeCycleFeedback/LifeCycleFeedback';

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onCancel} />;
};
const ModalOverlay = (props) => {
  const [open, setOpen] = useState(true);

  const confirmButtonRef=useRef(null)
  const cancelButtonRef=useRef(null)
  const xButtonRef=useRef(null)
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <div className='fixed z-10 inset-0 overflow-y-auto'
             onClick={props.onCancel}>
          <div
            className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div
                className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen'
                  aria-hidden='true'>
            &#8203;
          </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div
                className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <div
                  className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => props.onCancel(xButtonRef)}
                    ref={xButtonRef}
                  >
                    <span className='sr-only'>Close</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  <div
                    className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <ExclamationIcon className='h-6 w-6 text-red-600'
                                     aria-hidden='true' />
                  </div>
                  <div
                    className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <h3>{props.title}</h3>
                    <h6 className='text-gray-400 text-sm'>{props.item}</h6>

                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>
                        {`${props.message} \n \n¿Estas seguro que deseas borrar este registro? El registro desaparecerá del servidor.Esta acción no puede deshacerse.
                     `}
                      </p>
                    </div>
                  </div>
                </div>
                {props?.sliceState?.success||props?.sliceState?.error? '':(<div className='mt-5  sm:mt-4 sm:flex sm:flex-row-reverse'>
                  <button
                    ref={confirmButtonRef}
                    type='button'
                    className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={() => props.onConfirm(confirmButtonRef)}
                  >
                    Borrar
                  </button>
                  <button
                    ref={cancelButtonRef}
                    type='button'
                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
                    onClick={() => props.onCancel(cancelButtonRef)}
                  >
                    Cancelar
                  </button>
                </div>)}

                {props.sliceState &&
                  <div className='mt-4 w-full'><LifeCycleFeedbackV3

                    sliceState={props.sliceState}
                  /></div>}
              </div>


            </Transition.Child>
          </div>

        </div>

      </Transition.Root>

    </div>
  );
};
const DeleteModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onCancel={props.onCancel} />,
        document.getElementById('backdrop-root'),
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          sliceState={props.sliceState}
          title={props.title}
          item={props.item}
          message={props.message}
          onCancel={props.onCancel}
          onConfirm={props.onConfirm}
        />,

        document.getElementById('delay-root'),
      )}

    </React.Fragment>
  );
};

export default DeleteModal;
