import { objectEntry } from './object-entry';

export const groupArrayByRootKey = (arr) => {
  const obj = new Object();

  if (!Array.isArray(arr)) return;

  arr.forEach((item, index) => {
    //extract item  values
    const [itemKey, itemValue] = objectEntry(item);
    //Write itemKey if not exists
    if (!obj[itemKey]) {
      obj[itemKey] = [];
    }
    let currentKeyArr = obj[itemKey];

    return currentKeyArr.push(itemValue);
  });
  return obj;
};
