import CuentasPorCobrar from './CuentasPorCobrar';
import CreateCuentaPorCobrar from './CreateCuentaPorCobrar';
import EditCuentaPorCobrar from './EditCuentaPorCobrar';

export const cpcRoutes = [
  {
    name: 'Edit CPPs',
    href: '/cuentas-por-cobrar',
    component: <CuentasPorCobrar />,
    exact: true,
  },
  {
    name: 'Edit CPPs',
    href: '/cuentas-por-cobrar/:id',
    component: <EditCuentaPorCobrar />,
    exact: true,
  },
  {
    name: 'Create CPP',
    href: '/cuentas-por-cobrar/crear/',
    component: <CreateCuentaPorCobrar />,
    exact: true,
  },
];
