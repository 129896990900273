import {
  getItems,
  handleFilter,
  fetchAllActions as sliceActions,
} from '../../store/fetch-all-slice';
import { sortObjKeysFromArray } from '../../Util/sort-utils';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const sortingArray = ['id', 'simbolo', 'categoria', 'divisaNombre'];

const tableHeaderMapFn = (obj, idx) => {
  const newObj = { ...obj };
  return sortObjKeysFromArray(newObj, sortingArray);
};
const tableBodyMapFn = (obj, idx) => {
  const newObj = { ...obj };
  return sortObjKeysFromArray(newObj, sortingArray);
};
const filterCondition = (state, value) => {
  let condition = (
    //TODO make all keys lowercase
    String(state?.simbolo).toUpperCase().includes(value) ||
    String(state?.categoria).toUpperCase().includes(value)
  );
  return condition;
};

const Productos = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    getItems,
    handleFilter,
    routePath: 'productos',
    filterCondition,
    // tableHeaderMapFn,
    // tableBodyMapFn,
    orderByOptions: [
      '',
      'id',
      'simbolo',
      'categoria',
    ],
  });

};
export default Productos;
