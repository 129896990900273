import React, { useContext } from 'react';
import {
  getItems,
  fetchAllActions as sliceActions,
} from '../../store/fetch-all-slice';
import { modalActions } from '../../store/modal-slice';
import TableRow from '../TailwindUI/TableRow';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import { sortObjKeysFromArray } from '../../Util/sort-utils';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';
import { IconButton2 } from '../TailwindUI/IconButton';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

const sortingArray = ['numeroOcp', 'razonSocial', 'rfc'];

const tableHeaderMapFn = (usuario, idx) => {
  console.log('table header?????');
  let newObj = { ...usuario };
  newObj.estatus = usuario.disabled ? 'DESABILITADO' : 'ACTIVO';
  newObj[' '] = '';
  delete newObj.disabled;

  return sortObjKeysFromArray(newObj, sortingArray);
};

const Usuarios = () => {
  const dispatch = useDispatch();
  const history=useHistory()
  const location=useLocation()
  const authCtx = useContext(AuthContext);
  const customDisplayMapFn = (usuario, idx) => {

    let newObj = { ...usuario };
    newObj.estatus = usuario.disabled ? 'DESABILITADO' : 'ACTIVO';
    delete newObj.disabled;
    newObj[' '] = (
      <>
        <IconButton2 icon={'edit'} color={'orangeMely'} onClick={() => {
          dispatch(modalActions.setModalContext({
            entity:usuario,
            apiRoutePath:"usuarios",
            authCtx
          }))
          history.push(`${location.pathname}/${usuario.id}/editar`)

        }} />
        <IconButton2 icon={'trash'} color={'red'} onClick={() => {
          dispatch(modalActions.setModalContext({
            entity:usuario,
            apiRoutePath:"usuarios",
            authCtx
          }))
          history.push(`${location.pathname}/${usuario.id}/borrar`)
        }} />
      </>
    );

    const mappedObj = sortObjKeysFromArray(newObj, sortingArray);

    return (
      <React.Fragment key={JSON.stringify(usuario) + idx}>
        <TableRow
          key={usuario.id}
          className={
            idx % 2 === 0 ? 'bg-white' : 'bg-gray-100'
          }
        >
          <TableBodyCellAuto list={mappedObj} />
        </TableRow>
      </React.Fragment>
    );
  };

  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    getItems,
    customDisplayMapFn,
    tableHeaderMapFn,
    routePath: 'usuarios',
  });
};

export default Usuarios;
