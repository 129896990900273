
import {  createSlice } from '@reduxjs/toolkit';
import {  apiURL_Nest } from '../Util/apiURL';
import { getRequestFromClientPathThunk } from '../Util/get-request-thunk';

const apiHOST = apiURL_Nest();
const initialState = {
  raw: [],
  formated: [],
  display: [],
  filterSearchValue: '',
  order: false,
  orderBy: null,
  orderByOptions: ['', 'salida', 'razonSocial', 'cp'],
  sortAscending: false,
  displayAll: false,
  cuentasP:[]
};

export const getEdoCuentaProveedor = getRequestFromClientPathThunk(
  'edoCuentaProveedor/getEdoCuentaProveedor',
  `${apiHOST}proveedores`,
);


const edoCuentaProveedorSlice = createSlice({
  name: 'getSalidas',
  initialState: initialState,
  reducers: {
    resetFilter(state, action) {
      state.filterSearchValue = null;
    },
    shouldDisplayAll(state, action) {
      state.displayAll = action.payload;
    },
    filterBy(state, action) {
      state.filterSearchValue = action.payload.value;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getEdoCuentaProveedor.fulfilled, (state, action) => {
      //   console.log('action.payload------get edoCuentaProveedor');
      //   console.log(action.payload);
      state.display = action.payload;
      state.mxn = action.payload?.mxn;
      state.total = action.payload?.total;
      state.usd = action.payload?.usd
      state.cuentasP = action.payload?.cuentasP?.flat()
    });
  },
});

export default edoCuentaProveedorSlice;

export const edoCuentaProveedorActions = edoCuentaProveedorSlice.actions;
