import {
  getItems,
  handleFilter,
  fetchAllActions as sliceActions
} from '../../store/fetch-all-slice';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const filterCondition = (state, value) => {
  let condition = (
    //TODO make all keys lowercase
    String(state?.id).toUpperCase().includes(value) ||
    String(state?.nombre).toUpperCase().includes(value)
  );
  return condition;
};


const CategoriasProductos = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    //TODO change this route to the backend
    getItems,
    handleFilter,
    routePath: 'categorias-producto',
    filterCondition,

    orderByOptions: [
      '',
      'id',
      'nombre',
    ],
  });
};
export default CategoriasProductos;
