import { createObj } from './create-obj';
import { groubBySeparator } from './group-by-separator';
import { iterateArrayWithFnCall } from './iterate-array-with-fn-call';

export const unflattenObjToArray = (obj, splitBy) => {
  return Object.entries(obj).map(([key, value]) => {
    const groups = groubBySeparator(key, splitBy);
    const createObjFromLastGroupsIndex = (arr, value) => createObj(arr.slice(-1), value);
    return iterateArrayWithFnCall(groups, value, createObjFromLastGroupsIndex);
  });
};
