import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../../Util/apiURL';
import {
  asignReducer,
  cancelAsignReducer,
  productToRemoveAsignReducer,
} from '../../lib/shared-reducers';
import * as util from '../../lib/edit';

const apiHOST = apiURL();
const initialState = {
  id: '',
  nombre: '',
  error: false,
  loading: false,
  success: null,
};

//--------OK REQUESTS------------------------------------------------------------
export const getCategoriasProducto = util.getDefaultRequestThunk(
  'showCategoriasProducto/getCategoriasProducto',
  `${apiHOST}categorias-productos`,
);
export const putCategoriasProducto = util.putRequestThunk(
  'showCategoriasProducto/putCategoriasProducto',
  apiHOST,
);

export const deleteCategoriasProducto = util.deleteRequestThunk(
  'showCategoriasProducto/deleteCategoriasProducto',
  apiHOST,
  'categorias-productos',
);

export const putJoinedTable = util.putRequestJoinedTableThunk(
  'showCategoriasProducto/putForeignEntity',
  apiHOST,
);

//--------OK REQUESTS------------------------------------------------------------
export const showCategoriasProductoSlice = createSlice({
  name: 'showCategoriasProducto',
  initialState: initialState,
  reducers: {
    change(state, action) {
      console.log('action.payload----->>>>>>>');
      console.log(action.payload);
      switch (action.payload.field) {
        case 'nombre':
          state.nombre = action.payload.value;
          break;
        default:
      }
    },
    asignReducer,
    cancelAsignReducer,
    productToRemoveAsignReducer,
  },
  resetSucces(state) {
    state.success = null;
  },
  resetError(state) {
    state.error = false;
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoriasProducto.fulfilled, (state, action) => {
      state.nombre = action.payload.nombre;
      state.id = action.payload.id;
    });

    builder.addCase(deleteCategoriasProducto.fulfilled, (state, action) => {
      state.nombre = null;
    });
    builder.addCase(putCategoriasProducto.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
      state.loading = false;
      state.error = false;
      state.success = true;
    });
    builder.addCase(putCategoriasProducto.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(putCategoriasProducto.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
  },
});

export default showCategoriasProductoSlice;

export const showCategoriasProductoActions = showCategoriasProductoSlice.actions;
