import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL, apiURL_Docs, apiURL_Nest } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import { getRequestWparamsThunk } from '../Util/get-request-thunk';
import { asignPutRequestThunk } from '../lib/asing-put-request';
import * as util from '../lib/edit';

const apiHOST = apiURL();
const apiHOST_nest = apiURL_Nest();
const apiHOSTDocs = apiURL_Docs();
const filterSeleccion = (valuesToFilter, selectedValueId) => {
  const filterdValue = valuesToFilter.filter(
    (item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const initialState = {
  editsNotSaved: null,
  occ: {},
  cliente: {},
  divisa: {},
  inventario: {},
  pedidoPor: '',
  productToAsign: [],
  productosOrdenCompra: [],
  vigente: '',
  subtotal: '',
  error: false,
  loading: false,
  success: null,
};


export const  downloadDocument = createAsyncThunk('producto/insertPagoCliente', async (payload) => {
  console.log("payload8")

  console.log('payload')
  console.log(payload)
  const { request} = payload;

  try {
    console.log('Reques INSERT PAGO PROVEEDORt---->');
    console.log(request);
    const updatedRequest = { ...request };
    const document=await postDocument({requestObj:payload})
    console.log(document)

    // const response = await axios.post(`${apiHOST}pagos-clientes`, updatedRequest);

    return;
  } catch (err) {
    // return err;
  }
});
export const downloadDocument1 = async (payload) =>{
  console.log('payload')
  console.log(payload)


  try {
    console.log('Reques downloaddocument');
    const document=await postDocument({requestObj:payload})
     console.log(document)
    // console.log(request);
    // const updatedRequest = { ...request };
    // updatedRequest.fecha = new Date(request.fecha);
    // console.log(updatedRequest);
    //
    // const response = await axios.post(`${apiHOST}pagos-clientes`, updatedRequest);

    return;
  } catch (err) {
    // return err;
  }
}
export const postDocument = util.postDocumentThunk({
  name: 'ordenCompraCliente/document',
  postRoute: `${apiHOSTDocs}documents`,
});

export const postEmail = util.postDocumentThunk({
  name: 'ordenCompraClente/notification/email',
  postRoute: `${apiHOSTDocs}notificaciones`,
});



export const getOrdenCompraCliente = getRequestWparamsThunk(
  'ordenCompraCliente/getOrdenCompraCliente',
  `${apiHOST_nest}ordenes-compra/cliente`,
);

export const putOrdenCompraCliente = asignPutRequestThunk(
  'ordenCompraCliente/putOrdenCompraCliente',
  apiHOST,
);

export const deleteOrdenCompraCliente = util.deleteRequestFromComponentThunk(
  'ordenCompraCliente/deleteOrdenCompraCliente',
  apiHOST,
  'ordenes-de-compra-cliente',
);

export const getRelation = util.getRequestRelationThunk(
  'ordenCompraCliente/getRelation', apiHOST);

//TODO refactor

export const postRelationProducto = util.postRelationProductoThunk({
  name: 'ordenCompraCliente/postRelationProducto',
  postRoute: `${apiHOST}productos-occ`,
  getRoute: `${apiHOST}views/productos-occ`,
  getParam: 'requestObj',
  getParamKey: 'numero_occ',
});

export const deleteRelationProducto = util.deleteRelationProductoThunk({
  name: 'ordenCompraCliente/deleteRelationProducto',
  route: `${apiHOST}productos-occ`,
  method: 'DELETE',
});

export const cerrarOrdenCompraCliente = util.putRequestThunkPrimaryKey(
  'ordenCompraCliente/cerrarOrdenCompraCliente',
  `${apiHOST}views/ordenes-compra-cliente/cerrar`,
);

export const setProductoOccVigente = createAsyncThunk(
  'ordenCompraCliente/deleteOrdenCompraCliente',
  async (payload) => {
    console.log('delete orden de compra');
    const { requestUrl, sliceState, history } = payload;
    try {
      console.log(`${apiHOST}${requestUrl}`);
      const response = await axios.put(`${apiHOST}${requestUrl}`, {});
      // history.push('/ordenesCompraCliente');
      // return { data: response.data, sliceState };
      console.log('response!!!!!!');
      console.log(response);
    } catch (err) {
      return err;
    }
  },
);

const editOrdenCompraClienteSlice = createSlice({
  name: 'editOrdenCompraCliente',
  initialState: initialState,
  reducers: {
    change (state, action) {
      // console.log('Reducer----->');
      // console.log(action.payload.field);
      // console.log(action.payload.value);
      switch (action.payload.field) {
        case 'fechaEntrega':
          state.occ.fechaEntrega = action.payload.value;
          break;
        default:
      }
    },
    select (state, action) {
      console.log(' Edit actions  SELECT action.payload.field--------->>>>');
      console.log(action.payload.field);
      console.log(action.payload.selectedValue);
      console.log(action.payload.values);
      state[action.payload.field] = filterSeleccion(
        action.payload.values,
        action.payload.selectedValue,
      );
      state.editsNotSaved = true;
    },

    asign (state, action) {
      state[action.payload.reduxStateTree] = filterSeleccion(
        action.payload.valuesToFilter,
        action.payload.selectedValue,
      );
    },
    cancelAsign (state, action) {
      state[action.payload.stateTree] = null;
    },
    productToRemoveAsign (state, action) {
      state[action.payload.stateTree] = null;
    },
    resetError (state, action) {
      console.log('RESSEERT');
      state.error = null;
    },
    resetProductToAssign (state, action) {
      state.productToAsign = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrdenCompraCliente.fulfilled, (state, action) => {
      const {
        occ,
        document,
        productosOcc,
        subtotal,
        iva,
        total,
        totalTexto,
      } = action.payload;

      state.subtotal = subtotal;
      state.subtotalOk=subtotal;
      state.total = total;
      //TODO this is a patch.. Logic should be updated on Backend
      state.iva = occ.iva ? iva : null;
      state.totalTexto = totalTexto;
      state.occ = occ;
      state.document=document
      state.productosOcc = productosOcc;
      state.cliente = occ?.cliente;
      state.divisa = occ?.divisa;
      state.inventario = occ?.inventario;
      state.error = false;
    });

    builder.addCase(getOrdenCompraCliente.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    builder.addCase(deleteOrdenCompraCliente.fulfilled, (state, action) => {
      state.ordenCompraCliente = {
        id: null,
        rfc: null,
        razonSocial: null,
        direccionId: null,
      };

      state.direccionFiscal = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.direccionEntrega = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.relationsProductos = [];
      state.asignedProducts = [];
      state.productToAsign = null;
      state.productToRemoveAsign = null;
    });
    builder.addCase(putOrdenCompraCliente.fulfilled, (state, action) => {
      console.log('Console.log Action sss');
      console.log(action);
      state[action.payload.reduxStateTree] = action.payload.data;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(putOrdenCompraCliente.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });
    builder.addCase(putOrdenCompraCliente.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(postRelationProducto.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = null;
      console.log('action.payload.data-------?');
      console.log(action.payload.data);

      console.log('action.payload.data-------?');
      console.log(action.payload.asignedListString);

      state[action?.payload?.asignedListString] = [];
      if (Array.isArray(action.payload.data)) {
        action.payload.data.map(
          (i) => state[action?.payload?.asignedListString]?.push(i));
      }
      // else{

      //   state[action?.payload?.asignedListString]=action.payload.data

      // }

      //Set State to Nullstate the product that has been posted.
    });
    builder.addCase(deleteRelationProducto.fulfilled, (state, action) => {
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.id !== action.payload.data.id,
      );
      state[action.payload.asignedListString] = filterdValue;
    });
    builder.addCase(deleteRelationProducto.rejected, (state, action) => {
      console.log('action.payload');
      console.log(action.payload);
      state.error = action.payload.message;
    });
    builder.addCase(getValuesToAsign.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });

    builder.addCase(cerrarOrdenCompraCliente.fulfilled, (state, action) => {
      state.occ.vigente = action.payload.data;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(cerrarOrdenCompraCliente.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    builder.addCase(cerrarOrdenCompraCliente.pending, (state, action) => {
      state.error = false;
      state.loading = true;
    });
  },
});

export default editOrdenCompraClienteSlice;

export const editOrdenCompraClienteActions = editOrdenCompraClienteSlice.actions;
