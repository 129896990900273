import React from 'react';
import {
  getItemsLegacy,
  handleFilter,
  fetchAllActions as sliceActions, getItems,
} from '../../store/fetch-all-slice';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';


const filterCondition = (state, value) => {
  let condition = (
    String(state?.razonSocial).toUpperCase().includes(value) ||
    String(state?.simbolo).toUpperCase().includes(value)||
    String(state?.salidaIc).toUpperCase().includes(value)
  );
  return condition;
};
const SalidasIC = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    handleFilter,
    getItemsLegacy,
    responseDataKey:"raw",
    routePath:'views/salidas-inventario-clientes',
    filterCondition,
    orderByOptions: [
      '',
      'salidaIc',
      'simbolo',
      'razonSocial',
    ],
  });
};

export default SalidasIC;
