const setDay = (date) => date.getUTCDate();
const setMonth = (date) => date.getUTCMonth();
const setYear = (date) => date.getUTCFullYear();
const setHours = (date) => date.getHours();
const setMinutes = (date) => date.getMinutes();
const setSeconds = (date) => date.getSeconds();

const nomalize2Digits = (number) => {
  return number < 10 ? `0${number}` : number;
};

export const timeStampToDDMMYY = (timestamp) => {
  //2022-12-16T15:40:38.085Z
  const date = timestamp?.split('T')[0];

  if (!date) return timestamp;

  const [year, month, day] = date?.split('-');
  return `${day}-${month}-${year}`;
};

export const createDateFromYYYYMMDD_String = (string) => {
  //format YYYY-MM-DD
  const date = String(string).split('-');
  const d = new Date();
  d.setFullYear(date[0]);
  d.setMonth(+date[1] - 1);
  d.setDate(date[2]);
  return d;
};

export const addDays = (date, days) => {
  var result = new Date(date);

  if (!days) return result;

  result.setDate(result.getDate() + days);
  return result;
};
export const setDateStringYYYYMMDD = (date) => {
  const dateObj = new Date(date);
  return String(`${setYear(dateObj)}-${setMonth(dateObj)}-${setDay(dateObj)}`);
};

export const setDateStringDDMMYYYY = (date) => {
  const dateObj = new Date(date);

  return String(
    `${nomalize2Digits(setDay(dateObj))}-${nomalize2Digits(
      setMonth(dateObj) + 1)}-${setYear(dateObj)}`,
  );
};
export const setDateStringDDMMYYYY_MONTH = (date) => {
  const dateObj = new Date(date);

  return String(
    `${nomalize2Digits(setDay(dateObj))}-${nomalize2Digits(
      setMonth(dateObj) + 1)}-${setYear(
      dateObj,
    )}`,
  );
};

export const setTime = (date) => {
  const dateObj = new Date(date);

  return String(
    `${nomalize2Digits(setHours(dateObj))}:${nomalize2Digits(
      setMinutes(dateObj),
    )}:${nomalize2Digits(setSeconds(dateObj))}`,
  );
};

export const yearMonthDayFromDateInput = (dateInput) => {
  const [year, month, day] = dateInput.split('-');
  return {
    year,
    month: +month - 1,
    day,
  };
};
export const yearMonthDayFromLocaleString = (localeString) => {
  const [month, day, year] = localeString.split('/');
  return {
    year,
    month: +month - 1,
    day,
  };
};

export const strinDateSpanish = (date) => {
  if (!date) return;
  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleDateString('es-ES', dateOptions);
};

export const dateWithTimeZone = (
  timeZone, year, month, day, hour = 8, minute = 0, second = 0) => {
  let date = new Date(Date.UTC(year, month, day, hour, minute, second));

  let utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  let tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
  let offset = utcDate.getTime() - tzDate.getTime();

  date.setTime(date.getTime() + offset);

  return date;
};

export const formatDateFromDateInput = (dateInput) => {
  const { year, month, day } = yearMonthDayFromDateInput(dateInput);
  return strinDateSpanish(
    dateWithTimeZone('America/Los_Angeles', year, month, day));
};
export const formatDateFromtoLocaleDateString = (localeDateString) => {
  const { year, month, day } = yearMonthDayFromLocaleString(localeDateString);
  return strinDateSpanish(
    dateWithTimeZone('America/Los_Angeles', year, month, day));
};
