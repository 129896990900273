

import { EstadosDeCuenta } from '../EstadosDeCuenta/EstadosDeCuenta';
import React from 'react';
import EdoCuentaCliente from '../EdoCuentaCliente/EdoCuentaCliente';
import EdoCuentaProveedor from '../EdoCuentaProveedor/EdoCuentaProveedor';
export const estadosDeCuentaRoutes = [
  {
    name: 'EdoCuentaCliente',
    href: '/estado-de-cuenta/clientes-mxn/:id',
    component: <EdoCuentaCliente />,
    exact: true,
  },
  {
    name: 'EdoCuentaCliente',
    href: '/estado-de-cuenta/clientes-usd/:id',
    component: <EdoCuentaCliente />,
    exact: true,
  },
  {
    name: 'EdoCuentaCliente',
    href: '/estado-de-cuenta/clientes',
    component: <EstadosDeCuenta
      fetchModel={'clientes'}
      linkTo={'/estado-de-cuenta/clientes'}
    />,
    exact: true,
  },
  {
    name: 'EdoCuentaProveedor',
    href: '/estado-de-cuenta/proveedores-mxn/:id',
    component: <EdoCuentaProveedor />,
    exact: true,
  },
  {
    name: 'EdoCuentaProveedor',
    href: '/estado-de-cuenta/proveedores-usd/:id',
    component: <EdoCuentaProveedor />,
    exact: true,
  },
  {
    name: 'EdoCuentaProveedor',
    href: '/estado-de-cuenta/proveedores',
    component: <EstadosDeCuenta
      fetchModel={'proveedores'}
      linkTo={'/estado-de-cuenta/proveedores'}
    />,
    exact: true,
  },
];

