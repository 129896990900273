import {
  LifeCycleFeedback,
  LifeCycleFeedbackV2,
} from '../../LifeCycleFeedback/LifeCycleFeedback';
import FlexContainer from '../../TailwindUI/FlexContainer';
import { TablaBalance } from './TablaBalance';
import React from 'react';

export const EstadosDeCuentaUi=({
  sliceState,
  linkTo,
  balances,
  totales,
  displayAllComponent,
})=>(
  <>
    <div className='flex-1 flex flex-col overflow-hidden'>
      {sliceState.loading||sliceState.error ? (
        <LifeCycleFeedbackV2 sliceState={sliceState} />
      ) : (
        <FlexContainer className='flex-1 items-stretch  overflow-y-auto '>

          <main className='flex-1 overflow-y-auto  '>
              <div className="bg-green-500 max-w-full py-2"></div>
            <div className='ml-10'>

              {displayAllComponent}
            </div>
            <FlexContainer className='flex-col   bg-gray-100 py-2'>
              {/* {JSON.stringify(sliceState)} */}
              <div className='lg:grid grid-cols-2 md:flex'>

                <TablaBalance linkTo={linkTo+"-mxn"} label={"MXN"} balances={balances.mxn} totales={totales.mxn} />

                <TablaBalance linkTo={linkTo+"-usd"}  label={"USD"} balances={balances.usd} totales={totales.usd} />

              </div>
            </FlexContainer>
          </main>
        </FlexContainer>
      )}
    </div>
  </>
);