import React from 'react';
import {
  getItems,
  handleFilter,
  fetchAllActions as sliceActions,
} from '../../store/fetch-all-slice';
import TableRow from '../TailwindUI/TableRow';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { sortObjKeysFromArray } from '../../Util/sort-utils';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const sortingArray = ['numeroOcp', 'razonSocial', 'rfc'];

const customDisplayMapFn = (producto, idx) => {

  let newObj = { ...producto };

  const mappedObj = sortObjKeysFromArray(newObj, sortingArray);

  return (
    <React.Fragment  key={JSON.stringify(producto)+idx}>
      <TableRow
        key={producto.id}
        className={
          producto.entregado === true ? 'bg-green-50' : idx % 2 === 0 ? 'bg-white' : 'bg-gray-100'
        }
      >
        <TableBodyCellAuto list={mappedObj} />
      </TableRow>
    </React.Fragment>
  );
};

const filterCondition = (state, value) => {
  console.log(state)
  let condition = (
    String(state?.simbolo).toUpperCase().includes(value) ||
    String(state?.razonSocial).toUpperCase().includes(value)||
    String(state?.numeroOcp).toUpperCase().includes(value)
  );
  return condition;
};

const tableHeaderMapFn = (producto, idx) => {
  console.log('table header?????');
  let newObj = { ...producto };

  return sortObjKeysFromArray(newObj, sortingArray);
};

const OrdenesCompraProveedor = () => {
  return DefaultFetchAllList({
    routePath:'ordenes-compra/proveedor',
    sliceState: 'fetchAll',
    sliceActions,
    getItems,
    handleFilter,
    customDisplayMapFn,
    tableHeaderMapFn,
    filterCondition,
    defaultFilterCondition: {
      key: 'entregado',
      value: false||'NO',
    },
    orderByOptions: [
      '',
      'SIMBOLO',
    ],
  });
};

export default OrdenesCompraProveedor;
