import PagosProveedores from '../PagosProveedor/PagosProveedores';
import PagosClientes from '../PagosCliente/PagosClientes';

export const pagosCuentasRoutes = [
  {
    name: 'Edit CPPs',
    href: '/pagos-proveedores',
    component: <PagosProveedores />,
    exact: true,
  },
  {
    name: 'Edit CPPs',
    href: '/pagos-proveedores/:id',
    component: <PagosProveedores />,
    exact: true,
  },

  {
    name: 'Edit CPPs',
    href: '/pagos-clientes',
    component: <PagosClientes />,
    exact: true,
  },

  {
    name: 'Edit CPPs',
    href: '/pagos-clientes/:id',
    component: <PagosClientes />,
    exact: true,
  },


];
