import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '../TailwindUI/IconButton';
import { CardButton, CardContainer, CardTitle } from '../SharedUi/UpdateCardUI';
import RelationInsertValue from '../SharedUi/RelationInsertValue';
import { XCircleIcon } from '@heroicons/react/outline';
import { prettifyNumber } from '../../Util/prettifyNumber';
import { useHistory, useLocation } from 'react-router';
import { InputCornerHint, NumberInputWithLimits } from '../SharedUi/Inputs';
import { AlertWithDescription } from '../SharedUi/Alerts';
import Table from '../TailwindUI/Table';
import TableHeader from '../TailwindUI/TableHeader';
import TableBody from '../TailwindUI/TableBody';
import TableRow from '../TailwindUI/TableRow';
import TableRowHeader from '../TailwindUI/TableRowHeader';
import TableHeaderCell from '../TailwindUI/TableHeaderCell';
import TableBodyCell from '../TailwindUI/TableBodyCell';

const AsignProductoEtoCPC = (props) => {
  const { iva } = props;
  const history = useHistory();
  //Internal State
  const [editMode, setEditMode] = useState(false);
  const [errorCantidad, setErrorCantidad] = useState(null);

  const [remision, setRemision] = useState('');

  const [cantidad, setCantidad] = useState(null);

  const [limiteCantidadSuperior, setLimiteCantidadSuperior] = useState(null);
  const [limiteCantidadInferior, setLimiteCantidadInferior] = useState(null);

  const [productoPorDarSalida, setProductoPorDarSalida] = useState(null);
  const [sumaEntradas, setSumaEntradas] = useState(null);
  const [unidad, setUnidad] = useState(null);

  //Redux State

  //Create Relation
  const createRelation = useSelector((state) => state.createRelation);
  const loading = useSelector((state) => state.createRelation.loading);

  //Function Handlers

  const handleCancel = () => {
    props.handleCancel({
      stateTree: props.asignTree,
    });
    setEditMode(false);
  };

  const handlePostRelation = (producto) => {
    console.log('producto----->>>>>>>');
    console.log(productoPorDarSalida);
    let selectedValue = {
      cuenta_por_cobrar_id: props.cuentaPorCobrar?.id,
      numero_occ: productoPorDarSalida.numeroOcc,
      remision_proveedor: productoPorDarSalida.salidaId,
      factura: props.factura,
      producto_id: productoPorDarSalida.productoId,
      cantidad,
      precio_id: productoPorDarSalida.precioVentaId,
      producto_salida_id: productoPorDarSalida.id,
    };

    console.log('producto ????');
    console.log(selectedValue);

    props.handlePostRelation({
      selectedValue,
      reduxStateTree: props.asignTree,
      asignedListString: props.asignedListString,
    });
    setEditMode(false);
  };
  const handleCerrar = (e) => {
    // props.handleErrase({
    //   valueToUnasign: e,
    //   reduxStateTree: props.asignTree,
    //   asignedListString: props.asignedListString,
    //   asignedListSelector: props.asignedListSelector,
    // });
  };

  //<-----------------SELECT OPTIONS----------------->
  const dispatch = useDispatch();

  const handleCantidad = (e) => {
    setCantidad(e.target.value);
    setErrorCantidad(null);

    if (e.target.value < limiteCantidadInferior) {
      setErrorCantidad('Estas fuera del 10% permitido');
    }
    if (e.target.value > limiteCantidadSuperior) {
      setErrorCantidad('Estas fuera del 10% permitido');
    }
  };
  const handleCrearEntrada = (eduxStateTree, value, selectValues) => {
    console.log('handle divisa---> Events');
    console.log('eduxStateTree');
    console.log(eduxStateTree);

    // props.handleSelect({
    //   reduxStateTree: props.asignTree,
    //   valuesToFilter: createRelation.valuesToAsign,
    //   selectedValue: productoPorDarSalida,
    // });
  };

  const handleAddToCpp = (producto) => {
    console.log('producto');
    console.log(producto);
    console.log(producto.cantidad);
    setCantidad(producto.cantidad);
    setProductoPorDarSalida(producto);

    setEditMode(true);
  };

  const handleSetlimitAsCantidad = () => {
    setCantidad(limiteCantidadSuperior);
  };

  return (
    <React.Fragment>
      {loading && <h1>Loading</h1>}
      {!editMode && (
        <React.Fragment>
          {props.asignedListSelector
            ? props.asignedListSelector.length > 0 && (
                <div
                // style={{
                //   position: 'relative',
                //   width: '100%',
                //   height: '100%',
                // }}
                >
                  <div
                    style={
                      {
                        // position: 'absolute',
                        // minWidth: '70vw',
                        // height: '100vh',
                        // top: 0,
                        // left: 'auto',
                        // right: 'auto',
                        // bottom: 0,
                        // background: 'white',
                        // padding: ' 40% 1%',
                        // overflow: 'auto',
                      }
                    }
                  >
                    <div style={{ width: 'max-content', marginRight: 'auto' }}>
                      <Table>
                        <TableHeader>
                          <TableRowHeader className="bg-gray-300 ">
                            <TableHeaderCell textColor="text-gray-700">FACTURA</TableHeaderCell>
                            <TableHeaderCell textColor="text-gray-700">REMISION</TableHeaderCell>
                            <TableHeaderCell textColor="text-gray-700">OCC</TableHeaderCell>
                            <TableHeaderCell textColor="text-gray-700">CANTIDAD</TableHeaderCell>
                            <TableHeaderCell textColor="text-gray-700">Símbolo</TableHeaderCell>

                            <TableHeaderCell textColor="text-gray-700">P.U</TableHeaderCell>
                            <TableHeaderCell textColor="text-gray-700">AÑADIR</TableHeaderCell>
                            {/* <TableHeaderCell textColor="text-gray-700">
                        Cerrar
                      </TableHeaderCell> */}
                          </TableRowHeader>
                        </TableHeader>
                        <TableBody>
                          {props.asignedListSelector
                            ? props.asignedListSelector.map((producto, personIdx) => (
                                <React.Fragment>
                                  <TableRow
                                    key={producto.id}
                                    className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                  >
                                    {/* {JSON.stringify(producto)} */}
                                    <TableBodyCell textColor="text-gray-500">
                                      {props.factura}
                                    </TableBodyCell>
                                    <TableBodyCell textColor="text-gray-500">
                                      {producto.notaDeRemision}
                                    </TableBodyCell>
                                    <TableBodyCell textColor="text-gray-500">
                                      {producto.numeroOcc}
                                    </TableBodyCell>
                                    <TableBodyCell textColor="text-gray-500">
                                      {producto.cantidad}
                                    </TableBodyCell>

                                    <TableBodyCell textColor="text-gray-500">
                                      {producto.simbolo}
                                    </TableBodyCell>

                                    <TableBodyCell textColor="text-gray-500">
                                      {producto.precioUnitario}
                                    </TableBodyCell>

                                    <TableBodyCell textColor="text-gray-500">
                                      <IconButton
                                        onClick={() => handleAddToCpp(producto)}
                                        color="orange"
                                        icon="+"
                                        iconHeight={3}
                                        iconWidth={3}
                                      />
                                    </TableBodyCell>
                                    {/* <TableBodyCell textColor="text-gray-500">
                            <IconButton
                              onClick={() => handleCerrar(producto)}
                              color="red"
                              icon="x"
                              iconHeight={3}
                              iconWidth={3}
                            />
                          </TableBodyCell> */}
                                  </TableRow>
                                </React.Fragment>
                              ))
                            : ''}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </div>
              )
            : ''}
        </React.Fragment>
      )}
      {!loading && (
        <React.Fragment>
          {editMode && (
            <div
            // style={{
            //   position: 'relative',
            //   width: '100%',
            //   height: '100%',
            // }}
            >
              <div
                className="grid-cols-6"
                // style={{
                //   position: 'absolute',
                //   minWidth: '50vw',
                //   height: '100vh',
                //   top: 0,
                //   left: 'auto',
                //   right: 'auto',
                //   bottom: 0,
                //   background: 'white',
                //   padding: ' 40% 1%',
                //   overflow: 'auto',
                // }}
              >
                <InputCornerHint
                  name="simboloProducto"
                  label={'Símbolo'}
                  value={productoPorDarSalida?.simbolo?.toUpperCase()}
                  disabled
                  borderColor={'border-gray-400'}
                />
                <div className={'my-2'} />

                <InputCornerHint
                  textColor="text-gray-400"
                  name="unidad"
                  label={'Cantidad'}
                  value={cantidad}
                  onChange={handleCantidad}
                  disabled
                  type="Number"
                />
                <div className={'my-4'} />

                <div className={'my-2'} />
                <InputCornerHint
                  textColor="text-gray-400"
                  name="unidad"
                  label={'Unidad'}
                  value={productoPorDarSalida?.unidad}
                  disabled
                />

                <div className={'my-2'} />

                {errorCantidad && (
                  <AlertWithDescription
                    title={errorCantidad}
                    icon={<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                  />
                )}

                {!errorCantidad && (
                  <CardButton onClick={handlePostRelation} bgColor="orange">
                    Añadir a Cuenta Por Cobrar
                  </CardButton>
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default AsignProductoEtoCPC;
