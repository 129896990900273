import { prettyFinancial } from '../../Util/prettyFinancial';
import { prettifyNumber } from '../../Util/prettifyNumber';

export const OCDocumentFactory = (data) => {

  const totalesFactory = ({
    importe, iva, total, totalEnLetra,
  }) => {
    return {
      totales: {
        importe, iva, total, totalEnLetra,
      },
    };
  };

  const totales = totalesFactory({
    importe: prettyFinancial(data.subtotal),
    iva: prettyFinancial(data.iva),
    total: prettyFinancial(data.total),
    totalEnLetra: data.totalTexto,
  });

  const resolveDetalles = (data) => {
    const oc = data.ocp || data.occ;
    const divisa = data.divisa.nombre;
    return {
      fechaDeCreacion: oc.createdAt.split('T')[0],
      fechaDeEntrega: oc.fechaEntrega,
      divisa: divisa,
    };
  };
  const resolveProductos = (data) => {
    console.log('resolveProductjosData->', JSON.stringify(data));
    const productos = data.productosOrdenCompra;
    const productosB = data.productosOcc || data.productosOcp;
    return productos.map((item, index) => {
      console.log('item->>', item);
      console.log('producto');
      console.log(item?.pUnitario);
      console.log(item?.precioVenta);

      return {
        cantidad: prettifyNumber(item.cantidad),
        unidad: item.unidad,
        importe: prettyFinancial(item.importe || productosB[index].importe),
        descripcion: item.descripcion || item.simbolo,
        pUnitario: prettyFinancial(
          item.pUnitario || item.costoAdquisicion || item.precioVenta),
      };
    });
  };

  const resolveOC = () => {
    const PROVEEDOR = 'PROVEEDOR';
    const CLIENTE = 'CLIENTE';
    const OCP = 'OCP';
    const OCC = 'OCC';
    const occ = data?.occ;
    const ocp = data?.ocp;
    const id = data?.ocp?.numeroOcp || data?.occ?.numeroOcc;
    let entidadComercial;
    let type;

    if (occ) {
      type = OCC;
      entidadComercial = CLIENTE;
    } else if (ocp) {
      type = OCP;
      entidadComercial = PROVEEDOR;
    } else {
      throw Error;
    }

    const documentName = `${type}_${id}`;
    return {
      type,
      entidadComercial,
      id,
      documentName,

    };
  };

  const buildDocTitle = (data) => {
    const ORDEN_DE_COMPRA = 'ORDEN DE COMPRA';
    const { id, type, entidadComercial } = resolveOC(data);
    const titulo = `${ORDEN_DE_COMPRA} ${entidadComercial}`;
    const dividerTitle = `${type}_${id}`;
    return {
      id, titulo, dividerTitle,
    };
  };

  const resolveEmisor = (data) => {
    const inventario = data.inventario.nombre;
    const datosMelyMx = {
      name: `MELY PACKAGING S.A. de C.V.`,
      taxId: 'TME140908T79',
      correo: 'facturacion@grupomely.com',
      address1: 'PLAZA DE LAS FUENTES No 6273 INT. 49',
      address2: 'COL. VILLAS DE IRAPUATO',
      city: 'IRAPUATO',
      state: 'GUANAJUATO',
      zipCode: '36670',
      country: 'MÉXICO',
      webPage: 'www.melypackaging.com',
      email: 'facturacion@grupomely.com',
      telefono: `01 462 173 29 66`,
    };
    const datosMelyUsa = {

      name: `MELY PACKAGING L.L.C`,
      taxId: 'ID 372029667',
      correo: 'facturacion@grupomely.com',
      webPage: 'www.melypackaging.com',
      address1: '8122 DATAPOINT',
      address2: 'SUITE 250',
      city: 'SAN ANTONIO',
      state: 'TX',
      zipCode: '78229',
      country: 'USA',
      telefono: `01 462 173 29 66`,
    };
    let emisor;
    if (inventario === 'MELYUSA') {
      emisor = datosMelyUsa;
    } else if (inventario === 'MELYMX') {
      emisor = datosMelyMx;
    } else {
      throw Error;
    }

    return {
      emisor,
    };

  };
  const certificacion = {
    codigo: 'FOINO38',
    version: '00',
    fechaEmision: '01-NOV-23',
    fechaRevision: '01-NOV-23',
  };
  const resolveReceptor = (data) => {
    const info = data?.proveedor || data?.cliente;
    const direccion = info.direccion || info.direccionFiscal;
    console.log(info);

    const { entidadComercial, type } = resolveOC(data);
    return {
      id: '',
      type: entidadComercial,
      name: info.razonSocial,
      taxId: info.rfc,
      address1: direccion.calle,
      address2: direccion.colonia,
      city: direccion.ciudad,
      state: direccion.estado,
      zipCode: direccion.cp,
      country: direccion.pais,
      email: info?.email,
      webPage: info?.webPage,
    };
  };

  const { type, documentName } = resolveOC(data);

  const obj = {};
  obj.type = type;
  obj.name = documentName;
  obj[type] = {
    ...buildDocTitle(data),
    ...resolveEmisor(data),
    detalles: resolveDetalles(data),
    productos: resolveProductos(data),
    ...totales,
    certificacion,
    receptor: resolveReceptor(data),
  };

  return obj;

};