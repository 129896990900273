import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import * as util from '../lib/edit';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL();
const filterSeleccion = (valuesToFilter, selectedValueId) => {
  const filterdValue = valuesToFilter.filter((item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const initialState = {
  salida: null,
  producto: null,
  cliente: null,
  productosOrdenCompra: null,
  productosSalida: [],
  productToAsign: null,
  productToSearch: null,
  seleccionInventario: [],
  existenciasInventario: null,
};

//Fetch Salida to Edit

export const getSalida = util.getDefaultViewRequestThunk(
  'editSalida/getSalida',
  `${apiHOST}views/salidas`,
  'formated',
);

export const putSalida = createAsyncThunk('editSalida/putSalida', async (payload) => {
  const { requestObj, requestUrl, reduxStateTree } = payload;
  try {
    const response = await axios.put(
      `${apiHOST}${requestUrl}/${reduxStateTree.id}`,
      requestObj,
      {},
    );
    return { data: response.data, reduxStateTree };
  } catch (err) {
    return err;
  }
});

export const deleteSalida = util.deleteRequestFromComponentThunk(
  'editSalida/deleteSalida',
  apiHOST,
  'salidas',
);

export const getRelation = util.getRequestRelationThunk('editSalida/getRelation', apiHOST);

export const postRelationProducto = util.postRelationProductoThunk({
  name: 'editSalida/postRelationProducto',
  postRoute: `${apiHOST}views/productos-salida/custom`,
  getRoute: `${apiHOST}views/productos-salida`,
  getParam: 'response',
  getParamKey: 'salidaId',
});
export const cerrarSalida = createAsyncThunk(
  'editSalida/cerrarSalida',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiHOST}views/salidas/cerrar/${payload.salida.id}`,
        payload,
        {
          headers: {
            ...authorizationHeader()          },
        },
      );
      console.log('Cerrar Salida????');
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const moverAInventarioClientes = createAsyncThunk(
  'editSalida/moverAInventarioClientes',
  async (payload) => {
    try {
      const response = await axios.post(
        `${apiHOST}views/productos-entrada-inventario-clientes/custom`,
        payload,
        {},
      );
      return response.data;
    } catch (err) {
      return err;
    }
  },
);

export const deleteRelationProducto = util.deleteRelationProductoThunk({
  name: 'editSalida/deleteRelationProducto',
  route: `${apiHOST}views/productos-salida/delete`,
  method: 'POST_V2',
});

//Cerrar Producto_OCC
export const cerrarProductoOcc = util.putRequestThunkPrimaryKey(
  'ordenCompraCliente/cerrarProductoOcp',
  `${apiHOST}views/productos-occ/cerrar`,
);

const editSalidaSlice = createSlice({
  name: 'editSalida',
  initialState: initialState,
  reducers: {
    change(state, action) {
      // console.log('Reducer----->');
      // console.log(action.payload.field);
      // console.log(action.payload.value);
      switch (action.payload.field) {
        case 'cantidadLetra':
          break;
        case 'fechaEntrega':
          break;
        default:
      }
    },
    select(state, action) {
      console.log(' Edit actions  SELECT action.payload.field--------->>>>');
      console.log(action.payload.field);
      console.log(action.payload.selectedValue);
      console.log(action.payload.values);
      state[action.payload.field] = filterSeleccion(
        action.payload.values,
        action.payload.selectedValue,
      );
      state.editsNotSaved = true;
    },

    asign(state, action) {
      console.log('action.payload');
      console.log(action.payload);
      state[action.payload.reduxStateTree] = filterSeleccion(
        action.payload.valuesToFilter,
        action.payload.selectedValue,
      );
    },
    cancelAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    productToRemoveAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    resetProductosOrdenCompra(state, action) {
      state.productosOrdenCompra = null;
    },
    seleccionInventario(state, action) {
      state.seleccionInventario = action.payload;
    },
    setProductToSearch(state, action) {
      state.productToSearch = action.payload;
    },
    resetProductToSearch(state, action) {
      state.productToSearch = null;
    },
    resetSeleccionInventario(state, action) {
      state.seleccionInventario = null;
    },
    resetExistenciasInventario(state, action) {
      state.existenciasInventario = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalida.fulfilled, (state, action) => {
      const { salida, producto, cliente, enCalidadDe } = action?.payload;
      state.salida = salida;
      state.enCalidadDe = enCalidadDe;
      state.producto = producto;
      state.cliente = cliente;
      state.error = false;
    });

    builder.addCase(getSalida.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    builder.addCase(deleteSalida.fulfilled, (state, action) => {
      state.editSalida = {
        id: null,
        rfc: null,
        razonSocial: null,
        direccionId: null,
      };

      state.direccionFiscal = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.direccionEntrega = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.relationsProductos = [];
      state.asignedProducts = [];
      state.productToAsign = null;
      state.productToRemoveAsign = null;
    });
    builder.addCase(putSalida.fulfilled, (state, action) => {
      console.log('Console.log Action sss');
      console.log(action);
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });

    builder.addCase(cerrarSalida.fulfilled, (state, action) => {
      state.salida.vigente = action.payload?.vigente;
    });

    builder.addCase(postRelationProducto.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = null;
      console.log('action.payload.data-------?');
      console.log(action.payload.data);

      console.log('action.payload.data-------?');
      console.log(action.payload.asignedListString);

      state[action?.payload?.asignedListString] = [];
      if (Array.isArray(action.payload.data)) {
        action.payload.data.map((i) => state[action?.payload?.asignedListString]?.push(i));
      }
      // else{

      //   state[action?.payload?.asignedListString]=action.payload.data

      // }

      //Set State to Nullstate the product that has been posted.
    });
    builder.addCase(deleteRelationProducto.fulfilled, (state, action) => {
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.id !== action.payload.data.id,
      );
      state[action.payload.asignedListString] = filterdValue;
    });
    builder.addCase(getValuesToAsign.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });
    builder.addCase(moverAInventarioClientes.fulfilled, (state, action) => {
      state.salida.vigente = false;
    });
  },
});

export default editSalidaSlice;

export const editSalidaActions = editSalidaSlice.actions;
