import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { getValuesToAsign } from './createRelation-slice';
import * as util from '../lib/edit';
import { authorizationHeader } from '../Util/authorizationHeader';

const apiHOST = apiURL();

const filterSeleccion = (valuesToFilter, selectedValueId) => {
  const filterdValue = valuesToFilter.filter((item) => Number(item.id) === Number(selectedValueId));
  return filterdValue[0];
};

const initialState = {
  entrada: null,
  producto: null,
  proveedor: null,
  productosOrdenCompra: null,
  productosEntrada: [],
  productToAsign: null,
  productToSearch: null,
};

//Fetch Entrada to Edit

export const getEntrada = util.getDefaultViewRequestThunk(
  'editEntrada/getEntrada',
  `${apiHOST}views/entradas`,
  'formated',
);

//Put Edited Entrada
export const putEntrada = createAsyncThunk('editEntrada/putEntrada', async (payload) => {
  const { requestObj, requestUrl, reduxStateTree } = payload;
  try {
    const response = await axios.put(
      `${apiHOST}${requestUrl}/${reduxStateTree.id}`,
      requestObj,
      {},
    );
    return { data: response.data, reduxStateTree };
  } catch (err) {
    return err;
  }
});
//Delete  Entrada

export const deleteEntrada = util.deleteRequestFromComponentThunk(
  'editEntrada/deleteEntrada',
  apiHOST,
  'entradas',
);

//Relations

export const getRelation = util.getRequestRelationThunk('editEntrada/getRelation', apiHOST);

export const postRelationProducto = util.postRelationProductoThunk({
  name: 'editEntrada/postRelationProducto',
  postRoute: `${apiHOST}views/productos-entrada/custom`,
  getRoute: `${apiHOST}views/productos-entrada`,
  getParam: 'response',
  getParamKey: 'entradaId',
});

export const deleteRelationProducto = util.deleteRelationProductoThunk({
  name: 'editEntrada/deleteRelationProducto',
  route: `${apiHOST}views/productos-entrada/delete`,
  method: 'POST_V2',
});

export const cerrarProductoOcp = util.putRequestThunkPrimaryKey(
  'ordenCompraProveedor/cerrarProductoOcp',
  `${apiHOST}views/productos-ocp/cerrar`,
);

export const cerrarEntrada = createAsyncThunk(
  'editEntrada/cerrarEntrada',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiHOST}views/entradas/cerrar/${payload.entrada.id}`,
        payload,
        {
          headers: {
            ...authorizationHeader()          },
        },
      );
      console.log('Cerrar entrada????');
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  },
);

const editEntradaSlice = createSlice({
  name: 'editEntrada',
  initialState: initialState,
  reducers: {
    change(state, action) {
      // console.log('Reducer----->');
      // console.log(action.payload.field);
      // console.log(action.payload.value);
      switch (action.payload.field) {
        case 'cantidadLetra':
          break;
        case 'fechaEntrega':
          break;
        default:
      }
    },
    select(state, action) {
      state[action.payload.field] = filterSeleccion(
        action.payload.values,
        action.payload.selectedValue,
      );
      state.editsNotSaved = true;
    },

    asign(state, action) {
      state[action.payload.reduxStateTree] = filterSeleccion(
        action.payload.valuesToFilter,
        action.payload.selectedValue,
      );
    },
    cancelAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    productToRemoveAsign(state, action) {
      state[action.payload.stateTree] = null;
    },
    resetProductosOrdenCompra(state, action) {
      state.productosOrdenCompra = null;
    },
    resetProductToSearch(state, action) {
      state.productToSearch = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEntrada.fulfilled, (state, action) => {
      const { entrada, producto, proveedor, pedidoPor, enCalidadDe } = action.payload;
      state.entrada = entrada;
      state.enCalidadDe = enCalidadDe;
      state.producto = producto;
      state.proveedor = proveedor;
      state.pedidoPor = pedidoPor;
      state.productosOrdenCompra = null;
      state.error = false;
    });

    builder.addCase(getEntrada.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    });

    builder.addCase(deleteEntrada.fulfilled, (state, action) => {
      state.editEntrada = {
        id: null,
        rfc: null,
        razonSocial: null,
        direccionId: null,
      };

      state.direccionFiscal = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.direccionEntrega = {
        id: null,
        numero: null,
        calle: null,
        ciudad: null,
        estado: null,
        cp: null,
        pais: null,
      };
      state.relationsProductos = [];
      state.asignedProducts = [];
      state.productToAsign = null;
      state.productToRemoveAsign = null;
    });
    builder.addCase(putEntrada.fulfilled, (state, action) => {
      console.log('Console.log Action sss');
      console.log(action);
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(getRelation.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = action.payload.data;
    });
    builder.addCase(postRelationProducto.fulfilled, (state, action) => {
      state[action.payload.reduxStateTree] = null;
      console.log('action.payload.data-------?');
      console.log(action.payload.data);

      console.log('action.payload.data-------?');
      console.log(action.payload.asignedListString);

      state[action?.payload?.asignedListString] = [];
      if (Array.isArray(action.payload.data)) {
        action.payload.data.map((i) => state[action?.payload?.asignedListString]?.push(i));
      }
      // else{

      //   state[action?.payload?.asignedListString]=action.payload.data

      // }

      //Set State to Nullstate the product that has been posted.
    });
    builder.addCase(deleteRelationProducto.fulfilled, (state, action) => {
      const filterdValue = action.payload.asignedListSelector?.filter(
        (item) => item.id !== action.payload.data.id,
      );
      state[action.payload.asignedListString] = filterdValue;
    });
    builder.addCase(getValuesToAsign.fulfilled, (state, action) => {
      state[action.payload.stateTree] = action.payload.data;
    });

    builder.addCase(cerrarEntrada.fulfilled, (state, action) => {
      state.entrada.vigente = action.payload?.vigente;
    });
  },
});

export default editEntradaSlice;

export const editEntradaActions = editEntradaSlice.actions;
