import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../../Util/authorizationHeader';

export const putRequestJoinedTableThunk = (name, route) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { requestObj, requestUrl, stateTree, authCtx } = payload;
    try {
      const response = await axios.put(`${route}${requestUrl}`,
      requestObj, {
        headers: {
          ...authorizationHeader()        },
      });
      return { data: response.data, stateTree };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });
