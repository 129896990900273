import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from '../TailwindUI/IconButton';
import { InputCornerHint } from './Inputs';

const SelectDate = props => {
  //Hooks
  const dispatch = useDispatch();
  //Local State
  const [editMode, setEditMode] = useState(false);

  //Redux Selectors
  const selectValues = useSelector(state => state.selectFetch.options);
  const loading = useSelector(state => state.selectFetch.loading);

  //Function Handlers
  const handleSelect = e => {
    props.action(props.reduxStateTree, e.target.value, selectValues);

    setEditMode(false);
  };

  const handleEdit = () => {
    // props.editingSelect(true);
    setEditMode(true);
  };
  const handleDate = e => {
    setEditMode(false);
    props.action(e);
  };

  return (
    <React.Fragment>
      {loading && <h1>Loading</h1>}
      {!loading && (
        <React.Fragment>
          <div>
            {!editMode && (
              <>
                <div className="flex rounded  py-1 ">
                  <div style={{ flexBasis: '100%' }}>{props.children}</div>

                  {!props.disabled && (
                    <div className="flex items-center pt-5  ">
                      <IconButton
                        onClick={handleEdit}
                        color="darkBlue"
                        icon="edit"
                        iconHeight={4}
                        iconWidth={4}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            {editMode && (
              <React.Fragment>
                <div className="flex rounded  py-1 ">
                  <div style={{ flexBasis: '100%' }}>
                    <InputCornerHint
                      type="date"
                      onChange={handleDate}
                      label="Selecciona una Fecha"
                    />
                  </div>
                  <div className="flex items-center pt-5">
                    <IconButton
                      onClick={() => setEditMode(e => !e)}
                      color="red"
                      icon="x"
                      iconHeight={4}
                      iconWidth={4}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default SelectDate;
