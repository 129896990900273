import CryptoJS from 'crypto-js';

export const hashObjectMd5 = (object) => {
  // Convert object to JSON string
  const jsonString = JSON.stringify(object);

  // Create a hash and update it with the JSON string
  const hash = CryptoJS?.MD5(jsonString).toString();

  return hash;
};
