import React from 'react';

import { Page, Image,Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import MelyLogo from '../../images/mely-logo.jpg';
import { prettifyNumber } from '../../Util/prettifyNumber';
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: '5%',
    flexWrap: 'wrap',
  },
  section: {
    margin: 10, padding: 10, flexGrow: 1,
  },
  image: {
    width: 135,
  },
  header: {
    flexDirection: 'row', justifyContent: 'space-between', flexBasis: '100%',
  },

  headerDatosCliente: {
    marginTop: '32px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexBasis: '100%',
  },

  tableContainer: {
    fontSize: '10px',
    border: '1px solid black',
    borderRadius: '1px',
    flexBasis: '100%',
  },
  tableCell: {
    flexDirection: 'row', textAlign: 'center',
  },
  tableCellColumn: {
    flexDirection: 'column', textAlign: 'center', flexBasis: '30px',
  },

  flex25: {
    flexBasis: '25%',

    paddingVertical: '3px',
  },
  flex25VerticalBorder: {
    flexBasis: '25%',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',

    paddingVertical: '3px',
  },

  flex25VerticalLeftBorder: {
    flexBasis: '25%', borderLeft: '1px solid black',

    paddingVertical: '3px',
  },

  flex50: { flexBasis: '50%', paddingVertical: '3px' },
  flex33: { flexBasis: '33.3333%', paddingVertical: '3px' },
  flex33VerticalBorder: {
    flexBasis: '33.3333%',
    paddingVertical: '3px',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  },

  flex45: {
    flexBasis: '50%',
  },

  m15: {
    margin: '15px',
  },

  mt5: {
    marginTop: '5px',
  },

  infoMelyView: {
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: '6px',
    marginTop: '24px',
  },
  infoMelyText: {
    marginBottom: '1px', fontSize: '10px',
  },
  borderBottom: {
    borderBottom: '1px solid black',
  },
  borderTop: {
    borderTop: '1px solid black',
  },

  tituloMely: {
    fontSize: '10px',
  },
  tituloMelyRojo: {
    fontSize: '12px', color: 'red',
  },
  direccionMely: {
    flexBasis: '100%',
    fontSize: '8px',
    paddingTop: '10px',
    textAlign: 'center',
    letterSpacing: '0.5px',
    marginTop: '2px',
  },

  viewOrderNumber: {
    alignSelf: 'flex-start',
  },
  infoDoc: {
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '12px',
    border: '1px solid black',
    borderRadius: '12px',
    paddingHorizontal: '16px',
    paddingVertical: '10px',
  },
  line: {
    width: '100%', border: '0.3px solid black',

    marginTop: '10px', marginBottom: '10px',
  },
  mrAuto: {
    marginRight: 'auto',
  },
  tablaDatos: {
    flexDirection: 'row', alignItems: 'flex-end', padding: '3px',
  },
});

// Create Document Component
const EntradaPDF = (props) => (<Document>
  <Page wrap size='A4' style={styles.page}>
    <View style={styles.header}>
      <View>
        <Image style={styles.image} src={MelyLogo} />
      </View>

      <View style={styles.infoMelyView}>
        <Text style={styles.tituloMely}>{props.mely?.razonSocial}</Text>

        <Text style={styles.infoMelyText}>{props.mely?.rfc}</Text>
        <Text style={styles.infoMelyText}>{props.mely?.correo}</Text>
        <Text style={styles.infoMelyText}>{props.mely?.paginaWeb}</Text>
      </View>
      <View style={styles.viewOrderNumber}>
        <View style={styles.infoDoc}>
          <Text style={styles.tituloMely}>{props.info?.titulo}</Text>
          <Text style={styles.tituloMely}>{props.info?.producto}</Text>
          <Text style={styles.line}></Text>
          <Text
            style={styles.tituloMelyRojo}>{`Nº  ${props.info?.numero}`}</Text>
        </View>
      </View>
    </View>
    <View style={styles.direccionMely}>
      <Text>{props.mely?.direccion}</Text>
    </View>

    <View style={styles.headerDatosCliente}>
      <View style={styles.flex45}>
        <View style={{ ...styles.infoDoc, fontSize: '10px' }}>
          <Text
            style={styles.mrAuto}
          >{`Datos del proveedor : ${props.cliente?.razonSocial?.toUpperCase()}`}</Text>
          <Text style={{ ...styles.mrAuto, marginTop: '3px' }}>
            {`${props.cliente?.direccionFiscal?.calle} ${props.cliente?.direccionFiscal?.numero} ${props.cliente?.direccionFiscal?.ciudad} ${props.cliente?.direccionFiscal?.estado} ${props.cliente?.direccionFiscal?.cp} ${props.cliente?.direccionFiscal?.pais}`}
          </Text>
        </View>
      </View>
      <View style={{ ...styles.infoDoc, fontSize: '10px' }}>
        <Text> {props.numeroOC.length > 0
          ? 'Ordenes de Compra'
          : 'Orden de Compra'} </Text>
        <Text style={{ marginTop: '3px' }}>
          {props.numeroOC?.map((i, index) => {
            console.log('i propsnumeroIc');
            console.log(i);

            console.log('index');
            console.log(index);
            console.log('props.numeroOC.lenght');
            console.log(props.numeroOC.length);
            return `${props.info?.tipo}_${i}${index === props.numeroOC.length -
            1 ? '' : ','} `;
          })}
        </Text>
      </View>
    </View>
    <View style={styles.m15}></View>
    <View style={styles.tableContainer}>
      <View style={styles.borderBottom}>
        <View style={styles.tablaDatos}>
          <Text>Proveedor :</Text>
          <Text
            style={{ paddingLeft: '2px' }}>{props.cliente?.razonSocial?.toUpperCase()}</Text>
        </View>
      </View>
      <View style={styles.borderBottom}>
        <View style={styles.tablaDatos}>
          <Text>Fecha :</Text>
          <Text style={{ paddingLeft: '2px' }}> {props.info?.fecha}</Text>
        </View>
      </View>
      <View>
        <View style={styles.tablaDatos}>
          <Text>En Calidad De :</Text>
          <Text style={{ paddingLeft: '2px', fontWeight: 'bold' }}>
            {props.info?.enCalidadDe}
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.m15}></View>
    <View style={styles.tableContainer}>
      <View>
        <View style={styles.tableCell}>
          <Text style={styles.flex25}>CANTIDAD</Text>
          <Text style={styles.flex25VerticalLeftBorder}>LOTE</Text>
          <Text style={styles.flex25VerticalBorder}>UNIDAD</Text>
          <Text style={styles.flex50}>DESCRIPCIÓN</Text>
        </View>
      </View>
      {props.list?.map((item) => {
        return (<View style={styles.borderTop}>
          <View style={styles.tableCell}>
            <Text style={styles.flex25}>{prettifyNumber(
              item.cantidadEntrada.toString())}</Text>
            <Text style={styles.flex25VerticalLeftBorder}>{item.lote}</Text>
            <Text style={styles.flex25VerticalBorder}>{item.unidad}</Text>
            <Text style={styles.flex50}>{item.simbolo}</Text>
          </View>
        </View>);
      })}
    </View>
    <View style={styles.m15}></View>

    <View style={styles.tableContainer}>
      <View>
        <View style={styles.tableCell}>
          <Text style={styles.flex33}>RECIBE</Text>
          <Text style={styles.flex33VerticalBorder}>ENTREGA</Text>
          <Text style={styles.flex33}>AUTORIZA ENTRADA</Text>
        </View>
      </View>
      <View style={styles.borderTop}>
        <View style={styles.tableCell}>
          <View style={styles.flex33}>
            <View style={styles.tableCellColumn}></View>
          </View>
          <View style={styles.flex33VerticalBorder}>
            <View style={styles.tableCellColumn}></View>
          </View>

          <View style={styles.flex33}>
            <View style={styles.tableCellColumn}></View>
          </View>
        </View>
      </View>
    </View>
  </Page>
</Document>);

export default EntradaPDF;
