import React from 'react';
import {

  Page,
  Image,
  Text,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import MelyLogo from '../../images/mely-logo.jpg';


import { MatchPreviousValue } from '../../Util/MatchPreviousValue';
// Create styles
const borderFormat = '1px solid #e3e6e8';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: '5%',
    flexWrap: 'wrap',
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: 'white',
  },
  row: {
    flexDirection: 'row',
    border: borderFormat,
    alignItems: 'center',
  },
  socioComercialRow: {
    flexDirection: 'row',
    borderLeft: borderFormat,
    borderRight: borderFormat,
    borderBottom: borderFormat,
    padding: '2px',
    justifyContent: 'space-between',
    color: '#9ca3af',
  },
  tableRow: {
    borderTop: '0px',
  },

  col: {
    flexDirection: 'row',
    height: '100%',
    flexBasis: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '9px',
    textAlign: 'center',
    borderLeft: borderFormat,
  },
  colShort: {
    flexDirection: 'row',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '9px',
    textAlign: 'center',
    borderLeft: borderFormat,
    flexBasis: '9%',
  },
  colLeft: {
    borderLeft: '0px',
  },
  colGreen: {
    backgroundColor: '#9fca83',
  },
  colHeader: {
    border: '0px',
    // backgroundColor: '#2a6aac',
    color: '#585d64',
    padding: '2px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '9px',
  },
  socioComercialContainer: {
    width: '30%',
    fontSize: '10px',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  razonSocial: {
    fontSize: '14px',
    color: 'white',
    backgroundColor: '#05183ec7',
    borderBottom: borderFormat,
    marginBottom: '2px',
    padding: '2px',
    paddingBottom: '4px',
  },
  backGroundWhite: {
    backgroundColor: 'white',
  },
  backGroundGray: {
    backgroundColor: '#f3f4f6',
  },
  backGroundRed100: {
    backgroundColor: '#fee2e2',
  },
  backGroundRed200: {
    backgroundColor: '#f0c7c7',
  },
  colorWhite: {
    color: 'white',
  },
  image: {
    width: 135,
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  fecha: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '10px',
    color: '#9ca3af',
    textAlign: 'right',
    marginLeft: 'auto',
    marginBottom: '8px',
  },
});

// Create Document Component

const SocioComercialUI = ({ data, divisa }) => {
  return (
    <View style={styles.socioComercialContainer}>
      <Text style={styles.razonSocial}>{data.razonSocial}</Text>
      {/* <View style={styles.socioComercialRow}>
        <Text>Días de Crédito</Text>
        <Text style={styles.mlAuto}>{data.diasDeCredito}</Text>
      </View> */}
      <View style={styles.socioComercialRow}>
        <Text>TOTAL</Text>
        <Text style={styles.mlAuto}>{`${data.total} ${divisa}`}</Text>
      </View>
      <View style={styles.socioComercialRow}>
        <Text>VENCIDO</Text>

        <Text style={styles.mlAuto}>{`${data.vencido} ${divisa}`}</Text>
      </View>
      <View style={styles.socioComercialRow}>
        <Text>VIGENTE</Text>
        <Text style={styles.mlAuto}>{`${data.vigente} ${divisa}`}</Text>
      </View>
    </View>
  );
};

const parseTableData = (data) => {
  let tableHeader = [];
  let tableRow = [];
  for (let item of Object.entries(data)) {
    const [key, value] = item;

    tableHeader.push(key);
    tableRow.push(value);
  }

  return {
    tableHeader,
    tableRow,
  };
};

const Table = ({ data }) => {
  const matchPreviousValue = MatchPreviousValue();

  return (
    <>
      <View style={styles.row}>
        {data.headerFacturas.map((col, index) => {
          let overrideCol = index !== 1 ? styles.colShort : {};
          return (
            <Text style={{ ...styles.col, ...styles.colHeader, ...overrideCol }}>
              {col.toUpperCase()}
            </Text>
          );
        })}
      </View>
      {data.facturas?.map((items) => {
        let rowBg = matchPreviousValue.execute(
          items.factura,
          items.estatus === 'VENCIDO' ? styles.backGroundRed100 : styles.backGroundGray,
          items.estatus === 'VENCIDO' ? styles.backGroundRed200 : styles.backGroundWhite,
        );

        return (
          <View style={{ ...styles.row, ...styles.tableRow, ...rowBg }}>
            {parseTableData(items).tableRow.map((col, index) => {
              const colStyle = index === 0 ? styles.colLeft : {};
              let colColor = col === 'SI' ? styles.colGreen : {};

              let overrideCol = index !== 1 ? styles.colShort : {};

              return (
                <Text style={{ ...styles.col, ...colStyle, ...colColor, ...overrideCol }}>
                  {Array.isArray(col) ? col.join('') : col}
                </Text>
              );
            })}
          </View>
        );
      })}
    </>
  );
};

export const EdoCuentaDoc = ({ socioComercial, headerFacturas, facturas, fecha, divisa }) => (
  <Document>
    <Page wrap size="A4" orientation="landscape" style={styles.page}>
      <View>
        <View>
          <Text style={styles.fecha}>FECHA: {fecha}</Text>
        </View>
        <View style={styles.top}>
          <View>
            <Image style={styles.image} src={MelyLogo} />
          </View>

          <SocioComercialUI style={styles.section} data={socioComercial} divisa={divisa} />
        </View>

        <View style={{ margin: '5px' }} />
        <Table style={styles.section} data={{ headerFacturas, facturas }} />
      </View>
    </Page>
  </Document>
);
