import OCCs from './OCCs';
import CreateOCC from './CreateOCC';
import EditOCC from './EditOCC';

export const occRoutes = [
  {
    name: 'OCCs',
    href: '/ordenes-de-compra-cliente',
    component: <OCCs />,
    exact: true,
  },
  {
    name: 'Create OCC',
    href: '/ordenes-de-compra-cliente/crear/',
    component: <CreateOCC />,
    exact: true,
  },
  {
    name: 'Edit OCCs',
    href: '/ordenes-de-compra-cliente/:id',
    component: <EditOCC />,
    exact: true,
  },
];
