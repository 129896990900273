import { hashObjectMd5 } from '../../lib/hash-object-md5';

export const edoCuentaDocumentRequestObj = (data,name,tags={}) => {

  const md5 = hashObjectMd5(data);
  return {
    "data": {
      'tags':tags,
      'type': 'edo_cuenta',
      name,
      'edo_cuenta': data,
    },
    md5,
  };
};

