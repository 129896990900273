const proveedores = [
  ['diascredito', 'días credito'],
  ['pais', 'país'],
];

const productos = [
  ['divisanombre', 'divisa nombre'],
  ['dllargo', 'dl largo'],
  ['dlancho', 'dl ancho'],
  ['mdclargo', 'mdc largo'],
  ['mdcancho', 'mdc ancho'],
  ['mdcalto', 'mdc alto'],
  ['costoadquisicionactual', 'costo adquisicion actual'],
  ['precioventaactual', 'precio venta actual'],
];

const clientes = [
  ['dfiscalcalle', 'fiscal calle'],
  ['dfiscalcolonia', 'fiscal colonia'],
  ['dfiscalnumero', 'fiscal numero'],
  ['dfiscalciudad', 'fiscal ciudad'],
  ['dfiscalcp', 'fiscal cp'],
  ['dfiscalestado', 'fiscal estado'],
  ['dfiscalpais', 'fiscal pais'],
  ['dentregacalle', 'entrega calle'],
  ['dentregacolonia', 'entrega colonia'],
  ['dentreganumero', 'entrega numero'],
  ['dentregaciudad', 'entrega ciudad'],
  ['dentregacp', 'entrega cp'],
  ['dentregaestado', 'entrega estado'],
  ['dentregapais', 'entrega pais'],
];
const occ = [['numeroocc', 'número occ']];
const ocp = [['numeroocp', 'número ocp']];
const entradasRechazos = [['razonsocialclientes', 'Razon Social Cliente']];

const common = [
  ['fechaentrega', 'fecha de entrega'],
  ['createdat', 'creada en'],
  ['razonsocial', 'razon social'],
];
export const tableHeaderMap = new Map();

[...proveedores, ...productos, ...clientes, ...occ, ...ocp, ...common, ...entradasRechazos].forEach(
  (i) => tableHeaderMap.set(i[0], i[1]),
);
