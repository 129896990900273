import { createSlice } from '@reduxjs/toolkit';
import { apiURL } from '../Util/apiURL';
import { postItemThunk } from '../lib/default-post-item';

import { asignReducer } from '../lib/shared-reducers';
import {
  insertItemFullfilled,
  insertItemPending,
  insertItemRejected,
} from '../lib/default-post-item';
const apiHOST = apiURL();

const initialState = {
  cliente: '',
  divisa: '',
  inventario: '',
  iva: false,
  fechaEntrega: '',
  fechaEntregaDisplay: '',
  productToAsign: null,
  productosOrdenCompra: [],
  error: false,
  loading: false,
  success: null,
};

export const insertOCC = postItemThunk(
  'createOCC/insertOCC',
  `${apiHOST}ordenes-compra-cliente/`,
  'ordenes-de-compra-cliente',
  'numeroOcc',
);

const createOCCSlice = createSlice({
  name: 'createOCC',
  initialState: initialState,
  reducers: {
    change(state, action) {
      switch (action.payload.field) {
        case 'cliente':
          state.cliente = action.payload.value;

          break;
        case 'fechaEntrega':
          state.fechaEntrega = action.payload.value;
          state.fechaEntregaDisplay = action.payload.value;

          break;

        case 'inventario':
          state.inventario = action.payload.value;
          break;

        case 'divisa':
          state.divisa = action.payload.value;

          break;

        case 'iva':
          state.iva = action.payload.value;
          break;

        default:
      }
    },
    asignReducer,
  },
  extraReducers: builder => {
    builder.addCase(insertOCC.fulfilled, insertItemFullfilled(initialState));
    builder.addCase(insertOCC.pending, insertItemPending);
    builder.addCase(insertOCC.rejected, insertItemRejected);
  },
});

export default createOCCSlice;

export const createOCCActions = createOCCSlice.actions;
