import React  from 'react';
import logo from '../../../images/mely-logo.jpg'
import { MenuIcon } from '@heroicons/react/outline';
import { NavLink } from 'react-router-dom';
import { useSideBarContext } from '../../../context/SideBarProvider';

export const MobileNavBarHeader = () => {
  const { openSidebar, closeSideBar, } = useSideBarContext()
  return (
    <div className="lg:hidden">
      <div className="flex items-center justify-between border-b border-gray-200 px-4 py-1.5">
        <NavLink to="/" onClick={()=>closeSideBar()}>
          <div>
            <img className="h-8 w-auto" src={logo} alt="LogoCliente" />
          </div>
        </NavLink>
        <div>
          <button
            type="button"
            className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600"
            onClick={()=>openSidebar()}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

