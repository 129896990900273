import React from 'react';
import { BottomBar } from '../SideBar/BottomBar';
import { NavBar } from '../NavBars/NavBar';
import { NavBarLogoSection } from './NavBarLogoSection';

export const NavBarMobile = ({
  navBarSections,
}) => {


  return (
    <>
      <div className='flex-1 h-0 pt-5 pb-4 overflow-y-auto '>
        <NavBarLogoSection/>
        <NavBar navBarSections={navBarSections} />
      </div>
      <BottomBar  />

    </>
  );
};