import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorizationHeader } from '../Util/authorizationHeader';
export const postItemThunk = (
  name,
  route,
  redirectRoute,
  responseDataKey = 'id'
) =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    const { request, history } = payload;
    try {
      console.log('Route---->???');
      console.log(route);
      console.log('Request---->???');
      console.log(request);
      const response = await axios.post(route, request, {
        headers: {
          ...authorizationHeader()
        },
      });
      console.log(response);
      console.log('response data->>>>>>>>>');
      let data = response.data;
      console.log(data);

      return history.push(`/${redirectRoute}/${data[responseDataKey]}`);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

export const insertItemFullfilled = (initialState) => (state, action) => {
  state = initialState;
};

export const insertItemRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload?.message;
};
export const insertItemPending = (state, action) => {
  state.loading = true;
};
