import React from 'react';
import {
  getItems,
  fetchAllActions as sliceActions, handleFilter,
} from '../../store/fetch-all-slice';
import TableRow from '../TailwindUI/TableRow';
import TableBodyCellAuto from '../TailwindUI/TableBodyCellAuto';
import { sortObjKeysFromArray } from '../../Util/sort-utils';
import { DefaultFetchAllList } from '../SharedUi/DefaultFetchAllList';

const sortingArray = ['numeroOcp', 'razonSocial', 'rfc'];

const proveedorDto = (proveedor) => ({
  'id': proveedor.id,
  'RAZON_SOCIAL': proveedor.razonSocial,
  'RFC': proveedor.rfc,
  'DÍAS CREDITO': proveedor.diasCredito,
});
const tableHeaderMapFn = (proveedor, idx) => {
  const newObj = proveedorDto(proveedor);
  return sortObjKeysFromArray(newObj, sortingArray);
};
const customDisplayMapFn = (proveedor, idx) => {
  const newObj = proveedorDto(proveedor);
  const mappedObj = sortObjKeysFromArray(newObj, sortingArray);

  return (
    <React.Fragment key={proveedor.id + idx}>
      <TableRow
        key={proveedor.id}
        className={
          idx % 2 === 0 ? 'bg-white' : 'bg-gray-100'
        }
      >
        <TableBodyCellAuto list={mappedObj} />
      </TableRow>
    </React.Fragment>
  );
};

const filterCondition = (state, value) => {
  let condition = (
    //TODO make all keys lowercase
    String(state?.razonSocial).toUpperCase().includes(value) ||
    String(state?.rfc).toUpperCase().includes(value)
  );
  return condition;
};

const Clientes = () => {
  return DefaultFetchAllList({
    sliceState: 'fetchAll',
    sliceActions,
    getItems,
    handleFilter,
    routePath: 'proveedores',
    filterCondition,

    orderByOptions: [
      '',
      'razonSocial',
      'rfc',
    ],
  });

};

export default Clientes;
