import { NavLink } from 'react-router-dom';
import React from 'react';
import logo from '../../../images/logo-mely.svg';
import { useSideBarContext } from '../../../context/SideBarProvider';

export const NavBarLogoSection = () => {
  const {
    closeSideBar,
  } = useSideBarContext();
  return (
    <div className='flex-shrink-0 flex items-center px-4 '>
    <NavLink to='/' onClick={()=>closeSideBar()}>
      <img className='h-8 w-auto' src={logo}
           alt='DistritoMatera-logo' />
    </NavLink>
  </div>);
};