/* This example requires Tailwind CSS v2.0+ */
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/solid';

export default function Alert(props) {
  let icon;
  let color;
  switch (props.type) {
    case 'ok':
      color = 'green';
      icon = (
        <CheckCircleIcon
          className={`h-5 w-5 text-${color}-400`}
          aria-hidden="true"
        />
      );
      break;
    default:
      color = 'red';
      icon = (
        <XCircleIcon
          className={`h-5 w-5 text-${color}-400`}
          aria-hidden="true"
        />
      );
  }

  return (
    <div className={`rounded-md bg-${color}-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <button onClick={props.onClick}>{icon}</button>
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${color}-800`}>
            {props.message}
          </h3>
          {/* <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc pl-5 space-y-1">
              <li>Your password must be at least 8 characters</li>
              <li>Your password must include at least one pro wrestling finishing move</li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}
